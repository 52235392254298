import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { PenIcon } from '@components/Icons/PenIcon/PenIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { JobPolicy } from '@jobs/models/Job.model';
import { cn } from '@utils/classes.util';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  owner: string;
  icon?: string;
  policy: JobPolicy;
  modifyIcon: boolean;
  action?: () => void;
};

const JobTitleMobile: React.FC<Props> = ({
  title,
  owner,
  icon,
  policy,
  modifyIcon,
  action,
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <div className="lg:hidden w-full flex flex-col items-center justify-center text-center mb-4 lg:mb-0 break-normal">
      <span className="py-2 text-4xl">{icon}</span>
      <Title
        size="span"
        color="text-white"
        font="ubuntu"
        classOverrides={['text-2xl']}
        bold
      >
        {owner}
      </Title>
      <Title
        size="h1"
        color="text-white"
        classOverrides={['flex items-center justify-center flex-row ']}
        bold
      >
        {title}
        {policy === 'private' && (
          <FeatureFlag name="private-job">
            <Tooltip
              title={t('my_jobs.modify_your_job.policy.public.tooltip')}
              colorSet={
                theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
              }
            >
              <LockIcon
                classOverrides={['ml-2']}
                color="text-gray-primary"
                width="22"
              />
            </Tooltip>
          </FeatureFlag>
        )}
      </Title>
      {modifyIcon && (
        <Tooltip
          title={t('job_details.modify_tooltip')}
          colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
        >
          <div>
            <div
              className={cn(
                'lg:hidden mt-2 rounded-full bg-white shadow-lg hover:shadow-button-inner w-10 h-10 flex items-center justify-center flex-col relative cursor-pointer ',
              )}
              data-testid="dropdown-icon"
              onClick={action}
            >
              <PenIcon
                color="text-secondary-default"
                classOverrides={['w-5/12']}
              />
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export { JobTitleMobile };
