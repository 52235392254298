import { JobVersion } from '@jobs/models/JobVersion.model';
import { returnLatestVersion } from '@utils/jobs.util';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
  versions: JobVersion[];
  uniqueTestId: number;
};

export const JobVersionTag: React.FC<Props> = ({ versions, uniqueTestId }) => {
  const versionToShow = returnLatestVersion(versions);
  const { theme } = useContext(ThemeContext);
  return (
    <div className="text-xs sm:text-sm">
      {versionToShow ? (
        versionToShow.tag
      ) : (
        <Skeleton
          width={30}
          baseColor={
            theme === 'dark'
              ? getColorSet(ColorSetEnum.darkSkeleton)?.backgroundColor
              : undefined
          }
          highlightColor={
            theme === 'dark'
              ? getColorSet(ColorSetEnum.darkSkeleton)?.color
              : undefined
          }
          containerClassName="ml-12 md:ml-0"
          containerTestId={'job-version-skeleton-' + uniqueTestId}
        />
      )}
    </div>
  );
};
