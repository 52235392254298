import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { HomePage } from './Home.page';
import { NotFoundThrow } from '@components/Errors/NotFound.component';
import { Layout, LayoutEnum } from '@components/Page/Layout/Layout.component';
import { PrivacyPolicyPage } from './PrivacyPolicy.page';
import { RoadmapPage } from './Roadmap.page';
import { TermsOfUsePage } from './TermsOfUse.page';
import { TeamPage } from './Team.page';
import { LegalNoticesPage } from './LegalNotices.page';
import { PricingPage } from './Pricing.page';
import { UserContext } from 'user/contexts/user.context';
import { MyProjects } from 'user/pages/dashboard.router';

const GeneralRouter: React.FC = () => {
  const { identity } = useContext(UserContext);
  const { location } = useHistory();

  return (
    <Layout
      layoutType={
        identity && location.pathname === '/'
          ? LayoutEnum.DASHBOARD
          : LayoutEnum.STANDARD
      }
    >
      <Switch>
        <Route path={'/'} exact component={identity ? MyProjects : HomePage} />
        <Route path={'/team'} component={TeamPage} />
        <Route path={'/privacy'} component={PrivacyPolicyPage} />
        <Route path={'/legal'} component={LegalNoticesPage} />
        <Route path={'/termsofuse'} component={TermsOfUsePage} />
        <Route path={'/roadmap'} component={RoadmapPage} />
        <Route path={'/pricing'} component={PricingPage} />
        <Route path={'*'} component={NotFoundThrow} />
      </Switch>
    </Layout>
  );
};

export { GeneralRouter };
