export const DISCORD_INVITE_LINK = 'https://discord.com/invite/5QKpGqR';
export const DISCORD_NOTIF_LINK =
  'https://discord.r2devops.io/?utm_medium=website&utm_source=r2devops&utm_campaign=notification';
export const DISCORD_FOOTER_LINK =
  'https://discord.r2devops.io/?utm_medium=website&utm_source=r2devops&utm_campaign=footer';
export const DISCORD_HOME_LINK =
  'https://discord.r2devops.io/?utm_medium=website&utm_source=r2devops&utm_campaign=homepage';
export const YOUTUBE_CHANNEL_LINK =
  'https://www.youtube.com/channel/UCVt39An0xR1PJaX3_Me_zkw';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/go2scale/';
export const GITLAB_HUB_LINK = 'https://gitlab.com/r2devops/hub';
export const TWITTER_LINK = 'https://twitter.com/r2devops_io';
export const FAQ_LINK = 'https://docs.r2devops.io/faq/';
export const CONTACT_FORM = 'https://tally.so/r/m6zkkm';
export const FEEDBACK_FORM = 'https://tally.so/r/w5Edvw';
export const OPENCOLLECTIVE_LINK = 'https://opencollective.com/r2devops';
export const JOB_DEFINITION =
  'https://docs.r2devops.io/job-structure/#job-definition';
export const JOB_DOCUMENTATION =
  'https://docs.r2devops.io/job-structure/#job-documentation';
export const JOB_CHANGELOGS =
  'https://docs.r2devops.io/job-structure/#job-changelogs';
export const CONTRIBUTE_JOB_DOCUMENTATION =
  'https://docs.r2devops.io/get-started-contribute-job/#job-documentation';
export const DOCUMENTATION_LINK = 'https://docs.r2devops.io';
export const PREMIUM_LICENSE_PRICEID =
  process.env.REACT_APP_PREMIUM_LICENSE_PRICEID ??
  'price_1KsG7JAYzPPlvWexGE8zHFyE';
export const GITLAB_TOKEN_DOCUMENTATION =
  'https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html';
export const GITHUB_TOKEN_DOCUMENTATION =
  'https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token';
export const DOCS_TOKEN_DOCUMENTATION =
  'https://docs.r2devops.io/faq/#access-token';

/*
 * PASSWORD POLICY RELATED
 */

/**
 * Minimum length for password
 */
export const PWD_MIN_LENGTH = 8;
/**
 * Maximum length for password
 */
export const PWD_MAX_LENGTH = 72;
/**
 * Minimum numeric for password
 */
export const PWD_MIN_NUMERIC = 1;
/**
 * Minimum lowercase characters for password
 */
export const PWD_MIN_LOWERCASE = 1;
/**
 * Minimum uppercase characters for password
 */
export const PWD_MIN_UPPERCASE = 1;
/**
 * Minimum special characters for password
 */
export const PWD_MIN_SPECIAL = 1;

/**
 * Default number of element when searching something
 */
export const DEFAULT_SEARCH_LIMIT = 20;

/**
 * MIN number of element when searching something
 */
export const MIN_SEARCH_LIMIT = 5;
/**
 * MAX number of element when searching something
 */
export const MAX_SEARCH_LIMIT = 100;

/**
 * Default page when searching something
 */
export const DEFAULT_SEARCH_PAGE = 1;
/**
 * Minimum page when searching something
 */
export const MIN_SEARCH_PAGE = 1;

/**
 * Maximum job description length
 */
export const MAX_JOB_DESCRIPTION_LENGTH = 300;

/**
 * Job labels
 */
// TODO: replace once backend provides labels
export const labels = [
  'Gitlab',
  'Security',
  'SAST',
  'Golang',
  'Test',
  'Lint',
  'Gradle',
  'Code Quality',
  'Code Performance',
  'Sonarqube',
  'Npm',
  'Utilities',
  'Java',
  'Maven',
  'Dependency management',
  'PHP',
  'Composer',
  'Python',
  'Spelling',
  'Twig',
  'Yarn',
  'API',
  'Documentation',
  'Build',
  'Container',
  'Docker',
  '.NET',
  'Laravel',
  'Robustness',
  'DAST',
  'Terraform',
  'Configuration',
  'Helm',
  'Kubernetes',
  'Release',
  'Artisan',
  'Database',
  'AWS',
  'S3',
  'Deploy',
];

/**
 * Feature flags - change the active attribute to active or desactivate features
 */
export const FeatureFlags = [
  {
    name: 'private-job',
    description: 'User can set the policy of his jobs ( Private or Public )',
    active: false,
  },
  {
    name: 'user-projects',
    description: 'The choice to add and manage GitLab projects',
    route: '/dashboard/projects',
    active: true,
  },
  {
    name: 'deprecated-job',
    description: 'User can set his job as deprecated',
    active: false,
  },
  {
    name: 'referral-program',
    description: 'User can register with a referral link',
    active: false,
  },
  {
    override: true,
  },
];
