import { PricingCard } from '@components/PricingCard/PricingCard.component';
import { Highlighter } from '@components/Highlighter/Highlighter.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Trans, useTranslation } from 'react-i18next';
import { PricingTable } from '@components/PricingTable/PricingTable.component';
import { PricingFeatures } from '@typings/pricingFeatures';
import { PricingPlans } from 'common/constants/pricingPlans';
import { useState } from 'react';
import { Modal } from '@components/Modal/Modal.component';
import { TomorrowFeaturesRow } from 'user/components/TomorrowFeaturesRow/TomorrowFeaturesRow.component';
import { CollapseRow } from '@components/CollapseRow/CollapseRow.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Typography } from '@components/Typography/Typography.component';

export const PricingPage: React.FC = () => {
  const { t } = useTranslation();
  const pricing: Array<PricingFeatures> = PricingPlans();
  const [showModal, setShowModal] = useState(false);

  /**
   * Function used to get the array of features into string that user will read
   * @param pricingName the name of the pricing
   * @returns An array of string that coressponds to all features of the card
   */
  const getFeatures = (pricingName: string) => {
    let features: Array<string> = [];

    let nbFeatures = 4;
    if (pricingName === t('pricing.ondemand.name')) {
      nbFeatures = 3;
    }

    for (let i = 0; i < nbFeatures; ++i) {
      const feature: string = t(
        `pricing.${pricingName
          .toLowerCase()
          .replaceAll(' ', '')}.features.${i}`,
      );
      features.push(feature);
    }

    return features;
  };

  /**
   * Function used for displaying a card with informations abour pricing
   * Defines automaticcaly the color of the button and the border around if the Feature is selected as popular
   * @param pricingOption An PricingFeature type which should be displayed
   * @returns A card with all datas showed
   */
  const getPricingCard = (pricingOption: PricingFeatures) => {
    const pricingCard = (
      <PricingCard
        title={pricingOption.name}
        key={pricingOption.name}
        subtitle={pricingOption.description}
        price={pricingOption.price === -1 ? undefined : pricingOption.price}
        features={getFeatures(pricingOption.name)}
        isButtonTop={
          pricingOption.name === t('pricing.ondemand.name') ? true : false
        }
        textButton={pricingOption.textButton}
        bgColor={
          !pricingOption.isPopular
            ? 'dark:bg-secondary-default-medium-opacity'
            : 'bg-gray-primary'
        }
        bgColorButton={
          !pricingOption.isPopular
            ? 'bg-secondary-default dark:bg-gray-primary'
            : 'bg-primary-default'
        }
        classOverrides={[
          pricingOption.name.toUpperCase() === t('pricing.premium.name') &&
            'mx-2',
          'w-72 md:w-80 my-4',
        ]}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    );

    return pricingOption.isPopular ? (
      <Highlighter
        borderColor={'border-primary-default'}
        textColor={'text-primary-default'}
        textSize={'lg'}
        textBgColor={'dark:bg-dark-primary'}
        key={pricingOption.name}
        classOverrides={['md:mx-3']}
      >
        {pricingCard}
      </Highlighter>
    ) : (
      pricingCard
    );
  };

  return (
    <Page title={t('pricing.title')} classOverrides={['pb-12']}>
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('pricing.title')}
        </Title>
        <NavBarCutter />
      </div>
      {/* Render pricings card */}

      <div className="flex flex-col items-center w-full">
        <div className="flex flex-row items-baseline justify-evenly flex-wrap w-96 md:w-3/4 lg:w-5/6 2xl:px-28">
          {pricing.map((e) => getPricingCard(e))}
        </div>
        <div className="hidden md:block bg-gray-primary dark:bg-secondary-default-medium-opacity w-full text-center mt-4">
          <Title
            size={'h2'}
            color={'text-secondary-default'}
            font={'ubuntu'}
            bold
            classOverrides={['mb-8 mt-8 lg:mt-14']}
          >
            {t('pricing.features_title')}
          </Title>
          {/* Render table features */}
          <div className="flex flex-row items-start lg:justify-center overflow-x-auto w-full">
            <PricingTable
              pricing={pricing}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
        </div>
        <div className="relative w-full sm:w-5/6 px-12 lg:px-36 xl:px-0 xl:w-6/12 2xl:w-5/12 flex items-center flex-col">
          <Title
            size={'h2'}
            color={'text-secondary-default'}
            font={'ubuntu'}
            bold
            classOverrides={['mb-8 mt-8 lg:mt-14']}
          >
            {t('pricing.faq.title')}
          </Title>

          <TomorrowFeaturesRow
            title={t('pricing.faq.rows_titles.0')}
            content={t('pricing.faq.rows_content.0')}
            titleColor="text-gray-secondary"
            titleSize="md"
            font={'comfortaa'}
            titleClassOverrides={['font-bold my-4']}
          />
          <CollapseRow
            title={t('pricing.faq.rows_titles.1')}
            titleColor="text-gray-secondary"
            titleSize="md"
            font={'comfortaa'}
            titleClassOverrides={['font-bold my-4']}
          >
            <Trans i18nKey={t('link_job.privacy.reached_max_jobs')}>
              <Typography
                color="text-gray-secondary"
                classOverrides={['dark:text-gray-primary ']}
              >
                Auto pipeline only works for projects on GitLab today. So if you
                work using GitHub, Jenkins or any other platform you won't be
                able to use the auto pipeline feature. But you can join the{' '}
                <OutsideLink
                  name="discord link"
                  to="https://discord.r2devops.io/?utm_medium=website&utm_source=r2devops&utm_campaign=faqpricing"
                  className="text-primary-default hover:opacity-70"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  community
                </OutsideLink>{' '}
                to be informed when auto pipeline will work on other CI/CD
                platforms!
              </Typography>
            </Trans>
          </CollapseRow>
          <TomorrowFeaturesRow
            title={t('pricing.faq.rows_titles.2')}
            content={t('pricing.faq.rows_content.2')}
            titleColor="text-gray-secondary"
            titleSize="md"
            titleClassOverrides={['font-bold my-4']}
            font={'comfortaa'}
          />
        </div>
      </div>
      <Modal
        classOverrides={['w-3/4 xl:w-2/4 z-40']}
        setShowModal={setShowModal}
        showModal={showModal}
      >
        <iframe
          src="https://tally.so/embed/3E2aAw?alignLeft=1&hideTitle=1&transparentBackground=1"
          width="100%"
          height="200"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          title="R2Devops contact form"
          className="h-125 px-12 w-full"
        />
      </Modal>
    </Page>
  );
};
