import { toastError } from '@components/Toasters/Toaster.component';
import { Tier } from '@jobs/components/TiersFilterSelector/TiersFilterSelector.component';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { buildRoute } from '@utils/routes.util';
import { DEFAULT_SEARCH_PAGE } from 'common/constants/general';
import { API_ROUTES } from 'common/constants/routes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpContext } from '../contexts/http.context';

export type Response = {
  jobs: JobResponse[];
  count: number;
};

export type Label = {
  name: string;
};

export enum JobsSortBy {
  RateUp = 'rateUp',
  RateDown = 'rateDown',
  updatedAt = 'updatedAt',
}

export interface UseJobsProps {
  perPage: number;
  page: number;
  search?: string;
  owner?: string;
  policy?: string;
  labels?: Label[];
  tiers?: Tier[];
  sortBy?: JobsSortBy;
  sortOrder?: 'ASC' | 'DESC';
}

export const defaultUseJobsProps: UseJobsProps = {
  perPage: 20,
  page: DEFAULT_SEARCH_PAGE,
  search: '',
  owner: '',
  policy: '',
  labels: [],
  tiers: [],
};

export const useJobs = ({
  perPage,
  page,
  search,
  owner,
  policy,
  labels,
  tiers,
  sortOrder,
  sortBy,
}: UseJobsProps = defaultUseJobsProps) => {
  const [response, setResponse] = useState<Response>({ count: 0, jobs: [] });
  const [fetchResult, dispatch] = useReducer(httpReducer<Response>(), null);
  const [loading, setLoading] = useState(false);

  /* Used to avoid concatenating repeated jobs when page changes but new jobs are being fetched. See line 70 */
  const hasFetchedJobs = useRef(false);

  const httpService = useContext(HttpContext);

  const { t } = useTranslation();

  const route = buildRoute(
    API_ROUTES.GET_JOBS,
    `per_page=${perPage}`,
    `page=${page}`,
    search ? `search=${search}` : '',
    owner ? `owner=${owner}` : '',
    policy ? `policy=${policy}` : '',
    labels && labels.length
      ? `labels=${labels.map((label) => label.name).join()}`
      : '',
    tiers && tiers.length
      ? `tiers=${tiers.map((tier) => tier.name).join()}`
      : '',
    sortBy ? `sortBy=${sortBy}` : '',
    sortOrder ? `sortOrder=${sortOrder}` : '',
  );

  useEffect(() => {
    hasFetchedJobs.current = false;
    dispatch({
      type: ActionType.PROGRESS,
    });
    setLoading(true);
    httpService
      .fetch<Response>('GET', route)
      .then(async (res) => {
        if (!res.response) {
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        } else if (res.status === 500) {
          toastError(t('toast.internal_server_error'));
        } else {
          dispatch({
            type: ActionType.SUCCESS,
            result: res.response,
          });

          hasFetchedJobs.current = true;
        }
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => setLoading(false));
  }, [httpService, route, t]);

  useEffect(() => {
    if (fetchResult && fetchResult.jobs && hasFetchedJobs.current) {
      if (page > 1) {
        setResponse((prevResponse) => {
          return {
            count: prevResponse.count,
            jobs: [...prevResponse.jobs, ...fetchResult.jobs],
          };
        });
      } else {
        setResponse({ count: fetchResult.count, jobs: [...fetchResult.jobs] });
      }
    }
  }, [fetchResult, page]);

  return {
    loading,
    jobs: response.jobs,
    count: response.count,
    fetchResult,
  };
};
