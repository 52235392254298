import { useCallback, useEffect, useState } from 'react';

export const useOnKeyPress = (
  keys: string | string[],
  callback: () => void,
  elementReceivingKeyEvent?: React.RefObject<any>,
) => {
  const [triggeredKeys, setTriggeredKeys] = useState(keys);
  const triggeredCallback = useCallback(
    (key: KeyboardEvent) => {
      if (typeof triggeredKeys === 'string' && triggeredKeys === key.key) {
        callback();
      } else if (
        typeof triggeredKeys === 'object' &&
        triggeredKeys.includes(key.key)
      ) {
        callback();
      }
    },
    [callback, triggeredKeys],
  );
  useEffect(() => {
    const element = elementReceivingKeyEvent?.current ?? window;
    element.addEventListener('keyup', triggeredCallback);

    return () => {
      element.removeEventListener('keyup', triggeredCallback);
    };
  }, [triggeredCallback, elementReceivingKeyEvent]);

  return {
    updateKeys: setTriggeredKeys,
  };
};
