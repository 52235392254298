import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

export const TableCell: React.FC<ClassOverrides & { dataTestId?: string }> = ({
  children,
  classOverrides = [],
  dataTestId,
}) => {
  return (
    <td className={cn(...classOverrides)} data-testid={dataTestId}>
      {children}
    </td>
  );
};
