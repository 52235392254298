import React from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import { GitlabIcon } from '@components/Icons/GitlabIcon/GitlabIcon.component';
import { Typography } from '@components/Typography/Typography.component';
import { UnlinkIcon } from '@components/Icons/UnlinkIcon/UnlinkIcon.component';
import { UseFormRegisterReturn } from 'react-hook-form';
import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import { ChainIcon } from '@components/Icons/ChainIcon/ChainIcon.component';

type GitLabButtonProps = {
  showLinkActivation?: boolean;
  isAccountLinked?: boolean;
  title?: string;
  register?: UseFormRegisterReturn;
  value?: string;
  node?: UiNode;
};

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 *
 * @see HTMLStyling
 * @see ButtonProps
 */
const GitLabButton: React.FC<HTMLStyling & ButtonProps & GitLabButtonProps> = ({
  content,
  action,
  children,
  title,
  classOverrides = [],
  bgColor = 'bg-gray-primary',
  color = 'text-gray-secondary',
  bold = true,
  light = false,
  uppercase = false,
  font = 'ubuntu',
  submitButton = false,
  disabled = false,
  isAccountLinked = false,
  showLinkActivation = false,
  register,
  node,
}) => {
  const onClickButton = () => {
    if (action) action();
  };
  const nodeAttributes = node && (node.attributes as UiNodeInputAttributes);
  return !nodeAttributes ? (
    <div className="w-full flex justify-center items-center">
      <button
        onClick={onClickButton}
        className={cn(
          'rounded-2lg transition duration-150 px-2 ' +
            'filter shadow-lg flex flex-row items-center justify-evenly',
          bgColor,
          color,
          !disabled && 'hover:shadow-button-inner',
          `font-${font}`,
          bold && 'font-bold',
          light && 'font-light',
          uppercase && 'uppercase',
          ...classOverrides,
        )}
        data-testid={`gitlabbutton-${content}`}
        type={submitButton ? 'submit' : 'button'}
        title={title}
        disabled={disabled}
        aria-label={content}
        {...register}
      >
        <GitlabIcon classOverrides={['w-9 lg:w-11 py-1']} />
        <Typography classOverrides={['md:text-sm']} size="xs" color={color}>
          {content}
        </Typography>
        {showLinkActivation ? (
          isAccountLinked ? (
            <ChainIcon />
          ) : (
            <UnlinkIcon />
          )
        ) : (
          <></>
        )}
        {children}
      </button>
    </div>
  ) : (
    <div className="w-full flex justify-center items-center">
      <button
        onClick={onClickButton}
        className={cn(
          'rounded-2lg transition duration-150 px-2 ' +
            'filter shadow-lg flex flex-row items-center justify-evenly',
          bgColor,
          !disabled && 'hover:shadow-button-inner',
          color,
          `font-${font}`,
          bold && 'font-bold',
          light && 'font-light',
          uppercase && 'uppercase',
          ...classOverrides,
        )}
        data-testid={`gitlabbutton-${content}`}
        type={submitButton ? 'submit' : 'button'}
        title={title}
        disabled={disabled}
        aria-label={content}
        name={nodeAttributes.name}
        value={nodeAttributes.value}
      >
        <GitlabIcon classOverrides={['w-9 lg:w-11 py-1']} />
        <Typography classOverrides={['md:text-sm']} size="xs" color={color}>
          {content}
        </Typography>
        {nodeAttributes.name &&
          (nodeAttributes.name === 'unlink' ? (
            <ChainIcon />
          ) : (
            nodeAttributes.name === 'link' && <UnlinkIcon />
          ))}
        {children}
      </button>
    </div>
  );
};

export { GitLabButton };
