import { License, useLicense } from 'common/hooks/useLicense.hook';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from 'react-query';
import { UserContext } from './user.context';

interface IProjectsContext {
  currentLicense?: License | null | undefined;
  setCurrentLicense?: React.Dispatch<
    React.SetStateAction<License | null | undefined>
  >;
  autoPipelineRemaining: number;
  setAutoPipelineRemaining?: React.Dispatch<React.SetStateAction<number>>;
  refetchUserLicense?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<License, Error>>;
}

export const ProjectsContext = createContext<IProjectsContext>({
  autoPipelineRemaining: 1,
});

export const ProjectsContextProvider: React.FC = ({ children }) => {
  const { identity } = useContext(UserContext);

  const { license: currentLicense, refetch: refetchUserLicense } = useLicense(
    identity?.traits.username,
  );

  const [autoPipelineRemaining, setAutoPipelineRemaining] = useState(
    currentLicense?.licenseType
      ? currentLicense.licenseType.projectMax - currentLicense.projects.length
      : 5,
  );

  useEffect(() => {
    if (!setAutoPipelineRemaining) return;
    setAutoPipelineRemaining(
      currentLicense?.licenseType
        ? currentLicense.licenseType.projectMax - currentLicense.projects.length
        : 1,
    );
  }, [currentLicense, setAutoPipelineRemaining]);

  return (
    <ProjectsContext.Provider
      value={{
        currentLicense,
        refetchUserLicense,
        autoPipelineRemaining,
        setAutoPipelineRemaining,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
