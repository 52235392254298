import { useContext, useReducer, useState } from 'react';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { API_ROUTES, API_URL } from 'common/constants/routes';
import {
  toastError,
  toastPipeline,
  toastWarning,
} from '@components/Toasters/Toaster.component';
import { HttpContext } from 'common/contexts/http.context';
import { useTranslation } from 'react-i18next';
import { useGitRepo } from '../useGitRepo/useGitRepo.hook';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { SM_SCREEN_SIZE } from 'common/constants/style.constant';

export type LightPipelineConfig = {
  projectUrl: string;
  sourceBranch: string;
  platform: 'github' | 'gitlab';
};

type ReturnType = [
  (repo: LightPipelineConfig) => void,
  LightPipelineResponse | null | undefined,
  boolean,
];

type PipelineResponse = {
  pipeline: string;
  platform: string;
};

export type LightPipelineResponse = {
  pipelines: PipelineResponse[];
  errors?: {
    body: string;
  };
};
export const useLightPipelineGenerator = (): ReturnType => {
  const [fetchResult, dispatch] = useReducer(
    httpReducer<LightPipelineResponse>(),
    null,
  );
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);
  const [getGitRepo] = useGitRepo();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const triggerAutoPipeline = (
    autoPipelineConfig: LightPipelineConfig,
  ): void => {
    let defaultBranch = '';
    setLoading(true);
    const gitRequest = getGitRepo(autoPipelineConfig);
    gitRequest.then(async (repoResponse) => {
      if (!repoResponse.response) {
        return;
      } else if (repoResponse.response.default_branch) {
        defaultBranch = repoResponse.response.default_branch;
      }

      if (repoResponse.status === StatusCodes.NOT_FOUND) {
        toastWarning(t('home.light_pipeline.modal.error'));
        setLoading(false);
      } else if (repoResponse.error?.message) {
        toastError(repoResponse.error?.message);
        setLoading(false);
      }

      if (!autoPipelineConfig || !gitRequest || !defaultBranch) return;
      autoPipelineConfig.sourceBranch = defaultBranch;

      httpService
        .fetch<LightPipelineResponse>(
          'POST',
          API_URL + API_ROUTES.POST_USER_PROJECT_LIGHT_PIPELINE,
          {
            body: autoPipelineConfig,
            withCredentials: false,
            timeout: 20000,
          },
        )
        .then(async (res) => {
          if (
            res.status !== StatusCodes.CREATED &&
            res.status !== StatusCodes.OK &&
            res.status !== StatusCodes.TOO_MANY_REQUESTS &&
            res.response &&
            res.response.errors?.body
          ) {
            toastError(res.response.errors?.body);
          } else if (
            res.status !== StatusCodes.CREATED &&
            res.status !== StatusCodes.OK &&
            res.status !== StatusCodes.TOO_MANY_REQUESTS &&
            !res.response?.errors?.body
          ) {
            toastError(t('toast.unknow_error'));
          } else if (res.status === StatusCodes.TOO_MANY_REQUESTS) {
            toastError(t('toast.rate_limited'));
          } else {
            if (windowSize.width >= SM_SCREEN_SIZE)
              toastPipeline(
                autoPipelineConfig.projectUrl,
                t('home.light_pipeline.modal.advise'),
                t('action:sign_in'),
              );
          }
          if (!res.response) {
            dispatch({
              type: ActionType.FAIL,
              error: {
                message: getReasonPhrase(res.status),
                code: res.status,
              },
            });
            return;
          }
          dispatch({
            type: ActionType.SUCCESS,
            result: res.response,
          });
        })
        .catch((_) => {
          dispatch({
            type: ActionType.FAIL,
            error: {
              code: StatusCodes.SERVICE_UNAVAILABLE,
              message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return [triggerAutoPipeline, fetchResult, loading];
};
