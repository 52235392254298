import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from 'user/contexts/user.context';
import { ProjectsContext } from 'user/contexts/projects.context';
import {
  GitHubRepository,
  useGitHubProjects,
} from 'common/hooks/useGitHubProjects.hook';
import { GitHubProjectRow } from '../GitHubProjectRow/GitHubProjectRow.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { motion } from 'framer-motion';
import { CardIcon } from '@components/CardIcon/CardIcon.component';
import { LinkProjectIcon } from '@components/Icons/LinkProjectIcon/LinkProjectIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Typography } from '@components/Typography/Typography.component';
import { simpleFadeIn } from '@utils/motion.util';
import { Trans, useTranslation } from 'react-i18next';
import { toastWarning } from '@components/Toasters/Toaster.component';
import { ProjectsCardIcon } from '@components/ProjectsCardIcon/ProjectsCardIcon.component';
import { isGitLabWebUrl } from '@utils/text.util';

type Props = {
  setShowTokenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GitHubProjects: React.FC<Props> = ({ setShowTokenModal }) => {
  const { identity } = useContext(UserContext);
  const { t } = useTranslation();
  const { getGitHubProjects, loading, gitHubProjects, notFound } =
    useGitHubProjects();
  const { autoPipelineRemaining, currentLicense } = useContext(ProjectsContext);

  const [projectsCards, setProjectsCards] = useState<GitHubRepository[]>([
    ...Array(7),
  ]);

  const lightPipelineUrl = sessionStorage.getItem('lightPipelineUrl');

  useEffect(() => {
    if (
      !lightPipelineUrl ||
      isGitLabWebUrl(lightPipelineUrl) ||
      !projectsCards ||
      loading ||
      !projectsCards[0]
    )
      return;
    let projectExist =
      projectsCards.find((project) => {
        if (project) {
          return project.html_url === lightPipelineUrl;
        } else {
          return undefined;
        }
      }) !== undefined;
    if (!projectExist) {
      let projectNamespace = new URL(lightPipelineUrl);
      toastWarning(
        <Trans
          i18nKey={'toast.light_pipeline_not_found'}
          values={{
            namespace: `${projectNamespace.pathname
              .replace('/', '')
              .replace('/', '|')}`,
          }}
        >
          The project
          <OutsideLink
            className="text-primary-default"
            name={'Light pipeline Url'}
            to={lightPipelineUrl}
            rel="nofollow referrer"
            target="_blank"
          >
            {projectNamespace.pathname.replace('/', '').replace('/', '|')}
          </OutsideLink>
          you've tested previously is not on your personal space
        </Trans>,
      );
      sessionStorage.removeItem('lightPipelineUrl');
    }
  }, [lightPipelineUrl, loading, projectsCards, t]);

  const compare = useCallback(
    (a: GitHubRepository, b: GitHubRepository) => {
      const aIsUsed =
        currentLicense?.projects &&
        currentLicense.projects.find(
          (licenseProject) => a.id === licenseProject.projectIDPlatform,
        ) !== undefined;
      const bIsUsed =
        currentLicense?.projects &&
        currentLicense.projects.find(
          (licenseProject) => b.id === licenseProject.projectIDPlatform,
        ) !== undefined;

      if (aIsUsed && !bIsUsed) {
        return -1;
      } else if (!aIsUsed && bIsUsed) {
        return 1;
      }
      return 0;
    },
    [currentLicense?.projects],
  );

  useEffect(() => {
    if (gitHubProjects || loading) return;
    getGitHubProjects();
  });
  useEffect(() => {
    if (!gitHubProjects) {
      setProjectsCards([]);
    } else if (gitHubProjects) {
      if (typeof gitHubProjects.sort === 'function') {
        gitHubProjects.sort(compare);
      }
      setProjectsCards(gitHubProjects);
    }
  }, [compare, gitHubProjects]);

  useEffect(() => {
    if (!notFound || loading) return;
    toastWarning(
      `No projects found on your GitHub account, it could be related to a wrong personal access token, please fill it again or create a new project on GitHub`,
    );
  }, [
    identity?.traits.github_username,
    identity?.traits.username,
    loading,
    notFound,
  ]);

  return (
    <>
      {!loading &&
        typeof projectsCards.map === 'function' &&
        projectsCards.map((project, index) => {
          if (!project) return null;
          return (
            <GitHubProjectRow
              key={index}
              index={index}
              project={project}
              projectInLicense={currentLicense?.projects.find(
                (licenseProject) =>
                  licenseProject.projectIDPlatform === project.id,
              )}
              autoPipelineRemaining={autoPipelineRemaining}
            />
          );
        })}
      {loading && <SpinnerIcon />}
      {!identity?.traits.gitlab_token && !loading && (
        <motion.div
          variants={simpleFadeIn()}
          initial={'hidden'}
          animate={'show'}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex flex-col justify-center items-center text-center"
        >
          <div className="text-center flex flex-col items-center">
            <div
              className="h-72 md:h-80 w-72 md:w-96 mb-20 md:mb-16"
              onClick={() => setShowTokenModal(true)}
            >
              <ProjectsCardIcon
                title={t('my_projects.token_card.title')}
                text={'my_projects.token_card.instructions'}
                platform={'GitHub'}
              >
                <LinkProjectIcon
                  classOverrides={['text-secondary-default dark:text-white']}
                />
              </ProjectsCardIcon>
            </div>
          </div>
        </motion.div>
      )}
      {gitHubProjects !== undefined && gitHubProjects.length === 0 && !loading && (
        <motion.div
          variants={simpleFadeIn()}
          initial={'hidden'}
          animate={'show'}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex flex-col justify-center items-center text-center"
        >
          <div className="text-center flex flex-col items-center">
            <Typography
              classOverrides={['hidden md:block dark:text-gray-primary']}
              color="text-gray-secondary"
              size="xl"
            >
              {t('my_projects.dont_have_projects_yet')}
            </Typography>
            <div
              className="h-72 md:h-80 w-72 md:w-96 mb-20 md:mb-16"
              onClick={() => true}
            >
              <OutsideLink
                name={'GitHub repository creation'}
                to={`https://github.com/new`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardIcon
                  title={t('my_projects.card.title')}
                  text={t('my_projects.card.instructions.1')}
                >
                  <LinkProjectIcon
                    classOverrides={['text-secondary-default dark:text-white']}
                  />
                </CardIcon>
              </OutsideLink>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};
