import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { JobVersion } from '@jobs/models/JobVersion.model';
import { transformHighNumber } from './text.util';

/*
 * If a job has more than one version we return the second one which will always be the latest
 * that hasn't 'latest' as tag. If it only has one version we return it, which will be 'latest'
 */
export const returnLatestVersion = (versions: JobVersion[]): JobVersion => {
  return versions.length > 1 && versions[1] ? versions[1] : versions[0];
};

export const getJobUsageCounter = (job: JobResponse): string => {
  if (!job?.versions.some((version) => version.usageCounter > 0)) return '0';

  const usageCounter: number = job.versions
    .map((version) => (version.usageCounter ? version.usageCounter : 0))
    .reduce((result, item) => result + item, 0);

  return transformHighNumber(usageCounter);
};
