import { License } from '@jobs/components/LicenseSelectInput/LicenseSelectInput.component';
import { JobPolicy } from '@jobs/models/Job.model';
import { JobVersion } from '../models/JobVersion.model';

export enum Tier {
  Community = 'community',
  Official = 'official',
}
export interface IJobApi {
  id: number;

  name: string;

  description: string;

  repoUrl: string;
  /**
   * Complete path to reach the end folder for specified job
   */
  folderPath: string;

  ownerName: string;

  icon: string;

  /**
   * @example ["Kubernetes", "lint", "release"]
   */
  labels: string[];

  rateDown: number;

  /**
   * Better rendering rateDown property
   * @example +500
   * @example 423
   */
  rateDownPretty: string;

  rateUp: number;

  /**
   * Better rendering rateUp property
   * @example +500
   * @example 423
   */
  rateUpPretty: string;

  deprecated: boolean;

  verified: boolean;

  enabled: boolean;

  createdAt: string;

  updatedAt: string;

  versions: JobVersion[];

  tier: Tier;

  license: License;

  policy: JobPolicy;
}
