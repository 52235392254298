import { Mixpanel } from '@components/Mixpanel/Mixpanel.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import { Typography } from '@components/Typography/Typography.component';
import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '../../../../common/hooks/useQueryParam.hook';
import { useKratosLoginFlow } from '../../../../kratos/hooks/useKratosFlow.hook';
import { SignInForm } from '../../../components/SignInForm/SignInForm.component';
import { UserContext } from '../../../contexts/user.context';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { SignInThirdParty } from 'user/components/SignInThirdParty/SignInThirdParty.component';
import { useLocation } from 'react-router-dom';
import { isGitLabOrGitHubUrl } from '@utils/text.util';

export const SignInPage: React.FC = () => {
  const { t } = useTranslation();
  const trackSuccess = () => {
    mixpanel.track('[SignIn] - Successfully logged in with email/password');
  };
  const {
    action: { whoami },
  } = useContext(UserContext);
  const flowId = useQuery().get('flow');
  const [flow, submitHandler] = useKratosLoginFlow(flowId, () => {
    toastSuccess(t('toast.connected'));
    trackSuccess();
    whoami();
  });

  const location = useLocation().search.replace('?', '');
  useEffect(() => {
    if (!location) return;
    if (isGitLabOrGitHubUrl(location)) {
      const domain = new URL(location).hostname.replace('.com', '');
      sessionStorage.setItem('lightPipelineUrl', location);
      localStorage.setItem('projectsPlatform', domain);
    }
  });
  const trackRegister = () => {
    mixpanel.track('[SignIn] - Click on Register with an email link');
  };
  return (
    <Page title={t('signin.meta_title')} description={t('signin.description')}>
      <div className="bg-secondary-default text-center">
        <Title size={'h1'} color={'text-white'} bold>
          {t('signin_title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="container mx-auto flex justify-around flex-col md:flex-row mb-8">
        <div className="flex flex-col items-center w-10/12 md:w-5/12 lg:w-4/12 text-center mx-auto">
          <Title size={'h2'} bold color={'text-secondary-default'}>
            {t('signin.title_gitlab')}
          </Title>
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-white mt-6 w-72 md:w-96']}
            size="sm"
          >
            <Trans
              i18nKey="signin.terms_use"
              components={[
                '',
                <span className="font-bold" />,
                '',
                <TrackedLink
                  name={'terms of use'}
                  title={t('action:title.redirect_terms_of_use')}
                  to={'/termsofuse'}
                  className={'underline'}
                >
                  Terms of Use
                </TrackedLink>,
              ]}
            />
            <br />
            <Trans i18nKey="signup.data_storage">
              Your data will be stored on r2devops.io database, check our
              <TrackedLink
                name={'privacy policies'}
                to="/privacy"
                title={t('action:title.redirect_policies')}
                className={'underline dark:text-gray-primary'}
              >
                Privacy policies.
              </TrackedLink>
            </Trans>
          </Typography>
          <Mixpanel content={'[SignIn] - Click on sign in with GitLab'}>
            <SignInThirdParty flow={flow} />
          </Mixpanel>
        </div>
        <div className="hidden md:block h-80 border-secondary-default dark:border-gray-primary border-4 rounded-2xl mt-24 mb-8" />
        <div className="w-10/12 md:w-5/12 lg:w-4/12 mt-8 md:mt-0 text-center mx-auto">
          <Title size={'h2'} bold color={'text-secondary-default'}>
            {t('signin.title_credentials')}
          </Title>
          <div className="md:w-full xl:w-3/4 mx-auto">
            <SignInForm flow={flow} submitHandler={submitHandler} />
          </div>
          <div className="m-auto w-auto mt-8">
            <Typography
              size="sm"
              color="text-gray-secondary"
              classOverrides={['mx-auto mb-4 dark:text-gray-primary']}
            >
              <Trans i18nKey="signin.dont_have_account_yet">
                You don't have an account yet ?
                <TrackedLink
                  name={'signUp'}
                  to="/u/signup"
                  title={t('action:title.redirect_signup')}
                  className={'underline'}
                  onClick={trackRegister}
                >
                  Register with email
                </TrackedLink>
              </Trans>
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  );
};
