import {
  SearchProps,
  withSearch,
  WithSearchSubProps,
} from '@jobs/hoc/withSearch.component';
import React, { useEffect, useState } from 'react';
import { SearchJobsForm } from '@jobs/components/SearchJobsForm/SearchJobsForm';
import { cn } from '@utils/classes.util';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JobsSortBy, useJobs, UseJobsProps } from 'common/hooks/useJobs.hook';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { useOnVisible } from 'common/hooks/useOnVisible';
import { JobsExplorerCard } from '../JobsExplorerCard/JobsExplorerCard.component';
import {
  LG_SCREEN_SIZE,
  MD_SCREEN_SIZE,
  XL_SCREEN_SIZE,
  XXL_SCREEN_SIZE,
} from 'common/constants/style.constant';
import { Typography } from '@components/Typography/Typography.component';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { useLocation } from 'react-router-dom';
import Plausible from 'plausible-tracker';
import mixpanel from '@utils/mixpanel.util';
import { JobsExplorerRow } from '../JobsExplorerRow/JobsExplorerRow.component';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { JobsDisplayToggle } from '../JobsDisplayToggle/JobsDisplayToggle.component';
import { FiltersTab } from '../FiltersTab/FiltersTab.component';
import { FiltersMobileTab } from '../FiltersMobileTab/FiltersMobileTab.component';
import { SortMenu, SortType } from '../SortMenu/SortMenu.component';

type AllJobsFormSubmit = {
  searchValue: string;
};

type SearchQueryParams = SearchProps & {
  page: number;
};

export enum JobsDisplayType {
  CARD = 'card',
  ROW = 'row',
}
/**
 * This component should be used along with withSearch HOC
 * @param paramsValues
 * @param update
 */
export const SearchJobsContainerComponent: React.FC<WithSearchSubProps> = ({
  paramsValues,
  update,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const windowSize = useWindowSize();
  const [queryParams, setQueryParams] = useState<SearchQueryParams>({
    ...paramsValues,
    page: 1,
  });
  const [jobsFilter, setJobsFilter] = useState<Partial<UseJobsProps>>();
  const { jobs, count, fetchResult, loading } = useJobs({
    perPage: queryParams.per_page,
    page: queryParams.page,
    search: queryParams.search,
    ...jobsFilter,
  });
  const { trackEvent } = Plausible();
  const [jobsDisplay, setJobsDisplay] = useState<JobsDisplayType>(
    JobsDisplayType.CARD,
  );

  const [displayToggle, setDisplayToggle] = useState(true);
  const submitHandler = (values: AllJobsFormSubmit): void => {
    // Reset page to obtain first results on search
    setQueryParams((state) => ({
      ...state,
      search: values.searchValue,
      page: 1,
    }));
    trackEvent('Search a job');
    mixpanel.track('Search a job');
    update('search', values.searchValue);
  };
  const { control, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchValue: paramsValues.search,
      labels: [],
      tiers: [],
    },
  });

  /* Reset the search results and value inside the input if the user 
  click on the jobs explorer link again */
  useEffect(() => {
    if (location.search) return;
    if (!location.search) {
      setQueryParams((state) => ({
        ...state,
        search: '',
        page: 1,
      }));
      setValue('searchValue', '');
    }
  }, [location.search, setValue]);

  /* Track number of cards per line to show depending on width to determinde
  how many ghost cards to display */

  const getCardsPerLine = () => {
    if (!location.pathname.includes('dashboard')) {
      if (window.innerWidth < MD_SCREEN_SIZE) return 1;
      if (
        window.innerWidth > MD_SCREEN_SIZE &&
        window.innerWidth < XL_SCREEN_SIZE
      )
        return 2;
      if (window.innerWidth > XL_SCREEN_SIZE) return 3;
    } else {
      if (window.innerWidth < LG_SCREEN_SIZE) return 1;
      if (
        window.innerWidth > LG_SCREEN_SIZE &&
        window.innerWidth < XXL_SCREEN_SIZE
      )
        return 2;
      if (window.innerWidth > XXL_SCREEN_SIZE) return 3;
    }
  };
  const cardsPerLineToShow = getCardsPerLine();

  const lastElementRef = useOnVisible(() => {
    // When last job is visible, fetch more increasing the page number
    if (!fetchResult || jobs.length === count) return;
    // Check whether we have fetched all jobs from search
    if (jobs.length <= count) {
      // Add empty cards to display skeletons.
      setJobsCard([...jobsCard, ...Array(cardsPerLineToShow)]);

      setQueryParams((state) => ({
        ...state,
        page: state.page + 1,
      }));
    }
  });

  // The role of jobsCard is to display empty cards (skeleton) while loading data
  const [jobsCard, setJobsCard] = useState<JobResponse[]>([
    ...Array(cardsPerLineToShow),
  ]);

  useEffect(() => {
    if (!fetchResult?.jobs) return;
    setJobsCard(jobs);
  }, [jobs, fetchResult]);

  useEffect(() => {
    setQueryParams((state) => ({
      ...state,
      search: paramsValues.search,
      page: 1,
    }));
  }, [paramsValues]);

  useEffect(() => {
    if (windowSize.width >= LG_SCREEN_SIZE) {
      setDisplayToggle(true);
    } else {
      setJobsDisplay(JobsDisplayType.CARD);
      setDisplayToggle(false);
    }
  }, [windowSize.width]);

  // When coming from the footer 'Find a job' link we need to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const applyFilters = () => {
    if (!jobs) return;
    setJobsFilter({
      ...jobsFilter,
      labels: watch('labels') ?? undefined,
      tiers: watch('tiers') ?? undefined,
    });
  };

  const applySorting = (sortType: SortType) => {
    if (!jobs) return;
    if (sortType === SortType.DATE) {
      setJobsFilter({ ...jobsFilter, sortOrder: 'DESC', sortBy: undefined });
    } else if (sortType === SortType.RATING) {
      setJobsFilter({
        ...jobsFilter,
        sortOrder: 'DESC',
        sortBy: JobsSortBy.RateUp,
      });
    } else if (sortType === SortType.DEFAULT) {
      setJobsFilter({ ...jobsFilter, sortOrder: undefined, sortBy: undefined });
    }
  };

  const resetFilters = () => {
    setValue('labels', []);
    setValue('tiers', []);
    setJobsFilter({ ...jobsFilter, labels: undefined, tiers: undefined });
  };
  return (
    <>
      <div
        className={cn(
          'w-full relative flex  flex-col items-center justify-center',
          location.pathname.includes('dashboard')
            ? 'xl:flex-row'
            : 'lg:flex-row',
        )}
      >
        <div
          className={cn(
            'flex justify-center items-center',
            window.innerWidth < XL_SCREEN_SIZE && 'flex-col',
            location.pathname.includes('dashboard') &&
              window.innerWidth < LG_SCREEN_SIZE &&
              'flex-col',
          )}
        >
          <div className="lg:w-full sm:w-116">
            <SearchJobsForm
              classOverrides={['lg:mx-auto']}
              onSubmit={handleSubmit(submitHandler)}
              register={register}
              placeholder={t('action:search_jobs')}
            />
          </div>
          <FiltersMobileTab
            control={control}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
          />
        </div>
        <div
          className={cn(
            location.pathname.includes('dashboard')
              ? 'xl:absolute xl:flex-row lg:right-24'
              : 'lg:absolute lg:flex-row lg:right-0',
            'w-max right-2  2xl:right-44 top-14 lg:top-0 flex flex-col items-center',
          )}
        >
          {/* DISPLAY TYPE BUTTON */}
          {displayToggle && fetchResult && fetchResult.jobs.length > 0 && (
            <JobsDisplayToggle
              jobsDisplay={jobsDisplay}
              setJobsDisplay={setJobsDisplay}
            />
          )}
          {/* SORT BUTTONS */}
          {fetchResult && fetchResult.jobs.length > 0 && (
            <div className="flex flex-col-reverse items-center font-comfortaa text-sm lg:ml-2 lg:mt-2">
              <Typography
                classOverrides={[
                  cn(
                    'text-secondary-dark dark:text-gray-primary',
                    !loading ? '' : 'invisible',
                  ),
                ]}
              >
                {count > 1 || count === 0 ? `${count} Results` : `1 Result`}
              </Typography>
              <SortMenu applySorting={applySorting} />
            </div>
          )}
        </div>
      </div>
      {/* CARDS GRID */}
      {!fetchResult && <SpinnerIcon />}
      <div
        className={cn(
          'flex justify-center',
          count === 0 &&
            !paramsValues.search &&
            !watch('labels') &&
            !watch('tiers') &&
            'hidden',
          count === 0 && jobsCard.length === 0 && 'xl:pr-96 mr-96',
        )}
      >
        {
          <FiltersTab
            control={control}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
          />
        }
        <div
          className={cn(
            jobsDisplay === JobsDisplayType.CARD
              ? location.pathname.includes('dashboard')
                ? 'mt-6 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 w-max gap-9 lg:mx-7'
                : 'mt-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-max gap-9 lg:mx-7'
              : 'mt-6 w-5/6 lg:w-7/12 lg:mx-7',
          )}
        >
          {jobsCard.map((job, index) => (
            <TrackedLink
              name={`job card from jobs explorer`}
              to={
                location.pathname.includes('dashboard')
                  ? `/dashboard/_/${job?.ownerName}/${job?.name}`
                  : `/_/${job?.ownerName}/${job?.name}`
              }
              className="cursor-pointer transform hover:scale-105 transition-transform "
              key={index}
            >
              <span ref={jobs.length === index + 1 ? lastElementRef : null}>
                {jobsDisplay === JobsDisplayType.CARD ? (
                  <JobsExplorerCard uniqueTestId={index} job={job} />
                ) : (
                  <JobsExplorerRow index={index} job={job} />
                )}
              </span>
            </TrackedLink>
          ))}
        </div>
      </div>

      {jobs.length === count && (
        <div
          className={cn(
            'w-full text-center mt-12',
            count <= 3 && jobsDisplay === JobsDisplayType.CARD
              ? 'pb-16'
              : count <= 3 && 'pb-48',
          )}
          data-testid="advise-bottom-text"
        >
          <Typography
            classOverrides={['w-full dark:text-gray-primary']}
            color="text-secondary-default"
          >
            {t('hub.advise_bottom_text')}
          </Typography>
          <br />
          <TrackedLink to="/u/addjob" name="add a job">
            <PrimaryButton
              bgColor={'bg-secondary-default'}
              content={t('action:add_a_job')}
              classOverrides={['mx-auto xl:text-md mt-4']}
            />
          </TrackedLink>
        </div>
      )}
    </>
  );
};

export const SearchJobsContainer = withSearch(SearchJobsContainerComponent);
