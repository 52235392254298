import {
  SelfServiceSettingsFlow,
  SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import {
  KratosChangePwdFiltersIn,
  KratosChangePwdFiltersOut,
} from 'kratos/filters/changePwd.filter';
import { newKratosSdk } from 'kratos/helpers/kratos.helper';
import { useKratosFlow } from 'kratos/hooks/useKratosFlow.hook';
import { useState } from 'react';

/**
 * Generate a kratos change password flow
 * @param flowId -- In edge cases, a flow id is given
 * @param onSucceed
 * @see useKratosFlow
 */
export const useKratosChangePwdFlow = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceSettingsFlow,
    SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
    SelfServiceSettingsFlow
  >(
    flowId
      ? () => Sdk.getSelfServiceSettingsFlow(flowId)
      : Sdk.initializeSelfServiceSettingsFlowForBrowsers.bind(Sdk),
    (flow, submit, options) =>
      Sdk.submitSelfServiceSettingsFlow(flow, undefined, submit, options),
    KratosChangePwdFiltersIn,
    KratosChangePwdFiltersOut,
    onSucceed,
  );
};
