import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFoundThrow } from '@components/Errors/NotFound.component';
import { ProtectedRoute } from '@components/ProtectedRoute/ProtectedRoute';
import { RouteConditions } from '@typings/RouteConditions.type';
import { MyJobsContextProvider } from 'user/contexts/myJobs.context';
import { ProjectsContextProvider } from 'user/contexts/projects.context';
import { Jobs } from './PersonalSpace/Jobs.component';
import { Projects } from './PersonalSpace/Projects.component';
import { ProjectsContainer } from './PersonalSpace/ProjectsContainer.component';
import { SettingsPage } from './Settings.page';
import { ResourcesPage } from './Resources.page';
import { ProjectPage } from './Project.page';
import { LinkJobPage } from 'pages/LinkJob.page';
import { JobPage } from '@jobs/pages/Job.page';
import { DashBoardLoadingPage } from 'pages/DashBoardLoading.page';
import { Layout, LayoutEnum } from '@components/Page/Layout/Layout.component';

const UserSpaceConditions: RouteConditions = {
  isAuthenticated: true,
};

export type ProjectParams = {
  project: string;
  id: string;
};

export const MyJobs: React.FC = () => (
  <div data-testid="jobs" className="flex-grow">
    <MyJobsContextProvider>
      <Jobs />
    </MyJobsContextProvider>
  </div>
);

export const MyProjects: React.FC = () => (
  <div data-testid="projects" className="w-full">
    <ProjectsContextProvider>
      <ProjectsContainer>
        <Projects />
      </ProjectsContainer>
    </ProjectsContextProvider>
  </div>
);
const MyProjectPage: React.FC = () => (
  <div data-testid="project" className="w-full">
    <ProjectPage />
  </div>
);
const settingsRoute = [
  'profile',
  'account',
  'billing',
  'password',
  'notifications',
  'referral',
];
const resourcesRoute = ['jobs', 'hub'];
const DashboardRouter: React.FC = () => {
  let { path } = useRouteMatch();
  return (
    <Layout layoutType={LayoutEnum.DASHBOARD}>
      <Switch>
        <ProtectedRoute
          path={`${path}`}
          exact
          return_to={'/u/signin'}
          component={MyProjects}
          conditions={UserSpaceConditions}
        />
        <ProtectedRoute
          path={`${path}loading`}
          exact
          return_to={'/u/signin'}
          component={DashBoardLoadingPage}
          conditions={UserSpaceConditions}
        />
        <ProtectedRoute
          path={`${path}project/:id`}
          exact
          return_to={'/u/signin'}
          component={MyProjectPage}
          conditions={UserSpaceConditions}
        />
        <ProtectedRoute
          path={`${path}linkjob`}
          exact
          return_to={'/u/signin'}
          component={LinkJobPage}
          conditions={UserSpaceConditions}
        />
        {settingsRoute.map((route) => {
          return (
            <ProtectedRoute
              path={`${path}${route}`}
              key={route}
              exact
              return_to={'/u/signin'}
              component={SettingsPage}
              conditions={UserSpaceConditions}
            />
          );
        })}
        {resourcesRoute.map((route) => {
          return (
            <ProtectedRoute
              path={`${path}${route}`}
              key={route}
              exact
              return_to={'/u/signin'}
              component={ResourcesPage}
              conditions={UserSpaceConditions}
            />
          );
        })}
        <ProtectedRoute
          path={`${path}projects`}
          exact
          return_to={'/u/signin'}
          component={MyProjects}
          conditions={UserSpaceConditions}
        />
        <ProtectedRoute
          exact
          return_to={'/u/signin'}
          conditions={UserSpaceConditions}
          path={`${path}_/:owner/:job`}
          component={JobPage}
        />
        <Route path={'*'} component={NotFoundThrow} />
      </Switch>
    </Layout>
  );
};

export { DashboardRouter };
