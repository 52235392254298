import { CardIcon } from '@components/CardIcon/CardIcon.component';
import { AddDocumentIcon } from '@components/Icons/AddDocumentIcon/AddDocumentIcon.component';
import { PuzzleIcon } from '@components/Icons/PuzzleIcon/PuzzleIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { DOCUMENTATION_URL } from 'common/constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AddJobPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t('add_your_job.title')}
      navBarCutter
      description={t('add_your_job.description')}
    >
      <div className="my-12 text-center">
        <Title size={'h1'} color={'text-secondary-default'} bold>
          {t('action:add_a_job')}
        </Title>
      </div>
      <div className="flex lg:flex-row flex-col justify-evenly text-center items-center pb-16 xl:px-60">
        <div className="w-72 md:w-96">
          <TrackedLink to="/u/linkjob" name="link a job">
            <CardIcon
              title={t('add_your_job.card_title.0')}
              text={t('add_your_job.card_content.0')}
            >
              <AddDocumentIcon
                classOverrides={[
                  'text-secondary-default dark:text-gray-primary',
                ]}
              />
            </CardIcon>
          </TrackedLink>
        </div>
        <div className="w-72 md:w-96">
          <OutsideLink
            name="Documentation contribute"
            to={`${DOCUMENTATION_URL}/contribute`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardIcon
              title={t('add_your_job.card_title.1')}
              text={t('add_your_job.card_content.1')}
            >
              <PuzzleIcon
                classOverrides={[
                  'text-secondary-default dark:text-gray-primary',
                ]}
              />
            </CardIcon>
          </OutsideLink>
        </div>
      </div>
    </Page>
  );
};

export { AddJobPage };
