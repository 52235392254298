import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFoundThrow } from '@components/Errors/NotFound.component';
import { SignUpPage } from './auth/SignUp/SignUp.page';
import { SignUpSuccessful } from './auth/SignUp/SignUpSuccessful.page';
import { SignInPage } from './auth/SignIn/SignIn.page';
import { ProtectedRoute } from '@components/ProtectedRoute/ProtectedRoute';
import { RouteConditions } from '@typings/RouteConditions.type';
import { RecoveryPage } from './recovery/Recovery.page';
import { LinkJobPage } from 'pages/LinkJob.page';
import { VerifyPage } from './auth/Verify/Verify.page';
import { AddJobPage } from 'pages/AddJob.page';
import { Layout, LayoutEnum } from '@components/Page/Layout/Layout.component';

const AuthPagesConditions: RouteConditions = {
  isAuthenticated: false,
};

const UserSpaceConditions: RouteConditions = {
  isAuthenticated: true,
};

const UserRouter: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Layout
      layoutType={
        AuthPagesConditions.isAuthenticated
          ? LayoutEnum.DASHBOARD
          : LayoutEnum.STANDARD
      }
    >
      <Switch>
        <Route
          path={`${path}signup/successful`}
          exact
          component={SignUpSuccessful}
        />
        <ProtectedRoute
          component={RecoveryPage}
          path={`${path}recovery`}
          exact
          return_to={'/dashboard/projects'}
          conditions={AuthPagesConditions}
        />
        <ProtectedRoute
          path={`${path}signup`}
          exact
          return_to={'/dashboard/projects'}
          component={SignUpPage}
          conditions={AuthPagesConditions}
        />
        <ProtectedRoute
          path={`${path}signin`}
          exact
          return_to={'/dashboard/projects'}
          component={SignInPage}
          conditions={AuthPagesConditions}
        />
        <ProtectedRoute
          path={`${path}linkJob`}
          exact
          return_to={'/u/signin'}
          component={LinkJobPage}
          conditions={UserSpaceConditions}
        />
        <ProtectedRoute
          path={`${path}verify`}
          exact
          return_to={'/dashboard/projects'}
          component={VerifyPage}
          conditions={AuthPagesConditions}
        />
        <Route path={`${path}addjob`} component={AddJobPage} />
        <Route path={'*'} component={NotFoundThrow} />
      </Switch>
    </Layout>
  );
};

export { UserRouter };
