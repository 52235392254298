import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

/**
 * Returns an URLSearchParams object allowing
 * you to search for a specific field in query
 * @see useQueryParam
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * Returns a specific query param value
 * @param {string} item
 */
export const useQueryParam = (item: string): string | null => {
  const query = useQuery();
  const [param, setParam] = useState<string | null>(null);

  useEffect(() => {
    const value = query.get(item);
    if (value && value !== param) setParam(value);
  }, [query, param, item]);

  return param;
};
