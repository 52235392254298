import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TomorrowFeaturesRow } from 'user/components/TomorrowFeaturesRow/TomorrowFeaturesRow.component';
import R2_sherlock from '@images/R2/R2_sherlock.png';
import { motion } from 'framer-motion';
import i18n from 'common/config/i18n';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { getRandomTransformOrigin, shake } from '@utils/motion.util';

type FeatureRow = {
  title: string | undefined;
  content: string | undefined;
};

export const RoadmapPage: React.FC = () => {
  const { t } = useTranslation();
  const [tomorrowFeatureRows, setTomorrowFeatureRows] = useState<FeatureRow[]>(
    [],
  );
  const [todayFeatureRows, setTodayFeatureRows] = useState<FeatureRow[]>([]);

  useEffect(() => {
    const todayFeatures: FeatureRow[] = [];
    const tomorrowFeatures: FeatureRow[] = [];

    let i = 0;
    while (i18n.exists('roadmap.tomorrow_rows.title.' + i)) i++;
    for (let j = 0; j < i; j++) {
      tomorrowFeatures.push({
        title: i18n.exists('roadmap.tomorrow_rows.title.' + j)
          ? t('roadmap.tomorrow_rows.title.' + j)
          : undefined,
        content: i18n.exists('roadmap.tomorrow_rows.content.' + j)
          ? t('roadmap.tomorrow_rows.content.' + j)
          : undefined,
      });
    }
    let y = 0;
    while (i18n.exists('roadmap.today_rows.title.' + y)) y++;
    for (let b = 0; b < y; b++) {
      todayFeatures.push({
        title: i18n.exists('roadmap.today_rows.title.' + b)
          ? t('roadmap.today_rows.title.' + b)
          : undefined,
        content: i18n.exists('roadmap.today_rows.content.' + b)
          ? t('roadmap.today_rows.content.' + b)
          : undefined,
      });
    }
    setTomorrowFeatureRows(tomorrowFeatures);
    setTodayFeatureRows(todayFeatures);
  }, [t]);

  const featureForm = useRef<HTMLDivElement>(null);
  const openFeatureForm = () => {
    if (!featureForm || !featureForm.current) return;
    featureForm?.current?.click();
  };

  useEffect(() => {
    setTimeout(() => {
      openFeatureForm();
    }, 3000);
  }, []);

  return (
    <Page title={t('roadmap.page_title')} classOverrides={['pb-8']}>
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('roadmap.page_title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="text-center mt-8 mx-12">
        <Title
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['my-4']}
          font="comfortaa"
          bold
        >
          {t('roadmap.catch_line')}
        </Title>
        <Typography
          color={'text-gray-secondary'}
          light
          classOverrides={[' dark:text-gray-primary']}
        >
          {t('roadmap.introduction.1')}
        </Typography>
      </div>
      <div className="flex flex-col xl:flex-row justify-center items-center mt-14 lg:mx-16 2xl:mx-64">
        <div className="relative w-full sm:w-5/6 px-12 lg:px-36 xl:px-0 xl:w-6/12 2xl:w-5/12 flex items-center flex-col">
          <Title
            bold
            color={'text-secondary-default'}
            size={'h2'}
            classOverrides={['text-center mb-4 text-2xl']}
          >
            {t('roadmap.content_titles.0')}
          </Title>
          {todayFeatureRows.map((row, index) => {
            return (
              <TomorrowFeaturesRow
                title={row.title}
                content={row.content}
                key={row.title + '-' + index}
                titleClassOverrides={['font-black']}
              />
            );
          })}
          <Title
            bold
            color={'text-secondary-default'}
            size={'h2'}
            classOverrides={['text-center mt-6 mb-4 text-2xl']}
          >
            {t('roadmap.content_titles.1')}
          </Title>
          {tomorrowFeatureRows.map((row, index) => {
            return (
              <TomorrowFeaturesRow
                title={row.title}
                content={row.content}
                key={row.title + '-' + index}
                titleClassOverrides={['font-black']}
              />
            );
          })}
        </div>
      </div>
      <div className="sticky bottom-8 mb-4 right-0 float-right mr-6">
        <Tooltip
          multiline={false}
          title={t('roadmap.r2.tooltip')}
          colorSet={ColorSetEnum.success}
          autoShow
        >
          <motion.div
            animate={shake()}
            style={{
              ...getRandomTransformOrigin(),
            }}
            className="w-max cursor-pointer r2-chat"
          >
            <div
              className="opacity-80 hover:opacity-100 rounded-full bg-green-pearl w-20 h-20 shadow-lg hover:shadow-button-inner transition duration-500 flex"
              data-tally-open="w8DqPw"
              data-tally-hide-title="1"
              data-tally-emoji-text="👋"
              data-tally-emoji-animation="wave"
              ref={featureForm}
            >
              <motion.img
                src={R2_sherlock}
                alt={t('roadmap.r2.alt')}
                className="pb-2 pl-0.5"
              />
            </div>
          </motion.div>
        </Tooltip>
      </div>
    </Page>
  );
};
