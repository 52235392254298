// Styled text area

import React, { TextareaHTMLAttributes } from 'react';
import {
  getInputClasses,
  InputError,
  InputLabel,
  InputProps,
} from '../StyledField/StyledField.component';

/**
 * Create a styled textarea for a form
 *
 * We omit `className` so we don't override current hard coded className
 * @param classOverrides
 * @param {UseFormRegisterReturn} register -- Register method from useForm hook
 * @param {string} error -- Define an error for an input, show a little hint below with it
 * @param {string} label -- Label above input
 * @param {Omit<TextareaHTMLAttributes<HTMLInputElement>, "className">} rest -- HTML attributes
 * @see InputHTMLAttributes
 */
export const StyledTextarea: React.FC<
  InputProps<TextareaHTMLAttributes<HTMLTextAreaElement>>
> = ({
  bgColor,
  borderColor = 'border-none',
  register,
  classOverrides = [],
  rows = 9,
  error,
  label,
  placeholderColor = 'placeholder-input-field',
  value: defaultValue,
  ...rest
}) => {
  return (
    <>
      <InputLabel label={label} classOverrides={['dark:text-gray-primary']} />
      <textarea
        aria-label={label}
        className={getInputClasses(
          bgColor ? bgColor : 'bg-white',
          'rounded-lg',
          borderColor,
          Boolean(error),
          placeholderColor,
          ...classOverrides,
        )}
        defaultValue={defaultValue}
        id={label}
        rows={rows}
        {...rest}
        {...register}
      />
      <InputError error={error} label={label} />
    </>
  );
};
