import { JobPolicy } from '@jobs/models/Job.model';
import {
  JobCreationResponse,
  JobResponse,
} from '@jobs/models/JobCreationResponse.model';
import React, { createContext, useState } from 'react';

interface IMyJobsContext {
  jobToModify?: JobResponse;
  setJobToModify?: (job: JobResponse) => void;
  showModifyJobForm: boolean;
  setShowModifyJobForm?: React.Dispatch<React.SetStateAction<boolean>>;
  jobsToDisplay: JobResponse[];
  setJobsToDisplay?: React.Dispatch<React.SetStateAction<JobResponse[]>>;
  filterJobPolicy: JobPolicy | '';
  setFilterJobPolicy?: React.Dispatch<React.SetStateAction<JobPolicy | ''>>;
  jobAnalysisToDisplay?: JobCreationResponse;
  setJobAnalysisToDisplay?: (job: JobCreationResponse) => void;
  showVersionsReport?: boolean;
  setShowVersionsReport?: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfVersionsUpdated?: number;
  setNumberOfVersionsUpdated?: React.Dispatch<React.SetStateAction<number>>;
}

export const MyJobsContext = createContext<IMyJobsContext>({
  jobsToDisplay: [],
  showModifyJobForm: false,
  showVersionsReport: false,
  filterJobPolicy: '',
});

export const MyJobsContextProvider: React.FC = ({ children }) => {
  const [jobToModify, setJobToModify] = useState<JobResponse>();
  const [showModifyJobForm, setShowModifyJobForm] = useState(false);
  const [jobsToDisplay, setJobsToDisplay] = useState<JobResponse[]>([]);
  const [filterJobPolicy, setFilterJobPolicy] = useState<JobPolicy | ''>('');
  const [numberOfVersionsUpdated, setNumberOfVersionsUpdated] = useState(0);
  const [jobAnalysisToDisplay, setJobAnalysisToDisplay] =
    useState<JobCreationResponse>();
  const [showVersionsReport, setShowVersionsReport] = useState(false);
  return (
    <MyJobsContext.Provider
      value={{
        filterJobPolicy,
        setFilterJobPolicy,
        jobToModify,
        setJobToModify,
        showModifyJobForm,
        setShowModifyJobForm,
        jobsToDisplay,
        setJobsToDisplay,
        jobAnalysisToDisplay,
        setJobAnalysisToDisplay,
        showVersionsReport,
        setShowVersionsReport,
        numberOfVersionsUpdated,
        setNumberOfVersionsUpdated,
      }}
    >
      {children}
    </MyJobsContext.Provider>
  );
};
