import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const ProjectsIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '24',
  width = '24',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentcolor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <rect x="4" y="4" width="6" height="6" rx="1"></rect>
      <rect x="4" y="14" width="6" height="6" rx="1"></rect>
      <rect x="14" y="14" width="6" height="6" rx="1"></rect>
      <line x1="14" y1="7" x2="20" y2="7"></line>
      <line x1="17" y1="4" x2="17" y2="10"></line>
    </svg>
  );
};
