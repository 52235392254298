import { BarsIcon } from '@components/Icons/BarsIcon/BarsIcon.component';
import { GridIcon } from '@components/Icons/GridIcon/GridIcon.component';
import { cn } from '@utils/classes.util';
import { JobsDisplayType } from '../SearchJobsContainer/SearchJobsContainer';

type Props = {
  jobsDisplay: JobsDisplayType;
  setJobsDisplay: React.Dispatch<React.SetStateAction<JobsDisplayType>>;
};

export const JobsDisplayToggle: React.FC<Props> = ({
  jobsDisplay,
  setJobsDisplay,
}) => {
  return (
    <div className="bg-gray-primary dark:bg-secondary-default-high-opacity rounded-lg flex cursor-pointer transition-all duration-300 mb-2 lg:mb-0 lg:mr-2 shadow-lg">
      <div
        onClick={() => {
          setJobsDisplay(JobsDisplayType.CARD);
        }}
        className={cn(
          'w-10 rounded-lg p-2',
          jobsDisplay === JobsDisplayType.CARD
            ? 'bg-secondary-default'
            : 'bg-transparent',
        )}
      >
        <GridIcon
          color={
            jobsDisplay === JobsDisplayType.CARD
              ? 'text-gray-primary'
              : 'text-secondary-default dark:text-dark-primary'
          }
        />
      </div>
      <div
        onClick={() => {
          setJobsDisplay(JobsDisplayType.ROW);
        }}
        className={cn(
          'w-10 rounded-lg p-2',
          jobsDisplay === JobsDisplayType.ROW
            ? 'bg-secondary-default'
            : 'bg-transparent',
        )}
      >
        <BarsIcon
          color={
            jobsDisplay === JobsDisplayType.ROW
              ? 'text-gray-primary'
              : 'text-secondary-default dark:text-dark-primary'
          }
        />
      </div>
    </div>
  );
};
