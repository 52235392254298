import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { JobDescription } from '@components/JobDescription/JobDescription';
import { JobIcon } from '@components/JobIcon/JobIcon';
import { JobRating } from '@components/JobRating/JobRating';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { getJobUsageCounter } from '@utils/jobs.util';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { CardLabelSlider } from '../CardLabelSlider/CardLabelSlider.component';
import { JobVersionTag } from './JobVersionTag.component';

type Props = ClassOverrides & {
  /** uniqueTestId - Unique key in order to test childrens of the component */
  uniqueTestId?: number;
  job?: JobResponse;
};

export const JobsExplorerCard: React.FC<Props> = ({
  classOverrides = [],
  uniqueTestId = 0,
  job,
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const baseColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.backgroundColor
      : undefined;
  const highlightColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.color
      : undefined;
  const usageCounter =
    job?.versions.some((version) => version.usageCounter > 0) &&
    getJobUsageCounter(job);

  return (
    <div
      className={cn(
        'w-72 pt-3 px-6 pb-1 relative rounded-2lg font-comfortaa',
        'bg-secondary-semi-light text-secondary-dark dark:bg-secondary-default-high-opacity dark:text-gray-primary filter shadow-lg relative h-48',
        ...classOverrides,
      )}
      data-testid={'job-explorer-card-' + uniqueTestId}
    >
      <div className="flex flex-col justify-between h-full overflow-hidden">
        {/* ICON, TITLE AND DESCRIPTION*/}
        <div className="flex items-center flex-start">
          {job?.icon ? (
            <div
              className="absolute top-3 left-3 border-2 border-gray-input rounded-full w-10 h-10"
              data-testid={'job-icon-' + uniqueTestId}
            >
              <JobIcon classOverrides={['text-xl ']} icon={job.icon} />
            </div>
          ) : (
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              circle
              height="100%"
              width="100%"
              containerTestId={'job-icon-skeleton-' + uniqueTestId}
              containerClassName="absolute avatar-skeleton  w-10 h-10 "
            />
          )}
          <div className="flex flex-col items-center w-full">
            <div className="text-xs">
              {job?.ownerName ? (
                job.ownerName
              ) : (
                <Skeleton
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  width={30}
                  containerClassName="ml-12 md:ml-0"
                  containerTestId={'job-owner-skeleton-' + uniqueTestId}
                />
              )}
            </div>
            <Tooltip
              title={job?.name}
              colorSet={
                theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
              }
            >
              <Title
                bold
                color={
                  theme === 'light'
                    ? 'text-secondary-dark'
                    : 'text-gray-primary'
                }
                size={'h3'}
                classOverrides={['w-full text-center justify-center text-xl']}
              >
                {job?.name ? (
                  <div
                    className={cn(
                      'truncate max-w-10 -mt-0.5',
                      job?.name && job?.name.length >= 14 && 'pl-2',
                    )}
                  >
                    {job.name}
                  </div>
                ) : (
                  <Skeleton
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    containerTestId={'job-name-skeleton-' + uniqueTestId}
                    containerClassName="ml-6 md:ml-0"
                  />
                )}
              </Title>
            </Tooltip>

            <div className="text-xs">
              {job?.versions ? (
                <JobVersionTag
                  uniqueTestId={uniqueTestId}
                  versions={job.versions}
                />
              ) : (
                <Skeleton
                  width={30}
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  containerClassName="ml-12 md:ml-0"
                  containerTestId={'job-version-skeleton-' + uniqueTestId}
                />
              )}
            </div>
          </div>

          {/* RATING */}
          {typeof job?.rateUp !== 'undefined' &&
          typeof job?.rateDown !== 'undefined' ? (
            job.policy !== 'private' && (
              <JobRating
                classOverrides={[' text-xs absolute right-4 top-4']}
                rateUp={job.rateUp}
                rateDown={job.rateDown}
              />
            )
          ) : (
            <Skeleton
              count={2}
              baseColor={baseColor}
              highlightColor={highlightColor}
              className="mr-1"
              inline
              width={50}
              containerTestId={'job-ratings-skeleton-' + uniqueTestId}
              containerClassName="hidden ml-6 justify-self-end "
            />
          )}
          {job?.policy === 'private' && (
            <FeatureFlag name="private-job">
              <div
                className="absolute right-4 top-1 h-12"
                data-testid={'lockIcon-' + job.name}
                title={t('my_jobs.modify_your_job.policy.public.tooltip')}
              >
                <LockIcon
                  width="20"
                  classOverrides={[
                    'text-primary-default dark:text-gray-primary max-h-full',
                  ]}
                />
              </div>
            </FeatureFlag>
          )}
        </div>
        {!job ? (
          <Skeleton
            containerClassName="w-full mt-4"
            baseColor={baseColor}
            highlightColor={highlightColor}
            count={2}
            containerTestId={'job-description-skeleton-' + uniqueTestId}
          />
        ) : (
          <div className="w-full items-center flex justify-center text-center py-3  text-secondary-dark dark:text-gray-primary">
            <JobDescription
              description={
                job?.description ? job.description : t('job.no_description')
              }
              classOverrides={['line-clamp-2 text-sm']}
            />
          </div>
        )}
        {/* LABELS */}
        <div className="flex mb-3 mx-auto">
          {job?.labels && job?.tier ? (
            <div className="flex justify-center items-center ">
              <CardLabelSlider
                isDeprecated={job.deprecated}
                labels={job.labels}
                tier={job.tier}
                labelClass="text-xs"
                usedFromCard
              />
            </div>
          ) : (
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              count={3}
              containerTestId={'job-labels-skeleton-' + uniqueTestId}
              className="mr-1"
              inline
              containerClassName="flex justify-beetwen w-full"
            />
          )}
        </div>

        {/* LAST UPDATED */}
        <div
          className={cn(
            'text-xs flex',
            usageCounter ? 'justify-between' : 'justify-center',
          )}
        >
          {job?.updatedAt ? (
            <span>
              {t('home.section_jobs.lastUpdated')}
              &nbsp;
              {new Date(job.updatedAt).toLocaleDateString()}
            </span>
          ) : (
            <Skeleton
              baseColor={baseColor}
              highlightColor={highlightColor}
              count={2}
              containerTestId={'job-update-skeleton-' + uniqueTestId}
              inline
              className=""
              containerClassName="flex justify-between w-full"
            />
          )}

          <span className="block" data-testid="jobs-explorer-usage-counter">
            {usageCounter ? (
              usageCounter + ' ' + t('hub.usage_counter_title')
            ) : (
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                containerClassName="w-1/4 md:w-full"
                containerTestId={'job-usages-skeleton-' + uniqueTestId}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
