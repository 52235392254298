import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpForm } from '../../../components/SignUpForm/SignUpForm.component';

export const SignUpPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('signup.meta_title')} description={t('signup.description')}>
      <div className="bg-secondary-default text-center">
        <Title size={'h1'} color={'text-white'} bold>
          {t('signup.title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="w-5/6 md:w-4/6 2xl:w-3/6 mx-auto text-center mt-12 mb-32">
        <SignUpForm />
      </div>
    </Page>
  );
};
