import { cn } from '@utils/classes.util';
import React, { InputHTMLAttributes } from 'react';
import { InputProps } from '../StyledField/StyledField.component';

export const StyledCheckboxInput: React.FC<
  InputProps<
    InputHTMLAttributes<HTMLInputElement> & {
      defaultChecked?: boolean;
      itemsPlacement?: string;
    }
  >
> = ({
  classOverrides = [],
  defaultChecked,
  label,
  register,
  itemsPlacement = 'items-center',
  ...rest
}) => {
  return (
    <label
      className={cn(
        'flex text-gray-secondary dark:text-gray-primary font-comfortaa cursor-pointer hover:opacity-90',
        ...classOverrides,
        itemsPlacement,
      )}
      htmlFor={label}
    >
      <input
        className={cn(
          'w-5 h-5 mr-2 mb-0.5 rounded-md appearance-none border-none',
          'cursor-pointer text-primary-default bg-gray-primary transition-colors checked:bg-primary-default hover:opacity-70 hover:bg-primary-default',
          'filter shadow-checkbox checked:shadow-button-inner-outer checked:bg-none',
        )}
        id={label}
        type="checkbox"
        defaultChecked={defaultChecked}
        {...register}
        {...rest}
      />
      {label}
    </label>
  );
};
