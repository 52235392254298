import { useContext } from 'react';
import {
  GITHUB_API_PROJECTS_URL,
  GITLAB_API_PROJECTS_URL,
} from 'common/constants/routes';
import { IApiResponse } from '@typings/http.type';
import { components } from '@octokit/openapi-types';
import { LightPipelineConfig } from '../useLightPipelineGenerator/useLightPipelineGenerator.hook';
import { HttpContext } from 'common/contexts/http.context';
export interface GitLabProjectResponse {
  id: number;
  name: string;
  name_with_namespace: string;
  description: string;
  projectUrl: string;
  default_branch: string;
  ownerName: string;
  createdAt: string;
  updatedAt: string;
  forks_count: number;
  star_count: number;
  web_url: string;
  avatar_url: string;
}
type ReturnType = [
  (
    autoPipelineConfig: LightPipelineConfig,
  ) =>
    | Promise<IApiResponse<GitLabProjectResponse>>
    | Promise<IApiResponse<GitHubRepository>>,
];

export type GitHubRepository = components['schemas']['minimal-repository'];

export const useGitRepo = (): ReturnType => {
  const httpService = useContext(HttpContext);

  const getGitlabRepo = async (
    autoPipelineConfig: LightPipelineConfig,
  ): Promise<IApiResponse<GitLabProjectResponse>> => {
    const formatedRepoUrl = new URL(
      autoPipelineConfig.projectUrl,
    ).pathname.replace('/', '');
    return httpService.fetch<GitLabProjectResponse>(
      'GET',
      GITLAB_API_PROJECTS_URL + encodeURIComponent(formatedRepoUrl),
      {
        withCredentials: false,
      },
    );
  };

  const getGitHubRepo = async (autoPipelineConfig: LightPipelineConfig) => {
    let pathNameSplitted = new URL(
      autoPipelineConfig.projectUrl,
    ).pathname.split('/');

    return httpService.fetch<GitHubRepository>(
      'GET',
      GITHUB_API_PROJECTS_URL + `${pathNameSplitted[1]}/${pathNameSplitted[2]}`,
      {
        withCredentials: false,
      },
    );
  };

  const getGitRepo = (autoPipelineConfig: LightPipelineConfig) => {
    if (autoPipelineConfig.platform === 'gitlab') {
      return getGitlabRepo(autoPipelineConfig);
    } else {
      return getGitHubRepo(autoPipelineConfig);
    }
  };
  return [getGitRepo];
};
