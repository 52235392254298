import {
  faDiscord,
  faGitlab,
  faTwitter,
  faYoutube,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DISCORD_FOOTER_LINK,
  GITLAB_HUB_LINK,
  // LINKEDIN_LINK,
  TWITTER_LINK,
  YOUTUBE_CHANNEL_LINK,
} from 'common/constants/general';
import { useTranslation } from 'react-i18next';
import { Mixpanel } from '@components/Mixpanel/Mixpanel.component';

type IconInfo = {
  link: string;
  title: string;
  icon: IconDefinition;
};

export const FooterSocialNetwork: React.FC = () => {
  const { t } = useTranslation();

  const socialMediaLinks: Array<IconInfo> = [
    {
      link: TWITTER_LINK,
      title: t('action:title.redirect_twitter'),
      icon: faTwitter,
    },
    {
      link: DISCORD_FOOTER_LINK,
      title: t('action:title.redirect_discord'),
      icon: faDiscord,
    },
    {
      link: GITLAB_HUB_LINK,
      title: t('action:title.redirect_gitlab'),
      icon: faGitlab,
    },
    {
      link: YOUTUBE_CHANNEL_LINK,
      title: t('action:title.redirect_youtube'),
      icon: faYoutube,
    },
  ];
  return (
    <div className="flex justify-center flex-nowrap space-x-8 md:w-60 lg:w-96 md:absolute xl:left-24 md:left-12">
      {socialMediaLinks.map((mediaLink, index) => (
        <Mixpanel
          content={`Footer - ${mediaLink.title}`}
          key={`${mediaLink.title}-${index}`}
        >
          <a title={mediaLink.title} href={mediaLink.link} rel="noreferrer">
            <FontAwesomeIcon
              icon={mediaLink.icon}
              className="text-white text-3xl"
            />
          </a>
        </Mixpanel>
      ))}
    </div>
  );
};
