import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const RefreshIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '20',
  width = '20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <path
        d="M5.04137 16.1477C4.21714 15.4037 3.62255 14.5117 3.23299 13.5539C2.19963 11.0136 2.68351 7.99231 4.70102 5.90825L6.56271 7.77445C6.66522 7.87721 6.84975 7.80322 6.87436 7.65113L7.89131 0.97968C7.90772 0.868695 7.8134 0.774152 7.70268 0.790594L1.04325 1.81002C0.891529 1.83468 0.817718 2.01965 0.920234 2.12242L2.78602 3.99273C-0.293554 7.14964 -0.818436 11.885 1.20318 15.5845C1.81007 16.6985 2.6507 17.7179 3.72097 18.5729C5.54165 20.0239 7.7806 20.7186 10.0031 20.6734L10.4214 17.9317C8.50641 18.0961 6.5381 17.5042 5.04137 16.1477Z"
        fill="currentColor"
      />
      <path
        d="M17.4244 17.796C20.504 14.6391 21.0289 9.90374 19.0073 6.20423C18.4004 5.09027 17.5598 4.07085 16.4895 3.21585C14.6688 1.76482 12.4299 1.07014 10.2073 1.11535L9.78906 3.8571C11.7 3.69268 13.6724 4.2846 15.1691 5.64108C15.9933 6.3851 16.5879 7.27709 16.9775 8.23485C18.0108 10.7752 17.527 13.7964 15.5094 15.8805L13.6478 14.0143C13.5452 13.9115 13.3607 13.9855 13.3361 14.1376L12.3192 20.8091C12.3028 20.92 12.3971 21.0146 12.5078 20.9981L19.1672 19.9787C19.3189 19.9541 19.3886 19.7691 19.2902 19.6663L17.4244 17.796Z"
        fill="currentColor"
      />
    </svg>
  );
};
