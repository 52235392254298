import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';

type Props = {
  title: string;
  linkTo: string;
  isRoute?: boolean;
};

export const FooterLink: React.FC<Props> = ({
  title,
  linkTo,
  isRoute = false,
}) => {
  return (
    <Typography
      color="text-white"
      font={'ubuntu'}
      classOverrides={['py-3 md:py-0.5']}
    >
      {!isRoute ? (
        <TrackedLink
          name={title}
          to={linkTo}
          className="hover:opacity-70 text-white"
        >
          {title}
        </TrackedLink>
      ) : (
        <OutsideLink
          name={title}
          to={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-70 text-white"
        >
          {title}
        </OutsideLink>
      )}
    </Typography>
  );
};
