import React from 'react';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { useTranslation } from 'react-i18next';
import { LinkJobForm } from '@jobs/components/LinkJobForm/LinkJobForm.component';
import { useKratosSettingsFlowWithOidcMethod } from 'kratos/flows/settings.flow';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import { UiNodeInputAttributes } from '@ory/kratos-client';
import { Redirect } from 'react-router';
import { toastWarning } from '@components/Toasters/Toaster.component';

const LinkJobPage: React.FC = () => {
  const { t } = useTranslation();
  const [flow] = useKratosSettingsFlowWithOidcMethod(null);

  const linkUnlinkNode = flow?.ui.nodes.find(
    (t) =>
      isUiNodeInputAttributes(t.attributes) &&
      ((t.attributes.name === 'link' && t.attributes.value === 'gitlab') ||
        (t.attributes.name === 'unlink' && t.attributes.value === 'gitlab')),
  );

  const linkUnlinkNodeName = linkUnlinkNode
    ? (linkUnlinkNode?.attributes as UiNodeInputAttributes).name
    : '';

  const isAccountLinked =
    linkUnlinkNodeName === 'unlink' || linkUnlinkNode === undefined;

  if (!isAccountLinked) {
    toastWarning(t('toast.linkRequiredToAddJob'), {
      toastId: 'link required toast',
    });
    return <Redirect push to="/dashboard/account" />;
  }

  return (
    <Page
      title={t('link_job.primary_title')}
      description={t('link_job.description')}
      noNav
    >
      <div className="text-center">
        <Title
          size={'h1'}
          color={'text-secondary-default'}
          classOverrides={['mb-2']}
          bold
        >
          {t('link_job.primary_title')}
        </Title>
        <Title
          size={'h2'}
          color={'text-secondary-default'}
          classOverrides={['my-4']}
          bold
        >
          {t('link_job.secondary_title')}
        </Title>
      </div>
      <LinkJobForm />
    </Page>
  );
};

export { LinkJobPage };
