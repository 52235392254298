import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const KebabIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '20',
  width = '20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(color, ...classOverrides)}
    >
      <circle cx="3" cy="3" r="3" fill="currentColor" />
      <circle cx="3" cy="13" r="3" fill="currentColor" />
      <circle cx="3" cy="23" r="3" fill="currentColor" />
    </svg>
  );
};
