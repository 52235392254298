import React from 'react';
import GitHubWhite from '@images/Github_white.svg';
import GitHub from '@images/GitHub-Mark.svg';
import { ClassOverrides, Sizing } from '@typings/ClassOverrides.type';
import { useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';

type Props = {
  white?: boolean;
};

export const GitHubIcon: React.FC<Props & Sizing & ClassOverrides> = ({
  classOverrides = [],
  width = 'auto',
  height = 'auto',
  white = false,
}) => {
  const { t } = useTranslation();
  return (
    <img
      src={white ? GitHubWhite : GitHub}
      alt={t('github_alt')}
      className={cn(...classOverrides)}
      width={width}
      height={height}
    />
  );
};
