import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { UserContext } from '../../../../user/contexts/user.context';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { useHistory } from 'react-router-dom';

type Props = {
  profileImage: string;
};

/**
 * Part of global navbar where the userprofile picture is shown & a menu is available (logout/settings)
 */
export const NavBarUserProfile: React.FC<Props> = ({ profileImage }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const { action } = useContext(UserContext);
  const history = useHistory();

  const menuRef = useRef(null);
  const onClickOutside = useCallback(() => {
    if (openMenu) setOpenMenu(!openMenu);
  }, [openMenu]);
  useOnClickOutside(menuRef, onClickOutside);

  useEffect(() => {
    history.listen(() => {
      setOpenMenu(false);
    });
  }, [history]);

  return (
    <div className="col-span-2 inline-block h-24 mt-4" ref={menuRef}>
      <div className="relative w-12 h-12 mr-4 z-50 cursor-pointer transition duration-300 filter hover:grayscale hover:contrast-100">
        <img
          src={profileImage}
          alt={t('identity.profile_pic_alt')}
          title={t('identity.profile_pic_alt')}
          className={
            'filter drop-shadow-button-outer rounded-3xl border hover:border-gray-secondary transition duration-300 border-gray-primary p-0.5 w-full h-full'
          }
          onClick={() => setOpenMenu(!openMenu)}
        />
      </div>
      <AnimatePresence exitBeforeEnter>
        {openMenu && (
          <motion.div
            variants={{
              hidden: {
                width: 0,
                height: 0,
                y: -20,
                x: '-3rem',
                opacity: 0,
              },
              show: {
                width: '8rem',
                height: 'auto',
                y: 0,
                opacity: 1,
                transition: {
                  staggerChildren: 0.01,
                },
              },
            }}
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            className={
              'origin-top-right absolute mt-1 right-0 mr-3 w-32 text-center rounded-md shadow-lg bg-white dark:bg-dark-secondary dark:opacity-40 ring-1 ring-black ring-opacity-5 focus:outline-none'
            }
          >
            <ul className="text-secondary-default dark:text-gray-primary w-full">
              {/* <TrackedLink
                name={'NavBar - Profile menu - Settings '}
                to={'/_/addjob'}
              >
                <li
                  className="py-2 hover:bg-gray-100 dark:hover:bg-secondary-default-high-opacity transition duration-300 rounded-md"
                  title={t('action:add_job')}
                >
                  {t('action:add_job')}
                </li>
              </TrackedLink>
              <div className="w-full border-2 border-gray-primary dark:border-secondary-default " /> */}
              <li
                className="py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-secondary-default-high-opacity transition duration-300 rounded-md"
                onClick={action.logout}
                title={t('action:logout')}
              >
                {t('action:logout')}
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
