import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import {
  github,
  solarizedDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import mixpanel from '@utils/mixpanel.util';
import Plausible from 'plausible-tracker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { GitHubRepository } from 'common/hooks/useGitHubProjects.hook';
import { Modal } from '@components/Modal/Modal.component';
import { AutoPipelineResponseType } from 'user/hooks/useOnProjectPipeline.hook';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { PlatformToggle } from '../PlatformToggle/PlatformToggle.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { GitLabAutoPipeline } from '../GitLabAutoPipeline/GitLabAutoPipeline.component';
import { GitHubAutoPipeline } from '../GitHubAutoPipeline/GitHubAutoPipeline.component';
import { determineIfIsGitLabOrGitHub } from '@utils/object.util';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { SM_SCREEN_SIZE } from 'common/constants/style.constant';
import { useHistory } from 'react-router-dom';
import { ProjectsContext } from 'user/contexts/projects.context';

type Props = {
  fetchResult: AutoPipelineResponseType;
  project: GitLabProjectResponse | GitHubRepository;
  withWrite: boolean;
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AutoPipelineResponse: React.FC<Props> = ({
  fetchResult,
  project,
  withWrite,
  setShowResponseModal,
  showResponseModal,
}) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const { theme } = useContext(ThemeContext);
  const { trackEvent } = Plausible();
  const [gitHubPipeline, setGitHubPipeline] = useState<string>();
  const [gitLabPipeline, setGitLabPipeline] = useState<string>();
  const [displayFeedBack, setDisplayFeedBack] = useState(true);
  const [platformDisplayed, setPlatformDisplayed] = useState<
    'gitlab' | 'github'
  >();

  useEffect(() => {
    if (!fetchResult.platform || platformDisplayed) return;
    setPlatformDisplayed(fetchResult.platform);
  }, [fetchResult.platform, platformDisplayed]);
  useEffect(() => {
    if (!fetchResult?.pipelines) return;
    fetchResult.pipelines.forEach((res) => {
      if (res.platform === 'github') {
        setGitHubPipeline(res.pipeline);
      }
      if (res.platform === 'gitlab') {
        setGitLabPipeline(res.pipeline);
      }
    });
  }, [fetchResult?.pipelines]);

  const copyPipelineCode = (platform: 'gitlab' | 'github') => {
    if (!gitLabPipeline || !gitHubPipeline) return;
    navigator.clipboard.writeText(
      platform === 'gitlab' ? gitLabPipeline : gitHubPipeline,
    );
    mixpanel.track(`[Copy] Content of the .gitlab-ci.yml copied`);
    trackEvent('Copy a pipeline generated code');
  };

  const shouldDisplayButton = () => {
    if (
      (fetchResult.platform === 'github' && platformDisplayed === 'gitlab') ||
      (fetchResult.platform === 'gitlab' && platformDisplayed === 'github')
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (showResponseModal) return;
    setDisplayFeedBack(true);
  }, [showResponseModal]);
  const { push: setLocation } = useHistory();
  const { currentLicense } = useContext(ProjectsContext);

  const licenseProject = currentLicense?.projects.find(
    (licenseProject) => licenseProject.projectIDPlatform === project.id,
  );

  const projectWebUrl = determineIfIsGitLabOrGitHub(project)
    ? project?.web_url
    : project?.html_url;
  return fetchResult.pipelines ? (
    <Modal
      classOverrides={[
        'w-full sm:w-full md:w-145 pb-8 px-6 py-4 h-max z-40 scale-90',
      ]}
      setShowModal={setShowResponseModal}
      showModal={showResponseModal}
    >
      <div className="w-full h-full text-xs relative ">
        <Title
          size="h2"
          classOverrides={[
            'text-secondary-default font-extrabold text-center pb-4 pt-2',
          ]}
        >
          {t('my_projects.modal.response.title')}
        </Title>
        {platformDisplayed && setPlatformDisplayed && (
          <PlatformToggle
            platformDisplayed={platformDisplayed}
            setPlatformDisplayed={setPlatformDisplayed}
            tooltip={false}
            gitHubBtnClassOverrides={[
              platformDisplayed === 'github'
                ? 'bg-secondary-default dark:bg-dark-primary'
                : 'bg-transparent',
            ]}
            gitLabBtnClassOverrides={[
              platformDisplayed === 'gitlab'
                ? 'bg-secondary-default dark:bg-dark-primary'
                : 'bg-transparent',
            ]}
          />
        )}
        {platformDisplayed === 'gitlab' ? (
          <GitLabAutoPipeline
            fetchResult={fetchResult}
            project={project}
            withWrite={withWrite}
          />
        ) : (
          <GitHubAutoPipeline
            fetchResult={fetchResult}
            project={project}
            withWrite={withWrite}
            setShowResponseModal={setShowResponseModal}
          />
        )}

        <div className="mt-4 relative rounded-lg shadow bg-gray-highlighter flex  h-88 overflow-auto">
          {gitLabPipeline && gitHubPipeline ? (
            <>
              <Tooltip
                afterShow={() => {
                  if (platformDisplayed) copyPipelineCode(platformDisplayed);
                  setTimeout(ReactTooltip.hide, 2000);
                }}
                title={t('action:copied')}
                click={true}
                classOverrides={['absolute h-6 w-6 top-3 right-4 ']}
                colorSet={ColorSetEnum.success}
              >
                <button
                  className={
                    'text-xl ' +
                    'text-secondary-dark dark:text-gray-primary hover:opacity-70' +
                    'transition-all duration-300 cursor-pointer right-0 -mt-1 -mr-1'
                  }
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </Tooltip>
              <SyntaxHighlighter
                language="yaml"
                showLineNumbers={true}
                wrapLongLines
                style={theme === 'light' ? github : solarizedDark}
                className={['rounded-lg shadow-lg w-full']}
                PreTag={'div'}
              >
                {platformDisplayed === 'gitlab'
                  ? gitLabPipeline
                  : gitHubPipeline}
              </SyntaxHighlighter>
            </>
          ) : (
            <SpinnerIcon />
          )}
        </div>
        <div className="flex justify-center mt-3">
          {shouldDisplayButton() && (
            <SecondaryButton
              content={''}
              action={() => {
                setLocation(`/dashboard/project/${licenseProject?.id}`);
                setShowResponseModal(false);
              }}
              classOverrides={[
                'flex text-center justify-center items-center font-bold',
              ]}
              color="text-gray-primary"
              font="comfortaa"
              bgColor="bg-primary-default"
            >
              {t('action:customize_pipeline')}
            </SecondaryButton>
          )}
        </div>
        {displayFeedBack &&
          gitLabPipeline &&
          gitHubPipeline &&
          windowSize.width >= SM_SCREEN_SIZE && (
            <div className="transition-all duration-500">
              <iframe
                src={`https://tally.so/embed/mD4NqN?alignLeft=1&hideTitle=1&transparentBackground=1&projectUrl=${projectWebUrl}&pipeline=${
                  platformDisplayed === 'gitlab'
                    ? Buffer.from(gitLabPipeline).toString('base64')
                    : Buffer.from(gitHubPipeline).toString('base64')
                }`}
                width="100%"
                height="330"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                className="min-h-min"
                title="Feedback Pipeline Generator"
              ></iframe>
            </div>
          )}
      </div>
    </Modal>
  ) : (
    <></>
  );
};
