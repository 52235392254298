import React from 'react';
import { NavigationBar } from '@components/NavigationBar/NavigationBar.component';
import { Footer } from '@components/Footer/Footer.component';
import { getInitialTheme, ThemeProvider } from 'common/contexts/theme.context';
import { CookiePopup } from '@components/CookiePopup/CookiePopup.component';
import { MaintenancePage } from 'pages/Maintenance.page';
import { cn } from '@utils/classes.util';
import { DashboardSideBar } from 'user/components/DashboardSideBar/DashboardSideBar.component';
import { useHistory } from 'react-router-dom';
import { DashboardFooter } from '@components/Footer/DashboardFooter.component';

export enum LayoutEnum {
  STANDARD,
  DASHBOARD,
}

type Props = {
  layoutType?: LayoutEnum;
};
export const Layout: React.FC<Props> = ({
  children,
  layoutType = LayoutEnum.STANDARD,
}) => {
  const siteInMaintenance = false;
  const { location } = useHistory();
  return siteInMaintenance ? (
    <MaintenancePage />
  ) : (
    <ThemeProvider initialTheme={getInitialTheme()}>
      {layoutType === LayoutEnum.DASHBOARD ? (
        <div className="min-h-screen relative flex flex-col  h-full ">
          <div className="flex flex-auto relative">
            <DashboardSideBar />
            <div className="flex flex-col w-full dark:bg-dark-primary min-h-125 relative">
              <div
                className={cn(
                  'scale-90',
                  !location.pathname.includes('_') &&
                    location.pathname.includes('dashboard') &&
                    'md:py-8 py-2 px-2',
                )}
              >
                {children}
              </div>
              <DashboardFooter />
            </div>
          </div>
          <CookiePopup />
        </div>
      ) : (
        <div className="min-h-screen relative flex flex-col h-full">
          <NavigationBar />
          <div className="flex-auto dark:bg-dark-primary">{children}</div>
          <Footer />
          <CookiePopup />
        </div>
      )}
    </ThemeProvider>
  );
};
