import React, { useContext } from 'react';
import { Page } from '@components/Page/Page.component';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { CardContainer } from '@components/CardContainer/CardContainer.component';
import { motion } from 'framer-motion';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { fadeInTopVariant } from '@utils/motion.util';
import { Typography } from '@components/Typography/Typography.component';
import { ShareIcon } from '@components/Icons/ShareIcon/ShareIcon.component';
import { RocketLaunchIcon } from '@components/Icons/RocketLaunchIcon/RocketLaunchIcon.component';
import R2_rocket from '@images/R2/R2_rocket.png';
import { LensIcon } from '@components/Icons/LensIcon/LensIcon.component';
import { UserAtomIcon } from '@components/Icons/UserAtomIcon/UserAtomIcon.component';
import { getNewlineText } from '@utils/components.utils';
import { ThemeContext } from 'common/contexts/theme.context';
import { TeamIcon } from '@components/Icons/TeamIcon/TeamIcon.component';

export const TeamPage: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const getCardIcon = (i: number) => {
    switch (i) {
      case 0:
        return <LensIcon classOverrides={['dark:text-gray-primary']} />;
      case 1:
        return <UserAtomIcon classOverrides={['dark:text-gray-primary']} />;
      case 2:
        return <ShareIcon classOverrides={['dark:text-gray-primary']} />;
      case 3:
        return <RocketLaunchIcon classOverrides={['dark:text-gray-primary']} />;
    }
  };

  //Get four cards from correct texts and inputs in translation
  const cards = [0, 1, 2, 3].map((index) => (
    <div className="flex flex-wrap justify-center" key={'card-' + index}>
      <CardContainer
        title={t(`company.values.card.${index}.title`)}
        iconBgColor={theme !== 'light' ? 'dark:bg-gray-third' : undefined}
        textColor={theme !== 'light' ? 'dark:text-gray-primary' : undefined}
        titleSize="h3"
        text={t(`company.values.card.${index}.text`)}
      >
        {getCardIcon(index)}
      </CardContainer>
    </div>
  ));

  return (
    <Page title={t('company.title')}>
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('company.title')}
        </Title>
        <NavBarCutter />
      </div>
      {/* Whole div containing all*/}
      <div className="w-full h-auto lg:space-y-28">
        {/* Div that contains the rocket image and the text  */}
        <div className="lg:flex justify-center w-full h-full">
          {/* Div containing all Title texts */}
          <div className="text-center mt-3">
            <Title
              bold
              color={'text-secondary-default'}
              size={'h2'}
              classOverrides={['mb-12']}
            >
              {t('company.mission')}
            </Title>
            <Typography
              bold
              color={'text-gray-secondary'}
              size="4xl"
              font="comfortaa"
              classOverrides={['dark:text-white']}
            >
              {t('company.mission_text')}
            </Typography>
          </div>

          {/* Div containing the image rocket, position absolute when large screen */}
          <div className="w-full h-auto flex justify-center right-3/4 my-4 lg:w-auto lg:absolute lg:h-auto lg:-mt-11">
            <motion.img
              className="m-0 w-52 md:w-60  lg:w-75 lg:ml-48 xl:w-88 xl:ml-60 2xl:w-96 2xl:ml-80"
              variants={fadeInTopVariant()}
              src={R2_rocket}
              alt={t('company.r2_rocket_alt')}
              title={t('company.r2_rocket_alt')}
            />
          </div>
        </div>
        <div className="w-full justify-center text-center bg-gray-primary dark:bg-secondary-default-medium-opacity">
          <Title
            bold
            color={'text-secondary-default'}
            size={'h2'}
            classOverrides={['pt-10 mb-10']}
          >
            {t('company.values.title')}
          </Title>

          {/* Div containing the cards */}
          <div className="flex flex-shrink-0 flex-wrap justify-center">
            {cards}
          </div>
        </div>
      </div>

      {/* Div about the team section */}
      <div className="flex flex-col items-center text-center w-full px-4 md:w-auto sm:px-12 md:px-24 lg:px-48">
        <Title
          bold
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['-mb-4  lg:-mb-12 mt-8 lg:mt-20']}
        >
          {t('company.team.title')}
        </Title>
        <TeamIcon />
        <Typography
          color={'text-gray-secondary'}
          classOverrides={[
            'mt-4 mb-24 lg:mb-16 xl:px-10 dark:text-gray-primary',
          ]}
        >
          {getNewlineText(t('company.team.text'))}
        </Typography>
      </div>
    </Page>
  );
};
