import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

export const TableRow: React.FC<ClassOverrides> = ({
  children,
  classOverrides = [],
}) => {
  return <tr className={cn(...classOverrides)}>{children}</tr>;
};
