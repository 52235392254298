/**
 * Concatenate multiple classes to a single string.
 * Can accept conditional classes like `true && "class-if-true"`.
 * @param {(string|boolean)[]} classes the classes to join
 * @returns {string} the joined classes
 */
export const cn = (...classes: Array<string | boolean | undefined>): string =>
  classes.filter(Boolean).join(' ');

/**
 * Define if the screen width is Md
 * @param {number} width -- pixel
 * @return {boolean}
 */
export const isMd = (width: number): boolean => width >= 768;

/**
 * Define if the screen width is Lg
 * @param {number} width -- pixel
 * @return {boolean}
 */
export const isLg = (width: number): boolean => width >= 1024;
/**
 * If there is a DOM
 */
export const isClient = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
