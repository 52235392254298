import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides & {
  description: string;
};

export const JobDescription: React.FC<Props> = ({
  description,
  classOverrides = [],
}) => {
  return <p className={cn('w-full flex ', ...classOverrides)}>{description}</p>;
};
