import React, { useEffect } from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { PageMeta } from '@typings/PageMeta.type';
import { Helmet } from 'react-helmet-async';
import { LoadingPage } from '../../../pages/Loading.page';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInStaggerChildren } from '@utils/motion.util';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { cn } from '@utils/classes.util';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactSEOMetaTags } from 'react-seo-meta-tags';

type Props = ClassOverrides &
  PageMeta & {
    isLoading?: boolean;
    noNav?: boolean;
  };

const Page: React.FC<Props> = ({
  classOverrides = [],
  title,
  description,
  children,
  isLoading = false,
  navBarCutter = false,
  noNav = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  // Scroll to the top of the page before each render
  useEffect(() => {
    if (location.hash) {
      const object = document.querySelector(location.hash);
      if (object) {
        object.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <ReactSEOMetaTags
        render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
        website={{
          title: title ? `${title} - R2Devops` : 'R2Devops',
          description: description ? description : t('home.description'),
          image:
            'https://r2devops-public-media.s3.fr-par.scw.cloud/twitter_card.png',
        }}
        twitter={{
          title: title ? `${title} - R2Devops` : 'R2Devops',
          description: description ? description : t('home.description'),
          image:
            'https://r2devops-public-media.s3.fr-par.scw.cloud/twitter_card.png',
          cardType: 'summary_large_image',
        }}
      />
      {!noNav && <div className="bg-secondary-default pb-2 relative" />}
      {navBarCutter && <NavBarCutter />}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className={cn(...classOverrides)}
          variants={{ ...fadeInStaggerChildren(), hidden: {} }}
          initial={'hidden'}
          animate={'show'}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export { Page };
