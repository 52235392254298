import {
  fullnameIsContainingSpecialChar,
  isUserNameCorrect,
} from './../../common/utils/text.util';
import {
  Configuration,
  UiNode,
  UiNodeAnchorAttributes,
  UiNodeAttributes,
  UiNodeImageAttributes,
  UiNodeInputAttributes,
  UiNodeTextAttributes,
  V0alpha2Api,
} from '@ory/kratos-client';
import { KRATOS_URL } from '../../common/constants/routes';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import {
  isContainingSpecialChar,
  isEmail,
  isValidLinkedinURL,
  isValidTwitter,
} from '@utils/text.util';
import { PWD_MAX_LENGTH, PWD_MIN_LENGTH } from '../../common/constants/general';
import { axiosInstance } from '../../common/services/http.service';
import i18n from 'i18next';

export const newKratosSdk = (token?: string) =>
  new V0alpha2Api(
    new Configuration({
      basePath: KRATOS_URL,
      apiKey: token,
      baseOptions: {
        // Setting this is very important as axios will send the CSRF cookie otherwise
        // which causes problems with ORY Kratos' security detection.
        withCredentials: true,

        // Timeout after 5 seconds.
        timeout: 10000,
      },
    }),
    '',
    // Ensure that we are using the axios client with retry.
    axiosInstance,
  );

export function isUiNodeAnchorAttributes(
  pet: UiNodeAttributes,
): pet is UiNodeAnchorAttributes {
  return (pet as UiNodeAnchorAttributes).href !== undefined;
}

export function isUiNodeImageAttributes(
  pet: UiNodeAttributes,
): pet is UiNodeImageAttributes {
  return (pet as UiNodeImageAttributes).src !== undefined;
}

export function isUiNodeInputAttributes(
  pet: UiNodeAttributes,
): pet is UiNodeInputAttributes {
  return (pet as UiNodeInputAttributes).name !== undefined;
}

export function isUiNodeTextAttributes(
  pet: UiNodeAttributes,
): pet is UiNodeTextAttributes {
  return (pet as UiNodeTextAttributes).text !== undefined;
}

/**
 * Find a specific UiNode with input attributes
 * @param {UiNode[]} nodes -- List of available nodes
 * @param {string} nodeName -- Name of node to search for
 * @returns {[number, UiNodeInputAttributes | null]} First is index in nodes, second is value
 */
export function findUiNodeInput(
  nodes: UiNode[],
  nodeName: string,
): UiNode | null {
  const nodeIndex = nodes.findIndex(
    (n) =>
      isUiNodeInputAttributes(n.attributes) && n.attributes.name === nodeName,
  );
  if (nodeIndex !== -1 && isUiNodeInputAttributes(nodes[nodeIndex].attributes))
    return nodes[nodeIndex];
  else return null;
}

export function getNodeName({ attributes }: UiNode) {
  if (isUiNodeInputAttributes(attributes)) {
    return attributes.name;
  }

  return '';
}

export function getNodeValue({ attributes }: UiNode) {
  if (isUiNodeInputAttributes(attributes)) {
    if (attributes.value) return attributes.value;

    return '';
  }

  return '';
}

export const getNodeTitle = ({ attributes, meta }: UiNode): string => {
  if (isUiNodeInputAttributes(attributes)) {
    if (meta?.label?.text) {
      return meta.label.text;
    }
    return attributes.name;
  }

  if (meta?.label?.text) {
    return meta.label.text;
  }

  return '';
};

/**
 * Guess the variant typ of an input
 * @param attributes
 */
export const guessVariant = ({ attributes }: UiNode) => {
  if (!isUiNodeInputAttributes(attributes)) {
    return 'text';
  }

  if (attributes.name === 'identifier') {
    return 'username';
  }

  switch (attributes.type) {
    case 'hidden':
      return null;
    case 'email':
      return 'email';
    case 'submit':
      return null;
    case 'password':
      return 'password';
    default:
      return 'text';
  }
};

/**
 * Guess the validation pattern of an input
 * @param attributes
 * @see useForm
 */
export const guessValidationPattern = ({
  attributes,
}: UiNode): RegisterOptions => {
  if (!isUiNodeInputAttributes(attributes)) {
    return {};
  }

  switch (attributes.name) {
    // Field generated into recovery flow
    case 'traits.email':
    case 'email':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        required: {
          value: true,
          message: i18n.t('error:form.required'),
        },
        validate: (v) => {
          // Returns true if no validation needed or isn't following our needs
          return v !== undefined && v.length >= 3 && !isEmail(v)
            ? (i18n.t('error:form.validField', { field: 'email' }) as string)
            : true;
        },
      };
    case 'password_identifier':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        required: {
          value: true,
          message: i18n.t('error:form.required'),
        },
      };
    case 'traits.username':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        validate: (v) =>
          v !== undefined && !isUserNameCorrect(v)
            ? (i18n.t('error:form.noSpecialChar.username') as string)
            : true,
        required: {
          value: true,
          message: i18n.t('error:form.required'),
        },
      };
    case 'password':
    case 'password_repeat':
      return {
        minLength: {
          value: PWD_MIN_LENGTH,
          message: i18n.t('error:form.minLengthWithCount', {
            count: PWD_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: PWD_MAX_LENGTH,
          message: i18n.t('error:form.maxLengthWithCount', {
            count: PWD_MAX_LENGTH,
          }),
        },
        required: {
          value: true,
          message: i18n.t('error:form.required'),
        },
      };
    case 'traits.full_name':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        maxLength: {
          value: 40,
          message: i18n.t('error:form.maxLengthWithCount', { count: 40 }),
        },
        validate: (v) =>
          v !== undefined && !fullnameIsContainingSpecialChar(v)
            ? (i18n.t('error:form.noSpecialChar.full_name') as string)
            : true,
      };
    case 'traits.linkedin':
      return {
        validate: (v) => {
          if (v !== undefined && v.length > 0) {
            return !isValidLinkedinURL(v)
              ? (i18n.t('error:form.validField', {
                  field: 'Linkedin url',
                }) as string)
              : true;
          }
          return true;
        },
      };
    case 'traits.twitter':
      return {
        validate: (v) => {
          if (v !== undefined && v.length > 0) {
            return !isValidTwitter(v)
              ? (i18n.t('error:form.validField', {
                  field: 'Twitter handle',
                }) as string)
              : true;
          }
          return true;
        },
      };
    case 'traits.job_title':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        maxLength: {
          value: 60,
          message: i18n.t('error:form.maxLengthWithCount', { count: 60 }),
        },
        validate: (v) => {
          return v !== undefined && isContainingSpecialChar(v)
            ? (i18n.t('error:form.validField', {
                field: 'job title',
              }) as string)
            : true;
        },
      };
    case 'traits.company':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        maxLength: {
          value: 60,
          message: i18n.t('error:form.maxLengthWithCount', { count: 60 }),
        },
        validate: (v) =>
          v !== undefined && isContainingSpecialChar(v)
            ? (i18n.t('error:form.validField', {
                field: 'company name',
              }) as string)
            : true,
      };
    case 'traits.bio':
      return {
        minLength: {
          value: 3,
          message: i18n.t('error:form.minLengthWithCount', { count: 3 }),
        },
        maxLength: {
          value: 400,
          message: i18n.t('error:form.maxLengthWithCount', { count: 400 }),
        },
      };
    default:
      return {};
  }
};

/**
 * useKratosHook expect an array of filters, this
 * apply them all in once
 * @param flow
 * @param filters
 */
export const applyFilters = async <F>(
  flow: F,
  filters: ((body: F) => Promise<F>)[],
): Promise<F> => {
  for (let i = 0; i < filters.length; i++) {
    const fn = filters[i];
    flow = (await fn(flow)) as F;
  }
  return Promise.resolve(flow);
};
