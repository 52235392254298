import { RateType } from '@components/Buttons/RatingBox/RatingBox.component';
import {
  toastError,
  toastSuccess,
} from '@components/Toasters/Toaster.component';
import { Rating } from '@jobs/models/Rating.model';
import { buildRoute } from '@utils/routes.util';
import { API_ROUTES } from 'common/constants/routes';
import { HttpContext } from 'common/contexts/http.context';
import { HttpService } from 'common/services/http.service';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export type ReturnType = [(newUserRating: RateType) => void, boolean];

const addRating = (
  httpService: HttpService,
  jobId: string,
  rateType: RateType,
  t: TFunction<'translation'>,
  onSuccess: (rateType: RateType, value: number) => void,
): Promise<void> => {
  return httpService
    .fetch<Rating>('POST', buildRoute(API_ROUTES.JOB_RATING(jobId)), {
      body: {
        value: rateType.valueOf(),
      },
    })
    .then((res) => {
      if (!res.response) {
        toastError(t('error:toast.unhandled'));
      } else if (res.status === StatusCodes.CREATED) {
        // Update rating value locally if request was successful
        onSuccess(rateType, 1);
        toastSuccess(t('toast.jobRated'));
      }
    });
};

const deleteRating = async (
  httpService: HttpService,
  jobId: string,
  rateType: RateType,
  t: TFunction<'translation'>,
  showToast = true,
  onSuccess?: (rateType: RateType, value: number) => void,
): Promise<void> => {
  return httpService
    .fetch<Rating>('DELETE', buildRoute(API_ROUTES.JOB_RATING(jobId)))
    .then((res) => {
      if (
        res.status !== StatusCodes.NO_CONTENT &&
        res.status !== StatusCodes.OK
      ) {
        toastError(t('error:toast.unhandled'));
      } else if (onSuccess && showToast) {
        // Update rating value locally if request was successful
        onSuccess(rateType, -1);
        if (showToast) {
          toastSuccess(t('toast.jobUnrated'));
        }
      }
    });
};

export const useOnJobRating = (
  username: string | undefined,
  userPreviousRating: RateType,
  jobId: string,
  updateRatingState: (rateType: RateType, value: number) => void,
): ReturnType => {
  const { t } = useTranslation();
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);

  const rateJob = (newUserRating: RateType): void => {
    setLoading(true);
    if (!username) {
      // Display error if non logged in user tries to rate
      toastError(t('error:toast.login_required_for_rating_job'));
    } else if (newUserRating === userPreviousRating) {
      /* User is unrating by clicking the same value they've rated before
      Only delete, no add necessary */
      deleteRating(
        httpService,
        jobId,
        newUserRating,
        t,
        true,
        updateRatingState,
      ).finally(() => setLoading(false));
    } else if (
      userPreviousRating !== RateType.NONE &&
      newUserRating !== userPreviousRating
    ) {
      /* User has rated and is rating with the opposite value
      Delete existing rating and add new one */
      deleteRating(httpService, jobId, newUserRating, t, false)
        .then(() => {
          addRating(httpService, jobId, newUserRating, t, updateRatingState);
        })
        .finally(() => setLoading(false));
    } else {
      /* User hasn't rated yet
      Only add rating, no delete necessary */
      addRating(
        httpService,
        jobId,
        newUserRating,
        t,
        updateRatingState,
      ).finally(() => setLoading(false));
    }
  };

  return [rateJob, loading];
};
