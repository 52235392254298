import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { SearchJobsContainer } from '@jobs/components/SearchJobsContainer/SearchJobsContainer';
import { cn } from '@utils/classes.util';
import { isAResourcesLink } from '@utils/dashboard.util';
import { fadeInRightVariant } from '@utils/motion.util';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardLink } from 'user/components/DashboardSideBar/DashboardSideBar.component';
import { MyJobs } from './dashboard.router';

export const ResourcesPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [links, setLinks] = useState<DashboardLink[] | undefined>();
  const ref = useRef(null);

  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState(<MyJobs />);
  useEffect(() => {
    if (links) return;
    let i = 0;
    while (i18n.exists('dashboard.links.' + i)) i++;
    let dashboardTrans: DashboardLink[] = [];
    for (let j = 0; j < i; j++) {
      dashboardTrans.push({
        name: t('dashboard.links.' + j + '.name'),
        to: t('dashboard.links.' + j + '.link'),
        target: i18n.exists('dashboard.links.' + j + '.target')
          ? t('dashboard.links.' + j + '.target')
          : undefined,
        title: i18n.exists('dashboard.links.' + j + '.title')
          ? t('dashboard.links.' + j + '.title')
          : undefined,
        button: i18n.exists('dashboard.links.' + j + '.button')
          ? t('dashboard.links.' + j + '.button')
          : undefined,
      });
    }
    setLinks(dashboardTrans);
  }, [i18n, links, t]);

  useEffect(() => {
    switch (history.location.pathname) {
      case '/dashboard/jobs':
      case '/dashboard/resources':
        setCurrentComponent(<MyJobs />);
        break;

      case '/dashboard/hub':
        setCurrentComponent(
          <>
            <Title
              bold
              color="text-secondary-default"
              font="ubuntu"
              size="h1"
              classOverrides={['text-center mb-4']}
            >
              {t('hub.title')}
            </Title>
            <SearchJobsContainer />
          </>,
        );
        break;

      default:
        break;
    }
  }, [history.location.pathname, t]);

  return (
    <Page
      noNav
      title={t('personal_space.title')}
      description={t('personal_space.description')}
    >
      <motion.nav
        ref={ref}
        className={cn('lg:hidden mb-2 flex justify-evenly flex-wrap')}
      >
        <div className={cn('flex items-center justify-center w-full relative')}>
          {/* Show chevron only if first item isn't the one selected */}
          <div
            className={cn(`flex items-center flex-wrap justify-center mx-6`)}
            data-testid="buttons-container"
          >
            {links?.map((link, key) => {
              if (isAResourcesLink(link.to)) {
                return (
                  <button
                    className={cn(
                      'py-3 ' +
                        'text-sm transition duration-500 font-comfortaa text-gray-secondary dark:text-gray-primary ' +
                        'filter w-25 h-16',
                      history.location.pathname === link.to
                        ? 'border-b-2 border-secondary-default text-secondary-default '
                        : '',
                    )}
                    data-testid={`tab-${link}`}
                    onClick={() => {
                      if (link.name === 'Generator') {
                        window.location.href = link.to;
                      } else {
                        history.push(link.to);
                      }
                    }}
                    key={key}
                  >
                    {link.name}
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </motion.nav>
      <motion.div
        initial={'hidden'}
        animate={'show'}
        variants={fadeInRightVariant()}
      >
        {ref.current && currentComponent}
      </motion.div>
    </Page>
  );
};
