import { FooterLink } from '@components/FooterLink/FooterLink';
import { FAQ_LINK } from 'common/constants/general';
import { DOCUMENTATION_URL } from 'common/constants/routes';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'user/contexts/user.context';

export const FooterLinks: React.FC = () => {
  const { t } = useTranslation();
  const { identity } = useContext(UserContext);

  return (
    <div className="md:space-x-16 md:flex-row md:absolute md:left-3/4 lg:space-x-24 xl:space-x-40">
      <div className="flex flex-col text-center md:text-left md:absolute md:-ml-20 xl:-ml-2">
        <FooterLink
          title={t('action:ci_generator')}
          linkTo="https://pipeline.r2devops.io"
          isRoute
        />
        <FooterLink title={t('action:team')} linkTo="/team" />
        <FooterLink title={t('action:legal_notices')} linkTo="/legal" />
        <FooterLink title={t('action:privacy_policies')} linkTo="/privacy" />
        <FooterLink title={t('action:terms_of_use')} linkTo="/termsofuse" />
      </div>

      <div className="flex flex-col items-center md:items-start">
        <FooterLink title={t('action:find_job')} linkTo="/_/jobs" />
        {identity ? (
          <div className="flex flex-col items-center md:items-start">
            {/* <FooterLink
              title={t('action:personal_space')}
              linkTo="/dashboard"
            /> */}
            <FooterLink title={t('action:add_a_job')} linkTo="/u/addjob" />
          </div>
        ) : (
          <FooterLink title={t('action:verify_account')} linkTo="/u/verify" />
        )}
        <FooterLink
          title={t('action:documentation')}
          linkTo={DOCUMENTATION_URL}
          isRoute
        />
        <FooterLink title={t('action:roadmap')} linkTo="/roadmap" />
        <FooterLink title={t('action:faq')} linkTo={FAQ_LINK} isRoute />
      </div>
    </div>
  );
};
