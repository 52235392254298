import { ButtonProps } from '@typings/ButtonProps.type';
import { ClassOverrides, HTMLStyling } from '@typings/ClassOverrides.type';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { SpinnerButton } from '../SpinnerButton/SpinnerButton.component';

type Props = ButtonProps &
  HTMLStyling &
  ClassOverrides & {
    isSubmitting: boolean;
    isValid: boolean;
    errors: DeepMap<FieldValues, FieldError>;
    loading?: boolean;
  };

export const SubmitButton: React.FC<Props> = ({
  action,
  bgColor = 'bg-secondary-default',
  classOverrides = [],
  color,
  content,
  disabled,
  isSubmitting,
  isValid,
  errors,
  hoverBgColor,
  font,
  margin,
  loading = false,
}) => {
  return (
    <SpinnerButton
      action={action}
      bgColor={bgColor}
      color={color}
      content={content}
      font={font}
      margin={margin}
      classOverrides={[
        'xl:text-md my-0',
        ...classOverrides,
        !margin && 'mx-auto',
      ]}
      // We test if the number of errors is more than 0 and if the form is valid
      disabled={
        disabled ||
        isSubmitting ||
        Object.keys(errors).length > 0 ||
        !isValid ||
        loading
      }
      hoverBgColor={hoverBgColor}
      key={'submit'}
      submitButton
      loading={loading}
    />
  );
};
