import { Title } from '@components/Titles/Title.component';
import { fadeInLeftVariant, fadeInStaggerChildren } from '@utils/motion.util';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import R2Discord from '@images/R2/R2Discord.svg';
import { Typography } from '@components/Typography/Typography.component';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { DISCORD_NOTIF_LINK } from 'common/constants/general';

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      variants={fadeInStaggerChildren()}
      initial={'hidden'}
      animate={'show'}
      className="px-8"
    >
      <Title
        size={'h1'}
        color={'text-secondary-default'}
        bold
        classOverrides={['text-center pb-8']}
      >
        {t('notifications.title')}
      </Title>
      <div className="flex flex-col  items-center">
        <motion.img
          variants={fadeInLeftVariant()}
          initial={'hidden'}
          animate={'show'}
          src={R2Discord}
          className={'w-72 lg:w-2-4 order-last '}
          alt={t('r2_alt')}
        />
        <div className="flex flex-col justify-center items-center">
          <Typography
            size="md"
            color="text-gray-secondary"
            classOverrides={[
              'mx-auto text-center font-bold dark:text-gray-primary 2xl:px-16',
            ]}
          >
            {t('notifications.content')}
          </Typography>
          <a href={DISCORD_NOTIF_LINK} rel="noreferrer">
            <PrimaryButton
              classOverrides={['text-xl w-60 dark:bg-secondary-default']}
              bgColor={'bg-primary-default'}
              bold={false}
              font={'ubuntu'}
              content={t('notifications.button')}
            />
          </a>
        </div>
      </div>
    </motion.div>
  );
};
