import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { useHistory } from 'react-router-dom';
import { SmallArrowIcon } from '@components/Icons/SmallArrowIcon/SmallArrowIcon';
import { cn } from '@utils/classes.util';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { XL_SCREEN_SIZE } from 'common/constants/style.constant';

export enum SortType {
  DEFAULT,
  DATE,
  RATING,
}

type Props = {
  applySorting: (sortType: SortType) => void;
};

/**
 * Part of global navbar where the userprofile picture is shown & a menu is available (logout/settings)
 */
export const SortMenu: React.FC<Props> = ({ applySorting }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const menuRef = useRef(null);
  const onClickOutside = useCallback(() => {
    if (!menuRef) return;
    if (openMenu) setOpenMenu(!openMenu);
  }, [openMenu]);
  useOnClickOutside(menuRef, onClickOutside);
  const windowSize = useWindowSize();

  useEffect(() => {
    history.listen(() => {
      setOpenMenu(false);
    });
  }, [history]);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <div ref={menuRef}>
      <button
        className={cn(
          'underline items-center flex font-semibold dark:text-gray-primary',
          'text-secondary-default hover:text-secondary-default hover:text-opacity-70 text-lg',
          'cursor-pointer transition delay-200',
        )}
        onClick={() => toggleMenu()}
      >
        {t('hub.sort_button_title')}
        <div className="w-max ml-0.5">
          <SmallArrowIcon height="8" width="13" />
        </div>
      </button>
      {openMenu && (
        <motion.div
          variants={{
            hidden: {
              width: 0,
              height: 0,
              y: -20,
              x: '-3rem',
              opacity: 0,
            },
            show: {
              width: '8rem',
              height: 'auto',
              y: 0,
              x: windowSize.width > XL_SCREEN_SIZE ? 26 : -42,
              opacity: 1,
              transition: {
                staggerChildren: 0.01,
              },
            },
          }}
          initial={'hidden'}
          animate={'show'}
          exit={'hidden'}
          className={
            'xl:origin-top-right absolute mt-1 z-30 xl:right-0 mr-3 w-32 text-center rounded-md shadow-lg bg-white dark:bg-dark-secondary dark:opacity-40 ring-1 ring-black ring-opacity-5 focus:outline-none'
          }
        >
          <ul className="text-secondary-default dark:text-gray-primary w-full">
            <li
              className="py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-secondary-default-high-opacity transition duration-300 rounded-md"
              title={t('hub.sort_type.0')}
              onClick={() => applySorting(SortType.DEFAULT)}
            >
              {t('hub.sort_type.0')}
            </li>
            <div className="w-full border-2 border-gray-primary dark:border-secondary-default " />
            <li
              className="py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-secondary-default-high-opacity transition duration-300 rounded-md"
              title={t('hub.sort_type.1')}
              onClick={() => applySorting(SortType.DATE)}
            >
              {t('hub.sort_type.1')}
            </li>
            <div className="w-full border-2 border-gray-primary dark:border-secondary-default " />
            <li
              className="py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-secondary-default-high-opacity transition duration-300 rounded-md"
              title={t('hub.sort_type.1')}
              onClick={() => applySorting(SortType.RATING)}
            >
              {t('hub.sort_type.2')}
            </li>
          </ul>
        </motion.div>
      )}
    </div>
  );
};
