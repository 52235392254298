import { Tier } from '@jobs/types/IJobApi.type';
import { cn } from '@utils/classes.util';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {
  labels: string[];
  isDeprecated?: boolean;
  tier: Tier;
};

const LabelSlider: React.FC<Props> = ({
  isDeprecated = false,
  labels,
  tier,
}) => {
  if (labels[0] !== tier) labels.unshift(tier);
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={10}
      className="w-full"
      breakpoints={{
        520: {
          slidesPerView: isDeprecated ? 3 : 4,
          spaceBetween: 15,
        },
      }}
    >
      {labels.map((label, index) => (
        <SwiperSlide key={index} className="min-w-max max-w-max">
          <div
            className={cn(
              label === Tier.Community
                ? 'bg-secondary-default'
                : label === Tier.Official
                ? 'bg-primary-default'
                : 'bg-green-pearl',
              'capitalize text-white py-1 px-3 cursor-pointer rounded-xl shadow',
            )}
            data-testid={`label-${label}`}
            key={`${label}-${index}`}
          >
            {label}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export { LabelSlider };
