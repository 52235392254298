import { Typography } from '@components/Typography/Typography.component';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Size, TextColor } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { useState } from 'react';
import { Trans } from 'react-i18next';

type Props = {
  title?: string;
  content?: string;
  titleColor?: TextColor;
  titleSize?: Size;
  titleClassOverrides?: string[];
  font?: 'ubuntu' | 'comfortaa';
};

export const TomorrowFeaturesRow: React.FC<Props> = ({
  title,
  content,
  titleColor = 'text-primary-default',
  titleSize = 'lg',
  titleClassOverrides = [],
  font = 'ubuntu',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="mb-3 w-full relative">
      <button
        className={cn(
          'px-4 bg-gray-primary py-2 relative dark:bg-secondary-default flex justify-center cursor-pointer shadow w-full',
          isOpen ? 'rounded-tr-lg rounded-tl-lg' : 'rounded-lg',
        )}
        onClick={() => toggleContent()}
      >
        <Typography
          color={titleColor}
          font={font}
          size={titleSize}
          classOverrides={[
            'dark:text-gray-primary text-center mx-4',
            ...titleClassOverrides,
          ]}
        >
          {title}
        </Typography>
        <FontAwesomeIcon
          className={cn(
            'text-gray-secondary absolute right-2 top-0 h-full text-right dark:text-gray-primary transition-transform duration-300 text-2xl cursor-pointer',
            isOpen ? 'transform rotate-180' : '',
          )}
          icon={faCaretDown}
        />
      </button>
      {isOpen && (
        <div className="border-gray-primary rounded-b-lg dark:border-transparent border-2 p-4 text-center dark:bg-secondary-default-medium-opacity">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={content}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
      )}
    </div>
  );
};
