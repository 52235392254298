import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import R2Wiring from '@images/R2/R2_wiring.svg';
import { motion } from 'framer-motion';
import { fadeInTopVariant } from '@utils/motion.util';
import {
  SelfServiceLoginFlow,
  UiNodeInputAttributes,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from '../../../kratos/helpers/kratos.helper';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { GitLabButton } from '@components/Buttons/GitLabButton/GitLabButton.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { GitHubButton } from '@components/Buttons/GitHubButton/GitHubButton.component';

type Props = {
  flow?: SelfServiceLoginFlow;
};

/**
 * SignIn with gitlab part on the sign in page.
 * It creates a new form that will redirect to Kratos & then to Gitlab
 * @param {SelfServiceLoginFlow} flow
 */
export const SignInThirdParty: React.FC<Props> = ({ flow }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [gitLabProviderNode, setGitLabProviderNode] =
    useState<UiNodeInputAttributes>();
  const [gitHubProviderNode, setGitHubProviderNode] =
    useState<UiNodeInputAttributes>();

  useEffect(() => {
    if (flow !== undefined) {
      flow.ui.nodes.forEach((node) => {
        if (isUiNodeInputAttributes(node.attributes)) {
          switch (node.attributes.value) {
            case 'github':
              setGitHubProviderNode(node.attributes);
              break;
            case 'gitlab':
              setGitLabProviderNode(node.attributes);
              break;
            default:
              break;
          }
        }
      });
    }
  }, [flow]);

  return (
    <div className={'w-52 sm:w-60 md:w-80 mx-auto'}>
      {flow ? (
        <>
          <form action={flow.ui.action} method={flow.ui.method}>
            <input
              type={'hidden'}
              id={'gitlab-csrf_token'}
              name={'csrf_token'}
              required
              value={
                (
                  flow.ui.nodes.find(
                    (t) =>
                      isUiNodeInputAttributes(t.attributes) &&
                      t.attributes.name === 'csrf_token',
                  )?.attributes as UiNodeInputAttributes
                ).value
              }
            />
            {gitLabProviderNode && (
              <input
                type={'hidden'}
                id={gitLabProviderNode?.name}
                name={gitLabProviderNode?.name}
                value={gitLabProviderNode?.value}
              />
            )}
            <GitLabButton
              title={t('action:title.redirect_gitlab')}
              content={t('action:continue_with_gitlab')}
              classOverrides={[
                'flex flex-row w-full py-1 mt-6 dark:bg-secondary-default dark:text-gray-primary',
              ]}
              submitButton={true}
              bold={true}
              color={theme === 'dark' ? 'text-gray-primary' : undefined}
            />
          </form>
          <form action={flow.ui.action} method={flow.ui.method}>
            <input
              type={'hidden'}
              id={'github-csrf_token'}
              name={'csrf_token'}
              required
              value={
                (
                  flow.ui.nodes.find(
                    (t) =>
                      isUiNodeInputAttributes(t.attributes) &&
                      t.attributes.name === 'csrf_token',
                  )?.attributes as UiNodeInputAttributes
                ).value
              }
            />
            {gitHubProviderNode && (
              <input
                type={'hidden'}
                id={gitHubProviderNode?.name}
                name={gitHubProviderNode?.name}
                value={gitHubProviderNode?.value}
              />
            )}

            <GitHubButton
              title={t('action:title.redirect_github')}
              content={t('action:continue_with_github')}
              classOverrides={[
                'flex flex-row w-full py-3 mt-6 dark:bg-secondary-default dark:text-gray-primary',
              ]}
              submitButton={true}
              bold={true}
              color={theme === 'dark' ? 'text-gray-primary' : undefined}
            />
          </form>
        </>
      ) : (
        <SpinnerIcon />
      )}
      <motion.img
        src={R2Wiring}
        alt={t('r2_wiring_alt')}
        className={'hidden md:block w-4/6 mx-auto mt-8'}
        variants={fadeInTopVariant(-20, 0.5)}
      />
    </div>
  );
};
