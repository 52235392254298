import React from 'react';
import JobIconPlaceholder from 'assets/images/new-product.png';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides & {
  icon: string;
};

export const JobIcon: React.FC<Props> = ({ classOverrides = [], icon }) => {
  return (
    <div
      className={cn(
        'w-full h-full flex items-center justify-center text-lg',
        ...classOverrides,
      )}
    >
      {icon.length > 0 ? (
        <>{icon}</>
      ) : (
        // In case job has no icon we show a placeholder
        <img
          src={JobIconPlaceholder}
          alt="Job's icon"
          className="w-2/3 h-2/3 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
        />
      )}
    </div>
  );
};
