import React from 'react';
import { Page } from '@components/Page/Page.component';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { ListKeyValue } from './TermsOfUse.page';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';

export const LegalNoticesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('legal_notices.title')}
      description={t('legal_notices.description')}
    >
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('legal_notices.title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="flex justify-center">
        <div className="w-3/4 lg:w-2/4 2xl:w-1/4 mb-8">
          <div className="text-center">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
              italic
              font="ubuntu"
            >
              <Trans i18nKey="legal_notices.law">
                The Platform is accessible within the Client's internal
                infrastructure as part of an on-premise license or, in the case
                of a SaaS license, on the website
                <TrackedLink
                  name={'home page'}
                  to="/"
                  title={t('action:title.redirect_home_page')}
                  className={'underline text-primary-default hover:opacity-70'}
                >
                  https://r2devops.io
                </TrackedLink>
                .
              </Trans>
            </Typography>
          </div>
          <Title
            bold
            color={'text-secondary-default'}
            classOverrides={['text-center my-4']}
            size={'h2'}
          >
            {t('legal_notices.titles.0')}
          </Title>
          <div>
            <ListKeyValue
              light
              keyStr={t('legal_notices.company.keys.0')}
              value={t('legal_notices.company.content.0')}
            />
            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.company.keys.1')}
              value={t('legal_notices.company.content.1')}
            />
            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.company.keys.2')}
              value={t('legal_notices.company.content.2')}
            />
            <br />
            <span>
              <span className="font-black text-gray-secondary dark:text-gray-primary">
                {t('legal_notices.company.keys.3')}:
              </span>{' '}
              <span className="font-light text-gray-secondary dark:text-gray-primary">
                <Trans
                  i18nKey="legal_notices.company.content.3"
                  components={[
                    '',
                    <OutsideLink
                      name="Send mail contact@go2scale.com"
                      to="mailto:contact@go2scale.com"
                      title={t('action:mail_contact_go2scale')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-primary-default"
                    >
                      {' '}
                      contact@go2scale.com{' '}
                    </OutsideLink>,
                    '',
                  ]}
                />
              </span>
            </span>
            <br />
            <span>
              <span className="font-black text-gray-secondary dark:text-gray-primary">
                {t('legal_notices.company.keys.4')}:
              </span>{' '}
              <span className="font-light text-gray-secondary dark:text-gray-primary">
                <Trans
                  i18nKey="legal_notices.company.content.3"
                  components={[
                    '',
                    <OutsideLink
                      name="Send mail contact@go2scale.com"
                      to="mailto:contact@go2scale.com"
                      title={t('action:mail_contact_go2scale')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-primary-default"
                    >
                      {' '}
                      contact@go2scale.com{' '}
                    </OutsideLink>,
                    '',
                  ]}
                />
              </span>
            </span>
          </div>
          <Title
            bold
            color={'text-secondary-default'}
            classOverrides={['text-center my-4']}
            size={'h2'}
          >
            {t('legal_notices.titles.1')}
          </Title>
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('legal_notices.provider.content.0')}
          </Typography>
          <div>
            <span>
              <span className="font-black text-gray-secondary dark:text-gray-primary">
                {t('legal_notices.provider.keys.0')}:
              </span>{' '}
              <span className="font-light text-gray-secondary dark:text-gray-primary">
                <OutsideLink
                  name="Scaleway website"
                  to="https://www.scaleway.com/en/"
                  title={t('action:mail_contact_go2scale')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-primary-default"
                >
                  {' '}
                  https://www.scaleway.com/en/{' '}
                </OutsideLink>
              </span>
            </span>
            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.provider.keys.1')}
              value={t('legal_notices.provider.content.2')}
            />
            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.provider.keys.2')}
              value={t('legal_notices.provider.content.3')}
            />
            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.provider.keys.3')}
              value={t('legal_notices.provider.content.4')}
            />

            <br />
            <ListKeyValue
              light
              keyStr={t('legal_notices.provider.keys.4')}
              value={t('legal_notices.provider.content.5')}
            />
          </div>
          <Title
            bold
            color={'text-secondary-default'}
            classOverrides={['text-center my-4']}
            size={'h3'}
          >
            {t('legal_notices.titles.2')}
          </Title>
          <ListKeyLink
            keyStr={t('legal_notices.more_info.keys.0')}
            value={t('legal_notices.more_info.content.0')}
            to={t('legal_notices.more_info.content.0')}
          />
          <br />
          <ListKeyLink
            keyStr={t('legal_notices.more_info.keys.1')}
            value={t('legal_notices.more_info.content.1')}
            to={t('legal_notices.more_info.content.1')}
          />
          <Title
            bold
            color={'text-secondary-default'}
            classOverrides={['text-center my-4']}
            size={'h2'}
          >
            {t('legal_notices.titles.3')}
          </Title>
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
            font="ubuntu"
          >
            <Trans i18nKey="legal_notices.content.text">
              The official CI/CD resources are hosted in a public repository on
              <OutsideLink
                name={'GitLab website'}
                to="https://GitLab.com"
                target="_blank"
                rel="noopener noreferrer"
                className={'underline text-primary-default hover:opacity-70'}
              >
                GitLab.com
              </OutsideLink>
              .
            </Trans>
          </Typography>
          <br />
          <ListKeyValue
            light
            keyStr={t('legal_notices.content.key')}
            value={t('legal_notices.content.value')}
          />
        </div>
      </div>
    </Page>
  );
};

type ListKeyLinkProps = {
  keyStr: string;
  value: string;
  to: string;
  title?: string;
  light?: boolean;
};

/**
 * @param {string} keyStr -- cannot be named key as is React reserved property
 * @param {string} value
 */
export const ListKeyLink: React.FC<ListKeyLinkProps> = ({
  keyStr,
  value,
  to,
  title,
}) => (
  <span>
    <span className="font-black text-gray-secondary dark:text-gray-primary">
      {keyStr}:
    </span>{' '}
    <span className="font-light text-gray-secondary dark:text-gray-primary">
      <OutsideLink
        name="Redirect to scaleway website"
        to={to}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
        className="underline text-primary-default"
      >
        {value}
      </OutsideLink>
    </span>
  </span>
);
