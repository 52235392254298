import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '@utils/classes.util';
import { useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput.component';
import { TiersFilterSelector } from '../TiersFilterSelector/TiersFilterSelector.component';

type Props = {
  control: Control<any>;
  applyFilters: () => void;
  resetFilters: () => void;
};

export const FiltersMobileTab: React.FC<Props> = ({
  control,
  applyFilters,
  resetFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  const { location } = useHistory();
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'mb-3 w-72 lg:w-125 relative block',
        location.pathname.includes('dashboard') ? 'xl:hidden' : 'lg:hidden',
      )}
    >
      <button
        className={cn(
          'p-2 bg-gray-primary dark:bg-secondary-default flex justify-center cursor-pointer shadow w-full',
          isOpen ? 'rounded-tr-lg rounded-tl-lg' : 'rounded-lg',
        )}
        onClick={() => toggleContent()}
      >
        <Typography
          color="text-secondary-default"
          font="ubuntu"
          size="xl"
          classOverrides={[
            'dark:text-gray-primary font-black text-center mx-4',
          ]}
        >
          {t('hub.filters.title')}
        </Typography>
        <div className="max-w-max absolute right-2">
          <FontAwesomeIcon
            className={cn(
              'text-gray-secondary dark:text-gray-primary transition-transform duration-300 mt-0.5 text-2xl cursor-pointer',
              isOpen ? 'transform rotate-180' : '',
            )}
            icon={faCaretDown}
          />
        </div>
      </button>
      {isOpen && (
        <div className="shadow rounded-b-lg px-4 text-center bg-gray-primary dark:bg-secondary-default block left-12 2xl:left-5/20 top-24">
          <div className=" pt-3 mb-6" onClick={() => resetFilters()}>
            <Typography
              classOverrides={[
                'dark:text-gray-primary underline font-semibold cursor-pointer',
              ]}
              color="text-gray-secondary"
            >
              {t('hub.filters.reset')}
            </Typography>
          </div>
          <Title
            font="comfortaa"
            size="h3"
            color="text-gray-secondary"
            bold
            classOverrides={['mb-4']}
          >
            {t('hub.filters.tiers.title')}
          </Title>
          <TiersFilterSelector
            control={control}
            classOverrides={['ml-16 lg:ml-44']}
          />
          <Title
            font="comfortaa"
            size="h3"
            color="text-gray-secondary"
            bold
            classOverrides={['mt-4 mb-1']}
          >
            {t('hub.filters.label.title')}
          </Title>
          <div className="flex justify-center">
            <LabelSelectInput
              classOverrides={['w-48']}
              control={control}
              inputAndDisplayContainerClassOverrides={['flex flex-col ']}
              inputClassOverrides={['flex-shrink-0 w-48 md:mr-4']}
            />
          </div>
          <PrimaryButton
            content={t('action:apply')}
            bgColor="bg-secondary-default"
            classOverrides={['px-12 dark:bg-dark-primary']}
            action={applyFilters}
          />
        </div>
      )}
    </div>
  );
};
