import { API_URL } from 'common/constants/routes';

export const buildRoute = (path: string, ...queryParams: string[]) => {
  if (!path.startsWith('/')) path = '/' + path;
  path = queryParams.length > 0 ? path.concat('?') : path;

  // Remove empty string query params to avoid unecessary '&'
  const filteredQueryParams = queryParams.filter((q) => q.length !== 0);
  filteredQueryParams.forEach((queryParam, i) => {
    path =
      i !== filteredQueryParams.length - 1
        ? path.concat(queryParam + '&')
        : path.concat(queryParam);
  });

  return `${API_URL}${path}`;
};
