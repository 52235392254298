import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faThumbsUp as faThumbsUpRegular,
  faThumbsDown as faThumbsDownRegular,
} from '@fortawesome/free-regular-svg-icons';
import { cn } from '@utils/classes.util';
import { Size } from '@typings/ClassOverrides.type';
import mixpanel from '@utils/mixpanel.util';
import Plausible from 'plausible-tracker';

export enum RateType {
  /**
   * Down vote
   */
  NEGATIVE = 'down',
  /**
   * If the user hasn't voted yet, or is cancelling his vote
   */
  NONE = '',
  /**
   * Upvote
   */
  POSITIVE = 'up',
}

type Props = {
  /**
   * number of upvotes
   */
  upRates: number | null;
  /**
   * Number of downvotes
   */
  downRates: number | null;
  /**
   * Has the user already upvote (icon is solid or regular depending on it)
   */
  rate: RateType;
  action?: (rateType: RateType) => void;
  mobileTextSize?: Size;
  textSize?: Size;
  disabledButtons?: boolean;
};

const RatingBox: React.FC<Props> = ({
  upRates,
  downRates,
  rate,
  action: actionSrc,
  mobileTextSize,
  textSize,
  disabledButtons = false,
}) => {
  const { trackEvent } = Plausible();
  const action = (rateType: RateType) => {
    mixpanel.track(`[Ratings] Job rated with a thumbs ${rateType}`);
    trackEvent(`Job rated with a thumbs ${rateType}`);
    if (actionSrc) {
      actionSrc(rateType);
    }
  };
  const iconClasses =
    'hover:animate-pulse hover:text-gray-700 transition duration-300';

  return (
    <div
      className={cn(
        'inline-flex items-center text-white min-w-max lg:ml-2',
        textSize && `lg:text-${textSize}`,
        mobileTextSize && `text-${mobileTextSize}`,
      )}
    >
      <div className="mx-1">
        <span data-testid="rating-up-value" className="pr-1">
          {upRates}
        </span>
        {action ? (
          <FontAwesomeIcon
            data-testid={'rating-up-icon'}
            onClick={() =>
              !disabledButtons ? action(RateType.POSITIVE) : undefined
            }
            className={cn(
              iconClasses,
              'mb-1',
              disabledButtons ? '' : 'cursor-pointer',
            )}
            icon={
              rate === RateType.POSITIVE ? faThumbsUpSolid : faThumbsUpRegular
            }
          />
        ) : (
          <FontAwesomeIcon
            data-testid={'rating-up-icon'}
            icon={
              rate === RateType.POSITIVE ? faThumbsUpSolid : faThumbsUpRegular
            }
          />
        )}
      </div>
      <div className="mx-1 ml-2">
        {action ? (
          <FontAwesomeIcon
            className={cn(iconClasses, disabledButtons ? '' : 'cursor-pointer')}
            onClick={() =>
              !disabledButtons ? action(RateType.NEGATIVE) : undefined
            }
            data-testid={'rating-down-icon'}
            icon={
              rate === RateType.NEGATIVE
                ? faThumbsDownSolid
                : faThumbsDownRegular
            }
            flip={'horizontal'}
          />
        ) : (
          <FontAwesomeIcon
            icon={
              rate === RateType.NEGATIVE
                ? faThumbsDownSolid
                : faThumbsDownRegular
            }
            data-testid={'rating-down-icon'}
            flip={'horizontal'}
          />
        )}
        <span data-testid="rating-down-value" className="pl-1">
          {downRates}
        </span>
      </div>
    </div>
  );
};

export { RatingBox };
