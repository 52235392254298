import { CheckIcon } from '@components/Icons/CheckIcon/CheckIcon.component';
import { CloseIcon } from '@components/Icons/CloseIcon/CloseIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import { PricingFeatures } from '@typings/pricingFeatures';
import React from 'react';
import { LicenseType } from 'common/hooks/useLicense.hook';

type Props = {
  col: PricingFeatures;
  currentLicenseType?: LicenseType;
};

/**
 * Component used by the PricingTable to display Columns
 * @param col : All datas are display as row
 */

export const BillingCol: React.FC<Props> = ({ col, currentLicenseType }) => {
  const showValue = (
    key: string,
    value: string | number | boolean,
    index: number,
  ) => {
    if (
      key === 'description' ||
      key === 'isPopular' ||
      key === 'price' ||
      key === 'textButton'
    )
      return null;
    else if (key === 'name') {
      return (
        <Title
          key={`${key}-${index}`}
          size="h3"
          bold
          color={
            typeof value === 'string' &&
            currentLicenseType?.name.toLowerCase().includes(value.toLowerCase())
              ? 'text-primary-default'
              : 'text-secondary-default'
          }
          font={'ubuntu'}
          classOverrides={['py-5 text-center']}
        >
          {value}
        </Title>
      );
    } else {
      return (
        <Typography
          color="text-gray-secondary"
          key={`${key}-${index}`}
          size="sm"
          classOverrides={[
            cn(
              'w-full flex flex-col items-center justify-center h-14 dark:text-gray-primary',
              index % 2 === 0
                ? 'bg-white dark:bg-secondary-default'
                : 'dark:bg-dark-secondary dark:text-gray-primary',
            ),
          ]}
        >
          {value === 0 || !value || value === 'none' ? (
            <CloseIcon key={`${key}-${index}`} width={'18'} />
          ) : value === true ? (
            <CheckIcon
              key={`${key}-${index}`}
              color="text-primary-default"
              // Icon too big, need to reduce the size
              width={'18'}
            />
          ) : value === -1 ? (
            'Unlimited'
          ) : (
            value
          )}
        </Typography>
      );
    }
  };

  return (
    <>
      <div className="flex flex-col w-max">
        <div
          className={
            'text-lg flex flex-col divide-x-4 w-full divide-gray-input divide-opacity-70'
          }
        >
          {col &&
            Object.entries(col).map(
              ([key, value], index) =>
                typeof value !== 'function' && (
                  <span key={`${key}-${index}`} className="w-32">
                    {showValue(key, value, index)}
                  </span>
                ),
            )}
        </div>
      </div>
    </>
  );
};
