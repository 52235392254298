import { useCallback, useRef } from 'react';

/**
 * Receives a callback to be executed once an element is visible
 * Uses default options for the IntersectionObserver so "visible" is just when any part
 * of the element enters the viewport
 *
 * Returns a callback to be used as a value for a ref attribute
 * @param callback - Callback to execute once the element assigned the ref returned is visible
 * @see SearchJobsContainer for an implementation of this hook
 */
export const useOnVisible = (callback: () => void): ((node: any) => void) => {
  const observer = useRef<IntersectionObserver>();
  const elementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        // Determine if last item is visible
        if (entries[0].isIntersecting) {
          // Once visible execute the callback
          callback();
        }
      });
      if (node) observer.current.observe(node);
    },
    [callback],
  );
  return elementRef;
};
