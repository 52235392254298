import R2_wave from '@images/R2/R2_wave.svg';
import R2_thinking from '@images/R2/R2_thinking.svg';
import R2_happy from '@images/R2/R2_happy.svg';
import R2_marine from '@images/R2/R2_marine.svg';
import R2_glass from '@images/R2/R2_glass.svg';
import R2_pompomgirl from '@images/R2/R2_pompomgirl.svg';
import R2_sherlock from '@images/R2/R2_sherlock.svg';
import R2_moustache from '@images/R2/R2_moustache.svg';
import R2 from '@images/R2/R2.svg';
import R2_cosmonaut from '@images/R2/R2_cosmonaut.svg';
import R2_jedi from '@images/R2/R2_jedi.svg';

export const isContainingUppercase = (text: string): boolean =>
  text.toLowerCase() !== text;
export const isContainingLowercase = (text: string): boolean =>
  text.toUpperCase() !== text;
export const isContainingNumeric = (text: string): boolean =>
  /[0-9]+/.test(text);
export const isContainingSpecialCharIncludingSpaces = (text: string): boolean =>
  /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?£€~µ]/.test(text);
export const isContainingSpecialChar = (text: string): boolean =>
  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?£€~µ]/.test(text);
export const fullnameIsContainingSpecialChar = (text: string): boolean =>
  /^[^:;?\\,%^`"$*()_&@#]*$/.test(text);
export const isUrlFriendly = (text: string): boolean =>
  /^[a-zA-Z0-9_.-]*$/.test(text);
export const isUserNameCorrect = (text: string): boolean =>
  /^[\\.a-zA-Z0-9_-]+$/.test(text);
export const isJobOrProjectNameCorrect = (text: string): boolean =>
  /^[a-z0-9_-]{3,30}$/.test(text);
export const isJobPathCorrect = (text: string): boolean =>
  /^$|[a-zA-Z0-9_\- /]+$/.test(text);
export const isGitLabUrl = (text: string): boolean =>
  /^[a-zA-Z0-9_\-./]+$/.test(text);
export const isGitLabOrGitHubUrl = (text: string): boolean =>
  /^(https:\/\/gitlab.com\/.+)|^(https:\/\/github.com\/.+)/.test(text);
export const isGitLabWebUrl = (text: string): boolean =>
  /^([^http]|https:\/\/gitlab.com\/.+)/.test(text);
export const isGitHubWebUrl = (text: string): boolean =>
  /^([^http]|https:\/\/github.com\/.+)/.test(text);
export const isFollowingPasswordPolicy = (text: string): boolean => {
  return !(
    text.length < 8 ||
    !isContainingNumeric(text) ||
    !isContainingSpecialChar(text) ||
    !isContainingLowercase(text) ||
    !isContainingUppercase(text)
  );
};

export const isEmail = (text: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\]\\.,;:\s@"]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    text,
  );
export const isValidLinkedinURL = (text: string): boolean =>
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/.test(text);
export const isValidTwitter = (text: string): boolean =>
  /^@?[A-Za-z0-9_]+$/.test(text);

export interface PasswordCheckerState {
  /**
   * Password has the correct length
   */
  correctLength?: boolean;
  /**
   * Password has either the needed uppercase & lowercase
   */
  correctLowercaseUppercase?: boolean;
  /**
   * Password has at least the need special characters
   */
  correctSpecial?: boolean;
  /**
   * Password has a numeric
   */
  correctNumeric?: boolean;
  /**
   * Password match with the password confirmation input
   */
  passwordMatch?: boolean;
}

export const defaultPasswordCheckerState: PasswordCheckerState = {
  correctLength: false,
  correctSpecial: false,
  correctLowercaseUppercase: false,
  correctNumeric: false,
  passwordMatch: false,
};

/**
 * Replaces {n} in a string with the corresponding arg passed
 *
 * Example:
 *
 *  string: "This is {0} {1}"
 *  formatString(string, one, example)
 *
 *  Results in: "This is one example"
 *
 * Analogous to C# String.Format method (https://docs.microsoft.com/en-us/dotnet/api/system.string.format?view=net-6.0)
 *
 * @param {string} stringToFormat
 * @param {} args
 */
export const formatString = (
  stringToFormat: string,
  ...args: any[]
): string => {
  return stringToFormat.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

export const transformHighNumber = (usageCounter: Number) => {
  const usageCounterAsString = usageCounter.toString();

  if (usageCounter >= 1000000000) {
    /**
     * Append the millions digit if it is different from zero
     * Examples:
     *  1,500,000,000 => 1.5B
     *  3,782,000,000 => 3.7B
     *  25,692,000,000 => 25B
     */
    const millions = usageCounterAsString[usageCounterAsString.length - 9];
    const millionsString = millions !== '0' ? '.' + millions : '';

    return usageCounterAsString.slice(0, -9) + millionsString + 'B';
  } else if (usageCounter >= 1000000) {
    const thousands = usageCounterAsString[usageCounterAsString.length - 6];
    const thousandsString = thousands !== '0' ? '.' + thousands : '';

    return usageCounterAsString.slice(0, -6) + thousandsString + 'M';
  } else if (usageCounter >= 1000) {
    const hundreds = usageCounterAsString[usageCounterAsString.length - 3];
    const hundredsString = hundreds !== '0' ? '.' + hundreds : '';

    return usageCounterAsString.slice(0, -3) + hundredsString + 'K';
  } else {
    return usageCounterAsString;
  }
};

export const capitalizeFirstLetter = (
  str: string,
  locale = navigator.language,
) => {
  return str.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase(locale));
};

// List of R2 icons in order to randomize them in the navigation bar
export const R2Images = [
  R2_marine,
  R2_happy,
  R2_thinking,
  R2_wave,
  R2_glass,
  R2_pompomgirl,
  R2_jedi,
  R2_sherlock,
  R2_moustache,
  R2,
  R2_cosmonaut,
];
