import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
    testid?: string;
  };

export const ExclamationCircleIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '5',
  width = '5',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <path
        d="M11 0C4.91979 0 0 4.92022 0 11C0 17.0802 4.92022 22 11 22C17.0802 22 22 17.0798 22 11C22 4.91979 17.0798 0 11 0ZM11 20.2812C5.86983 20.2812 1.71875 16.1298 1.71875 11C1.71875 5.86983 5.87018 1.71875 11 1.71875C16.1302 1.71875 20.2812 5.87018 20.2812 11C20.2813 16.1302 16.1298 20.2812 11 20.2812Z"
        fill="currentColor"
      />
      <path
        d="M11.0024 5.45294C10.5351 5.45294 10.1562 5.84195 10.1562 6.32186V11.9174C10.1562 12.3973 10.5351 12.7863 11.0024 12.7863C11.4697 12.7863 11.8486 12.3973 11.8486 11.9174V6.32186C11.8486 5.84195 11.4697 5.45294 11.0024 5.45294Z"
        fill="currentColor"
      />
      <path
        d="M10.9996 16.171C11.6746 16.171 12.2218 15.6659 12.2218 15.0428C12.2218 14.4197 11.6746 13.9146 10.9996 13.9146C10.3246 13.9146 9.77734 14.4197 9.77734 15.0428C9.77734 15.6659 10.3246 16.171 10.9996 16.171Z"
        fill="currentColor"
      />
    </svg>
  );
};
