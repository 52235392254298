import React from 'react';
import { Dropdown } from '@components/Buttons/Dropdown/Dropdown.component';
import {
  BgColor,
  ClassOverrides,
  TextColor,
} from '@typings/ClassOverrides.type';
import { JobVersion } from '@jobs/models/JobVersion.model';

type Props = ClassOverrides & {
  versions: string[];
  deprecated: boolean;
  bgColor: BgColor;
  textColor: TextColor;
  action: (version: string) => void;
  selectedVersion?: JobVersion;
};

const VersionSelector: React.FC<Props> = ({
  deprecated,
  versions,
  classOverrides,
  bgColor,
  textColor,
  action,
  selectedVersion,
}) => {
  const overrides = classOverrides && [...classOverrides];

  return (
    <Dropdown
      content={selectedVersion?.tag ?? ''}
      items={versions.map((c) => ({
        content: c,
        action: () => action(c),
      }))}
      classOverrides={overrides}
      bgColor={deprecated ? 'bg-red-alert' : bgColor}
      borderColorWhenOpen={!deprecated ? 'border-secondary-default' : undefined}
      textColor={deprecated ? 'text-white' : textColor}
      itemsTextColor={deprecated ? 'text-red-alert' : 'text-secondary-default'}
      itemsDivideColor={
        deprecated ? 'divide-red-alert' : 'divide-secondary-default'
      }
      fontSize="lg"
    />
  );
};

export { VersionSelector };
