import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { GitLabButton } from '@components/Buttons/GitLabButton/GitLabButton.component';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { toastWarning } from '@components/Toasters/Toaster.component';
import { UiNodeInputAttributes } from '@ory/kratos-client';
import { useQuery } from 'common/hooks/useQueryParam.hook';
import { useKratosSettingsFlowWithOidcMethod } from 'kratos/flows/settings.flow';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import { GitHubButton } from '@components/Buttons/GitHubButton/GitHubButton.component';

type Props = {};

export const ThirdPartyCard: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { register, setValue } = useForm();
  const flowId = useQuery().get('flow');
  const [flow] = useKratosSettingsFlowWithOidcMethod(flowId);

  const gitLabLinkUnlinkNode = flow?.ui.nodes.find(
    (t) =>
      isUiNodeInputAttributes(t.attributes) &&
      ((t.attributes.name === 'link' && t.attributes.value === 'gitlab') ||
        (t.attributes.name === 'unlink' && t.attributes.value === 'gitlab')),
  );

  const gitLabLinkUnlinkNodeName = gitLabLinkUnlinkNode
    ? (gitLabLinkUnlinkNode?.attributes as UiNodeInputAttributes).name
    : '';

  useEffect(() => {
    const gitLabLinkUnlinkNodeValue = gitLabLinkUnlinkNode
      ? (gitLabLinkUnlinkNode?.attributes as UiNodeInputAttributes).value
      : '';

    setValue(gitLabLinkUnlinkNodeName, gitLabLinkUnlinkNodeValue);
  }, [flow, gitLabLinkUnlinkNode, gitLabLinkUnlinkNodeName, setValue]);

  const showToast = () => {
    if (gitLabLinkUnlinkNodeName === 'link') {
      toastWarning(t('toast.linkRequiredToAddJob'));
    }
  };

  const gitHubLinkUnlinkNode = flow?.ui.nodes.find(
    (t) =>
      isUiNodeInputAttributes(t.attributes) &&
      ((t.attributes.name === 'link' && t.attributes.value === 'github') ||
        (t.attributes.name === 'unlink' && t.attributes.value === 'github')),
  );
  const gitHubLinkUnlinkNodeName = gitHubLinkUnlinkNode
    ? (gitHubLinkUnlinkNode?.attributes as UiNodeInputAttributes).name
    : '';

  useEffect(() => {
    const gitHubLinkUnlinkNodeValue = gitHubLinkUnlinkNode
      ? (gitHubLinkUnlinkNode?.attributes as UiNodeInputAttributes).value
      : '';

    setValue(gitHubLinkUnlinkNodeName, gitHubLinkUnlinkNodeValue);
  }, [flow, gitHubLinkUnlinkNode, gitHubLinkUnlinkNodeName, setValue]);

  const isGitHubAccountLinked =
    gitHubLinkUnlinkNodeName === 'unlink' || gitHubLinkUnlinkNode === undefined;

  const isGitLabAccountLinked =
    gitLabLinkUnlinkNodeName === 'unlink' || gitLabLinkUnlinkNode === undefined;

  return (
    <div className="rounded-xl shadow-lg bg-gray-100 dark:bg-secondary-default md:w-104 w-full p-8 inline-block mb-8">
      <span className="decoration-clone">
        <div className="mb-4">
          <Title size={'h2'} color={'text-secondary-default'} bold>
            {t('account.title_link')}
          </Title>
        </div>
        <Typography
          size="sm"
          color="text-gray-secondary"
          classOverrides={['mx-auto dark:text-gray-primary']}
          font={'comfortaa'}
        >
          {isGitLabAccountLinked
            ? t('account.link_description_gitlab_linked')
            : t('account.link_description')}
        </Typography>
        <div className="text-center">
          {/* Link GitLab account */}
          <div className="justify-center w-full flex">
            {flow && flow.ui.method && (
              <form action={flow.ui.action} method={flow.ui.method}>
                <GitLabButton
                  content={
                    isGitLabAccountLinked
                      ? t('action:gitlab_instance_linked')
                      : t('action:gitlab_instance_unlinked')
                  }
                  showLinkActivation={true}
                  isAccountLinked={isGitLabAccountLinked}
                  bgColor={'bg-white'}
                  classOverrides={[
                    'flex flex-row flex-wrap w-64 sm:w-80 py-1 mt-3 h-14',
                    !gitLabLinkUnlinkNodeName
                      ? 'bg-secondary-dark dark:bg-gray-third'
                      : 'dark:bg-primary-lightest',
                  ]}
                  color={
                    !gitLabLinkUnlinkNodeName
                      ? 'text-gray-primary'
                      : 'text-gray-secondary'
                  }
                  register={
                    gitLabLinkUnlinkNodeName
                      ? register(gitLabLinkUnlinkNodeName)
                      : undefined
                  }
                  submitButton
                  disabled={!gitLabLinkUnlinkNodeName}
                  node={gitLabLinkUnlinkNode}
                />
                {gitLabLinkUnlinkNodeName && (
                  <input
                    type={'hidden'}
                    id={'gitlab-csrf_token'}
                    name={'csrf_token'}
                    required
                    value={
                      (
                        flow.ui.nodes.find(
                          (t) =>
                            isUiNodeInputAttributes(t.attributes) &&
                            t.attributes.name === 'csrf_token',
                        )?.attributes as UiNodeInputAttributes
                      ).value
                    }
                  />
                )}

                <GitHubButton
                  content={
                    isGitHubAccountLinked
                      ? t('action:github_instance_linked')
                      : t('action:github_instance_unlinked')
                  }
                  showLinkActivation={true}
                  isAccountLinked={isGitHubAccountLinked}
                  bgColor={'bg-white'}
                  classOverrides={[
                    'flex flex-row flex-wrap w-64 sm:w-80 py-1 mt-3 h-14',
                    !gitHubLinkUnlinkNodeName
                      ? 'bg-secondary-dark dark:bg-gray-third'
                      : 'dark:bg-primary-lightest',
                  ]}
                  color={
                    !gitHubLinkUnlinkNodeName
                      ? 'text-gray-primary'
                      : 'text-gray-secondary'
                  }
                  register={
                    gitHubLinkUnlinkNodeName
                      ? register(gitHubLinkUnlinkNodeName)
                      : undefined
                  }
                  submitButton
                  node={gitHubLinkUnlinkNode}
                  disabled={!gitHubLinkUnlinkNodeName}
                />
                {gitHubLinkUnlinkNodeName && (
                  <input
                    type={'hidden'}
                    id={'github-csrf_token'}
                    name={'csrf_token'}
                    required
                    value={
                      (
                        flow.ui.nodes.find(
                          (t) =>
                            isUiNodeInputAttributes(t.attributes) &&
                            t.attributes.name === 'csrf_token',
                        )?.attributes as UiNodeInputAttributes
                      ).value
                    }
                  />
                )}
              </form>
            )}
          </div>
          <div className="my-4 relative">
            <TrackedLink to="/u/linkjob" name="link a job">
              <PrimaryButton
                content={t('action:link_job_from_account')}
                font={'comfortaa'}
                action={() => {}}
                disabled={gitLabLinkUnlinkNodeName === 'link'}
                classOverrides={[
                  'mx-auto xl:text-md my-0 dark:bg-dark-secondary',
                ]}
              />
            </TrackedLink>
            {/* Since events don't bubble up in Firefox we use this invisible element
                above the button to show a toaster when user's account is not linked */}
            {gitLabLinkUnlinkNodeName === 'link' && (
              <div
                onClick={() => showToast()}
                className="cursor-pointer w-full absolute top-0 left-0 bottom-0"
              ></div>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};
