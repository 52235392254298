import {
  InputLabel,
  InputProps,
} from '@components/Form/StyledField/StyledField.component';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { CameraIcon } from '@components/Icons/CameraIcon/CameraIcon';
import { SmallArrowIcon } from '@components/Icons/SmallArrowIcon/SmallArrowIcon';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { hexaIcons } from 'common/constants/hexaIcons';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';

type Props = ClassOverrides &
  InputProps<InputHTMLAttributes<HTMLInputElement>> & {
    buttonClassOverrides?: string[];
    defaultValue?: string;
    register: UseFormRegisterReturn;
    setValue: UseFormSetValue<any>;
    showError?: boolean;
  };

/**
 * @param {boolean} showError - Whether to display the div for the hidden input's error
 */
export const IconsDropdown: React.FC<Props> = ({
  buttonClassOverrides = [],
  classOverrides = [],
  defaultValue,
  label,
  register,
  setValue,
  showError = true,
}) => {
  const icons = hexaIcons;
  const [iconSelected, setIconSelected] = useState<string>(
    defaultValue ? defaultValue : '',
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef(null);
  const onClickOutside = useCallback(() => {
    if (isOpen) setIsOpen(!isOpen);
  }, [isOpen]);
  useOnClickOutside(ref, onClickOutside);

  useEffect(() => {
    setValue('icon', iconSelected);
  }, [iconSelected, setValue]);

  return (
    <div className={cn('relative w-max', ...classOverrides)} ref={ref}>
      <StyledInput
        id={label}
        register={register}
        type="hidden"
        classOverrides={['hidden']}
        data-testid="icon-input"
        showError={showError}
      />
      <InputLabel
        classOverrides={['flex justify-center dark:text-gray-primary']}
        label={label}
      />
      <div
        onClick={toggleDropdown}
        className={cn(
          'rounded-full bg-white dark:bg-gray-third dark:text-gray-primary shadow-lg w-16 h-16 z-40 flex items-center justify-center pt-3 flex-col relative cursor-pointer',
          ...buttonClassOverrides,
        )}
        data-testid="dropdown-icon"
      >
        {iconSelected ? (
          <div className="text-3xl" data-testid="icon-selected">
            {iconSelected}
          </div>
        ) : (
          <CameraIcon
            classOverrides={[
              'w-12 h-12 md:w-10 md:h-10 dark:text-gray-primary',
            ]}
            color="text-secondary-default"
            testid="default-icon"
          />
        )}
        <SmallArrowIcon
          height="15"
          width="15"
          color="text-secondary-default"
          classOverrides={[
            isOpen && 'transform rotate-180',
            'dark:text-gray-primary',
          ]}
        />
      </div>
      {isOpen && (
        <motion.ul
          className={cn(
            'w-36 transform -translate-x-1/2 left-1/2 -mt-3 absolute bg-white z-30 p-4' +
              ' rounded-md shadow-lg items-center justify-center dark:bg-gray-third ' +
              'ring-opacity-5 max-h-80 flex flex-col flex-wrap pr-2 pl-2',
          )}
          data-testid="dropdown-items"
          aria-labelledby="icons"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {icons.map((icon, index) => {
            return (
              <li
                key={'icon-0' + index}
                className={cn(
                  'w-10 bg-white dark:bg-gray-third text-center cursor-pointer hover:bg-gray-100 text-xl',
                )}
                onClick={(e) => setIconSelected(String.fromCodePoint(icon))}
              >
                {String.fromCodePoint(icon)}
              </li>
            );
          })}
        </motion.ul>
      )}
    </div>
  );
};
