import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput.component';
import { TiersFilterSelector } from '../TiersFilterSelector/TiersFilterSelector.component';

type Props = {
  control: Control<any>;
  applyFilters: () => void;
  resetFilters: () => void;
};

export type Label = {
  name: string;
};

export const FiltersTab: React.FC<Props> = ({
  control,
  applyFilters,
  resetFilters,
}) => {
  const { t } = useTranslation();
  const { location } = useHistory();
  return (
    <div
      className={cn(
        'hidden -ml-12 mt-7 sticky top-32 transition-all duration-300 ease-in delay-1000 h-max',
        location.pathname.includes('dashboard') ? 'xl:block' : 'lg:block',
      )}
    >
      <Title
        bold
        size="span"
        color="text-secondary-default"
        classOverrides={['text-3xl dark:text-gray-primary']}
      >
        {t('hub.filters.title')}
      </Title>
      <div className="mt-3 mb-6" onClick={() => resetFilters()}>
        <Typography
          classOverrides={[
            'dark:text-gray-primary underline font-semibold cursor-pointer',
          ]}
          color="text-gray-secondary"
        >
          {t('hub.filters.reset')}
        </Typography>
      </div>
      <Title
        font="comfortaa"
        size="h3"
        color="text-gray-secondary"
        bold
        classOverrides={['pl-2 mb-4']}
      >
        {t('hub.filters.tiers.title')}
      </Title>
      <TiersFilterSelector control={control} />
      <Title
        font="comfortaa"
        size="h3"
        color="text-gray-secondary"
        bold
        classOverrides={['pl-2 mt-4 mb-1']}
      >
        {t('hub.filters.label.title')}
      </Title>
      <LabelSelectInput
        classOverrides={['w-48']}
        control={control}
        inputAndDisplayContainerClassOverrides={['flex flex-col ']}
        inputClassOverrides={['flex-shrink-0 w-48 md:mr-4']}
      />
      <PrimaryButton
        content={t('action:apply')}
        bgColor="bg-secondary-default"
        classOverrides={['px-12 mt-8 mx-6']}
        margin={'m-0'}
        action={applyFilters}
      />
    </div>
  );
};
