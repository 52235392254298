import React, { useContext, useEffect } from 'react';
import { Page } from '@components/Page/Page.component';
import { Trans, useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { simpleFadeIn } from '@utils/motion.util';
import { useKratosVerificationFlow } from '../../../../kratos/flows/verification.flow';
import { useQuery } from '../../../../common/hooks/useQueryParam.hook';
import {
  SelfServiceVerificationFlow,
  SelfServiceVerificationFlowState,
} from '@ory/kratos-client';
import { useForm } from 'react-hook-form';
import { Typography } from '@components/Typography/Typography.component';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import { useHistory } from 'react-router-dom';
import { ErrorLayout } from '@components/Page/ErrorLayout/ErrorLayout.component';
import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { ThemeContext } from 'common/contexts/theme.context';

export const VerifyPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const onSucceed = (data: unknown) => {
    toastSuccess(t('verify.link_sent'));
    reset();

    const flow = data as SelfServiceVerificationFlow;
    if (flow.state === SelfServiceVerificationFlowState.SentEmail)
      history.push('/u/signin');
  };
  const flowId = useQuery().get('flow');
  const [flow, submitHandler] = useKratosVerificationFlow(flowId, onSucceed);

  useEffect(() => {
    if (!flow || flow.state === SelfServiceVerificationFlowState.ChooseMethod)
      return;

    if (flow.state === SelfServiceVerificationFlowState.PassedChallenge)
      history.push('/u/signin');
  }, [flow, history]);
  const { theme } = useContext(ThemeContext);

  // We want this page to be loading until we have a flow since we redirect the user when
  // the flow is a passed challenge
  return (
    <Page
      title={t('verify.title')}
      description={t('verify.meta_description')}
      isLoading={!flow}
      navBarCutter
    >
      <ErrorLayout
        classOverrides={['w-4/6 lg:w-4/12']}
        i18nKey="verify.h1"
        title={'Oops, \n your link has expired'}
      >
        <Typography
          classOverrides={['mb-6 dark:text-gray-primary']}
          color={'text-gray-secondary'}
        >
          <Trans i18nKey={'verify.description'}>
            We apologize for the inconvenience.
            <br />
            <br />
            In order to obtain a new link to change your password, give us the
            email where you want to receive it!
          </Trans>
        </Typography>
        <motion.span
          variants={simpleFadeIn()}
          data-testid="http-error-sentence"
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            {/* Check on expires_at is here because of typescript compilation error */}
            {flow && flow.expires_at !== undefined && (
              <KratosFormInputs
                bgColor={
                  theme === 'light'
                    ? 'bg-secondary-semi-light'
                    : 'bg-gray-third'
                }
                classOverrides={['dark:text-gray-primary']}
                flowUiNodes={flow.ui.nodes}
                register={register}
                isSubmitting={isSubmitting}
                errors={errors}
              />
            )}
            <SubmitButton
              bgColor={
                theme === 'light'
                  ? 'bg-primary-default'
                  : 'bg-secondary-default'
              }
              classOverrides={['font-comfortaa w-32']}
              content={t('action:get_verify_link')}
              isSubmitting={isSubmitting}
              isValid={isValid}
              errors={errors}
            />
          </form>
        </motion.span>
      </ErrorLayout>
    </Page>
  );
};
