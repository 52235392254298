/**
 * This file is used for basics logical operations between JS types and JSX
 */

//Replace <1/> balise in text with <br/>
export const getNewlineText = (text: string) =>
  text.split('<1/>').map((t, i) => (
    <span key={`span-${i}`}>
      {t}
      <br />
    </span>
  ));
