import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { JobPolicy } from '@jobs/models/Job.model';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  owner: string;
  policy: JobPolicy;
};
const JobTitleDesktop: React.FC<Props> = ({ title, owner, policy }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div className="hidden relative lg:flex items-center ">
      <Title
        size="span"
        color="text-white"
        font="ubuntu"
        classOverrides={['text-2xl min-w-max']}
        bold
      >
        {owner + '/'}
      </Title>
      <Title
        size="h1"
        color="text-white"
        font="ubuntu"
        bold
        classOverrides={['pb-1 truncate lg:max-w-sm 2xl:max-w-lg ']}
      >
        {title}{' '}
      </Title>
      {policy === 'private' && (
        <FeatureFlag name="private-job">
          <Tooltip
            title={t('my_jobs.modify_your_job.policy.public.tooltip')}
            colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
            classOverrides={[
              'w-max flex items-center justify-center pl-2 pb-1',
            ]}
          >
            <LockIcon color="text-gray-primary" width="22" />
          </Tooltip>
        </FeatureFlag>
      )}
    </div>
  );
};

export { JobTitleDesktop };
