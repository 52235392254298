import React, { useEffect, useState } from 'react';
import { cn, isLg } from '@utils/classes.util';
import { GitlabTab } from '../GitlabTab/GitlabTab.component';
import { Job } from '../../../models/Job.model';
import { JobVersion } from '../../../models/JobVersion.model';
import { useWindowSize } from '../../../../common/hooks/useWindowSize.hook';
import { JobContentLayout } from '../../Layout/JobContentLayout.component';
import { JobContentLeft } from '../../Layout/JobContentLeft.component';
import { JobContentRight } from '../../Layout/JobContentRight.component';
import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../DetailsTab/DetailsTab.component';
import { DocTab } from '../DocTab/DocTab.component';
import { GitHubTab } from '../GitHubTab/GitHubTab.component';
import Plausible from 'plausible-tracker';
import mixpanel from 'mixpanel-browser';

type Props = {
  job?: Job;
  selectedVersion?: JobVersion;
};

/**
 * Job page content tabs (Details (documentation), .gitlab-ci.yml & job infos)
 * @param {string} selectedVersion
 * @param {Job} job
 */
const TabSelector: React.FC<Props> = ({ selectedVersion, job }) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [tabIndex, setTabIndex] = useState<0 | 1 | 2 | 3>(
    isLg(windowSize.width) ? 1 : 0,
  );
  const [sizeLg, setSizeLg] = useState(isLg(windowSize.width));
  const { trackEvent } = Plausible();

  useEffect(() => {
    const lg = isLg(windowSize.width);
    if (lg && tabIndex === 0) setTabIndex(1);
    setSizeLg(lg);
  }, [windowSize, tabIndex]);

  if (!job || !selectedVersion) return null;

  return (
    <section className="mt-5">
      <JobContentLayout
        classOverrides={['justify-center lg:justify-start w-full']}
      >
        <JobContentLeft
          justifyStart={false}
          classOverrides={[
            'font-comfortaa text-sm sm:text-base dark:text-gray-primary',
          ]}
        >
          <button
            className={cn(getClasses(tabIndex, 0), 'lg:hidden')}
            onClick={() => setTabIndex(0)}
            data-testid={'doc-tab-button'}
          >
            {t('job.tab.0')}
          </button>
          <button
            className={cn(getClasses(tabIndex, 1), 'pl-0')}
            onClick={() => setTabIndex(1)}
            data-testid={'details-tab-button'}
          >
            {t('job.tab.1')}
          </button>
          <button
            className={getClasses(tabIndex, 2)}
            onClick={() => {
              setTabIndex(2);
              trackEvent('JOB - GitLab code');
              mixpanel.track('[Tab] JOB - GitLab code');
            }}
            data-testid={'gitlab-tab-button'}
          >
            {t('job.tab.2')}
          </button>
          <button
            className={getClasses(tabIndex, 3)}
            onClick={() => {
              setTabIndex(3);
              trackEvent('JOB - GitHub code');
              mixpanel.track('[Tab] JOB - GitHub code');
            }}
            data-testid={'github-tab-button'}
          >
            {t('job.tab.3')}
          </button>
        </JobContentLeft>
      </JobContentLayout>
      <JobContentLayout
        classOverrides={['justify-between pb-24 md:pb-16 lg:pt-12']}
      >
        {sizeLg ? (
          <TabContentDesktop
            version={selectedVersion}
            job={job}
            tabIndex={tabIndex}
          />
        ) : (
          <TabContentMobile
            version={selectedVersion}
            job={job}
            tabIndex={tabIndex}
          />
        )}
      </JobContentLayout>
    </section>
  );
};

type TabContentProps = {
  tabIndex: 0 | 1 | 2 | 3;
  job: Job;
  version: JobVersion;
};

const TabContentMobile: React.FC<TabContentProps> = ({
  version,
  job,
  tabIndex,
}) => {
  switch (tabIndex) {
    case 0:
      return <DetailsTab job={job} version={version} />;
    case 1:
      return <DocTab version={version} />;
    case 2:
      return <GitlabTab version={version} />;
    case 3:
      return <GitHubTab />;
  }
};

const TabContentDesktop: React.FC<TabContentProps> = ({
  version,
  job,
  tabIndex,
}) => {
  const withLg = (component: JSX.Element) => (
    <>
      {component}
      <JobContentRight classOverrides={['hidden lg:block']}>
        <DetailsTab job={job} version={version} />
      </JobContentRight>
    </>
  );

  switch (tabIndex) {
    case 0:
    case 1:
      return withLg(
        <JobContentLeft>
          <DocTab version={version} />
        </JobContentLeft>,
      );
    case 2:
      return withLg(
        <GitlabTab
          classOverrides={[
            'w-7/12 md:mr-8 flex-shrink-0 md:w-8/12 xl:w-8/12 2xl:w-9/12',
          ]}
          version={version}
        />,
      );
    case 3:
      return withLg(
        <GitHubTab
          classOverrides={[
            'w-7/12 md:mr-8 flex-shrink-0 md:w-8/12 xl:w-8/12 2xl:w-9/12',
          ]}
        />,
      );
  }
};

const getClasses = (currentIndex: number, tabIndex: number) => {
  return cn(
    ' block hover:text-secondary-default dark:hover:text-primary-default border-b-2 ml-4 lg:ml-0 mr-4 lg:mr-16 ' +
      'hover:border-secondary-default font-light dark:text-gray-primary transition-all duration-300',
    tabIndex === currentIndex
      ? 'text-secondary-default dark:text-primary-default border-secondary-default font-bold'
      : 'text-secondary-dark hover:text-secondary-default hover:border-secondary-default ',
  );
};

export { TabSelector };
