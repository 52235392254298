export const API_URL = process.env.REACT_APP_API ?? 'http://localhost:8080';
export const DOCUMENTATION_URL =
  process.env.REACT_APP_DOC_URL ?? 'https://docs.r2devops.io';
export const KRATOS_URL =
  process.env.REACT_APP_API_IDENTITIES ?? 'http://localhost:4433';
export const JOB_MOCK_URL =
  'https://stoplight.io/mocks/go2scale/jobs:main/133820/job';
export const PROJECT_MOCK_URL =
  'https://stoplight.io/mocks/go2scale/projects:main/2834628/project';
export const GITLAB_API_PROJECTS_URL = 'https://gitlab.com/api/v4/projects/';
export const GITHUB_API_PROJECTS_URL = 'https://api.github.com/repos/';
export const GITLAB_API_URL = 'https://gitlab.com/api/v4';
export const GITHUB_API_URL = 'https://api.github.com';
export const API_ROUTES = {
  GET_LICENSE_SINGLE_PROJECT: (projectId: string) =>
    `${API_URL}/job/project/${projectId}`,
  GET_GITLAB_SINGLE_PROJECT: (projectId: string) =>
    `https://gitlab.com/api/v4/projects/${projectId}`,
  /**
   * GET - Get job's information
   * PATCH - Modify job
   */
  ACCESS_SINGLE_JOB: (ownerName: string, jobName: string) =>
    `/job/${ownerName}/${jobName}?ignore=true`,
  /**
   * GET - Fetch job's yaml most recent
   */
  GET_SINGLE_JOB_LATEST_YML: '/job/:ownerName/:jobName.yml?ignore=true',
  /**
   * GET - Fetch job's yaml based on a specific version tag
   */
  GET_SINGLE_JOB_SPECIFIC_YML:
    '/job/:ownerName/:jobName/:versionTag.yml?ignore=true',
  /**
   * GET - Fetch all jobs
   */
  GET_JOBS: '/job',
  /**
   * GET - Fetch all job's ratings
   * POST - Create a new rate for a job
   * DELETE - Delete the rate set by an user on a job
   */
  JOB_RATING: (jobId: string) => `/job/${jobId}/rating`,
  /**
   * POST - Create new job version
   */
  CREATE_JOB_VERSION: (ownerName: string, jobName: string) =>
    `/job/${ownerName}/${jobName}/version`,
  /**
   * GET - Get all user GitLab projects
   */
  GET_USER_GITLAB_PROJECTS: (gitLabUserName: string) =>
    `${GITLAB_API_URL}/users/${gitLabUserName}/projects`,
  /**
   * GET - Get all user GitLab projects
   */
  GET_USER_GITHUB_PROJECTS: (gitHubUserName: string) =>
    `${GITHUB_API_URL}/users/${gitHubUserName}/repos`,
  /**
   * POST - Post project information to trigger the pipeline generator
   */
  POST_USER_PROJECT_PIPELINE: '/job/pipeline',
  /**
   * POST - Post project information to trigger the light pipeline generator
   */
  POST_USER_PROJECT_LIGHT_PIPELINE: '/job/pipeline/light',
  /**
   * GET - Get user License information
   */
  GET_USER_LICENSE: API_URL + '/job/license',
  /**
   * POST - Create a checkout page
   */
  CREATE_CHECKOUT: '/job/license/checkout',
  /**
   * POST - Create a project customization
   */
  CREATE_PROJECT_CUSTOMIZATION: (projectID: string) =>
    `/job/project/${projectID}`,
};
