import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { CopyInputText } from '@components/Form/CopyInputText/CopyInputText.component';
import { R2wipIcon } from '@components/Icons/R2wipIcon/R2wipIcon.component';
import { SocialIcon } from '@components/Social/SocialIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import {
  faFacebookF,
  faLinkedinIn,
  faRedditAlien,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralCounter } from '../ReferralCounter/ReferralCounter.component';

const icons = [faFacebookF, faTwitter, faLinkedinIn, faRedditAlien];

export const Referral: React.FC = () => {
  const { t } = useTranslation();

  const socialIconLink = (index: number): string => {
    switch (index) {
      case 0:
        return 'https://www.facebook.com/sharer/sharer.php?u=urldemapage';
      case 1:
        return 'https://twitter.com/intent/tweet?url=urldemapage&text=The%20text%20you%20wanna%20add';
      case 2:
        return 'https://www.linkedin.com/shareArticle?mini=true&url=urldemapage';
      case 3:
        return 'https://reddit.com/submit?url=urldemapage&title=textyouwannashare';
      default:
        return '';
    }
  };

  return (
    <div className="text-center w-full">
      <Title
        size={'h1'}
        classOverrides={['mb-8']}
        color={'text-secondary-default'}
        bold
      >
        {t('referral.title')}
      </Title>

      <div className="flex flex-col items-center">
        <FeatureFlag name="referral-program" showOnActive={false}>
          <R2wipIcon width={'550'} />
          <Typography
            classOverrides={['inline-block mb-4 dark:text-gray-primary']}
            color="text-gray-secondary"
            size="sm"
            italic
          >
            {t('referral.instructions.0')}
          </Typography>
        </FeatureFlag>
        <FeatureFlag name="referral-program">
          <div className="flex flex-col xl:flex-row items-center divide-y-8 divide-transparent justify-center mb-2">
            <CopyInputText
              classOverrides={[
                'filter shadow-inner border-none rounded-2lg max-w-sm mr-5 my-4 dark:bg-gray-third dark:text-gray-primary',
              ]}
              defaultValue="Referral URL"
              disabled
              inputClassOverrides={['text-xs text-lg font-bold pl-6']}
            />
            <Typography
              color="text-gray-secondary"
              classOverrides={['mr-0 xl:mr-5 dark:text-gray-primary']}
            >
              {t('referral.instructions.1')}
            </Typography>
            <div className="flex my-4">
              {icons.map((icon, index) => (
                <SocialIcon
                  key={index + '-' + icon}
                  classOverrides={[
                    'h-10 w-10 rounded-2lg',
                    index === icons.length - 1 ? '' : 'mr-2',
                  ]}
                  link={socialIconLink(index)}
                  icon={icon}
                />
              ))}
            </div>
          </div>
          <div>
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('referral.instructions.2')}
            </Typography>
            <div>
              <ReferralCounter referralCount={0} />
            </div>
            <span>
              <Typography
                color="text-gray-secondary"
                size="sm"
                classOverrides={['dark:text-gray-primary']}
              >
                {t('referral.instructions.3')}
              </Typography>
            </span>
          </div>
        </FeatureFlag>
      </div>
    </div>
  );
};
