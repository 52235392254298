import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { Typography } from '@components/Typography/Typography.component';
import {
  SelfServiceLoginFlow,
  SubmitSelfServiceLoginFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import { ThemeContext } from 'common/contexts/theme.context';
import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KratosFormInputs } from '../../../kratos/components/KratosFormInputs/KratosFormInputs';
import { GrayBox } from '../GrayBox.component';

type Props = {
  flow?: SelfServiceLoginFlow;
  submitHandler: (
    body: SubmitSelfServiceLoginFlowWithPasswordMethodBody,
  ) => void;
};

export const SignInForm: React.FC<Props> = ({ flow, submitHandler }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  return (
    <GrayBox>
      <div
        className="grid grid-cols-1 pt-2 px-4 md:px-8 h-auto"
        data-testid="signin-form"
      >
        <AnimatePresence exitBeforeEnter>
          {flow ? (
            <form
              onSubmit={handleSubmit(submitHandler)}
              method={flow.ui.method}
              id="signinForm"
            >
              <KratosFormInputs
                flowUiNodes={flow.ui.nodes}
                register={register}
                isSubmitting={isSubmitting}
                errors={errors}
                classOverrides={['dark:text-gray-primary']}
                bgColor={
                  theme === 'dark'
                    ? 'bg-secondary-default-high-opacity'
                    : undefined
                }
              />
              <SubmitButton
                content={t('action:signin')}
                isSubmitting={isSubmitting}
                isValid={isValid}
                errors={errors}
                classOverrides={['dark:bg-dark-secondary']}
              />
            </form>
          ) : (
            <SpinnerIcon classOverrides={['my-8']} />
          )}
        </AnimatePresence>
        <TrackedLink
          name={'recovery'}
          to={'/u/recovery'}
          title={t('action:title.redirect_recovery')}
        >
          <Typography
            size="xs"
            color="text-gray-secondary"
            classOverrides={[
              'mx-auto my-3 underline block dark:text-gray-primary',
            ]}
          >
            {t('signin.hint')}
          </Typography>
        </TrackedLink>
      </div>
    </GrayBox>
  );
};
