import { StatusCodes } from 'http-status-codes';
import HttpError from 'standard-http-error';
import { Error } from '../types/error';

export enum ActionType {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PROGRESS = 'PROGRESS',
}

export interface Action<T> {
  type: ActionType;
  result?: T;
  error?: Error;
}

export const httpReducer =
  <T>() =>
  (state: any, action: Action<T>) => {
    switch (action.type) {
      case ActionType.SUCCESS:
        return action.result;
      case ActionType.FAIL:
        /**
         * We only want to throw the Error if it is of code 404
         * This error will be handled by the ErrorBoundary (see GlobalErrorBoundary component)
         */
        if (action?.error && action.error.code === StatusCodes.NOT_FOUND) {
          throw new HttpError(
            action.error ? +action.error.code : 400,
            action.error?.message,
          );
        }
        return null;
      case ActionType.PROGRESS:
        return null;
    }
  };
