import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const JobContentRight: React.FC<ClassOverrides> = ({
  children,
  classOverrides = [],
}) => {
  // There are some case where Right is empty n
  return (
    <div
      className={cn('w-0 text-center lg:w-80 flex-shrink-0', ...classOverrides)}
    >
      {children}
    </div>
  );
};
