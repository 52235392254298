import { DocumentationIcon } from '@components/Icons/DocumentationIcon/DocumentationIcon.component';
import { GeneratorIcon } from '@components/Icons/GeneratorIcon/GeneratorIcon.component';
import { HubIcon } from '@components/Icons/HubIcon/HubIcon.component';
import { LogOutIcon } from '@components/Icons/LogoutIcon/LogOutIcon.component';
import { MyJobsIcon } from '@components/Icons/MyJobsIcon/MyJobsIcon.component';
import { ProfileSettingsIcon } from '@components/Icons/ProfileSettingsIcon/ProfileSettingsIcon.ccomponent';
import { ProjectsIcon } from '@components/Icons/ProjectsIcon/ProjectsIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { ThemeToggle } from '@components/ThemeToggle/ThemeToggle.component';
import { Typography } from '@components/Typography/Typography.component';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '@utils/classes.util';
import {
  isAGeneralDashboardLink,
  isAResourcesLink,
  isASettingsLink,
} from '@utils/dashboard.util';
import { fadeInLeftVariant } from '@utils/motion.util';
import { R2Images } from '@utils/text.util';
import { DOCUMENTATION_LINK } from 'common/constants/general';
import { LG_SCREEN_SIZE } from 'common/constants/style.constant';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'user/contexts/user.context';
import { SideBarButton } from './SideBarButton.component';
import { SideBarLink } from './SideBarLink.component';

export type DashboardLink = {
  to: string;
  name: string;
  target?: string;
  title?: string;
  button?: string;
};

export const DashboardSideBar: React.FC = () => {
  const [links, setLinks] = useState<DashboardLink[] | undefined>();
  const [settingsLinks, setSettingsLinks] = useState<
    DashboardLink[] | undefined
  >();
  const [resourcesLinks, setResourcesLinks] = useState<
    DashboardLink[] | undefined
  >();
  const [generalLinks, setGeneralLinks] = useState<
    DashboardLink[] | undefined
  >();
  const { location, push: setLocation } = useHistory();
  const { t, i18n } = useTranslation();
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const [resourcesIsOpen, setResourcesIsOpen] = useState(false);
  const randomIndex = Math.floor(Math.random() * R2Images.length);
  const [randomR2Image, setRandomR2Image] = useState(R2Images[randomIndex]);
  useEffect(() => {
    setRandomR2Image(R2Images[randomIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const window = useWindowSize();
  const icons = [
    <ProjectsIcon color="text-gray-primary" classOverrides={['h-8 w-8']} />,
    <MyJobsIcon color="text-gray-primary" classOverrides={['w-8 h-8']} />,
    <GeneratorIcon color="text-gray-primary" />,
    <HubIcon color="text-gray-primary" />,
    <DocumentationIcon color="text-gray-primary" />,
  ];
  const currentIsASettingsLink = () => {
    switch (history.location.pathname) {
      case '/dashboard/profile':
      case '/dashboard/password':
      case '/dashboard/account':
      case '/dashboard/billing':
      case '/dashboard/notifications':
      case '/dashboard/referral':
        return true;
      default:
        return false;
    }
  };
  const currentIsAResourcesLink = () => {
    switch (history.location.pathname) {
      case '/dashboard/jobs':
      case '/dashboard/hub':
        return true;
      default:
        return false;
    }
  };
  useEffect(() => {
    let i = 0;
    while (i18n.exists('dashboard.links.' + i)) i++;
    let dashboardTrans: DashboardLink[] = [];
    for (let j = 0; j < i; j++) {
      dashboardTrans.push({
        name: t('dashboard.links.' + j + '.name'),
        to: t('dashboard.links.' + j + '.link'),
        target: i18n.exists('dashboard.links.' + j + '.target')
          ? t('dashboard.links.' + j + '.target')
          : undefined,
        title: i18n.exists('dashboard.links.' + j + '.title')
          ? t('dashboard.links.' + j + '.title')
          : undefined,
        button: i18n.exists('dashboard.links.' + j + '.button')
          ? t('dashboard.links.' + j + '.button')
          : undefined,
      });
    }
    setLinks(dashboardTrans);
  }, [i18n, t]);

  useEffect(() => {
    if (!links || (generalLinks && settingsLinks && resourcesLinks)) return;
    let settingsLinksToPush: DashboardLink[] = [];
    let resourcesLinksToPush: DashboardLink[] = [];
    let generalLinksTopPush: DashboardLink[] = [];
    for (let j = 0; j < links.length; j++) {
      let link = links[j];
      if (link && isASettingsLink(link.to)) {
        settingsLinksToPush.push(link);
      } else if (link && isAResourcesLink(link.to)) {
        resourcesLinksToPush.push(link);
      } else {
        generalLinksTopPush.push(link);
      }
    }
    setSettingsLinks(settingsLinksToPush);
    setResourcesLinks(resourcesLinksToPush);
    setGeneralLinks(generalLinksTopPush);
  }, [generalLinks, links, resourcesLinks, settingsLinks]);

  const history = useHistory();
  const { action } = useContext(UserContext);
  useEffect(() => {
    if (resourcesIsOpen || settingsIsOpen) return;
    if (isASettingsLink(location.pathname)) {
      setSettingsIsOpen(true);
    } else if (isAResourcesLink(location.pathname)) {
      setResourcesIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const actionOnClick = (link: string) => {
    if (isASettingsLink(link)) {
      setResourcesIsOpen(false);
    } else if (isAResourcesLink(link)) {
      setSettingsIsOpen(false);
    } else {
      setResourcesIsOpen(false);
      setSettingsIsOpen(false);
    }
  };
  return (
    <div className={cn('w-max bg-secondary-default-high-opacity shadow-2xl ')}>
      <div className="flex flex-col sticky top-0 h-screen overflow-auto">
        <div className="flex justify-center my-4">
          <TrackedLink
            name={'R2Devops Logo'}
            to={'/dashboard'}
            data-testid={'navbar-r2'}
            className={cn(
              'flex h-full items-center relative',
              randomR2Image === R2Images[6]
                ? 'w-12 lg:w-16'
                : randomR2Image === R2Images[5]
                ? 'w-16 lg:w-24'
                : 'w-6 lg:w-12',
            )}
          >
            <motion.img
              variants={fadeInLeftVariant()}
              src={randomR2Image}
              alt={t('r2_alt')}
              title={t('action:title.redirect_dashboard')}
            />
          </TrackedLink>
        </div>

        <ThemeToggle classOverrides={['mb-4']} />
        {links?.map((link, index) => {
          if (isAGeneralDashboardLink(link.to)) {
            return (
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: index * 0.1 },
                }}
                title={link.title ? link.title : undefined}
                key={link.name + '-' + index}
              >
                <SideBarButton
                  key={link.name}
                  classOverrides={[
                    'lg:justify-start font-bold',
                    isAGeneralDashboardLink(history.location.pathname) &&
                      'shadow-button-active-inner bg-secondary-default ',
                  ]}
                  content={link.name}
                  action={() => {
                    actionOnClick(link.to);
                    setLocation(link.to);
                  }}
                >
                  <div className="w-full lg:w-12">{icons[index]}</div>
                </SideBarButton>
              </motion.div>
            );
          } else {
            return null;
          }
        })}
        <button
          onClick={() => {
            if (window.width < LG_SCREEN_SIZE) {
              history.push('/dashboard/jobs');
            } else {
              setResourcesIsOpen(!resourcesIsOpen);
              if (settingsIsOpen) {
                setSettingsIsOpen(false);
              }
            }
          }}
          className={cn(
            'hover:opacity-70 justify-center lg:justify-start flex px-3 py-4 items-center cursor-pointer',
            resourcesIsOpen && 'font-bold',
            window.width < LG_SCREEN_SIZE &&
              currentIsAResourcesLink() &&
              'shadow-button-active-inner bg-secondary-default ',
          )}
        >
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.3 },
            }}
            className="text-white flex lg:flex-row flex-col relative items-center justify-center lg:justify-start"
          >
            <div className="w-full lg:w-12">
              <MyJobsIcon
                color="text-gray-primary"
                classOverrides={['w-8 h-8']}
              />
            </div>
            <Typography
              color="text-white"
              classOverrides={[
                'py-3 md:py-0.5 hidden lg:block',
                resourcesIsOpen && 'underline decoration-white',
              ]}
              size="sm"
            >
              {t('dashboard.parent_links.1')}
            </Typography>
            <FontAwesomeIcon
              className={cn(
                ' lg:ml-3 text-center mt-0.5 w-full lh:text-right text-gray-primary transition-transform duration-300 text-2xl',
                resourcesIsOpen ? 'transform rotate-180' : '',
                window.width < LG_SCREEN_SIZE && 'hidden',
              )}
              icon={faCaretDown}
            />
          </motion.div>
        </button>
        <div className="bg-secondary-default flex rounded-xl text-center flex-col">
          {resourcesIsOpen &&
            window.width > LG_SCREEN_SIZE &&
            resourcesLinks?.map((link, index) => {
              return (
                <motion.div
                  initial={{ opacity: 0, y: -100 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: index * 0.1 },
                  }}
                  title={link.title ? link.title : undefined}
                  key={link.name + '-' + index}
                >
                  <SideBarLink
                    link={link}
                    key={link.name}
                    isRoute={link.name === 'Generator' ? true : false}
                    subLink
                    rounded={cn(
                      index === 0 && 'rounded-t-lg',
                      index === resourcesLinks.length - 1 && 'rounded-b-lg',
                    )}
                    classOverrides={['flex justify-center ']}
                    setResourcesIsOpen={setResourcesIsOpen}
                    setSettingsIsOpen={setSettingsIsOpen}
                  />
                </motion.div>
              );
            })}
        </div>
        <button
          onClick={() => {
            if (window.width < LG_SCREEN_SIZE) {
              history.push('/dashboard/profile');
            } else {
              setSettingsIsOpen(!settingsIsOpen);
              if (resourcesIsOpen) {
                setResourcesIsOpen(false);
              }
            }
          }}
          className={cn(
            'hover:opacity-70 justify-center lg:justify-start flex px-3 py-3 items-center cursor-pointer',
            settingsIsOpen && 'font-bold',
            window.width < LG_SCREEN_SIZE &&
              currentIsASettingsLink() &&
              'shadow-button-active-inner bg-secondary-default ',
          )}
        >
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2 },
            }}
            className="text-white flex lg:flex-row flex-col relative items-center justify-center lg:justify-start"
          >
            <div className="w-full lg:w-12">
              <ProfileSettingsIcon classOverrides={['w-8 h-8']} />
            </div>
            <Typography
              color="text-white"
              classOverrides={[
                'py-3 md:py-0.5 hidden lg:block',
                settingsIsOpen && 'underline decoration-white',
              ]}
              size="sm"
            >
              {t('dashboard.parent_links.0')}
            </Typography>
            <FontAwesomeIcon
              className={cn(
                ' lg:ml-3 text-center mt-0.5 w-full lh:text-right text-gray-primary transition-transform duration-300 text-2xl',
                settingsIsOpen ? 'transform rotate-180' : '',
                window.width < LG_SCREEN_SIZE && 'hidden',
              )}
              icon={faCaretDown}
            />
          </motion.div>
        </button>
        <div className="bg-secondary-default flex rounded-xl text-center flex-col">
          {settingsIsOpen &&
            window.width > LG_SCREEN_SIZE &&
            settingsLinks?.map((link, index) => {
              return (
                <motion.div
                  initial={{ opacity: 0, y: -100 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: index * 0.08 },
                  }}
                  title={link.title ? link.title : undefined}
                  key={link.name + '-' + index}
                >
                  <SideBarLink
                    // rounded={cn(index === links.length ? 'rounded-b-lg' : "", index === 0 ? 'rounded-t-lg' : "" )}
                    link={link}
                    key={link.name}
                    subLink
                    rounded={cn(
                      index === 0 && 'rounded-t-lg',
                      index === settingsLinks.length - 1 && 'rounded-b-lg',
                    )}
                    classOverrides={['flex justify-center ']}
                    setResourcesIsOpen={setResourcesIsOpen}
                    setSettingsIsOpen={setSettingsIsOpen}
                  />
                </motion.div>
              );
            })}
        </div>
        <div className="flex items-end flex-col justify-end h-full">
          <OutsideLink
            name={''}
            to={DOCUMENTATION_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SideBarButton
              content={t('action:documentation')}
              classOverrides={['lg:justify-start font-bold']}
            >
              <div className="w-full lg:w-12">
                <DocumentationIcon classOverrides={['h-8 w-8']} />
              </div>
            </SideBarButton>
          </OutsideLink>
          <SideBarButton
            content={t('action:logout')}
            classOverrides={['lg:justify-start font-bold']}
            action={action.logout}
          >
            <div className="w-full lg:w-12">
              <LogOutIcon classOverrides={['h-8 w-8']} />
            </div>
          </SideBarButton>
        </div>
      </div>
    </div>
  );
};
