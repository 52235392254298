import React, { useContext } from 'react';
import { ThemeContext } from 'common/contexts/theme.context';
import { UiNode } from '@ory/kratos-client';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  linkedinNode: UiNode;
  twitterNode: UiNode;
  jobTitleNode: UiNode;
  companyNode: UiNode;
  websiteNode: UiNode;
  goodiesNode: UiNode;
  register: UseFormRegister<{
    'traits.username': any;
    'traits.email': any;
    'traits.picture': any;
  }>;
  errors: DeepMap<
    {
      'traits.username': any;
      'traits.email': any;
      'traits.picture': any;
    },
    FieldError
  >;
  isSubmitting: boolean;
};

export const ProfileOptionalInputs: React.FC<Props> = ({
  linkedinNode,
  twitterNode,
  jobTitleNode,
  companyNode,
  websiteNode,
  goodiesNode,
  register,
  errors,
  isSubmitting,
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[linkedinNode]}
        placeholder={t('profile.placeholder.linkedin')}
        tooltipTitle={t('profile.tooltip.linkedin')}
      />
      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[twitterNode]}
        placeholder={t('profile.placeholder.twitter')}
      />

      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[jobTitleNode]}
        placeholder={t('profile.placeholder.job')}
      />
      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[companyNode]}
        placeholder={t('profile.placeholder.company')}
      />
      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[websiteNode]}
        placeholder={t('profile.placeholder.website')}
      />
      <KratosFormInputs
        register={register}
        errors={errors}
        classOverrides={['dark:text-gray-primary']}
        isSubmitting={isSubmitting}
        bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
        flowUiNodes={[goodiesNode]}
        placeholder={t('profile.placeholder.goodies')}
      />
    </>
  );
};
