import React, { useEffect, useState } from 'react';
import { Page } from '@components/Page/Page.component';
import { VersionSelector } from '../components/VersionSelector/VersionSelector.component';
import { Header } from '../components/Header/Header.component';
import { TabSelector } from '../components/Tabs/TabSelector/TabSelector.component';
import { useHistory, useParams } from 'react-router-dom';
import { JobParams } from './job.router';
import { useJob } from '../hooks/useJob.hook';
import { JobVersion } from '../models/JobVersion.model';
import { ModifyJob } from 'user/components/ModifyJob/ModifyJob.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { capitalizeFirstLetter } from '@utils/text.util';

const JobPage: React.FC = () => {
  const { owner, job: jobName } = useParams<JobParams>();
  const job = useJob(owner, jobName);
  const [selectedVersion, setSelectedVersion] = useState<JobVersion>();
  const [modifyIsOpen, setModifyIsOpen] = useState<boolean>(false);
  const toggleModifyForm = () => {
    setModifyIsOpen(!modifyIsOpen);
  };
  const onSelectVersion = (newVersion: string) => {
    const version = job?.versions.find((c) => c.tag === newVersion);
    if (!version) return setSelectedVersion(job?.versions[0]);
    setSelectedVersion(version);
  };

  useEffect(() => {
    /*
    * We pick the second to last version if there is more than one since 'latest' will always
    be in index 0
    */
    const version = job?.versions && job?.versions[0];
    if (!selectedVersion) setSelectedVersion(version);
  }, [job, selectedVersion]);
  const { location } = useHistory();
  return (
    <Page
      title={capitalizeFirstLetter(`${job?.name ?? 'Loading...'}`)}
      isLoading={false}
      description={job?.description}
    >
      {modifyIsOpen && job ? (
        <>
          {!location.pathname.includes('dashboard') && <NavBarCutter />}
          <div className="mx-auto px-12 md:px-14 lg:px-40 xl:px-52 2xl:px-72 mt-6">
            <ModifyJob
              job={job}
              returnAction={toggleModifyForm}
              returnContent="action:go_back_to_documentation"
            />
          </div>
        </>
      ) : (
        <>
          <Header
            job={job ?? false}
            onSelectVersion={onSelectVersion}
            selectedVersion={selectedVersion}
            action={toggleModifyForm}
          />
          {/** Mobile version of the version selector which is completely out of <Header> **/}
          <div className="flex justify-center lg:hidden">
            {job && selectedVersion && (
              <VersionSelector
                classOverrides={['flex justify-center']}
                versions={job.versionsList}
                deprecated={job.deprecated}
                bgColor={'bg-secondary-default'}
                textColor={'text-white'}
                action={onSelectVersion}
                selectedVersion={selectedVersion}
              />
            )}
          </div>
          {job && (
            <TabSelector
              job={job ?? undefined}
              selectedVersion={selectedVersion}
            />
          )}
        </>
      )}
    </Page>
  );
};

export { JobPage };
