import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { InfoIcon } from '@components/Icons/InfoIcon/InfoIcon.component';
import { MegaPhoneIcon } from '@components/Icons/MegaPhoneIcon/MegaPhoneIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Title } from '@components/Titles/Title.component';
import { TextColor } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { motion } from 'framer-motion';
import Plausible from 'plausible-tracker';
import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ToastContent } from 'react-toastify/dist/types';
import R2_pompomgirl from '@images/R2/R2_pompomgirl.svg';

type Props = {
  color: TextColor;
  icon?: ReactNode;
};

const Toast: React.FC<Props> = ({
  color = 'text-orange-warning',
  children,
  icon = <InfoIcon color={color} />,
}) => {
  return (
    <div
      className={cn(
        'flex justify-start font-comfortaa mb-2',
        'text-gray-secondary',
      )}
      data-testid="toast"
    >
      <div className="mr-4 mb-2 flex items-center">{icon}</div>
      <span className="pt-1 text-sm">{children}</span>
    </div>
  );
};

const toastError = (content: ToastContent, options?: ToastOptions) =>
  toast.error(<Toast color={'text-red-alert'} children={content} />, options);
const toastSuccess = (content: ToastContent, options?: ToastOptions) =>
  toast.success(
    <Toast color={'text-green-pearl'} children={content} />,
    options,
  );
const toastWarning = (content: ToastContent, options?: ToastOptions) =>
  toast.warning(
    <Toast color={'text-orange-warning'} children={content} />,
    options,
  );
const toastInfo = (content: ToastContent, options?: ToastOptions) =>
  toast.info(
    <Toast color={'text-primary-default'} children={content} />,
    options,
  );
const toastAdvertise = (content: ToastContent, options?: ToastOptions) =>
  toast.info(
    <Toast
      color={'text-primary-default'}
      children={content}
      icon={<MegaPhoneIcon />}
    />,
    options,
  );
const toastPipeline = (projectUrl: string, title: string, action: string) => {
  const { trackEvent } = Plausible();
  const content = (
    <div className="flex flex-col text-center h-full items-center  justify-end relative ">
      <motion.img src={R2_pompomgirl} width="120" height="120" className="" />
      <div className="flex justify-center flex-col">
        <Title
          size="h3"
          classOverrides={[
            'text-secondary-default font-extrabold text-center pb-2 pt-2',
          ]}
        >
          {title}
        </Title>
        <div className="flex justify-center items-center">
          <OutsideLink
            name={'Click on SignIn from the light pipeline generator'}
            to={`/u/signin?${projectUrl}`}
            className="flex justify-center w-max"
            onClick={() =>
              trackEvent('Click on Sign in after a light pipeline generation')
            }
          >
            <SecondaryButton
              content={''}
              classOverrides={[
                'flex text-center justify-center items-center font-bold dark:bg-primary-default',
              ]}
              color="text-gray-primary"
              font="comfortaa"
              bgColor="bg-secondary-default"
            >
              {action}
            </SecondaryButton>
          </OutsideLink>
        </div>
      </div>
    </div>
  );
  toast.info(content, {
    closeButton: true,
    className: 'dark:bg-secondary-default',
  });
};
export {
  toastError,
  toastSuccess,
  toastWarning,
  toastInfo,
  toastAdvertise,
  toastPipeline,
};
