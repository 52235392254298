import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import {
  getNodeName,
  getNodeTitle,
  guessValidationPattern,
} from '../../helpers/kratos.helper';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import React, { TextareaHTMLAttributes } from 'react';
import { FieldErrors } from 'react-hook-form';
import { motion } from 'framer-motion';
import { fadeInTopVariant } from '@utils/motion.util';
import { StyledTextarea } from '@components/Form/StyledTextarea/StyledTextarea.component';

type KratosFormProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  /**
   * Errors given by useForm
   */
  errors: FieldErrors;
  /**
   * The position of the input in any given form
   * Used for the fadeInTopVariant animation, see ProfileEdit for an example
   */
  inputIndex: number;
  /**
   * useForm hook isSubmitting property
   * @see useForm
   */
  isSubmitting: boolean;
  /**
   * useForm hook register property
   * @see useForm
   */
  register: UseFormRegister<any>;
  UINode: UiNode;
};

/**
 * This getter fetch an error related to an input from useForm hook
 * @param {FieldErrors} errors -- All the error in the form
 * @param {string} fieldRaw -- Name of the field
 * @see useForm
 */

const getFormFieldError = (errors: FieldErrors, fieldRaw: string): string => {
  if (!errors) return '';

  const field = fieldRaw.split('.');
  // We use any as we redefine errors based on the depth in the object
  let error = errors;

  field.forEach((item, i) => {
    if (error[item] !== undefined) error = error[item];
  });

  if (error) return error.message;
  return '';
};

/**
 * Convert a Kratos flow into a list of inputs, only support TextInput for now
 * @param {SelfServiceRegistrationFlow|undefined} flow -- Current Kratos Flow
 * @param {UseFormRegister} register -- UseForm method to register a new data
 * @param {boolean} isSubmitting -- Information telling whether the form is currently submitting
 * @param {FieldErrors} errors
 * @see useForm
 */
export const KratosTextarea: React.FC<KratosFormProps> = ({
  errors,
  inputIndex,
  isSubmitting,
  maxLength,
  minLength,
  register,
  UINode,
}) => {
  const attr = UINode.attributes as UiNodeInputAttributes;
  const name = getNodeName(UINode);
  const title = getNodeTitle(UINode);

  const registerSanitized = register(name, guessValidationPattern(UINode));
  let error = getFormFieldError(errors, name);

  if (UINode.messages && UINode.messages.length > 0) {
    error = [
      error ? `${error}` : '',
      ...UINode.messages.map((c) => c.text),
    ].join(' ');
  }

  return (
    <motion.div
      variants={fadeInTopVariant(-20, inputIndex * 0.1)}
      className="px-5 py-2"
      key={name}
    >
      <StyledTextarea
        defaultValue={attr.value}
        disabled={attr.disabled || isSubmitting}
        error={error}
        id={attr.name}
        label={title}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={title}
        register={registerSanitized}
        required={attr.required}
        classOverrides={[
          'dark:bg-gray-third dark:text-gray-primary dark:placeholder-gray-primary',
        ]}
      />
    </motion.div>
  );
};
