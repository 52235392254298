export const hexaIcons = [
  0x231b /* Hourglass */, 0x1f48e /* Gem stone */, 0x1f9f1 /* Brick */,
  0x1f4a1 /* Electric Light Bulb */, 0x1f4c4 /* Page Facing Up */,
  0x1f680 /* Rocket */, 0x1f4aa /* Flexed Biceps */, 0x1f9e0 /* Brain */,
  0x26a1 /* High Voltage Sign */, 0x1f3af /* Direct Hit */, 0x1f3f7 /* Label */,
  0x1f6a6 /* Vertical Traffic Light */, 0x1f527 /* Wrench */,
  0x1f9f0 /* Toolbox */, 0x1f5dd /* Old key */, 0x1f381 /* Wrapped Present */,
  0x1f510 /* Closed Lock with Key */, 0x1f514 /* Bell */,
  0x1f587 /* Linked Paperclips */, 0x1f4e6 /* Package */,
  0x1fa84 /* Magic Wand */, 0x1f9fd /* Sponge */, 0x1f511 /* Key */,
  0x2699 /* Gear */, 0x1f5fd /* Statue of Liberty */,
  0x1f6f8 /* Flying Saucer */, 0x1f3b7 /* Saxophone */, 0x1f3a7 /* Headphone */,
  0x1f3a8 /* Artist Palette */, 0x1f354 /* Hamburger */,
];

export const DEFAULT_ICON = String.fromCodePoint(0x1f3f7);
