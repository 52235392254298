import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClassOverrides,
  DarkModeTextColor,
  HTMLStyling,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import React, { useState } from 'react';
import ReactTooltip, { Place, Type, VoidFunc } from 'react-tooltip';
import './Tooltip.scss';

export type TooltipProps = {
  delayHide?: number;
  // Text of the tooltip
  title?: string;
  // Icon that will be displayed
  icon?: IconDefinition;
  // Icon color
  iconColor?: TextColor;
  // Dark mode icon color
  iconDarkModeColor?: DarkModeTextColor;
  // If the tooltip will be shown on hover or click
  click?: boolean;
  // Size of the icon
  size?: SizeProp;
  // Placement of tooltip
  place?: Place;
  // Tooltip styling theme
  type?: Type;
  // Set of colors
  colorSet?: ColorSetEnum;
  // Callback after tooltip is shown
  afterShow?: VoidFunc;
  // Callback for using dynamic content instead of plain text as with title
  getContent?: (toolTipStr: string) => React.ReactNode;
  // Custom event to trigger tooltip
  event?: string;
  // Auto show the tooltip
  autoShow?: boolean;
  multiline?: boolean;
  tooltipClass?: string;
};

type Props = ClassOverrides & HTMLStyling & TooltipProps;

const Tooltip: React.FC<Props> = ({
  delayHide = 100,
  children,
  multiline = true,
  icon,
  iconColor = 'text-gray-secondary',
  iconDarkModeColor = 'dark:text-gray-primary',
  click = false,
  afterShow,
  title,
  colorSet = ColorSetEnum.gray,
  type = 'light',
  size = '2x',
  place = 'top',
  classOverrides = [],
  getContent,
  autoShow = false,
  tooltipClass,
}) => {
  const colors = getColorSet(colorSet);
  const [id] = useState(Math.random().toString(36));

  const showTooltip = () => {
    let tooltip = document.querySelectorAll(`[data-tip][data-for="${id}"]`)[0];
    ReactTooltip.show(tooltip);
  };

  setTimeout(function () {
    autoShow && showTooltip();
  }, 3000);

  return (
    <>
      <span
        className={cn(...classOverrides, iconColor, 'z-30 w-max inline-block')}
        data-tip={title}
        data-event={click ? 'click focus' : undefined}
        data-for={id}
      >
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            size={size}
            className={iconDarkModeColor}
          />
        ) : (
          children
        )}
      </span>
      <ReactTooltip
        id={id}
        backgroundColor={colors?.backgroundColor}
        delayHide={delayHide}
        getContent={getContent}
        textColor={colors?.color}
        place={place}
        type={type}
        afterShow={afterShow}
        effect={'solid'}
        globalEventOff={click ? 'click' : undefined}
        multiline={multiline}
        className={cn('shadow-lg focus:outline-none', tooltipClass)}
      />
    </>
  );
};

export { Tooltip };
