import {
  toastError,
  toastSuccess,
} from '@components/Toasters/Toaster.component';
import { buildRoute } from '@utils/routes.util';
import { API_ROUTES } from 'common/constants/routes';
import { HttpContext } from 'common/contexts/http.context';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export type ReturnType = [
  (ownerName: string, jobName: string) => Promise<void>,
  boolean,
];

export const useOnJobDelete = (): ReturnType => {
  const { t } = useTranslation();
  const httpService = useContext(HttpContext);
  const [processingDeletion, setProcessingDeletion] = useState(false);
  const history = useHistory();

  const deleteJob = (ownerName: string, jobName: string): Promise<void> => {
    setProcessingDeletion(true);
    return httpService
      .delete(buildRoute(API_ROUTES.ACCESS_SINGLE_JOB(ownerName, jobName)))
      .then((res) => {
        if (res.status !== StatusCodes.NO_CONTENT) {
          toastError(t('error:toast.unhandled'));
        } else if (res.status === StatusCodes.NO_CONTENT) {
          toastSuccess(t('toast.jobDeleted'));
          history.push('/dashboard');
        }
      })
      .finally(() => setProcessingDeletion(false));
  };

  return [deleteJob, processingDeletion];
};
