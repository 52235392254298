import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { Typography } from '@components/Typography/Typography.component';
import { SubmitSelfServiceRegistrationFlowWithPasswordMethodBody } from '@ory/kratos-client';
import { simpleFadeIn } from '@utils/motion.util';
import {
  defaultPasswordCheckerState,
  isContainingLowercase,
  isContainingNumeric,
  isContainingSpecialCharIncludingSpaces,
  isContainingUppercase,
  PasswordCheckerState,
} from '@utils/text.util';
import { motion } from 'framer-motion';
import React, { FormEvent, useEffect, useState } from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PWD_MIN_LENGTH } from '../../../common/constants/general';
import { useQuery } from '../../../common/hooks/useQueryParam.hook';
import { KratosFormInputs } from '../../../kratos/components/KratosFormInputs/KratosFormInputs';
import { useKratosRegistrationFlow } from '../../../kratos/hooks/useKratosFlow.hook';
import { GrayBox } from '../GrayBox.component';
import { PwdChecker } from '../PwdChecker/PwdChecker.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

export const SignUpForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onSucceed = () => {
    history.push('/u/signup/successful');
  };
  const flowId = useQuery().get('flow');
  const [flow, submitHandler] = useKratosRegistrationFlow(flowId, onSucceed);
  const [passwordState, setPasswordState] = useState<PasswordCheckerState>(
    defaultPasswordCheckerState,
  );
  const [pwdIsValid, setPwdIsValid] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = (
    data: UnpackNestedValue<
      SubmitSelfServiceRegistrationFlowWithPasswordMethodBody & {
        password_repeat: string;
      }
    >,
  ) => {
    if (!flow) return;
    submitHandler(data);
  };

  useEffect(() => {
    setPwdIsValid(
      Object.values(passwordState).every((state) => {
        return state;
      }),
    );
  }, [passwordState]);

  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.value === undefined) return;

    if (input.name === 'password' || input.name === 'password_repeat') {
      setPasswordState({
        correctNumeric: isContainingNumeric(input.value),
        correctLength: input.value.length > PWD_MIN_LENGTH,
        correctSpecial: isContainingSpecialCharIncludingSpaces(input.value),
        correctLowercaseUppercase:
          isContainingUppercase(input.value) &&
          isContainingLowercase(input.value),
        passwordMatch:
          watch('password') === watch('password_repeat') ? true : false,
      });
    }
  };
  return (
    <motion.div variants={simpleFadeIn()} data-testid={'register-form'}>
      <GrayBox>
        {flow && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            onChange={onFormChange}
            method={flow.ui.method}
          >
            <div className="grid lg:grid-cols-2 pt-2 px-4 md:px-8 h-auto">
              <KratosFormInputs
                flowUiNodes={flow.ui.nodes}
                register={register}
                isSubmitting={isSubmitting}
                errors={errors}
                classOverrides={['dark:text-gray-primary']}
                bgColor={'dark:bg-secondary-default-high-opacity'}
              />
              <PwdChecker values={passwordState} />
            </div>
            <div className="flex flex-col w-full items-center">
              <div className="flex flex-col w-full md:w-9/12 lg:w-10/12 xl:w-full items-center lg:items-end">
                <div className="flex flex-col w-full mt-8 lg:-mt-10 lg:w-3/4 items-center lg:items-end">
                  <Typography
                    size="sm"
                    color="text-gray-secondary"
                    classOverrides={[
                      'text-center lg:w-5/6 px-4 lg:px-0 lg:pr-2 my-auto col-span-2 dark:text-gray-primary',
                    ]}
                  >
                    <Trans i18nKey="signup.hint">
                      By clicking on <b>t(action:signup)</b>, you agree to
                      <TrackedLink
                        name={'terms of use'}
                        to="/termsofuse"
                        title={t('action:title.redirect_terms_of_use')}
                        className={'underline dark:text-gray-primary'}
                      >
                        {t('signup.term_of_use')}
                      </TrackedLink>
                    </Trans>{' '}
                    <br />
                    <Trans i18nKey="signup.data_storage">
                      Your data will be stored on r2devops.io database, check
                      our
                      <TrackedLink
                        name={'privacy policies'}
                        to="/privacy"
                        title={t('action:title.redirect_policies')}
                        className={'underline dark:text-gray-primary'}
                      >
                        Privacy policies.
                      </TrackedLink>
                    </Trans>
                  </Typography>
                  <div>
                    <SubmitButton
                      classOverrides={['my-8 dark:bg-dark-secondary lg:mr-8']}
                      content={t('action:signup')}
                      isSubmitting={isSubmitting}
                      isValid={pwdIsValid && isValid}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </GrayBox>
      <div className="m-auto w-80 mt-8">
        <Typography
          size="sm"
          color="text-gray-secondary"
          classOverrides={['mx-auto dark:text-gray-primary']}
        >
          {t('action:already_have_account')} <br />
          <TrackedLink name={'signin'} to="/u/signin" className="underline">
            {t('action:primary')}
          </TrackedLink>
        </Typography>
      </div>
    </motion.div>
  );
};
