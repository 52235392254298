import {
  toastError,
  toastSuccess,
} from '@components/Toasters/Toaster.component';
import { Job } from '@jobs/models/Job.model';
import { API_ROUTES } from 'common/constants/routes';
import { HttpContext } from 'common/contexts/http.context';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { useContext, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyJobsContext } from 'user/contexts/myJobs.context';
import { JobUpdateRequestBody } from 'user/types/forms';

type ReturnType = [
  boolean,
  (
    jobMetaData: JobUpdateRequestBody,
    ownerName: string,
    jobName: string,
  ) => Promise<void>,
];

export const useOnJobDetailsUpdate = (): ReturnType => {
  const { t } = useTranslation();
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useReducer(httpReducer<Response>(), null);
  const { setShowModifyJobForm } = useContext(MyJobsContext);

  const updateJob = (
    jobMetaData: JobUpdateRequestBody,
    ownerName: string,
    jobName: string,
  ): Promise<void> => {
    setLoading(true);

    return httpService
      .fetch<Job>('PATCH', API_ROUTES.ACCESS_SINGLE_JOB(ownerName, jobName), {
        body: jobMetaData,
      })
      .then((res) => {
        setLoading(false);
        if (!res.response) {
          toastError(t('error:toast.unhandled'));
        } else if (res.status === StatusCodes.OK && setShowModifyJobForm) {
          toastSuccess(t('toast.jobUpdated'));
          setShowModifyJobForm(false);
        }
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      });
  };
  return [loading, updateJob];
};
