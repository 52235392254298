import React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelWithTooltip } from '@components/Form/LabelWithTooltip/LabelWithTooltip.component';
import { SelectInput } from '@components/Form/SelectInput/SelectInput.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { UseFormRegisterReturn } from 'react-hook-form';
import { cn } from '@utils/classes.util';
import { Place } from 'react-tooltip';

export enum License {
  Apache = 'Apache-2.0',
  MIT = 'MIT',
}

type Props = ClassOverrides & {
  defaultValue?: License;
  /**
   * useForm hook register method return object
   * @see useForm
   */
  register: UseFormRegisterReturn;
  tooltipPlace?: Place;
};

export const LicenseSelectInput: React.FC<Props> = ({
  classOverrides = [],
  defaultValue = '',
  register,
  tooltipPlace,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(...classOverrides)}>
      <LabelWithTooltip
        labelText="License"
        tooltipTitle={t('link_job.license_tooltip')}
        tooltipPlace={tooltipPlace}
      />
      <SelectInput
        options={Object.values(License)}
        placeholder={t('job.license')}
        register={register}
        required
        value={defaultValue}
      />
    </div>
  );
};
