import React from 'react';
import GitlabLogo from '../../../../assets/images/gitlab-alliance-partner-badge.svg';
import GitlabBlackLogo from '../../../../assets/images/gitlab-alliance-partner-badge-black.svg';
import { ClassOverrides, Sizing } from '@typings/ClassOverrides.type';
import { useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';

type Props = {
  white?: boolean;
};

export const GitlabAlliancePartnerIcon: React.FC<
  Props & Sizing & ClassOverrides
> = ({ classOverrides = [], width = '120', height = 'auto', white }) => {
  const { t } = useTranslation();
  return (
    <img
      src={white ? GitlabLogo : GitlabBlackLogo}
      alt={t('gitlab_alt')}
      className={cn(...classOverrides)}
      width={width}
      height={height}
    />
  );
};
