import { MinimalKratosFlow } from '../types/flow.type';
import { isUiNodeInputAttributes } from '../helpers/kratos.helper';
import i18n from 'i18next';

/**
 * Replace an error by a custom one if a translation exists for it in nodes
 * Use in useKratosFlow
 * @param flow
 * @see useKratosFlow
 */
export const genericNodeKratosErrorFilter = (
  flow: MinimalKratosFlow,
): Promise<MinimalKratosFlow> => {
  for (let i = 0; i < flow.ui.nodes.length; i++) {
    const node = flow.ui.nodes[i];

    if (
      isUiNodeInputAttributes(node.attributes) &&
      node.messages &&
      node.messages.length > 0
    ) {
      for (let j = 0; j < node.messages.length; j++) {
        const message = node.messages[j];
        if (i18n.exists(`kratos:${node.attributes.name}.${message.id}`)) {
          flow.ui.nodes[i].messages[j] = {
            ...message,
            text: i18n.t(`kratos:${node.attributes.name}.${message.id}`),
          };
        }
      }
    }
  }

  return Promise.resolve(flow);
};

/**
 * Replace an error by a custom one if a translation exists for it in global messages
 * Use in useKratosFlow
 * @param flow
 * @see useKratosFlow
 */
export const genericKratosErrorFilter = (
  flow: MinimalKratosFlow,
): Promise<MinimalKratosFlow> => {
  // In case there is no message in the flow, we skip the whole function
  if (!(flow.ui.messages instanceof Array)) return Promise.resolve(flow);

  for (let i = 0; i < flow.ui.messages?.length; i++) {
    const message = flow.ui.messages[i];
    if (i18n.exists(`kratos:${message.id}`)) {
      flow.ui.messages[i].text = i18n.t(`kratos:${message.id}`);
    }
  }

  return Promise.resolve(flow);
};

/**
 * These filters are intended to be used over all incoming Kratos flows
 */
export const KratosFiltersIn: ((
  flow: MinimalKratosFlow,
) => Promise<MinimalKratosFlow>)[] = [
  genericNodeKratosErrorFilter,
  genericKratosErrorFilter,
];

/**
 * These filters are intended to be used over all outcoming Kratos flows
 */
export const KratosFilterOut = [];
