import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import React, { useContext, useEffect } from 'react';
import { License } from 'common/hooks/useLicense.hook';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'user/contexts/user.context';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { openInNewTab } from '@utils/object.util';
import { PREMIUM_LICENSE_PRICEID } from 'common/constants/general';
import { Product, useCheckout } from 'common/hooks/useCheckout.hook';

type Props = {
  license: License | null | undefined;
};

export const BillingCard: React.FC<Props> = ({ license }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identity } = useContext(UserContext);
  const redirectTo = (location: string) => {
    history.push(location);
  };
  const [createCheckoutPage, checkoutUrl] = useCheckout();
  const configureCheckout = (product: Product) => {
    createCheckoutPage(product);
  };
  useEffect(() => {
    if (!checkoutUrl) return;
    window.location.href = checkoutUrl;
  }, [checkoutUrl]);

  const getActionButton = (pricingName: string) => {
    switch (pricingName) {
      case t('pricing.ondemand.name'):
        return openInNewTab('https://tally.so/r/3E2aAw');
      case t('pricing.core.name'):
        return identity ? redirectTo('/_/jobs') : redirectTo('/u/signin');
      case t('pricing.premium.name'):
        return identity
          ? configureCheckout({ priceID: PREMIUM_LICENSE_PRICEID })
          : redirectTo('/u/signin');
      default:
        return undefined;
    }
  };
  return (
    <div className="md:hidden relative rounded-xl shadow-lg bg-gray-100 dark:bg-secondary-default w-75 sm:w-96 md:w-135 py-6 inline-block mb-8">
      <Title
        size={'h2'}
        color={'text-secondary-default'}
        bold
        classOverrides={['text-center px-4']}
      >
        {t('billing.card.title')}
      </Title>
      <div className="mx-16 my-4">
        {license?.licenseType?.name.toLowerCase().includes('core') ||
        (license?.licenseType?.name.toLowerCase().includes('premium') &&
          !license.active) ? (
          <>
            <Typography
              color="text-gray-secondary"
              size="sm"
              light
              classOverrides={[' dark:text-white ']}
            >
              <Trans
                i18nKey="billing.card.content.0"
                components={[
                  '',
                  <span className="font-bold"> PREMIUM </span>,
                  '',
                ]}
              />
            </Typography>
            <div className="text-center">
              <PrimaryButton
                classOverrides={[
                  'text-center py-1 mx-2 mt-4 m-0 w-28 px-1 dark:bg-gray-primary dark:text-secondary-default font-md',
                ]}
                bgColor={'bg-secondary-default'}
                bold={true}
                margin={'m-0'}
                font={'comfortaa'}
                content={t('action:subscribe')}
                action={() => getActionButton('PREMIUM')}
              />
            </div>
          </>
        ) : (
          license?.licenseType?.name.toLowerCase().includes('premium') &&
          license.active && (
            <>
              <Typography
                color="text-gray-secondary"
                size="sm"
                light
                classOverrides={[' dark:text-white ']}
              >
                <Trans
                  i18nKey="billing.card.content.1"
                  components={[
                    '',
                    <div className="font-bold">
                      <span> ON DEMAND </span>
                      <br />
                    </div>,
                    '',
                  ]}
                />
              </Typography>
              <div className="text-center">
                <PrimaryButton
                  classOverrides={[
                    'text-center py-1 mx-2 mt-4 m-0 w-28 px-1 dark:bg-gray-primary dark:text-secondary-default font-md',
                  ]}
                  bgColor={'bg-secondary-default'}
                  bold={true}
                  margin={'m-0'}
                  font={'comfortaa'}
                  content={t('action:get_quote')}
                  action={() => getActionButton('ON DEMAND')}
                />
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};
