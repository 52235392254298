import React from 'react';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

const DashboardFooter: React.FC = () => {
  return (
    <div className="absolute bottom-4 w-full text-center ">
      <span className="flex justify-center items-center text-gray-secondary">
        <div className="font-medium">Copyright R2Devops 2022 -</div>
        <TrackedLink
          name={''}
          target="_blank"
          className="mx-1"
          rel="noopener noreferrer"
          to={'/legal'}
        >
          Legal
        </TrackedLink>
        -
        <TrackedLink
          name={''}
          target="_blank"
          className="mx-1"
          rel="noopener noreferrer"
          to={'/privacy'}
        >
          Privacy policies
        </TrackedLink>
        -
        <TrackedLink
          name={''}
          target="_blank"
          className="ml-1"
          rel="noopener noreferrer"
          to={'/termsofuse'}
        >
          Terms of use
        </TrackedLink>
        {/* - R2Devops status <div className='ml-2 mt-0.5 w-3 h-3 rounded-full bg-green-pearl'/> */}
      </span>
    </div>
  );
};

export { DashboardFooter };
