import React from 'react';
import { Page } from '@components/Page/Page.component';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Typography } from '@components/Typography/Typography.component';
import { Link } from 'react-router-dom';
import { TableRow } from '@components/Table/TableRow.component';
import { TableHeader } from '@components/Table/TableHeader.compontent';
import { TableCell } from '@components/Table/TableCell.component';
import { CollapseRow } from '@components/CollapseRow/CollapseRow.component';

export const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();

  const headers = t('privacy_policy.table_headers', {
    returnObjects: true,
  }) as Array<string>;

  const mandatoryCookiesTable = t('privacy_policy.mandatory_cookies', {
    returnObjects: true,
  }) as Array<Array<string>>;

  const optionalCookiesTable = t('privacy_policy.optional_cookies', {
    returnObjects: true,
  }) as Array<Array<string>>;

  const listInstallationInstructions = () => {
    const instructions = t('privacy_policy.installation_instructions', {
      returnObjects: true,
    }) as Array<string>;
    return instructions.map((instruction, index) => (
      <Typography
        key={`${instruction}-${index}`}
        size="md"
        color="text-gray-secondary"
        classOverrides={['dark:text-white']}
      >
        {instruction}
      </Typography>
    ));
  };

  const showCookieTable = (bodyRow: Array<Array<string>>) => (
    <div className="overflow-x-auto w-full">
      <table className="font-comfortaa dark:text-gray-primary dark:bg-dark-secondary text-md w-full  text-secondary-dark">
        <thead>
          <TableRow
            classOverrides={['divide-x-4 divide-gray-input divide-opacity-70']}
          >
            {headers.map((header, index) => (
              <TableHeader
                key={`${header}-${index}`}
                classOverrides={[
                  'px-4 py-3 bg-gray-primary dark:bg-secondary-default-medium-opacity',
                ]}
              >
                <Title size="h3" color="text-secondary-default">
                  {header}
                </Title>
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {bodyRow.map((cookieRow, index) => (
            <TableRow
              key={`${cookieRow.toString()}-${index}`}
              classOverrides={[
                'text-center divide-x-4 divide-gray-input divide-opacity-70',
                index % 2 === 1 && 'bg-gray-primary dark:bg-secondary-default',
              ]}
            >
              {cookieRow.map((cell, index) => (
                <TableCell
                  key={`${cell}-${index}`}
                  classOverrides={['py-3 px-5']}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <Page
      title={t('privacy_policy.title')}
      description={t('privacy_policy.description')}
    >
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('privacy_policy.title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="flex flex-col mt-10 space-y-6 w-auto px-12 md:px-24 lg:px-48 mb-8">
        <Title
          bold
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['text-center']}
        >
          {t('privacy_policy.cookieTitle')}
        </Title>
        <Typography
          size="md"
          color="text-gray-secondary"
          classOverrides={['dark:text-white']}
        >
          <Trans
            i18nKey={t('privacy_policy.intro')}
            components={[
              '',
              <Link to="/" className="underline cursor-pointer">
                {' '}
                www.r2devops.io{' '}
              </Link>,
              '',
            ]}
          />
        </Typography>
        {listInstallationInstructions()}
        <Title
          bold
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['text-center']}
        >
          {t('privacy_policy.mandatory_title')}
        </Title>

        {showCookieTable(mandatoryCookiesTable)}

        <Title
          bold
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['text-center']}
        >
          {t('privacy_policy.optional_title')}
        </Title>

        {showCookieTable(optionalCookiesTable)}
      </div>
      <div className="px-12 md:px-24 lg:px-48" id="jobs">
        <Title
          bold
          color={'text-secondary-default'}
          size={'h2'}
          classOverrides={['text-center mt-8 mb-4']}
        >
          {t('privacy_policy.jobs.title')}
        </Title>
        <div className="text-center">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
            italic
            font="ubuntu"
          >
            {t('privacy_policy.jobs.description')}
          </Typography>
        </div>
        <div className=" xl:mx-48 2xl:mx-56">
          <div className="text-center mt-4">
            <CollapseRow
              title={t('privacy_policy.jobs.section_titles.0')}
              titleClassOverrides={['font-black']}
            >
              <ul className="inline-block list-disc text-gray-secondary dark:text-gray-primary">
                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.0')}
                </li>
                <br />
                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.1')}
                </li>
                <br />

                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.2')}
                </li>
                <br />

                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.3')}
                </li>
              </ul>
            </CollapseRow>
          </div>

          <div className="text-center mb-14">
            <CollapseRow
              title={t('privacy_policy.jobs.section_titles.1')}
              titleClassOverrides={['font-black']}
            >
              <ul className="inline-block list-disc text-gray-secondary dark:text-gray-primary">
                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.4')}
                </li>
                <br />
                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.5')}
                </li>
                <br />

                <li className="text-left  ml-auto max-w-20">
                  {t('privacy_policy.jobs.content.6')}
                </li>
              </ul>
            </CollapseRow>
          </div>
        </div>
      </div>
    </Page>
  );
};
