import React, { useContext } from 'react';
import { Page } from '@components/Page/Page.component';
import { HeaderBottom } from '@components/Header/HeaderBottom.component';
import { Header } from '@home/components/Header/Header.component';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import autopipelineGif from '@images/Gif/autopipeline_V2_white.gif';
import autopipelineDarkGif from '@images/Gif/autopipeline_V2_black.gif';
import myProjectsGif from '@images/Gif/myprojects_white.gif';
import myProjectsDarkGif from '@images/Gif/myprojects_dark.gif';
import jobsExplorerGif from '@images/Gif/JobExplorer_white.gif';
import jobsExplorerDarkGif from '@images/Gif/JobExplorer_dark.gif';
import communityGif from '@images/Gif/community_white.gif';
import communityDarkGif from '@images/Gif/community_dark.gif';
import R2Discord from '@images/R2/R2Discord.svg';
import { Typography } from '@components/Typography/Typography.component';
import { fadeInLeftVariant } from '@utils/motion.util';
import { motion } from 'framer-motion';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { GifSection } from '@jobs/components/GifSection/GifSection.component';
import { ThemeContext } from 'common/contexts/theme.context';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <Page title={t('home.title')} description={t('home.description')}>
      <header className="bg-gradient-to-b from-secondary-default to-primary-default w-full  dark:to-secondary-default-medium-opacity">
        <div className="flex flex-col items-center mb-12 w-11/12 mx-auto">
          <Header />
          <div className="w-6/12 ml-24 h-92 bg-primary-default"></div>
        </div>
        <HeaderBottom />
      </header>
      <div className="mx-12 md:mx-16 lg:mx-24 xl:mx-28 2xl:mx-34 ">
        <Title
          size={'h1'}
          bold
          classOverrides={['text-center py-8 dark:text-gray-primary']}
          color="text-secondary-default"
        >
          {t('home.body.title')}
        </Title>
        <GifSection
          placement="left"
          title={t('home.body.sections.titles.0')}
          gif={theme === 'light' ? autopipelineGif : autopipelineDarkGif}
          classOverrides={['flex-col-reverse xl:mt-0 xl:flex-row']}
        >
          <div className="flex flex-col">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              <Trans
                i18nKey={t('home.body.sections.pipeline_list')}
                tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
              />
            </Typography>
          </div>
        </GifSection>
        <GifSection
          placement="right"
          title={t('home.body.sections.titles.1')}
          classOverrides={['flex-col-reverse xl:flex-row-reverse']}
          gif={theme === 'light' ? myProjectsGif : myProjectsDarkGif}
        >
          <div className="xl:text-right">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              <Trans
                i18nKey={t('home.body.sections.content.1')}
                tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
              />
            </Typography>
          </div>
        </GifSection>
        <GifSection
          placement="left"
          classOverrides={['flex-col-reverse xl:flex-row']}
          title={t('home.body.sections.titles.2')}
          gif={theme === 'light' ? jobsExplorerGif : jobsExplorerDarkGif}
        >
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('home.body.sections.content.2')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </GifSection>
        <GifSection
          placement="right"
          title={t('home.body.sections.titles.3')}
          classOverrides={['flex-col-reverse xl:flex-row-reverse']}
          gif={theme === 'light' ? communityGif : communityDarkGif}
        >
          <div className="xl:text-right">
            <Typography
              color={'text-gray-secondary'}
              classOverrides={['dark:text-gray-primary']}
            >
              {t('home.body.sections.content.3')}
            </Typography>
          </div>
        </GifSection>
      </div>
      <div className="bg-primary-default dark:bg-secondary-default flex flex-col-reverse items-center xl:items-start xl:flex-row justify-center mt-20">
        <motion.img
          variants={fadeInLeftVariant()}
          initial={'hidden'}
          animate={'show'}
          src={R2Discord}
          className={'w-64'}
        />
        <div className="w-full xl:w-max my-auto px-12 md:px-24 xl:px-0 xl:ml-16 flex flex-col items-center  xl:items-start xl:flex-col">
          <Title
            size={'h1'}
            bold
            classOverrides={['xl:w-max pt-8 xl:text-left text-center']}
            color="text-gray-primary"
          >
            {t('home.body.sections.discord.title')}
          </Title>
          <div className="w-full xl:w-145 mt-4">
            <Typography color="text-gray-primary" classOverrides={['']}>
              <Trans
                i18nKey={t('home.body.sections.discord.content')}
                tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
              />
            </Typography>
          </div>
          <OutsideLink
            name="signin"
            to="https://discord.r2devops.io/?utm_medium=website&utm_source=r2devops&utm_campaign=homepage"
            className="w-max"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SecondaryButton
              content={t('action:join_discord')}
              classOverrides={[
                'dark:bg-dark-secondary dark:text-gray-primary my-7',
              ]}
              font={'comfortaa'}
              color="text-secondary-default"
              bold={true}
            />
          </OutsideLink>
        </div>
      </div>
    </Page>
  );
};

export { HomePage };
