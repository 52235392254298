import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';
import './index.scss';

const numericalIndicators = [0, 5, 10, 20, 50];

/**
 * The numerical indicators above the progress bar dots
 */
const Indicator: React.FC<ClassOverrides> = ({
  children,
  classOverrides = [],
}) => {
  return <span className={cn('absolute', ...classOverrides)}>{children}</span>;
};

/**
 * Each one of the dots in the progress bar which change on color based on the referral counter
 */
const Dot: React.FC<
  { dotValue: number; referralCount: number } & ClassOverrides
> = ({ classOverrides = [], dotValue, referralCount }) => {
  return (
    <div
      className={cn(
        'rounded-full h-2 w-2 sm:w-4 sm:h-4 absolute transform -translate-y-1/2 top-1/2',
        referralCount === 0 || referralCount < dotValue
          ? 'bg-gray-input'
          : 'bg-secondary-default sm:bg-white',
        ...classOverrides,
      )}
    ></div>
  );
};

export const ReferralCounter: React.FC<{ referralCount: number }> = ({
  referralCount,
}) => {
  const getPositionClass = (key: number): string => {
    switch (key) {
      case 0:
        return 'first-level-position';
      case 1:
        return 'second-level-position';
      case 2:
        return 'left-1/4';
      case 3:
        return 'fourth-level-position';
      case 4:
        return 'right-16';
      default:
        return '';
    }
  };

  const getProgressBarWidth = (): string => {
    /**
     * We multiply value for two since progress bar goes from 0 to 50 instead of 0 to 100
     */
    let width = referralCount * 2;

    /**
     * If the referral value matches one of the progress bar Indicators, we want the progress bar
     * to be a little bit ahead of the Dot as shown in the design, not just above it
     */
    if (numericalIndicators.includes(referralCount) || referralCount < 10) {
      width += 9;
    } else if (referralCount > 20) {
      /**
       * For values above 20 we want to avoid the progress bar to exceed the '50' indicator
       * (what would happen with values like 40 or 49) but we also want values between 20
       * and 30 to exceed the '20' indicator as well.
       *
       * Using the following linear function we ensure that we add to the initial width for values below
       * 35 and then start subtracting from it after it surpasses this threshold
       *
       * For example, when the value is 35, the linear function is nearly 0 so we neither add nor subtract from
       * the initial width (line 61, referralCount * 2). For 49 we subtract to avoid the progress bar surpassing the '50' indicator.
       */
      width += -0.53 * referralCount + 18.41;
    }
    return width + '%';
  };

  return (
    <div className="my-6 text-center w-full">
      <div className="h-6 sm:h-8 relative font-comfortaa text-sm sm:text-lg font-bold">
        {numericalIndicators.map((indicator, key) => {
          return (
            <Indicator
              key={getPositionClass(key)}
              classOverrides={[
                key === 0 || key === 1
                  ? 'text-primary-default'
                  : 'text-secondary-default',
                getPositionClass(key),
              ]}
            >
              {indicator}
            </Indicator>
          );
        })}
      </div>
      <div className="h-7 sm:h-12 p-1 rounded-4xl filter shadow-inner relative flex items-center bg-gray-primary dark:bg-secondary-default-high-opacity">
        {/* PROGRESS BAR */}
        <div
          className={cn(
            'h-6 sm:h-10 bg-primary-default dark:bg-secondary-default rounded-4xl filter shadow-referral-counter',
          )}
          style={{ width: getProgressBarWidth() }}
        ></div>

        {numericalIndicators.map((indicator, key) => {
          return (
            <Dot
              classOverrides={[getPositionClass(key)]}
              dotValue={indicator}
              referralCount={referralCount}
              key={key + '-' + indicator}
            />
          );
        })}
      </div>
    </div>
  );
};
