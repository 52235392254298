import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { Typography } from '@components/Typography/Typography.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { PricingFeatures } from '@typings/pricingFeatures';
import { fadeInStaggerChildren } from '@utils/motion.util';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { PricingPlans } from 'common/constants/pricingPlans';
import { ThemeContext } from 'common/contexts/theme.context';
import { useLicense } from 'common/hooks/useLicense.hook';
import { mockCoreLicense } from 'common/mocks/license.mock';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from 'user/contexts/user.context';
import { BillingCard } from '../BillingCard/BillingCard.component';
import { BillingTable } from '../BillingTable/BillingTable.component';

export const Billing: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { identity } = useContext(UserContext);
  const { license } = useLicense(identity?.traits.username);

  const pricing: Array<PricingFeatures> = PricingPlans();
  return (
    <motion.div
      variants={fadeInStaggerChildren()}
      initial={'hidden'}
      animate={'show'}
    >
      <Title
        size={'h1'}
        color={'text-secondary-default'}
        bold
        classOverrides={['mb-12 text-center px-4']}
      >
        {t('billing.title')}
      </Title>
      <div className=" flex flex-col items-center justify-center">
        <div className="relative rounded-xl shadow bg-gray-100 dark:bg-secondary-default w-75 sm:w-96 md:w-135 py-6 inline-block mb-8">
          <Tooltip
            title={t('billing.tooltip')}
            icon={faQuestionCircle}
            place="right"
            size="lg"
            multiline
            classOverrides={['absolute right-4 top-4']}
            tooltipClass="max-w-18 bg-secondary-semi-light"
            colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
          />
          <Title
            size={'h2'}
            color={'text-secondary-default'}
            bold
            classOverrides={['text-center px-4']}
          >
            {t('billing.page_titles.0')}
          </Title>
          <div className="mx-16 my-4">
            <Typography
              color="text-gray-secondary"
              size="sm"
              light
              classOverrides={[' dark:text-white ']}
            >
              <Trans
                i18nKey={
                  license?.id
                    ? license.licenseType.name === 'Core'
                      ? 'billing.licenses_description.0'
                      : license.active
                      ? 'billing.licenses_description.1'
                      : 'billing.licenses_inactive_description.0'
                    : 'billing.licenses_description.0'
                }
                components={[
                  '',
                  <span className="font-bold"> PREMIUM </span>,
                  '',
                ]}
              />
            </Typography>
          </div>
        </div>
        <div className="hidden relative rounded-xl shadow bg-gray-100 dark:bg-secondary-default w-max px-4 2xl:px-24 md:flex md:flex-col items-center justify-center py-6  mb-8">
          <Title
            size={'h2'}
            color={'text-secondary-default'}
            bold
            classOverrides={['text-center px-4 pb-4']}
          >
            {t('billing.page_titles.1')}
          </Title>
          <BillingTable pricing={pricing} license={license} />
        </div>
        <BillingCard
          license={
            license?.licenseType
              ? license
              : mockCoreLicense(identity?.traits.username)
          }
        />
        <div className="relative rounded-xl shadow bg-gray-100 dark:bg-secondary-default w-75 sm:w-96 md:w-135 py-6 inline-block mb-8">
          <Title
            size={'h2'}
            color={'text-secondary-default'}
            bold
            classOverrides={['text-center px-4']}
          >
            {t('billing.manage_license.title')}
          </Title>
          <div className="mx-16 my-4">
            <Typography
              color="text-gray-secondary"
              size="sm"
              light
              classOverrides={[' dark:text-white ']}
            >
              <Trans i18nKey="billing.manage_license.description">
                If you need an invoice, to modify your license level or have any
                question, fill out
                <OutsideLink
                  to="https://tally.so/r/m68Jye"
                  name="tally form manage license"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-primary-default hover:opacity-70"
                >
                  this form
                </OutsideLink>
                and explain what you want to do.
              </Trans>
            </Typography>
          </div>
          <div className="text-center">
            <OutsideLink
              to="https://tally.so/r/m68Jye"
              name="tally form manage license"
              target="_blank"
              rel="noopener noreferrer"
            ></OutsideLink>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
