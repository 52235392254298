import React, { useContext } from 'react';
import { Typography } from '@components/Typography/Typography.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  PWD_MIN_LENGTH,
  PWD_MIN_NUMERIC,
  PWD_MIN_SPECIAL,
  PWD_MIN_UPPERCASE,
} from '../../../common/constants/general';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { PasswordCheckerState } from '@utils/text.util';
import { motion } from 'framer-motion';
import { fadeInTopVariant } from '@utils/motion.util';
import { ThemeContext } from 'common/contexts/theme.context';

type Props = {
  values: PasswordCheckerState;
};

/**
 * Show a list of items that are checked or not to give conditional visual alert
 */
export const PwdChecker: React.FC<Props> = ({
  values: {
    correctLength,
    correctNumeric,
    correctLowercaseUppercase,
    correctSpecial,
    passwordMatch,
  },
}) => {
  const { t } = useTranslation('error');
  const name = 'Password';
  return (
    <div className="text-left w-5/6 mx-auto" data-testid="pwdchecker">
      <motion.ul variants={fadeInTopVariant()}>
        {correctLength !== undefined && (
          <ContentItem
            state={correctLength}
            children={t('content_checker.length', {
              name,
              count: PWD_MIN_LENGTH,
            })}
          />
        )}
        {correctLowercaseUppercase !== undefined && (
          <ContentItem
            state={correctLowercaseUppercase}
            children={t('content_checker.lowercase_n_uppercase', {
              name,
              count: PWD_MIN_UPPERCASE,
            })}
          />
        )}
        {correctNumeric !== undefined && (
          <ContentItem
            state={correctNumeric}
            children={t('content_checker.numeric', {
              name,
              count: PWD_MIN_NUMERIC,
            })}
          />
        )}
        {correctSpecial !== undefined && (
          <ContentItem
            state={correctSpecial}
            children={t('content_checker.special', {
              name,
              count: PWD_MIN_SPECIAL,
            })}
          />
        )}
        {passwordMatch !== undefined && (
          <ContentItem
            state={passwordMatch}
            children={t('content_checker.match_repeat')}
          />
        )}
      </motion.ul>
    </div>
  );
};

const Checker: React.FC<{ state: boolean }> = ({ state }) =>
  state ? (
    <FontAwesomeIcon
      icon={faCheckCircle}
      className={'text-green-pearl'}
      size={'sm'}
    />
  ) : (
    <FontAwesomeIcon
      icon={faTimesCircle}
      className={'text-red-alert'}
      size={'sm'}
    />
  );
/**
 * Item in the list of conditional content checker
 * @param {boolean} state -- Show cross or check
 * @param {JSX.Element} children -- Usually text translation, can be anything
 */
const ContentItem: React.FC<{ state: boolean }> = ({ state, children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <li className="flex">
      <span>
        <Checker state={state} />
      </span>
      <Typography
        size={'xs'}
        color={theme === 'light' ? 'text-gray-secondary' : 'text-gray-primary'}
        classOverrides={['ml-2 pt-1 ']}
      >
        {children}
      </Typography>
    </li>
  );
};
