import React, { useContext, useEffect } from 'react';
import { PricingFeatures } from '@typings/pricingFeatures';
import { useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'user/contexts/user.context';
import { License } from 'common/hooks/useLicense.hook';
import { BillingCol } from '../BillingCol/BillingCol.component';
import { Product, useCheckout } from 'common/hooks/useCheckout.hook';
import { PREMIUM_LICENSE_PRICEID } from 'common/constants/general';
import { openInNewTab } from '@utils/object.util';

type Props = {
  pricing: Array<PricingFeatures>;
  license: License | null | undefined;
};

/**
 * Component used by the pricing page to display the page
 * @param pricing: An array of type PricingFeatures defined in Pricing
 * Show the table by row and associated a background color and a row title
 */
export const BillingTable: React.FC<Props> = ({ pricing, license }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identity } = useContext(UserContext);

  const [createCheckoutPage, checkoutUrl] = useCheckout();
  const configureCheckout = (product: Product) => {
    createCheckoutPage(product);
  };

  const redirectTo = (location: string) => {
    history.push(location);
  };

  useEffect(() => {
    if (!checkoutUrl) return;
    window.location.href = checkoutUrl;
  }, [checkoutUrl]);

  const buttonsAllowed = () => {
    if (!license?.licenseType?.name) return ['PREMIUM', 'ON DEMAND'];
    switch (license?.licenseType?.name) {
      case 'Core':
      case 'Core plan':
        return ['PREMIUM', 'ON DEMAND'];
      case 'Premium':
      case 'Premium plan':
        if (license.active) {
          return ['ON DEMAND'];
        }
        return ['PREMIUM', 'ON DEMAND'];
      case 'On demand':
      case 'On demand plan':
        return [];
    }
  };

  const getActionButton = (pricingName: string) => {
    switch (pricingName) {
      case t('pricing.ondemand.name'):
        return openInNewTab('https://tally.so/r/3E2aAw');
      case t('pricing.core.name'):
        return identity ? redirectTo('/_/jobs') : redirectTo('/u/signin');
      case t('pricing.premium.name'):
        return identity
          ? configureCheckout({ priceID: PREMIUM_LICENSE_PRICEID })
          : redirectTo('/u/signin');
      default:
        return undefined;
    }
  };

  const rowNumber = [0, 1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="justify-start lg:justify-center flex flex-row items-end w-full mb-2">
      <div className=" max-w-14 mb-12 flex flex-col text-right absolute md:static max-h-full">
        {rowNumber.map((number, index) => (
          <div
            key={`${index} - ${number}`}
            className={cn(
              'z-10 w-full text-sm pr-2 xl:text-md text-gray-secondary font-comfortaa overflow-wrap break-word grid items-center',
              index % 2 === 1 && index < 8
                ? 'bg-gray-primary  dark:text-gray-primary dark:bg-dark-secondary'
                : ' bg-white  dark:text-gray-primary dark:bg-secondary-default',
              index !== 8 && index % 2 === 0 && '',
              index === 0 ? 'h-14 dark:bg-transparent' : 'h-14',
            )}
          >
            {t(`pricing.content.${index}`)}
          </div>
        ))}
      </div>
      <div className="flex flex-row ml-56 md:ml-0">
        {pricing.map((element, index) => (
          <div key={`${element.name}-${index}`} className="w-max">
            <BillingCol
              col={pricing[index]}
              currentLicenseType={license?.licenseType}
            />
            {buttonsAllowed() && buttonsAllowed()?.includes(element.name) ? (
              <PrimaryButton
                key={pricing[index].textButton}
                classOverrides={[
                  'text-center py-1 mx-2 mt-4 m-0 w-28 px-1 dark:bg-gray-primary dark:text-secondary-default font-md',
                ]}
                bgColor={'bg-secondary-default'}
                bold={true}
                margin={'m-0'}
                font={'comfortaa'}
                content={pricing[index].textButton}
                action={() => getActionButton(pricing[index].name)}
              />
            ) : (
              <div className="w-28 h-8 px-4 py-1 mx-2 mt-4" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
