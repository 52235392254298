import { useContext, useReducer, useState } from 'react';
import { HttpContext } from '../contexts/http.context';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { API_ROUTES } from 'common/constants/routes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { JobToLink } from '@jobs/models/JobToLink.model';
import {
  toastError,
  toastSuccess,
} from '@components/Toasters/Toaster.component';
import { useTranslation } from 'react-i18next';
import { JobCreationResponse } from '@jobs/models/JobCreationResponse.model';
import { capitalizeFirstLetter } from '@utils/text.util';

export type ReturnType = [
  (job: JobToLink) => void,
  JobCreationResponse | undefined,
  boolean,
  boolean,
];

export const useJobToLink = (): ReturnType => {
  const [, dispatch] = useReducer(httpReducer<JobCreationResponse>(), null);
  const [jobCreationResponse, setJobCreationResponse] = useState<
    JobCreationResponse | undefined
  >();
  const httpService = useContext(HttpContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [jobCreated, setJobCreated] = useState(false);

  const addJob = (job: JobToLink): void => {
    setLoading(true);

    httpService
      .fetch<JobCreationResponse>('POST', API_ROUTES.GET_JOBS, {
        body: job,
      })
      .then(async (res) => {
        if (res.response?.analysis) {
          if (res.status === StatusCodes.CREATED) {
            setJobCreated(true);
            toastSuccess(t('toast.job_created'));
          }
          setJobCreationResponse(res.response);
        } else {
          if (res.response?.errors?.body) {
            toastError(capitalizeFirstLetter(res.response?.errors?.body));
          }
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return [addJob, jobCreationResponse, loading, jobCreated];
};
