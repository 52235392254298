import i18n from 'i18next';
import {
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';

export const recoveryFilterUiNodes = (
  flow: SelfServiceRecoveryFlow,
): Promise<SelfServiceRecoveryFlow> => {
  const idIndex = flow.ui.nodes.findIndex(
    (e) =>
      isUiNodeInputAttributes(e.attributes) && e.attributes.name === 'email',
  );
  if (idIndex === -1) throw new Error('Identifier field not found');
  const item = flow.ui.nodes[idIndex];
  if (item) {
    item.meta.label = {
      id: 0,
      type: '',
      text: i18n.t('input_fields.email'),
    };
  }

  flow.ui.nodes[idIndex] = item;

  return Promise.resolve(flow);
};

export const KratosRecoveryFiltersIn = [recoveryFilterUiNodes];

export const recoveryFilterOutMethod = (
  flow: SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
): Promise<SubmitSelfServiceRecoveryFlowWithLinkMethodBody> => {
  flow.method = 'link';
  return Promise.resolve(flow);
};

export const KratosRecoveryFiltersOut = [recoveryFilterOutMethod];
