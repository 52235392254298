import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

const previousTrack = mixpanel.track.bind(mixpanel);
/**
 * Rewriting method of tracking so we can easily disable it if needed
 * with RGPD
 * @param event_name
 * @param properties
 * @param optionsOrCallback
 * @param callback
 */
mixpanel.track = (
  event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
) => {
  previousTrack(`${event_name}`, properties, optionsOrCallback, callback);
};

export default mixpanel;
