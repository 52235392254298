import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import { License } from 'common/hooks/useLicense.hook';

type Props = {
  license: License | null | undefined;
};

export const AutoReload: React.FC<Props> = ({ license }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [value, setValue] = useState(false);
  useEffect(() => {
    if (!license || value) return;
    setValue(true);
  }, [license, value]);

  // const handleToggle = () => {
  //   if (!refreshUserLicense) return;
  //   // Uncomment this when the auto-reload desactivation is enabled
  //   // setValue(!value);
  //   // refreshUserLicense();
  // };
  // const { refreshUserLicense } = useContext(ProjectsContext);
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex items-center justify-center relative">
        <Tooltip
          colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
          icon={faQuestionCircle}
          size="lg"
          title={t('my_projects.auto_reload.tooltip')}
          classOverrides={['mr-2']}
          tooltipClass="max-w-18"
        />
        <Typography color="text-gray-secondary dark:text-gray-primary">
          <Trans
            i18nKey="my_projects.auto_reload.title"
            values={{ status: value ? 'activated' : 'deactivated' }}
          >
            Pipeline updates:
            <span className={cn(value ? 'text-green-pearl' : 'text-red-alert')}>
              {value ? 'activated' : 'deactivated'}
            </span>
          </Trans>
        </Typography>
      </div>
      {/* <div className="flex items-center mt-1">
        <Typography
          color="text-gray-secondary dark:text-gray-primary"
          size="sm"
          bold
        >
          {t('action:off')}
        </Typography>
        <input
          checked={value}
          onChange={handleToggle}
          className="react-switch-checkbox "
          id={`react-switch-new`}
          type="checkbox"
          disabled
          onClick={() => {
            toastInfo("You can't disable this feature for the moment");
          }}
        />
        <label
          className="mx-2 react-switch-label bg-gray-input shadow-button-inner"
          htmlFor={`react-switch-new`}
        >
          <span
            className={cn(
              `react-switch-button`,
              value
                ? // 'bg-green-pearl'
                  'bg-gray-secondary'
                : 'bg-gray-secondary',
            )}
          />
        </label>
        <Typography
          color="text-gray-secondary dark:text-gray-primary"
          size="sm"
          bold
        >
          {t('action:on')}
        </Typography>
      </div> */}
    </div>
  );
};
