import { Typography } from '@components/Typography/Typography.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BorderColor,
  ClassOverrides,
  TextColor,
  Size,
  BgColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { simpleFadeIn } from '@utils/motion.util';
import { motion } from 'framer-motion';

/**
 * This component is used to display an highlighter around an element
 */

type Props = ClassOverrides & {
  borderColor: BorderColor;
  textColor: TextColor;
  textSize: Size;
  textBgColor?: BgColor;
};

export const Highlighter: React.FC<Props> = ({
  children,
  borderColor,
  textColor,
  textSize,
  textBgColor,
  classOverrides = [],
}) => {
  const { t } = useTranslation();

  return (
    <motion.div
      className={cn(
        'py-5 border-4 rounded-2xl flex flex-col items-center',
        borderColor,
        ...classOverrides,
      )}
      variants={simpleFadeIn()}
    >
      <Typography
        size={textSize}
        font={'comfortaa'}
        color={textColor}
        classOverrides={[
          'absolute -mt-9 bg-white px-2',
          textBgColor ? textBgColor : '',
        ]}
      >
        {t('pricing.popular_text')}
      </Typography>
      {children}
    </motion.div>
  );
};
