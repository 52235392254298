import { createContext } from 'react';
import { Session } from '@ory/kratos-client';

export interface ISession extends Partial<Session> {
  action: {
    whoami: () => void;
    logout: () => void;
  };
}

export const UserContext = createContext<ISession>({
  action: {
    whoami: () => {
      throw new Error(
        'Calling userContext.action.whoami while not loaded properly yet',
      );
    },
    logout: () => {
      throw new Error(
        'Calling userContext.action.logout while not loaded properly yet',
      );
    },
  },
});
UserContext.displayName = 'UserContext';
