/**
 * Animation where the item fade in with a slide from left
 * @param {number} x -- X origin of animation
 * @param {number} delay -- Appearance delay
 * @constructor
 */
export const fadeInLeftVariant = (x = -20, delay = 0.1) => ({
  hidden: {
    opacity: 0,
    x: x,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      delay: delay,
    },
  },
});
export const boxAnimation = {
  key: 'box',
  initial: {
    y: '50%',
    opacity: 0,
    scale: 0.5,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
  exit: {
    y: '50%',
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  transition: {
    duration: 0.2,
    ease: 'easeOut',
  },
};
export const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },

  hidden: { opacity: 0, scale: 0 },
};
export const fadeInRightVariant = (x = 20, delay = 0.1) => ({
  hidden: {
    opacity: 0,
    x: x,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      delay: delay,
    },
  },
});
/**
 * Animation where the item fade in with a slide from top
 * @param {number} [y=-20] -- Y origin of animation
 * @param {number} [delay=0.3] -- Appearance delay
 * @constructor
 */
export const fadeInTopVariant = (y = -20, delay = 0.3) => ({
  hidden: {
    opacity: 0,
    y: y,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: delay,
    },
  },
});

/**
 * Animation where the item fade in and its children
 * @param stagger
 * @constructor
 */
export const fadeInStaggerChildren = (stagger = 0.5) => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: stagger,
    },
  },
});

/**
 * A basic fade in, only opacity update
 * @constructor
 */
export const simpleFadeIn = () => ({
  hidden: { opacity: 0 },
  show: { opacity: 1 },
});

export const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

export const randomDuration = () => Math.random() * 0.07 + 0.23;

export const shake = () => ({
  rotate: [-1, 1.3, 0],
  transition: {
    delay: getRandomDelay(),
    repeat: 10,
    duration: randomDuration(),
  },
});

export const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2,
  };
};

export const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 2,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};
