import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const LightningIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '29',
  width = '29',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <g clipPath="url(#clip0_5632_2)">
        <path
          d="M0.000709445 14.4978C3.91046 14.4978 7.82021 14.5028 11.7293 14.4893C12.0506 14.4878 12.1166 14.5771 12.1031 14.8777C12.0776 15.4338 12.0776 15.9921 12.1031 16.5475C12.1166 16.8488 12.0506 16.9388 11.7285 16.9374C8.59635 16.9224 5.46415 16.9295 2.33124 16.9281C1.55439 16.9281 0.776842 16.9216 0 16.9181V14.4978H0.000709445Z"
          fill="white"
        />
        <path
          d="M19.3171 28.9807V28.6073C19.3171 24.8356 19.3136 21.0632 19.3242 17.2915C19.3249 17.0002 19.2625 16.9174 18.9624 16.9209C17.4903 16.9388 16.0175 16.9288 14.4922 16.9288C17.7088 11.2623 20.9063 5.6308 24.1038 0L24.179 0.0128508V0.379099C24.179 4.15081 24.1839 7.92324 24.1704 11.695C24.169 12.0226 24.2641 12.0776 24.5621 12.0741C26.0214 12.0583 27.4814 12.0669 29.0003 12.0669C25.7795 17.7391 22.582 23.3692 19.3852 28.9993L19.3171 28.98V28.9807Z"
          fill="white"
        />
        <path
          d="M13.1143 21.8042C9.67494 21.8042 6.23626 21.8006 2.79688 21.8106L13.1143 21.8042Z"
          fill="white"
        />
        <path
          d="M8.48025 9.6324C10.3766 9.6324 12.2729 9.63954 14.1693 9.62455C14.4559 9.62241 14.5155 9.70951 14.5077 9.97652C14.4885 10.5919 14.4935 11.2095 14.5063 11.8249C14.5105 12.0248 14.4552 12.0805 14.2544 12.0798C10.3915 12.0741 6.52785 12.0741 2.66492 12.0798C2.46557 12.0798 2.40385 12.0269 2.4081 11.8227C2.42087 11.1945 2.42513 10.5662 2.40668 9.93868C2.39959 9.68524 2.47834 9.62526 2.72168 9.62598C4.64144 9.63597 6.5612 9.63169 8.48096 9.63169L8.48025 9.6324Z"
          fill="white"
        />
        <path
          d="M12.0585 7.22714C10.563 7.22714 9.06816 7.22071 7.57265 7.23356C7.31016 7.23571 7.22644 7.17217 7.23496 6.90087C7.25482 6.27332 7.24773 5.64506 7.23779 5.01751C7.23425 4.8169 7.2981 4.75479 7.49958 4.7555C10.5608 4.76121 13.6221 4.76121 16.6833 4.7555C16.8721 4.7555 16.9239 4.81476 16.921 4.99752C16.9118 5.63721 16.9061 6.27761 16.9231 6.91658C16.9295 7.16146 16.8664 7.23499 16.6145 7.23285C15.0956 7.22071 13.5767 7.22642 12.0585 7.22642V7.22714Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5632_2">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
