import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { CheckIcon } from '@components/Icons/CheckIcon/CheckIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { BgColor, ClassOverrides } from '@typings/ClassOverrides.type';
import { PREMIUM_LICENSE_PRICEID } from 'common/constants/general';
import { Product, useCheckout } from 'common/hooks/useCheckout.hook';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GrayBox } from 'user/components/GrayBox.component';
import { UserContext } from 'user/contexts/user.context';

export type Props = {
  title: string;
  subtitle: string;
  price?: number;
  features: string[];
  isButtonTop: boolean;
  textButton: string;
  actionButton?: void | (() => void);
  bgColorButton: BgColor;
  bgColor: BgColor;
  showModal?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @param title : the title of the card
 * @param subtitle : the subtitle of the card
 * @param price : the price whosn, could be undefined
 * @param features : An array of string that defines all available features
 * @param isButtonTop : A boolean to show the button under the subtitle. If false, is displayed under features
 * @param textButton : the text to be shown on the button
 * @param bgColorButton : The background color of the button
 */
export const PricingCard: React.FC<Props & ClassOverrides> = ({
  title,
  subtitle,
  price,
  features,
  isButtonTop = false,
  textButton = 'Sign up',
  bgColorButton = 'bg-secondary-default',
  bgColor,
  showModal,
  setShowModal,
  classOverrides = [],
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identity } = useContext(UserContext);
  const redirectTo = (location: string) => {
    history.push(location);
  };
  const [createCheckoutPage, checkoutUrl] = useCheckout();
  useEffect(() => {
    if (!checkoutUrl) return;
    window.location.href = checkoutUrl;
  }, [checkoutUrl]);
  const configureCheckout = (product: Product) => {
    createCheckoutPage(product);
  };
  const getActionButton = (pricingName: string) => {
    switch (pricingName) {
      case t('pricing.ondemand.name'):
        return setShowModal ? setShowModal(!showModal) : undefined;
      case t('pricing.core.name'):
        return identity ? redirectTo('/_/jobs') : redirectTo('/u/signin');
      case t('pricing.premium.name'):
        return identity
          ? configureCheckout({ priceID: PREMIUM_LICENSE_PRICEID })
          : redirectTo('/u/signin');
      default:
        return undefined;
    }
  };

  const getButton = () => (
    <PrimaryButton
      classOverrides={[
        'text-xl px-16 my-3 whitespace-nowrap dark:text-secondary-default dark:bg-gray-primary',
      ]}
      bgColor={bgColorButton}
      bold={false}
      font={'ubuntu'}
      content={textButton}
      action={() => getActionButton(title)}
    />
  );

  return (
    <GrayBox
      classOverrides={[
        'mt-0 p-5 flex flex-col items-center',
        bgColor,
        ...classOverrides,
      ]}
    >
      {/* Show the title and subtitle*/}
      <Title size={'h2'} color={'text-secondary-default'} bold>
        {title}
      </Title>
      <Typography
        size={'base'}
        classOverrides={['dark:text-gray-primary']}
        color={'text-secondary-default'}
        font={'ubuntu'}
      >
        {subtitle}
      </Typography>

      {isButtonTop ? getButton() : null}

      {/* Show the price if defined */}
      {price !== undefined ? (
        <Title
          size={'h3'}
          color={'text-secondary-default'}
          classOverrides={['mt-7 dark:text-white']}
          bold
        >
          {price !== 0 ? `${price}${t('pricing.price_unit')}` : 'Free'}
        </Title>
      ) : null}

      {/* Display a stack of features with a check icon for each */}
      <div className="flex flex-col items-center w-full">
        {features.map((feature, index) => (
          <span
            className="my-3 flex flex-row justify-start flex-grow w-full pl-2"
            key={`${feature}-${index}`}
          >
            <span className="mr-5 pt-1">
              <CheckIcon
                color="text-primary-default"
                width={'23.8'}
                height={'21.7'}
              />
            </span>
            <Typography
              color={'text-gray-secondary'}
              classOverrides={['dark:text-gray-primary']}
            >
              {feature}
            </Typography>
          </span>
        ))}
      </div>

      {!isButtonTop ? getButton() : null}
    </GrayBox>
  );
};
