import React from 'react';
import { ISession, UserContext } from '../../contexts/user.context';
import { newKratosSdk } from '../../../kratos/helpers/kratos.helper';
import { Session, V0alpha2Api } from '@ory/kratos-client';
import { Component } from 'react';
import { LoadingPage } from '../../../pages/Loading.page';
import { AnimatePresence } from 'framer-motion';
import {
  toastError,
  toastSuccess,
} from '@components/Toasters/Toaster.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { initFeatures } from '@components/FeatureFlag/FeatureFlag.component';
import Plausible from 'plausible-tracker';

type State = {
  session: ISession;
  warmingUp: boolean;
  sdk: V0alpha2Api;
};
interface Props extends WithTranslation {}

class AuthProviderComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      session: {
        action: {
          whoami: this.whoami.bind(this),
          logout: this.logout.bind(this),
        },
      },
      warmingUp: true,
      sdk: newKratosSdk(),
    };
  }

  resetSession() {
    this.setState((v) => ({ session: { action: v.session.action } }));
  }

  setSession(data: Session) {
    this.setState((v) => ({
      ...v,
      session: { action: v.session.action, ...data },
    }));
  }

  whoami() {
    this.setState((v) => ({ ...v, warmingUp: true }));
    this.state.sdk
      .toSession()
      .then((res) => this.setSession(res.data))
      .catch(() => this.resetSession())
      .finally(() => {
        this.setState((v) => ({ ...v, warmingUp: false }));
      });
  }

  async logout() {
    this.setState((v) => ({ ...v, warmingUp: true }));
    this.state.sdk
      .createSelfServiceLogoutFlowUrlForBrowsers()
      .then(async (res) => {
        if (!res.data.logout_url) {
          toastError(this.props.t('error:toast.disconnect'));
          return;
        }
        // At this stage it contains a full URL to logout
        let data = res.data.logout_url;
        data = data.split('?token=')[1];
        const submitter = await this.state.sdk.submitSelfServiceLogoutFlow(
          data,
        );
        if (submitter.status !== 204) {
          toastError(this.props.t('error:toast.disconnect'));
          return;
        }

        this.resetSession();
        toastSuccess(this.props.t('toast.disconnected'));
      })
      .catch(() => {
        toastError(this.props.t('error:toast.disconnect'));
      })
      .finally(() => this.setState((v) => ({ ...v, warmingUp: false })));
  }

  plausibleActived = false;
  plausible = () => {
    let currentDomain = '';
    if (this.plausibleActived) return;
    if (process.env.NODE_ENV === 'production') {
      currentDomain = 'r2devops.io';
    } else if (process.env.NODE_ENV === 'test') {
      currentDomain = 'r2devops.tech';
    }
    const { enableAutoPageviews } = Plausible();
    Plausible({
      domain: currentDomain,
      hashMode: true,
    });
    enableAutoPageviews();
    this.plausibleActived = true;
  };

  componentDidMount() {
    mixpanel.init(
      process.env.REACT_APP_MIXPANEL_TOKEN
        ? process.env.REACT_APP_MIXPANEL_TOKEN
        : '27cea49990a183a8cedf01c61aa6e62c',
    );
    if (process.env.REACT_APP_MIXPANEL_TOKEN)
      mixpanel.set_config({ secure_cookie: true });
    initFeatures();
    this.whoami();
    this.plausible();
  }
  render() {
    return (
      <UserContext.Provider value={this.state.session}>
        <AnimatePresence exitBeforeEnter>
          {this.state.warmingUp ? (
            <LoadingPage key={'loading'} />
          ) : (
            this.props.children
          )}
        </AnimatePresence>
      </UserContext.Provider>
    );
  }
}

export const AuthProvider = withTranslation()(AuthProviderComponent);
