import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassOverrides, TextColor } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides & {
  rateUp: number;
  rateDown: number;
  textColor?: TextColor;
  iconsColor?: TextColor;
};

export const JobRating: React.FC<Props> = ({
  classOverrides = [],
  rateUp,
  rateDown,
  textColor,
  iconsColor,
}) => {
  return (
    <div
      className={cn('text-sm', textColor, ...classOverrides)}
      data-testid="rating"
    >
      {rateUp}
      <FontAwesomeIcon
        className={cn('ml-1 mr-2', iconsColor)}
        icon={faThumbsUp}
      />
      <FontAwesomeIcon
        className={cn('transform scale-x-negative-1 mr-1', iconsColor)}
        icon={faThumbsDown}
      />
      {rateDown}
    </div>
  );
};
