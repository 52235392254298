import { useEffect, useState } from 'react';
import { isClient } from '@utils/classes.util';

type Output = {
  width: number;
  height: number;
};

export const useWindowSize = (
  initialHeight?: number,
  initialWidth?: number,
): Output => {
  const initialState = {
    initialHeight:
      !isClient && initialHeight ? initialHeight : window.innerHeight,
    initialWidth: !isClient && initialWidth ? initialWidth : window.innerWidth,
  };

  const [windowSize, setWindowSize] = useState<Output>({
    width: initialState.initialWidth,
    height: initialState.initialHeight,
  });

  useEffect(() => {
    const resizeHandler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return windowSize;
};
