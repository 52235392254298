import React from 'react';
import { motion } from 'framer-motion';
import { fadeInStaggerChildren, fadeInTopVariant } from '@utils/motion.util';
import R2_icecream from '@images/R2/R2_icecream.png';
import { Trans, useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { Title } from '@components/Titles/Title.component';

type Props = {
  i18nKey?: string;
  icon?: string;
  title?: string;
  jobError?: boolean;
  useGenericTitle?: boolean;
};

export const ErrorLayout: React.FC<Props & ClassOverrides> = ({
  children,
  i18nKey,
  icon = R2_icecream,
  jobError = false,
  title = '',
  classOverrides = [],
}) => {
  const { t } = useTranslation();
  const getTitle = (cn: string) => (
    <Title
      classOverrides={[cn, 'whitespace-pre-line']}
      color={'text-secondary-default'}
      bold
    >
      <Trans
        i18nKey={
          i18nKey
            ? i18nKey
            : jobError
            ? 'error:error_page.h1.job'
            : 'error:error_page.h1.global'
        }
      >
        {title ? (
          title
        ) : (
          <>
            Oops, <br /> something went wrong
          </>
        )}
      </Trans>
    </Title>
  );

  return (
    <>
      <motion.div
        variants={fadeInStaggerChildren()}
        initial={'hidden'}
        animate={'show'}
        className="flex justify-evenly items-center h-full flex-col lg:flex-row px-8 lg:px-20 w-full mb-64 md:mb-8"
        data-testid="error-page"
      >
        <div className={cn(...classOverrides)}>
          {getTitle(
            'mb-12 visible h-auto lg:hidden lg:h-0 text-center w-full mt-4',
          )}
          <motion.img
            variants={fadeInTopVariant()}
            src={icon}
            alt={
              jobError
                ? t('error:error_page.r2_job_alt')
                : t('error:error_page.r2_alt')
            }
            title={
              jobError
                ? t('error:error_page.r2_job_alt')
                : t('error:error_page.r2_alt')
            }
          />
        </div>
        <div className="w-full lg:w-4/12 flex-shrink-0 h-full text-center flex flex-col my-8 lg:my-auto justify-center">
          {getTitle(
            'h-0 hidden lg:mb-12 lg:h-auto lg:flex w-full justify-center',
          )}
          {children}
        </div>
      </motion.div>
    </>
  );
};
