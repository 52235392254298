import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Modal } from '@components/Modal/Modal.component';
import { Typography } from '@components/Typography/Typography.component';
import { JobPolicy } from '@jobs/models/Job.model';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { useOnJobDetailsUpdate } from 'common/hooks/useOnJobDetailsUpdate';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'user/contexts/user.context';

type Props = {
  setShowPolicyModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPolicyModal: boolean;
  job: JobResponse;
};

export const UpdatePolicyModal: React.FC<Props> = ({
  job,
  setShowPolicyModal,
  showPolicyModal,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [loading, updateJob] = useOnJobDetailsUpdate();
  const { identity } = useContext(UserContext);

  const updateJobPolicy = (policy: JobPolicy) => {
    if (!policy) return;
    updateJob({ policy }, identity?.traits.username, job.name);
  };
  const enableConfirmButton = inputValue === job.name;
  return (
    <Modal
      classOverrides={['px-7 p-4']}
      setShowModal={setShowPolicyModal}
      showModal={showPolicyModal}
      disablePrimaryButton={loading}
      disableSecondaryButton={loading || !enableConfirmButton}
      primaryButtonAction={() => setShowPolicyModal(false)}
      primaryButtonClassOverrides={['h-10 flex items-center']}
      primaryButtonContent={t('action:cancel')}
      secondaryButtonAction={() => updateJobPolicy('private')}
      secondaryButtonBgColor={
        enableConfirmButton ? 'bg-orange-warning' : undefined
      }
      secondaryButtonClassOverrides={['h-10 flex items-center']}
      secondaryButtonContent={t('action:confirm')}
      showCloseIcon={false}
    >
      <h2 className="text-center text-3xl text-secondary-default font-bold mb-4 dark:text-gray-primary">
        {t('my_jobs.modify_your_job.policy.modal.title')}
      </h2>
      <p className="font-comfortaa text-secondary-dark text-center dark:text-gray-primary">
        {/* <div> elements can't be used as children of <p> elements so we use
        span with inline-block class instead */}
        <span className="inline-block">
          <strong>
            {t('my_jobs.modify_your_job.policy.modal.warning_content')}
          </strong>
        </span>
        <span className="inline-block mt-4">
          {t('my_jobs.modify_your_job.policy.modal.content.0')}
        </span>
        <span className="inline-block">
          {t('my_jobs.modify_your_job.policy.modal.content.1')}
        </span>
      </p>
      <div className="w-2/3 mt-6 flex flex-col items-center mx-auto">
        <Typography
          size="lg"
          classOverrides={['mb-3 dark:text-red-500']}
          color="text-red-alert"
        >
          {job.name}
        </Typography>
        <StyledInput
          classOverrides={[
            'button-inner-outer text-secondary-dark',
            enableConfirmButton ? 'border-green-pearl border-2' : '',
          ]}
          placeholderColor="placeholder-gray-secondary"
          placeholder={t('my_jobs.modify_your_job.delete_modal_placeholder')}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      {loading && (
        <SpinnerIcon classOverrides={['my-auto']} color="text-secondary-dark" />
      )}
    </Modal>
  );
};
