import React from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const BarsIcon: React.FC<
  ClassOverrides & Sizing & { color?: TextColor }
> = ({ classOverrides = [], color }) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100.51 99"
      className={cn(...classOverrides, color)}
    >
      <rect
        fill="currentColor"
        x="5.76"
        y="5.45"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
      <rect
        fill="currentColor"
        x="5.76"
        y="20.42"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
      <rect
        fill="currentColor"
        x="5.76"
        y="37"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
      <rect
        fill="currentColor"
        x="5.76"
        y="51.96"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
      <rect
        fill="currentColor"
        x="5.76"
        y="68.55"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
      <rect
        fill="currentColor"
        x="5.76"
        y="83.51"
        width="88.98"
        height="10.04"
        rx="3.19"
        ry="3.19"
      />
    </svg>
  );
};
