import React from 'react';
import { motion } from 'framer-motion';
import { ClassOverrides, Rounded } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides & {
  rounded?: Rounded;
};

export const GrayBox: React.FC<Props> = ({
  children,
  classOverrides = [],
  rounded = 'rounded-2xl',
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className={cn(
        !/.*mt-.*/.test(classOverrides[0] as string) && 'mt-6',
        'mt-6 bg-gray-primary dark:bg-secondary-default filter shadow rounded-2xl w-full',
        ...classOverrides,
        rounded,
      )}
      data-testid={'graybox'}
    >
      {children}
    </motion.div>
  );
};
