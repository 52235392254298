import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides & {
  content?: string;
  uppercase?: boolean;
};

const SectionTitle: React.FC<Props> = ({
  children,
  uppercase = false,
  classOverrides = [],
  content,
}) => {
  return (
    <h3
      className={cn(
        'text-secondary-default dark:text-white text-xl font-bold',
        uppercase && 'uppercase',
        ...classOverrides,
      )}
    >
      {content}
      {children}
    </h3>
  );
};

export { SectionTitle };
