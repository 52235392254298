import React, { useContext, useRef } from 'react';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { SearchIcon } from '@components/Icons/SearchIcon/SearchIcon';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { useTranslation } from 'react-i18next';
import { UseFormRegister } from 'react-hook-form';
import { useOnKeyPress } from '../../../common/hooks/useOnKeyPress.hook';
import { ThemeContext } from 'common/contexts/theme.context';

type Props = ClassOverrides & {
  onSubmit: () => void;
  register: UseFormRegister<any>;
  placeholder?: string;
};

export const SearchJobsForm: React.FC<Props> = ({
  classOverrides = [],
  onSubmit,
  placeholder = 'Search CI/CD job',
  register,
}: Props) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLDivElement>(null);
  useOnKeyPress('Enter', onSubmit, formRef);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={cn('flex-grow max-w-xl', ...classOverrides)}
      ref={formRef}
      role="search"
    >
      <label htmlFor="search-box" className="block h-0">
        {t('hub.search_box_explorer')}
      </label>
      <StyledInput
        bgColor={'bg-secondary-semi-light'}
        borderColor="border-secondary-default"
        classOverrides={[
          'text-lg sm:text-xl py-1.5 pl-12 pr-14 text-center font-bold focus:placeholder-transparent border-2 dark:bg-gray-third dark:border-transparent dark:text-white',
        ]}
        id="search-box"
        placeholder={placeholder}
        placeholderColor={
          theme === 'light'
            ? 'placeholder-secondary-default'
            : 'placeholder-white'
        }
        type="search"
        icon={
          <button type="submit" onClick={() => onSubmit()}>
            <SearchIcon
              classOverrides={[
                'absolute right-4 top-3 hover:opacity-70 transition cursor-pointer text-secondary-default dark:text-white',
              ]}
              height="20"
              title={t('hub.search_icon_title')}
              width="20"
            />
          </button>
        }
        register={register('searchValue')}
      />
    </div>
  );
};
