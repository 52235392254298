import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ClassOverrides, TextColor } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

type Props = ClassOverrides & {
  color?: TextColor;
  size?: SizeProp;
};

export const SpinnerIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  size,
}) => {
  return (
    <motion.div
      initial={{ opacity: 1, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20, transition: { delay: 0.5 } }}
      key={'loading'}
      className={cn('flex items-center justify-center', ...classOverrides)}
    >
      <div
        className={cn(
          'text-center text-4xl dark:text-gray-primary',
          color ?? 'text-secondary-dark',
        )}
      >
        <FontAwesomeIcon
          icon={faSpinner}
          className="animate-spin"
          size={size ?? '1x'}
        />
      </div>
    </motion.div>
  );
};
