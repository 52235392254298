import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides & {
  flex?: boolean;
  justifyStart?: boolean;
};

export const JobContentLeft: React.FC<Props> = ({
  children,
  flex = true,
  justifyStart = true,
  classOverrides = [],
}) => {
  return (
    <div
      className={cn(
        flex && 'flex w-full  lg:w-8/12 lg:mr-4',
        justifyStart && 'justify-start',
        ...classOverrides,
      )}
    >
      {children}
    </div>
  );
};
