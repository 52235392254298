import { useState } from 'react';
import { newKratosSdk } from '../helpers/kratos.helper';
import { useKratosFlow } from '../hooks/useKratosFlow.hook';
import {
  SelfServiceVerificationFlow,
  SubmitSelfServiceVerificationFlowBody,
} from '@ory/kratos-client';
import {
  KratosVerificationFiltersIn,
  KratosVerificationFiltersOut,
} from '../filters/verification.filter';

export const useKratosVerificationFlow = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceVerificationFlow,
    SubmitSelfServiceVerificationFlowBody,
    SelfServiceVerificationFlow
  >(
    flowId
      ? () => Sdk.getSelfServiceVerificationFlow(flowId)
      : () => Sdk.initializeSelfServiceVerificationFlowForBrowsers(),
    (flowId, body, options) =>
      Sdk.submitSelfServiceVerificationFlow(flowId, undefined, body, options),
    KratosVerificationFiltersIn,
    KratosVerificationFiltersOut,
    onSucceed,
  );
};
