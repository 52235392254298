import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GrayBox } from 'user/components/GrayBox.component';
import { Typography } from '@components/Typography/Typography.component';
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput.component';
import { FieldValues, useForm, UseFormGetValues } from 'react-hook-form';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { StyledTextarea } from '@components/Form/StyledTextarea/StyledTextarea.component';
import { IconsDropdown } from '../IconsDropdown/IconsDropdown.component';
import { LicenseSelectInput } from '../LicenseSelectInput/LicenseSelectInput.component';
import { UserContext } from 'user/contexts/user.context';
import { MAX_JOB_DESCRIPTION_LENGTH } from 'common/constants/general';
import { useOnJobDetailsUpdate } from 'common/hooks/useOnJobDetailsUpdate';
import { JobModificationFormData } from 'user/types/forms';
import { processFormData } from 'user/components/ModifyJob/ModifyJob.component';

type Props = {
  getValues: UseFormGetValues<FieldValues>;
};

export const LinkJobDetailsForm: React.FC<Props> = ({ getValues }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    register,
    setValue,
  } = useForm();
  const { identity } = useContext(UserContext);

  const { t } = useTranslation();

  const [loading, updateJob] = useOnJobDetailsUpdate();

  const submitHandler = (data: JobModificationFormData) => {
    updateJob(
      processFormData(data),
      identity?.traits.username,
      getValues('name'),
    );
  };

  return (
    <div className="hidden md:block w-9/12">
      <GrayBox
        classOverrides={[
          'flex flex-col justify-center items-center pt-4 pb-10 px-16 relative filter shadow-lg ',
        ]}
      >
        <form
          onSubmit={handleSubmit(submitHandler)}
          className="w-full"
          action=""
        >
          <Typography
            size="lg"
            color="text-gray-secondary"
            classOverrides={[
              'mb-8 w-full inline-block text-center dark:text-gray-primary',
            ]}
          >
            {t('link_job.add_more_info')}
          </Typography>

          <div className="flex flex-col lg:flex-row w-full mb-12 lg:mb-0">
            <div className="lg:mr-28 lg:w-1/2">
              <div className="flex mb-4">
                <LicenseSelectInput
                  classOverrides={['mr-10 w-full']}
                  register={register('license')}
                />
                <IconsDropdown
                  register={register('icon')}
                  setValue={setValue}
                />
              </div>
              <StyledTextarea
                classOverrides={['text-lg dark:text-gray-primary']}
                bgColor={'dark:bg-gray-third'}
                disabled={isSubmitting}
                id={'Description'}
                label={t('common_job_form.labels.job_description_label')}
                maxLength={MAX_JOB_DESCRIPTION_LENGTH}
                placeholder={'Description'}
                register={register('description')}
                rows={5}
              />
            </div>

            <LabelSelectInput classOverrides={['lg:w-1/2']} control={control} />
          </div>

          <PrimaryButton
            classOverrides={[
              'absolute -bottom-5 -right-5 text-lg dark:bg-dark-secondary',
            ]}
            content={t('link_job.save_details')}
            font="comfortaa"
            bold={false}
            submitButton
            disabled={isSubmitting || loading}
          />
        </form>
      </GrayBox>
    </div>
  );
};
