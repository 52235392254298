import { ClassOverrides, HTMLStyling } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { simpleFadeIn } from '@utils/motion.util';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useRef } from 'react';

type Props = HTMLStyling &
  ClassOverrides & {
    isOpen: boolean;
  };

export const ActionBox: React.FC<PropsWithChildren<Props>> = ({
  bgColor,
  children,
  classOverrides = [],
  isOpen,
}) => {
  const ref = useRef(null);
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={cn(
              bgColor ?? 'bg-white',
              'rounded-2lg p-3',
              ...classOverrides,
            )}
            data-testid="action-box"
            ref={ref}
            variants={simpleFadeIn()}
            initial={'hidden'}
            animate={'show'}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
