import React from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const GridIcon: React.FC<
  ClassOverrides & Sizing & { color?: TextColor }
> = ({ classOverrides = [], color }) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100.51 99"
      className={cn(...classOverrides, color)}
    >
      <defs></defs>
      <rect
        fill="currentColor"
        x="4.77"
        y="4.36"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="36.77"
        y="4.36"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="68.76"
        y="4.36"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="4.77"
        y="35.91"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="36.77"
        y="35.91"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="68.76"
        y="35.91"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="4.77"
        y="67.46"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="36.77"
        y="67.46"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
      <rect
        fill="currentColor"
        x="68.76"
        y="67.46"
        width="25"
        height="25"
        rx="5.01"
        ry="5.01"
      />
    </svg>
  );
};
