import {
  SelfServiceSettingsFlow,
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
} from '@ory/kratos-client';
import {
  findUiNodeInput,
  isUiNodeInputAttributes,
} from 'kratos/helpers/kratos.helper';

export const settingsFilterUiNodes = (
  flow: SelfServiceSettingsFlow,
): Promise<SelfServiceSettingsFlow> => {
  const usernameItem = findUiNodeInput(flow.ui.nodes, 'traits.username');
  const emailItem = findUiNodeInput(flow.ui.nodes, 'traits.email');

  if (!usernameItem) throw new Error('Username field not found');
  else if (!emailItem) throw new Error('Email field not found');

  if (usernameItem && isUiNodeInputAttributes(usernameItem.attributes))
    usernameItem.attributes.disabled = true;

  if (emailItem && isUiNodeInputAttributes(emailItem.attributes))
    emailItem.attributes.disabled = true;

  return Promise.resolve(flow);
};

/**
 * List of filters used during the fetching of a settings flow
 */
export const KratosSettingsFiltersIn = [settingsFilterUiNodes];

export const settingsFilterOutMethod = (
  flow: SubmitSelfServiceSettingsFlowWithProfileMethodBody,
): Promise<SubmitSelfServiceSettingsFlowWithProfileMethodBody> => {
  flow.method = 'profile';
  return Promise.resolve(flow);
};

/**
 * List of filters used during the submission of a settings flow
 */
export const KratosSettingsFiltersOut = [settingsFilterOutMethod];
