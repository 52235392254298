import React, { useState } from 'react';
import { Modal } from '@components/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { Title } from '@components/Titles/Title.component';
import { SpinnerButton } from '@components/Buttons/SpinnerButton/SpinnerButton.component';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  triggerFunction: (withPush: boolean, destBranch?: string) => void;
  loading: boolean;
};

export const TriggerPipelineModal: React.FC<Props> = ({
  setShowModal,
  showModal,
  triggerFunction,
  loading,
}) => {
  const { t } = useTranslation();
  const [destBranch, setDestBranch] = useState('r2devops');
  return (
    <Modal
      classOverrides={['sm:w-135 pt-6 pb-4 sm:pb-10 px-10']}
      setShowModal={setShowModal}
      showModal={showModal}
    >
      <Title
        bold
        color="text-secondary-default"
        classOverrides={['mb-4']}
        font="ubuntu"
        size="h1"
      >
        Trigger my pipeline
      </Title>
      <StyledInput
        label={t('my_projects.modify.modal_desc')}
        onChange={(data) => {
          setDestBranch(data.target.value);
        }}
        defaultValue="r2devops"
        classOverrides={[
          'px-8 dark:text-gray-primary dark:placeholder-gray-secondary dark:bg-dark-primary outline-blue-500',
        ]}
        placeholderColor="placeholder-input-field"
        bgColor="bg-gray-primary"
        placeholder="r2devops"
        minLength={1}
      ></StyledInput>
      <div className="flex justify-center items-center">
        <SpinnerButton
          margin="m-0"
          content={'Trigger'}
          loading={loading}
          disabled={loading}
          color="text-gray-primary"
          bold
          classOverrides={['px-8 dark:bg-dark-primary']}
          bgColor="bg-secondary-default"
          action={() => triggerFunction(true, destBranch)}
        />
      </div>
    </Modal>
  );
};
