import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { JobPage } from './Job.page';
import { AllJobsPage } from './AllJobs.page';
import { ErrorBoundary } from 'react-error-boundary';
import { JobErrorPage } from 'pages/JobError.page';
import { NotFoundThrow } from '@components/Errors/NotFound.component';
import { Layout } from '@components/Page/Layout/Layout.component';
import { RouteConditions } from '@typings/RouteConditions.type';
import { ProtectedRoute } from '@components/ProtectedRoute/ProtectedRoute';

const AuthPagesConditions: RouteConditions = {
  isAuthenticated: false,
};

export type JobParams = {
  owner: string;
  job: string;
};

const JobRouter: React.FC = () => {
  let match = useRouteMatch();
  return (
    <ErrorBoundary
      key={window.location.pathname}
      FallbackComponent={JobErrorPage}
    >
      <Layout>
        <Switch>
          <ProtectedRoute
            path={`${match.path}`}
            exact
            return_to={'/dashboard/projects'}
            component={JobPage}
            conditions={AuthPagesConditions}
          />
          <Route path={`${match.path}:owner/:job`} component={JobPage} />

          <ProtectedRoute
            path={`${match.path}hub`}
            exact
            return_to={'/dashboard/hub'}
            component={AllJobsPage}
            conditions={AuthPagesConditions}
          />
          <ProtectedRoute
            path={`${match.path}jobs`}
            exact
            return_to={'/dashboard/jobs'}
            component={AllJobsPage}
            conditions={AuthPagesConditions}
          />
          <Route path={'*'} component={NotFoundThrow} />
        </Switch>
      </Layout>
    </ErrorBoundary>
  );
};

export { JobRouter };
