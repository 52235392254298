import { NavBarButtons } from '@components/NavigationBar/NavBarButtons/NavBarButtons.component';
import { NavBarUserProfile } from '@components/NavigationBar/NavBarUserProfile/NavBarUserProfile.component';
import { ThemeToggleLaserSword } from '@components/ThemeToggle/ThemeToggleLaserSword.component';
import { Typography } from '@components/Typography/Typography.component';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogInIcon from '@images/LogInIcon.png';
import { cn } from '@utils/classes.util';
import { fadeInLeftVariant, fadeInTopVariant } from '@utils/motion.util';
import { R2Images } from '@utils/text.util';
import { XL_SCREEN_SIZE } from 'common/constants/style.constant';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../user/contexts/user.context';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { ThemeToggle } from '@components/ThemeToggle/ThemeToggle.component';
import { GITLAB_HUB_LINK } from 'common/constants/general';
import { GitIconRoundedWhite } from '@components/Icons/GitIcon/GitIconRoundedWhite';
import { GitLabCounter } from '@jobs/components/GitLabCounter/GitLabCounter.component';

type NavbarLink = {
  to: string;
  name: string;
  target?: string;
  title?: string;
};

export type JobsSearchSubmit = {
  searchValue: string;
};

/**
 * Left button in the top navigation bar
 * @constructor
 */
const NavigationBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [mobileNavState, setMobileNavState] = useState(false);
  const { identity } = useContext(UserContext);
  const randomIndex = Math.floor(Math.random() * R2Images.length);
  const history = useHistory();
  const [links, setLinks] = useState<NavbarLink[] | undefined>();
  const [randomR2Image, setRandomR2Image] = useState(R2Images[randomIndex]);

  // We change the icon only when we do the first render of this component
  useEffect(() => {
    setRandomR2Image(R2Images[randomIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We store the width of the window
  const windowSize = useWindowSize();

  useEffect(() => {
    history.listen(() => {
      setMobileNavState(false);
    });
  }, [history]);
  const [mountCounter, setMountedCounter] = useState(false);

  // If the window width is superior or equal at 1024 we close the mobile navigation bar
  useEffect(() => {
    if (windowSize.width >= XL_SCREEN_SIZE) {
      setMobileNavState(false);
      setMountedCounter(true);
    }
  }, [windowSize]);

  useEffect(() => {
    let i = 0;
    while (i18n.exists('home.navbar.links.' + i)) i++;
    let translations: NavbarLink[] = [];
    for (let j = 0; j < i; j++) {
      translations.push({
        name: t('home.navbar.links.' + j + '.name'),
        to: t('home.navbar.links.' + j + '.link'),
        target: i18n.exists('home.navbar.links.' + j + '.target')
          ? t('home.navbar.links.' + j + '.target')
          : undefined,
        title: i18n.exists('home.navbar.links.' + j + '.title')
          ? t('home.navbar.links.' + j + '.title')
          : undefined,
      });
    }
    if (!identity) {
      translations = translations.filter((trans) => {
        return trans.name !== 'Dashboard' && trans.name !== 'Roadmap';
      });
    } else {
      translations = translations.filter((trans) => {
        return trans.name !== 'Roadmap';
      });
    }
    setLinks(translations);
    return () => {
      setLinks([]);
      setRandomR2Image('');
      setMobileNavState(false);
      setMountedCounter(false);
    };
  }, [i18n, identity, t]);
  return (
    <motion.nav
      className={cn(
        'w-full bg-secondary-default sticky top-0 z-50',
        mobileNavState ? 'h-auto' : 'h-20',
      )}
      initial={'hidden'}
      animate={'show'}
    >
      <div className="px-2 flex justify-between h-full">
        <div className="flex justify-center items-center mx-4 h-full relative mt-2">
          <TrackedLink
            name={'R2Devops Logo'}
            to={'/'}
            data-testid={'navbar-r2'}
            className={cn(
              'flex h-full pt-2 pb-5',
              randomR2Image === R2Images[10]
                ? 'w-10'
                : randomR2Image === R2Images[5]
                ? 'w-14'
                : 'w-7',
            )}
          >
            <motion.img
              variants={fadeInLeftVariant()}
              src={randomR2Image}
              alt={t('r2_alt')}
              className="w-full h-full"
              title={
                identity
                  ? t('action:title.redirect_dashboard')
                  : t('action:title.redirect_home_page')
              }
            />
          </TrackedLink>
        </div>

        <div className="pt-6 pr-5 xl:hidden">
          <span
            onClick={() => setMobileNavState(!mobileNavState)}
            data-testid="navbar-mobile-hamburger"
          >
            <FontAwesomeIcon
              pull={'right'}
              className={
                'text-white cursor-pointer hover:text-primary-default transition duration-300 h-8 '
              }
              icon={faBars}
            />
          </span>
        </div>
        <motion.div
          className={
            'hidden text-sm xl:flex w-10/12 text-white flex-1 justify-between transition duration-500'
          }
          variants={fadeInTopVariant(-20, 0)}
        >
          <div className="flex items-center w-4/6 xl:w-min">
            <div
              className={cn(
                'flex justify-evenly ',
                'w-full flex-wrap xl:flex-nowrap',
              )}
            >
              {links &&
                links.map((link, index) =>
                  link.name !== 'Documentation' &&
                  link.name !== 'Blog' &&
                  link.name !== 'CI/CD Generator' ? (
                    (identity || link.name !== 'My jobs') && (
                      <TrackedLink
                        name={`NavBar ${link.title}`}
                        to={link.to}
                        data-testid={`navbar-mobile-item-${index}`}
                        key={link.name + '-' + index}
                        className="w-max inline-block mx-4"
                      >
                        <Typography
                          size="sm"
                          classOverrides={[
                            'hover:opacity-70 inline-block text-center',
                          ]}
                          font="ubuntu"
                        >
                          {link.name}
                        </Typography>
                      </TrackedLink>
                    )
                  ) : (
                    <OutsideLink
                      name={`NavBar ${link.title}`}
                      to={link.to}
                      rel="noreferrer"
                      key={link.name + '-' + index}
                      className={'mx-4 w-max inline-block'}
                    >
                      <span>
                        <Typography
                          size="sm"
                          font="ubuntu"
                          classOverrides={[
                            'hover:opacity-70 inline-block text-center',
                          ]}
                        >
                          {link.name}
                        </Typography>
                      </span>
                    </OutsideLink>
                  ),
                )}
            </div>
          </div>
          {mountCounter && (
            <div className="flex w-1/2 ml-4 2xl:ml-0 2xl:w-4/12 items-center justify-start">
              <div className="py-2 bg-transparent">
                <GitLabCounter repoUrl={GITLAB_HUB_LINK}>
                  <GitIconRoundedWhite id="1" classOverrides={['w-10 mr-2']} />
                </GitLabCounter>
              </div>
            </div>
          )}
          {identity ? (
            <ThemeToggleLaserSword
              classOverrides={[
                'absolute top-5 w-10 right-64',
                !identity && 'mr-2',
              ]}
            />
          ) : (
            <ThemeToggle
              classOverrides={[
                'absolute top-7 w-10 right-36',
                !identity && 'mr-3 xl:mr-5 ',
              ]}
            />
          )}
          {identity ? (
            <>
              {/* <TrackedLink
                name="NavBar add a job"
                to={'/u/addjob'}
                className={cn(
                  'absolute right-24 top-4 mt-0.5',
                  !identity ? '-mr-10' : '',
                )}
              >
                <SecondaryButton
                  content={t('action:secondary')}
                  classOverrides={[
                    'text-sm dark:bg-dark-secondary dark:text-gray-primary',
                  ]}
                  color="text-secondary-default"
                  bold={true}
                />
              </TrackedLink> */}
              <NavBarUserProfile
                profileImage={identity.traits.picture ?? LogInIcon}
              />
            </>
          ) : (
            <NavBarButtons />
          )}
        </motion.div>
      </div>
      {mobileNavState && (
        <AnimatePresence>
          <div className={cn('w-full block xl:hidden border-b-2 border-white')}>
            <ul className="text-white text-xl font-ubuntu text-center">
              {identity ? (
                <ThemeToggleLaserSword
                  classOverrides={[
                    'absolute left-0 right-0 mx-auto pl-1 w-12 -top-12',
                  ]}
                  width="40"
                />
              ) : (
                <>
                  <ThemeToggle
                    classOverrides={[
                      'absolute left-0 right-0 mx-auto w-10 -top-12 min-w-max',
                    ]}
                  />
                </>
              )}
              <GitLabCounter
                repoUrl={GITLAB_HUB_LINK}
                classOverrides={['flex-col mb-6']}
              >
                <GitIconRoundedWhite id="2" classOverrides={['w-10 ']} />
              </GitLabCounter>

              {links &&
                links.map((link, index) => (
                  <motion.li
                    initial={{ opacity: 0, y: -100 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: { delay: index * 0.1 },
                    }}
                    title={link.title ? link.title : undefined}
                    className={
                      index === 0 ? (identity ? 'mt-3' : 'mt-6') : 'mt-6'
                    }
                    key={link.name + '-' + index}
                  >
                    {link.name !== 'Documentation' &&
                    link.name !== 'Blog' &&
                    link.name !== 'CI/CD Generator' ? (
                      <TrackedLink
                        name={`${link.name}`}
                        to={link.to}
                        title={link.title ? link.title : undefined}
                        key={link.name + '-' + index}
                        className="hover:opacity-70"
                      >
                        {link.name}
                      </TrackedLink>
                    ) : (
                      <OutsideLink
                        name={`${link.name}`}
                        to={link.to}
                        rel="noreferrer"
                        key={link.name + '-' + index}
                        className="hover:opacity-70"
                      >
                        {link.name}
                      </OutsideLink>
                    )}
                  </motion.li>
                ))}
              <NavBarButtons
                classOverrides={['flex-col']}
                logged={!!identity}
              />
            </ul>
          </div>
        </AnimatePresence>
      )}
    </motion.nav>
  );
};

export { NavigationBar };
