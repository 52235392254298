import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DEFAULT_SEARCH_LIMIT,
  MAX_SEARCH_LIMIT,
  MIN_SEARCH_LIMIT,
} from '../../common/constants/general';

export type WithSearchSubProps = {
  paramsValues: SearchProps;
  update: (element: keyof SearchProps, value: string | number) => void;
};

export type SearchProps = {
  search: string;
  per_page: number;
};

/**
 * Transform a query param into an number, if not parsable returns a default value
 * @param urlSearch
 * @param key
 * @param {number} fallbackValue -- If content isn't parsable
 */
const getParamAsNumber = (
  urlSearch: URLSearchParams,
  key: string,
  fallbackValue: number,
) => {
  const queryParam = urlSearch.get(key);
  if (!queryParam) return fallbackValue;

  try {
    return parseInt(queryParam);
  } catch (e) {
    console.error('Could not parse per page query param ', queryParam);
    return fallbackValue;
  }
};

/**
 * HOC used to inject parameters fetched from URL query
 * @param {React.FC} OriginComponent -- Component that is rendered with the HOC
 */
export const withSearch = (OriginComponent: React.FC<WithSearchSubProps>) => {
  // We want to return a new component with the appropriate wrapper of this HOC
  const RenderedComponent: React.FC = () => {
    const history = useHistory();
    const urlSearch = new URLSearchParams(useLocation().search);
    let perPage = getParamAsNumber(urlSearch, 'per_page', DEFAULT_SEARCH_LIMIT);

    if (perPage < MIN_SEARCH_LIMIT) perPage = MIN_SEARCH_LIMIT;
    else if (perPage > MAX_SEARCH_LIMIT) perPage = MAX_SEARCH_LIMIT;
    // We keep it in a state so we don't rerender everything when update the query params (search)
    // Also, we do not need to decode as URLSearchParams.get already does it
    const [paramsValues] = useState<SearchProps>({
      search: decodeURI(urlSearch.get('search') || ''),
      per_page: perPage,
    });

    const updateParams = (
      element: keyof SearchProps,
      value: string | number,
    ) => {
      if (value === '' || value === 0) {
        urlSearch.delete(element);
      } else {
        urlSearch.set(element, encodeURI(`${value}`));
      }
      history.push({
        pathname: history.location.pathname,
        search: '?' + urlSearch,
      });
    };

    const props: WithSearchSubProps = { update: updateParams, paramsValues };
    return <OriginComponent {...props} />;
  };

  return RenderedComponent;
};
