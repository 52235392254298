import React, { useCallback, useEffect, useRef } from 'react';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { CloseIcon } from '@components/Icons/CloseIcon/CloseIcon.component';
import { BgColor, ClassOverrides, Sizing } from '@typings/ClassOverrides.type';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { cn } from '@utils/classes.util';
import { fadeInTopVariant } from '@utils/motion.util';

type Props = Sizing &
  ClassOverrides & {
    bgColor?: BgColor;
    disablePrimaryButton?: boolean;
    disableSecondaryButton?: boolean;
    modalTitle?: string;
    primaryButtonAction?: () => void;
    primaryButtonClassOverrides?: string[];
    primaryButtonContent?: string;
    secondaryButtonAction?: () => void;
    secondaryButtonBgColor?: BgColor;
    secondaryButtonClassOverrides?: string[];
    secondaryButtonContent?: string;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    showCloseIcon?: boolean;
    showModal: boolean;
    textContent?: string;
  };

/**
 * When using children and modalTitle, primaryButtonAction or SecondaryButtonAction
 * properties the component will output both, children comes first.
 * The title, textContent and buttons are optional, if you want to fully customize
 * the modal's content, use only the children prop
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowModal - The function to
 * update the state from the parent component controlling the display of the modal
 */

export const Modal: React.FC<Props> = ({
  bgColor = 'bg-white',
  children,
  classOverrides = [],
  disablePrimaryButton,
  disableSecondaryButton,
  modalTitle,
  primaryButtonAction,
  primaryButtonClassOverrides = [],
  primaryButtonContent,
  secondaryButtonAction,
  secondaryButtonBgColor,
  secondaryButtonClassOverrides = [],
  secondaryButtonContent,
  setShowModal,
  showCloseIcon = true,
  showModal,
  textContent,
}) => {
  const modalBodyRef = useRef(null);
  const onClickOutside = useCallback(() => {
    if (showModal) setShowModal(!showModal);
  }, [showModal, setShowModal]);
  useOnClickOutside(modalBodyRef, onClickOutside);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowModal]);

  return (
    <>
      {showModal && (
        <>
          {/* <!-- BEGIN Backdrop part --> */}
          <div
            className="z-60 fixed top-0 left-0 h-screen w-screen bg-opacity-50 bg-black "
            data-testid="backdrop"
          ></div>
          {/* <!-- END Backdrop part --!> */}

          <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
            onExitComplete={() => null}
          >
            <motion.div
              className="px-6 overflow-y-auto"
              data-testid="modal"
              initial={'hidden'}
              animate={'show'}
              variants={fadeInTopVariant()}
            >
              {/* <!-- BEGIN Body part --> */}
              <div
                role="dialog"
                aria-modal="true"
                className={cn(
                  'z-70 fixed filter shadow dark:bg-secondary-default-high-opacity ' +
                    'mx-auto inset-x-0 top-1/2 transform -translate-y-1/2 rounded-2lg ' +
                    `w-88 sm:w-96 ${bgColor}`,
                  ...classOverrides,
                )}
                data-testid="modal-body"
                ref={modalBodyRef}
              >
                {showCloseIcon && (
                  <CloseIcon
                    action={() => setShowModal(false)}
                    dataTestId="close-icon"
                    classOverrides={[
                      'absolute right-4 top-4 z-50 cursor-pointer text-secondary-dark dark:text-gray-primary',
                    ]}
                  />
                )}
                <>
                  {children}
                  {modalTitle && (
                    <h2 className="text-center text-3xl text-secondary-default dark:text-white font-bold mb-4">
                      {modalTitle}
                    </h2>
                  )}
                  {textContent && (
                    <p className="text-lg text-secondary-dark dark:text-gray-primary font-comfortaa mb-2 overflow-auto">
                      {textContent}
                    </p>
                  )}
                  <div className="w-full flex justify-center">
                    {primaryButtonContent && (
                      <SecondaryButton
                        action={primaryButtonAction}
                        bold
                        color="text-white"
                        classOverrides={[
                          'text-lg',
                          'bg-secondary-default',
                          secondaryButtonContent ? 'mr-6' : '',
                          ...primaryButtonClassOverrides,
                        ]}
                        content={primaryButtonContent}
                        disabled={disablePrimaryButton}
                      />
                    )}
                    {secondaryButtonContent && (
                      <SecondaryButton
                        action={secondaryButtonAction}
                        bold
                        color="text-white"
                        classOverrides={[
                          'text-lg',
                          secondaryButtonBgColor ?? 'bg-secondary-dark',
                          ...secondaryButtonClassOverrides,
                        ]}
                        content={secondaryButtonContent}
                        disabled={disableSecondaryButton}
                      />
                    )}
                  </div>
                </>
              </div>
              {/* <!-- END Body part --!> */}
            </motion.div>
          </AnimatePresence>
        </>
      )}
    </>
  );
};
