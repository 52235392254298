import {
  SelfServiceRegistrationFlow,
  SessionAuthenticationMethodMethodEnum,
  SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from '../helpers/kratos.helper';

/**
 * Remove some fields from the flow as in the registration we only want some of them
 * @param {SelfServiceRegistrationFlow} flow
 */
const registrationFilterUiNodes = <T extends SelfServiceRegistrationFlow>(
  flow: T,
): Promise<T> => {
  const ACCEPTED_FIELDS = [
    'csrf_token',
    'traits.email',
    'traits.username',
    'password',
  ];

  // We more unwanted fields
  flow.ui.nodes = flow.ui.nodes.filter(
    (v) =>
      isUiNodeInputAttributes(v.attributes) &&
      ACCEPTED_FIELDS.indexOf(v.attributes?.name) !== -1,
  );

  return Promise.resolve(flow);
};

/**
 * This filter is here to move the password field into last position & to add the password repeat field
 * It must match each other in the registrationForm page
 * @param {SelfServiceRegistrationFlow} flow
 */
const registrationFilterPassword = <T extends SelfServiceRegistrationFlow>(
  flow: T,
): Promise<T> => {
  flow.ui.nodes = flow.ui.nodes.sort((a, b) => {
    if (
      (isUiNodeInputAttributes(a.attributes) &&
        a.attributes.name === 'traits.email') ||
      (isUiNodeInputAttributes(a.attributes) &&
        a.attributes.name === 'traits.username')
    ) {
      return -1;
    } else if (
      isUiNodeInputAttributes(a.attributes) &&
      a.attributes.name === 'password'
    ) {
      return 1;
    }
    return 0;
  });
  // We add the password repeat field
  flow.ui.nodes.push({
    type: 'input',
    group: 'password',
    attributes: {
      name: 'password_repeat',
      type: 'password',
      required: true,
      disabled: false,
      node_type: 'input',
    },
    messages: [],
    meta: {
      label: {
        id: 0,
        // Do not replace it with i18n, it will crash your tests
        // Strange bug where snake eat its own tail
        text: 'Confirm password',
        type: 'info',
      },
    },
  });
  return Promise.resolve(flow);
};

/**
 * List of filters used during the fetching of a registration flow
 */
export const KratosRegistrationFiltersIn = [
  registrationFilterUiNodes,
  registrationFilterPassword,
];

/**
 * Update the method used for the registration flow with the password type
 * @param {SubmitSelfServiceRegistrationFlowWithPasswordMethodBody} flow
 */
const registrationFilterOutMethod = (
  flow: SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
): Promise<SubmitSelfServiceRegistrationFlowWithPasswordMethodBody> => {
  flow.method = SessionAuthenticationMethodMethodEnum.Password;
  return Promise.resolve(flow);
};

/**
 * List of filters used during the submission of a registration flow
 */
export const KratosRegistrationFiltersOut = [registrationFilterOutMethod];
