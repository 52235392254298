import React from 'react';
import { ThemeContext } from 'common/contexts/theme.context';
import { LunarIcon } from '@components/Icons/ThemeIcons/LunarIcon.component';
import { SunIcon } from '@components/Icons/ThemeIcons/SunIcon.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import Plausible from 'plausible-tracker';

export const ThemeToggle: React.FC<ClassOverrides> = ({
  classOverrides = [],
}) => {
  const { theme, modifyTheme } = React.useContext(ThemeContext);
  const { trackEvent } = Plausible();

  function isDark() {
    return theme === 'dark';
  }
  function toggleTheme(e: React.ChangeEvent<HTMLInputElement>) {
    modifyTheme(e.target.checked ? 'dark' : 'light');
    trackEvent('Click on theme toggler');
  }

  return (
    <label
      htmlFor="toggleB"
      className="flex flex-col items-center cursor-pointer transition duration-300"
    >
      <div
        className={cn('relative transition duration-300', ...classOverrides)}
      >
        <input
          type="checkbox"
          id="toggleB"
          className=" sr-only hover:border-primary-default border-2 transition duration-1000"
          onChange={(e) => toggleTheme(e)}
        />
        {isDark() ? (
          <div className="block bg-white w-12 h-6 p-2 rounded-full transition duration-400"></div>
        ) : (
          <div className="block bg-gray-400 w-12 h-6 p-2 rounded-full transition duration-400"></div>
        )}
        {isDark() ? (
          <SunIcon
            classOverrides={[
              'text-yellow-400 dot absolute left-5 top-0 mt-0.5 transition rounded-full w-7 h-5  duration-1000',
            ]}
          />
        ) : (
          <LunarIcon
            classOverrides={[
              'text-gray-secondary dot absolute left-0 top-0 mt-0.5 transition rounded-full w-7 h-5  duration-1000',
            ]}
          />
        )}
      </div>
    </label>
  );
};
