import { Title } from '@components/Titles/Title.component';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { PlatformToggle } from 'user/components/PlatformToggle/PlatformToggle.component';
import moment from 'moment';
import { toastAdvertise } from '@components/Toasters/Toaster.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { FAQ_LINK } from 'common/constants/general';
import { AddTokenModal } from 'user/components/AddTokenModal/AddTokenModal.component';
import { UserContext } from 'user/contexts/user.context';
import { ProjectsContext } from 'user/contexts/projects.context';
import { AutoReload } from 'user/components/AutoReload/AutoReload.component';
import { GitHubProjects } from 'user/components/GitHubProjects/GitHubProjects.component';
import { GitLabProjects } from 'user/components/GitLabProjects/GitLabProjects.component';
import { motion } from 'framer-motion';
import { fadeInRightVariant } from '@utils/motion.util';

export const Projects: React.FC = () => {
  const { t } = useTranslation();
  const { identity } = useContext(UserContext);

  const pipelineToast = () =>
    toastAdvertise(
      <div className="flex items-center">
        <Typography
          color="text-gray-secondary"
          bold
          classOverrides={['text-sm md:text-lg']}
        >
          {t('my_projects.features_advertise.0')}

          <ul>
            <li>
              <Trans
                i18nKey={'my_projects.features_advertise.1'}
                values={{ jobsNumber: '42' }}
              />
            </li>
            <li>
              <Trans
                i18nKey={'my_projects.features_advertise.2'}
                values={{ actionsNumber: '28' }}
              />
            </li>
          </ul>
          <div className="text-xs mt-2">
            <Trans i18nKey="my_projects.features_advertise.3">
              More details
              <OutsideLink
                name={'FAQ link'}
                to={FAQ_LINK}
                className={'underline hover:opacity-70 '}
                rel="noreferrer"
              >
                on the FAQ
              </OutsideLink>
            </Trans>
          </div>
        </Typography>
      </div>,
    );
  useEffect(() => {
    const latestTriggerDate = localStorage.getItem('autoPipelineUpdate');
    if (!latestTriggerDate) {
      pipelineToast();
      localStorage.setItem(
        'autoPipelineUpdate',
        moment(new Date()).toISOString(),
      );
    } else {
      const duration = moment.duration(moment().diff(latestTriggerDate));
      const hours = duration.asHours();
      if (hours > 1.0) {
        pipelineToast();
        localStorage.setItem(
          'autoPipelineUpdate',
          moment(new Date()).toISOString(),
        );
      }
    }
  });
  const latestPlatform = localStorage.getItem('projectsPlatform');
  const { currentLicense } = useContext(ProjectsContext);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [platformDisplayed, setPlatformDisplayed] = useState<
    'github' | 'gitlab'
  >(
    latestPlatform
      ? latestPlatform === 'github'
        ? 'github'
        : 'gitlab'
      : 'gitlab',
  );
  useEffect(() => {
    if (identity?.traits.gitlab_token && identity?.traits.github_token) return;
    if (platformDisplayed === 'gitlab' && !identity?.traits.gitlab_token) {
      setShowTokenModal(true);
    }
    if (platformDisplayed === 'github' && !identity?.traits.github_token) {
      setShowTokenModal(true);
    }
  }, [
    identity?.traits.gitlab_token,
    identity?.traits.github_token,
    platformDisplayed,
  ]);
  return (
    <>
      {showTokenModal && (
        <AddTokenModal
          title={t('account.modal.title')}
          setShowModal={setShowTokenModal}
          showModal={showTokenModal}
          button={t('action:save')}
          source={platformDisplayed}
        />
      )}
      <div className="w-11/12 sm:w-10/12 md:w-8/12 mx-auto mb-32 md:mb-16 flex flex-col items-center relative">
        <div className="flex justify-center w-full mb-4 relative">
          <Title
            bold
            color="text-secondary-default"
            font="ubuntu"
            size="h1"
            classOverrides={['text-center']}
          >
            {t('my_projects.title')}
          </Title>
        </div>
        <div className="flex flex-col items-center">
          <PlatformToggle
            platformDisplayed={platformDisplayed}
            setPlatformDisplayed={setPlatformDisplayed}
            gitHubBtnClassOverrides={[
              platformDisplayed === 'github'
                ? 'bg-secondary-default'
                : 'bg-transparent',
            ]}
            gitLabBtnClassOverrides={[
              platformDisplayed === 'gitlab'
                ? 'bg-secondary-default'
                : 'bg-transparent',
            ]}
          />
          <div className=" right-14 lg:right-0 2xl:right-24 top-28">
            <AutoReload license={currentLicense} />
          </div>
        </div>
        <motion.div
          className="w-full 2xl:w-3/4 mt-4"
          initial={'hidden'}
          animate={'show'}
          variants={fadeInRightVariant()}
        >
          {platformDisplayed === 'gitlab' ? (
            <GitLabProjects setShowTokenModal={setShowTokenModal} />
          ) : (
            <GitHubProjects setShowTokenModal={setShowTokenModal} />
          )}
        </motion.div>
        <div
          className={cn('w-full text-center mt-20 md:mt-8 ')}
          data-testid="advise-bottom-text"
        >
          <Typography
            classOverrides={['w-full dark:text-gray-primary']}
            color="text-secondary-default"
            bold
          >
            {t('my_projects.more_projects.prompt')}
          </Typography>
          <br />
          <TrackedLink
            to="/dashboard/profile#billing"
            name="get an upgrade on license"
          >
            <PrimaryButton
              bgColor={'bg-secondary-default'}
              content={t('action:upgrade_license')}
              light
              classOverrides={['mx-auto xl:text-md mt-4']}
            />
          </TrackedLink>
        </div>
      </div>
    </>
  );
};
