import { PricingFeatures } from '@typings/pricingFeatures';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'user/contexts/user.context';

const popularPricing = 'PREMIUM';

export const PricingPlans = () => {
  const { identity } = useContext(UserContext);
  const { t } = useTranslation();

  const corePricing: PricingFeatures = {
    isPopular: popularPricing === t('pricing.core.name'),
    name: t('pricing.core.name'),
    description: t('pricing.core.description'),
    price: 0,
    accessHub: true,
    useFree: true,
    projectsNumber: 5,
    support: true,
    autoReload: true,
    selfhosted: false,
    updatesOnDemand: false,
    expertsOnDemand: false,
    textButton: identity ? t('action:get_started') : t('action:register'),
  };

  const premiumPricing: PricingFeatures = {
    isPopular: popularPricing === t('pricing.premium.name'),
    name: t('pricing.premium.name'),
    description: t('pricing.premium.description'),
    price: 30,
    accessHub: true,
    useFree: true,
    projectsNumber: 30,
    support: true,
    autoReload: true,
    selfhosted: false,
    updatesOnDemand: false,
    expertsOnDemand: false,
    textButton: identity ? t('action:subscribe') : t('action:register'),
  };

  const onDemandPricing: PricingFeatures = {
    isPopular: popularPricing === t('pricing.ondemand.name'),
    name: t('pricing.ondemand.name'),
    description: t('pricing.ondemand.description'),
    price: -1,
    accessHub: true,
    useFree: true,
    projectsNumber: 'On demand',
    support: true,
    autoReload: true,
    selfhosted: true,
    updatesOnDemand: true,
    expertsOnDemand: true,
    textButton: t('pricing.text_quote'),
  };

  return [corePricing, premiumPricing, onDemandPricing];
};
