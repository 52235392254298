import { toastError } from '@components/Toasters/Toaster.component';
import { API_ROUTES, API_URL } from './../constants/routes';
import { useContext, useReducer, useState } from 'react';
import { HttpContext } from '../contexts/http.context';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import Plausible from 'plausible-tracker';
import mixpanel from '@utils/mixpanel.util';

export type Product = {
  priceID: string;
};

export type ReturnType = [
  (product: Product) => void,
  string | undefined,
  boolean,
];

export const useCheckout = (): ReturnType => {
  const [, dispatch] = useReducer(httpReducer<string>(), null);
  const [checkoutUrl, setCheckoutUrl] = useState<string | undefined>();
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = Plausible();

  const createCheckoutPage = (product: Product): void => {
    setLoading(true);
    trackEvent('Click on subscribe button of the PREMIUM plan');
    mixpanel.track(`[Click] on PREMIUM plan subscribe button`);
    httpService
      .fetch<any>('POST', API_URL + API_ROUTES.CREATE_CHECKOUT, {
        body: JSON.stringify(product),
      })
      .then(async (res) => {
        if (res.response.url) {
          setCheckoutUrl(res.response.url);
        }
        if (res.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          toastError('We are experiencing some issues, try again later');
        }
        if (!res.response) {
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return [createCheckoutPage, checkoutUrl, loading];
};
