import React, { ButtonHTMLAttributes } from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import mixpanel from '@utils/mixpanel.util';
import { motion } from 'framer-motion';
import { Typography } from '@components/Typography/Typography.component';

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 */

const SideBarButton: React.FC<
  ButtonProps & HTMLStyling & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  content,
  action,
  bgColor,
  classOverrides = [],
  children,
  bold = false,
  light = true,
  color = '',
  type = 'button',
  font = 'ubuntu',
  ...rest
}) => {
  const onClickButton = () => {
    if (!action) return;
    action();
    mixpanel.track(`[Button] Click on ${content}`, {
      buttonType: 'sideBarButton',
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.3 },
      }}
      className="w-full"
    >
      <button
        onClick={onClickButton}
        className={cn(
          ' hover:shadow-button-inner transition duration-500 w-full',
          'filter hover:opacity-70 flex items-center justify-center lg:justify-start p-3 lg:rounded-xl ',
          color,
          bgColor,
          bold && 'font-bold',
          light && 'font-light',
          ...classOverrides,
        )}
        data-testid={`secondarybutton-${content}`}
        aria-label={content}
        type={type}
        {...rest}
      >
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.3 },
          }}
          className="text-white flex lg:flex-row flex-col relative items-center justify-center lg:justify-start"
        >
          {children}
        </motion.div>
        <div></div>
        <Typography
          color="text-white"
          classOverrides={[' hidden lg:block', `font-${font}`]}
          size="sm"
        >
          {content}
        </Typography>
      </button>
    </motion.div>
  );
};

export { SideBarButton };
