import React from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import { Typography } from '@components/Typography/Typography.component';
import { UnlinkIcon } from '@components/Icons/UnlinkIcon/UnlinkIcon.component';
import { UseFormRegisterReturn } from 'react-hook-form';
import { GitHubIcon } from '@components/Icons/GitHubIcon/GitHubIcon.component';
import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import { ChainIcon } from '@components/Icons/ChainIcon/ChainIcon.component';

type GitHubButtonProps = {
  showLinkActivation?: boolean;
  isAccountLinked?: boolean;
  title?: string;
  register?: UseFormRegisterReturn;
  value?: string;
  node?: UiNode;
};

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 *
 * @see HTMLStyling
 * @see ButtonProps
 */
const GitHubButton: React.FC<HTMLStyling & ButtonProps & GitHubButtonProps> = ({
  content,
  action,
  children,
  title,
  classOverrides = [],
  bgColor = 'bg-gray-primary',
  color = 'text-gray-secondary',
  bold = true,
  light = false,
  uppercase = false,
  font = 'ubuntu',
  submitButton = false,
  disabled = false,
  isAccountLinked = false,
  showLinkActivation = false,
  node,
  register,
}) => {
  const onClickButton = () => {
    if (action) action();
  };
  const nodeAttributes = node && (node.attributes as UiNodeInputAttributes);
  return !nodeAttributes ? (
    <button
      onClick={onClickButton}
      className={cn(
        'rounded-2lg transition duration-150 px-2 ' +
          'filter shadow-lg flex flex-row items-center justify-evenly',
        bgColor,
        !disabled && 'hover:shadow-button-inner',
        color,
        `font-${font}`,
        bold && 'font-bold',
        light && 'font-light',
        uppercase && 'uppercase',
        ...classOverrides,
      )}
      data-testid={`githubbutton-${content}`}
      type={submitButton ? 'submit' : 'button'}
      title={title}
      disabled={disabled}
      aria-label={content}
      {...register}
    >
      <GitHubIcon classOverrides={['w-8 lg:w-10 p-1']} />
      <Typography classOverrides={['md:text-sm']} size="xs" color={color}>
        {content}
      </Typography>
      {showLinkActivation ? (
        isAccountLinked ? (
          <ChainIcon />
        ) : (
          <UnlinkIcon />
        )
      ) : (
        <></>
      )}
      {children}
    </button>
  ) : (
    <button
      onClick={onClickButton}
      className={cn(
        'rounded-2lg transition duration-150 px-2 ' +
          'filter shadow-lg flex flex-row items-center justify-evenly',
        bgColor,
        color,
        `font-${font}`,
        bold && 'font-bold',
        light && 'font-light',
        !disabled && 'hover:shadow-button-inner',
        uppercase && 'uppercase',
        ...classOverrides,
      )}
      data-testid={`githubbutton-${content}`}
      type={submitButton ? 'submit' : 'button'}
      title={title}
      disabled={disabled}
      aria-label={content}
      name={nodeAttributes.name}
      value={nodeAttributes.value}
    >
      <GitHubIcon classOverrides={['w-8 lg:w-10 p-1']} />
      <Typography classOverrides={['md:text-sm']} size="xs" color={color}>
        {content}
      </Typography>
      {nodeAttributes.name &&
        (nodeAttributes.name === 'unlink' ? (
          <ChainIcon />
        ) : (
          nodeAttributes.name === 'link' && <UnlinkIcon />
        ))}
      {children}
    </button>
  );
};

export { GitHubButton };
