import { Title } from '@components/Titles/Title.component';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@components/Typography/Typography.component';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { useKratosSettingsFlowWithProfileMethod } from 'kratos/flows/settings.flow';
import { useQuery } from 'common/hooks/useQueryParam.hook';
import { useForm } from 'react-hook-form';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import {
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  UiNode,
} from '@ory/kratos-client';
import { useContext, useEffect, useRef, useState } from 'react';
import { toastWarning } from '@components/Toasters/Toaster.component';
import { motion } from 'framer-motion';
import { fadeInStaggerChildren } from '@utils/motion.util';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { AddTokenModal } from '../AddTokenModal/AddTokenModal.component';
import { UserContext } from 'user/contexts/user.context';
import GitlabLogo from '../../../assets/images/gitlab.png';
import GitHubLogo from '../../../assets/images/github.svg';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import { TrashIcon } from '@components/Icons/TrashIcon/TrashIcon';
import { removeEmptyFields } from '@utils/object.util';
import { SettingsTraits } from 'user/types/traits';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { ThirdPartyCard } from '../ThirdPartyCard/ThirdPartyCard.component';
import gitHubWhiteIcon from '@images/Github_white.svg';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { DOCS_TOKEN_DOCUMENTATION } from 'common/constants/general';

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { identity, action } = useContext(UserContext);
  const [tokenSource, setTokenSource] = useState<'github' | 'gitlab'>('gitlab');
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      'traits.username': identity?.traits.username,
      'traits.email': identity?.traits.email,
      'traits.gitlab_username': identity?.traits.gitlab_username,
      'traits.github_username': identity?.traits.github_username,
      'traits.linkedin': identity?.traits.linkedin,
      'traits.twitter': identity?.traits.twitter,
      'traits.company': identity?.traits.company,
      'traits.job_title': identity?.traits.job_title,
      'traits.website': identity?.traits.website,
      'traits.goodies': identity?.traits.goodies,
      'traits.bio': identity?.traits.bio,
      'traits.picture': identity?.traits.picture,
      'traits.gitlab_token': identity?.traits.gitlab_token,
      'traits.github_token': identity?.traits.github_token,
    },
  });

  const flowId = useQuery().get('flow');
  const [flow, settingsSubmitHandlerFlow] =
    useKratosSettingsFlowWithProfileMethod(flowId, () => {
      action.whoami();
    });

  const [csrfNode, setCsrfNode] = useState<UiNode>();
  const [gitLabTokenNode, setGitLabTokenNode] = useState<UiNode>();
  const [gitHubTokenNode, setGitHubTokenNode] = useState<UiNode>();
  const userGitLabToken = identity?.traits.gitlab_token;
  const userGitHubToken = identity?.traits.github_token;

  useEffect(() => {
    if (flow !== undefined) {
      flow.ui.nodes.forEach((node) => {
        if (isUiNodeInputAttributes(node.attributes)) {
          switch (node.attributes.name) {
            case 'csrf_token':
              setCsrfNode(node);
              break;
            case 'traits.gitlab_token':
              node.attributes.type = 'password';
              setGitLabTokenNode(node);
              break;
            case 'traits.github_token':
              node.attributes.type = 'password';
              setGitHubTokenNode(node);
              break;
            default:
              break;
          }
        }
      });
    }
  }, [
    flow,
    identity?.traits.github_token,
    identity?.traits.gitlab_token,
    tokenSource,
  ]);

  const onSubmit = (
    data: SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  ) => {
    const traits = data.traits as SettingsTraits;
    traits.username = identity?.traits.username;
    traits.email = identity?.traits.email;

    removeEmptyFields(data.traits);
    settingsSubmitHandlerFlow(data);
  };

  const formButtonRef = useRef<HTMLButtonElement>(null);

  const deleteToken = (source: string) => {
    if (source === 'gitlab') {
      setValue('traits.github_token', identity?.traits.github_token as never);
      setValue('traits.gitlab_token', '' as never);
    }
    if (source === 'github') {
      setValue('traits.gitlab_token', identity?.traits.gitlab_token as never);
      setValue('traits.github_token', '' as never);
    }
    if (formButtonRef.current) {
      toastWarning(t('toast.token_deleted'));
      formButtonRef.current.click();
    }
  };

  return (
    <motion.div
      variants={fadeInStaggerChildren()}
      initial={'hidden'}
      animate={'show'}
      className="text-center flex flex-col items-center"
    >
      {flow && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          method={flow.ui.method}
          className="hidden"
        >
          {csrfNode?.attributes && (
            <KratosFormInputs
              register={register}
              errors={errors}
              isSubmitting={isSubmitting}
              flowUiNodes={[csrfNode]}
            />
          )}
          {gitLabTokenNode && (
            <KratosFormInputs
              register={register}
              errors={errors}
              classOverrides={['dark:text-gray-primary']}
              isSubmitting={isSubmitting}
              bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
              flowUiNodes={[gitLabTokenNode]}
              placeholder={'Token'}
              placeholderColor="placeholder-gray-secondary"
              label=" "
            />
          )}
          {gitHubTokenNode && (
            <KratosFormInputs
              register={register}
              errors={errors}
              classOverrides={['dark:text-gray-primary']}
              isSubmitting={isSubmitting}
              bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
              flowUiNodes={[gitHubTokenNode]}
              placeholder={'Token'}
              placeholderColor="placeholder-gray-secondary"
              label=" "
            />
          )}
          <button ref={formButtonRef} type="submit">
            {t('action:save')}
          </button>
        </form>
      )}

      <div className="mb-12">
        <Title size={'h1'} color={'text-secondary-default'} bold>
          {t('account.title')}
        </Title>
      </div>

      {/* Link to platforms Buttons */}
      <ThirdPartyCard />

      {/* Add a token */}
      <div
        id="token"
        className="relative rounded-xl shadow-lg bg-gray-100 dark:bg-secondary-default w-full md:w-4/6 xl:w-3/6 p-8 inline-block mb-8"
      >
        <span className="decoration-clone">
          <Tooltip
            title={t('account.add_token.tooltip')}
            icon={faQuestionCircle}
            place="right"
            size="lg"
            multiline
            classOverrides={['absolute right-4 top-4']}
            tooltipClass="max-w-18 bg-secondary-semi-light"
            colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
          />
          <div className="mb-4">
            <Title size={'h2'} color={'text-secondary-default'} bold>
              {t('account.add_token.title')}
            </Title>
          </div>

          <div className="flex flex-col text-gray-secondary items-center">
            <Typography
              classOverrides={['mb-3 dark:text-white']}
              color="text-gray-secondary"
              size="sm"
            >
              <Trans i18nKey="account.add_token.description">
                Add your token to benefit all R2Devops features. See how to
                generate a token in
                <OutsideLink
                  name={'GitLab documentation'}
                  to={DOCS_TOKEN_DOCUMENTATION}
                  className={'underline text-primary-default hover:opacity-70'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitLab
                </OutsideLink>
                <OutsideLink
                  name={'GitLab documentation'}
                  to={DOCS_TOKEN_DOCUMENTATION}
                  className={'underline text-primary-default hover:opacity-70'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub.
                </OutsideLink>
              </Trans>
            </Typography>
            {!userGitLabToken && (
              <Typography
                classOverrides={['mb-2 dark:text-orange-warning']}
                color="text-gray-secondary"
                size="sm"
                bold
              >
                {t('account.add_token.current_status')}
              </Typography>
            )}
            {userGitLabToken && (
              <>
                <div className="bg-white dark:bg-dark-secondary mt-4 mb-2 rounded-lg flex items-center px-1 py-1 w-max justify-between">
                  <motion.img
                    className="filter transition duration-300 w-8 h-8"
                    src={GitlabLogo}
                  />
                  <Typography
                    classOverrides={[
                      'dark:text-white flex items-center justify-center mx-2',
                    ]}
                    color="text-gray-secondary"
                    size="md"
                    bold
                  >
                    {t('account.add_token.token')}
                  </Typography>
                  <Typography
                    classOverrides={[
                      'dark:text-white flex items-center justify-center pt-3 mr-2',
                    ]}
                    color="text-gray-secondary"
                    size="md"
                    bold
                  >
                    ***************
                  </Typography>
                  <div
                    onClick={() => {
                      deleteToken('gitlab');
                    }}
                  >
                    <TrashIcon
                      color="text-secondary-default"
                      classOverrides={[
                        'w-4 h-full w-max cursor-pointer px-2 rounded-lg dark:text-white hover:opacity-70',
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
            {userGitHubToken && (
              <>
                <div className="bg-white dark:bg-dark-secondary mt-4 rounded-lg flex items-center px-2 py-1 w-max justify-between">
                  <motion.img
                    className="filter transition duration-300 w-6"
                    src={theme === 'light' ? GitHubLogo : gitHubWhiteIcon}
                  />
                  <Typography
                    classOverrides={[
                      'dark:text-white flex items-center justify-center mx-2',
                    ]}
                    color="text-gray-secondary"
                    size="md"
                    bold
                  >
                    {t('account.add_token.token')}
                  </Typography>
                  <Typography
                    classOverrides={[
                      'dark:text-white flex items-center justify-center pt-3 mr-2',
                    ]}
                    color="text-gray-secondary"
                    size="md"
                    bold
                  >
                    ***************
                  </Typography>
                  <div
                    onClick={() => {
                      deleteToken('github');
                    }}
                  >
                    <TrashIcon
                      color="text-secondary-default"
                      classOverrides={[
                        'w-4 h-full w-max cursor-pointer px-2 rounded-lg dark:text-white hover:opacity-70',
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex lg:flex-row  mt-4 flex-col justify-center items-center">
              {!userGitLabToken && (
                <div className="w-48">
                  <PrimaryButton
                    font="comfortaa"
                    content={t('action:add_gitlab_token')}
                    action={() => {
                      setTokenSource('gitlab');
                      setShowModal(true);
                    }}
                    margin="m-0"
                    classOverrides={['mt-2 dark:bg-dark-secondary text-sm']}
                  />
                </div>
              )}
              {!userGitHubToken && (
                <div className="w-48">
                  <PrimaryButton
                    font="comfortaa"
                    content={t('action:add_github_token')}
                    action={() => {
                      setTokenSource('github');
                      setShowModal(true);
                    }}
                    margin="m-0"
                    classOverrides={['mt-2 dark:bg-dark-secondary text-sm']}
                  />
                </div>
              )}
            </div>
          </div>
        </span>
      </div>
      <div className="rounded-xl shadow-lg bg-gray-100 dark:bg-secondary-default w-full md:w-4/6 xl:w-3/6 p-8 inline-block mb-8">
        <span className="decoration-clone  ...">
          <div className="mb-4">
            <Title size={'h2'} color={'text-secondary-default'} bold>
              {t('account.data_advise_title')}
            </Title>
          </div>
          <Typography
            size="sm"
            color="text-gray-secondary"
            font={'comfortaa'}
            classOverrides={['mx-auto dark:text-gray-primary']}
          >
            <div className="flex flex-col text-gray-secondary">
              <Typography
                color="text-gray-secondary"
                size="sm"
                classOverrides={['mb-7 dark:text-white']}
              >
                <Trans
                  i18nKey="account.data_advise_description_ask"
                  components={[
                    '',
                    <OutsideLink
                      name="Send mail data@r2devops.io"
                      to="mailto:data@r2devops.io"
                      title={t('action:mail_data')}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      {' '}
                      data@r2devops.io{' '}
                    </OutsideLink>,
                    '',
                  ]}
                />
              </Typography>
              <div className="flex justify-center">
                <SecondaryButton
                  content={t('action:why_leaving')}
                  classOverrides={[
                    'flex text-center text-sm mr-2 justify-center items-center font-bold w-54',
                  ]}
                  data-tally-open="mJ9AEY"
                  data-tally-layout="modal"
                  data-tally-width="600"
                  data-tally-hide-title="1"
                  data-tally-emoji-animation="wave"
                  color="text-gray-primary"
                  font="comfortaa"
                  bgColor="bg-primary-default"
                />
              </div>
            </div>
          </Typography>
        </span>
      </div>
      {tokenSource && (
        <AddTokenModal
          title={t('account.modal.title')}
          description={t('account.modal.input_label')}
          setShowModal={setShowModal}
          showModal={showModal}
          button={t('action:save')}
          source={tokenSource}
        />
      )}
    </motion.div>
  );
};
