import { TableHeader } from '@components/Table/TableHeader.compontent';
import { TableRow } from '@components/Table/TableRow.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VersionAnalysis } from '@jobs/models/JobCreationResponse.model';
import { cn } from '@utils/classes.util';
import {
  JOB_CHANGELOGS,
  JOB_DEFINITION,
  JOB_DOCUMENTATION,
} from 'common/constants/general';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { VersionRow } from './VersionRow.component';

type Props = {
  jobName: string;
  versionsAnalysis: VersionAnalysis[];
};

export const VersionsTable: React.FC<Props> = ({
  jobName,
  versionsAnalysis,
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const headers = t('link_job.versions_table_headers', {
    returnObjects: true,
  }) as Array<Record<string, string>>;

  const headerTooltipLinks = t('link_job.versions_table_links', {
    returnObjects: true,
  }) as Array<Record<string, string>>;

  const HeaderTooltipLink: React.FC<{ index: number }> = ({ index }) => {
    let linkText;
    let linkHref = '';

    if (index === 0) {
      linkText = headerTooltipLinks[index];
      linkHref = JOB_DEFINITION;
    } else if (index === 1) {
      linkText = headerTooltipLinks[index];
      linkHref = JOB_DOCUMENTATION;
    } else if (index === 2) {
      linkText = headerTooltipLinks[index];
      linkHref = JOB_CHANGELOGS;
    }

    return (
      <a
        className="text-blue-400 cursor-pointer hover:underline z-40"
        href={linkHref}
        rel="noreferrer"
      >
        {linkText}
      </a>
    );
  };

  return (
    <table className="font-comfortaa w-full xl:w-11/12 text-secondary-dark">
      <thead>
        <TableRow
          classOverrides={[
            'text-sm divide-x-2 divide-gray-input divide-opacity-70',
          ]}
        >
          <th className="w-3/12 py-5"></th>
          {headers.map((header, index) => (
            <TableHeader
              key={index}
              classOverrides={[
                'w-2/12 md:pb-2 lg:pb-0 font-normal text-xs lg:text-sm dark:text-gray-primary',
              ]}
            >
              {Object.keys(header)}
              <span
                className={cn('z-30 w-max inline-block')}
                data-tip={Object.values(header)[0]}
                data-event={undefined}
                data-for={index.toString()}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size={'lg'}
                  className="ml-2"
                />
              </span>
              <ReactTooltip
                getContent={() => (
                  <div>
                    <Trans
                      i18nKey={Object.values(header)[0]}
                      tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
                    />
                    <br />
                    <HeaderTooltipLink index={index} />
                  </div>
                )}
                id={index.toString()}
                data-tip={Object.values(header)[0]}
                effect={'solid'}
                delayHide={800}
                backgroundColor={
                  theme === 'light'
                    ? getColorSet(ColorSetEnum.gray)?.backgroundColor
                    : getColorSet(ColorSetEnum.dark)?.backgroundColor
                }
                textColor={
                  theme === 'light'
                    ? getColorSet(ColorSetEnum.gray)?.color
                    : getColorSet(ColorSetEnum.dark)?.color
                }
                multiline={false}
              />
            </TableHeader>
          ))}
        </TableRow>
      </thead>

      <tbody>
        {versionsAnalysis.map((analysis, versionIndex) => (
          <VersionRow
            key={versionIndex}
            headers={headers}
            jobName={jobName}
            versionAnalysis={analysis}
            versionIndex={versionIndex}
          />
        ))}
      </tbody>
    </table>
  );
};
