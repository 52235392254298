import { LicenseProject } from './useLicense.hook';
import { useContext } from 'react';
import { API_ROUTES } from 'common/constants/routes';
import { UserContext } from 'user/contexts/user.context';
import { toastError } from '@components/Toasters/Toaster.component';
import { useQuery } from 'react-query';
import axios from 'axios';

export const useLicenseProject = (projectId: string) => {
  const { identity } = useContext(UserContext);
  const getProjectQuery = async () =>
    await axios
      .get(API_ROUTES.GET_LICENSE_SINGLE_PROJECT(projectId), {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
  const { isLoading, data, isFetching, refetch } = useQuery<
    LicenseProject,
    Error
  >('gitLabProjects', getProjectQuery, {
    enabled: !!projectId,
    onError: (error) => {
      if (
        !error.message.includes('200') &&
        !error.message.includes('401') &&
        !error.message.includes('404')
      ) {
        toastError('An unknown error has occurred');
      } else if (error.message.includes('401')) {
        toastError(
          'There is something wrong with your GitLab Token, please fill it again.',
        );
      } else if (error.message.includes('404')) {
        toastError(`No projects found on your GitLab account`);
      } else {
        if (identity?.traits.gitlab_token) {
          toastError(`Something went wrong: ${error.message}`);
        }
      }
    },
  });
  return {
    isLoading,
    data,
    isFetching,
    refetch,
  };
};
