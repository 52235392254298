import { ClassOverrides } from '@typings/ClassOverrides.type';
import { motion } from 'framer-motion';
import { cn } from '@utils/classes.util';
import { useContext, useEffect, useState } from 'react';
import { LaserSwordIcon } from '@components/Icons/LaserSwordIcon/LaserSwordIcon.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { XL_SCREEN_SIZE } from 'common/constants/style.constant';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { UserContext } from 'user/contexts/user.context';
import mixpanel from 'mixpanel-browser';
import Plausible from 'plausible-tracker';
import { shake } from '@utils/motion.util';

export enum Direction {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'Left',
}

type Props = ClassOverrides & {
  direction?: Direction;
  width?: string;
};

export const ThemeToggleLaserSword: React.FC<Props> = ({
  direction = Direction.LEFT,
  width = '60',
  classOverrides = [],
}) => {
  const { theme, modifyTheme } = useContext(ThemeContext);
  const { trackEvent } = Plausible();

  const [maxSize, setMaxSize] = useState(200);
  const { identity } = useContext(UserContext);
  const saber = theme === 'dark';

  const clickOnSaber = () => {
    modifyTheme(saber ? 'light' : 'dark');
    mixpanel.track('[NavBar] Click on theme toggler');
    trackEvent('Click on theme toggler');
  };

  const getRotation = () => {
    switch (direction) {
      case Direction.TOP:
        return 90;
      case Direction.LEFT:
        return 0;
      case Direction.BOTTOM:
        return 270;
      case Direction.RIGHT:
        return 180;
    }
  };

  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width < XL_SCREEN_SIZE) {
      setMaxSize((windowSize.width + 100) / 5);
    } else if (
      identity &&
      windowSize.width < 1400 &&
      windowSize.width >= XL_SCREEN_SIZE
    ) {
      setMaxSize(190 - (1400 - windowSize.width));
    } else {
      setMaxSize(200);
    }
  }, [windowSize, identity]);

  return (
    <motion.div animate={shake()} className={cn(...classOverrides)} style={{}}>
      <motion.div initial={{ rotate: getRotation() }}>
        <span onClick={() => clickOnSaber()}>
          <LaserSwordIcon width={width} classOverrides={['cursor-pointer']} />
        </span>
        <motion.div
          style={{ top: '-1.3rem', height: parseInt(width) / 140 + 'rem' }}
          className={
            'relative bg-white rounded-lg blur-xl h-2 shadow-laser-sword'
          }
          initial={{ opacity: 0, width: 0 }}
          animate={{
            width: saber ? maxSize : 0,
            right: saber ? maxSize + 4 : 4,
            opacity: saber ? [0, 0.9, 1] : [1, 0.9, 0],
          }}
          transition={{ duration: 0.2, times: [0, saber ? 0.01 : 0.99, 1] }}
        ></motion.div>
      </motion.div>
    </motion.div>
  );
};
