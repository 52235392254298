import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Modal } from '@components/Modal/Modal.component';
import { Typography } from '@components/Typography/Typography.component';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnJobDelete } from 'user/hooks/useOnJobDelete';

type Props = {
  jobName: string;
  jobOwner: string;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleteModal: boolean;
};

export const DeleteJobModal: React.FC<Props> = ({
  jobName,
  jobOwner,
  setShowDeleteModal,
  showDeleteModal,
}) => {
  const { t } = useTranslation();
  const [deleteJob, processingDeletion] = useOnJobDelete();
  const [inputValue, setInputValue] = useState('');

  const enableDeleteButton = inputValue === jobName;
  return (
    <Modal
      classOverrides={['px-7 p-4']}
      setShowModal={setShowDeleteModal}
      showModal={showDeleteModal}
      disablePrimaryButton={processingDeletion}
      disableSecondaryButton={processingDeletion || !enableDeleteButton}
      primaryButtonAction={() => setShowDeleteModal(false)}
      primaryButtonClassOverrides={['h-10 flex items-center']}
      primaryButtonContent={t('action:cancel')}
      secondaryButtonAction={() =>
        deleteJob(jobOwner, jobName).then(() => setShowDeleteModal(false))
      }
      secondaryButtonBgColor={enableDeleteButton ? 'bg-red-alert' : undefined}
      secondaryButtonClassOverrides={['h-10 flex items-center']}
      secondaryButtonContent={t('action:delete')}
      showCloseIcon={false}
    >
      <h2 className="text-center text-3xl text-secondary-default dark:text-gray-primary font-bold mb-4">
        {t('action:delete_my_job')}
      </h2>
      <p className="font-comfortaa text-secondary-dark dark:text-gray-primary text-center">
        {/* <div> elements can't be used as children of <p> elements so we use
        span with inline-block class instead */}
        <span className="inline-block">
          <strong>{t('my_jobs.modify_your_job.delete_details.0')}</strong>
        </span>
        <span className="inline-block mt-4">
          {t('my_jobs.modify_your_job.delete_details.1')}
        </span>
        <span className="inline-block">
          {t('my_jobs.modify_your_job.delete_details.2')}
        </span>
      </p>
      <div className="w-2/3 mt-6 flex flex-col items-center mx-auto">
        <Typography
          size="lg"
          classOverrides={['mb-3 dark:text-red-500']}
          color="text-red-alert"
        >
          {jobName}
        </Typography>
        <StyledInput
          classOverrides={[
            'button-inner-outer text-secondary-dark',
            enableDeleteButton ? 'border-green-pearl border-2' : '',
          ]}
          placeholderColor="placeholder-gray-secondary"
          placeholder={t('my_jobs.modify_your_job.delete_modal_placeholder')}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      {processingDeletion && (
        <SpinnerIcon classOverrides={['my-auto']} color="text-secondary-dark" />
      )}
    </Modal>
  );
};
