import { Title } from '@components/Titles/Title.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { fadeInLeftVariant, fadeInRightVariant } from '@utils/motion.util';
import { motion } from 'framer-motion';
import React from 'react';

type Props = {
  title: string;
  gif: string;
  placement: 'right' | 'left';
};

export const GifSection: React.FC<Props & ClassOverrides> = ({
  title,
  gif,
  classOverrides = [],
  placement,
  children,
}) => {
  return (
    <div className={cn('flex items-center justify-center', ...classOverrides)}>
      <motion.img
        variants={
          placement === 'left' ? fadeInLeftVariant() : fadeInRightVariant()
        }
        initial={'hidden'}
        animate={'show'}
        src={gif}
        className={cn(
          'w-145',
          placement === 'left' ? 'xl:-ml-10 xl:mr-16' : 'xl:-mr-10 xl:ml-16',
        )}
      />
      <div className="flex flex-col w-full xl:w-145">
        <Title
          size={'h2'}
          bold
          classOverrides={[
            'text-center  py-4 dark:text-gray-primary',
            placement === 'left' ? 'xl:text-left' : 'xl:text-right',
          ]}
          color="text-gray-secondary"
        >
          {title}
        </Title>
        {children}
      </div>
    </div>
  );
};
