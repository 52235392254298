import { Tier } from '@jobs/types/IJobApi.type';
import { cn } from '@utils/classes.util';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {
  labels: string[];
  isDeprecated?: boolean;
  tier: Tier;
  labelClass: string;
  usedFromCard?: boolean;
};

const CardLabelSlider: React.FC<Props> = ({
  isDeprecated = false,
  labels,
  tier,
  labelClass,
  usedFromCard = false,
}) => {
  if (labels[0] !== tier) labels.unshift(tier);
  return (
    <Swiper
      slidesPerView={'auto'}
      className="w-full"
      breakpoints={{
        520: {
          slidesPerView: isDeprecated ? 3 : 4,
        },
      }}
    >
      {labels.map((label, index) => (
        <SwiperSlide
          key={index}
          className={cn(
            'min-w-max max-w-max',
            index !== labels.length - 1 && labels.length !== 1 && 'mr-2',
            labels.length === 1 && usedFromCard && 'mx-2',
          )}
        >
          <div
            className={cn(
              label === Tier.Community
                ? 'bg-secondary-default'
                : label === Tier.Official
                ? 'bg-primary-default'
                : 'bg-green-pearl',
              'capitalize text-white py-0.5 px-3 font-extrabold comfortaa rounded-md shadow',
              labelClass,
            )}
            data-testid={`label-${label}`}
            key={`${label}-${index}`}
          >
            {label}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export { CardLabelSlider };
