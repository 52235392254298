import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { CopyInputText } from '@components/Form/CopyInputText/CopyInputText.component';
import { GitIconRoundedBlue } from '@components/Icons/GitIcon/GitIconRoundedBlue.component';
import { GitIconRoundedWhite } from '@components/Icons/GitIcon/GitIconRoundedWhite';
import { GitLabRegularIcon } from '@components/Icons/GitLabRegularIcon/GitLabRegularIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { SocialIcon } from '@components/Social/SocialIcon.component';
import { SectionTitle } from '@components/Titles/SectionTitle.component';
import { Typography } from '@components/Typography/Typography.component';
import {
  faFacebookF,
  faLinkedinIn,
  faRedditAlien,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { GitLabCounter } from '@jobs/components/GitLabCounter/GitLabCounter.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { getJobUsageCounter } from '@utils/jobs.util';
import { ThemeContext } from 'common/contexts/theme.context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../../models/Job.model';
import { JobVersion } from '../../../models/JobVersion.model';

type Props = ClassOverrides & {
  job: Job;
  version: JobVersion;
};

const DetailsTab: React.FC<Props> = ({ version, job, classOverrides = [] }) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeContext);
  return (
    <div
      data-testid={'doc-tab'}
      className={cn(
        'w-full text-center sticky top-20 mt-0.5 transition-all duration-300 ease-in delay-1000',
        ...classOverrides,
      )}
    >
      <div
        className={
          'sm:px-24 lg:px-2 bg-transparent lg:bg-gray-primary dark:bg-secondary-default-high-opacity rounded-xl px-8 py-4 lg:shadow-md'
        }
      >
        <SectionTitle content={t('job.doc_tab.titles.0')} />
        <div className="px-2 mt-2">
          <CopyInputText
            classOverrides={[
              'filter shadow-button-inner dark:bg-dark-primary dark:text-gray-primary',
            ]}
            disabled
            defaultValue={version.jobUrl}
          />
        </div>
        <div className="mt-8 lg:mt-4">
          <SectionTitle content={t('job.doc_tab.titles.1')} />
          <div className="text-left px-4 py-2 font-comfortaa shadow-md bg-gray-primary rounded-xl lg:shadow-none lg:bg-transparent lg:dark:bg-transparent mt-4 lg:mt-0 dark:bg-dark-primary">
            <ul className=" divide-y-8 divide-transparent list-disc list-inside text-gray-secondary dark:text-gray-primary ">
              <li className="w-full">
                <ListKeyValue keyStr={t('job.job_name')} value={job.name} />
              </li>
              <li className="w-full ">
                <ListKeyValue
                  keyStr={t('job.docker_image')}
                  value={`${version.containerImage}:${version.containerImageTag}`}
                />
              </li>
              <li className="w-full">
                <ListKeyValue
                  keyStr={t('job.default_stage')}
                  value={version.defaultStage}
                />
              </li>
              {job.license && (
                <li className="w-full">
                  <ListKeyValue keyStr={t('job.license')} value={job.license} />
                </li>
              )}
              {getJobUsageCounter(job) && (
                <li className="w-full">
                  <ListKeyValue
                    keyStr={t('job.usage')}
                    value={getJobUsageCounter(job)}
                  />
                </li>
              )}
              <li className="w-full">
                {new Date(job.updatedAt) > new Date(job.createdAt) ? (
                  <ListKeyValue
                    keyStr={t('job.doc_tab.about.0')}
                    value={new Date(job.updatedAt).toISOString().split('T')[0]}
                  />
                ) : (
                  <ListKeyValue
                    keyStr={t('job.doc_tab.about.0')}
                    value={new Date(job.createdAt).toISOString().split('T')[0]}
                  />
                )}
              </li>
              <li className="w-full">
                <ListKeyValue
                  keyStr={t('job.maintainer')}
                  value={job.ownerName}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 lg:mt-2">
          <SectionTitle content={t('job.doc_tab.titles.2')} />
          <div className="relative text-left px-4 py-2 font-comfortaa shadow-md lg:dark:bg-transparent dark:bg-dark-primary bg-gray-primary rounded-xl lg:shadow-none lg:bg-transparent mt-4 lg:mt-0">
            <GitLabCounter
              repoUrl={job.repoUrl}
              statsClassOverrides={[
                'text-gray-secondary dark:text-gray-primary',
              ]}
              classOverrides={['lg:justify-start ']}
              repoClassOverrides={[
                'text-secondary-default dark:text-gray-primary',
              ]}
            >
              {theme === 'light' ? (
                <GitIconRoundedBlue classOverrides={['w-10 mr-4']} />
              ) : (
                <GitIconRoundedWhite
                  id="pattern0"
                  classOverrides={['w-10 mr-4']}
                />
              )}
            </GitLabCounter>
          </div>
        </div>
      </div>
      <div
        className={
          'bg-transparent bg-gray-primary dark:bg-secondary-default-high-opacity rounded-xl px-8 py-4 shadow-md mt-4 flex flex-col mx-8 sm:mx-24 lg:mx-0'
        }
      >
        <SectionTitle
          content={t('job.doc_tab.titles.3')}
          classOverrides={['mb-3']}
        />
        <Typography
          classOverrides={['text-gray-secondary dark:text-gray-primary px-2']}
          size="sm"
        >
          {t('job.doc_tab.create_issue')}
        </Typography>
        <OutsideLink
          name={`create issue`}
          to={`${job.repoUrl}/-/issues/new?issue[title]=[${job.name}] -`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PrimaryButton
            bgColor="bg-primary-blue"
            classOverrides={[
              'mx-auto text-lg mt-4 mb-2 pl-8 h-10 flex items-center justify-center',
            ]}
            content={t('job_details.new_issue_modal.button_content')}
            margin="m-0"
          >
            <GitLabRegularIcon classOverrides={['ml-2']} />
          </PrimaryButton>
        </OutsideLink>
      </div>
      <div data-testid="section-share-job" className="mt-4 lg:mt-4">
        <SectionTitle content={'Share this job'} />
        <div className="flex justify-center divide-x-8 divide-transparent lg:justify-around mt-4">
          <SocialIcon
            classOverrides={['w-12 h-12 mr-2 lg:mr-0']}
            icon={faFacebookF}
            link={
              'http://www.facebook.com/sharer.php?u=' +
              window.location.href +
              '&quote=Discover ' +
              job.name +
              ' in R2Devops!'
            }
          />
          <SocialIcon
            classOverrides={['w-12 h-12 mr-2 lg:mr-0']}
            icon={faTwitter}
            link={
              'https://twitter.com/intent/tweet?url=' +
              window.location.href +
              '&text=Discover%20' +
              job.name +
              '%20in%20R2Devops!%20'
            }
          />
          <SocialIcon
            classOverrides={['w-12 h-12 mr-2 lg:mr-0']}
            icon={faLinkedinIn}
            link={
              'https://www.linkedin.com/sharing/share-offsite/?url=' +
              window.location.href
            }
          />
          <SocialIcon
            classOverrides={['w-12 h-12']}
            icon={faRedditAlien}
            link={
              'https://reddit.com/submit?url=' +
              window.location.href +
              '&title=Discover ' +
              job.name +
              ' in R2Devops! 👉🏻'
            }
          />
        </div>
      </div>
    </div>
  );
};

type ListKeyValueProps = {
  keyStr: string;
  value: string;
};

/**
 * @param {string} keyStr -- cannot be named key as is React reserved property
 * @param {string} value
 */
const ListKeyValue: React.FC<ListKeyValueProps> = ({ keyStr, value }) => (
  <span className="w-full text-sm">
    <b>{keyStr}:</b> {value}
  </span>
);

export { DetailsTab };
