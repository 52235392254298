import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const GeneratorIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '29',
  width = '29',
}) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <g clipPath="url(#clip0_6364_2438)">
        <path
          d="M27.0455 12.1817C26.2188 12.0547 25.3838 12.1439 24.5529 12.1323C24.3368 12.1288 24.2106 12.0643 24.0802 11.8908C22.3231 9.55322 18.8899 9.51549 17.1108 11.8215C16.9256 12.0623 16.7465 12.1316 16.4645 12.1309C12.8955 12.1233 9.32568 12.1254 5.7566 12.1261C4.63895 12.1261 3.52129 12.1117 2.40364 12.1295C0.73093 12.157 -0.397015 13.735 0.138142 15.2773C0.483249 16.2729 1.38067 16.8739 2.55389 16.8759C4.88045 16.8808 7.20633 16.8773 9.53289 16.8773C11.8821 16.8773 14.2313 16.8808 16.5805 16.8725C16.798 16.8718 16.9249 16.9398 17.0553 17.1147C18.8151 19.4824 22.3334 19.4763 24.0919 17.1017C24.2147 16.9356 24.3334 16.8739 24.5365 16.8718C25.3337 16.865 26.133 16.9253 26.9289 16.8382C28.0678 16.714 28.9762 15.7185 29.0009 14.5775C29.0263 13.3926 28.1893 12.3573 27.0455 12.1817ZM16.2361 15.1484C16.3232 15.5099 16.1757 15.5038 15.9088 15.5031C13.891 15.4955 11.8732 15.499 9.85535 15.499C9.63854 15.499 9.42242 15.499 9.20562 15.499C6.98266 15.499 4.75901 15.4955 2.53605 15.5017C2.07911 15.5031 1.70588 15.3755 1.48838 14.9453C1.20091 14.3765 1.50416 13.7117 2.12028 13.5408C2.29935 13.4907 2.47979 13.4907 2.6623 13.4907C7.07459 13.4907 11.4862 13.4928 15.8985 13.4866C16.1826 13.4866 16.3266 13.4894 16.2354 13.8592C16.1325 14.277 16.1352 14.7305 16.2354 15.149L16.2361 15.1484ZM20.5633 17.5195C18.8988 17.5195 17.5568 16.1734 17.5561 14.5027C17.5561 12.8259 18.8858 11.4908 20.5592 11.4887C22.2422 11.4866 23.613 12.8513 23.6 14.5165C23.5869 16.1706 22.223 17.5195 20.5633 17.5195ZM26.6099 15.4948C26.098 15.5072 25.5855 15.4914 25.073 15.501C24.8857 15.5044 24.8528 15.455 24.8864 15.2684C24.9385 14.9789 24.9893 14.6846 24.9605 14.3868C24.9317 14.0938 24.896 13.8009 24.8617 13.4928C25.4593 13.4928 26.0631 13.4695 26.6641 13.4997C27.237 13.5285 27.6541 13.9985 27.6376 14.5267C27.6212 15.066 27.1917 15.4804 26.6092 15.4948H26.6099Z"
          fill="currentColor"
        />
        <path
          d="M28.9615 24.2145C28.7118 22.9925 27.7986 22.242 26.5423 22.2413C21.8433 22.2385 17.1449 22.2392 12.4458 22.2433C12.2399 22.2433 12.1034 22.2015 11.9669 22.019C10.1768 19.6176 6.70313 19.6163 4.91448 22.0162C4.77863 22.1987 4.64484 22.2461 4.43832 22.244C3.70831 22.2358 2.97831 22.2269 2.24898 22.2468C1.0195 22.279 -0.0110242 23.385 0.00406999 24.6323C0.0191642 25.8783 1.04419 26.9383 2.26751 26.9589C2.99752 26.9712 3.72753 26.9726 4.45685 26.9589C4.66268 26.9548 4.78137 27.0206 4.90213 27.1832C6.69216 29.6031 10.1796 29.6072 11.9744 27.1873C12.1103 27.0042 12.2461 26.9609 12.4526 26.9609C14.8361 26.9664 17.2196 26.9644 19.6032 26.9644V26.9602C21.9867 26.9602 24.3702 26.9712 26.7537 26.9561C28.1368 26.9472 29.2367 25.5599 28.9615 24.2145ZM4.13164 25.4206C4.15977 25.551 4.16045 25.6052 4.00745 25.6038C3.42701 25.597 2.8452 25.6162 2.26545 25.5922C1.77009 25.5709 1.36323 25.0975 1.36872 24.6014C1.37421 24.1095 1.78244 23.6368 2.27711 23.6162C2.90078 23.5894 3.52718 23.61 4.16937 23.61C4.02529 24.2275 4.00334 24.8251 4.13095 25.422L4.13164 25.4206ZM8.45063 27.6388C6.80056 27.6491 5.39612 26.2412 5.41327 24.5946C5.43042 22.9253 6.77998 21.5902 8.44651 21.5929C10.1144 21.5956 11.4592 22.939 11.4647 24.6069C11.4695 26.2536 10.1021 27.6292 8.45063 27.6395V27.6388ZM27.566 24.9658C27.4185 25.3534 27.059 25.5983 26.6082 25.599C25.1839 25.6025 23.7589 25.6011 22.3345 25.6011C19.2574 25.6011 16.1809 25.6011 13.1037 25.6011H12.7168C12.8499 24.9733 12.8773 24.375 12.7429 23.7788C12.7099 23.632 12.7518 23.5936 12.8863 23.6052C12.9315 23.6093 12.9775 23.6052 13.0228 23.6052C17.5359 23.6052 22.0484 23.6045 26.5616 23.6059C27.3389 23.6059 27.8295 24.2742 27.5667 24.9644L27.566 24.9658Z"
          fill="currentColor"
        />
        <path
          d="M28.8558 3.58488C28.5669 2.79861 27.7628 2.02606 26.5189 2.03086C21.8329 2.04802 17.1468 2.03704 12.4608 2.0391C12.2755 2.0391 12.1321 2.02606 11.9977 1.8463C10.1548 -0.614046 6.71538 -0.614732 4.88213 1.8415C4.77373 1.98695 4.66807 2.04321 4.49037 2.04115C3.73772 2.03429 2.98438 2.02057 2.2331 2.0487C1.04066 2.09261 0.0911042 3.05384 0.00534196 4.26686C-0.0756176 5.40372 0.773086 6.50765 1.90995 6.70525C2.75728 6.85276 3.61559 6.74436 4.4691 6.76151C4.67218 6.76563 4.78951 6.82257 4.91232 6.98724C6.69137 9.37966 10.1994 9.38035 11.9688 6.98792C12.0916 6.82189 12.209 6.7622 12.4121 6.76288C14.7722 6.77043 17.1324 6.76768 19.4926 6.76768C21.8754 6.76768 24.2589 6.77729 26.6417 6.76425C28.2849 6.75534 29.4177 5.11487 28.8558 3.58488ZM2.25849 5.3852C1.72745 5.3454 1.33569 4.83906 1.36793 4.34988C1.40361 3.81403 1.82556 3.41541 2.38679 3.40649C2.96586 3.39757 3.54561 3.40443 4.16859 3.40443C4.00804 4.08641 4.00873 4.72792 4.17202 5.39617C3.52297 5.39617 2.88764 5.43185 2.25849 5.3852ZM8.43063 7.40576C6.7641 7.40301 5.40974 6.0514 5.4118 4.3931C5.41385 2.73068 6.7984 1.35986 8.46562 1.36946C10.1129 1.37907 11.4858 2.77185 11.4632 4.41094C11.4405 6.09051 10.1054 7.4085 8.42994 7.40576H8.43063ZM26.674 5.40304C26.6061 5.4099 26.5374 5.40578 26.4688 5.40578C21.9893 5.40578 17.5098 5.40578 13.0302 5.40578H12.7105C12.8498 4.78143 12.8731 4.18247 12.7469 3.58419C12.7181 3.44628 12.7386 3.39071 12.8834 3.40306C12.9287 3.40718 12.9747 3.40306 13.0199 3.40306C17.5221 3.40306 22.025 3.40512 26.5272 3.39963C26.9964 3.39963 27.3518 3.5691 27.5474 3.99585C27.8376 4.62843 27.393 5.33237 26.6747 5.40235L26.674 5.40304Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6364_2438">
          <rect width="29.0027" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
