import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    action?: () => void;
    color?: TextColor;
  };

export const SmallArrowIcon: React.FC<Props> = ({
  action,
  classOverrides = [],
  color,
  height = '50',
  width = '50',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
      onClick={action}
    >
      <path
        d="M6.5 6L0.870835 -1.06691e-07L12.1292 8.77544e-07L6.5 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
