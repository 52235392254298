import React, { useContext, useEffect, useState } from 'react';
import { JobTitleMobile } from '../Titles/JobTitleMobile/JobTitleMobile.component';
import { JobTitleDesktop } from '../Titles/JobTitleDesktop/JobTitleDesktop.component';
import {
  RateType,
  RatingBox,
} from '@components/Buttons/RatingBox/RatingBox.component';
import { DeprecationLabel } from '@components/Buttons/Label/DeprecationLabel/DeprecationLabel.component';
import { LabelSlider } from '../LabelSlider/LabelSlider.component';
import { VersionSelector } from '../VersionSelector/VersionSelector.component';
import { Job } from '../../models/Job.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { JobContentLayout } from '../Layout/JobContentLayout.component';
import { JobContentRight } from '../Layout/JobContentRight.component';
import { HeaderBottom } from '@components/Header/HeaderBottom.component';
import { useJobRates } from '@jobs/hooks/useJobRates.hook';
import { UserContext } from 'user/contexts/user.context';
import { useOnJobRating } from '@jobs/hooks/useOnJobRating';
import { JobVersion } from '@jobs/models/JobVersion.model';
import { cn } from '@utils/classes.util';
import { PenIcon } from '@components/Icons/PenIcon/PenIcon.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { useTranslation } from 'react-i18next';
import { NewIssueModal } from '../NewIssueModal/NewIssueModal.component';
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';

type Props = {
  job:
    | Pick<
        Job,
        | 'id'
        | 'name'
        | 'description'
        | 'rateUp'
        | 'rateDown'
        | 'deprecated'
        | 'labels'
        | 'versionsList'
        | 'tier'
        | 'icon'
        | 'ownerName'
        | 'repoUrl'
        | 'policy'
      >
    | false;
  onSelectVersion: (item: string) => void;
  selectedVersion?: JobVersion;
  action?: () => void;
};

const Header: React.FC<Props> = ({
  onSelectVersion,
  job,
  selectedVersion,
  action,
}) => {
  const user = useContext(UserContext);

  const jobId = job ? job.id.toString() : '';

  // Destructuring not used because username might be undefined when not logged in
  const username = user.identity?.traits.username;

  const { t } = useTranslation();

  const jobRates = useJobRates(jobId, true, username);
  const [currentUserRating, setCurrentUserRating] = useState(RateType.NONE);
  const [showNewIssueModal, setShowNewIssueModal] = useState(false);
  const [onFirstRender, setOnFirstRender] = useState(true);

  // If user is logged in and they have rated the job, assign it to variable to show it in rating box
  useEffect(() => {
    if (!onFirstRender) return;
    if (username && jobRates && jobRates.length > 0) {
      if (jobRates[0].value) {
        setCurrentUserRating(RateType.POSITIVE);
      } else {
        setCurrentUserRating(RateType.NEGATIVE);
      }
      setOnFirstRender(false);
    }
  }, [currentUserRating, jobRates, onFirstRender, username]);

  const [rating, setRating] = useState({
    rateDown: job ? job.rateDown : null,
    rateUp: job ? job.rateUp : null,
  });

  const updateRatingState = (rateType: RateType, value: number): void => {
    if (value === -1) {
      setCurrentUserRating(RateType.NONE);
    } else {
      setCurrentUserRating(rateType);
    }
    if (rateType === RateType.POSITIVE) {
      setRating((prevState) => {
        let newRateDown = prevState.rateDown;
        if (prevState.rateDown !== null && newRateDown) {
          newRateDown = newRateDown - 1;
          setCurrentUserRating(RateType.POSITIVE);
        } else if (
          prevState.rateUp ||
          currentUserRating === RateType.POSITIVE
        ) {
          setCurrentUserRating(RateType.NONE);
        }
        const newRateUp = Number(prevState.rateUp) + value;
        return {
          rateUp: newRateUp,
          rateDown: newRateDown,
        };
      });
    } else if (rateType === RateType.NEGATIVE) {
      setRating((prevState) => {
        let newRateUp = prevState.rateUp;
        if (prevState.rateUp && newRateUp) {
          newRateUp -= 1;
          setCurrentUserRating(RateType.NEGATIVE);
          setShowNewIssueModal(true);
        } else if (
          prevState.rateDown ||
          currentUserRating === RateType.NEGATIVE
        ) {
          setCurrentUserRating(RateType.NONE);
        } else {
          setShowNewIssueModal(true);
        }
        const newRateDown = Number(prevState.rateDown) + value;
        return {
          rateUp: newRateUp,
          rateDown: newRateDown,
        };
      });
    }
  };

  const [rateJob, loading] = useOnJobRating(
    username,
    currentUserRating,
    jobId,
    updateRatingState,
  );
  useEffect(() => {
    if (job) {
      setRating({ rateDown: job.rateDown, rateUp: job.rateUp });
    }
  }, [job]);

  if (!job)
    return (
      <header className="relative pt-12 h-80 text-center w-full bg-gradient-to-b from-secondary-default to-primary-default">
        <span className="text-white text-3xl">
          <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
        </span>
      </header>
    );
  const { name, description, deprecated, icon, labels, ownerName } = job;
  return (
    <header
      data-testid="header"
      className="relative w-full bg-gradient-to-b from-secondary-default to-primary-default dark:to-secondary-default-medium-opacity"
    >
      <JobContentLayout classOverrides={['lg:w-full']}>
        <div className="flex w-full lg:w-4/6 flex-col">
          <div className="lg:flex lg:justify-between mb-6 lg:mb-0 w-full">
            <JobTitleMobile
              title={name}
              policy={job.policy}
              owner={job.ownerName}
              icon={icon}
              modifyIcon={
                username && username.toLowerCase() === ownerName.toLowerCase()
              }
              action={action ? action : undefined}
            />

            <p className="lg:hidden text-white font-comfortaa text-center mb-5">
              {description}
            </p>

            <div className="w-full flex justify-center lg:justify-between ">
              <span className="hidden lg:flex text-lg lg:text-2xl items-center">
                <span className="mr-2">{icon}</span>

                <JobTitleDesktop
                  title={name}
                  owner={job.ownerName}
                  policy={job.policy}
                />
              </span>
              <RatingBox
                disabledButtons={loading}
                upRates={rating.rateUp ?? job.rateUp}
                downRates={rating.rateDown ?? job.rateDown}
                rate={currentUserRating}
                textSize={'2xl'}
                mobileTextSize={'xl'}
                action={rateJob}
              />
            </div>
          </div>
          <div className="hidden lg:block md:w-3/4 py-2 lg:w-full text-white font-comfortaa mb-3">
            <p>{description}</p>
          </div>
          <div className="w-full flex items-center justify-center mx-auto lg:justify-start mb-4 lg:mb-0">
            {deprecated && (
              <FeatureFlag name="deprecated-job">
                <DeprecationLabel classOverrides={['mr-4']} />
              </FeatureFlag>
            )}
            <LabelSlider
              isDeprecated={job.deprecated}
              labels={labels}
              tier={job.tier}
            />
          </div>
        </div>
        <JobContentRight
          classOverrides={['hidden lg:block lg:w-3/12 xl:w-4/12 2xl:w-5/12']}
        >
          <div className={cn('flex justify-end h-full pb-2')}>
            <div className={cn('flex flex-col items-center')}>
              {username && username.toLowerCase() === ownerName.toLowerCase() && (
                <Tooltip title={t('job_details.modify_tooltip')}>
                  <div
                    className={cn(
                      'rounded-full bg-white shadow-lg hover:shadow-button-inner w-12 h-12 flex items-center justify-center flex-col relative cursor-pointer mb-3',
                    )}
                    data-testid="dropdown-icon"
                    onClick={action ? action : undefined}
                  >
                    <PenIcon
                      color="text-secondary-default"
                      classOverrides={['w-5/12']}
                    />
                  </div>
                </Tooltip>
              )}

              <VersionSelector
                classOverrides={['flex justify-center']}
                versions={job.versionsList}
                deprecated={job.deprecated}
                bgColor={'bg-white'}
                textColor={'text-secondary-default'}
                action={onSelectVersion}
                selectedVersion={selectedVersion}
              />
            </div>
          </div>
        </JobContentRight>
      </JobContentLayout>
      <HeaderBottom />
      <NewIssueModal
        repoUrl={job.repoUrl}
        jobName={job.name}
        setShowNewIssueModal={setShowNewIssueModal}
        showNewIssueModal={showNewIssueModal}
      />
    </header>
  );
};

export { Header };
