import React from 'react';
import { HeaderBottom } from '@components/Header/HeaderBottom.component';

/**
 * This is the linear line that goes under navigation bar and is leaning
 */
export const NavBarCutter: React.FC = () => {
  return (
    <HeaderBottom
      classOverrides={[
        'bg-gradient-to-b from-secondary-default to-primary-default dark:to-secondary-default-medium-opacity',
      ]}
    />
  );
};
