import React, { useContext, useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  github,
  solarizedDark,
} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { JobVersion } from '@jobs/models/JobVersion.model';
import { useJobCode } from 'common/hooks/useJobCode.hook';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { ThemeContext } from 'common/contexts/theme.context';
import mixpanel from 'mixpanel-browser';
import Plausible from 'plausible-tracker';

type Props = ClassOverrides & {
  version: JobVersion;
};

const GitlabTab: React.FC<Props> = ({ classOverrides = [], version }) => {
  const { t } = useTranslation();
  const [jobCode, setJobCode] = useState('');
  const [codeRequest, loading, noCodeFile] = useJobCode(version.jobUrl);
  const { theme } = useContext(ThemeContext);
  const { trackEvent } = Plausible();
  useEffect(() => {
    if (!codeRequest || typeof codeRequest !== 'string') return;
    setJobCode(codeRequest);
  }, [codeRequest]);

  const copyJobCode = () => {
    navigator.clipboard.writeText(jobCode);
    mixpanel.track(`[Copy] Content of the .gitlab-ci.yml copied`);
    trackEvent('Copy a job code');
  };

  return (
    <div
      className={cn('relative w-full', ...classOverrides)}
      data-testid={'gitlab-tab'}
      id="gitlab-tab"
    >
      {!noCodeFile && !loading ? (
        <>
          <Tooltip
            afterShow={() => {
              copyJobCode();
              setTimeout(ReactTooltip.hide, 2000);
            }}
            title={t('action:copied')}
            click={true}
            classOverrides={['absolute h-6 w-6 top-3 right-4']}
            colorSet={ColorSetEnum.success}
          >
            <button
              className={
                'text-xl ' +
                'text-secondary-dark dark:text-gray-primary dark:hover:text-primary-default hover:text-secondary-default ' +
                'transition-all duration-300 cursor-pointer right-0 -mt-1 -mr-1'
              }
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </Tooltip>
          <SyntaxHighlighter
            language="yaml"
            showLineNumbers={true}
            style={theme === 'light' ? github : solarizedDark}
          >
            {jobCode}
          </SyntaxHighlighter>
        </>
      ) : (
        <SpinnerIcon classOverrides={['mt-6']} />
      )}
    </div>
  );
};

export { GitlabTab };
