import React, { createContext, useState } from 'react';

export const getInitialTheme = () => {
  if (
    typeof window !== 'undefined' &&
    window.localStorage &&
    typeof window.matchMedia === 'function'
  ) {
    const storedPrefs = window.localStorage.getItem('color-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
    if (userMedia.matches) {
      return 'dark';
    }
  }

  // If you want to use light theme as the default,
  // return "light" instead
  return 'light';
};

export const ThemeContext = createContext({
  theme: getInitialTheme(),
  modifyTheme: (newTheme: string) => {},
});

type Props = {
  initialTheme: string;
};

export const ThemeProvider: React.FC<Props> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState<string>(getInitialTheme);

  const modifyTheme = (newTheme: string) => {
    setTheme(newTheme);
  };

  const rawSetTheme = (theme: string) => {
    const root = window.document.documentElement;
    const isDark = theme === 'dark';
    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(theme);

    localStorage.setItem('color-theme', theme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, modifyTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
