import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { StyledCheckboxInput } from '@components/Form/StyledCheckboxInput.component/StyledCheckboxInput.component';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { Typography } from '@components/Typography/Typography.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { JobToLink } from '@jobs/models/JobToLink.model';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import {
  isGitLabUrl,
  isJobOrProjectNameCorrect,
  isJobPathCorrect,
} from '@utils/text.util';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { DEFAULT_ICON } from 'common/constants/hexaIcons';
import { ThemeContext } from 'common/contexts/theme.context';
import { useJobToLink } from 'common/hooks/useJobToLink.hook';
import { AnimatePresence } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { JobVersionsDetails } from '../JobVersionsDetails/JobVersionsDetails.component';
import { LinkJobDetailsForm } from '../LinkJobDetailsForm/LinkJobDetailsForm.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { HashLink } from 'react-router-hash-link';

const LinkJobForm: React.FC<ClassOverrides> = ({ classOverrides = [] }) => {
  const formRef = useRef<HTMLDivElement>(null);
  const {
    getValues,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });
  const folderPath = watch('folderPath');
  const repoUrl = watch('repoUrl');
  const name = watch('name');
  const policy = watch('policy');
  const { theme } = useContext(ThemeContext);

  const defaultJobName = () => {
    if (folderPath && folderPath !== '') {
      var newJobName = folderPath.substring(
        folderPath.lastIndexOf('/') + 1,
        folderPath.length,
      );
      setValue('name', newJobName);
    }
  };
  const [addJob, jobCreationResponse, loading, jobCreated] = useJobToLink();
  const submitHandler = (): void => {
    const newJob: JobToLink = {
      description: '',
      icon: DEFAULT_ICON,
      labels: [''],
      repoUrl: t('link_job.inputs_prefix.0') + repoUrl,
      folderPath: t('link_job.inputs_prefix.1') + folderPath,
      name: name,
      policy: policy ? 'private' : 'public',
    };
    addJob(newJob);
  };

  const { t } = useTranslation();

  const customIsValid = () => {
    if (
      (!errors.repoUrl?.message ||
        !errors.jobPath?.message ||
        !errors.jobName?.message) &&
      name &&
      repoUrl
    ) {
      return true;
    }
    return false;
  };

  /**
   * Replace with real information of user's license and amount of private jobs
   * once available
   */
  // const [corePricing] = PricingPlans();
  // const userPrivateJobs = -1;

  const getReachedMaximumPrivateJobs = (): boolean => {
    //   return userPrivateJobs >= corePricing.privateJobs;
    return false;
  };

  return (
    <div
      className={cn('flex flex-col items-center mb-14', ...classOverrides)}
      ref={formRef}
    >
      {!jobCreationResponse && (
        <div className="w-full mx-1 sm:mx-0 sm:w-4/6 lg:w-3/6 mb-6">
          <AnimatePresence exitBeforeEnter>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="mb-6">
                <StyledInput
                  register={register('repoUrl', {
                    required: true,
                    validate: (value) =>
                      !isGitLabUrl(value)
                        ? `${t('link_job.inputs_error.0')}`
                        : undefined,
                  })}
                  label={t('link_job.inputs_label.0')}
                  classOverrides={['dark:bg-gray-third dark:text-gray-primary']}
                  type="text"
                  key={'repo-input'}
                  error={errors.repoUrl?.message}
                  placeholder={t('link_job.inputs_placeholder.0')}
                  data-testid="repo-url-input"
                  prefix={t('link_job.inputs_prefix.0')}
                />
              </div>
              <div className="mb-6">
                <StyledInput
                  register={register('folderPath', {
                    validate: (value) =>
                      !isJobPathCorrect(value)
                        ? `${t('link_job.inputs_error.1')}`
                        : undefined,
                  })}
                  label={t('link_job.inputs_label.1')}
                  type="text"
                  key={'path-input'}
                  error={errors.folderPath?.message}
                  disabled={errors.repoUrl?.message || !repoUrl ? true : false}
                  placeholder={t('link_job.inputs_placeholder.1')}
                  classOverrides={[
                    'dark:text-gray-primary',
                    errors.repoUrl?.message || !repoUrl
                      ? 'bg-gray-input dark:bg-dark-third'
                      : 'dark:bg-gray-third',
                  ]}
                  data-testid="job-path-input"
                  tooltipTitle={t('link_job.inputs_tooltip.0')}
                  tooltipClassOverrides={['max-w-18']}
                  onBlur={defaultJobName}
                  prefix={t('link_job.inputs_prefix.1')}
                />
              </div>
              <StyledInput
                register={register('name', {
                  required: true,
                  validate: (value) =>
                    !isJobOrProjectNameCorrect(value)
                      ? `${t('link_job.inputs_error.2')}`
                      : undefined,
                })}
                label={t('link_job.inputs_label.2')}
                type="text"
                key={'job-input'}
                error={errors.name?.message}
                disabled={
                  errors.jobPath?.message || errors.repoUrl?.message || !repoUrl
                    ? true
                    : false
                }
                placeholder={t('link_job.inputs_placeholder.2')}
                classOverrides={[
                  'dark:text-gray-primary',
                  errors.repoUrl?.message ||
                  !repoUrl ||
                  errors.folderPath?.message
                    ? 'bg-gray-input dark:bg-dark-third'
                    : 'dark:bg-gray-third',
                ]}
                data-testid="job-name-input"
                tooltipTitle={t('link_job.inputs_tooltip.1')}
                tooltipClassOverrides={['max-w-18']}
                advise={t('link_job.job_name_advise')}
              />
              <FeatureFlag name="private-job">
                {!getReachedMaximumPrivateJobs() ? (
                  <div>
                    <div className="flex justify-center md:justify-start">
                      <Typography
                        color="text-gray-secondary"
                        bold
                        classOverrides={[
                          'text-sm md:text-lg dark:text-gray-primary',
                        ]}
                      >
                        {t('link_job.privacy.label')}
                      </Typography>
                      <Tooltip
                        getContent={() => (
                          <Trans
                            i18nKey={t('link_job.privacy_label_tooltip_title')}
                          >
                            The number of private jobs you can define depends of{' '}
                            <TrackedLink
                              name={'pricing page'}
                              to="/pricing"
                              className="underline dark:hover:text-primary-default hover:text-secondary-default"
                            >
                              your license level
                            </TrackedLink>
                          </Trans>
                        )}
                        title=""
                        icon={faQuestionCircle}
                        place="right"
                        size="lg"
                        classOverrides={['ml-2']}
                        colorSet={
                          theme === 'light'
                            ? ColorSetEnum.gray
                            : ColorSetEnum.dark
                        }
                      />
                    </div>
                    <StyledCheckboxInput
                      classOverrides={['mt-2 text-sm md:text-lg']}
                      label={t('link_job.privacy.checkbox_label')}
                      register={register('policy')}
                      type="checkbox"
                    />
                  </div>
                ) : (
                  <div className="w-2/3 mx-auto bg-orange-info rounded-lg flex flex-col justify-center items-center py-2 px-5">
                    <div className="mb-2 md:mb-0 flex">
                      <Typography
                        color="text-gray-secondary"
                        bold
                        classOverrides={['text-center text-sm md:text-lg']}
                      >
                        {t('link_job.privacy.label')}
                      </Typography>
                      <Tooltip
                        colorSet={
                          theme === 'light'
                            ? ColorSetEnum.gray
                            : ColorSetEnum.dark
                        }
                        getContent={() => (
                          <Trans
                            i18nKey={t('link_job.privacy.label_tooltip_title')}
                          >
                            The number of private jobs you can define depends of{' '}
                            <TrackedLink
                              name={'pricing page'}
                              to="/pricing"
                              className="underline dark:hover:text-primary-default hover:text-secondary-default"
                            >
                              your license level
                            </TrackedLink>
                          </Trans>
                        )}
                        icon={faQuestionCircle}
                        iconDarkModeColor="dark:text-secondary-dark"
                        place="right"
                        size="lg"
                        classOverrides={['md:ml-2']}
                      />
                    </div>
                    <Typography
                      font="comfortaa"
                      color="text-secondary-dark"
                      classOverrides={['text-center']}
                    >
                      <Trans i18nKey={t('link_job.privacy.reached_max_jobs')}>
                        You’ve reached the number of private jobs available in
                        your plan. To get more, you need to{' '}
                        <TrackedLink
                          name="pricing page"
                          to="/pricing"
                          className="underline"
                        >
                          upgrade your license
                        </TrackedLink>
                      </Trans>
                    </Typography>
                  </div>
                )}
              </FeatureFlag>
              <div className="text-center mt-8">
                <SubmitButton
                  bgColor={'bg-secondary-default'}
                  classOverrides={['mx-auto xl:text-md my-0 ']}
                  content={t('action:import_job')}
                  disabled={loading}
                  key={'submit'}
                  isSubmitting={isSubmitting}
                  isValid={customIsValid()}
                  errors={errors}
                  loading={loading}
                />
              </div>
              <HashLink
                smooth
                to={'/privacy#jobs'}
                className={
                  'underline block text-center mx-auto mt-2 w-full dark:text-gray-primary font-light hover:opacity-70'
                }
              >
                {t('link_job.privacy_jobs_link')}
              </HashLink>
            </form>
          </AnimatePresence>
        </div>
      )}

      {jobCreationResponse && (
        <JobVersionsDetails
          classOverrides={['mb-24, w-8/12']}
          jobAnalysis={jobCreationResponse.analysis}
          jobName={jobCreationResponse.job.name}
          versions={jobCreationResponse.job.versions}
        />
      )}

      {jobCreated && <LinkJobDetailsForm getValues={getValues} />}
    </div>
  );
};

export { LinkJobForm };
