import React from 'react';
import {
  ClassOverrides,
  HTMLStyling,
  Size,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = HTMLStyling &
  ClassOverrides & {
    action?: () => void;
    size?: Size;
    title?: string;
  };

/**
 * Simple text with base parameters
 * @param {string} children -- Content
 * @param {string} font -- Font used
 * @param {FontSize | string} size -- Size of text, default is text-base
 * @param {boolean} light -- Is your text in light
 * @param {boolean} bold -- Is your text in bold
 * @param {boolean} italic -- Is your text in light
 * @param {string} color -- Text color, default is primary (white)
 * @param {Array<string>} classOverrides -- Ability to override some classes
 * @constructor
 */
export const Typography: React.FC<Props> = ({
  action,
  children,
  font = 'comfortaa',
  size = 'base',
  light = false,
  bold = false,
  italic = false,
  color = 'text-white',
  classOverrides = [],
  title = '',
}) => {
  return (
    <span
      className={cn(
        `text-${size}`,
        bold && 'font-bold',
        light && 'font-light',
        italic && 'italic',
        color,
        `font-${font}`,
        ...classOverrides,
      )}
      onClick={action}
      title={title}
    >
      {children}
    </span>
  );
};
