import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling, Size } from '@typings/ClassOverrides.type';
import { PartialBy } from '@typings/PartialBy.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type LabelProps = PartialBy<ButtonProps, 'action'> &
  HTMLStyling & {
    fontSize?: Size;
    roundedSize?: Size | '2lg';
    shadow?: boolean;
    mobileFontSize?: Size;
  };

/**
 * Label defined in the job page to determine categories of the job
 * @param {LabelProps} props
 */
const Label: React.FC<LabelProps> = ({
  bgColor,
  children,
  content,
  action,
  classOverrides = [],
  mobileFontSize = 'xs',
  fontSize = 'sm',
  font = 'ubuntu',
  roundedSize = '2lg',
  shadow = true,
}) => {
  return (
    <div
      className={cn(
        'text-white filter font-extrabold w-auto inline-block py-1 sm:py-0.5 whitespace-nowrap',
        `font-${font}`,
        `text-${mobileFontSize} sm:text-${fontSize}`,
        `rounded-${roundedSize}`,
        shadow && 'shadow',
        bgColor ?? 'bg-green-pearl',
        ...classOverrides,
      )}
      onClick={action && ((_) => action())}
      data-testid={`label-${content}`}
    >
      {children}
      {content}
    </div>
  );
};

export { Label };
