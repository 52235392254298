import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides & {};
export const JobContentLayout: React.FC<Props> = ({
  children,
  classOverrides = [],
}) => {
  return (
    <div
      className={cn(
        'flex flex-row px-6 sm:px-16 lg:px-6 xl:px-16 2xl:px-48 pt-6 lg:w-full',
        ...classOverrides,
      )}
    >
      {children}
    </div>
  );
};
