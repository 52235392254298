import React from 'react';
import { Page } from '@components/Page/Page.component';
import HttpError from 'standard-http-error';
import { Typography } from '@components/Typography/Typography.component';
import { motion } from 'framer-motion';
import { simpleFadeIn } from '@utils/motion.util';
import { useTranslation } from 'react-i18next';
import { ErrorLayout } from '@components/Page/ErrorLayout/ErrorLayout.component';
import { useHistory } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { SearchJobsForm } from '@jobs/components/SearchJobsForm/SearchJobsForm';
import R2_jobCard from '@images/R2/R2_job_card.svg';
import { JobsSearchSubmit } from '@components/NavigationBar/NavigationBar.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

type Props = {
  error: Error;
};

const JobErrorPage: React.FC<FallbackProps & Props> = ({
  error,
  resetErrorBoundary,
}) => {
  const history = useHistory();

  history.listen(() => {
    resetErrorBoundary();
  });

  const { t } = useTranslation('error');
  let output;
  switch (error.name) {
    case 'HttpError':
      output = <HttpErrorContent error={error as HttpError} />;
      break;
    default:
      throw error;
  }

  const { register, handleSubmit } = useForm<JobsSearchSubmit>();
  const submitHandler = (values: JobsSearchSubmit): void => {
    if (!values.searchValue) return;
    history.push(`/_/jobs?search=${encodeURI(values.searchValue)}`);
  };

  return (
    <Page
      title={t('error_page.title.1')}
      description={t('error_page.description.1')}
      isLoading={false}
      navBarCutter
    >
      <ErrorLayout
        icon={R2_jobCard}
        jobError={true}
        title={t('error:error_page.h1.job')}
        classOverrides={['w-full md:w-3/4 lg:w-5/12']}
      >
        <motion.span
          variants={simpleFadeIn()}
          data-testid="http-error-sentence"
        >
          {output}
        </motion.span>
        <motion.div
          variants={simpleFadeIn()}
          className="flex flex-col sm:flex-row sm:flex-wrap mt-12"
          data-testid="reset-error-link"
        >
          <div className="flex flex-col items-center -mt-8 w-full">
            <SearchJobsForm
              classOverrides={['lg:mx-auto']}
              onSubmit={handleSubmit(submitHandler)}
              register={register}
              placeholder={t('action:search_jobs')}
            />
            <Typography
              color={'text-gray-secondary'}
              classOverrides={['mb-6 -mt-4 dark:text-gray-primary']}
            >
              {t('error:error_page.job_advise')}
            </Typography>
            <TrackedLink to="/u/addjob" name="add a job">
              <PrimaryButton
                bgColor={'bg-secondary-default'}
                content={t('action:add_job')}
                classOverrides={['mx-auto xl:text-md my-0 w-40']}
              />
            </TrackedLink>
          </div>
        </motion.div>
      </ErrorLayout>
    </Page>
  );
};

const HttpErrorContent: React.FC<{ error: HttpError }> = ({ error }) => {
  const { t } = useTranslation('error');
  switch (error.code) {
    case 404:
      return (
        <Typography
          color={'text-gray-secondary'}
          classOverrides={['dark:text-gray-primary']}
        >
          {t('error:error_page.errors.http.job_404')}
        </Typography>
      );
    default:
      throw error;
  }
};

export { JobErrorPage };
