import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import { Typography } from '@components/Typography/Typography.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { GitHubRepository } from 'common/hooks/useGitHubProjects.hook';
import { determineIfIsGitLabOrGitHub } from '@utils/object.util';
import { AutoPipelineResponseType } from 'user/hooks/useOnProjectPipeline.hook';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { LightningIcon } from '@components/Icons/LightningIcon/LightningIcon.component';

type Props = {
  fetchResult: AutoPipelineResponseType;
  project: GitLabProjectResponse | GitHubRepository;
  withWrite: boolean;
};

export const GitLabAutoPipeline: React.FC<Props> = ({
  fetchResult,
  project,
  withWrite,
}) => {
  const { t } = useTranslation();
  const projectWebUrl = determineIfIsGitLabOrGitHub(project)
    ? project?.web_url
    : project?.html_url;
  return (
    <>
      <Typography
        classOverrides={['dark:text-white ']}
        color="text-gray-secondary"
        size="sm"
      >
        {fetchResult.platform === 'github' ? (
          <Trans
            i18nKey="my_projects.modal.response.description_if_not_domain"
            values={{ platform: 'GitLab', editor: '.gitlab-ci.yml file' }}
          />
        ) : withWrite ? (
          <div className="flex flex-col w-full">
            <Trans
              i18nKey="my_projects.modal.response.description_with_write"
              values={{
                platform: 'GitLab',
              }}
            />
            <div className="flex items-center justify-center">
              <OutsideLink
                name={'GitLab documentation'}
                to={`${projectWebUrl}/-/pipelines?page=1&scope=branches&ref=${fetchResult.destBranch}`}
                rel="noreferrer"
                target="_blank"
              >
                <SecondaryButton
                  content={''}
                  classOverrides={[
                    'my-4 flex text-center justify-center items-center font-bold',
                  ]}
                  color="text-gray-primary"
                  font="comfortaa"
                  bgColor="bg-secondary-default"
                >
                  {t('action:see_pipeline')}
                  <LightningIcon
                    width="24"
                    height="24"
                    classOverrides={['ml-2']}
                  />
                </SecondaryButton>
              </OutsideLink>
            </div>
            <div>
              <Trans
                i18nKey="my_projects.modal.response.link_to_ci_file"
                values={{ PipelineConfFile: 'GitLab-CI config.' }}
              >
                Here's your new
                <OutsideLink
                  name={'GitLab documentation'}
                  to={`${projectWebUrl}/-/blob/${fetchResult.destBranch}/.gitlab-ci.yml`}
                  className={'underline text-primary-default hover:opacity-70'}
                  rel="noreferrer"
                  target="_blank"
                >
                  GitLab-CI config.
                </OutsideLink>
              </Trans>
            </div>
          </div>
        ) : (
          <Trans
            i18nKey="my_projects.modal.response.description_without_write"
            values={{ editor: 'CI-CD editor' }}
          >
            In order to add this pipeline in your project, copy the code below
            and paste it in your
            <OutsideLink
              name={'GitLab documentation'}
              to={`${projectWebUrl}/-/ci/editor`}
              className={'underline text-primary-default hover:opacity-70 ml-1'}
              rel="noreferrer"
              target="_blank"
            >
              CI/CD editor
            </OutsideLink>
            👇
          </Trans>
        )}
      </Typography>
    </>
  );
};
