import React from 'react';
import { TextColor } from '@typings/ClassOverrides.type';
type Props = {
  color?: TextColor;
};

export const InfoIcon: React.FC<Props> = ({ color = 'text-green-pearl' }) => {
  return (
    <div className="pr-2 pl-2">
      <svg
        width="32"
        height="32"
        viewBox="0 0 50 50"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        className={color}
      >
        <path
          d="M25 50C38.8187 50 50 38.8177 50 25C50 11.1813 38.8177 -9.7759e-07 25 -2.18557e-06C11.1814 -3.39363e-06 3.39355e-06 11.1823 2.18557e-06 25C9.77504e-07 38.8186 11.1823 50 25 50ZM25 3.90625C36.6595 3.90625 46.0938 13.3413 46.0938 25C46.0938 36.6595 36.6587 46.0937 25 46.0937C13.3405 46.0937 3.90625 36.6587 3.90625 25C3.90625 13.3405 13.3413 3.90625 25 3.90625Z"
          fill={'currentColor'}
        />
        <path
          d="M24.9978 37.6069C26.0599 37.6069 26.9209 36.7228 26.9209 35.6321L26.9209 22.9151C26.9209 21.8244 26.0599 20.9403 24.9978 20.9403C23.9357 20.9403 23.0747 21.8244 23.0747 22.9151L23.0747 35.6321C23.0747 36.7228 23.9357 37.6069 24.9978 37.6069Z"
          fill={'currentColor'}
        />
        <path
          d="M25.0025 13.2478C23.4684 13.2478 22.2247 14.3958 22.2247 15.8119C22.2247 17.228 23.4684 18.376 25.0025 18.376C26.5366 18.376 27.7803 17.228 27.7803 15.8119C27.7803 14.3958 26.5366 13.2478 25.0025 13.2478Z"
          fill={'currentColor'}
        />
      </svg>
    </div>
  );
};
