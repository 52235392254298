import React from 'react';
import { Typography } from '@components/Typography/Typography.component';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LoadingPage: React.FC = () => {
  return (
    <div
      className="w-screen h-screen flex justify-center flex-col"
      data-testid={'page-loading'}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        className="mx-auto"
      >
        <div className="text-center text-4xl mb-8 text-secondary-dark dark:text-gray-primary">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </div>
        <span className="mx-auto">
          <Typography
            color={'text-secondary-default'}
            size={'lg'}
            font={'comfortaa'}
            classOverrides={['dark:text-gray-primary']}
          >
            Content is on its way
          </Typography>
        </span>
      </motion.div>
    </div>
  );
};
