import React from 'react';
import {
  ClassOverrides,
  HTMLStyling,
  Size,
  TextColor,
  BgColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { getNewlineText } from '../../../common/utils/components.utils';

type Props = ClassOverrides &
  HTMLStyling & {
    title?: string;
    titleColor?: TextColor;
    titleSize?: 'h1' | 'h2' | 'h3';
    iconBgColor?: BgColor;
    text: string;
    textSize?: Size;
    textColor?: TextColor;
    titleClassOverrides?: Array<string | boolean>;
    textClassOverrides?: Array<string | boolean>;
  };

//Container used by the CompanPage, to display one card with following parameters
export const CardContainer: React.FC<Props> = ({
  children,
  title = '',
  titleColor = 'text-gray-secondary',
  titleSize = 'h1',
  iconBgColor = 'bg-white',
  text,
  textSize = 'base',
  textColor = 'text-gray-secondary',
  titleClassOverrides,
  textClassOverrides,
}) => {
  return (
    <div className="w-full md:w-3/4 lg:w-96 px-0.5 item-center mb-8">
      {title.length > 0 ? (
        <Title
          color={titleColor}
          size={titleSize}
          classOverrides={titleClassOverrides}
        >
          {title}
        </Title>
      ) : null}

      <div className="w-full flex justify-center my-5">
        <div
          className={cn(
            iconBgColor,
            'rounded-full h-24 w-24 flex flex-row items-center justify-center',
          )}
        >
          {children}
        </div>
      </div>
      <div className="mx-8">
        <Typography
          color={textColor}
          size={textSize}
          classOverrides={textClassOverrides}
        >
          {getNewlineText(text)}
        </Typography>
      </div>
    </div>
  );
};
