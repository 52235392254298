import { useContext, useReducer, useRef, useState } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { API_ROUTES, API_URL } from 'common/constants/routes';
import {
  toastError,
  toastSuccess,
  toastWarning,
} from '@components/Toasters/Toaster.component';
import { ProjectsContext } from 'user/contexts/projects.context';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { LicenseProject } from 'common/hooks/useLicense.hook';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { LightningIcon } from '@components/Icons/LightningIcon/LightningIcon.component';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Typography } from '@components/Typography/Typography.component';
import R2_pompomgirl from '@images/R2/R2_pompomgirl.svg';

type ReturnType = [
  (
    pipelineCustomization: PipelineCustomization,
    project: LicenseProject,
  ) => void,
  boolean,
];

export type PipelineCustomization = {
  projectID: string;
  customs: string;
  push: boolean;
  destBranch?: string;
};

export type Response = {
  errors?: {
    body: string;
  };
};

export const useProjectCustomization = (): ReturnType => {
  const [, dispatch] = useReducer(httpReducer<Response>(), null);
  const { t } = useTranslation();
  const hasPostCustomization = useRef(false);
  const httpService = useContext(HttpContext);
  const { push: setLocation } = useHistory();
  const [loading, setLoading] = useState(false);
  const { refetchUserLicense } = useContext(ProjectsContext);

  const addCustomization = (
    pipelineCustomization: PipelineCustomization,
    project: LicenseProject,
  ): void => {
    if (!project || !pipelineCustomization) return;
    hasPostCustomization.current = false;
    setLoading(true);
    const content = (
      <div className="flex flex-col items-center justify-center my-4 w-full">
        <motion.img
          src={R2_pompomgirl}
          width="120"
          height="120"
          className="-mt-2 mb-2"
        />
        <div className="text-center">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey="my_projects.modal.response.description_with_write"
              values={{
                platform: project.platform === 'gitlab' ? 'GitLab' : 'GitHub',
              }}
            />
          </Typography>
        </div>
        <div className="flex items-center justify-center">
          {project.platform === 'gitlab' ? (
            <OutsideLink
              name={'GitLab documentation'}
              to={`${project.projectUrl}/-/pipelines?page=1&scope=branches&ref=${pipelineCustomization.destBranch}`}
              rel="noreferrer"
              target="_blank"
            >
              <SecondaryButton
                content={''}
                classOverrides={[
                  'my-4 flex text-center dark:bg-primary-default justify-center items-center font-bold',
                ]}
                color="text-gray-primary"
                font="comfortaa"
                bgColor="bg-secondary-default"
              >
                {t('action:see_pipeline')}
                <LightningIcon
                  width="24"
                  height="24"
                  classOverrides={['ml-2']}
                />
              </SecondaryButton>
            </OutsideLink>
          ) : (
            <OutsideLink
              name={'GitLab documentation'}
              to={`${project.projectUrl}/actions/workflows/r2devops.yml`}
              rel="noreferrer"
              target="_blank"
            >
              <SecondaryButton
                content={''}
                classOverrides={[
                  'my-4 flex text-center justify-center items-center font-bold',
                ]}
                color="text-gray-primary"
                font="comfortaa"
                bgColor="bg-secondary-default"
              >
                {t('action:see_pipeline')}
                <LightningIcon
                  width="24"
                  height="24"
                  classOverrides={['ml-2']}
                />
              </SecondaryButton>
            </OutsideLink>
          )}
        </div>
        <div>
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {project.platform === 'gitlab' ? (
              <Trans
                i18nKey="my_projects.modal.response.link_to_ci_file"
                values={{ PipelineConfFile: 'GitLab-CI config.' }}
              >
                Here's your new
                <OutsideLink
                  name={'GitLab documentation'}
                  to={`${project.projectUrl}/-/blob/${pipelineCustomization.destBranch}/.gitlab-ci.yml`}
                  className={'underline text-primary-default hover:opacity-70'}
                  rel="noreferrer"
                  target="_blank"
                >
                  GitLab-CI config.
                </OutsideLink>
              </Trans>
            ) : (
              <Trans
                i18nKey="my_projects.modal.response.link_to_ci_file"
                values={{ PipelineConfFile: 'workflow configuration.' }}
              >
                Here's your new
                <OutsideLink
                  name={'GitLab documentation'}
                  to={`${project.projectUrl}/blob/${pipelineCustomization.destBranch}/.github/workflows/r2devops.yml`}
                  className={'underline text-primary-default hover:opacity-70'}
                  rel="noreferrer"
                  target="_blank"
                >
                  workflow configuration.
                </OutsideLink>
              </Trans>
            )}
          </Typography>
        </div>
      </div>
    );

    httpService
      .fetch<Response>(
        'PATCH',
        API_URL +
          API_ROUTES.CREATE_PROJECT_CUSTOMIZATION(
            pipelineCustomization.projectID,
          ),
        {
          body: {
            customs: pipelineCustomization.customs,
            push: pipelineCustomization.push,
            destBranch: pipelineCustomization.destBranch,
          },
          withCredentials: true,
        },
      )
      .then(async (res) => {
        if (
          res.status !== StatusCodes.CREATED &&
          res.status !== StatusCodes.OK &&
          res.response &&
          res.response.errors?.body
        ) {
          toastError(res.response.errors?.body);
        } else if (
          res.status !== StatusCodes.CREATED &&
          res.status !== StatusCodes.OK &&
          !res.response?.errors?.body
        ) {
          toastError(t('toast.unknow_error'));
        }
        if (res.status === StatusCodes.OK) {
          dispatch({
            type: ActionType.SUCCESS,
            result: res.response,
          });
          hasPostCustomization.current = true;
          if (pipelineCustomization.push) {
            toast.info(content, {
              closeButton: true,
              className: 'bg-primary-default dark:bg-secondary-default',
            });
          } else {
            toastSuccess(t('toast.project_modified'));
            toastWarning(t('toast.reload_pipeline'));
          }
          if (refetchUserLicense) {
            refetchUserLicense();
          }
          setLocation('/dashboard');
        }
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [addCustomization, loading];
};
