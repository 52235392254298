import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const AddDocumentIcon: React.FC<ClassOverrides> = ({
  classOverrides = [],
}) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides)}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M58.1165 34.6314V19.4916C58.1165 19.0582 57.9163 18.6578 57.6331 18.3411L40.6592 0.516974C40.3424 0.183722 39.892 5.72205e-06 39.4421 5.72205e-06H12.5305C7.56226 5.72205e-06 3.59375 4.05152 3.59375 9.02039V59.9085C3.59375 64.8773 7.56226 68.8623 12.5305 68.8623H33.7732C37.7911 75.5316 45.0946 80 53.4143 80C66.0529 80 76.3739 69.729 76.3739 57.074C76.391 46.0193 68.454 36.7822 58.1165 34.6314ZM41.1096 5.85267L52.4976 17.8406H45.1111C42.9102 17.8406 41.1096 16.0236 41.1096 13.8226V5.85267ZM12.5305 65.5274C9.41284 65.5274 6.92871 63.0261 6.92871 59.9085V9.02039C6.92871 5.88563 9.41284 3.33497 12.5305 3.33497H37.7747V13.8226C37.7747 17.8742 41.0596 21.1755 45.1111 21.1755H54.7815V34.1974C54.2816 34.1809 53.8812 34.1309 53.4479 34.1309C47.6288 34.1309 42.2766 36.3654 38.2416 39.867H17.066C16.1487 39.867 15.3986 40.6171 15.3986 41.5338C15.3986 42.4512 16.1487 43.2013 17.066 43.2013H35.1404C33.9563 44.8688 32.9724 46.5363 32.2058 48.3704H17.066C16.1487 48.3704 15.3986 49.1205 15.3986 50.0379C15.3986 50.9546 16.1487 51.7053 17.066 51.7053H31.1218C30.705 53.3728 30.4883 55.2234 30.4883 57.074C30.4883 60.0751 31.0718 63.0432 32.1222 65.5444H12.5305V65.5274ZM53.4314 76.6821C42.627 76.6821 33.8397 67.8949 33.8397 57.0905C33.8397 46.286 42.6099 37.4988 53.4314 37.4988C64.2523 37.4988 73.0225 46.286 73.0225 57.0905C73.0225 67.8949 64.2358 76.6821 53.4314 76.6821Z"
          fill="currentColor"
        />
        <path
          d="M17.0659 34.848H33.9562C34.8735 34.848 35.6237 34.0973 35.6237 33.1805C35.6237 32.2632 34.8735 31.5131 33.9562 31.5131H17.0659C16.1486 31.5131 15.3984 32.2632 15.3984 33.1805C15.3984 34.0973 16.1486 34.848 17.0659 34.848Z"
          fill="currentColor"
        />
        <path
          d="M60.2157 55.5231H55.1138V50.3375C55.1138 49.4208 54.363 48.67 53.4463 48.67C52.5289 48.67 51.7788 49.4208 51.7788 50.3375V55.5231H46.5933C45.6759 55.5231 44.9258 56.2738 44.9258 57.1906C44.9258 58.1079 45.6759 58.858 46.5933 58.858H51.7788V63.96C51.7788 64.8773 52.5289 65.6274 53.4463 65.6274C54.363 65.6274 55.1138 64.8773 55.1138 63.96V58.858H60.2157C61.1331 58.858 61.8832 58.1079 61.8832 57.1906C61.8832 56.2738 61.1331 55.5231 60.2157 55.5231Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="80" height="80" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
