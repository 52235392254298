import React from 'react';
import { HeaderBottom } from '@components/Header/HeaderBottom.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { useTranslation } from 'react-i18next';
import { withSearch, WithSearchSubProps } from '@jobs/hoc/withSearch.component';
import { SearchJobsContainer } from '@jobs/components/SearchJobsContainer/SearchJobsContainer';

const AllJobsPageComponent: React.FC<WithSearchSubProps> = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('hub.title')} description={t('hub.description')}>
      <header className="bg-gradient-to-b from-secondary-default to-primary-default w-full  dark:to-secondary-default-medium-opacity">
        <div className="flex justify-center mb-12 w-11/12 mx-auto">
          <Title bold>{t('hub.title')}</Title>
        </div>
        <HeaderBottom />
      </header>

      <div className="px-9 pb-9 min-h-105">
        <SearchJobsContainer />
      </div>
    </Page>
  );
};

export const AllJobsPage = withSearch(AllJobsPageComponent);
