import { CardIcon } from '@components/CardIcon/CardIcon.component';
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { StyledCheckboxInput } from '@components/Form/StyledCheckboxInput.component/StyledCheckboxInput.component';
import { AddDocumentIcon } from '@components/Icons/AddDocumentIcon/AddDocumentIcon.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { Typography } from '@components/Typography/Typography.component';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { cn } from '@utils/classes.util';
import { fadeInStaggerChildren, simpleFadeIn } from '@utils/motion.util';
import { useJobs } from 'common/hooks/useJobs.hook';
import { useOnVisible } from 'common/hooks/useOnVisible';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyJobsContext } from 'user/contexts/myJobs.context';
import { UserContext } from 'user/contexts/user.context';
import { JobRow } from '../JobRow/JobRow.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

type QueryPageParam = {
  page: number;
};

export const JobsList: React.FC<{}> = () => {
  const { t } = useTranslation();
  // Initialize with 1 empty job to have ghost row shown
  const [jobsRows, setJobsRows] = useState<JobResponse[]>([...Array(5)]);
  const [queryParams, setQueryParams] = useState<QueryPageParam>({
    page: 1,
  });
  const user = useContext(UserContext);
  const username = user.identity?.traits.username;
  const {
    filterJobPolicy,
    setJobsToDisplay,
    setFilterJobPolicy,
    jobsToDisplay,
  } = useContext(MyJobsContext);

  /**
   * TODO: Refactor useJobs to return fetch function instead of jobs itself so we can
   * conditionally call the API only when jobs are not present in the context.
   * This way we would avoid unnecssary API calls when modifying a job, returning to the
   * dashboard and so on
   */
  const { fetchResult, jobs, count, loading } = useJobs({
    perPage: 10,
    page: queryParams.page,
    owner: username,
    policy: filterJobPolicy,
  });
  const lastElementRef = useOnVisible(() => {
    // When last job is visible, fetch more increasing the page number
    if (!fetchResult || jobs.length === count) return;
    // Check whether we have fetched all jobs
    if (jobs.length <= count) {
      // Add empty rows to display skeletons.
      setJobsRows([...jobsRows, ...Array(5)]);

      setQueryParams((state) => ({
        page: state.page + 1,
      }));
    }
  });

  useEffect(() => {
    setQueryParams((state) => ({
      ...state,
      page: 1,
    }));
  }, []);

  useEffect(() => {
    if (!fetchResult?.jobs) return;
    setJobsRows(jobs);
    if (jobs.length >= 0 && setJobsToDisplay) {
      setJobsToDisplay(jobs);
    }
  }, [
    fetchResult,
    jobs,
    jobs.length,
    setJobsToDisplay,
    jobsToDisplay,
    filterJobPolicy,
  ]);

  let noJobsCard = t('my_jobs.user_has_no_jobs.title');
  switch (filterJobPolicy) {
    case 'public':
      noJobsCard = t('my_jobs.user_has_no_jobs.title_public');
      break;
    case 'private':
      noJobsCard = t('my_jobs.user_has_no_jobs.title_private');
      break;
    default:
      noJobsCard = t('my_jobs.user_has_no_jobs.title');
      break;
  }

  return (
    <div
      data-testid="jobs-list"
      className="w-10/12 sm:w-9/12 md:w-8/12 lg:w-7/12 mx-auto pb-14"
    >
      <div className="flex flex-row items-center mb-8">
        <div className="flex flex-row justify-center w-full items-center text-center flex-shrink-0">
          <Title
            bold
            color="text-secondary-default"
            font="ubuntu"
            size="h1"
            classOverrides={['inline-block text-center']}
          >
            {t('my_jobs.section_title')}
          </Title>
          {!loading && jobs.length > 0 && (
            <Tooltip
              title={t('action:link_job')}
              classOverrides={['ml-2 visible lg:hidden']}
            >
              <TrackedLink
                name={'link job icon'}
                to="/dashboard/linkjob"
                className="rounded-full cursor-pointer shadow-lg bg-primary-default h-8 w-8 flex items-center justify-center"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2x"
                  color="white"
                  className={cn('text-xl')}
                />
              </TrackedLink>
            </Tooltip>
          )}
        </div>
        {!loading && jobs.length > 0 && (
          <div className="flex-shrink-0 invisible lg:ml-10 lg:visible">
            <Tooltip title={t('action:link_job')}>
              <TrackedLink
                name={'link job icon'}
                to="/dashboard/linkjob"
                className="rounded-full cursor-pointer shadow-lg bg-primary-default h-12 w-12 flex items-center justify-center"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="2x"
                  color="white"
                  className={cn('text-xl md:text-4xl ')}
                />
              </TrackedLink>
            </Tooltip>
          </div>
        )}
      </div>
      {setFilterJobPolicy && (
        <FeatureFlag name="private-job">
          <div className="flex  items-center justify-evenly sm:justify-start mb-8 sm:ml-8   text-lg">
            <StyledCheckboxInput
              checked={filterJobPolicy === ''}
              data-testid="checkbox-all-jobs"
              label={t('my_jobs.checkbox_labels.all_jobs')}
              onChange={() => setFilterJobPolicy('')}
              classOverrides={['sm:mr-5']}
            />
            <StyledCheckboxInput
              checked={filterJobPolicy === 'public'}
              label={t('my_jobs.checkbox_labels.public_jobs')}
              data-testid="checkbox-public-jobs"
              onChange={() => setFilterJobPolicy('public')}
              classOverrides={['sm:mr-5']}
            />
            <StyledCheckboxInput
              checked={filterJobPolicy === 'private'}
              label={t('my_jobs.checkbox_labels.private_jobs')}
              data-testid="checkbox-private-jobs"
              onChange={() => setFilterJobPolicy('private')}
            />
          </div>
        </FeatureFlag>
      )}
      <motion.div
        className="relative lg:block"
        variants={fadeInStaggerChildren()}
        initial={'hidden'}
        animate={'show'}
      >
        {jobsRows.map((job, index) => (
          <div
            key={index}
            ref={jobs.length === index + 1 ? lastElementRef : null}
          >
            <JobRow
              job={job}
              index={index}
              isLastRow={index === jobs.length - 1}
            />
          </div>
        ))}
      </motion.div>
      {jobs.length === 0 && !loading && (
        <motion.div
          variants={simpleFadeIn()}
          initial={'hidden'}
          animate={'show'}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex flex-col justify-center items-center text-center"
        >
          <Typography
            size="xl"
            color="text-secondary-dark"
            classOverrides={['mb-1 dark:text-gray-primary']}
          >
            {noJobsCard}
          </Typography>
          <div className="flex text-center items-center">
            <div className="w-72 md:w-96">
              <TrackedLink
                name={'link job when no job available'}
                to="/dashboard/linkjob"
                title={t('add_your_job.card_title.0')}
              >
                <CardIcon
                  title={t('add_your_job.card_title.0')}
                  text={t('add_your_job.card_content.0')}
                >
                  <AddDocumentIcon
                    classOverrides={['text-secondary-default dark:text-white']}
                  />
                </CardIcon>
              </TrackedLink>
            </div>
          </div>
        </motion.div>
      )}
      {loading && <SpinnerIcon />}
    </div>
  );
};
