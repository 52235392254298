import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { useTranslation } from 'react-i18next';
import R2Maintenance from '@images/R2/R2_maintenance.png';
import { motion } from 'framer-motion';
import { fadeInTopVariant } from '@utils/motion.util';
import { Title } from '@components/Titles/Title.component';

const GitHubTab: React.FC<ClassOverrides> = ({ classOverrides = [] }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'relative w-full flex items-center flex-col',
        ...classOverrides,
      )}
      data-testid={'gitlab-tab'}
      id="gitlab-tab"
    >
      <Title
        size={'h2'}
        color={'text-secondary-default'}
        bold
        classOverrides={['text-center']}
      >
        {t('job.github_tab.content')}
      </Title>
      <motion.img
        src={R2Maintenance}
        variants={fadeInTopVariant()}
        className="w-135 lg:w-3/4 2xl:w-2/4"
      />
    </div>
  );
};

export { GitHubTab };
