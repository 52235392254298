import React, { useContext, useEffect } from 'react';
import { PricingCol } from '@components/PricingCol/PricingCol.component';
import { PricingFeatures } from '@typings/pricingFeatures';
import { useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'user/contexts/user.context';
import { Product, useCheckout } from 'common/hooks/useCheckout.hook';
import { PREMIUM_LICENSE_PRICEID } from 'common/constants/general';

type Props = {
  pricing: Array<PricingFeatures>;
  showModal?: boolean;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Component used by the pricing page to display the page
 * @param pricing: An array of type PricingFeatures defined in Pricing
 * Show the table by row and associated a background color and a row title
 */
export const PricingTable: React.FC<Props> = ({
  pricing,
  showModal,
  setShowModal,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identity } = useContext(UserContext);
  const [createCheckoutPage, checkoutUrl] = useCheckout();
  const configureCheckout = (product: Product) => {
    createCheckoutPage(product);
  };
  const redirectTo = (location: string) => {
    history.push(location);
  };
  const getActionButton = (pricingName: string) => {
    switch (pricingName) {
      case t('pricing.ondemand.name'):
        return setShowModal ? setShowModal(!showModal) : undefined;
      case t('pricing.core.name'):
        return identity ? redirectTo('/_/jobs') : redirectTo('/u/signin');
      case t('pricing.premium.name'):
        return identity
          ? configureCheckout({ priceID: PREMIUM_LICENSE_PRICEID })
          : redirectTo('/u/signin');
      default:
        return undefined;
    }
  };
  useEffect(() => {
    if (!checkoutUrl) return;
    window.location.href = checkoutUrl;
  }, [checkoutUrl]);
  const rowNumber = [0, 1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="justify-start lg:justify-center flex flex-row items-end w-full lg:w-11/12 xl:w-10/12 2xl:w-9/12 mb-2">
      <div className=" max-w-14 mb-20 flex flex-col text-right absolute md:static max-h-full">
        {rowNumber.map((number, index) => (
          <span
            key={`${index} - ${number}`}
            className={cn(
              'z-10  align-middle inline-block pr-4 pl-2 w-full text-md text-gray-secondary font-comfortaa overflow-wrap break-word',
              index % 2 === 1 && index < 8
                ? 'bg-gray-primary  dark:text-gray-primary dark:bg-dark-secondary'
                : ' bg-white  dark:text-gray-primary dark:bg-secondary-default',
              index !== 8 && index % 2 === 0 && '',
              index === 0 ? 'h-14 dark:bg-transparent' : 'h-14',
              index !== 6 && index !== 7 ? 'pt-4' : 'pt-1',
            )}
          >
            {t(`pricing.content.${index}`)}
          </span>
        ))}
      </div>
      <div className="flex flex-row ml-64 md:ml-0">
        {pricing.map((element, index) => (
          <div key={`${element.name}-${index}`}>
            <PricingCol col={pricing[index]} />
            <PrimaryButton
              key={pricing[index].textButton}
              classOverrides={[
                'text-center py-1  mt-4 m-0 w-32 dark:bg-gray-primary dark:text-secondary-default font-md',
              ]}
              bgColor={
                pricing[index].isPopular
                  ? 'bg-primary-default'
                  : 'bg-secondary-default'
              }
              bold={true}
              font={'comfortaa'}
              content={pricing[index].textButton}
              action={() => getActionButton(pricing[index].name)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
