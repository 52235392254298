import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import history from 'history';
import mixpanel from 'mixpanel-browser';
import Plausible from 'plausible-tracker';

type TrackedLinkProps<S> = {
  name: string;
} & LinkProps<S> &
  React.RefAttributes<HTMLAnchorElement>;
const { trackEvent } = Plausible();

export function TrackedLink<S = history.LocationState>(
  props: TrackedLinkProps<S>,
) {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }
    if (
      props.name === 'job card from jobs explorer' ||
      props.name === 'job card from home page'
    ) {
      trackEvent('Click on job card');
    }

    mixpanel.track(`[Link] Clicked on ${props.name}`, {
      url: typeof props.to === 'string' ? props.to : undefined,
    });
  };
  return <Link {...props} onClick={onClick} />;
}
