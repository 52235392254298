import React from 'react';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import mixpanel from 'mixpanel-browser';

type SocialIconProps = {
  icon: IconDefinition;
  link?: string;
};

export const SocialIcon: React.FC<SocialIconProps & ClassOverrides> = ({
  classOverrides = [],
  icon,
  link,
}) => {
  return (
    <a
      onClick={() =>
        mixpanel.track('Click on social icon ' + icon.iconName, { url: link })
      }
      href={link}
      rel="noreferrer"
    >
      <div
        className={cn(
          'bg-gray-primary dark:bg-secondary-default rounded-lg p-1 cursor-pointer filter drop-shadow-lg hover:shadow-button-inner relative',
          ...classOverrides,
        )}
      >
        <FontAwesomeIcon
          icon={icon}
          className="text-3xl text-secondary-default dark:text-gray-primary absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
        />
      </div>
    </a>
  );
};
