import React from 'react';
import GitlabLogo from '../../../../assets/images/gitlab.png';
import { ClassOverrides, Sizing } from '@typings/ClassOverrides.type';
import { useTranslation } from 'react-i18next';
import { cn } from '@utils/classes.util';

export const GitlabIcon: React.FC<Sizing & ClassOverrides> = ({
  classOverrides = [],
  width = 'auto',
  height = 'auto',
}) => {
  const { t } = useTranslation();
  return (
    <img
      src={GitlabLogo}
      alt={t('gitlab_alt')}
      className={cn(...classOverrides)}
      width={width}
      height={height}
    />
  );
};
