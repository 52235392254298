// Components used to generate fields

import React, { HTMLAttributes } from 'react';
import { cn } from '@utils/classes.util';
import { AnimatePresence, motion } from 'framer-motion';
import {
  BgColor,
  BorderColor,
  ClassOverrides,
  HTMLStyling,
  PlaceholderColor,
} from '@typings/ClassOverrides.type';
import { UseFormRegisterReturn } from 'react-hook-form';
import { fadeInLeftVariant } from '@utils/motion.util';

export type InputProps<T extends HTMLAttributes<any>> = ClassOverrides &
  HTMLStyling &
  Omit<T, 'className'> & {
    register?: UseFormRegisterReturn;
    error?: string;
    label?: string;
    icon?: JSX.Element;
  };

export const InputLabel: React.FC<{ label?: string } & ClassOverrides> = ({
  classOverrides = [],
  label,
}) => {
  if (!label) return null;

  return (
    <label
      className={cn(
        'flex justify-start text-gray-secondary font-comfortaa text-md',
        ...classOverrides,
      )}
      htmlFor={label}
    >
      {label}
    </label>
  );
};

export const InputError: React.FC<{
  error?: string | JSX.Element;
  label?: string;
}> = ({ error, label }) => (
  <div
    className={cn(
      'text-red-500 text-xs flex justify-start text-left pl-3 mt-1 font-comfortaa',
      !error && 'h-4',
    )}
  >
    {/* We spawn this children with a condition so the animation works properly */}
    <AnimatePresence>
      {error && (
        <motion.span
          key={`error-${label}`}
          variants={fadeInLeftVariant(-5)}
          initial={'hidden'}
          animate={'show'}
          exit={{
            opacity: 0,
            x: -5,
          }}
        >
          {error}
        </motion.span>
      )}
    </AnimatePresence>
  </div>
);

export const getInputClasses = (
  bgColor: BgColor,
  border: string,
  borderColor: BorderColor,
  isInError: boolean,
  placeholderColor: PlaceholderColor,
  ...classOverrides: (string | boolean)[]
) =>
  cn(
    'px-5 py-4 pl-4  p-1 ' +
      'filter shadow-button-inner font-comfortaa w-full text-sm text-secondary-dark',
    isInError ? 'border-red-500' : borderColor || 'border-none',
    isInError ? 'focus:ring-0 focus:border-red-500' : '',
    bgColor,
    border,
    placeholderColor || 'placeholder-input-field',
    ...classOverrides,
  );
