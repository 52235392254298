import React from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import mixpanel from '@utils/mixpanel.util';

type Props = HTMLStyling &
  ButtonProps & {
    loading: boolean;
  };

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 *
 * @see HTMLStyling
 * @see ButtonProps
 */
const SpinnerButton: React.FC<Props> = ({
  content,
  action,
  children,
  classOverrides = [],
  bgColor = 'bg-primary-default',
  color = 'text-white',
  bold = true,
  light = false,
  margin = 'm-4',
  uppercase = false,
  font = 'ubuntu',
  submitButton = false,
  disabled = false,
  hoverBgColor = '',
  loading = false,
}) => {
  const onClickButton = () => {
    if (action) action();
    mixpanel.track(`[Button] Click on ${content}`, {
      buttonType: 'SpinnerButton',
    });
  };

  return (
    <button
      onClick={onClickButton}
      className={cn(
        'px-4 py-3 rounded-2lg hover:shadow-button-inner transition duration-500 flex flex-row items-center ',
        loading ? 'justify-between' : 'justify-center',
        'filter shadow-lg drop-shadow-button-outer ',
        disabled ? 'bg-gray-secondary' : bgColor,
        color,
        `font-${font}`,
        bold && 'font-bold',
        light && 'font-light',
        margin,
        uppercase && 'uppercase',
        hoverBgColor,
        ...classOverrides,
      )}
      data-testid={`primarybutton-${content}`}
      type={submitButton ? 'submit' : 'button'}
      disabled={disabled}
      aria-label={content}
    >
      {content}

      {children}
      {loading && (
        <SpinnerIcon
          size={'sm'}
          classOverrides={['w-max ml-1 h-5 p-0 m-0']}
          color="text-white"
        />
      )}
    </button>
  );
};

export { SpinnerButton };
