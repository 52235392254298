import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const MagicWandAltIcon: React.FC<ClassOverrides> = ({
  classOverrides = [],
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides)}
    >
      <path
        d="M0 18.4374C0.0829962 18.2676 0.208274 18.1321 0.340859 17.9992C2.71747 15.6254 5.09304 13.25 7.46496 10.8714C7.56884 10.7672 7.66175 10.7396 7.80582 10.7615C8.17852 10.8188 8.55383 10.86 8.92914 10.8949C9.05129 10.9064 9.092 10.9486 9.10401 11.0705C9.14263 11.4654 9.19275 11.8588 9.24494 12.2522C9.25799 12.3501 9.24286 12.4194 9.16873 12.4934C6.73157 14.9262 4.29597 17.361 1.86298 19.7978C1.77424 19.8869 1.6594 19.9276 1.56283 19.9995H1.28931C1.12958 19.976 1.00848 19.8922 0.897821 19.7754C0.67963 19.5451 0.454652 19.3206 0.224455 19.1017C0.10753 18.9913 0.0240115 18.8699 0.000521989 18.7099V18.4364L0 18.4374Z"
        fill="currentColor"
      />
      <path
        d="M10.9354 0C11.2152 0.107334 11.3535 0.31679 11.4141 0.606487C11.4788 0.916504 11.5785 1.21975 11.6589 1.52664C11.7502 1.87834 11.5853 2.18575 11.2585 2.2811C10.9412 2.37384 10.6321 2.1894 10.5319 1.83874C10.4307 1.48339 10.3325 1.12752 10.2417 0.769571C10.153 0.420477 10.2819 0.192784 10.6619 0H10.9354Z"
        fill="currentColor"
      />
      <path
        d="M8.49243 2.65312C8.61196 2.64166 8.72314 2.70158 8.83589 2.75004C9.74207 3.13665 10.6488 3.52221 11.5518 3.91612C11.6724 3.96874 11.7319 3.96562 11.8164 3.85047C12.4173 3.03348 13.0269 2.22275 13.6361 1.41149C13.8444 1.1343 14.133 1.05875 14.4055 1.19995C14.5939 1.29791 14.6968 1.45734 14.7218 1.66315C14.8429 2.67032 14.964 3.67748 15.0763 4.68569C15.0935 4.83887 15.1379 4.90505 15.3039 4.92328C16.3118 5.03322 17.3187 5.15514 18.3262 5.27654C18.5898 5.30833 18.7683 5.45318 18.845 5.71161C18.9134 5.94295 18.8309 6.1795 18.6101 6.34571C17.7948 6.96001 16.9789 7.57275 16.1568 8.1782C16.0383 8.26573 16.031 8.33138 16.0863 8.458C16.4903 9.38544 16.8881 10.3155 17.2838 11.2471C17.4461 11.6295 17.2718 11.9953 16.8938 12.0922C16.7388 12.1318 16.5994 12.0886 16.4595 12.0287C15.5367 11.6322 14.6117 11.2393 13.6914 10.836C13.5635 10.7797 13.5077 10.799 13.4273 10.9084C12.8244 11.7244 12.2147 12.5351 11.6056 13.3458C11.4208 13.5918 11.2005 13.6736 10.9442 13.598C10.6738 13.5178 10.547 13.3203 10.5141 13.053C10.4593 12.6075 10.4076 12.162 10.3549 11.7166C10.2875 11.1419 10.2186 10.5672 10.155 9.99193C10.1445 9.89918 10.1158 9.85698 10.0161 9.84604C9.00867 9.73089 8.00176 9.60897 6.99432 9.49277C6.68113 9.45682 6.47912 9.31771 6.40395 9.065C6.32356 8.79719 6.40917 8.57888 6.67852 8.37723C7.47977 7.777 8.27998 7.17468 9.08593 6.5807C9.20233 6.49473 9.21381 6.43064 9.15744 6.30038C8.7529 5.37294 8.35619 4.44236 7.96209 3.51075C7.7773 3.0736 8.03777 2.6526 8.49295 2.65469L8.49243 2.65312Z"
        fill="currentColor"
      />
      <path
        d="M17.588 1.82623C17.8312 1.82727 18.0149 1.93512 18.1172 2.15448C18.2185 2.37175 18.2049 2.59788 18.0405 2.77451C17.7492 3.08818 17.4475 3.39402 17.1317 3.68268C16.9005 3.89422 16.5523 3.85931 16.3409 3.63839C16.1295 3.41747 16.1154 3.07463 16.3315 2.84485C16.6113 2.54682 16.9057 2.26233 17.1985 1.97628C17.304 1.87364 17.4366 1.82049 17.5885 1.82623H17.588Z"
        fill="currentColor"
      />
      <path
        d="M15.0282 14.1128C15.023 14.4562 14.822 14.6927 14.538 14.7464C14.2812 14.7948 13.9915 14.6557 13.9095 14.3973C13.7744 13.9716 13.6559 13.5386 13.5614 13.102C13.5029 12.8316 13.6934 12.57 13.9555 12.4814C14.2071 12.397 14.5192 12.4893 14.6289 12.7326C14.834 13.189 14.906 13.6887 15.0282 14.1123V14.1128Z"
        fill="currentColor"
      />
      <path
        d="M7.54269 5.86738C7.54164 6.24357 7.19557 6.51972 6.82339 6.42906C6.4392 6.33528 6.05659 6.23263 5.67658 6.12321C5.35608 6.03047 5.18382 5.71837 5.26682 5.40835C5.35034 5.09573 5.64839 4.91597 5.9762 4.99621C6.36038 5.09 6.74248 5.19212 7.12196 5.3031C7.3934 5.3823 7.54269 5.59697 7.54164 5.86738H7.54269Z"
        fill="currentColor"
      />
      <path
        d="M19.3875 9.77831C19.2846 9.78196 19.1735 9.7408 19.0612 9.7111C18.7543 9.63034 18.4469 9.54905 18.142 9.45996C17.8158 9.36461 17.632 9.06032 17.7088 8.75864C17.7881 8.44654 18.0867 8.25115 18.4119 8.3267C18.8096 8.41892 19.2037 8.52678 19.5947 8.64349C19.8745 8.72686 20.042 9.0275 19.9883 9.30417C19.934 9.58292 19.6918 9.77935 19.387 9.77831H19.3875Z"
        fill="currentColor"
      />
    </svg>
  );
};
