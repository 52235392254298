import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

export const TableHeader: React.FC<ClassOverrides> = ({
  children,
  classOverrides = [],
}) => {
  return <th className={cn(...classOverrides)}>{children}</th>;
};
