import React, { useContext } from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { ThemeContext } from 'common/contexts/theme.context';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const LensIcon: React.FC<Props> = ({
  classOverrides = [],
  color = 'text-secondary-default',
}) => {
  const { theme } = useContext(ThemeContext);
  const defaultColor = theme === 'light' ? '#FEFEFE' : '#4E5E61';
  return (
    <svg
      width="60"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      className={cn(...classOverrides, color)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2852_161)">
        <path
          d="M34.8103 0.151324C50.5218 0.985248 63.5715 12.9344 65.6938 28.4841C66.7596 36.3275 65.2182 43.66 61.0694 50.4197C60.7705 50.9062 60.6935 51.186 61.1728 51.6481C63.6297 54.0353 66.0434 56.4675 68.4515 58.9054C68.8274 59.2942 69.0586 59.4689 69.5455 58.9937C70.2072 58.3495 71.0193 58.4735 71.7825 59.2304C74.2125 61.6445 76.6381 64.0649 79.0593 66.4915C82.3941 69.8235 85.7301 73.1554 89.0674 76.4874C89.4346 76.8246 89.7512 77.213 90.0073 77.6406V78.5196C89.6859 78.8952 89.387 79.2709 89.0411 79.6277C85.8304 82.8357 82.6178 86.0431 79.4033 89.2498C78.4051 90.2471 77.6156 90.2528 76.6305 89.2686C70.8307 83.4762 65.0333 77.6825 59.2385 71.8876C58.3192 70.9692 58.2666 70.3531 59.1012 69.3971C59.3795 69.0797 59.2892 68.9445 59.0543 68.7116C56.5033 66.1722 53.9468 63.6404 51.4203 61.0691C51.0274 60.6691 50.7943 60.8663 50.4635 61.0691C46.7264 63.4019 42.6867 65.0021 38.3293 65.6313C27.6312 67.1771 18.1664 64.4011 10.2937 56.9653C3.07894 50.153 -0.252096 41.6165 0.0148384 31.7071C0.402081 17.5248 10.8125 4.644 24.6104 1.19373C26.7352 0.649885 28.9054 0.301588 31.0939 0.153201C32.3307 0.0549101 33.5733 0.0542831 34.8103 0.151324Z"
          fill="currentColor"
        />
        <path
          d="M34.8101 0.148335H31.0938C31.1088 0.0997346 31.1126 0.048373 31.105 -0.00192261H34.797C34.7966 0.048467 34.801 0.0987809 34.8101 0.148335Z"
          fill="#A7A7A7"
        />
        <path
          d="M46.5016 81.0166C45.6971 80.9978 45.1519 80.4531 45.0316 79.5628C44.635 76.5859 43.4563 75.4308 40.428 75.0251C39.6196 74.9199 39.035 74.3339 39.0519 73.5826C39.0377 73.226 39.1557 72.8766 39.3832 72.6015C39.6108 72.3263 39.9319 72.1446 40.2851 72.0913C40.6836 72.003 41.1066 72.0368 41.5032 71.9486C43.509 71.5053 44.8455 69.9051 45.0185 67.777C45.1294 66.421 46.1464 65.6997 47.2028 66.235C47.7874 66.5299 47.9792 67.0352 48.0393 67.6681C48.2894 70.2863 49.8252 71.8002 52.4268 72.0368C53.4325 72.1307 54.0134 72.6904 54.0096 73.5638C54.0059 74.4372 53.4607 74.9406 52.4926 75.0401C49.7744 75.3199 48.3345 76.7699 48.0243 79.5478C47.9171 80.4925 47.357 81.0353 46.5016 81.0166Z"
          fill="currentColor"
        />
        <path
          d="M81.0103 7.64993C81.0235 8.4801 80.3975 9.07361 79.4125 9.1525C77.2112 9.34032 75.6528 10.5875 75.1547 12.584C75.055 12.9765 75.0701 13.3954 75.0024 13.7973C74.8577 14.6538 74.2411 15.1646 73.4234 15.1252C72.6451 15.0876 72.1376 14.5617 72.0342 13.7072C71.6845 10.7884 70.3668 9.48118 67.4343 9.14123C66.5696 9.04168 66.03 8.45756 66.0432 7.63866C66.0564 6.81976 66.6203 6.20934 67.5734 6.16427C69.2953 6.08351 70.5811 5.30969 71.4496 3.83905C71.8255 3.19482 71.959 2.47735 72.0267 1.74297C72.1207 0.766298 72.6489 0.202836 73.4704 0.180297C74.2918 0.157759 75.0062 0.777567 75.0118 1.7486C75.025 4.14144 77.1134 6.16239 79.4576 6.1699C79.6572 6.16339 79.8562 6.1967 80.0429 6.26789C80.2295 6.33907 80.4001 6.4467 80.5447 6.58448C80.6892 6.72227 80.8048 6.88745 80.8848 7.07039C80.9647 7.25332 81.0074 7.45034 81.0103 7.64993Z"
          fill="currentColor"
        />
        <path
          d="M63.0246 33.1372C62.9757 49.7312 49.4899 63.1322 32.9193 63.0646C16.4014 62.997 3.03024 49.5415 3.09791 33.064C3.17498 16.4719 16.5781 3.13472 33.1355 3.17604C49.6365 3.21737 63.0753 16.686 63.0246 33.1372Z"
          fill={defaultColor}
        />
        <path
          d="M70.5954 62.1913C70.705 62.3407 70.8223 62.4842 70.9469 62.6214C75.9322 67.6074 80.9219 72.5884 85.9159 77.5644C86.2919 77.94 86.3332 78.1279 85.9328 78.5129C83.4264 80.9734 80.9426 83.4538 78.4812 85.9544C78.1053 86.33 77.9305 86.3582 77.5413 85.9544C72.5899 80.9771 67.6253 76.0124 62.6475 71.0602C62.3374 70.7521 62.0949 70.5699 62.5611 70.1211C65.0932 67.6493 67.5821 65.1307 70.0803 62.6308C70.2251 62.4936 70.3868 62.3716 70.5954 62.1913Z"
          fill={defaultColor}
        />
        <path
          d="M58.7873 53.5045C59.0411 53.7393 59.1632 53.8482 59.2854 53.9666C61.6878 56.3669 64.0827 58.7729 66.4983 61.1601C66.8179 61.4775 66.8404 61.6616 66.4983 61.9865C64.9493 63.5028 63.4167 65.0342 61.9003 66.5806C61.6051 66.8811 61.4435 66.8924 61.1483 66.5806C58.7384 64.1527 56.3172 61.7323 53.8847 59.3194C53.6648 59.1016 53.4636 58.9438 53.8358 58.6489C55.5935 57.2352 57.1948 55.6378 58.6125 53.8839C58.685 53.7645 58.7437 53.6372 58.7873 53.5045Z"
          fill={defaultColor}
        />
        <path
          d="M46.479 72.0856C46.5187 72.0999 46.5566 72.1188 46.5918 72.142C47.0543 72.6134 47.5129 73.0811 47.9735 73.56L46.3531 74.9274C46.5241 74.9649 46.4565 74.9706 46.4264 74.9405C45.979 74.4597 45.5354 73.9751 45.0898 73.4905C45.6066 73.0785 46.0729 72.6069 46.479 72.0856Z"
          fill="#FBFBFB"
        />
        <path
          d="M73.5734 6.20372C73.9788 6.72587 74.448 7.19531 74.9701 7.60111C74.9062 7.91665 74.6092 8.02558 74.4174 8.2134C73.5189 9.07926 73.5546 9.02291 72.6241 8.25848C72.0263 7.76451 72.0601 7.48278 72.6504 7.07145C72.9801 6.80655 73.2887 6.51643 73.5734 6.20372Z"
          fill="#FBFBFB"
        />
        <path
          d="M60.0143 33.1748C59.9955 48.0033 47.9064 60.0745 33.0784 60.067C29.53 60.0658 26.0166 59.3656 22.7392 58.0067C19.4618 56.6477 16.4847 54.6565 13.9781 52.147C11.4715 49.6375 9.48465 46.6589 8.13112 43.3816C6.77759 40.1043 6.08395 36.5925 6.08988 33.0471C6.08988 18.1829 18.2203 6.11733 33.1066 6.1624C40.2593 6.18131 47.1118 9.0374 52.1575 14.1028C57.2033 19.1682 60.0293 26.0282 60.0143 33.1748Z"
          fill="currentColor"
        />
        <path
          d="M78.2116 81.0184C77.407 81.0409 76.9596 80.7498 76.6776 80.2202C76.3487 79.6041 76.4896 79.0162 76.9295 78.5185C77.351 78.0329 77.8071 77.5785 78.2943 77.1587C78.8075 76.7305 78.8225 76.4487 78.3168 75.9529C75.4557 73.1487 72.636 70.3014 69.8012 67.4709C69.5324 67.2023 69.2241 66.9487 69.117 66.575C68.929 65.9007 69.0474 65.2828 69.6546 64.8639C70.2618 64.4451 70.9348 64.4207 71.4386 64.9184C75.0365 68.472 78.6126 72.0493 82.1667 75.6505C82.6912 76.182 82.6536 76.9352 82.1385 77.4893C81.1234 78.5786 80.0463 79.6116 78.9879 80.6616C78.7819 80.8719 78.5055 80.9989 78.2116 81.0184Z"
          fill="currentColor"
        />
        <path
          d="M23.6191 55.1367L27.3956 51.3803C28.1005 50.6778 28.8149 49.9847 29.5029 49.2654C30.1909 48.546 30.2172 47.7027 29.6138 47.0773C29.0104 46.4518 28.0987 46.4575 27.358 47.1881C25.3805 49.1452 23.4104 51.1004 21.4629 53.0951C20.9948 53.5778 20.6828 53.6773 20.1 53.2697C18.8612 52.4039 18.8349 52.4396 19.8707 51.4028C20.5756 50.6985 21.29 50.0054 21.9799 49.2879C22.6698 48.5705 22.7092 47.6464 22.0682 47.0341C21.4272 46.4218 20.5944 46.4706 19.8782 47.1618C18.9101 48.1009 17.9458 49.04 17.0284 50.0261C16.6525 50.4355 16.4476 50.4017 16.0791 50.0167C12.3195 46.1664 9.91707 41.5873 9.36252 36.2475C8.30419 26 12.1522 17.9106 20.9873 12.5708C35.1329 4.00809 53.7394 12.5859 56.6249 28.8249C58.954 41.9348 50.4478 54.2952 37.4075 56.6956C32.7343 57.5521 28.1945 57.0393 23.6191 55.1367Z"
          fill={defaultColor}
        />
        <path
          d="M32.7447 45.1202C26.9172 44.7615 22.0842 42.2653 18.5878 37.3256C17.8584 36.2964 17.9261 35.4268 18.7382 34.884C19.5502 34.3412 20.3153 34.5985 21.0221 35.5996C23.6294 39.2827 27.1503 41.5403 31.6506 41.9704C37.1416 42.4945 41.4802 40.2594 44.8093 35.9602C44.971 35.7517 45.1082 35.5263 45.2699 35.3197C45.8545 34.5684 46.6365 34.4182 47.349 34.8896C48.0614 35.361 48.2269 36.2588 47.7024 37.0496C46.5586 38.7917 45.1152 40.3178 43.439 41.5572C40.3185 43.8674 36.7938 45.0131 32.7447 45.1202Z"
          fill="currentColor"
        />
        <path
          d="M33.0182 21.1242C39.2216 21.4566 44.0358 24.026 47.5529 28.977C48.2278 29.9292 48.1413 30.8364 47.3649 31.3623C46.5886 31.8882 45.7859 31.6347 45.0753 30.6617C41.78 26.154 37.3793 23.7931 31.7455 24.2758C27.3449 24.6515 23.8503 26.8227 21.2186 30.3612C21.0795 30.5491 20.9573 30.7481 20.8106 30.9247C20.1997 31.6948 19.3707 31.845 18.662 31.321C17.9947 30.8252 17.8462 29.9875 18.3725 29.2212C21.9423 24.0298 26.9144 21.4135 33.0182 21.1242Z"
          fill="currentColor"
        />
        <path
          d="M54.0063 32.2225C54.0063 32.5437 54.0251 32.8668 54.0063 33.188C53.9518 34.0106 53.401 34.5722 52.6284 34.6154C51.82 34.6605 51.1772 34.1458 51.0587 33.28C50.9347 32.354 50.9685 31.4149 50.8369 30.4833C49.6902 22.3807 42.8627 15.931 34.6855 15.2698C34.1216 15.2229 33.5745 15.2003 33.02 15.1534C32.1064 15.0745 31.5462 14.4641 31.5838 13.6076C31.6214 12.7512 32.2305 12.1708 33.1572 12.1783C35.1202 12.191 37.072 12.4761 38.9565 13.0254C47.5547 15.5384 53.6999 23.395 54.0063 32.2225Z"
          fill="currentColor"
        />
        <path
          d="M39.0372 33.141C39.035 34.3244 38.6812 35.4806 38.0208 36.463C37.3604 37.4454 36.423 38.2098 35.3274 38.6595C34.2318 39.1092 33.0273 39.2239 31.8664 38.989C30.7055 38.7542 29.6404 38.1804 28.8062 37.3403C27.972 36.5002 27.4061 35.4316 27.1802 34.2699C26.9542 33.1082 27.0785 31.9056 27.5372 30.8145C27.9959 29.7234 28.7683 28.7928 29.7567 28.1407C30.7452 27.4886 31.905 27.1443 33.0895 27.1514C34.6708 27.1652 36.1826 27.8022 37.2964 28.9238C38.4102 30.0454 39.0358 31.561 39.0372 33.141Z"
          fill="currentColor"
        />
        <path
          d="M53.0739 39.3297C53.0081 39.5306 52.886 39.8931 52.7694 40.2425C52.2721 41.6757 51.6043 43.044 50.7806 44.3182C50.2598 45.1371 49.4026 45.3324 48.6658 44.861C47.9289 44.3896 47.7484 43.5932 48.2259 42.795C49.0034 41.4941 49.6513 40.1202 50.1602 38.693C50.4441 37.891 51.164 37.4984 51.8709 37.6506C52.5777 37.8027 53.0946 38.4319 53.0739 39.3297Z"
          fill="currentColor"
        />
        <path
          d="M36.0283 33.1617C36.0253 33.753 35.8469 34.3302 35.5155 34.8203C35.1842 35.3104 34.7149 35.6913 34.167 35.9148C33.619 36.1384 33.017 36.1946 32.437 36.0763C31.8571 35.958 31.3253 35.6705 30.9089 35.2503C30.4925 34.83 30.2101 34.2958 30.0976 33.7152C29.985 33.1346 30.0473 32.5337 30.2766 31.9885C30.5058 31.4433 30.8917 30.9782 31.3855 30.6521C31.8793 30.326 32.4588 30.1536 33.0506 30.1565C33.445 30.1537 33.8359 30.2297 34.2004 30.3801C34.5649 30.5305 34.8957 30.7523 35.1731 31.0323C35.4506 31.3123 35.6692 31.645 35.816 32.0107C35.9629 32.3763 36.035 32.7677 36.0283 33.1617Z"
          fill={defaultColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2852_161">
          <rect width="90" height="90" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
