import {
  SelfServiceSettingsFlow,
  SessionAuthenticationMethodMethodEnum,
  SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import i18n from 'i18next';

export const changePwdFilterUiNodes = (
  flow: SelfServiceSettingsFlow,
): Promise<SelfServiceSettingsFlow> => {
  flow.ui.nodes = flow.ui.nodes.filter(
    (n) => n.group === 'password' || n.group === 'default',
  );
  const idIndex = flow.ui.nodes.findIndex(
    (e) =>
      isUiNodeInputAttributes(e.attributes) && e.attributes.name === 'password',
  );
  if (idIndex === -1) throw new Error('Identifier field not found');
  const item = flow.ui.nodes[idIndex];
  if (item && item.meta.label) {
    item.meta.label.text = i18n.t('change_pwd.input_label');
  }
  flow.ui.nodes[idIndex] = item;
  return Promise.resolve(flow);
};

export const changePwdFilterOutMethod = (
  flow: SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
): Promise<SubmitSelfServiceSettingsFlowWithPasswordMethodBody> => {
  flow.method = SessionAuthenticationMethodMethodEnum.Password;
  return Promise.resolve(flow);
};

const changePwdFilterPassword = <T extends SelfServiceSettingsFlow>(
  flow: T,
): Promise<T> => {
  // We sort to have password in last position
  flow.ui.nodes = flow.ui.nodes.sort((v) =>
    isUiNodeInputAttributes(v.attributes) && v.attributes.name === 'password'
      ? 1
      : -1,
  );
  const idIndex = flow.ui.nodes.findIndex(
    (e) =>
      isUiNodeInputAttributes(e.attributes) &&
      e.attributes.name === 'password_repeat',
  );

  if (idIndex > -1) return Promise.resolve(flow);

  // We add the password repeat field
  flow.ui.nodes.push({
    type: 'input',
    group: 'password',
    attributes: {
      name: 'password_repeat',
      type: 'password',
      required: true,
      disabled: false,
      node_type: 'input',
    },
    messages: [],
    meta: {
      label: {
        id: 0,
        text: 'Confirm password',
        type: 'info',
      },
    },
  });
  return Promise.resolve(flow);
};

export const KratosChangePwdFiltersIn = [
  changePwdFilterUiNodes,
  changePwdFilterPassword,
];
export const KratosChangePwdFiltersOut = [changePwdFilterOutMethod];
