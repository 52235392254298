import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = {
  width?: string;
  height?: string;
};

export const LaserSwordIcon: React.FC<Props & ClassOverrides> = ({
  width = '121',
  height = '38',
  classOverrides = [],
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 121 38"
    className={cn(...classOverrides)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4847_10)">
      <path
        d="M109.646 37.3764C76.581 37.3764 43.5141 37.3544 10.4453 37.3103C4.05239 29.9297 4.29295 22.1103 8.88762 14.0024C12.7065 11.2737 17.0366 11.1535 21.4869 11.1535C47.8762 11.1896 74.2696 11.1896 100.667 11.1535C104.768 11.1535 108.786 11.3579 112.448 13.5156C118.077 22.9638 117.284 29.7133 109.646 37.3764Z"
        fill="#DDDDDD"
      />
      <path
        d="M111.48 15.1504L10.5656 15.1504C7.66682 12.578 8.11786 9.94552 10.4393 7.28897C23.221 7.10465 36.0027 6.92033 48.7844 6.73601C55.6002 2.72915 62.404 2.72915 69.1958 6.73601C82.6872 6.89228 96.1785 7.05055 109.67 7.21083C113.038 9.23028 114.998 11.5683 111.48 15.1504Z"
        fill="#EEEEEE"
      />
      <path
        d="M10.438 7.28899L10.5643 15.1504L10.4441 37.3103C0.905881 36.505 0.0158078 29.8636 0.00979346 22.3147C0.00377915 14.7658 0.941958 8.14846 10.438 7.28899Z"
        fill="#7E7E7E"
      />
      <path
        d="M111.479 15.1504C111.806 13.7773 111.814 12.3474 111.501 10.9708C111.188 9.59428 110.563 8.30789 109.675 7.21083C119.153 8.14843 119.989 14.8199 119.989 22.3147C119.989 29.8095 119.147 36.4929 109.645 37.3764C113.626 30.2483 111.334 22.5731 111.479 15.1504Z"
        fill="#7F7F7F"
      />
      <path
        d="M109.664 7.28294L109.531 15.1444L109.652 37.3103C119.19 36.4989 120.08 29.8576 120.086 22.2846C120.092 14.7117 119.154 8.14842 109.664 7.28294Z"
        fill="#7E7E7E"
      />
      <path
        d="M55.6875 7.11469L69.1528 7.11468C69.1528 7.11468 69.0506 0.539453 65.1595 0.341114C61.2685 0.142775 56.782 0.341115 56.782 0.341115L55.6875 0.250946L55.6875 7.11469Z"
        fill="#B54937"
      />
      <path
        d="M62.4183 7.11468L48.959 7.11469C48.959 7.11469 49.0552 0.539454 52.9463 0.341114C56.8373 0.142775 61.3237 0.341114 61.3237 0.341114L62.4183 0.250945L62.4183 7.11468Z"
        fill="#B54937"
      />
    </g>
    <defs>
      <clipPath id="clip0_4847_10">
        <rect
          width="38"
          height="121"
          fill="white"
          transform="translate(0 38) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
