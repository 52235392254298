import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Page } from '@components/Page/Page.component';
import { useLicenseProject } from 'common/hooks/useLicenseProject.hook';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ModifyProject } from 'user/components/ModifyProject/ModifyProject.component';
import { ProjectParams } from './dashboard.router';

export const ProjectPage: React.FC = () => {
  const { id: projectID } = useParams<ProjectParams>();
  const { t } = useTranslation();
  const { isLoading, data, refetch } = useLicenseProject(projectID);
  useEffect(() => {
    if (data !== undefined) return;
    refetch();
  });
  return (
    <Page
      noNav
      title={t('personal_space.title')}
      description={t('personal_space.description')}
    >
      {/* Hi {projectName} {data?.name_with_namespace} */}
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <SpinnerIcon />
        </div>
      ) : (
        data && <ModifyProject project={data} />
      )}
    </Page>
  );
};
