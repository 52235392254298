import React from 'react';
import { Label } from '@components/Buttons/Label/Label.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';

const DeprecationLabel: React.FC<ClassOverrides> = ({ classOverrides }) => {
  let override = classOverrides ? classOverrides : [];
  return (
    <Label
      bgColor="bg-red-alert"
      content={'Deprecated'}
      classOverrides={['bg-red-700 uppercase px-3 sm:px-7', ...override]}
    />
  );
};

export { DeprecationLabel };
