import React from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

export const CheckIcon: React.FC<
  ClassOverrides & Sizing & { color?: TextColor }
> = ({ classOverrides = [], height = '31', width = '34', color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <path
        d="M32.5152 0.230049C30.981 -0.596086 29.2697 0.997176 28.2666 1.94133C25.9653 4.1837 24.0179 6.78013 21.8345 9.14051C19.4151 11.7369 17.1727 14.3334 14.6943 16.8709C13.2781 18.2871 11.7438 19.8214 10.7997 21.5917C8.67533 19.5262 6.84603 17.2839 4.48564 15.4546C2.77436 14.1564 -0.0581022 13.2123 0.000907519 16.3398C0.118927 20.4116 3.71852 24.7783 6.37395 27.5516C7.49514 28.7318 8.97038 29.971 10.6817 30.03C12.747 30.1481 14.8714 27.6697 16.1106 26.3124C18.294 23.952 20.0643 21.2965 22.0705 18.8772C24.667 15.6907 27.3224 12.5631 29.8598 9.31754C31.4531 7.31121 36.4689 2.3543 32.5152 0.230049ZM2.59724 16.1038C2.53823 16.1038 2.47922 16.1038 2.3612 16.1627C2.12516 16.1038 1.94813 16.0446 1.71209 15.9266C1.88912 15.8086 2.18417 15.8676 2.59724 16.1038Z"
        fill="currentColor"
      />
    </svg>
  );
};
