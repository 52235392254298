import {
  SelfServiceVerificationFlow,
  SubmitSelfServiceVerificationFlowWithLinkMethodBody,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from '../helpers/kratos.helper';

export const verificationFilterUiNodes = (
  flow: SelfServiceVerificationFlow,
): Promise<SelfServiceVerificationFlow> => {
  const idIndex = flow.ui.nodes.findIndex(
    (e) =>
      isUiNodeInputAttributes(e.attributes) && e.attributes.name === 'email',
  );
  if (idIndex === -1) throw new Error('Mail field not found');
  const item = flow.ui.nodes[idIndex];
  if (item && item.meta) {
    item.meta.label = {
      id: 0,
      text: 'Email',
      type: 'email',
    };
  }

  flow.ui.nodes[idIndex] = item;

  return Promise.resolve(flow);
};

export const KratosVerificationFiltersIn = [verificationFilterUiNodes];

export const verificationFilterOutMethod = (
  flow: SubmitSelfServiceVerificationFlowWithLinkMethodBody,
): Promise<SubmitSelfServiceVerificationFlowWithLinkMethodBody> => {
  flow.method = 'link';
  return Promise.resolve(flow);
};

export const KratosVerificationFiltersOut = [verificationFilterOutMethod];
