import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { ErrorLayout } from '@components/Page/ErrorLayout/ErrorLayout.component';
import { Layout } from '@components/Page/Layout/Layout.component';
import { Page } from '@components/Page/Page.component';
import { Typography } from '@components/Typography/Typography.component';
import { simpleFadeIn } from '@utils/motion.util';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HttpError from 'standard-http-error';
import { UserContext } from 'user/contexts/user.context';

type Props = {
  error: Error;
};

const ErrorPage: React.FC<FallbackProps & Props> = ({
  error,
  resetErrorBoundary,
}) => {
  const history = useHistory();
  history.listen(() => {
    resetErrorBoundary();
  });
  const { t, i18n } = useTranslation('error');
  const [links, setLinks] = useState<ResetErrorLinkProps[] | undefined>();
  const { identity } = useContext(UserContext);
  let output;

  switch (error.name) {
    case 'HttpError':
      output = <HttpErrorContent error={error as HttpError} />;
      break;
    default:
      output = (
        <Typography
          color={'text-secondary-default'}
          classOverrides={['dark:text-gray-primary']}
        >
          {t('error_page.errors.default')}
        </Typography>
      );
      break;
  }

  useEffect(() => {
    let i = 0;
    while (i18n.exists('error:error_page.links.' + i)) i++;
    const translations: ResetErrorLinkProps[] = [];
    for (let j = 0; j < i; j++) {
      if (j > 0) {
        translations.push({
          name: t('error:error_page.links.' + j + '.name'),
          link: t('error:error_page.links.' + j + '.link'),
          target: i18n.exists('error:error_page.links.' + j + '.target')
            ? t('error:error_page.links.' + j + '.target')
            : undefined,
          redirect: t('error:error_page.links.' + j + '.redirect'),
        });
      } else {
        if (identity) {
          translations.push({
            name: t('error:error_page.links_home_page_connected_name'),
            link: t('error:error_page.links_home_page_connected_link'),
            redirect: t('error:error_page.links_home_page_redirect'),
          });
        } else {
          translations.push({
            name: t('error:error_page.links.' + j + '.name'),
            link: t('error:error_page.links.' + j + '.link'),
            redirect: t('error:error_page.links.' + j + '.redirect'),
          });
        }
      }
    }
    setLinks(translations);
  }, [i18n, t, identity]);

  return (
    <Layout>
      <Page
        title={t('error_page.title.0')}
        description={t('error_page.description.0')}
        isLoading={false}
        navBarCutter
      >
        <ErrorLayout
          classOverrides={['w-4/6 lg:w-4/12']}
          title={t('error:error_page.h1.global')}
        >
          <motion.span
            variants={simpleFadeIn()}
            data-testid="http-error-sentence"
          >
            {output}
          </motion.span>
          <motion.div
            variants={simpleFadeIn()}
            className="flex flex-col sm:flex-row sm:flex-wrap mt-12"
            data-testid="reset-error-link"
          >
            {links &&
              links.map((link, index) => (
                <ResetErrorLink
                  key={link.name + '-' + index}
                  link={link.link}
                  name={link.name}
                  target={link.target}
                  redirect={link.redirect}
                />
              ))}
          </motion.div>
        </ErrorLayout>
      </Page>
    </Layout>
  );
};

type ResetErrorLinkProps = {
  link: string;
  name: string;
  target?: string;
  redirect: string;
};

const ResetErrorLink: React.FC<ResetErrorLinkProps> = ({
  link,
  name,
  target = '',
  redirect,
}) => {
  return (
    <motion.span variants={simpleFadeIn()} className="w-full sm:w-1/2 pb-2">
      <OutsideLink name={name} to={link} target={target}>
        <Typography
          color="text-secondary-dark"
          classOverrides={[
            'hover:text-secondary-default transition-all duration-500 dark:text-gray-primary',
          ]}
        >
          {name}
        </Typography>
      </OutsideLink>
    </motion.span>
  );
};

const HttpErrorContent: React.FC<{ error: HttpError }> = ({ error }) => {
  const { t, i18n } = useTranslation('error');
  switch (error.code) {
    case 503:
      return (
        <Typography
          color={'text-secondary-default'}
          classOverrides={['dark:text-gray-primary']}
        >
          <Trans
            i18nKey="error:error_page.errors.http.503"
            components={{
              spanColor: <span className="text-red-alert" />,
            }}
            values={{ error: error.message }}
          />
        </Typography>
      );
    default:
      if (!i18n.exists('error:error_page.errors.http.' + error.code)) {
        return (
          <Typography
            color={'text-secondary-default'}
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey="error:error_page.errors.http.default"
              values={{ code: error.code }}
            />
          </Typography>
        );
      } else {
        return (
          <Typography
            color={'text-secondary-default'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('error:error_page.errors.http.' + error.code)}
          </Typography>
        );
      }
  }
};

export { ErrorPage };
