import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plausible from 'plausible-tracker';
import mixpanel from '@utils/mixpanel.util';
import { LightPipelineResponse } from '@home/hooks/useLightPipelineGenerator/useLightPipelineGenerator.hook';
import { Modal } from '@components/Modal/Modal.component';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import {
  github,
  solarizedDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ThemeContext } from 'common/contexts/theme.context';
import { PlatformToggle } from 'user/components/PlatformToggle/PlatformToggle.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorSetEnum } from 'common/constants/getColorSet';
import ReactTooltip from 'react-tooltip';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Title } from '@components/Titles/Title.component';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { SM_SCREEN_SIZE } from 'common/constants/style.constant';

type Props = {
  fetchResult: LightPipelineResponse;
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  platformToDisplay: 'github' | 'gitlab';
  projectUrl: string;
};
/**
 * Left button in the top navigation bar
 * @constructor
 */
export const LightPipelineResponseModal: React.FC<Props> = ({
  fetchResult,
  showResponseModal,
  setShowResponseModal,
  platformToDisplay,
  projectUrl,
}) => {
  const { theme } = useContext(ThemeContext);
  const { trackEvent } = Plausible();
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const [displayFeedBack, setDisplayFeedBack] = useState(true);
  const copyPipelineCode = (platform: 'gitlab' | 'github') => {
    if (!gitLabPipeline || !gitHubPipeline) return;
    navigator.clipboard.writeText(
      platform === 'gitlab' ? gitLabPipeline : gitHubPipeline,
    );
    mixpanel.track(`[Copy] a light pipeline generated code`);
    trackEvent('Copy a light pipeline generated code');
  };

  const [platformDisplayed, setPlatformDisplayed] = useState<
    'gitlab' | 'github'
  >(platformToDisplay);
  const [gitHubPipeline, setGitHubPipeline] = useState<string>();
  const [gitLabPipeline, setGitLabPipeline] = useState<string>();
  useEffect(() => {
    if (!fetchResult?.pipelines) return;
    fetchResult.pipelines.forEach((pipeline) => {
      if (pipeline.platform === 'github') {
        setGitHubPipeline(pipeline.pipeline);
      }
    });
    fetchResult.pipelines.forEach((pipeline) => {
      if (pipeline.platform === 'gitlab') {
        setGitLabPipeline(pipeline.pipeline);
      }
    });
  }, [fetchResult?.pipelines]);
  useEffect(() => {
    if (showResponseModal) return;
    setDisplayFeedBack(true);
    localStorage.setItem('FORM_DATA_mD4NqN', '');
  }, [showResponseModal]);

  return (
    <Modal
      classOverrides={[
        'w-full sm:w-full md:w-145 scale-90 pb-8 px-6 py-4 h-max z-40',
      ]}
      setShowModal={setShowResponseModal}
      showModal={showResponseModal}
    >
      <Title
        size="h2"
        classOverrides={[
          'text-secondary-default font-extrabold text-center pb-4 pt-2',
        ]}
      >
        {t('home.light_pipeline.modal.title')}
      </Title>
      <PlatformToggle
        platformDisplayed={platformDisplayed}
        setPlatformDisplayed={setPlatformDisplayed}
        tooltip={false}
        gitHubBtnClassOverrides={[
          platformDisplayed === 'github'
            ? 'bg-secondary-default dark:bg-dark-primary'
            : 'bg-transparent',
        ]}
        gitLabBtnClassOverrides={[
          platformDisplayed === 'gitlab'
            ? 'bg-secondary-default dark:bg-dark-primary'
            : 'bg-transparent',
        ]}
      />
      <div className="relative rounded-lg shadow bg-gray-highlighter flex  h-88 overflow-auto">
        {gitLabPipeline && gitHubPipeline ? (
          <>
            <Tooltip
              afterShow={() => {
                if (platformDisplayed) copyPipelineCode(platformDisplayed);
                setTimeout(ReactTooltip.hide, 2000);
              }}
              title={t('action:copied')}
              click={true}
              classOverrides={['absolute h-6 w-6 top-3 right-4 ']}
              colorSet={ColorSetEnum.success}
            >
              <button
                className={
                  'text-xl ' +
                  'text-secondary-dark dark:text-gray-primary hover:opacity-70' +
                  'transition-all duration-300 cursor-pointer right-0 -mt-1 -mr-1'
                }
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </Tooltip>

            <SyntaxHighlighter
              language="yaml"
              showLineNumbers={true}
              wrapLongLines
              style={theme === 'light' ? github : solarizedDark}
              className={['rounded-lg shadow-lg']}
              PreTag={'div'}
            >
              {platformDisplayed === 'gitlab' ? gitLabPipeline : gitHubPipeline}
            </SyntaxHighlighter>
          </>
        ) : (
          <SpinnerIcon />
        )}
      </div>
      {displayFeedBack &&
        gitLabPipeline &&
        gitHubPipeline &&
        windowSize.width >= SM_SCREEN_SIZE && (
          <div className="transition-all duration-500">
            <iframe
              src={`https://tally.so/embed/mD4NqN?alignLeft=1&hideTitle=1&transparentBackground=1&projectUrl=${projectUrl}&pipeline=${
                platformDisplayed === 'gitlab'
                  ? Buffer.from(gitLabPipeline).toString('base64')
                  : Buffer.from(gitHubPipeline).toString('base64')
              }`}
              width="100%"
              height="330"
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
              className="min-h-min"
              title="Feedback Pipeline Generator"
            ></iframe>
          </div>
        )}
    </Modal>
  );
};
