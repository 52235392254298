import { SecondaryButton } from '../SecondaryButton/SecondaryButton.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ButtonProps } from '@typings/ButtonProps.type';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside.hook';
import {
  BgColor,
  BorderColor,
  DivideColor,
  TextColor,
  Size,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { motion } from 'framer-motion';

export type DropdownProps = Omit<ButtonProps, 'action'> & {
  items: ButtonProps[];
  disabled?: boolean;
  bold?: boolean;
  light?: boolean;
  bgColor?: BgColor;
  borderColorWhenOpen?: BorderColor;
  textColor?: TextColor;
  itemsTextColor?: TextColor;
  itemsDivideColor?: DivideColor;
  fontSize?: Size;
};

type DropdownItemsProps = {
  items: ButtonProps[];
  onSelect: (item: ButtonProps) => void;
  textColor?: TextColor;
  divideColor?: DivideColor;
  fontSize?: Size;
};

const Dropdown: React.FC<DropdownProps> = ({
  bgColor,
  borderColorWhenOpen,
  textColor,
  itemsTextColor,
  itemsDivideColor,
  classOverrides = [],
  content,
  items,
  disabled,
  bold = true,
  light = false,
  fontSize,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonMessage, setButtonMessage] = useState(content);
  const [mappedItems, setMappedItems] = useState(items);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef(null);
  const onClickOutside = useCallback(() => {
    if (isOpen) setIsOpen(!isOpen);
  }, [isOpen]);
  useOnClickOutside(ref, onClickOutside);

  const onSelectItem = (item: ButtonProps) => {
    if (item.action) item.action(item.content);
    toggleDropdown();
  };

  useEffect(() => {
    setMappedItems(
      items.map((c) => ({
        ...c,
        action: (value) => {
          setButtonMessage(value + '');
          if (c.action) c.action(value);
        },
      })),
    );
  }, [items]);

  useEffect(() => {
    if (buttonMessage !== content) setButtonMessage(content);
  }, [content, buttonMessage]);

  return (
    <div
      ref={ref}
      className={cn(
        textColor,
        isOpen ? 'rounded-t-2lg' : 'rounded-2lg',
        isOpen && 'bg-white',
      )}
    >
      <SecondaryButton
        content={buttonMessage}
        action={toggleDropdown}
        disabled={disabled}
        bold={bold}
        light={light}
        bgColor={bgColor}
        classOverrides={[
          'w-full inline-flex justify-center text-center relative pr-10 border-2 border-transparent',
          `text-${fontSize}`,
          ...classOverrides,
          isOpen && (borderColorWhenOpen ?? ''),
          disabled
            ? 'bg-gray-input text-gray-secondary'
            : bgColor + ' ' + textColor,
        ]}
      >
        <div className="absolute right-4">
          <FontAwesomeIcon
            className={cn(
              'transition-transform duration-300',
              isOpen ? 'transform rotate-180' : '',
            )}
            size={'lg'}
            icon={faCaretDown}
          />
        </div>
      </SecondaryButton>
      <div className="relative">
        {isOpen && (
          <DropdownItems
            fontSize={fontSize}
            items={mappedItems}
            onSelect={onSelectItem}
            textColor={itemsTextColor}
            divideColor={itemsDivideColor}
          />
        )}
      </div>
    </div>
  );
};

const DropdownItems: React.FC<DropdownItemsProps> = ({
  divideColor,
  items,
  fontSize,
  onSelect,
  textColor,
}) => {
  return (
    <motion.div
      className={cn(
        'w-full origin-top-right divide-y absolute z-50' +
          ' rounded-b-md shadow-lg ring-1 ring-black ' +
          'ring-opacity-5 focus:outline-none',
        divideColor ?? 'divide-secondary-default',
        `text-${fontSize}`,
      )}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
      data-testid="dropdown-items"
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {items.map((item, index) => {
        return (
          <div
            key={item.content}
            onClick={(_) => onSelect(item)}
            role="menuitem"
            className={cn(
              'bg-white py-1 font-light w-full text-center cursor-pointer hover:bg-gray-100 ',
              index === items.length - 1 ? 'rounded-b-2lg' : '',
              textColor ?? 'text-secondary-default',
            )}
          >
            {item.content}
          </div>
        );
      })}
    </motion.div>
  );
};

export { Dropdown };
