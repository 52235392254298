import { FooterLinks } from '@components/FooterLinks/FooterLinks.component';
import { FooterSocialNetwork } from '@components/FooterSocialNetwork/FooterSocialNetwork.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OPENCOLLECTIVE_LINK } from '../../constants/general';
import openCollective from '@images/open_collective.svg';
import { motion } from 'framer-motion';
import Plausible from 'plausible-tracker';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { trackEvent } = Plausible();
  return (
    <div
      className={cn(
        theme === 'light'
          ? 'from-primary-default to-secondary-default bg-gradient-to-b relative'
          : 'bg-gradient-to-b dark:to-primary-default-medium-opacity dark:from-secondary-default',
        'w-full h-auto footer z-40',
      )}
    >
      <div className="flex flex-col md:flex-row mt-8 relative">
        <FooterSocialNetwork />
        <div className="md:hidden w-full flex justify-center mt-4">
          <div className="w-60 border-white border-2 rounded-2xl mt-auto" />
        </div>
        <div className="flex justify-center text-center py-4 md:py-0 w-full">
          <div className="flex flex-col xl:w-2/4 ">
            <div className="flex flex-col items-center justify-center xl:flex-row xl:justify-evenly">
              <OutsideLink
                name="open collective link"
                to={OPENCOLLECTIVE_LINK}
                onClick={() => {
                  trackEvent('Click on Open Collective link');
                }}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  'rounded-lg bg-gray-primary py-2 px-5 shadow-xl hover:shadow-button-inner'
                }
              >
                <div className="text-secondary-default font-comfortaa font-bold ">
                  <span
                    className={
                      'text-secondary-default font-comfortaa block text-xs'
                    }
                  >
                    {t('action:support_us')}
                  </span>
                  <motion.img
                    src={openCollective}
                    className="w-28 pt-2"
                  ></motion.img>
                </div>
              </OutsideLink>
              {/* <div className="pt-4 xl:pt-0">
                <span className={'text-white font-comfortaa block'}>
                  {t('action:feedback')}
                </span>
                <OutsideLink
                  name="feedback form"
                  to={FEEDBACK_FORM}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={'text-white font-comfortaa font-bold underline'}
                >
                  {t('action:feedback_link')}
                </OutsideLink>
              </div> */}
            </div>
            <Typography
              size="sm"
              color="text-white"
              classOverrides={['mx-auto hidden md:block md:mt-8 xl:mt-12 mb-4']}
              font={'ubuntu'}
            >
              {t('footer.r2devops_date')}
            </Typography>
          </div>
        </div>
        <div className="md:hidden w-full flex justify-center mb-4">
          <div className="w-60 border-white border-2 rounded-2xl mt-auto" />
        </div>
        <FooterLinks />
      </div>
    </div>
  );
};

export { Footer };
