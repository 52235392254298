import { API_ROUTES, API_URL } from 'common/constants/routes';
import { AutoPipelineConfig } from 'user/components/AddProjectPipelineModal/AddProjectPipelineModal.component';
import { toastError } from '@components/Toasters/Toaster.component';
import { GitHubRepository } from 'common/hooks/useGitHubProjects.hook';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import { Platform } from '@typings/Projects.type';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction, useMutation } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { useLicense } from 'common/hooks/useLicense.hook';
import { UserContext } from 'user/contexts/user.context';

type ReturnType = [
  UseMutateFunction<
    AutoPipelineResponseType,
    Error,
    AutoPipelineConfig,
    unknown
  >,
  AutoPipelineResponseType | null | undefined,
  boolean,
];

export type AutoPipelineResponseType = {
  pipelines: Pipeline[];
  platform: Platform;
  destBranch: string;
  projectIDPlatform: number;
  write: boolean;
  project?: GitHubRepository | GitLabProjectResponse;
  errors?: {
    body: string;
  };
};

export type Pipeline = {
  pipeline: string;
  platform: Platform;
};
export const useOnProjectPipeline = (
  project: GitHubRepository | GitLabProjectResponse,
): ReturnType => {
  const { t } = useTranslation();
  const { identity } = useContext(UserContext);

  const { refetch: refetchUserLicense } = useLicense(identity?.traits.username);
  const generatePipeline = async (autoPipelineConfig: AutoPipelineConfig) => {
    const response = await axios.post(
      API_URL + API_ROUTES.POST_USER_PROJECT_PIPELINE,
      autoPipelineConfig,
      { withCredentials: true },
    );
    return response.data;
  };
  const { mutate, isLoading, data } = useMutation(generatePipeline, {
    onSuccess: () => {
      refetchUserLicense();
    },
    onError: (error: Error) => {
      if (error.message.includes('429')) {
        toastError(error.message);
      } else if (!error.message) {
        toastError(t('toast.unknow_error'));
      } else if (error.message) {
        toastError(t('toast.rate_limited'));
      }
    },
  });
  return [mutate, data, isLoading];
};
