import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { motion } from 'framer-motion';
import { fadeInLeftVariant } from '@utils/motion.util';
import { useTranslation } from 'react-i18next';
import R2_cookie from '@images/R2/R2_cookie.svg';
import { Title } from '@components/Titles/Title.component';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const CookiePopup: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCookieConsent = () => {
    setIsOpen(!isOpen);
  };

  const { t } = useTranslation();

  const [cookieConsent, setCookieConsent] = useState(false);

  // Update Mixpanel tracking whenever the user update his choice
  useEffect(() => {
    if (cookieConsent) {
      mixpanel.opt_in_tracking({ track: () => null });
    } else if (!cookieConsent) {
      mixpanel.opt_out_tracking();
    }
  }, [cookieConsent]);

  // Read value on display, set value if cookie is present
  // In other case show the pop-up
  useEffect(() => {
    const consent = getCookieConsentValue() === 'true';
    setCookieConsent(consent);
    if (getCookieConsentValue() === undefined) {
      setIsOpen(true);
    }
  }, []);

  return (
    <>
      <div
        className="w-44 py-0.5 z-40 rounded-t-lg bg-primary-default fixed bottom-0 shadow-2xl right-12 cursor-pointer"
        onClick={toggleCookieConsent}
      >
        <div className="text-gray-primary text-center">Cookie consent</div>
      </div>
      {isOpen && (
        <div>
          {/* <!-- BEGIN Backdrop part --> */}
          <div
            className="z-60 fixed top-0 left-0 h-screen w-screen bg-opacity-30 bg-black"
            data-testid="backdrop"
          ></div>
          {/* <!-- END Backdrop part --!> */}
          <div className="w-max transition duration-500 filter">
            <CookieConsent
              disableButtonStyles
              onDecline={() => {
                setCookieConsent(false);
                setIsOpen(!isOpen);
              }}
              onAccept={() => {
                setCookieConsent(true);
                setIsOpen(!isOpen);
              }}
              declineButtonClasses="dark:text-gray-primary font-light text-secondary-default ubuntu absolute right-10 top-6 w-max hover:opacity-70"
              buttonClasses="text-sm lg:text-base border-2 border-transparent font-semibold absolute right-14 mr-6 lg:mr-8 lg:right-4 bottom-8 text-gray-primary bg-green-pearl px-4 py-3 rounded-2lg hover:shadow-button-inner transition duration-500 filter shadow-lg ubuntu "
              buttonText={t('action:cookie_accept_btn')}
              declineButtonText={
                <>
                  {t('action:cookie_decline_btn') + '  '}
                  <FontAwesomeIcon icon={faArrowRight} />
                </>
              }
              enableDeclineButton
              debug={true}
              expires={365}
              location="none"
              disableStyles
              containerClasses="px-30 py-4 z-70 fixed filter shadow dark:bg-secondary-default-high-opacity bg-gray-primary mx-auto inset-x-0 top-1/2 transform -translate-y-1/2 rounded-2lg lg:px-12 w-80 lg:w-135"
            >
              <div className="px-8 lg:px-0 py-8 lg:pb-20 flex lg:flex-row flex-col-reverse lg:space-x-5 items-center">
                <motion.img
                  variants={fadeInLeftVariant()}
                  src={R2_cookie}
                  alt={t('cookie_consent.r2_alt')}
                  className="hidden lg:block w-32 ml-8 lg:ml-0 lg:w-40 absolute lg:relative bottom-0"
                />
                <div className="flex flex-col items-center">
                  <Title
                    color="text-secondary-default"
                    bold
                    size="span"
                    classOverrides={['dark:text-gray-primary text-2xl mt-8']}
                  >
                    {t('cookie_consent.title')}
                  </Title>
                  <Typography
                    font="comfortaa"
                    classOverrides={[
                      'dark:text-gray-primary text-secondary-dark text-center mt-2 mb-32 lg:mb-8',
                    ]}
                  >
                    {t('cookie_consent.content')}
                  </Typography>
                  <TrackedLink
                    name={'cookie more informations'}
                    to={'/privacy'}
                  >
                    <SecondaryButton
                      classOverrides={[
                        'text-sm lg:text-base font-semibold dark:bg-transparent dark:border-gray-primary border-primary-default text-primary-default dark:text-gray-primary border-2 absolute mr-0.5 lg:mr-0 right-20 lg:right-60 bottom-24 lg:bottom-8',
                      ]}
                      content={''}
                    >
                      {t('action:cookie_info_btn')}
                    </SecondaryButton>
                  </TrackedLink>
                </div>
              </div>
            </CookieConsent>
          </div>
        </div>
      )}
    </>
  );
};
