import { toastError } from '@components/Toasters/Toaster.component';
import {
  SelfServiceSettingsFlow,
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
} from '@ory/kratos-client';
import {
  KratosSettingsFiltersIn,
  KratosSettingsFiltersOut,
} from 'kratos/filters/settings.filter';
import { newKratosSdk } from 'kratos/helpers/kratos.helper';
import { useKratosFlow } from 'kratos/hooks/useKratosFlow.hook';
import { useKratosFlowReturn } from 'kratos/types/flow.type';
import { useEffect, useState } from 'react';
import { ToastContent } from 'react-toastify';

/**
 * Generate a kratos settings flow
 * @param flowId -- In edge cases, a flow id is given
 * @param onSucceed
 * @see useKratosFlow
 */
export const useKratosSettingsFlowWithProfileMethod = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceSettingsFlow,
    SubmitSelfServiceSettingsFlowWithProfileMethodBody,
    SelfServiceSettingsFlow
  >(
    flowId
      ? () => Sdk.getSelfServiceSettingsFlow(flowId)
      : Sdk.initializeSelfServiceSettingsFlowForBrowsers.bind(Sdk),
    (flowId, data, options) =>
      Sdk.submitSelfServiceSettingsFlow(flowId, undefined, data, options),
    KratosSettingsFiltersIn,
    KratosSettingsFiltersOut,
    onSucceed,
  );
};

export const useKratosSettingsFlowWithOidcMethod = (
  flowId: string | null,
): useKratosFlowReturn<SelfServiceSettingsFlow, void> => {
  const [Sdk] = useState(newKratosSdk());
  const [flow, setFlow] = useState<SelfServiceSettingsFlow | undefined>();

  useEffect(() => {
    if (flow) return;
    const method = flowId
      ? Sdk.getSelfServiceSettingsFlow(flowId)
      : Sdk.initializeSelfServiceSettingsFlowForBrowsers();
    method
      .then((res: any) => setFlow(res.data))
      .catch((err: ToastContent) => toastError(err));
  }, [flow, Sdk, flowId]);

  return [flow, () => {}];
};
