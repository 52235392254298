import React from 'react';
import { Typography } from '@components/Typography/Typography.component';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { LightPipelineGenerator } from '../LightPipelineGenerator/LightPipelineGenerator.component';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pb-4 text-center text-5xl">
        <Title size={'h1'} bold classOverrides={['mb-12']}>
          {t('home.header_title')}
        </Title>
      </div>
      <div className="flex lg:flex-row flex-col justify-evenly items-center w-full xl:mx-24 2xl:mx-48 pt-4 ">
        <div className="flex flex-col items-left mr-2">
          <Title size={'h2'} bold classOverrides={['pb-8 lg:pl-6 text-center']}>
            {t('home.header.title')}
          </Title>
          <Typography
            font={'comfortaa'}
            size={'base'}
            classOverrides={['mx-auto']}
          >
            <ul className="list-disc ml-2 sm:ml-0 inline-block font-light ">
              <li className="text-left  ml-auto max-w-26 mb-6">
                {t('home.header.item.0')}
              </li>
              <div />
              <li className="text-left  ml-auto max-w-26 mb-6">
                {t('home.header.item.1')}
              </li>
              <li className="text-left  ml-auto max-w-26">
                {t('home.header.item.2')}
              </li>
            </ul>
          </Typography>
        </div>
        <LightPipelineGenerator />
      </div>
    </>
  );
};
