import { ArrowIcon } from '@components/Icons/ArrowIcon/ArrowIcon';
import { JobVersionsDetails } from '@jobs/components/JobVersionsDetails/JobVersionsDetails.component';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { JobsList } from 'user/components/JobsList/JobsList.component';
import { ModifyJob } from 'user/components/ModifyJob/ModifyJob.component';
import { MyJobsContext } from 'user/contexts/myJobs.context';

export const Jobs: React.FC = () => {
  const {
    jobToModify,
    showModifyJobForm,
    showVersionsReport,
    setShowVersionsReport,
    jobAnalysisToDisplay,
    numberOfVersionsUpdated,
  } = useContext(MyJobsContext);
  const { t } = useTranslation();

  const jobsCanBeDisplayed = () => {
    if (showModifyJobForm || showVersionsReport) return false;
    return true;
  };
  return (
    <div>
      {/* Pass jobs from context if present, if not those returned from hook */}
      {jobsCanBeDisplayed() && <JobsList />}
      {showModifyJobForm && jobToModify && (
        <motion.div
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
          initial={{ opacity: 0, x: 100 }}
          className="w-11/12 sm:w-10/12  mx-auto pb-14"
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        >
          <ModifyJob job={jobToModify} />{' '}
        </motion.div>
      )}
      <div className="w-11/12 sm:w-10/12  mx-auto pb-14">
        {showVersionsReport && jobAnalysisToDisplay && (
          <div className="w-full flex flex-col">
            <div
              onClick={() =>
                setShowVersionsReport && setShowVersionsReport(false)
              }
              className="hidden mb-4 md:mb-0 md:flex min-w-min items-center cursor-pointer hover:opacity-70 transition-opacity text-gray-secondary dark:text-gray-primary"
            >
              <ArrowIcon
                classOverrides={[
                  'transform rotate-180 mr-4 w-8 h-8 xl:h-12 xl:w-12 ',
                ]}
                strokeWidth="1.5"
                height="45"
                width="55"
              />
              <span className="xl:text-lg">
                {t('action:go_back_to_dashboard')}
              </span>
            </div>
            <div className=" w-full">
              <JobVersionsDetails
                fromJobRefresh
                jobName={jobAnalysisToDisplay.job.name}
                jobAnalysis={jobAnalysisToDisplay.analysis}
                versions={jobAnalysisToDisplay.job.versions}
                numberOfVersionsUpdated={numberOfVersionsUpdated}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
