import React from 'react';
import { useTranslation } from 'react-i18next';

export const TeamIcon: React.FC = () => {
  const { t } = useTranslation();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3315.21 1412.99">
      <defs></defs>
      <g id="Calque_2" data-name="Calque 2">
        <a
          href="https://www.linkedin.com/in/guy-rostan-djounang-nana/"
          rel="noreferrer"
        >
          <title>
            {t('company.team.members.7.name')} /{' '}
            {t('company.team.members.7.role')}
          </title>
          <g id="Guy">
            <rect
              fill="#7d4733"
              x="2774.21"
              y="533.79"
              width="99.23"
              height="89.48"
            />
            <path
              fill="#6e3f2c"
              d="M2873.44,528.08V555.9a47.45,47.45,0,0,1-29.69,14.31c-26.83,2.4-53.06-17.52-69.54-32.94V528l.24.07c12.1,3.6,30.58,7.36,55.22,7.33,17.82,0,31.45-3.39,41.29-7.33Z"
            />
            <path
              fill="#130600"
              d="M2901.53,429.85s5.6-55.22-5.53-67-4.93-15.11-16.94-18.46-12.18-9.52-32-9.52c-3.41,0-5.07-2.23-24-2.23-6.12,0-16.14-5-30.34,2.79s-17.59,1.25-28.24,14.12c-5.69,6.87-14.18,10.82-18,22.14-7.14,21.37-5.87,15.61-3.65,38S2756.81,450,2756.81,450l141.55-4.56Z"
            />
            <path
              fill="#7d4733"
              d="M2756.11,457.44c1.23,14.95-4.61,27.63-13.06,28.33s-16.28-10.86-17.51-25.81,4.61-27.64,13.05-28.33S2754.87,442.49,2756.11,457.44Z"
            />
            <path
              fill="#7d4733"
              d="M2919.28,460.51c-1.77,14.9-10,26.16-18.44,25.16s-13.79-13.89-12-28.79,10-26.16,18.45-25.15S2921.06,445.62,2919.28,460.51Z"
            />
            <path
              fill="#7d4733"
              d="M2903.79,457.59c0,59.3-36.06,107.37-80.53,107.37s-80.52-48.07-80.52-107.37,34.41-91.53,78.89-91.53S2903.79,398.29,2903.79,457.59Z"
            />
            <path
              fill="#d9d9d9"
              d="M3032,1423.78H2848.1c-3.72-48.1-7-97.3-7.45-126.62-.1-5.9,0-14.82,0-14.82L2832,1423.78H2623.1c.16-39.75,2.27-88.22,8-145.64.2-2,.39-3.94.6-5.91q2.91-28.61,7.09-60.14,1.5-11.27,3.17-22.92,1.77-12.39,3.78-25.2,1.46-9.46,3.06-19.17c.49-3,1-6.1,1.52-9.18h0c.91-5.39,1.83-10.82,2.81-16.33q2.79-16,5.92-32.6h340.12s1.46,5.88,3.77,16.48c.82,3.65,1.7,7.85,2.68,12.57a.24.24,0,0,1,0,.08c.17.83.34,1.68.52,2.54,2.06,10.05,4.42,22.21,6.88,36.08,1.92,10.79,3.89,22.59,5.82,35.23,3.18,20.85,6.26,44,8.77,68.52q.61,6,1.19,12.16C3033.29,1318.81,3035.42,1372.05,3032,1423.78Z"
            />
            <path
              fill="#00d6a0"
              d="M2732.29,609.48c8.16-3.06,30-9.27,45.35-3.57s52.16,11.41,69.73,3.57,40.26-8.89,56.13-5.52,60.66,12.64,86.74,34,41.94,424.78,17.85,459c-8.41,12-50.33,52.68-116.72,40.46-53-9.76-113.18,32.91-169.76,3.86-44.21-22.7-79.47-22.94-79.47-22.94S2588.85,762,2618.33,680.75C2618.33,680.75,2621.73,650.81,2732.29,609.48Z"
            />
            <path
              fill="#66676b"
              d="M2840.65,1284.34h-.06a2,2,0,0,1-1.94-2.06c.35-10.31,5.93-17.65,14.38-28.78a272.26,272.26,0,0,0,22.45-33.44,2,2,0,0,1,3.49,2,277.91,277.91,0,0,1-22.75,33.89c-8.28,10.89-13.27,17.46-13.57,26.49A2,2,0,0,1,2840.65,1284.34Z"
            />
            <path
              fill="#00d6a0"
              d="M2733.69,615s-60.75,37.2-87,52.63-43.34,38-49.38,51-20.16,24.92-19.15,33.22S2555,795.75,2550,825.42s-14.11,128.17-14.11,131.73S2552,1042.6,2552,1042.6s47.37,57,57.45,70,40.32,34.41,44.35,34.41,29.23-20.17,28.22-40.35-8.06-78.32-15.12-87.82c-5.52-7.42-12.27-14.12-17.36-24.07a62.42,62.42,0,0,1-3.81-9.16c-5-15.42,9.08-52.21,9.08-52.21s45.35-130.55,43.34-148.35,13.1-80.7,9.07-97.31S2733.69,615,2733.69,615Z"
            />
            <path
              fill="#459d80"
              d="M2755.5,1152.69c-35.43,0-51.48-19.26-52.17-20.1a2,2,0,0,1,3.11-2.52c.24.29,16.69,19.8,52.39,18.56,30.92-1.07,46.5-4.82,59-7.82,2.58-.62,5-1.21,7.49-1.75,22.59-5,53.91-10.85,65.07-4.66a2,2,0,0,1-2,3.5c-11.11-6.17-49.61,2.28-62.26,5.06-2.43.54-4.85,1.12-7.42,1.74-12.7,3-28.5,6.84-59.82,7.93C2757.8,1152.67,2756.64,1152.69,2755.5,1152.69Z"
            />
            <path
              fill="#7d4733"
              d="M2868.53,1135.15l50.33-30.57-3.54-10.29c-2.51-3.58-5.32-7.75-2.08-10.83a11.65,11.65,0,0,1,13-1.71l56.24,28.6,39.78,32.54c-25.84,25.48-44.93,27.44-56.56,24.46-1.22.53-2.43,1.08-3.68,1.57l-43.15,16.64a9.36,9.36,0,0,1-11.54-2.24c-1.86-2.4-3-6.49,5.05-11.37l-8.05,4.88a9.69,9.69,0,0,1-12.12-2.48c-2.43-3.15-.75-7.88,3.89-11.38l-6.26,3.06c-4.51,2.39-10.47,1-12.93-3.29-2.14-3.7-.46-8.25,3.42-10.6l8.89-5.37-10.34,1.14c-5,2.23-11.77,3.06-14.21-1.56-2.09-4-.24-8.7,3.84-11.2Z"
            />
            <path
              fill="#00d6a0"
              d="M2964.91,627.2s74.44,23.31,89.63,36.91,66.85,129.66,66.85,190.56,12.16,154.41,0,172-66.85,104.88-103.31,125-51.4,18.63-51.4,18.63l-8.92-90.66,39-143.41L2977,720.16Z"
            />
            <path
              fill="#459d80"
              d="M2654.79,975.78a2,2,0,0,1-1.95-1.59c-4.56-21.55-2.24-40.92,6.9-57.58,8.41-15.32,13.83-30.83,19.58-47.26,3.36-9.63,6.84-19.58,10.95-29.4a2,2,0,0,1,3.69,1.55c-4.06,9.7-7.52,19.6-10.87,29.17-5.54,15.86-11.27,32.25-19.84,47.87-8.66,15.79-10.85,34.24-6.5,54.83a2,2,0,0,1-2,2.41Z"
            />
            <path
              fill="#459d80"
              d="M2815.84,615.65c-15.2,0-41.09-8.5-42.26-8.89a2,2,0,1,1,1.26-3.8c.3.1,30.25,9.93,43.83,8.56a105.8,105.8,0,0,1,16.2-.47c2.2.08,4.09.15,4.62-.19a2,2,0,0,1,2.16,3.36c-1.58,1-3.59.95-6.92.82a104.69,104.69,0,0,0-15.66.45A30,30,0,0,1,2815.84,615.65Z"
            />
            <path
              fill="#130600"
              d="M2756,376l6.58,9,32.48-5.72,30.13-2,24.52,3.14,25.66,4.44s16.63,15.57,14.42.51-23.4-18.36-44-25C2815.18,350.53,2756,376,2756,376Z"
            />
            <path
              fill="#130600"
              d="M2773.67,542.59c2.46,1.69,24.87,11.8,27.79,11.49,5-.53,10.72-1.73,14.06-5.15,3.83-3.92,2.66-10.33,2.57-15.13,0-1.37.52-5.11-.7-6.33l13.11-.72c-1.17.06,1.13,15.89,1.59,17.2,1.21,3.5,5,5.71,8.88,6.85a47.6,47.6,0,0,0,12.65,1.66c1,0,5.63-1.1,5.79-2-.13.76,17.74-13.11,17.26-12.43-5.8,8.28-27.86,25.3-35.49,29.87,0,0-34.6,0-35.51-.93S2773.71,542.62,2773.67,542.59Z"
            />
            <path
              fill="#130600"
              d="M2792.12,555c-.34-7-1.06-29.31,2.86-35.65l0-.45,1.26-1a36.88,36.88,0,0,1,6.4-3.18,56.58,56.58,0,0,1,23.39-3.53,101.62,101.62,0,0,1,13.86,1.42l1.1.19c1.26.22,2.5.44,3.74.77,3.12.79,7,2.22,9.36,6.19s3.27,8.39,4.1,12.55c.21,1.07.42,2.14.66,3.19,1.42,6.48,1.61,11.89.6,17l-7.11,3c.87-4.49,1.36-13.3.08-19.11-.24-1.09-.46-2.2-.69-3.31-.77-3.92-1.51-7.62-3.49-11-1-1.63-2.44-2.55-5.29-3.28-1-.27-2.07-.44-3.12-.63l-1.14-.2a96.44,96.44,0,0,0-12.8-1.3,49.18,49.18,0,0,0-20.38,3,31.24,31.24,0,0,0-4.74,2.28l-.06.06c-2.08,2.31-2.76,18.86-2.09,32.88Z"
            />
          </g>
        </a>
        <a
          href="https://www.linkedin.com/in/thibaud-van-den-berghe-34000/"
          rel="noreferrer"
        >
          <title>
            {t('company.team.members.4.name')} /{' '}
            {t('company.team.members.4.role')}
          </title>
          <g id="Thibaud">
            <path
              fill="#f2b588"
              d="M2390.88,435.43c.72,13.4,9.61,23.81,19.84,23.26s18-11.87,17.23-25.26-9.61-23.82-19.85-23.26S2390.16,422,2390.88,435.43Z"
            />
            <path
              fill="#5e3c1d"
              d="M2381.13,550.7c-12.61-43.48-4.93-175.06,6.45-189.48s30.35-37.18,81.47-42.57c0,0,21.75.82,36.91,3.11,25.09,3.8,66.39,70.41,68.29,79.68s15.66,115.52,6.83,135.07C2557.56,544.1,2444.15,569.61,2381.13,550.7Z"
            />
            <polygon
              fill="#f8bd8c"
              points="2523.47 505.16 2541.65 571.54 2502.46 617.42 2446.47 589.36 2448.81 519.43 2523.47 505.16"
            />
            <polygon
              fill="#7b839b"
              points="2323.42 1090.98 2323.42 1420.18 2490.38 1420.18 2490.38 1099.32 2334.37 1091.53 2323.42 1090.98"
            />
            <path
              fill="#7b839b"
              d="M2436.11,1106.28l5.71,15.8,5.63,15.6,18.91,52.36,24,66.49,38.56,163.65h151c-.09.46-.12.69-.12.69l20.73.5-.14-1.19-8.33-67-1.21-9.83-3.26-26.3-3.06-24.6-2.3-18.6-10.81-87.06-2.94-23.69-7.05-56.8Z"
            />
            <path
              fill="#00b1d2"
              d="M2537.37,559.35s82.08,50.11,118.26,67.71,59.82,43.34,68.17,58.24,27.82,28.44,26.43,37.92,32,50.11,39,84,19.48,146.26,19.48,150.33-22.26,97.51-22.26,97.51-65.39,65-79.31,79.9-55.65,39.28-61.21,39.28-40.35-23-39-46,11.13-89.38,20.87-100.22c7.62-8.47,16.94-16.12,24-27.47A65.38,65.38,0,0,0,2657,990c7-17.61-12.52-59.59-12.52-59.59s-62.61-149-59.83-169.29-18.08-92.09-12.52-111S2537.37,559.35,2537.37,559.35Z"
            />
            <path
              fill="#00b1d2"
              d="M2316.16,742.11s-13.91,191-12.52,223.53,8.15,51.47-.1,88-4.27,47.41,0,60.95,57.14,12.19,57.14,12.19l247.73-14.43,93.13-134.55-121-376.5-43.13-42s-49.39,42-90.43,16.92c0,0-114.09,64.34-130.09,76.52S2316.16,742.11,2316.16,742.11Z"
            />
            <path
              fill="#00b1d2"
              d="M2315.23,657.76s-39.88,39.73-38.95,65.91-10.21,123.69-8.35,151.68-2.78,28.9-6.49,49.66-19,62.3-20.41,108.35-21.56,79.23-7.65,102.25,24.35,29.8,24.35,29.8,3.47-35.14,9.73-38.56,36.08-12.23,36.08-12.23l33-364.77Z"
            />
            <path
              fill="#eab186"
              d="M2532.45,514.4c4-9.33-26.68,51.65-58,48.22-26.86-2.93-28-52.58-28-52.58s9.53,15.68,37.89,15.72c31.62,0,48.09-11.36,48.09-11.36Z"
            />
            <path
              fill="#f2b588"
              d="M2539.15,427.23c.72,13.4,9.61,23.81,19.84,23.26s18-11.86,17.23-25.26-9.61-23.81-19.85-23.26S2538.43,413.83,2539.15,427.23Z"
            />
            <path
              fill="#f8bd8c"
              d="M2399.24,434.78c9.71,57.05,42.79,106.07,88.38,103.61s69.53-44.93,76.72-112.52c6.31-59.3-42.79-106.06-88.39-103.6S2389.22,375.93,2399.24,434.78Z"
            />
            <path
              fill="#076e81"
              d="M2664.84,844.41a2,2,0,0,0,1.8-2.86c-2.18-4.6-20.39-59.15-20.7-63.35a2,2,0,0,0-4,.29c.37,5,19.13,60.67,21.08,64.78A2,2,0,0,0,2664.84,844.41Z"
            />
            <path
              fill="#076e81"
              d="M2474.24,586.79c7.76,0,17.15-1.44,28.24-5.48,1.45-.53,21.8-10.9,25.87-13a2,2,0,1,0-1.82-3.56c-9.2,4.7-24.24,12.32-25.43,12.79-35.58,13-52.62-2.58-52.78-2.73a2,2,0,0,0-2.77,2.89C2446.09,578.23,2455.33,586.79,2474.24,586.79Z"
            />
            <path
              fill="#5b6274"
              d="M2490.38,1258.54a2.08,2.08,0,0,0,.68-.12,2,2,0,0,0,1.2-2.56l-24-66.5a2,2,0,0,0-3.77,1.36l24,66.5A2,2,0,0,0,2490.38,1258.54Z"
            />
            <path
              fill="#5e3c1d"
              d="M2484.65,336.36s-77.87,99.16-71.78,159.29c4.29,42.33-17.66-14.21-22.25-13-2.3.6-6.84-33.24-.76-69.81s22.76-75.88,22.76-75.88,56-19.09,65.15-17S2484.65,336.36,2484.65,336.36Z"
            />
            <path
              fill="#5e3c1d"
              d="M2481.68,321.76a5.45,5.45,0,0,0-5.19,5.46h0l-2.3,18.95c27.26,13.95,44,32.11,62.93,54,20.06,23.25,11.7,129.26,26.51,87,1.71-4.88,11.27-42,15.1-38.58-3.44-20.32-1.05-33.3-9-54-6.66-17.29-28.84-47.05-41-58.43C2527.28,333.94,2498.61,320.93,2481.68,321.76Z"
            />
            <path
              fill="#5e3c1d"
              d="M2451.29,528.7c-.27-8.49-.74-35.66,3.44-43.43l0-.54,1.33-1.27a37.49,37.49,0,0,1,6.72-3.95,51.94,51.94,0,0,1,24.48-4.6,91.57,91.57,0,0,1,14.45,1.55l1.15.22a40,40,0,0,1,3.9.88c3.25.93,7.3,2.62,9.69,7.42s3.31,10.17,4.13,15.22c.2,1.3.41,2.6.65,3.88,1.4,7.87,1.53,14.45.4,20.72l-6.72-1.18a51.53,51.53,0,0,0-.41-18.38c-.24-1.32-.45-2.66-.68-4-.76-4.76-1.48-9.26-3.51-13.31-1-2-2.51-3.08-5.47-3.92-1.09-.32-2.17-.52-3.26-.73l-1.18-.23a85.27,85.27,0,0,0-13.36-1.43,45.32,45.32,0,0,0-21.32,4,30.31,30.31,0,0,0-5,2.84l-.06.06c-2.2,2.84-3.13,23-2.61,40Z"
            />
            <path
              fill="#5e3c1d"
              d="M2454.36,523.38c2.13,1.66,10.06.39,12.62,0,4.36-.58,9.39-1.82,12.35-5.28,3.39-3.94,2.42-10.35,2.39-15.14,0-1.37.5-5.11-.56-6.32l11.48-.84c-1,.08.85,15.87,1.23,17.18,1,3.49,4.36,5.66,7.71,6.76a36.55,36.55,0,0,0,11.06,1.54c.9,0,9.75-.09,9.9-1a3.4,3.4,0,0,1-1.33,1.84,221.65,221.65,0,0,1-19.61,14.7s-30.27.29-31.06-.61S2454.39,523.4,2454.36,523.38Z"
            />
            <path
              fill="#5e3c1d"
              d="M2490.33,552.2c-41.25,3-78.2-41.41-82.59-99-.41-5.55-.49-11-.37-16.38,5.46,56,41.92,98.75,82.4,95.88,37.19-2.64,65.08-42.84,67-93,.14,1,.2,1.9.27,2.85C2561.42,500.18,2531.58,549.25,2490.33,552.2Z"
            />
          </g>
        </a>
        <a
          href="https://www.linkedin.com/in/ars%C3%A8ne-fougerouse-6a65a01b6/"
          rel="noreferrer"
        >
          <title>
            {t('company.team.members.5.name')} /{' '}
            {t('company.team.members.5.role')}
          </title>
          <g id="Arsene">
            <polygon
              fill="#f8bd8c"
              points="1175.11 511.53 1156.94 577.91 1196.13 623.79 1252.12 595.73 1249.77 525.8 1175.11 511.53"
            />
            <polygon
              fill="#7b839b"
              points="1375.16 1097.35 1375.16 1426.54 1208.2 1426.54 1208.2 1105.69 1364.22 1097.9 1375.16 1097.35"
            />
            <path
              fill="#7b839b"
              d="M1262.47,1112.65l-5.71,15.8-5.62,15.6-18.92,52.36-24,66.49-38.55,163.64H1018.59c.09.47.13.7.13.7l-20.74.5.15-1.2,8.32-67,1.22-9.82,3.26-26.3,3.05-24.61,2.31-18.59,10.8-87.06,3-23.69,7-56.8Z"
            />
            <path
              fill="#00b1d2"
              d="M1161.22,565.71s-82.09,50.11-118.26,67.72-59.83,43.34-68.17,58.24S947,720.11,948.35,729.59s-32,50.11-39,84-19.48,146.26-19.48,150.33,22.26,97.51,22.26,97.51,65.39,65,79.3,79.9,55.65,39.28,61.22,39.28,40.34-23,39-46-11.13-89.38-20.87-100.22c-7.61-8.47-16.93-16.12-24-27.47a64.54,64.54,0,0,1-5.25-10.45c-7-17.61,12.52-59.59,12.52-59.59s62.61-149,59.82-169.29,18.09-92.1,12.52-111.06S1161.22,565.71,1161.22,565.71Z"
            />
            <path
              fill="#00b1d2"
              d="M1382.43,748.47s13.91,191,12.52,223.54-8.15,51.47.1,88,4.27,47.4,0,61-57.14,12.19-57.14,12.19l-247.74-14.43L997.05,984.2l121-376.5,43.13-42s49.39,42,90.43,16.93c0,0,114.08,64.33,130.08,76.52S1382.43,748.47,1382.43,748.47Z"
            />
            <path
              fill="#00b1d2"
              d="M1383.35,664.13s39.89,39.73,39,65.91,10.2,123.69,8.35,151.68,2.78,28.9,6.49,49.66,19,62.3,20.41,108.35,21.56,79.23,7.65,102.25-24.35,29.79-24.35,29.79-3.48-35.13-9.74-38.56-36.07-12.22-36.07-12.22l-33-364.77Z"
            />
            <path
              fill="#eab186"
              d="M1166.14,520.76c-4-9.32,26.68,51.66,58,48.23,26.87-2.93,28-52.58,28-52.58s-9.54,15.68-37.89,15.72c-31.62,0-48.09-11.37-48.09-11.37Z"
            />
            <path
              fill="#f2b588"
              d="M1307.71,441.8c-.73,13.39-9.61,23.81-19.85,23.26s-17.95-11.87-17.22-25.27,9.61-23.81,19.84-23.26S1308.43,428.4,1307.71,441.8Z"
            />
            <path
              fill="#f2b588"
              d="M1159.44,433.6c-.73,13.4-9.61,23.81-19.85,23.26s-17.94-11.86-17.22-25.26,9.61-23.81,19.84-23.26S1160.16,420.2,1159.44,433.6Z"
            />
            <path
              fill="#f8bd8c"
              d="M1299.35,441.15c-9.71,57.05-42.79,106.07-88.39,103.6s-69.52-44.92-76.71-112.51c-6.31-59.3,42.79-106.06,88.38-103.6S1309.37,382.3,1299.35,441.15Z"
            />
            <path
              fill="#ffd146"
              d="M1194.71,351.07c.13,3.77-.45,9.85-.08,11.67,1,5.05,3.66,9.95,7.95,12.28,3,1.61,6.38,1.86,9.71,1.94,12.11.32,24.76-4.89,36.38-4.36,5.35.25,9.41,4.13,13.95,7.16,8.39,5.6,23.85,4.29,23.85,4.29,7.93,11.07,8.69,27.57,16.76,37.8a49.32,49.32,0,0,0,5.52-4.34c10.86-9.13,11.5-10.8,8-25.14-2.82-11.48,4.9-16.38,8.81-25.57,5.36-12.62,14.69-24.3,5.19-34.14-12.35-12.78-21.33-23-29.2-39.77-4.39-9.33-10.28-19.52-19.91-21.32-15.53-2.89-27.35,14.17-43,3.76-5.69-3.79-11.16-8.68-17.83-9.53-6.92-.89-13.68,2.8-19.31,7.25-7,5.54-13.41,12.06-21.61,15.62-13.53,5.88-29.08,8.23-40.19,19.21-7.42,7.34-11.52,17.3-15.27,27.12-3.17,8.32-8.79,17.89-9.62,26.83-.93,10.08,4.94,31.65,12.91,38,3.85,3.08,6.69,10.43,6.69,10.43s12.25-22.55,14.31-27.7,12.15-18.46,19.11-23.34,9.27-6.67,14.76-9.24C1186.21,348.31,1194.47,344.3,1194.71,351.07Z"
            />
            <path
              fill="#076e81"
              d="M1033.75,850.78a2,2,0,0,1-.85-.19,2,2,0,0,1-1-2.67c2.18-4.6,20.4-59.15,20.71-63.36a2,2,0,0,1,4,.3c-.37,5-19.14,60.67-21.08,64.77A2,2,0,0,1,1033.75,850.78Z"
            />
            <path
              fill="#076e81"
              d="M1224.35,593.16c-7.76,0-17.16-1.44-28.25-5.48-1.45-.53-21.8-10.9-25.86-13a2,2,0,1,1,1.81-3.56c9.2,4.69,24.24,12.32,25.44,12.79,35.58,13,52.61-2.58,52.78-2.74a2,2,0,0,1,2.76,2.9C1252.5,584.6,1243.26,593.16,1224.35,593.16Z"
            />
            <path
              fill="#5b6274"
              d="M1208.21,1264.91a2,2,0,0,1-1.88-2.69l24-66.49a2,2,0,1,1,3.76,1.36l-24,66.49A2,2,0,0,1,1208.21,1264.91Z"
            />
          </g>
        </a>
        <a
          href="https://www.linkedin.com/in/alexandre-burgoni/"
          rel="noreferrer"
        >
          <title>
            {t('company.team.members.3.name')} /{' '}
            {t('company.team.members.3.role')}
          </title>
          <g id="Alexandre">
            <polygon
              fill="#f8bd8c"
              points="1950.74 821.57 1754 831.2 1796.35 544.89 1896.99 544.89 1950.74 821.57"
            />
            <path
              fill="#7f522d"
              d="M1944.94,423.42s9.16-89.69-4.94-104.52-6.24-19.07-21.45-23.31-15.43-12-40.5-12c-4.32,0-6.43-2.82-30.41-2.82-7.76,0-20.45-6.36-38.43,3.53s-29.43-3.88-42.39,12.8-17.89,7.68-29.17,33.81,3.52,38.85,6.34,67.1,17.65,50.85,17.65,50.85l179.28-5.76Z"
            />
            <path
              fill="#eab186"
              d="M1897.47,542.9l-.15,30.11c-7.58,7.35-16.54,14-29.55,15.11-27.93,2.41-54.94-14.82-72.11-30.24l-.31-11.93,1.84.31c12.6,3.6,30.26,7.13,55.92,7.1,18.56,0,32.75-3.4,43-7.34Z"
            />
            <path
              fill="#f8bd8c"
              d="M1931.85,453.31c0,63.91-38.48,115.72-85.94,115.72S1760,517.22,1760,453.31c0-26.53-6.37-67.19,4.78-86.71,15.71-27.5,53.4-29,81.16-29,27.61,0,60.3-6.43,76,20.82C1933.23,378,1931.85,426.58,1931.85,453.31Z"
            />
            <path
              fill="#f8bd8c"
              d="M1777.45,459.81c1.12,13.57-5.06,25.16-13.79,25.88s-16.72-9.7-17.83-23.28,5.06-25.17,13.79-25.89S1776.34,446.23,1777.45,459.81Z"
            />
            <path
              fill="#f8bd8c"
              d="M1912.87,458.81c-2,13.48,3.46,25.44,12.13,26.71s17.3-8.63,19.27-22.11-3.47-25.44-12.14-26.71S1914.84,445.33,1912.87,458.81Z"
            />
            <path
              fill="#7f522d"
              d="M1749.44,347.48s19.23,27.67,37.17,22.81,28.12-25.38,36.62-16.09,23.22,7.78,34.73.54,7.74,2.06,22.84,4.43,22.44-2.32,33.5,11.7,22.38,7.32,18.6-11.7-29.45-59.85-52.1-48.18S1749.44,347.48,1749.44,347.48Z"
            />
            <path
              fill="#1a1a1a"
              d="M1931.61,413.56c-.37-.11-.75-.19-1.12-.28a115.1,115.1,0,0,0-18.54-2.56,172.64,172.64,0,0,0-19.56-.07h0c-12.43.63-23.63,2.47-30.25,4.64-5.37,1.78-11.7,4.3-17.09,4.3s-11.71-2.52-17.09-4.3c-7.19-2.37-19.81-4.33-33.53-4.79h0A163.9,163.9,0,0,0,1774,411a103.07,103.07,0,0,0-15.6,2.54h0c-1.52.37-3,.79-4.42,1.24l.7,12.8a5,5,0,0,1,3.61.75,4.57,4.57,0,0,1,.83.77,5.79,5.79,0,0,1,1.13,2.18c.32,1.07.74,2.91,1.27,5.21.79,3.42,1.81,7.85,3.12,12.21,1.59,5.34,3.61,10.57,6.1,13.73a19.6,19.6,0,0,0,6.6,5.27c8,4.11,19,4.22,27.08,3.88,14.32-.62,23.71-7,27.71-17.79,1-2.56,1.8-5,2.57-7.34,2.52-7.48,4.3-13.4,6.25-15.63a6,6,0,0,1,8.32,0c1.89,2.16,3.63,7.81,6,15,.84,2.51,1.77,5.2,2.8,8,4,10.79,13.39,17.17,27.71,17.79,8,.33,18.93.23,26.93-3.81a19.57,19.57,0,0,0,6.75-5.34c3.3-4.18,5.76-12,7.54-18.94.63-2.43,1.17-4.75,1.63-6.74.55-2.42,1-4.36,1.32-5.47a5.57,5.57,0,0,1,1.58-2.65,2.39,2.39,0,0,1,.42-.31,5,5,0,0,1,3.58-.74l.69-12.8C1934.69,414.36,1933.18,413.94,1931.61,413.56Zm-106,40.28c-3.85,7.25-10.32,11-20,12.18s-21.3-.66-27.14-4.15a10.09,10.09,0,0,1-1-.66c-3.22-2.6-5.29-7.86-6.64-12.67-.53-1.92-1-3.77-1.29-5.34-.18-.87-.38-1.88-.57-3h0c-1.12-6.41-1.92-16.11,3.19-19.65,6-4.16,21.09-4.47,31.1-3.86,7.54.47,23.85,2.47,26.93,8.33,2.22,4.21.48,14.22-1.83,21.69A44.68,44.68,0,0,1,1825.64,453.84Zm94.82-10.64-.12.55c-1.18,5.49-3.42,13.83-7.73,17.38a7.45,7.45,0,0,1-1,.74c-5.85,3.49-17.45,5.39-27.14,4.15s-16.17-4.93-20-12.18a47.08,47.08,0,0,1-3.1-8.19c-2.13-7.34-3.62-16.63-1.52-20.64,3.09-5.86,19.4-7.86,26.94-8.33,10-.61,25.1-.3,31.1,3.86,5.41,3.75,4.19,14.42,3,20.77C1920.71,442,1920.57,442.63,1920.46,443.2Z"
            />
            <polygon
              fill="#7b839b"
              points="2032.64 1232.36 2032.64 1427.55 1833.73 1427.55 1832 1419.84 1831.27 1418.5 1792.17 1346.66 1747.31 1265.36 1739.92 1251.94 1739.08 1250.42 1729.11 1232.36 2032.64 1232.36"
            />
            <path
              fill="#7b839b"
              d="M1843.48,1265c-3.56,16.62-8.08,81.81-12.21,153.5-.16,3-.34,6-.51,9H1651.07c-2-109.07,1.69-213.83,1.69-213.83s1.69.08,4.75.27l5.72.37.66.05h.11c16.31,1.12,48.16,3.7,80.58,8.5,16.94,2.51,34,5.62,49.16,9.45,21.21,5.36,38.53,12.14,46,20.61a21,21,0,0,1,1.42,1.79h0c.28.4.54.8.78,1.21A12.28,12.28,0,0,1,1843.48,1265Z"
            />
            <path
              fill="#66676b"
              d="M2066.24,1050.08c-8.62,71.35-3.45,150.21-12.07,185.88-3.11,12.84-48.67,30.26-107.73,32.09-44.73,1.39-97.08-14.77-141.54-16.07l-10-.28c-55.12-1.4-97.69-.72-97.69-.72S1626.57,721.51,1649,697.1c19.66-21.4,109.57-52.88,133.69-65.85,0,0,36,45.86,69.7,45.86s59.39-54.34,59.39-54.34l80.34,19s58.62,34.73,60.35,40.36S2074.86,978.73,2066.24,1050.08Z"
            />
            <path
              fill="#5b6274"
              d="M1963.92,1271.07a428.2,428.2,0,0,1-81.81-7.7c-74.64-14.65-125.35-10.07-125.85-10a2,2,0,1,1-.38-4c.51,0,51.8-4.68,127,10.07,76.48,15,142.75,3.15,143.35,3a2,2,0,0,1,.9,3.89C2026.77,1266.44,2001.46,1271.07,1963.92,1271.07Z"
            />
            <path
              fill="#5b6274"
              d="M1859.18,1384.1a2,2,0,0,1-2-2v-54a1.84,1.84,0,0,1,0-.41l6.35-29.91a2,2,0,1,1,3.91.83l-6.3,29.7v53.81A2,2,0,0,1,1859.18,1384.1Z"
            />
          </g>
        </a>
        <a href="https://www.linkedin.com/in/bonithomas/" rel="noreferrer">
          <title>
            {t('company.team.members.1.name')} /{' '}
            {t('company.team.members.1.role')}
          </title>

          <g id="Thomas">
            <path
              fill="#d9d9d9"
              d="M2408.82,1425.29H2026.35l6.76-209.5.19-6.33,0-1.32,314.52-16.05,45.16-2.29,1.31,19.54,5.77,86v.13l2.09,31.24h0l.92,13.6Z"
            />
            <path
              fill="#d9d9d9"
              d="M2231.92,1425.29H2029.1l3.64-212.91.07-4.24,3.48,1.32,37.11,14,92.19,34.93.16,0,61.06,23.13Z"
            />
            <polygon
              fill="#f8bd8c"
              points="2275.75 655.91 2153.03 670.29 2150.02 587.67 2149.82 581.98 2149.42 571.11 2249.82 559.34 2256.35 558.58 2261.84 586.13 2275.75 655.91"
            />
            <path
              fill="#076e81"
              d="M2139.28,643.21s-126.71,59.58-130.23,71.5-17.59,45.67-17.59,45.67,61.59,168.81,45.75,226.4-5.28,152.92-5.28,182.71-10.56,41.7,8.8,49.65,82.71,4,117.91,0,107.35,1.22,123.19,4.58,110.87-14.51,110.87-14.51,12.32-75.47,8.8-113.2,1.26-127.1.63-141,6-19.86.06-53.62,23.94-218.46,23.94-218.46l-146.06-60.07Z"
            />
            <path
              fill="#076e81"
              d="M2054.17,685.37s54.13,23.66,49.17,263.25l197.22-51.08,24.18,112.75S2108.3,1116,2052.13,1114.22s-92.52-290.68-81-322.39S1985.92,715,2052,686.76Z"
            />
            <polygon
              fill="#f8bd8c"
              points="2327.92 903.25 2385.26 881.79 2371.03 968.37 2326.22 970.62 2316.4 909.3 2327.92 903.25 2327.92 903.25"
            />
            <polygon
              fill="#f8bd8c"
              points="2149.14 939.16 2102.28 949.96 2100.41 938.61 2097.31 919.78 2100.52 920.98 2149.14 939.16 2149.14 939.16"
            />
            <path
              fill="#076e81"
              d="M2380.06,697.82l-32.54,261.35-206.64-31.36-24.41,115s279.86,76.66,335.17,55.75,29.28-308.37,14.65-345-24.54-65.2-44.05-72.17-42.18,16.41-42.18,16.41Z"
            />
            <path
              fill="#eab186"
              d="M2261.84,586.13c-4.91,11.26-13.46,21.71-26.69,26.46-29.18,10.49-65.66-11.41-85.13-24.92l-.21-5.69c1.69.26,3.59.49,5.73.67,12.86,1.07,32,.72,56.16-5.31,17.26-4.32,29.59-11.29,38.12-18l6.53-.76Z"
            />
            <path
              fill="#f8bd8c"
              d="M2276.05,471.93c5.36,67.69-26.63,125.58-76.34,129.51S2105.38,553.68,2100,486s30.61-125.76,80.31-129.69S2270.7,404.24,2276.05,471.93Z"
            />
            <path
              fill="#684423"
              d="M2137.45,322.3s-.57,50.32,12,57.6,26.85-6,38.23-7.95,34.4-3.94,50.52,10.11,30,55,30,55l3.54,13.74s9.43,2.37,10.42-10.54-1.65-67,0-72.32,1-10.59-5-16.55-13.57-15.22-11.26-19.2,12.25-14.23,2.65-23.16-55.94-14.57-64.54-6.62S2163.27,279.94,2137.45,322.3Z"
            />
            <path
              fill="#684423"
              d="M2128.68,326.11s-45.18,5-46.17,44.68c0,0-5.47,8.94-16.39,23.34s2,38.73,5,53.12,9.1,26.32,8.77,30.62,7.93,12.58,15.38,9.27,9.11-12.14,9.11-12.14.66-56.05,8.94-66,22.34-19.69,30.45-23.83,9.93-11.91,9.93-12.91S2143.41,317,2143.41,317Z"
            />
            <path
              fill="#f8bd8c"
              d="M2118.74,496.27c6.22,12.8,3.94,26.74-5.11,31.14S2092.22,525,2086,512.2s-3.94-26.74,5.1-31.14S2112.51,483.48,2118.74,496.27Z"
            />
            <path
              fill="#f8bd8c"
              d="M2294.14,478.77c-1.63,14.14-11,24.67-21,23.52s-16.77-13.54-15.14-27.68,11-24.67,21-23.52S2295.77,464.63,2294.14,478.77Z"
            />
            <path
              fill="#666b6d"
              d="M2232.29,1385.72a2.17,2.17,0,0,1-2.17-2.12c-.85-35.87-3-94.16-5.39-100.39-7.88-4.7-46.78-16.84-66.56-22.69a2.17,2.17,0,1,1,1.23-4.16c2.4.71,58.88,17.46,68.06,23.45,1.44.93,3.22,2.09,5.36,53.58,1,24.92,1.63,49.86,1.64,50.11a2.18,2.18,0,0,1-2.12,2.22ZM2224.62,1283Z"
            />
            <path
              fill="#035a69"
              d="M2099.49,952.13a2.13,2.13,0,0,1-1.28-.42,2.18,2.18,0,0,1-.89-1.64c-.31-6.45-9-109.38-9.08-110.42a2.17,2.17,0,1,1,4.32-.37c.33,4,7.64,90.57,8.91,107.91,12.14-3.26,64.32-17.14,85.19-21.27a2.17,2.17,0,1,1,.84,4.25c-23.43,4.64-86.81,21.71-87.45,21.88A1.77,1.77,0,0,1,2099.49,952.13Z"
            />
            <path
              fill="#035a69"
              d="M2056.38,1112a43.14,43.14,0,0,1-15.42-2.4c-26.17-10.18-44.08-93.74-44.82-97.29a2.21,2.21,0,0,1,1.32-2.63,1.82,1.82,0,0,1,2.21,1.57c.18.85,18.16,84.79,42.43,94.23,24.64,9.59,84-15.4,84.61-15.66a1.77,1.77,0,0,1,2.33,1.31,2.29,2.29,0,0,1-1.1,2.77C2125.87,1094.8,2085,1112,2056.38,1112Z"
            />
            <path
              fill="#035a69"
              d="M2212.59,1063a2.17,2.17,0,0,1-1-4.1l66-33.05a2.16,2.16,0,1,1,1.94,3.87l-66,33A2.16,2.16,0,0,1,2212.59,1063Z"
            />
            <path
              fill="#035a69"
              d="M2367,960.26l-.35,0a2.18,2.18,0,0,1-1.8-2.49L2383.12,844a2.17,2.17,0,0,1,4.28.69l-18.23,113.72A2.17,2.17,0,0,1,2367,960.26Z"
            />
            <path
              fill="#035a69"
              d="M2399.06,1100.57a1.83,1.83,0,0,1-.33,0l-109.25-17a2.17,2.17,0,0,1,.66-4.28l109.25,17a2.17,2.17,0,0,1-.33,4.31Z"
            />
          </g>
        </a>
        <a
          href="https://www.linkedin.com/in/riccardo-pons-a3b730170/"
          rel="noreferrer"
        >
          <title>
            {t('company.team.members.6.name')} /{' '}
            {t('company.team.members.6.role')}
          </title>
          <g id="Riccardo">
            <polygon
              fill="#d6a07c"
              points="449.15 535.7 430.98 602.08 470.17 647.96 526.16 619.89 523.81 549.97 449.15 535.7"
            />
            <polygon
              fill="#7b839b"
              points="649.21 1121.52 649.21 1450.71 482.25 1450.71 482.25 1129.86 638.26 1122.07 649.21 1121.52"
            />
            <path
              fill="#7b839b"
              d="M536.51,1136.82l-5.71,15.8-5.62,15.59-18.91,52.37-24,66.49-38.56,163.64h-151l.12.7-20.74.5.15-1.2,8.32-67,1.22-9.83,3.26-26.29L288,1323l2.3-18.6,10.81-87,2.94-23.69,7.05-56.8Z"
            />
            <path
              fill="#66676b"
              d="M435.26,589.88S353.17,640,317,657.6s-59.82,43.34-68.17,58.23S221,744.28,222.39,753.76s-32,50.11-38.95,84S164,984,164,988.05s22.26,97.52,22.26,97.52,65.39,65,79.3,79.9,55.65,39.28,61.22,39.28,40.35-23,39-46-11.14-89.39-20.87-100.22c-7.62-8.47-16.94-16.12-24-27.47a65.38,65.38,0,0,1-5.26-10.45c-7-17.61,12.52-59.59,12.52-59.59S390.74,812,388,791.68s18.08-92.1,12.52-111.06S435.26,589.88,435.26,589.88Z"
            />
            <path
              fill="#66676b"
              d="M656.47,772.64s13.91,191,12.52,223.54-8.15,51.46.1,88,4.27,47.4,0,61S612,1157.35,612,1157.35l-247.74-14.43-93.12-134.55,121-376.5,43.13-42s49.39,42,90.43,16.93c0,0,114.08,64.33,130.08,76.52S656.47,772.64,656.47,772.64Z"
            />
            <path
              fill="#66676b"
              d="M657.4,688.3s39.88,39.72,39,65.91,10.21,123.69,8.35,151.68,2.78,28.89,6.49,49.66,19,62.3,20.41,108.35,21.56,79.22,7.65,102.25-24.35,29.79-24.35,29.79-3.48-35.13-9.74-38.56-36.07-12.22-36.07-12.22l-33-364.77Z"
            />
            <path
              fill="#c59472"
              d="M440.18,544.93c-4-9.33,26.68,51.65,58,48.23,26.87-2.93,28-52.58,28-52.58s-9.54,15.68-37.89,15.71c-31.62.05-48.09-11.36-48.09-11.36Z"
            />
            <path
              fill="#352b23"
              d="M577.24,466.44s8.22-89.69-4.42-104.52-5.59-19.07-19.22-23.31-13.83-12-36.3-12c-3.87,0-5.76-2.82-27.25-2.82-6.95,0-18.33-6.36-34.44,3.53s-26.38-3.88-38,12.8-16,7.68-26.14,33.81,3.15,38.85,5.68,67.1S413,491.86,413,491.86l160.66-5.75Z"
            />
            <path
              fill="#d6a07c"
              d="M581.75,466c-.73,13.4-9.61,23.82-19.85,23.26S544,477.36,544.68,464s9.61-23.81,19.84-23.26S582.47,452.56,581.75,466Z"
            />
            <path
              fill="#d6a07c"
              d="M433.48,457.77c-.72,13.4-9.61,23.81-19.84,23.26s-17.95-11.87-17.23-25.26S406,432,416.25,432.51,434.2,444.37,433.48,457.77Z"
            />
            <path
              fill="#d6a07c"
              d="M573.39,465.32c-9.71,57.05-42.79,106.06-88.38,103.6S415.48,524,408.29,456.41c-6.31-59.3,42.79-106.07,88.38-103.61S583.41,406.47,573.39,465.32Z"
            />
            <path
              fill="#525255"
              d="M307.79,875a1.86,1.86,0,0,1-.85-.2,2,2,0,0,1-.95-2.66c2.17-4.6,20.39-59.16,20.7-63.36a2,2,0,0,1,4,.3c-.37,5-19.13,60.66-21.08,64.77A2,2,0,0,1,307.79,875Z"
            />
            <path
              fill="#525255"
              d="M498.39,617.33c-7.76,0-17.15-1.44-28.24-5.48-1.46-.53-21.81-10.9-25.87-13a2,2,0,1,1,1.82-3.56c9.2,4.69,24.24,12.32,25.43,12.79,35.58,13,52.61-2.58,52.78-2.74a2,2,0,0,1,2.83.08,2,2,0,0,1-.07,2.82C526.54,608.77,517.3,617.33,498.39,617.33Z"
            />
            <path
              fill="#5b6274"
              d="M482.25,1289.07a2.08,2.08,0,0,1-.68-.12,2,2,0,0,1-1.2-2.56l24-66.49a2,2,0,1,1,3.76,1.36l-24,66.49A2,2,0,0,1,482.25,1289.07Z"
            />
            <path
              fill="#352b23"
              d="M391.09,380.43s19.24,31.1,37.17,25.64,28.13-28.53,36.62-18.09,23.23,8.75,34.74.61,7.73,2.32,22.84,5S544.89,391,556,406.71s22.38,8.23,18.61-13.14-25.59-49.68-52.1-54.14C467.5,330.18,391.09,380.43,391.09,380.43Z"
            />
            <path
              fill="#352b23"
              d="M406.07,360.15s-2.94,24,13.37,31.92,34.44-28.17,49.25-30.34,55.1,9.37,76.1,24.7,24.52,24.18,24.52,24.18l9.36,43.87s.77,5.36,5.2-7.94c4.16-12.48,6.17-61.69,8.32-67.47s1.3-11.55-6.46-18.05-17.67-16.61-14.65-20.95-1.75-14.63-14.25-24.38-55.14-16.79-66.34-8.12S439.69,313.93,406.07,360.15Z"
            />
            <path
              fill="#459d80"
              d="M3012.06,893a2.58,2.58,0,0,1-4-1.69c-1.07-6.46-21.76-77.41-24.51-82.08a2.57,2.57,0,1,1,4.43-2.6c3.27,5.54,24.19,78.07,25.15,83.84A2.56,2.56,0,0,1,3012.06,893Z"
            />
          </g>
        </a>
        <a href="https://www.linkedin.com/in/sandra-salerno/" rel="noreferrer">
          <title>
            {t('company.team.members.2.name')} /{' '}
            {t('company.team.members.2.role')}
          </title>
          <g id="Sandra">
            <path
              fill="#eab186"
              d="M1000,1253.77s24.45,7.28,29.29,8.33c0,0-8.38,34.52-25.57,42.94l-.41.21Z"
            />
            <path
              fill="#076e81"
              d="M978.82,702.09s6.38-4.88,14.56,2.31c43.56,38.31,39.37,105,38.76,122.06s15,79.5,23.28,102.91,18.2,37.27,24.29,78,0,121.31.68,131.24,8.67,58.15,12.84,71.37c3.12,9.89-.48,39.91-11.34,44.74s-17.9,11.11-57.84,6.17-29.71-18.19-30.36-36.63-6.46-34.9-6.63-43.12-1.77-23.71-1.69-44.33,1.5-12.6-2.54-32.56-33-120.92-22.5-145.78S931.53,850.29,932.16,844s-9.47-63.39-9.47-63.39,8.61-26.88,22.31-46.07,31.85-29.82,33.82-32.46Z"
            />
            <path
              fill="#d9d9d9"
              d="M829.19,1051.92v76l-1.64,76.76-.25,11.73s0,0,0,0l-4.51,211.63H639.94l17.84-215.29,2-23.88.54-6.56,1-11.72h0l3.8-45.89,3.66-44.14,2.37-28.64Z"
            />
            <path
              fill="#d9d9d9"
              d="M1015.16,1428.05H832.31l-4.6-215.46s0,0,0,0l-.16-7.89-1.64-76.76v-76H984l9.28,111.91h0l1.29,15.69.15,1.73v.06l.84,10.11h0l5.19,62.57.06.78,4.11,49.59Z"
            />
            <path
              fill="#963"
              d="M648.81,746s-10.34-92,14.93-139.21S681,519,681,497.08s4.59-91.12,26.41-116.43,31-81,102.2-60.74c0,0,18.37-15.19,65.45-6.75s66.6,96.18,68.9,114.74,6.89,57.37,18.37,75.93,23,60.75,14.93,102.93-8.41,50.62,2.68,89.43S985.26,746,985.26,746,850.91,824.43,648.81,746Z"
            />
            <polygon
              fill="#f8bd8c"
              points="880.18 666.98 768.09 666.98 779.5 588.76 780.27 583.52 781.87 572.54 866.39 572.54 868.2 584.88 871.5 607.48 880.18 666.98"
            />
            <path
              fill="#eab186"
              d="M871.5,607.48c-6.37,8.33-15.78,15.38-28.08,16.57-25.54,2.49-50.45-20.75-63.92-35.29l.77-5.24c1.16.46,2.45.92,3.9,1.39,10.35,3.36,26.15,6.84,47.19,6.82,15.24,0,26.88-3.17,35.31-6.82l1.53,0Z"
            />
            <ellipse
              fill="#f8bd8c"
              cx="820.32"
              cy="471.71"
              rx="95.61"
              ry="129.34"
            />
            <path
              fill="#f8bd8c"
              d="M754,494.34c4.94,13.41.66,27.55-9.56,31.58s-22.52-3.57-27.46-17-.66-27.55,9.56-31.58S749.06,480.93,754,494.34Z"
            />
            <path
              fill="#f8bd8c"
              d="M899.5,494.35c-4.93,13.41-.64,27.54,9.58,31.57s22.51-3.58,27.45-17,.65-27.55-9.58-31.58S904.44,480.93,899.5,494.35Z"
            />
            <path
              fill="#963"
              d="M809.57,354s-56.27,80.36-64.31,122.14-54,28.72-58.56,29.91,27.56-133,27.56-133l95.31-41.57Z"
            />
            <path
              fill="#963"
              d="M809.57,326.21V354a328.32,328.32,0,0,0,47.34,73C877,450.28,902,470.55,926.54,488.54c4.17,3.06,10.27,6.39,14.1,9.8-3.44-20.32-9.73-40.58-13.58-60.83-3.45-18.2-5.67-37.55-11.28-55.62C914.06,379.22,809.57,326.21,809.57,326.21Z"
            />
            <path
              fill="#076e81"
              d="M761.59,612.45s-5.2,10.22-5.2,19.9,8.32,35.51-13.52,36.58-93.53,48.42-112.28,62.4S617,775.44,617,775.44s38.48,389.72,44.72,396.17,95.94-86.33,129.48-82.3,22.88,11.57,36.4,10.49,18.72-11.83,22.88-11.3,18.2,3.23,25,8.07,32.75-2.69,50.43,13.45,88.4,69.93,88.4,69.93,9.36-76.38-6.24-100,6.24-232.38,0-239.91-11.44-133.4-11.44-133.4-65.78-35.24-86.84-36-15.6-51.64-15.6-51.64Z"
            />
            <path
              fill="#1a1a1a"
              d="M728.69,544.29a11.41,11.41,0,0,1-9.35-4.85,14,14,0,0,1-.58-14.27,11.7,11.7,0,0,1,10.34-6.59h0a.55.55,0,0,1,0,1.1,10.46,10.46,0,0,0-9.36,6,12.86,12.86,0,0,0,.5,13.14,10.3,10.3,0,0,0,8.85,4.36,9.39,9.39,0,0,0,7.91-4.72.55.55,0,1,1,1,.55,10.46,10.46,0,0,1-8.81,5.27Z"
            />
            <path
              fill="#1a1a1a"
              d="M912.05,544.61a11.42,11.42,0,0,0,9.35-4.84A14,14,0,0,0,922,525.5a11.71,11.71,0,0,0-10.34-6.6h0a.55.55,0,0,0-.55.55.55.55,0,0,0,.55.55,10.47,10.47,0,0,1,9.36,6,12.84,12.84,0,0,1-.5,13.13,10.3,10.3,0,0,1-8.85,4.36,9.38,9.38,0,0,1-7.91-4.71.54.54,0,0,0-.75-.2.55.55,0,0,0-.2.75,10.48,10.48,0,0,0,8.81,5.26Z"
            />
            <path
              fill="#f8bd8c"
              d="M776.93,1150.59,726.6,1120l3.54-10.28c2.51-3.59,5.32-7.76,2.08-10.83a11.63,11.63,0,0,0-13-1.72L663,1125.78l-39.78,32.55c25.84,25.48,44.93,27.44,56.57,24.45,1.21.54,2.42,1.09,3.67,1.58L726.57,1201a9.35,9.35,0,0,0,11.54-2.23c1.86-2.41,3-6.49-5.05-11.37l8.06,4.88a9.69,9.69,0,0,0,12.11-2.48c2.44-3.15.75-7.88-3.88-11.38l6.25,3.06c4.51,2.38,10.47,1,12.93-3.29,2.14-3.71.46-8.25-3.42-10.61l-8.89-5.37,10.34,1.15c5,2.23,11.78,3.06,14.21-1.56,2.1-4,.24-8.71-3.84-11.2Z"
            />
            <path
              fill="#076e81"
              d="M630.59,731.33S520.8,835.3,510.56,851c-9.65,14.78-16.09,18.35-22.8,23.6s-4.64,9.52-27.51,47.36c-17.05,28.24-27.26,75-15.53,107.41,12.14,33.47,44.46,56.32,74.42,72.62,32.48,17.68,38.68,23.4,64.59,39.17s32.71,18.77,46.86,33.9c8.44,9,28.54,13.34,40.65,17.27,5.33,1.73,11.86-4.24,13.44-9.78l23.49-79.74s-20.3-50.16-60.92-70.51c-23.17-11.61-50.23-23.76-63.47-35.44C573,987.37,611.93,967.2,620,960.25c6.65-5.73,32.34-28.83,36.91-30.41s18.3-67.41,18.3-68.4S630.59,731.33,630.59,731.33Z"
            />
            <path
              fill="#66676b"
              d="M821.54,1353.83h-.07a2.33,2.33,0,0,1-2.26-2.4c.14-5.23,3.51-128.36,6.77-142.69,3.71-16.3,33.23-47,34.48-48.32a2.33,2.33,0,0,1,3.36,3.24c-.3.31-29.87,31.08-33.29,46.11-3.16,13.89-6.63,140.51-6.66,141.79A2.34,2.34,0,0,1,821.54,1353.83Z"
            />
            <path
              fill="#035a69"
              d="M636.81,949.08a2.33,2.33,0,0,1-1.45-4.16,176.65,176.65,0,0,0,36.32-39.34c1.78-2.62,3.49-5.71,5.3-9,4.25-7.68,8.64-15.63,15.1-19.62a2.33,2.33,0,1,1,2.45,4c-5.42,3.35-9.51,10.75-13.47,17.91-1.86,3.37-3.62,6.56-5.53,9.36a181.55,181.55,0,0,1-37.28,40.37A2.29,2.29,0,0,1,636.81,949.08Z"
            />
            <path
              fill="#035a69"
              d="M707.73,1075.11a2.32,2.32,0,0,1-2.1-1.31h0c-.07-.14-7.14-14.37-22.61-21-16.74-7.17-21.58-11.9-21.78-12.1a2.33,2.33,0,1,1,3.3-3.3s4.64,4.4,20.31,11.11c17.13,7.33,24.66,22.6,25,23.25a2.33,2.33,0,0,1-1.09,3.11A2.29,2.29,0,0,1,707.73,1075.11Z"
            />
            <path
              fill="#035a69"
              d="M996.58,1128a2.59,2.59,0,0,1-.55-.07,2.33,2.33,0,0,1-1.71-2.82c0-.11,2.84-12.28-1-34.49l-.82-4.73c-2.07-11.92-2.15-12.39-.8-18.65.53-2.48,1.26-5.87,2.17-11.66,3.22-20.64-15.47-93-15.66-93.75a2.33,2.33,0,0,1,4.51-1.17c.78,3,19.13,74.05,15.75,95.64-.92,5.92-1.67,9.39-2.21,11.92-1.16,5.38-1.16,5.38.84,16.87l.82,4.74c4,23.28,1,35.86.91,36.39A2.35,2.35,0,0,1,996.58,1128Z"
            />
          </g>
        </a>
        <a href="https://www.linkedin.com/in/aurelien-coget/" rel="noreferrer">
          <title>
            {t('company.team.members.0.name')} /{' '}
            {t('company.team.members.0.role')}
          </title>

          <g id="Aurelien">
            <rect
              fill="#f8bd8c"
              x="1469.77"
              y="536.55"
              width="99.23"
              height="89.48"
            />
            <path
              fill="#eab186"
              d="M1569,543.84v27.82A47.4,47.4,0,0,1,1539.31,586c-26.82,2.41-53-17.51-69.54-32.94V543.8l.24.07c12.1,3.6,30.59,7.36,55.22,7.33,17.82,0,31.45-3.39,41.29-7.33Z"
            />
            <path
              fill="#d89150"
              d="M1614.33,454.48s8.71-85.6-4.69-99.76-5.93-18.2-20.37-22.24S1574.61,321,1550.8,321c-4.1,0-6.1-2.7-28.88-2.7-7.36,0-19.41-6.06-36.49,3.37s-28-3.7-40.26,12.22-17,7.33-27.71,32.27,3.35,37.07,6,64,16.76,48.53,16.76,48.53l170.26-5.49Z"
            />
            <path
              fill="#f2b588"
              d="M1451.67,460.2c1.23,14.95-4.61,27.63-13.05,28.33s-16.29-10.86-17.52-25.81,4.61-27.64,13.05-28.33S1450.44,445.25,1451.67,460.2Z"
            />
            <path
              fill="#f2b588"
              d="M1614.85,463.27c-1.78,14.9-10,26.16-18.45,25.16s-13.79-13.89-12-28.79,10-26.15,18.44-25.15S1616.62,448.38,1614.85,463.27Z"
            />
            <ellipse
              fill="#f8bd8c"
              cx="1518.83"
              cy="460.35"
              rx="80.53"
              ry="107.37"
            />
            <path
              fill="#d9d9d9"
              d="M1727.51,1426.54H1543.66c-3.71-48.1-7-97.3-7.44-126.62-.1-5.9,0-14.82,0-14.82l-8.7,141.44H1318.66c.17-39.75,2.28-88.22,8-145.64q.3-2.94.61-5.91,2.91-28.6,7.09-60.13,1.48-11.28,3.16-22.93,1.79-12.39,3.78-25.2,1.47-9.46,3.06-19.16c.49-3,1-6.11,1.53-9.19h0c.9-5.39,1.83-10.82,2.8-16.33q2.79-16,5.92-32.6H1694.7s1.45,5.88,3.77,16.48c.81,3.65,1.7,7.86,2.67,12.57a.24.24,0,0,1,0,.08c.17.83.35,1.68.52,2.54,2.07,10.05,4.43,22.21,6.89,36.08,1.92,10.79,3.89,22.59,5.82,35.23,3.18,20.85,6.26,44,8.76,68.52.42,4,.81,8.07,1.2,12.16C1728.85,1321.57,1731,1374.81,1727.51,1426.54Z"
            />
            <path
              fill="#00d6a0"
              d="M1660.48,630s74.43,23.3,89.63,36.9S1817,796.53,1817,857.43s12.15,154.41,0,172-66.85,104.88-103.32,125-51.4,18.63-51.4,18.63l-8.92-90.66,39-143.41-19.81-216.09Z"
            />
            <path
              fill="#00d6a0"
              d="M1427.85,612.24c8.17-3.06,30.05-9.27,45.36-3.57s52.15,11.41,69.73,3.57,40.25-8.89,56.13-5.52,60.66,12.64,86.74,34,41.93,424.78,17.85,459c-8.42,12-50.34,52.68-116.72,40.46-53-9.76-113.18,32.92-169.77,3.86-44.2-22.7-79.46-22.94-79.46-22.94s-53.3-356.36-23.81-437.61C1313.9,683.51,1317.3,653.58,1427.85,612.24Z"
            />
            <path
              fill="#66676b"
              d="M1536.22,1287.1h-.07a2,2,0,0,1-1.93-2.07c.34-10.3,5.92-17.65,14.38-28.78a272.62,272.62,0,0,0,22.45-33.44,2,2,0,0,1,3.48,2,275.61,275.61,0,0,1-22.75,33.89c-8.27,10.89-13.26,17.46-13.56,26.49A2,2,0,0,1,1536.22,1287.1Z"
            />
            <path
              fill="#00d6a0"
              d="M1429.26,617.73s-60.76,37.19-87,52.62-43.34,38-49.39,51-20.16,24.92-19.15,33.22-23.18,43.92-28.22,73.58-14.11,128.17-14.11,131.73,16.13,85.45,16.13,85.45,47.37,57,57.45,70,40.31,34.41,44.34,34.41,29.23-20.17,28.22-40.35-8.06-78.32-15.11-87.82c-5.52-7.42-12.27-14.12-17.36-24.07a62.42,62.42,0,0,1-3.81-9.16c-5-15.42,9.07-52.21,9.07-52.21s45.36-130.54,43.34-148.35,13.1-80.69,9.07-97.31S1429.26,617.73,1429.26,617.73Z"
            />
            <path
              fill="#459d80"
              d="M1451.06,1155.45c-35.43,0-51.48-19.25-52.16-20.1a2,2,0,1,1,3.1-2.52c.25.3,16.69,19.8,52.4,18.56,30.91-1.07,46.5-4.81,59-7.82,2.59-.62,5-1.21,7.5-1.75,22.59-5,53.91-10.85,65.06-4.65a2,2,0,1,1-1.94,3.49c-11.12-6.17-49.61,2.29-62.26,5.06-2.44.54-4.86,1.12-7.42,1.74-12.7,3-28.51,6.84-59.82,7.93Q1452.77,1155.45,1451.06,1155.45Z"
            />
            <path
              fill="#459d80"
              d="M1350.36,978.54a2,2,0,0,1-2-1.59c-4.55-21.55-2.23-40.92,6.91-57.58,8.41-15.32,13.83-30.83,19.57-47.26,3.37-9.63,6.85-19.58,11-29.4a2,2,0,0,1,3.69,1.55c-4.07,9.7-7.53,19.6-10.87,29.17-5.55,15.86-11.28,32.25-19.84,47.87-8.67,15.79-10.86,34.24-6.5,54.83a2,2,0,0,1-1.55,2.37A1.84,1.84,0,0,1,1350.36,978.54Z"
            />
            <path
              fill="#459d80"
              d="M1511.4,618.41c-15.2,0-41.08-8.5-42.25-8.89a2,2,0,1,1,1.25-3.8c.3.1,30.25,9.93,43.83,8.56a105.93,105.93,0,0,1,16.21-.47c2.19.08,4.09.15,4.62-.19a2,2,0,1,1,2.16,3.36c-1.58,1-3.6.95-6.93.83a101.88,101.88,0,0,0-15.65.45Q1513.13,618.41,1511.4,618.41Z"
            />
            <path
              fill="#d89150"
              d="M1442.49,358.61s11.1,36.55,27.28,32.93,28.73-27.52,34.19-15,18.27,12.62,29.44,5.22,6.19,3.55,18.58,8.45,19.52.1,26.26,18.78,17.64,11.94,18-11.94-13.68-77.44-35.16-66.07S1442.49,358.61,1442.49,358.61Z"
            />
            <path
              fill="#d89150"
              d="M1539.8,334.29l-3.85-5.61a27.45,27.45,0,0,0-1.23-15.25,17.69,17.69,0,0,0,3.83-1.05c9.74-3.93,14.87-16.55,10.64-26.16-1.34-3.07-3.5-5.87-3.88-9.2-.49-4.29,2.1-8.35,2.47-12.65.56-6.66-4.12-12.5-8.52-17.53-3-3.38-6-6.82-9.86-9.08s-8.84-3.18-12.89-1.24c-4.27,2-6.91,6.86-11.44,8.23-4.24,1.28-8.69-.88-13.11-1.24-10.16-.82-18.84,8.24-21.84,18a38.44,38.44,0,0,0,8.57,36.91,35.72,35.72,0,0,0,3.2,3,30.47,30.47,0,0,1-8.06,2.32c-5.82.68-11.6-1.21-17.45-1.56a33.31,33.31,0,0,0-35.23,35.12c.28,4.9,1.63,9.86.55,14.65-1.13,5-4.75,9.07-7,13.69A27,27,0,0,0,1433.51,404c6,1.23,13,0,16.87-4.69,4.51-5.45,3.8-13.88,8.27-19.38,4.32-5.32,12.25-6.39,16.94-11.39,5.39-5.76,5.49-15.65,12.07-20,6.4-4.24,14.81-.77,22.44.07A31.8,31.8,0,0,0,1539.8,334.29Z"
            />
            <path
              fill="#d89150"
              d="M1583.67,319.18c.06,0,0,.42-.07,1.21a12.84,12.84,0,0,1-1.1,3.29,16,16,0,0,1-9.55,8.23c-5.24,1.85-12.85,0-17.33-6-2.12-2.92-3.8-7-2.21-11.19a6.28,6.28,0,0,1,5.2-3.84,7.62,7.62,0,0,1,6.23,2.18c3.25,3.49,2.76,8.81.28,12.08a15.53,15.53,0,0,1-9.84,5.71,41.53,41.53,0,0,1-10.11.35,60,60,0,0,1-8.68-1.17,38.76,38.76,0,0,1-11.82-4.49c-2.56-1.55-3.75-2.72-3.68-2.8.16-.34,5.37,4.13,15.76,6a62.16,62.16,0,0,0,8.5.92,41,41,0,0,0,9.69-.49,13.9,13.9,0,0,0,8.69-5.09c2.07-2.7,2.31-7.13-.14-9.69s-7.27-2.12-8.39,1.07,0,6.85,1.91,9.44c3.94,5.43,10.78,7.28,15.56,5.75a15.83,15.83,0,0,0,9.28-7.3C1583.3,320.82,1583.48,319.13,1583.67,319.18Z"
            />
            <path
              fill="#d89150"
              d="M1428.25,290.13a36,36,0,0,1,1.73,3.5,25.73,25.73,0,0,0,6.33,8.21,16.46,16.46,0,0,0,14.11,3.63,10.39,10.39,0,0,0,6.83-4.76,4.59,4.59,0,0,0,.31-4,3.46,3.46,0,0,0-3-2.29,3.67,3.67,0,0,0-3.08,2.27,5.88,5.88,0,0,0-.08,4.16,15,15,0,0,0,5.17,7,25.85,25.85,0,0,0,14.31,4.88,73.12,73.12,0,0,0,10.57-.28c2.51-.2,3.91-.28,3.93-.14a15.44,15.44,0,0,1-3.84.87,56.5,56.5,0,0,1-10.72.88,26.48,26.48,0,0,1-15.3-4.84,16.42,16.42,0,0,1-5.87-7.79,7.64,7.64,0,0,1,.13-5.5,5.49,5.49,0,0,1,4.77-3.36,5.29,5.29,0,0,1,4.71,3.45,6.42,6.42,0,0,1-.4,5.56,12,12,0,0,1-8.07,5.59,17.65,17.65,0,0,1-15.37-4.31,24.06,24.06,0,0,1-6.13-8.92C1428.36,291.56,1428.15,290.16,1428.25,290.13Z"
            />
            <path
              fill="#d89150"
              d="M1490.87,309.63c-.12.1-1.15-1.06-3.28-2.85a99.24,99.24,0,0,0-9.67-6.77c-4.15-2.73-9.36-6.26-13.16-12-3.8-5.57-6.45-13.15-5.3-21.09a25.2,25.2,0,0,1,12-18,19.88,19.88,0,0,1,9.35-2.67,21,21,0,0,1,8.29,1.58,18.7,18.7,0,0,1,9.14,7.76,12.23,12.23,0,0,1,1.31,3c.19.73.26,1.11.2,1.13s-.59-1.47-2.13-3.75a18.91,18.91,0,0,0-9-6.91,18.63,18.63,0,0,0-16.28,1.35,23.79,23.79,0,0,0-11,16.79c-1.07,7.34,1.34,14.5,4.88,19.84,3.52,5.49,8.4,9,12.47,11.85a73.53,73.53,0,0,1,9.41,7.33C1490.1,308.23,1491,309.57,1490.87,309.63Z"
            />
            <path
              fill="#d89150"
              d="M1522.84,315.63c.15-.37,3.3,2.58,9.49,2.45a16,16,0,0,0,15.73-14.42,18.26,18.26,0,0,0-2.71-12.3,21.09,21.09,0,0,0-7.82-7c-5.49-2.82-9.58-2.52-9.53-2.89a6.14,6.14,0,0,1,2.77-.2,21.51,21.51,0,0,1,16,9.16,19.47,19.47,0,0,1,3.13,13.51,17.92,17.92,0,0,1-6.77,12.09,17.6,17.6,0,0,1-10.82,3.41,15.42,15.42,0,0,1-7.35-2C1523.42,316.48,1522.78,315.69,1522.84,315.63Z"
            />
            <path d="M1534.74,315.26c.15.4-2.91,2.07-8.38,3.3a53.9,53.9,0,0,1-41.79-8.56c-4.55-3.29-6.71-6-6.41-6.34.65-.89,11,8.27,27.14,11.42C1521.34,318.52,1534.49,314.18,1534.74,315.26Z" />
            <path
              fill="#d89150"
              d="M1526.79,585.16c-43.24,2.81-82.13-41.7-86.94-99.3a161.36,161.36,0,0,1-.44-16.38c5.92,56.07,44.29,98.92,86.73,96.19,39-2.51,68.07-42.62,69.9-92.74.14.95.22,1.9.29,2.85C1601.14,533.38,1570,582.36,1526.79,585.16Z"
            />
            <path
              fill="#d89150"
              d="M1489,556.2c2.24,1.67,10.55.43,13.23.1,4.57-.57,9.84-1.79,12.93-5.23,3.53-3.94,2.5-10.35,2.45-15.15,0-1.37.51-5.11-.61-6.32l12-.8c-1.07.07.94,15.88,1.35,17.19,1.09,3.49,4.6,5.68,8.11,6.79a40.15,40.15,0,0,0,11.6,1.58c.94,0,10.22-.06,10.37-1a3.33,3.33,0,0,1-1.38,1.83,231.28,231.28,0,0,1-20.5,14.63s-31.74.19-32.57-.71S1489,556.23,1489,556.2Z"
            />
            <path
              fill="#d89150"
              d="M1485.78,561.51c-.31-8.49-.9-35.67,3.45-43.42l0-.55,1.39-1.26a39.16,39.16,0,0,1,7-3.93c7.29-3.33,16.19-4.9,25.65-4.52a100.65,100.65,0,0,1,15.16,1.61l1.2.23c1.38.25,2.74.51,4.09.89,3.41.94,7.67,2.65,10.19,7.45,2.61,5,3.51,10.19,4.38,15.24.22,1.31.44,2.6.69,3.89,1.5,7.88,1.66,14.46.51,20.72l-7.06-1.2c1-5.48.85-11.32-.5-18.39-.25-1.32-.48-2.66-.72-4-.81-4.77-1.58-9.27-3.72-13.33-1-2-2.65-3.08-5.76-3.94a34.2,34.2,0,0,0-3.42-.74l-1.24-.23a93.65,93.65,0,0,0-14-1.48,49.25,49.25,0,0,0-22.33,3.88,32.64,32.64,0,0,0-5.22,2.82l-.06.07c-2.3,2.83-3.19,23-2.59,40.05Z"
            />
          </g>
        </a>
      </g>
    </svg>
  );
};
