/**
 * Job's explorer and home cards default height, also used for containers
 */
export const JOB_CARD_HEIGHT = 'h-56';

export const SM_SCREEN_SIZE = 640;

export const MD_SCREEN_SIZE = 768;

export const LG_SCREEN_SIZE = 1024;

export const XL_SCREEN_SIZE = 1280;

export const XXL_SCREEN_SIZE = 1536;

export const XXXL_SCREEN_SIZE = 1750;
