import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { Place } from 'react-tooltip';

type Props = {
  labelText: string;
  tooltipTitle: string;
  tooltipPlace?: Place;
};

export const LabelWithTooltip: React.FC<Props> = ({
  labelText,
  tooltipTitle,
  tooltipPlace = 'top',
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <label
      className="flex items-center text-gray-secondary dark:text-gray-primary font-comfortaa pl-2 text-md mb-1"
      htmlFor="Labels"
      id="label"
    >
      <span className="mr-6">{labelText}</span>
      <Tooltip
        colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
        title={tooltipTitle}
        icon={faQuestionCircle}
        place={tooltipPlace}
        size="lg"
      />
    </label>
  );
};
