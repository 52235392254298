import React from 'react';
import R2_wipImg from '@images/R2_workinprogress.png';
import { useTranslation } from 'react-i18next';

type Props = {
  width?: string;
  height?: string;
};

export const R2wipIcon: React.FC<Props> = ({
  width = 'auto',
  height = 'auto',
}) => {
  const { t } = useTranslation();

  return (
    <>
      <img
        src={R2_wipImg}
        alt={t('action:r2_wip_alt')}
        width={width}
        height={height}
      />
    </>
  );
};
