import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'common/hooks/useQueryParam.hook';
import { useKratosSettingsFlowWithProfileMethod } from 'kratos/flows/settings.flow';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import { useTranslation } from 'react-i18next';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { useForm } from 'react-hook-form';
import { PictureInput } from '@components/PictureInput/PictureInput.component';
import ProfilePicturePlaceholder from '../../../assets/images/profilePicturePlaceholder.png';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import {
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  UiNode,
} from '@ory/kratos-client';
import { KratosTextarea } from 'kratos/components/KratosTextarea/KratosTextarea';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { UserContext } from 'user/contexts/user.context';
import { removeEmptyFields } from '@utils/object.util';
import { SettingsTraits } from 'user/types/traits';
import { Title } from '@components/Titles/Title.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { ProfileOptionalInputs } from './ProfileOptionalInputs.component';

export const ProfileEdit: React.FC = () => {
  const { t } = useTranslation();
  const { identity, action } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      'traits.username': identity?.traits.username,
      'traits.email': identity?.traits.email,
      'traits.picture': identity?.traits.picture,
    },
  });
  const flowId = useQuery().get('flow');
  const [flow, settingsSubmitHandlerFlow] =
    useKratosSettingsFlowWithProfileMethod(flowId, () => {
      action.whoami();
      toastSuccess(t('toast.profileSaved'));
    });

  const [csrfNode, setCsrfNode] = useState<UiNode>();
  const [emailNode, setEmailNode] = useState<UiNode>();
  const [fullnameNode, setFullnameNode] = useState<UiNode>();
  const [gitLabUsernameNode, setGitLabUsernameNode] = useState<UiNode>();
  const [gitHubUsernameNode, setGitHubUsernameNode] = useState<UiNode>();
  const [usernameNode, setUsernameNode] = useState<UiNode>();
  const [twitterNode, setTwitterNode] = useState<UiNode>();
  const [linkedinNode, setLinkedinNode] = useState<UiNode>();
  const [jobTitleNode, setJobTitleNode] = useState<UiNode>();
  const [companyNode, setCompanyNode] = useState<UiNode>();
  const [websiteNode, setWebsiteNode] = useState<UiNode>();
  const [goodiesNode, setGoodiesNode] = useState<UiNode>();
  const [bioNode, setBioNode] = useState<UiNode>();
  const [pictureNode, setPictureNode] = useState<UiNode>();

  const onSubmit = (
    data: SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  ) => {
    const traits = data.traits as SettingsTraits;
    traits.username = identity?.traits.username;
    traits.email = identity?.traits.email;
    traits.gitlab_token = identity?.traits.gitlab_token;
    traits.github_token = identity?.traits.github_token;
    removeEmptyFields(data.traits);
    settingsSubmitHandlerFlow(data);
  };
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    if (flow !== undefined) {
      flow.ui.nodes.forEach((node) => {
        if (isUiNodeInputAttributes(node.attributes)) {
          switch (node.attributes.name) {
            case 'csrf_token':
              setCsrfNode(node);
              break;
            case 'traits.email':
              setEmailNode(node);
              break;
            case 'traits.full_name':
              setFullnameNode(node);
              break;
            case 'traits.username':
              setUsernameNode(node);
              break;
            case 'traits.gitlab_username':
              setGitLabUsernameNode(node);
              break;
            case 'traits.github_username':
              setGitHubUsernameNode(node);
              break;
            case 'traits.twitter':
              setTwitterNode(node);
              break;
            case 'traits.linkedin':
              setLinkedinNode(node);
              break;
            case 'traits.job_title':
              setJobTitleNode(node);
              break;
            case 'traits.company':
              setCompanyNode(node);
              break;
            case 'traits.website':
              setWebsiteNode(node);
              break;
            case 'traits.goodies':
              setGoodiesNode(node);
              break;
            case 'traits.bio':
              setBioNode(node);
              break;
            case 'traits.picture':
              setPictureNode(node);
              break;
            default:
              break;
          }
        }
      });
    }
    return () => {
      setCsrfNode(undefined);
      setEmailNode(undefined);
      setFullnameNode(undefined);
      setGitLabUsernameNode(undefined);
      setGitHubUsernameNode(undefined);
      setUsernameNode(undefined);
      setTwitterNode(undefined);
      setLinkedinNode(undefined);
      setJobTitleNode(undefined);
      setCompanyNode(undefined);
      setWebsiteNode(undefined);
      setGoodiesNode(undefined);
      setBioNode(undefined);
      setPictureNode(undefined);
    };
  }, [flow]);

  return (
    <div data-testid="settings-form" className="pb-8">
      <Title
        bold
        color="text-secondary-default"
        font="ubuntu"
        size="h1"
        classOverrides={['mb-4 inline-block text-center w-full']}
      >
        {t('profile.section_title_0')}
      </Title>
      {flow ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          method={flow.ui.method}
          className="flex flex-col items-center md:items-stretch xl:mx-32 2xl:mx-56"
        >
          {csrfNode?.attributes && (
            <KratosFormInputs
              register={register}
              errors={errors}
              isSubmitting={isSubmitting}
              flowUiNodes={[csrfNode]}
            />
          )}
          {/* <!-- BEGIN Section Profile picture, full name, username and e-mail --> */}
          <div className="flex flex-col items-center md:items-stretch md:flex-row">
            {pictureNode?.attributes &&
              isUiNodeInputAttributes(pictureNode.attributes) && (
                <PictureInput
                  classOverrides={['w-24 h-24 md:w-36 md:h-36 mt-10']}
                  disabled
                  name={pictureNode.attributes.name}
                  placeholder={ProfilePicturePlaceholder}
                  register={register('traits.picture')}
                  value={pictureNode.attributes.value}
                />
              )}
            <div className="flex-grow">
              {fullnameNode?.attributes && usernameNode?.attributes && (
                <div className="flex flex-col sm:flex-row">
                  <KratosFormInputs
                    classOverrides={['flex-grow dark:text-gray-primary']}
                    bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
                    register={register}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    flowUiNodes={[fullnameNode]}
                  />
                  <KratosFormInputs
                    classOverrides={['flex-grow']}
                    bgColor={
                      theme === 'light'
                        ? 'bg-gray-input'
                        : 'bg-secondary-default-high-opacity'
                    }
                    register={register}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    flowUiNodes={[usernameNode]}
                  />
                </div>
              )}
              {emailNode?.attributes !== undefined && (
                <KratosFormInputs
                  bgColor={
                    theme === 'light'
                      ? 'bg-gray-input'
                      : 'bg-secondary-default-high-opacity'
                  }
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  flowUiNodes={[emailNode]}
                />
              )}
            </div>
          </div>
          {/* <!-- END Section Profile picture, full name, username and e-mail --!> */}

          <div className="md:grid md:grid-cols-2">
            {gitHubUsernameNode?.attributes && gitLabUsernameNode?.attributes && (
              <>
                <KratosFormInputs
                  classOverrides={['flex-grow dark:text-gray-primary']}
                  bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  flowUiNodes={[gitLabUsernameNode]}
                />
                <KratosFormInputs
                  classOverrides={['flex-grow dark:text-gray-primary']}
                  bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
                  register={register}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  flowUiNodes={[gitHubUsernameNode]}
                />
              </>
            )}
            {/* <!-- BEGIN Section Socials --> */}
            {linkedinNode?.attributes &&
              twitterNode?.attributes &&
              jobTitleNode?.attributes &&
              companyNode?.attributes &&
              websiteNode?.attributes &&
              goodiesNode?.attributes && (
                <ProfileOptionalInputs
                  register={register}
                  errors={errors}
                  linkedinNode={linkedinNode}
                  twitterNode={twitterNode}
                  jobTitleNode={jobTitleNode}
                  companyNode={companyNode}
                  websiteNode={websiteNode}
                  goodiesNode={goodiesNode}
                  isSubmitting={isSubmitting}
                />
              )}
          </div>
          {/* <!-- END Section Socials --!> */}

          {/* <!-- BEGIN Section Bio --> */}
          {bioNode?.attributes && (
            <KratosTextarea
              errors={errors}
              inputIndex={8}
              isSubmitting={isSubmitting}
              register={register}
              minLength={3}
              maxLength={400}
              UINode={bioNode}
            />
          )}
          {/* <!-- END Section Bio --!> */}
          <PrimaryButton
            disabled={
              isSubmitting ||
              Object.keys(errors).length > 0 ||
              !isValid ||
              !isDirty
            }
            content={t('action:update_profile')}
            bold={false}
            classOverrides={['self-center w-max dark:bg-secondary-default']}
            margin="m-0"
            submitButton
          />
        </form>
      ) : (
        <SpinnerIcon classOverrides={['my-48']} />
      )}
    </div>
  );
};
