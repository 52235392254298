import { Title } from '@components/Titles/Title.component';
import { useTranslation } from 'react-i18next';
import { ChangePwdForm } from '../ChangePwdForm/ChangePwdForm.component';

export const ChangePwd: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb-12 text-center">
        <Title size={'h1'} color={'text-secondary-default'} bold>
          {t('change_pwd.title')}
        </Title>
      </div>
      <div className="text-center flex items-center mb-6 flex-col">
        <div className="w-full md:w-104 2xl:w-3/6 mx-auto">
          <ChangePwdForm />
        </div>
      </div>
    </div>
  );
};
