import React, { useRef, useState } from 'react';
import { SmallArrowIcon } from '@components/Icons/SmallArrowIcon/SmallArrowIcon';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { motion } from 'framer-motion';
import { UseFormRegisterReturn } from 'react-hook-form';
import { StyledInput } from '../StyledInput/StyledInput.component';

type Props = ClassOverrides & {
  /**
   * Optional callback when clicking an option.
   * Defaults to setting the selected option as the input value
   */
  customOnOptionClick?: (option: string) => void;
  options: string[];
  placeholder?: string;
  /**
   * useForm hook register method return object
   * @see useForm
   */
  register?: UseFormRegisterReturn;
  required?: boolean;
  /**
   * Whether to use the input text as a dropdown filter, sets readOnly to false
   */
  useFilter?: boolean;
  value?: string;
};

/**
 * @param {Array<string>} classOverrides
 * @param {function} customOnOptionClick - Optional custom callback to fire on option click
 * @param {Array<string>} options - Options to display in the dropdown
 * @param {Object} register - useForm hook register method return object
 * @param {boolean} required
 * @param {boolean} useFilter - Whether to use the input text as a dropdown filter, sets readOnly to false
 */
export const SelectInput: React.FC<Props> = ({
  classOverrides = [],
  customOnOptionClick,
  options,
  placeholder,
  register,
  required = false,
  useFilter = false,
  value,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? '');

  const onOptionClick = (option: string) => {
    if (customOnOptionClick) {
      customOnOptionClick(option);
    } else {
      setShowOptions(false);
      setInputValue(option);
    }
  };

  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowOptions(false));

  return (
    <div className={cn('relative', ...classOverrides)} ref={ref}>
      <StyledInput
        classOverrides={[
          'filter shadow-button-inner outline-none focus:border-secondary-default border dark:bg-gray-third dark:placeholder-gray-primary dark:text-gray-primary',
          !useFilter && 'cursor-pointer',
        ]}
        border={showOptions ? 'rounded-t-lg' : 'rounded-lg'}
        icon={
          <SmallArrowIcon
            action={() => setShowOptions(!showOptions)}
            classOverrides={[
              'absolute right-3 top-4 hover:opacity-70 transition cursor-pointer dark:text-gray-primary',
              useFilter && 'hidden',
              showOptions && 'transform rotate-180',
            ]}
            color="text-gray-secondary"
            height="20"
            width="20"
          />
        }
        id="Options"
        onBlur={useFilter ? () => setShowOptions(false) : register?.onChange}
        onChange={
          useFilter
            ? (event) => setInputValue(event.target.value)
            : register?.onChange
        }
        onClick={() => setShowOptions(!showOptions)}
        onFocus={() => setShowOptions(false)}
        placeholder={placeholder}
        placeholderColor="placeholder-gray-secondary"
        readOnly={!useFilter}
        register={register}
        required={required}
        type="text"
        value={inputValue}
        showError={false}
        autoCompletion={false}
      />
      {showOptions && (
        <motion.ul
          className={cn(
            'w-full origin-top-right top-13 divide-y absolute z-40 dark:divide-dark-third ' +
              'rounded-b-md shadow-lg ring-1 ring-black bg-white ' +
              'ring-opacity-5 focus:outline-none max-h-40 overflow-auto',
          )}
          aria-labelledby="label"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          role="menu"
        >
          {options
            .filter((option) =>
              useFilter
                ? option.toLowerCase().includes(inputValue.toLowerCase())
                : option,
            )
            .map((option, index) => {
              /*
               * We prevent default onMouseDown to avoid onBlur triggering when choosing a label
               */
              return (
                <li
                  key={option}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => onOptionClick(option)}
                  className={cn(
                    'bg-white dark:bg-gray-third dark:text-gray-primary py-1 font-light w-full text-center cursor-pointer hover:bg-gray-100 ',
                    'transition-colors transition-duration-300',
                    index === option.length - 1 ? 'rounded-b-2lg' : '',
                  )}
                  role="menuitem"
                >
                  {option}
                </li>
              );
            })}
        </motion.ul>
      )}
    </div>
  );
};
