import React, { ButtonHTMLAttributes } from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import mixpanel from '@utils/mixpanel.util';

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 */

const SecondaryButton: React.FC<
  ButtonProps & HTMLStyling & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  content,
  action,
  bgColor = 'bg-white',
  classOverrides = [],
  children,
  bold = false,
  light = true,
  color = '',
  type = 'button',
  font = 'ubuntu',
  ...rest
}) => {
  const onClickButton = () => {
    if (!action) return;
    action();
    mixpanel.track(`[Button] Click on ${content}`, {
      buttonType: 'secondaryButton',
    });
  };

  return (
    <button
      onClick={onClickButton}
      className={cn(
        'py-3 ' +
          'px-4 rounded-2lg hover:shadow-button-inner transition duration-500 ' +
          'filter shadow-lg ',
        `font-${font}`,
        color,
        bgColor,
        bold && 'font-bold',
        light && 'font-light',
        classOverrides.length === 0 && 'w-auto',
        ...classOverrides,
      )}
      data-testid={`secondarybutton-${content}`}
      aria-label={content}
      type={type}
      {...rest}
    >
      {content}
      {children}
    </button>
  );
};

export { SecondaryButton };
