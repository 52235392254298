import React, { useContext, useState } from 'react';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { StyledCheckboxInput } from '@components/Form/StyledCheckboxInput.component/StyledCheckboxInput.component';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { StyledTextarea } from '@components/Form/StyledTextarea/StyledTextarea.component';
import { ArrowIcon } from '@components/Icons/ArrowIcon/ArrowIcon';
import { PictureInput } from '@components/PictureInput/PictureInput.component';
import { Typography } from '@components/Typography/Typography.component';
import { IconsDropdown } from '@jobs/components/IconsDropdown/IconsDropdown.component';
import { LabelSelectInput } from '@jobs/components/LabelSelectInput/LabelSelectInput.component';
import { LicenseSelectInput } from '@jobs/components/LicenseSelectInput/LicenseSelectInput.component';
import { returnLatestVersion } from '@utils/jobs.util';
import { MAX_JOB_DESCRIPTION_LENGTH } from 'common/constants/general';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MyJobsContext } from 'user/contexts/myJobs.context';
import { UserContext } from 'user/contexts/user.context';
import ProfilePicturePlaceholder from '../../../assets/images/profilePicturePlaceholder.png';
import { DeleteJobModal } from './DeleteJobModal.component';
import { useOnJobDetailsUpdate } from 'common/hooks/useOnJobDetailsUpdate';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import {
  JobModificationFormData,
  JobUpdateRequestBody,
} from 'user/types/forms';
import { Title } from '@components/Titles/Title.component';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { ThemeContext } from 'common/contexts/theme.context';
import { UpdatePolicy } from '../UpdatePolicy/UpdatePolicy.component';
import { UpdatePolicyModal } from '../UpdatePolicy/UpdatePolicyModal.component';
import { PublicIcon } from '@components/Icons/PublicIcon/PublicIcon.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

export const processFormData = (
  data: JobModificationFormData,
): JobUpdateRequestBody => {
  const { labels, icon } = data;
  let processedData: JobUpdateRequestBody = {
    deprecated: data.deprecated,
    description: data.description,
    license: data.license,
  };

  if (labels) {
    /**
     * React hook form doesn't support string only fields with useFieldArray so we
     * do this mapping first
     */
    processedData.labels = data.labels.map((label) => label.name);
  }

  /**
   * If job has no icon, set a default one
   */
  if (!icon) {
    processedData.icon = String.fromCodePoint(0x1f3f7);
  } else {
    processedData.icon = icon;
  }
  return processedData;
};

type Props = {
  returnAction?: () => void;
  returnContent?: string;
};

export const ModifyJob: React.FC<{ job: JobResponse } & Props> = ({
  job,
  returnAction,
  returnContent = 'action:go_back_to_dashboard',
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues: {
      license: '',
      icon: job.icon,
      description: job.description,
      labels: job.labels.map((label) => {
        return { name: label };
      }),
      deprecated: job.deprecated,
    },
  });

  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [loading, updateJob] = useOnJobDetailsUpdate();

  const submitHandler = (data: JobModificationFormData): void => {
    updateJob(processFormData(data), identity?.traits.username, job.name);
  };
  const jobVersion = returnLatestVersion(job.versions);

  const { identity } = useContext(UserContext);
  const { setShowModifyJobForm } = useContext(MyJobsContext);
  const { theme } = useContext(ThemeContext);
  const setUpdateModal = () => {
    setShowPolicyModal(!showPolicyModal);
  };

  return (
    <div data-testid="modify-my-job">
      <div className="relative flex items-center text-gray-secondary font-comfortaa mb-4">
        {returnAction ? (
          <div
            onClick={returnAction}
            className="mb-32 sm:mb-0 flex w-56 2xl:w-auto items-center absolute -left-8 cursor-pointer hover:opacity-70 transition-opacity dark:text-gray-primary"
          >
            <ArrowIcon
              classOverrides={[
                'transform rotate-180 mr-4 w-8 h-8 xl:h-12 xl:w-12 ',
              ]}
              strokeWidth="1.5"
              height="45"
              width="55"
            />
            <span className="xl:text-lg">{t(returnContent)}</span>
          </div>
        ) : (
          <TrackedLink
            name={`go back to jobs dashboard`}
            onClick={() => setShowModifyJobForm && setShowModifyJobForm(false)}
            to="/dashboard/jobs"
            className="hidden lg:flex w-56 xl:w-auto items-center absolute -left-8 cursor-pointer hover:opacity-70 transition-opacity dark:text-gray-primary"
          >
            <ArrowIcon
              classOverrides={[
                'transform rotate-180 mr-4 w-8 h-8 xl:h-12 xl:w-12 ',
              ]}
              strokeWidth="1.5"
              height="45"
              width="55"
            />
            <span className="xl:text-lg">{t(returnContent)}</span>
          </TrackedLink>
        )}
        <div className="flex justify-center items-center w-full flex-col-reverse md:flex-row">
          <Title
            bold
            color="text-secondary-default"
            font="ubuntu"
            size="h1"
            classOverrides={[' inline-block text-center']}
          >
            {t('my_jobs.modify_your_job.section_title')}
          </Title>
          <FeatureFlag name="private-job">
            {job.policy === 'public' ? (
              <Tooltip
                title={t('my_jobs.modify_your_job.policy.private.tooltip')}
                colorSet={
                  theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
                }
              >
                <PublicIcon
                  color="text-primary-default"
                  width={'48'}
                  classOverrides={[
                    'md:ml-2 mt-8 sm:mt-0 dark:text-gray-primary ',
                  ]}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={t('my_jobs.modify_your_job.policy.public.tooltip')}
                colorSet={
                  theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
                }
              >
                <LockIcon
                  width={'22'}
                  color="text-primary-default"
                  classOverrides={['md:ml-2 dark:text-gray-primary text-xs']}
                />
              </Tooltip>
            )}
          </FeatureFlag>
        </div>
      </div>
      <FeatureFlag name="private-job">
        <UpdatePolicy
          currentPolicy={job.policy}
          classOverrides={['mb-6']}
          setShowPolicyModal={setUpdateModal}
          job={job}
        />
      </FeatureFlag>
      <form className="flex flex-col" onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-col md:flex-row-reverse mb-6 space-y-4 md:space-y-0">
          <div className="w-full space-y-4 md:space-y-2">
            {/* Job name and license */}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="md:mr-10 w-full">
                <StyledInput
                  bgColor="bg-gray-input"
                  classOverrides={[
                    'text-gray-secondary dark:bg-secondary-default-high-opacity dark:text-gray-primary',
                  ]}
                  disabled
                  label={t('job.job_name')}
                  value={job.name}
                  showError={false}
                />
              </div>
              <LicenseSelectInput
                classOverrides={['w-full']}
                defaultValue={job.license}
                tooltipPlace="right"
                register={register('license')}
              />
            </div>
            {/* Docker image and default stage */}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="md:mr-10 w-full">
                <StyledInput
                  bgColor="bg-gray-input"
                  classOverrides={[
                    'text-gray-secondary dark:bg-secondary-default-high-opacity dark:text-gray-primary',
                  ]}
                  disabled
                  label={t('job.docker_image')}
                  value={jobVersion.containerImage}
                  showError={false}
                />
              </div>
              <StyledInput
                bgColor="bg-gray-input"
                classOverrides={[
                  'text-gray-secondary dark:bg-secondary-default-high-opacity dark:text-gray-primary',
                ]}
                disabled
                label={t('job.default_stage')}
                value={jobVersion.defaultStage}
                showError={false}
              />
            </div>
            {/* Link/source */}
            <div className="hidden md:block">
              <StyledInput
                bgColor="bg-gray-input"
                classOverrides={[
                  'text-gray-secondary dark:bg-secondary-default-high-opacity dark:text-gray-primary',
                ]}
                disabled
                label={t('my_jobs.modify_your_job.labels.source')}
                value={jobVersion.jobUrl}
                showError={false}
              />
            </div>
            <div className="block md:hidden">
              <StyledInput
                bgColor="bg-gray-input"
                classOverrides={[
                  'text-gray-secondary dark:bg-secondary-default-high-opacity dark:text-gray-primary',
                ]}
                disabled
                label={t('my_jobs.modify_your_job.labels.source_mobile')}
                value={job.name}
                showError={false}
              />
            </div>
          </div>

          {/* Owner picture and job's icon */}
          <div className="flex items-center justify-evenly md:flex-col-reverse md:justify-between md:mr-14">
            <div className="w-20">
              <PictureInput
                classOverrides={[
                  'bg-gray-input dark:bg-secondary-default-high-opacity',
                ]}
                disabled
                label={t('my_jobs.modify_your_job.labels.owner')}
                placeholder={ProfilePicturePlaceholder}
                placeholderBlur={false}
                value={identity?.traits.picture}
              />
            </div>
            <div className="w-20">
              <IconsDropdown
                buttonClassOverrides={['w-20 h-20']}
                defaultValue={job.icon}
                label={t('my_jobs.modify_your_job.labels.icon')}
                register={register('icon')}
                setValue={setValue}
                showError={false}
              />
            </div>
          </div>
        </div>
        <StyledTextarea
          placeholderColor={
            theme === 'light'
              ? 'placeholder-gray-secondary'
              : 'placeholder-gray-primary'
          }
          disabled={isSubmitting}
          id={'Description'}
          label={t('common_job_form.labels.job_description_label')}
          maxLength={MAX_JOB_DESCRIPTION_LENGTH}
          placeholder={'Description'}
          register={register('description')}
          rows={5}
          classOverrides={['dark:bg-gray-third dark:text-gray-primary']}
        />
        <LabelSelectInput
          classOverrides={['w-full md:w-11/12']}
          control={control}
          inputAndDisplayContainerClassOverrides={['flex flex-col md:flex-row']}
          inputClassOverrides={['flex-shrink-0 w-full md:w-5/12 md:mr-4']}
          tooltipPlace="right"
        />
        {/* Deprecated checkbox */}
        <div>
          <FeatureFlag name="deprecated-job">
            <Typography
              color="text-gray-secondary"
              bold
              classOverrides={[
                'mb-6 text-sm md:text-lg dark:text-gray-primary mt-6 md:mt-10',
              ]}
            >
              {t('my_jobs.modify_your_job.signal_job_has_deprecated')}
            </Typography>
            <StyledCheckboxInput
              classOverrides={['mt-2 text-sm dark:text-gray-primary']}
              label={t('my_jobs.modify_your_job.labels.deprecated')}
              register={register('deprecated')}
              type="checkbox"
            />
          </FeatureFlag>
          <div className="my-6 relative flex font-comfortaa text-secondary-dark flex-col">
            <span className="text-sm xl:text-base text-left dark:text-gray-primary">
              {t('my_jobs.modify_your_job.delete')}
            </span>
            <div className="flex flex-col md:flex-row w-full justify-center md:justify-between items-center">
              <PrimaryButton
                bgColor="bg-red-alert"
                action={() => setShowDeleteModal(true)}
                classOverrides={['text-sm xl:text-base  w-52 h-12']}
                content={t('my_jobs.modify_your_job.delete_button_content')}
                font="comfortaa"
                disabled={isSubmitting || loading}
              />

              <div className="flex md:justify-end">
                <PrimaryButton
                  classOverrides={[
                    'text-sm xl:text-base dark:bg-secondary-default w-52 h-12',
                  ]}
                  content={t('my_jobs.modify_your_job.save_the_changes')}
                  font="comfortaa"
                  submitButton
                  disabled={isSubmitting || loading}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {(isSubmitting || loading) && <SpinnerIcon color="text-secondary-dark" />}
      <DeleteJobModal
        jobName={job.name}
        jobOwner={job.ownerName}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
      />
      <UpdatePolicyModal
        job={job}
        setShowPolicyModal={setShowPolicyModal}
        showPolicyModal={showPolicyModal}
      />
    </div>
  );
};
