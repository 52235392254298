import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../pages/Error.page';

/**
 * Error boundary used for the whole application
 *
 * This is the only error boundary where it should be a pure component
 * @param children
 */
const GlobalErrorBoundary: React.FC = ({ children }) => {
  // Key={pathname} makes the error boundary reset when clicking somewhere which redirect on another path
  return (
    <ErrorBoundary key={window.location.pathname} FallbackComponent={ErrorPage}>
      {children}
    </ErrorBoundary>
  );
};

export { GlobalErrorBoundary };
