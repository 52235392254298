import React from 'react';
import HttpError from 'standard-http-error';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';

/**
 * This component is only here to throw an error in some case - e.g. Router -
 * So the error boundary can catch it and maybe have a recovering state from it
 */
const NotFoundThrow: React.FC = () => {
  throw new HttpError(404, getReasonPhrase(StatusCodes.NOT_FOUND));
};

export { NotFoundThrow };
