import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import { Typography } from '@components/Typography/Typography.component';
import { useQuery } from 'common/hooks/useQueryParam.hook';
import { useKratosRecoveryFlow } from 'kratos/flows/recovery.flow';
import { useTranslation } from 'react-i18next';
import { RecoveryForm } from 'user/components/RecoveryForm/RecoveryForm.component';
import React from 'react';

export const RecoveryPage: React.FC = () => {
  const { t } = useTranslation();
  const flowId = useQuery().get('flow');
  const [flow, submitHandler] = useKratosRecoveryFlow(flowId, () => {
    toastSuccess(t('toast.recovery'));
  });
  return (
    <Page
      title={t('recovery.meta_title')}
      description={t('recovery.meta_description')}
    >
      <div className="bg-secondary-default text-center">
        <Title size={'h1'} color={'text-white'} bold>
          {t('recovery.title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="flex pt-2 px-4 md:px-8 h-auto mb-8">
        <div className="w-10/12 md:w-5/12 lg:w-3/12 mt-8 md:mt-0 text-center mx-auto">
          <Typography
            color="text-gray-secondary"
            classOverrides={['mx-auto my-3 mb-6 italic dark:text-gray-primary']}
          >
            {t('recovery.description')}
          </Typography>
          <RecoveryForm submitHandler={submitHandler} flow={flow} />
        </div>
      </div>
    </Page>
  );
};
