import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { JobRouter } from '@jobs/pages/job.router';
import { GlobalErrorBoundary } from '@components/GlobalErrorBoundary/GlobalErrorBoundary.component';
import { UserRouter } from '../user/pages/user.router';
import { GeneralRouter } from './general.router';
import { DashboardRouter } from 'user/pages/dashboard.router';

const IndexRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <GlobalErrorBoundary>
        <Switch>
          <Route path={'/_/'} component={JobRouter} />
          <Route path={'/u/'} component={UserRouter} />
          <Route path={'/dashboard/'} component={DashboardRouter} />
          <Route path={'*'} component={GeneralRouter} />
        </Switch>
      </GlobalErrorBoundary>
    </BrowserRouter>
  );
};

export { IndexRouter };
