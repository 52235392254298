import React from 'react';
import { ClassOverrides, Sizing } from '@typings/ClassOverrides.type';

export const UnlinkIcon: React.FC<Sizing & ClassOverrides> = ({
  classOverrides = [],
  width = 'auto',
  height = 'auto',
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.9702 12.557C10.8587 12.5171 10.7338 12.5449 10.6503 12.6292L6.70818 16.571C6.27094 17.0084 5.68869 17.2487 5.06861 17.2487C4.44854 17.2487 3.8665 17.008 3.42945 16.571C2.52551 15.6666 2.52551 14.1958 3.42945 13.2918L7.37101 9.35067C7.45485 9.26682 7.48287 9.14207 7.44299 9.03061C7.40311 8.91895 7.30229 8.84021 7.18449 8.82876L4.43238 8.55533C4.34014 8.54469 4.25016 8.57844 4.18512 8.64368L1.48291 11.3457C0.526617 12.3018 0 13.5753 0 14.9314C0 16.2875 0.526617 17.5608 1.48291 18.5171C2.439 19.4734 3.7125 20 5.06861 20C6.42473 20 7.69822 19.4734 8.65452 18.5171L11.3567 15.8151C11.4218 15.7502 11.4543 15.659 11.4451 15.5678L11.1721 12.8155C11.1602 12.6977 11.0821 12.5971 10.9702 12.557Z"
          fill="#66676B"
        />
        <path
          d="M18.5186 1.48291C17.5623 0.526617 16.2888 0 14.9327 0C13.5766 0 12.3031 0.526617 11.347 1.48291L8.64457 4.18512C8.57954 4.24995 8.54702 4.34096 8.55622 4.43238L8.82965 7.1847C8.84131 7.3027 8.92005 7.40332 9.03171 7.4432C9.14337 7.48328 9.26792 7.45526 9.35177 7.37121L13.2931 3.42925C13.7304 2.992 14.3126 2.75129 14.9327 2.75129C15.5528 2.75129 16.135 2.99221 16.5725 3.42925C17.4764 4.33319 17.4764 5.80404 16.5725 6.70818L12.6307 10.6497C12.5469 10.7336 12.5188 10.8585 12.5587 10.97C12.5986 11.0815 12.6994 11.1602 12.8172 11.1716L15.5691 11.4451C15.5794 11.4465 15.5894 11.4469 15.5992 11.4469C15.6802 11.4469 15.7583 11.415 15.8164 11.3571L18.5186 8.65472C20.4956 6.67751 20.4956 3.46013 18.5186 1.48291Z"
          fill="#66676B"
        />
        <path
          d="M1.81073 7.70927C1.81257 7.70927 1.81441 7.70927 1.81604 7.70927L5.1279 7.65159C5.27945 7.64893 5.40645 7.53604 5.42649 7.38552C5.44653 7.23521 5.35389 7.09266 5.20828 7.05013L2.02833 6.12287C1.94243 6.09772 1.84979 6.11142 1.77514 6.1605C1.70029 6.20918 1.65039 6.28812 1.63853 6.37688L1.50662 7.3618C1.49476 7.45056 1.52216 7.53973 1.58167 7.6064C1.63976 7.67184 1.7232 7.70927 1.81073 7.70927Z"
          fill="#66676B"
        />
        <path
          d="M7.04904 5.20564C7.08749 5.33775 7.20876 5.42651 7.34333 5.42651C7.35683 5.42651 7.37053 5.42569 7.38423 5.42364C7.53455 5.4036 7.64764 5.2766 7.6503 5.12506L7.70777 1.8132C7.7092 1.72362 7.67178 1.63814 7.6049 1.57883C7.53803 1.51952 7.44886 1.49191 7.3601 1.50418L6.37517 1.63671C6.28662 1.64857 6.20768 1.69867 6.15901 1.77352C6.11033 1.84837 6.09663 1.94081 6.12158 2.0265L7.04904 5.20564Z"
          fill="#66676B"
        />
        <path
          d="M18.1874 12.2907L14.8758 12.3482C14.724 12.3511 14.5972 12.4635 14.577 12.6143C14.5567 12.7646 14.6494 12.9071 14.795 12.9499L17.9749 13.8773C18.0034 13.8853 18.032 13.8896 18.0608 13.8896C18.1199 13.8896 18.1778 13.8726 18.2281 13.8399C18.303 13.7912 18.3527 13.7123 18.3647 13.6233L18.4969 12.6386C18.5087 12.5496 18.4813 12.4607 18.4218 12.394C18.3623 12.3265 18.2729 12.2868 18.1874 12.2907Z"
          fill="#66676B"
        />
        <path
          d="M12.9478 14.7948C12.9055 14.6492 12.7642 14.5547 12.6126 14.5768C12.4625 14.5972 12.3494 14.724 12.3466 14.8756L12.2891 18.1868C12.2877 18.2764 12.3251 18.3619 12.3918 18.4212C12.4482 18.4715 12.5212 18.4989 12.5959 18.4989C12.6094 18.4989 12.6231 18.4979 12.6368 18.4958L13.6215 18.3637C13.7103 18.3518 13.7892 18.3019 13.8381 18.2271C13.8867 18.152 13.9004 18.0596 13.8757 17.9741L12.9478 14.7948Z"
          fill="#66676B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
