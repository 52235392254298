import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { Typography } from '@components/Typography/Typography.component';
import {
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import { Size } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React, { InputHTMLAttributes, useContext, useState } from 'react';
import {
  getInputClasses,
  InputError,
  InputLabel,
  InputProps,
} from '../StyledField/StyledField.component';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from 'common/contexts/theme.context';
import { ColorSetEnum } from 'common/constants/getColorSet';

/**
 * Create a styled input for a form
 *
 * We omit `className` so we don't override current hard coded className
 * @param classOverrides
 * @param {UseFormRegisterReturn} register -- Register method from useForm hook
 * @param {string} error -- Define an error for an input, show a little hint below with it
 * @param {string} label -- Label above input
 * @param {string} prefix -- Content inside a prefix, if defined it will show the prefix before the input
 * @param {Omit<InputHTMLAttributes<HTMLInputElement>, "className">} rest -- HTML attributes
 * @see InputHTMLAttributes
 */
type Props = {
  labelClassOverrides?: string[];
  tooltipClassOverrides?: string[];
  tooltipTitle?: string;
  tooltipIcon?: IconDefinition;
  prefix?: string;
  prefixSize?: Size;
  useEyeIcon?: boolean;
  showError?: boolean;
  advise?: string;
  border?: string;
  autoCompletion?: boolean;
};
export const StyledInput: React.FC<
  InputProps<InputHTMLAttributes<HTMLInputElement> & Props>
> = ({
  bgColor = 'bg-white',
  borderColor = 'border-transparent',
  classOverrides = [],
  disabled,
  defaultValue,
  icon,
  placeholderColor = 'placeholder-input-field',
  register = {},
  error,
  label,
  labelClassOverrides = [],
  tooltipClassOverrides = [],
  advise,
  tooltipTitle = '',
  tooltipIcon = faQuestionCircle,
  prefix,
  prefixSize,
  showError = true,
  type,
  useEyeIcon = false,
  border = 'rounded-lg',
  autoCompletion = true,
  ...rest
}) => {
  const [customType, setCustomType] = useState(type);
  const { theme } = useContext(ThemeContext);
  return (
    <div className="relative w-full">
      <div className="flex">
        <InputLabel
          classOverrides={[
            'pl-2 mb-1 dark:text-gray-primary',
            ...labelClassOverrides,
          ]}
          label={label}
        />
        {tooltipTitle && (
          <Tooltip
            title={tooltipTitle}
            icon={tooltipIcon}
            place="right"
            size="lg"
            multiline
            classOverrides={['ml-2']}
            colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
            tooltipClass={cn('', ...tooltipClassOverrides)}
          />
        )}
      </div>
      <div
        className={cn(
          'relative',
          prefix ? 'flex rounded-lg dark:bg-gray-third ' + bgColor : '',
        )}
      >
        {prefix && (
          <Typography
            color="text-gray-secondary"
            classOverrides={[
              'items-center flex dark:bg-secondary-default dark:text-gray-primary dark:border-secondary-default border-l-2 border-t-2 border-b-2 border-gray-input w-max px-2 py-4 rounded-r-none rounded-tl-lg rounded-br-none rounded-t-lg rounded-b-lg z-40',
              bgColor,
            ]}
            size={prefixSize ?? 'base'}
          >
            {prefix}
          </Typography>
        )}
        {advise && (
          <div className="mb-2 -mt-1">
            <Typography
              light
              size={'sm'}
              classOverrides={[
                'text-gray-secondary ml-6 dark:text-gray-primary',
              ]}
            >
              {advise}
            </Typography>
          </div>
        )}
        <input
          id={label}
          className={
            prefix
              ? 'z-40 -ml-1 relative ' +
                getInputClasses(
                  bgColor,
                  border,
                  borderColor,
                  Boolean(error),
                  placeholderColor,
                  ...classOverrides,
                )
              : getInputClasses(
                  bgColor,
                  border,
                  borderColor,
                  Boolean(error),
                  placeholderColor,
                  ...classOverrides,
                )
          }
          data-testid="styled-input"
          defaultValue={defaultValue}
          {...register}
          disabled={disabled}
          aria-label={label}
          type={customType ?? type}
          autoComplete={autoCompletion ? 'on' : 'off'}
          {...rest}
        />
        {useEyeIcon && (
          <FontAwesomeIcon
            className="absolute right-3 top-6 transform mt-0.5 -translate-y-1/2 cursor-pointer hover:opacity-70 dark:text-white text-secondary-dark max-h-max"
            data-testid="eye-icon"
            icon={faEye}
            onClick={() =>
              customType === 'text'
                ? setCustomType('password')
                : setCustomType('text')
            }
            size={'lg'}
          />
        )}
      </div>
      {icon}
      {showError && <InputError error={error} label={label} />}
    </div>
  );
};
