import React, { useContext } from 'react';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { useTranslation } from 'react-i18next';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { UserContext } from 'user/contexts/user.context';
import { motion } from 'framer-motion';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

type NavBarButtonsProps = {
  logged?: boolean;
};
/**
 * NavBar buttons when the user is not logged in on Desktop
 * @constructor
 */
export const NavBarButtons: React.FC<ClassOverrides & NavBarButtonsProps> = ({
  classOverrides = [],
  logged = false,
}) => {
  const { action } = useContext(UserContext);
  const { t } = useTranslation();
  const delayStart = logged ? 0.4 : 0.2;
  return (
    <div
      className={cn(
        'xl:absolute lg:right-0 flex -top-1 col-span-2 h-full items-center',
        ...classOverrides,
      )}
    >
      {logged ? (
        <>
          {/* <motion.li
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: delayStart + 0.1 },
            }}
          >
            <TrackedLink to={'/u/addjob'} name="NavBar add a job">
              <SecondaryButton
                content={t('action:secondary')}
                classOverrides={[
                  'text-md mt-6 shadow-lg dark:bg-dark-secondary dark:text-gray-primary',
                ]}
                color="text-secondary-default"
                bold={true}
              />
            </TrackedLink>
          </motion.li> */}
          <motion.li
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: delayStart + 0.2 },
            }}
          >
            <PrimaryButton
              content={t('action:logout')}
              classOverrides={['text-md mt-6']}
              action={action.logout}
              bgColor={'bg-red-700'}
              color="text-white"
            />
          </motion.li>
        </>
      ) : (
        <>
          {/* <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0, transition: { delay: delayStart } }}
          >
            <TrackedLink to={'/u/addjob'} name="NavBar add a job">
              <SecondaryButton
                content={t('action:secondary')}
                classOverrides={[
                  'text-md mt-6 xl:mt-2 shadow-lg dark:bg-dark-secondary dark:text-gray-primary',
                ]}
                color="text-secondary-default"
                bold={true}
              />
            </TrackedLink>
          </motion.div> */}
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: delayStart + 0.1 },
            }}
            className="mx-6 my-6 xl:my-0 xl:mt-2 "
          >
            <TrackedLink name="NavBar signin" to={'/u/signin'}>
              <PrimaryButton
                content={t('action:get_started')}
                classOverrides={['text-md ']}
                margin={'m-0'}
              />
            </TrackedLink>
          </motion.div>
        </>
      )}
    </div>
  );
};
