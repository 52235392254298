import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { Title } from '@components/Titles/Title.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { Typography } from '@components/Typography/Typography.component';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { BgColor, ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { getNewlineText } from '@utils/components.utils';
import { simpleFadeIn } from '@utils/motion.util';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import { useOnJobDetailsUpdate } from 'common/hooks/useOnJobDetailsUpdate';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from 'user/contexts/user.context';
import { JobUpdateRequestBody } from 'user/types/forms';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

type Props = {
  currentPolicy: string;
  setShowPolicyModal: () => void;
  job: JobResponse;
};
export interface Policy {
  title: string;
  content: string;
  button: string;
  tooltip: string;
  btnColor: BgColor;
  action: () => void;
}

export const UpdatePolicy: React.FC<Props & ClassOverrides> = ({
  currentPolicy,
  classOverrides = [],
  setShowPolicyModal,
  job,
}) => {
  let policyContent: Policy = {
    title: '',
    content: '',
    button: '',
    tooltip: 'my_jobs.modify_your_job.policy.tooltip',
    btnColor: 'bg-orange-warning',
    action: () => {},
  };
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [loading, updateJob] = useOnJobDetailsUpdate();
  const { identity } = useContext(UserContext);
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      policy: job.policy,
    },
  });

  switch (currentPolicy) {
    case 'public':
      policyContent.title = 'my_jobs.modify_your_job.policy.private.title';
      policyContent.content = 'my_jobs.modify_your_job.policy.private.content';
      policyContent.button = 'my_jobs.modify_your_job.policy.private.button';
      policyContent.action = setShowPolicyModal;
      break;
    case 'private':
      policyContent.title = 'my_jobs.modify_your_job.policy.public.title';
      policyContent.content = 'my_jobs.modify_your_job.policy.public.content';
      policyContent.button = 'my_jobs.modify_your_job.policy.public.button';
      policyContent.btnColor = 'bg-green-pearl';
      break;
    case 'limited':
      policyContent.title = 'my_jobs.modify_your_job.policy.private.title';
      policyContent.content = 'my_jobs.modify_your_job.policy.limited.content';
      policyContent.button = 'my_jobs.modify_your_job.policy.limited.button';
      policyContent.btnColor = 'bg-primary-default';
      break;
    default:
      break;
  }

  const submitHandler = (data: JobUpdateRequestBody): void => {
    data.policy = 'public';
    updateJob(data, identity?.traits.username, job.name);
  };
  return (
    <form
      className={cn(
        'w-11/12 md:w-11/12 xl:w-8/12 2xl:w-5/12 mx-auto',
        ...classOverrides,
      )}
      onSubmit={
        currentPolicy === 'private' ? handleSubmit(submitHandler) : undefined
      }
    >
      <motion.div
        variants={simpleFadeIn()}
        initial={'hidden'}
        animate={'show'}
        className="text-center flex flex-col items-center py-2 px-4 md:px-8 lg:px-6 bg-gray-primary mt-6 dark:bg-secondary-default filter shadow rounded-2xl w-full"
      >
        <div className="flex items-center">
          <Title
            bold
            color="text-gray-secondary"
            font="comfortaa"
            size="h3"
            classOverrides={['py-2 mr-2']}
          >
            {t(policyContent.title)}
          </Title>
          <Tooltip
            colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
            icon={faQuestionCircle}
            size="lg"
            getContent={() => (
              <Trans i18nKey={t('link_job.privacy.label_tooltip_title')}>
                The number of private jobs you can define depends of{' '}
                <TrackedLink
                  name={'license level link'}
                  to="/pricing"
                  className="underline"
                >
                  your license level
                </TrackedLink>
              </Trans>
            )}
            delayHide={300}
          />
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary']}
        >
          {getNewlineText(t(policyContent.content))}
        </Typography>
        {currentPolicy === 'limited' ? (
          <TrackedLink to="/pricing" name="pricing">
            <PrimaryButton
              bgColor={policyContent.btnColor}
              classOverrides={['text-base xl:w-52 xl:text-lg mb-2 mt-3']}
              content={t(policyContent.button)}
              font="comfortaa"
            />
          </TrackedLink>
        ) : (
          <PrimaryButton
            bgColor={policyContent.btnColor}
            classOverrides={['text-base xl:w-52 xl:text-lg mb-2 mt-3']}
            content={t(policyContent.button)}
            font="comfortaa"
            action={policyContent.action ? policyContent.action : undefined}
            submitButton={currentPolicy === 'private' ? true : false}
          />
        )}
        {(isSubmitting || loading) && (
          <SpinnerIcon color="text-secondary-dark" />
        )}
      </motion.div>
    </form>
  );
};
