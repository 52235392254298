import React from 'react';
import { Page } from '@components/Page/Page.component';
import { Title } from '@components/Titles/Title.component';
import { Trans, useTranslation } from 'react-i18next';
import R2Wave from '@images/R2/R2_wave.svg';
import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

export const SignUpSuccessful: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page title={t('signup_title')} navBarCutter>
      <div className="w-5/6 md:w-4/6 2xl:w-3/6 mx-auto text-center mt-12 mb-32">
        <Title size={'h1'} color={'text-secondary-default'} bold>
          {t('signup.title')}
        </Title>
        <div className={'relative w-full md:w-4/6 mx-auto py-8'}>
          <div className="relative order-first md:order-last">
            <div className="w-full text-center mt-2">
              <Typography
                color={'text-gray-secondary'}
                size={'lg'}
                classOverrides={['dark:text-gray-primary']}
              >
                <Trans i18nKey="signup.success">
                  You're almost done!
                  <div className="pt-2 xl:pt-4" />
                  Just check your email to activate your account
                  <div className="pt-2 xl:pt-4" />
                  In the meantime you can check our
                  <TrackedLink
                    name="jobs explorer"
                    className="underline hover:text-secondary-default text-green-pearl"
                    to={'/_/jobs'}
                  >
                    {' '}
                    jobs page
                  </TrackedLink>
                </Trans>
              </Typography>
            </div>
          </div>
          <img
            src={R2Wave}
            alt={'R2 waving'}
            className={
              'w-4/6 xs:w-3/6 sm:w-2/6 md:w-3/6 mt-4 md:mt-16 order-last md:order-first mx-auto'
            }
          />
        </div>
      </div>
    </Page>
  );
};
