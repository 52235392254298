import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
    testid?: string;
  };

export const ExclamationIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '5',
  width = '5',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <path
        d="M1.65255 0.890259C1.23349 0.890259 0.893799 1.23913 0.893799 1.6695V6.68755C0.893799 7.11793 1.23349 7.4668 1.65255 7.4668C2.07161 7.4668 2.4113 7.11793 2.4113 6.68755V1.6695C2.4113 1.23913 2.07161 0.890259 1.65255 0.890259Z"
        fill="currentColor"
      />
      <path
        d="M1.65074 10.5022C2.25607 10.5022 2.74679 10.0493 2.74679 9.49047C2.74679 8.93169 2.25607 8.4787 1.65074 8.4787C1.04541 8.4787 0.554688 8.93169 0.554688 9.49047C0.554688 10.0493 1.04541 10.5022 1.65074 10.5022Z"
        fill="currentColor"
      />
    </svg>
  );
};
