import React, { useContext, useEffect, useRef, useState } from 'react';
import { cn } from '@utils/classes.util';
import { useTranslation } from 'react-i18next';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import { Title } from '@components/Titles/Title.component';
import Skeleton from 'react-loading-skeleton';
import { ThemeContext } from 'common/contexts/theme.context';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { motion } from 'framer-motion';
import GitlabLogo from '../../../assets/images/gitlab.png';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { MagicWandAltIcon } from '@components/Icons/MagicWandAltIcon/MagicWandAltIcon.component';
import { useWindowSize } from 'common/hooks/useWindowSize.hook';
import { LG_SCREEN_SIZE } from 'common/constants/style.constant';
import { LicenseProject } from 'common/hooks/useLicense.hook';
import { UserContext } from 'user/contexts/user.context';
import { GreenCheckIcon } from '@components/Icons/GreenCheckIcon/GreenCheckIcon.component';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { useOnKeyPress } from 'common/hooks/useOnKeyPress.hook';
import { faArrowAltCircleUp, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KebabIcon } from '@components/Icons/KebabIcon/KebabIcon';
import { ActionBox } from '@components/ActionBox/ActionBox.component';
import { AddProjectPipelineModal } from '../AddProjectPipelineModal/AddProjectPipelineModal.component';
import { AutoPipelineResponseType } from 'user/hooks/useOnProjectPipeline.hook';
import { AutoPipelineResponse } from '../AddProjectPipelineModal/AutoPipelineResponse.component';
import { useHistory } from 'react-router-dom';

type Props = {
  isLastRow?: boolean;
  index: number;
  project: GitLabProjectResponse;
  autoPipelineRemaining: number;
  projectInLicense: LicenseProject | undefined;
};

export const GitLabProjectRow: React.FC<Props> = ({
  index,
  project,
  autoPipelineRemaining,
  projectInLicense,
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { push: setLocation } = useHistory();
  const baseColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.backgroundColor
      : undefined;
  const highlightColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.color
      : undefined;
  const windowSize = useWindowSize();
  const { identity } = useContext(UserContext);
  const [openActions, setOpenActions] = useState(false);
  const [showPipelineProjectModal, setShowPipelineProjectModal] =
    useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpenActions(false));
  useOnKeyPress('Escape', () => setOpenActions(false));
  const [autoPipelineResponse, setAutoPipelineResponse] =
    useState<AutoPipelineResponseType>();
  const actions = t('my_projects.row.actions', {
    returnObjects: true,
  }) as Array<string>;

  useEffect(() => {
    const lightPipelineUrl = sessionStorage.getItem('lightPipelineUrl');
    if (lightPipelineUrl === project.web_url) {
      sessionStorage.removeItem('lightPipelineUrl');
      setShowPipelineProjectModal(true);
    }
  }, [project.web_url]);

  const ActionItem: React.FC<{ action: string; index: number }> = ({
    action,
    index,
  }) => {
    let item;
    if (project) {
      switch (index) {
        case 0:
          /**
           * See your job
           */
          item = (
            <button
              className={cn('flex justify-between')}
              onClick={() =>
                setLocation(`/dashboard/project/${projectInLicense?.id}`)
              }
            >
              <span className="w-min ">
                <FontAwesomeIcon icon={faEdit} className="text-lg" />
              </span>
              <span className="hidden sm:block sm:ml-2">{action}</span>
            </button>
          );
          break;
        default:
          item = <></>;
          break;
      }
    }
    return <div className="flex items-center">{item}</div>;
  };

  return (
    <div className="w-full relative">
      {project && (
        <AddProjectPipelineModal
          project={project}
          autoPipelineRemaining={autoPipelineRemaining}
          showModal={showPipelineProjectModal}
          setShowModal={setShowPipelineProjectModal}
          setAutoPipelineResponse={setAutoPipelineResponse}
          setShowResponseModal={setShowResponseModal}
        />
      )}

      {autoPipelineResponse && autoPipelineResponse.project && (
        <AutoPipelineResponse
          fetchResult={autoPipelineResponse}
          project={autoPipelineResponse.project}
          withWrite={autoPipelineResponse.write}
          showResponseModal={showResponseModal}
          setShowResponseModal={setShowResponseModal}
        />
      )}
      <span
        className={cn(
          'py-2 px-4  dark:bg-secondary-default flex cursor-pointer border-gray-input dark:border-dark-border w-full relative h-16 rounded-lg transition duration-500 filter',
          false
            ? index === 0
              ? 'border-2 bg-white dark:bg-dark-primary'
              : 'border-b-2 border-r-2 border-l-2 bg-white dark:bg-dark-primary'
            : 'bg-gray-primary shadow border-b-2',
        )}
      >
        <OutsideLink
          className="flex items-center hover:opacity-70 "
          name={'Project row name'}
          rel="noreferrer"
          target="_blank"
          to={project?.web_url ?? ''}
        >
          <div className="h-8 w-8 lg:w-10 lg:h-10 z-20 cursor-pointer transition duration-300 filter hover:grayscale hover:contrast-100">
            {project?.avatar_url ? (
              <motion.img
                src={project?.avatar_url}
                className=" drop-shadow-button-outer rounded-3xl border hover:border-gray-secondary transition duration-300 border-gray-input dark:border-gray-primary p-0.5 w-full h-full"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = GitlabLogo;
                }}
              />
            ) : (
              <motion.img
                className=" drop-shadow-button-outer rounded-3xl border hover:border-gray-secondary transition duration-300 border-gray-input dark:border-gray-primary p-0.5 w-full h-full"
                src={GitlabLogo}
              />
            )}
          </div>

          {project?.name_with_namespace ? (
            <div className="flex line-clamp-2-left items-center w-44 sm:w-80 pl-4">
              <Title
                bold
                font="comfortaa"
                size="h3-card"
                classOverrides={[
                  '',
                  false ? 'text-primary-default' : 'text-secondary-default',
                ]}
              >
                {project?.name_with_namespace}
              </Title>
            </div>
          ) : (
            <Skeleton
              width={200}
              height={25}
              baseColor={baseColor}
              highlightColor={highlightColor}
            />
          )}
        </OutsideLink>
        <div className="absolute right-4 top-5 xl:top-2">
          {project ? (
            <>
              <div
                className={cn(
                  'flex justify-between',
                  projectInLicense && 'w-16 sm:w-24 xl:w-max xl:items-center',
                )}
              >
                {projectInLicense && (
                  <Tooltip
                    title={t('my_projects.in_license')}
                    place="left"
                    multiline
                    classOverrides={['xl:mr-2']}
                    tooltipClass="max-w-18 bg-secondary-semi-light"
                    colorSet={
                      theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
                    }
                  >
                    <GreenCheckIcon classOverrides={['block w-5 z-10']} />
                  </Tooltip>
                )}
                <SecondaryButton
                  bold
                  color="text-white"
                  classOverrides={[
                    'text-sm items-center hidden xl:flex xl:justify-center w-54',
                    'h-11 mt-0.5 font-comfortaa xl:mr-3',
                    projectInLicense?.updateAvailable
                      ? 'bg-orange-warning'
                      : 'bg-primary-default dark:bg-secondary-default-high-opacity',
                  ]}
                  content=""
                  action={() => {
                    if (identity?.traits.gitlab_token) {
                      setShowPipelineProjectModal(true);
                    }
                  }}
                >
                  {projectInLicense && projectInLicense?.updateAvailable ? (
                    <>
                      {' '}
                      {t('action:reload_pipeline')}
                      <FontAwesomeIcon
                        icon={faArrowAltCircleUp}
                        className="text-gray-primary ml-2 text-lg"
                      />
                    </>
                  ) : (
                    t('my_projects.project_row_actions.0')
                  )}{' '}
                  {!projectInLicense?.updateAvailable && (
                    <MagicWandAltIcon classOverrides={['w-5 h-5 ml-2']} />
                  )}
                </SecondaryButton>
                <div
                  className="xl:hidden w-min hover:opacity-70 flex items-center"
                  onClick={() => {
                    if (identity?.traits.gitlab_token) {
                      setShowPipelineProjectModal(true);
                    }
                  }}
                >
                  <div
                    title={
                      projectInLicense && projectInLicense?.updateAvailable
                        ? t('my_projects.magic_wand_reload_title')
                        : t('my_projects.magic_wand_title')
                    }
                    className={cn('w-min mb-0.5', projectInLicense && '')}
                  >
                    {projectInLicense && projectInLicense?.updateAvailable ? (
                      <FontAwesomeIcon
                        icon={faArrowAltCircleUp}
                        className="text-orange-warning text-xl -mb-0.5 "
                      />
                    ) : (
                      <MagicWandAltIcon
                        classOverrides={[
                          'w-5 h-5',
                          !projectInLicense && 'mr-4',
                          projectInLicense && projectInLicense?.updateAvailable
                            ? 'text-orange-warning'
                            : 'text-secondary-default dark:text-primary-default',
                        ]}
                      />
                    )}
                  </div>
                </div>
                <button
                  data-testid="kebabButton"
                  onClick={() => setOpenActions(!openActions)}
                  title={t('my_jobs.job_row_kebab_title')}
                  disabled={!projectInLicense}
                >
                  <KebabIcon
                    classOverrides={[
                      cn(
                        'h-5 md:h-6 cursor-pointer xl:mt-1 -mr-1 transition',
                        openActions
                          ? 'text-primary-default'
                          : projectInLicense
                          ? 'text-secondary-default dark:text-gray-primary hover:opacity-70'
                          : 'text-gray-secondary opacity-50',
                      ),
                    ]}
                  />
                </button>
              </div>
            </>
          ) : (
            <Skeleton
              width={windowSize.width > LG_SCREEN_SIZE ? 215 : 20}
              height={windowSize.width > LG_SCREEN_SIZE ? 44 : 20}
              baseColor={baseColor}
              highlightColor={highlightColor}
              className={cn(windowSize.width < LG_SCREEN_SIZE && 'mt-3')}
            />
          )}
        </div>
      </span>
      <ActionBox
        bgColor={'bg-primary-default'}
        classOverrides={[
          'dark:bg-secondary-default absolute -right-10 sm:-right-20 lg:-right-48 top-2 sm:top-14 lg:top-2 mt-0.5',
          ' text-white font-comfortaa text-sm z-10',
        ]}
        isOpen={openActions}
      >
        {actions.map((action, index) => {
          return (
            <div
              key={index}
              ref={ref}
              className={cn(
                'flex items-center justify-start hover:opacity-70 transition',
              )}
            >
              <ActionItem index={index} action={action} />
            </div>
          );
        })}
      </ActionBox>
    </div>
  );
};
