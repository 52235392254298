import { CheckIcon } from '@components/Icons/CheckIcon/CheckIcon.component';
import { CloseIcon } from '@components/Icons/CloseIcon/CloseIcon.component';
import { ExclamationIcon } from '@components/Icons/ExclamationIcon/ExclamationIcon.component';
import { TableCell } from '@components/Table/TableCell.component';
import { TableRow } from '@components/Table/TableRow.component';
import { VersionAnalysis } from '@jobs/models/JobCreationResponse.model';
import { Message, WarningLevel } from '@jobs/models/JobVersion.model';
import { cn } from '@utils/classes.util';

export enum ImportState {
  imported = 'Version imported',
  notImported = 'Version not imported',
  missingData = 'Version imported with missing data',
}

type VersionRowProps = {
  headers: Record<string, string>[];
  jobName: string;
  versionAnalysis: VersionAnalysis;
  versionIndex: number;
};

/*
 * The icon to show on each table cell depending on the file affected
 * Warning level does NOT matter for now
 *
 * jobname.yml: red cross, file is critical
 * readme.md/changelog.md: orange exclamation mark, files not critical
 */
const FileStateIcon: React.FC<{
  filename: string;
  criticalErrorPresent: boolean;
  jobName: string;
  message: Message;
}> = ({ filename, criticalErrorPresent, jobName, message }) => {
  let icon;
  if (!message && !criticalErrorPresent) {
    icon = <CheckIcon color="text-green-pearl" width="12" height="12" />;
  } else if (filename.includes(jobName)) {
    icon = (
      <CloseIcon
        color="text-red-alert"
        dataTestId="closeIcon"
        strokeWidth="4"
        width="12"
        height="12"
      />
    );
  } else {
    icon = (
      <ExclamationIcon color="text-orange-warning" width="15" height="15" />
    );
  }

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
      {icon}
    </div>
  );
};

export const VersionRow: React.FC<VersionRowProps> = ({
  headers,
  jobName,
  versionAnalysis,
  versionIndex,
}) => {
  /*
   * Obtain necessary filenames (jobname.yml, changelog.md and readme.md) from headers
   */
  const filenames = headers.map((header) => Object.keys(header)[0]);

  /*
   * Obtain messages for readme.md and changelog.md
   */
  const keyFilesMessage = versionAnalysis.messages.filter((message) =>
    message.filename ? filenames.includes(message.filename) : false,
  );

  /**
   * Obtain messages related to <jobname>.yml
   */
  const jobNameMessage = versionAnalysis.messages.filter((message) =>
    message.filename?.includes(jobName),
  )[0];

  /**
   * Determine whether there is or not a critical version error which is when there
   * is any message with no filename associated or any message of level 0
   */
  const criticalErrorPresent = versionAnalysis.messages.some(
    (message) => message.level === WarningLevel.CRITICAL,
  );

  /*
   * If <jobname>.yml is not found: version is NOT imported
   * If there any messages of level 0 (CRITICAL): version it NOT imported
   * If there are any messages of level 1 (WARNING): version is imported but with missing data
   * If no messages are found: version is imported
   */
  const jobImportState =
    jobNameMessage || criticalErrorPresent
      ? ImportState.notImported
      : keyFilesMessage.some((message) => message.level === 1)
      ? ImportState.missingData
      : ImportState.imported;

  return (
    <TableRow
      key={versionAnalysis.version}
      classOverrides={[
        versionIndex % 2 === 0
          ? 'bg-white dark:bg-secondary-default-high-opacity'
          : 'bg-gray-primary dark:bg-secondary-default',
        'divide-x-2 divide-gray-input divide-opacity-70',
      ]}
    >
      {/* First cell with version tag and the import state */}
      <TableCell classOverrides={['text-right pr-4 py-1']}>
        <div className="text-lg dark:text-gray-primary">
          {versionAnalysis.version}
        </div>
        <div
          className={cn(
            'text-xs',
            jobImportState === ImportState.notImported
              ? 'text-red-alert'
              : jobImportState === ImportState.missingData
              ? 'text-orange-warning'
              : 'text-green-pearl',
          )}
        >
          {jobImportState}
        </div>
      </TableCell>

      {/* File state for <jobname>.yml, readme.md and changelog.md */}
      {headers.map((header, index) => (
        <TableCell
          dataTestId={`${index !== 0 ? filenames[index] : jobName}-state`}
          classOverrides={['relative']}
          key={index}
        >
          <FileStateIcon
            filename={index === 0 ? jobName : filenames[index]}
            criticalErrorPresent={criticalErrorPresent}
            jobName={jobName}
            message={
              index === 0
                ? jobNameMessage
                : keyFilesMessage.filter(
                    (message) => message.filename === filenames[index],
                  )[0]
            }
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
