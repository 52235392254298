import axios, { Method, AxiosRequestConfig } from 'axios';
import { IApiOptions, IApiResponse } from '@typings/http.type';
import { StatusCodes } from 'http-status-codes';
import { API_URL } from '../constants/routes';
import HttpError from 'standard-http-error';

export const axiosInstance = axios.create({
  withCredentials: true,
});

export class HttpService {
  /**
   * @typeParam T - The entity fetched
   * @param {Method} method - Call method
   * @param {string} path - Path on the API
   * @param {Partial<IApiOptions>|null} options - Parsed options for header / body
   */
  async fetch<T>(
    method: Method,
    path: string,
    options?: Partial<IApiOptions>,
  ): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>(method, path, options);
  }

  async get<T>(path: string): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>('GET', path);
  }

  async post<T>(path: string): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>('POST', path);
  }

  async put<T>(path: string): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>('PUT', path);
  }

  async patch<T>(path: string): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>('PATCH', path);
  }

  async delete<T>(path: string): Promise<IApiResponse<T>> {
    return HttpService._fetch<T>('DELETE', path);
  }

  private static async _fetch<A>(
    method: Method,
    path: string,
    options?: Partial<IApiOptions>,
  ): Promise<IApiResponse<A>> {
    const config = HttpService.setRequestConfiguration(method, path, options);

    try {
      const { data, status } = await axiosInstance.request(config);
      return {
        status,
        response: data,
      };
    } catch (e) {
      const httpError = e as HttpError;
      if (httpError?.response === undefined) {
        return {
          status: StatusCodes.SERVICE_UNAVAILABLE,
          error: { name: '', message: httpError.message },
        };
      }

      const { status, data, statusText } = httpError.response;
      return {
        status,
        error: data.message || statusText,
        response: data,
      };
    }
  }

  /**
   * Set header & body configuration for a request
   * @param {Method} method
   * @param {string} path -- Requested path (can be HTTP URL)
   * @param {IApiOptions} options
   * @private
   */
  private static setRequestConfiguration(
    method: Method,
    path: string,
    options?: Partial<IApiOptions>,
  ): AxiosRequestConfig {
    const isUrl = /^(http|https).+/.test(path);
    const { body, headers, withCredentials, timeout } = options || {};
    const baseConfig: AxiosRequestConfig = {
      ...(!isUrl && { baseURL: API_URL }),
      method,
      url: path,
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: withCredentials ?? true,
    };
    if (body) baseConfig.data = body;
    if (headers)
      baseConfig.headers = {
        ...baseConfig.headers,
        ...headers,
      };
    if (timeout) baseConfig.timeout = timeout;
    return baseConfig;
  }
}
