import React from 'react';
import { Label } from '@components/Buttons/Label/Label.component';
import { LabelWithTooltip } from '@components/Form/LabelWithTooltip/LabelWithTooltip.component';
import { SelectInput } from '@components/Form/SelectInput/SelectInput.component';
import { CloseIcon } from '@components/Icons/CloseIcon/CloseIcon.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { motion } from 'framer-motion';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { labels } from 'common/constants/general';
import { Place } from 'react-tooltip';

type LabelSelectInputProps = ClassOverrides & {
  control: Control<any>;
  defaultLabels?: string[];
  inputClassOverrides?: string[];
  inputAndDisplayContainerClassOverrides?: string[];
  tooltipPlace?: Place;
};

type LabelsDisplayProps = {
  labelsSelected: string[];
  removeLabel: (index?: number | number[] | undefined) => void;
};

const LabelsDisplay: React.FC<LabelsDisplayProps> = ({
  labelsSelected,
  removeLabel,
}) => {
  const removeLabelFromSelected = (index: number) => {
    removeLabel(index);
  };

  return (
    <div className="flex flex-wrap gap-3 justify-center">
      {labelsSelected.map(
        (label, index) =>
          label !== 'community' &&
          label !== 'official' && (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              key={label}
            >
              <Label
                action={() => removeLabelFromSelected(index)}
                key={index}
                content={label}
                fontSize="sm"
                font="comfortaa"
                classOverrides={[
                  'px-2 flex items-center cursor-pointer hover:opacity-70 transition-all',
                ]}
                data-testid={'label-' + label}
              >
                <CloseIcon height="12" width="12" classOverrides={['mr-3']} />
              </Label>
            </motion.div>
          ),
      )}
    </div>
  );
};

export const LabelSelectInput: React.FC<LabelSelectInputProps> = ({
  classOverrides = [],
  control,
  inputClassOverrides = [],
  inputAndDisplayContainerClassOverrides = [],
  tooltipPlace,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    keyName: 'name',
    control,
    name: 'labels',
  });
  const isLabelSelected = (label: string): boolean => {
    return fields.find((field) => field.name === label) !== undefined;
  };

  const onLabelSelect = (label: string) => {
    if (!isLabelSelected(label)) {
      append({ name: label }, { shouldFocus: false });
    }
  };

  return (
    <div className={cn(...classOverrides)}>
      {tooltipPlace && (
        <LabelWithTooltip
          labelText={t('common_job_form.labels.labels')}
          tooltipTitle={t('link_job.labels_tooltip')}
          tooltipPlace={tooltipPlace}
        />
      )}
      <div className={cn(...inputAndDisplayContainerClassOverrides)}>
        {/* Input and items */}
        <SelectInput
          classOverrides={[cn('mb-3', ...inputClassOverrides)]}
          customOnOptionClick={(option) => onLabelSelect(option)}
          options={labels.filter((label) => !isLabelSelected(label))}
          placeholder={t('link_job.job_labels_input_placeholder')}
          useFilter
        />
        {/* Labels selected */}
        <LabelsDisplay
          labelsSelected={fields.map((field) => field.name)}
          removeLabel={remove}
        />
      </div>
    </div>
  );
};
