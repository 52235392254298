import { useContext, useEffect, useReducer } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { JobMapper } from '../job.mapper';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { IJobApi } from '../types/IJobApi.type';
import { Job } from '@jobs/models/Job.model';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { buildRoute } from '@utils/routes.util';
import { API_ROUTES } from 'common/constants/routes';

export const useJob = (ownerName: string, jobName: string) => {
  const [job, dispatch] = useReducer(httpReducer<Job>(), null);
  const httpService = useContext(HttpContext);

  useEffect(() => {
    if (!ownerName || !jobName) {
      dispatch({
        type: ActionType.FAIL,
        error: {
          message: getReasonPhrase(StatusCodes.NOT_FOUND),
          code: StatusCodes.NOT_FOUND,
        },
      });
      return;
    }
    httpService
      .fetch<IJobApi>(
        'GET',
        buildRoute(API_ROUTES.ACCESS_SINGLE_JOB(ownerName, jobName)),
      )
      .then(async (res) => {
        if (!res.response || !res.response.name) {
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: JobMapper.into(res.response),
        });
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      });
  }, [ownerName, jobName, httpService]);

  return job;
};
