import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ColorSetEnum } from 'common/constants/getColorSet';
import mixpanel from 'mixpanel-browser';
import Plausible from 'plausible-tracker';

type Props = ClassOverrides & {
  defaultValue?: string;
  disabled?: boolean;
  inputClassOverrides?: string[];
  onChange?: (value: string) => void;
  placeholder?: string;
  type?: 'text' | 'number' | 'password';
};

const CopyInputText: React.FC<Props> = ({
  classOverrides = [],
  defaultValue = '',
  disabled = false,
  inputClassOverrides = [],
  onChange = (_) => {},
  placeholder = '',
  type = 'text',
  ...rest
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const { trackEvent } = Plausible();

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value, onChange]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const copyIncludeLink = () => {
    navigator.clipboard.writeText(value);
    mixpanel.track(`[Copy] Job include link`);
    trackEvent('Copy a job include link');
  };
  return (
    <div
      className={cn(
        'w-full text-secondary-dark flex flex-auto relative' +
          ' rounded-lg border-2 p-1 border-secondary-dark bg-white',
        ...classOverrides,
      )}
    >
      <input
        type={type}
        disabled={disabled}
        value={value}
        className={cn(
          'border-none bg-transparent font-comfortaa placeholder-secondary-dark',
          'w-11/12 cursor-text',
          ...inputClassOverrides,
        )}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        {...rest}
      />
      <Tooltip
        afterShow={() => {
          copyIncludeLink();
          setTimeout(ReactTooltip.hide, 2000);
        }}
        title={t('action:copied')}
        click={true}
        classOverrides={[
          'absolute right-0 top-2 cursor-pointer z-20 px-3 text-xl',
        ]}
        colorSet={ColorSetEnum.success}
      >
        <button
          className={
            'hover:text-secondary-default transition duration-300 mt-0.5 '
          }
        >
          <FontAwesomeIcon
            icon={faCopy}
            className="dark:text-gray-primary dark:hover:text-primary-default"
          />
        </button>
      </Tooltip>
    </div>
  );
};

export { CopyInputText };
