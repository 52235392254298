import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import {
  getNodeName,
  getNodeTitle,
  guessValidationPattern,
  guessVariant,
} from '../../helpers/kratos.helper';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import React, { useContext } from 'react';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { FieldErrors } from 'react-hook-form';
import { motion } from 'framer-motion';
import { fadeInTopVariant } from '@utils/motion.util';
import {
  BgColor,
  ClassOverrides,
  PlaceholderColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { ThemeContext } from 'common/contexts/theme.context';
import mixpanel from '@utils/mixpanel.util';

type KratosFormProps = ClassOverrides & {
  bgColor?: BgColor;
  /**
   * Flow ui nodes array defining the fields
   */
  flowUiNodes?: UiNode[];
  /**
   * useForm hook register property
   * @see useForm
   */
  register: UseFormRegister<any>;
  /**
   * useForm hook isSubmitting property
   * @see useForm
   */
  isSubmitting: boolean;
  /**
   * Errors given by useForm
   */
  errors: FieldErrors;
  /**
   * Placeholder for the input
   */
  placeholder?: string;
  /**
   * The color of the placeholder
   */
  placeholderColor?: PlaceholderColor;
  /**
   * Title in case we want to add a tooltip
   */
  tooltipTitle?: string;
  /**
   * Label to display at the top of the input
   */
  label?: string;
  /**
   * Allow autocompletion of the input
   */
  autoCompletion?: boolean;
};
/**
 * This getter fetch an error related to an input from useForm hook
 * @param {FieldErrors} errors -- All the error in the form
 * @param {string} fieldRaw -- Name of the field
 * @see useForm
 */
const getFormFieldError = (errors: FieldErrors, fieldRaw: string): string => {
  if (!errors) return '';

  const field = fieldRaw.split('.');
  // We use any as we redefine errors based on the depth in the object
  let error = errors;

  field.forEach((item, i) => {
    if (error[item] !== undefined) error = error[item];
  });

  if (error) return error.message;
  return '';
};

/**
 * Convert a Kratos flow into a list of inputs, only support TextInput for now
 * @param {SelfServiceRegistrationFlow|undefined} flow -- Current Kratos Flow
 * @param {UseFormRegister} register -- UseForm method to register a new data
 * @param {boolean} isSubmitting -- Information telling whether the form is currently submitting
 * @param {FieldErrors} errors
 * @see useForm
 */
const KratosFormInputs: React.FC<KratosFormProps> = ({
  bgColor,
  classOverrides = [],
  flowUiNodes,
  register,
  isSubmitting,
  errors,
  placeholder,
  placeholderColor,
  tooltipTitle,
  label,
  autoCompletion = true,
}) => {
  const { theme } = useContext(ThemeContext);
  const trackAction = (inputName: string) => {
    mixpanel.track(`[Form] Clicked on input ${inputName}`);
  };

  if (!flowUiNodes) return null;
  return (
    <>
      {flowUiNodes.map((node, i) => {
        const attr = node.attributes as UiNodeInputAttributes;
        const variant = guessVariant(node);
        const name = getNodeName(node);
        const title = getNodeTitle(node);

        if (!variant) {
          if (name === 'csrf_token') {
            return (
              <input
                key={attr.name}
                type={attr.type}
                required={attr.required}
                readOnly
                autoComplete={autoCompletion ? 'on' : 'off'}
                {...register(name)}
                value={attr.value}
                id={attr.name}
                hidden
              />
            );
          }
          return null;
        }

        // Returns after if statement so we don't register useless inputs
        const registerSanitized = register(name, guessValidationPattern(node));
        let error = getFormFieldError(errors, name);

        if (node.messages && node.messages.length > 0) {
          error = [
            error ? `${error}` : '',
            ...node.messages.map((c) => c.text),
          ].join(' ');
        }

        return (
          <motion.div
            className={cn('px-5 py-2', ...classOverrides)}
            key={name}
            variants={fadeInTopVariant(-20, i * 0.1)}
          >
            <StyledInput
              bgColor={bgColor}
              classOverrides={[
                attr.type === 'hidden' && 'hidden',
                ...classOverrides,
                placeholderColor ? placeholderColor : '',
              ]}
              defaultValue={attr.value}
              disabled={attr.disabled || isSubmitting}
              error={error}
              id={attr.name}
              label={label ? label : title}
              tooltipTitle={tooltipTitle ? tooltipTitle : undefined}
              placeholder={placeholder ? placeholder : title}
              placeholderColor={
                theme === 'light'
                  ? 'placeholder-input-field'
                  : 'placeholder-gray-primary'
              }
              register={registerSanitized}
              required={attr.required}
              type={attr.type}
              useEyeIcon={attr.type === 'password'}
              onClick={() => trackAction(title)}
            />
          </motion.div>
        );
      })}
    </>
  );
};

export { KratosFormInputs };
