import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<any>;
};

export type Tier = {
  name: string;
};

export const TiersFilterSelector: React.FC<Props & ClassOverrides> = ({
  control,
  classOverrides = [],
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    keyName: 'name',
    control,
    name: 'tiers',
  });

  const isTierSelected = useCallback(
    (tier: string): boolean => {
      return fields.find((field) => field.name === tier) !== undefined;
    },
    [fields],
  );

  const onTierSelect = (tier: string) => {
    if (!isTierSelected(tier)) {
      append({ name: tier }, { shouldFocus: false });
    } else {
      remove(fields.findIndex((field) => field.name === tier));
    }
  };
  const [communityIsChecked, setCommunityIsChecked] = useState(false);
  const [officialIsChecked, setOfficialIsChecked] = useState(false);

  useEffect(() => {
    if (isTierSelected('community') && isTierSelected('official')) return;
    if (!isTierSelected('official')) {
      setOfficialIsChecked(false);
    }
    if (!isTierSelected('community')) {
      setCommunityIsChecked(false);
    }
  }, [isTierSelected, fields]);
  const officialLabel = t('hub.filters.tiers.content.0');
  const communityLabel = t('hub.filters.tiers.content.1');
  return (
    <div className={cn('divide-y-8 divide-transparent', ...classOverrides)}>
      <label
        className={cn(
          'flex items-center text-gray-secondary dark:text-gray-primary font-comfortaa cursor-pointer hover:opacity-90',
        )}
        htmlFor={officialLabel}
      >
        <input
          className={cn(
            'w-5 h-5 mr-2 mb-0.5 rounded-md appearance-none border-none',
            'cursor-pointer text-primary-default bg-gray-primary transition-colors checked:bg-primary-default hover:opacity-70 hover:bg-primary-default',
            'filter shadow-checkbox checked:shadow-button-inner-outer checked:bg-none',
          )}
          id={officialLabel}
          type="checkbox"
          onChange={() => {
            onTierSelect('official');
            setOfficialIsChecked(!officialIsChecked);
          }}
          checked={officialIsChecked}
        />
        {officialLabel}
      </label>
      <label
        className={cn(
          'flex items-center text-gray-secondary dark:text-gray-primary font-comfortaa cursor-pointer hover:opacity-90',
        )}
        htmlFor={communityLabel}
      >
        <input
          className={cn(
            'w-5 h-5 mr-2 mb-0.5 rounded-md appearance-none border-none',
            'cursor-pointer text-primary-default bg-gray-primary transition-colors checked:bg-primary-default hover:opacity-70 hover:bg-primary-default',
            'filter shadow-checkbox checked:shadow-button-inner-outer checked:bg-none',
          )}
          id={communityLabel}
          type="checkbox"
          onChange={() => {
            onTierSelect('community');
            setCommunityIsChecked(!communityIsChecked);
          }}
          checked={communityIsChecked}
        />
        {communityLabel}
      </label>
    </div>
  );
};
