import React from 'react';
import { ClassOverrides, HTMLStyling } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = HTMLStyling &
  ClassOverrides & {
    size?: 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'h3-card';
  };

export const Title: React.FC<Props> = ({
  children,
  size = 'h1',
  classOverrides = [],
  bold = false,
  light = false,
  color = 'text-white',
  font = 'ubuntu',
  uppercase = false,
}) => {
  let fontSize;
  let sizedChildren;

  const getClassName = (size: string) =>
    cn(
      (fontSize = size),
      bold && 'font-bold',
      light && 'font-light',
      uppercase && 'uppercase',
      color,
      `font-${font}`,
      'dark:text-white',
      ...classOverrides,
    );

  switch (size) {
    case 'h1':
      fontSize = 'text-4xl';
      sizedChildren = (
        <h1 className={cn(getClassName(fontSize), ...classOverrides)}>
          {children}
        </h1>
      );
      break;
    case 'h2':
      fontSize = 'text-2xl';
      sizedChildren = (
        <h2 className={cn(getClassName(fontSize), ...classOverrides)}>
          {children}
        </h2>
      );
      break;
    case 'h3':
      fontSize = 'text-lg';
      sizedChildren = (
        <h3 className={cn(getClassName(fontSize), ...classOverrides)}>
          {children}
        </h3>
      );
      break;
    case 'h3-card':
      fontSize = 'text-sm lg:text-lg';
      sizedChildren = (
        <h3 className={cn(getClassName(fontSize), ...classOverrides)}>
          {children}
        </h3>
      );
      break;
    case 'h4':
      fontSize = 'text-base';
      sizedChildren = (
        <h4 className={cn(getClassName(fontSize), ...classOverrides)}>
          {children}
        </h4>
      );
      break;
    case 'span':
      sizedChildren = (
        <span
          className={cn(
            bold && 'font-bold',
            light && 'font-light',
            color,
            `font-${font}`,
            ...classOverrides,
          )}
        >
          {children}
        </span>
      );
  }

  return <>{sizedChildren}</>;
};
