import { useContext, useMemo, useReducer, useRef, useState } from 'react';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { UserContext } from 'user/contexts/user.context';
import { toastError } from '@components/Toasters/Toaster.component';
import { Octokit } from 'octokit';
import { components } from '@octokit/openapi-types';

export type GitHubRepository = components['schemas']['minimal-repository'];

export const useGitHubProjects = () => {
  const [, dispatch] = useReducer(httpReducer(), null);
  const [gitHubProjects, setGitHubProjects] = useState<GitHubRepository[]>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { identity } = useContext(UserContext);
  const userToken = identity?.traits.github_token;
  /* Used to avoid concatenating repeated projects when page changes but new projects are being fetched. See line 74 */
  const hasFetchedProjects = useRef(false);
  let octokit = useMemo(() => new Octokit(), []);
  const getGitHubProjects = () => {
    if (hasFetchedProjects.current || gitHubProjects || notFound || !userToken)
      return;
    dispatch({
      type: ActionType.PROGRESS,
    });
    if (userToken) {
      octokit = new Octokit({
        auth: identity?.traits.github_token,
      });
    }
    setLoading(true);

    octokit
      .request('GET /user/repos', {
        sort: 'updated',
        page: 1,
        per_page: 100,
        affiliation: 'collaborator,owner',
      })
      .then(async (res) => {
        if (!res || res.status !== 200) {
          hasFetchedProjects.current = true;
        } else if (res && res.status === 200) {
          hasFetchedProjects.current = true;
          setGitHubProjects(res.data as GitHubRepository[]);
        }
      })
      .catch((_) => {
        if (_.status === 404) {
          setNotFound(true);
          hasFetchedProjects.current = true;
        }

        if (_.status !== 200) {
          if (_.message === 'Bad credentials') {
            toastError(
              `There's something wrong with your GitHub personal access token, please fill it again`,
            );
          } else {
            toastError(_.message);
          }
          hasFetchedProjects.current = true;
        }
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })

      .finally(() => {
        setLoading(false);
        hasFetchedProjects.current = true;
      });
  };

  return { getGitHubProjects, loading, notFound, gitHubProjects };
};
