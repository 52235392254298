import { License } from 'common/hooks/useLicense.hook';

export const mockCoreLicense = (username: string): License => {
  return {
    id: 1,
    licenseType: {
      id: 1,
      createdAt: '2022-04-28T09:54:32.767Z',
      updatedAt: new Date().toString(),
      name: 'Core',
      projectMax: 5,
      priceID: '',
    },
    active: true,
    projects: [],
    updatedAt: new Date().toString(),
    createdAt: new Date().toString(),
    userName: username,
    customerID: 'cus_LZQgf98s3RsuD5',
  };
};
