import { toastError } from '@components/Toasters/Toaster.component';
import { ImportState } from '@jobs/components/VersionsTable/VersionRow.component';
import {
  JobCreationResponse,
  VersionAnalysis,
} from '@jobs/models/JobCreationResponse.model';
import { WarningLevel } from '@jobs/models/JobVersion.model';
import { buildRoute } from '@utils/routes.util';
import { API_ROUTES } from 'common/constants/routes';
import { HttpContext } from 'common/contexts/http.context';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { useContext, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ReturnType = [
  (ownerName: string, jobName: string) => Promise<JobCreationResponse | void>,
  boolean,
  (jobAnalysis: VersionAnalysis[], jobName: string) => number,
];
export const getNumberOfVersionsUpdated = (
  jobAnalysis: VersionAnalysis[],
  jobName: string,
): number => {
  const totalArray: number[] = jobAnalysis.map((versionAnalysis) => {
    const filenames = ['jobname.yml', 'readme.md', 'changelog.md'];
    /*
     * Obtain messages for readme.md and changelog.md
     */
    const keyFilesMessage = versionAnalysis.messages.filter((message) =>
      message.filename ? filenames.includes(message.filename) : false,
    );

    /**
     * Obtain messages related to <jobname>.yml
     */
    const jobNameMessage = versionAnalysis.messages.filter((message) =>
      message.filename?.includes(jobName),
    )[0];

    /**
     * Determine whether there is or not a critical version error which is when there
     * is any message with no filename associated or any message of level 0
     */
    const criticalErrorPresent = versionAnalysis.messages.some(
      (message) => message.level === WarningLevel.CRITICAL,
    );

    const jobImportState =
      jobNameMessage || criticalErrorPresent
        ? ImportState.notImported
        : keyFilesMessage.some((message) => message.level === 1)
        ? ImportState.missingData
        : ImportState.imported;

    if (jobImportState === ImportState.notImported) {
      return 0;
    } else {
      return +1;
    }
  });
  return totalArray.reduce((a, b) => {
    return a + b;
  });
};
export const useOnJobRefresh = (): ReturnType => {
  const { t } = useTranslation();
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useReducer(httpReducer<Response>(), null);
  const refreshJob = async (
    ownerName: string,
    jobName: string,
  ): Promise<JobCreationResponse | void> => {
    setLoading(true);
    return httpService
      .fetch<JobCreationResponse>(
        'POST',
        buildRoute(API_ROUTES.CREATE_JOB_VERSION(ownerName, jobName)),
      )
      .then((res) => {
        if (res.status !== StatusCodes.CREATED) {
          toastError(t('error:toast.unhandled'));
          return;
        } else if (res.response && res.status === StatusCodes.CREATED) {
          return res.response;
        }
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => setLoading(false));
  };

  return [refreshJob, loading, getNumberOfVersionsUpdated];
};
