import { useContext, useEffect, useReducer, useState } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { GITLAB_API_PROJECTS_URL } from 'common/constants/routes';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';

export const useGitLab = (repoUrl: string) => {
  const [repo, dispatch] = useReducer(
    httpReducer<GitLabProjectResponse>(),
    null,
  );
  const httpService = useContext(HttpContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (repo || !repoUrl) return;
    setLoading(true);
    httpService
      .fetch<GitLabProjectResponse>('GET', GITLAB_API_PROJECTS_URL + repoUrl, {
        withCredentials: false,
      })
      .then(async (res) => {
        if (!res.response) {
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [httpService, repo, repoUrl]);

  return [repo, loading];
};
