import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { GitLabRegularIcon } from '@components/Icons/GitLabRegularIcon/GitLabRegularIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Modal } from '@components/Modal/Modal.component';
import { Title } from '@components/Titles/Title.component';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewIssueModal: React.FC<{
  repoUrl: string;
  jobName: string;
  setShowNewIssueModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNewIssueModal: boolean;
}> = ({ repoUrl, jobName, setShowNewIssueModal, showNewIssueModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      classOverrides={['p-5 text-center sm:w-125']}
      setShowModal={setShowNewIssueModal}
      showModal={showNewIssueModal}
    >
      <Title
        bold
        classOverrides={['mb-8']}
        color="text-secondary-default"
        size="h2"
      >
        {t('job_details.new_issue_modal.title')}
      </Title>
      <p className="font-comfortaa text-secondary-dark dark:text-gray-primary text-center">
        {t('job_details.new_issue_modal.content')}
      </p>
      <OutsideLink
        name="New issue"
        to={`${repoUrl}/-/issues/new?issue[title]=[${jobName}] -`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PrimaryButton
          bgColor="bg-primary-blue"
          classOverrides={[
            'mx-auto text-lg my-4 pl-8 h-10 flex items-center justify-center',
          ]}
          content={t('job_details.new_issue_modal.button_content')}
          margin="m-0"
        >
          <GitLabRegularIcon classOverrides={['ml-2']} />
        </PrimaryButton>
      </OutsideLink>
    </Modal>
  );
};
