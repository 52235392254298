import { useCallback, useContext } from 'react';
import { GITLAB_API_PROJECTS_URL } from 'common/constants/routes';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import { UserContext } from 'user/contexts/user.context';
import { toastError } from '@components/Toasters/Toaster.component';
import { useQuery } from 'react-query';
import axios from 'axios';
import { ProjectsContext } from 'user/contexts/projects.context';

export const useGitLabProjects = () => {
  const { identity } = useContext(UserContext);
  // Create a new AbortController instance for this request

  const controller = new AbortController();

  // Get the abortController's signal

  const signal = controller.signal;
  let userToken = identity?.traits.gitlab_token;
  let route =
    GITLAB_API_PROJECTS_URL +
    `?private_token=${userToken}&per_page=100&min_access_level=30&order_by=updated_at`;
  const config = {
    headers: {
      'content-type': 'application/json',
      PRIVATE_TOKEN: userToken,
      signal,
    },
    withCredentials: false,
  };
  const { currentLicense } = useContext(ProjectsContext);
  const compare = useCallback(
    (a: GitLabProjectResponse, b: GitLabProjectResponse) => {
      const aIsUsed =
        currentLicense?.projects &&
        currentLicense?.projects.find(
          (licenseProject) => a.id === licenseProject.projectIDPlatform,
        ) !== undefined;
      const bIsUsed =
        currentLicense?.projects &&
        currentLicense?.projects.find(
          (licenseProject) => b.id === licenseProject.projectIDPlatform,
        ) !== undefined;

      if (aIsUsed && !bIsUsed) {
        return -1;
      } else if (!aIsUsed && bIsUsed) {
        return 1;
      }
      return 0;
    },
    [currentLicense?.projects],
  );
  const cancelQuery = () => {
    controller.abort();
  };
  const getProjectsQuery = async () =>
    await axios.get(route, config).then((res) => {
      return res.data;
    });
  const { isLoading, data, isFetching } = useQuery<
    GitLabProjectResponse[],
    Error
  >('gitLabProjects', getProjectsQuery, {
    enabled: !!userToken,
    refetchOnWindowFocus: false,

    onError: (error) => {
      if (
        !error.message.includes('200') &&
        !error.message.includes('401') &&
        !error.message.includes('404')
      ) {
        toastError('An unknown error has occurred');
      } else if (
        error.message.includes('401') &&
        identity?.traits.gitlab_token
      ) {
        toastError(
          'There is something wrong with your GitLab Token, please fill it again.',
        );
      } else if (error.message.includes('404')) {
        toastError(`No projects found on your GitLab account`);
      } else {
        if (identity?.traits.gitlab_token) {
          toastError(`Something went wrong: ${error.message}`);
        }
      }
    },
  });

  const dataSorted = typeof data?.sort === 'function' && data?.sort(compare);
  return {
    isLoading,
    dataSorted,
    isFetching,
    cancelQuery,
  };
};
