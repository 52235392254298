import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '@components/Modal/Modal.component';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQuery } from 'common/hooks/useQueryParam.hook';
import { UserContext } from 'user/contexts/user.context';
import { useKratosSettingsFlowWithProfileMethod } from 'kratos/flows/settings.flow';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import {
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  UiNode,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from 'kratos/helpers/kratos.helper';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { SettingsTraits } from 'user/types/traits';
import { removeEmptyFields } from '@utils/object.util';
import { PrimaryButton } from '@components/Buttons/PrimaryButton/PrimaryButton.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { Title } from '@components/Titles/Title.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Typography } from '@components/Typography/Typography.component';
import { DOCS_TOKEN_DOCUMENTATION } from 'common/constants/general';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  showModal: boolean;
  title: string;
  description?: string;
  button: string;
  source: 'github' | 'gitlab';
};

export const AddTokenModal: React.FC<Props> = ({
  setShowModal,
  showModal,
  title,
  description,
  button,
  source,
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { action, identity } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      'traits.username': identity?.traits.username,
      'traits.email': identity?.traits.email,
      'traits.gitlab_username': identity?.traits.gitlab_username,
      'traits.github_username': identity?.traits.github_username,
      'traits.linkedin': identity?.traits.linkedin,
      'traits.twitter': identity?.traits.twitter,
      'traits.company': identity?.traits.company,
      'traits.job_title': identity?.traits.job_title,
      'traits.website': identity?.traits.website,
      'traits.goodies': identity?.traits.goodies,
      'traits.bio': identity?.traits.bio,
      'traits.picture': identity?.traits.picture,
    },
  });

  const flowId = useQuery().get('flow');
  const [flow, settingsSubmitHandlerFlow] =
    useKratosSettingsFlowWithProfileMethod(flowId, () => {
      toastSuccess(t('toast.token_saved'));
      setShowModal && setShowModal(false);
      action.whoami();
    });

  const [csrfNode, setCsrfNode] = useState<UiNode>();
  const [gitLabTokenNode, setGitLabTokenNode] = useState<UiNode>();
  const [gitHubTokenNode, setGitHubTokenNode] = useState<UiNode>();

  const onSubmit = (
    data: SubmitSelfServiceSettingsFlowWithProfileMethodBody,
  ) => {
    const traits = data.traits as SettingsTraits;
    traits.username = identity?.traits.username;
    traits.email = identity?.traits.email;
    if (source === 'gitlab' && identity?.traits.github_token) {
      traits.github_token = identity.traits.github_token;
    }
    if (source === 'github' && identity?.traits.gitlab_token) {
      traits.gitlab_token = identity.traits.gitlab_token;
    }
    removeEmptyFields(data.traits);
    settingsSubmitHandlerFlow(data);
  };

  useEffect(() => {
    if (flow !== undefined) {
      flow.ui.nodes.forEach((node) => {
        if (isUiNodeInputAttributes(node.attributes)) {
          switch (node.attributes.name) {
            case 'csrf_token':
              setCsrfNode(node);
              break;
            case 'traits.gitlab_token':
              node.attributes.type = 'password';
              setGitLabTokenNode(node);
              break;
            case 'traits.github_token':
              node.attributes.type = 'password';
              setGitHubTokenNode(node);
              break;
            default:
              break;
          }
        }
      });
    }
  }, [flow]);

  return (
    <>
      {setShowModal && (
        <Modal
          classOverrides={[
            'w-32 sm:w-42 md:w-125 lg:w-135',
            'z-40 pt-10 pb-4 md:pt-6 md:pb-6 px-4 md:px-12',
          ]}
          setShowModal={setShowModal}
          showModal={showModal}
        >
          {flow ? (
            <>
              <Title
                size={'h2'}
                color={'text-secondary-default'}
                bold
                classOverrides={['text-center mb-2']}
              >
                {title}
              </Title>
              <form
                onSubmit={handleSubmit(onSubmit)}
                method={flow.ui.method}
                className="flex flex-col items-center md:items-stretch"
              >
                {csrfNode?.attributes && (
                  <KratosFormInputs
                    register={register}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    flowUiNodes={[csrfNode]}
                  />
                )}
                {gitLabTokenNode?.attributes && source === 'gitlab' && (
                  <div className="text-center">
                    <Typography
                      color="text-gray-secondary"
                      size="sm"
                      classOverrides={[
                        'dark:text-white flex flex-col items-center',
                      ]}
                    >
                      <Trans
                        i18nKey={
                          description
                            ? description
                            : 'account.modal.input_label_link'
                        }
                        values={{ platform: 'GitLab' }}
                        components={[
                          '',

                          <OutsideLink
                            name={'Token documentation'}
                            to={DOCS_TOKEN_DOCUMENTATION}
                            target="_blank"
                            className={
                              'underline text-primary-default hover:opacity-70'
                            }
                            rel="noreferrer"
                          >
                            See how to generate one.
                          </OutsideLink>,
                          '',
                        ]}
                      />
                    </Typography>
                    <KratosFormInputs
                      register={register}
                      errors={errors}
                      classOverrides={['dark:text-gray-primary']}
                      isSubmitting={isSubmitting}
                      bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
                      flowUiNodes={[gitLabTokenNode]}
                      placeholder={'Token'}
                      placeholderColor="placeholder-gray-secondary"
                      label=" "
                    />
                  </div>
                )}
                {gitHubTokenNode?.attributes && source === 'github' && (
                  <div>
                    <Typography
                      color="text-gray-secondary"
                      size="sm"
                      classOverrides={[
                        'dark:text-white flex flex-col items-center',
                      ]}
                    >
                      <Trans
                        i18nKey={
                          description
                            ? description
                            : 'account.modal.input_label_link'
                        }
                        values={{ platform: 'GitHub' }}
                        components={[
                          '',

                          <OutsideLink
                            name={'Token documentation'}
                            to={DOCS_TOKEN_DOCUMENTATION}
                            target="_blank"
                            className={
                              'underline text-primary-default hover:opacity-70'
                            }
                            rel="noreferrer"
                          >
                            See how to generate one.
                          </OutsideLink>,
                          '',
                        ]}
                      />
                    </Typography>
                    <KratosFormInputs
                      register={register}
                      autoCompletion={false}
                      errors={errors}
                      classOverrides={['dark:text-gray-primary']}
                      isSubmitting={isSubmitting}
                      bgColor={theme === 'light' ? undefined : 'bg-gray-third'}
                      flowUiNodes={[gitHubTokenNode]}
                      placeholder={'Token'}
                      placeholderColor="placeholder-gray-secondary"
                      label=" "
                    />
                  </div>
                )}
                <PrimaryButton
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    !isValid ||
                    !isDirty
                  }
                  content={button}
                  bold={false}
                  bgColor="bg-secondary-default"
                  classOverrides={[
                    'self-center w-max dark:bg-secondary-default',
                  ]}
                  margin="m-0"
                  submitButton
                />
              </form>
            </>
          ) : (
            <SpinnerIcon classOverrides={['my-48']} />
          )}
        </Modal>
      )}
    </>
  );
};
