import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { toastSuccess } from '@components/Toasters/Toaster.component';
import { SubmitSelfServiceSettingsFlowWithPasswordMethodBody } from '@ory/kratos-client';
import {
  defaultPasswordCheckerState,
  isContainingLowercase,
  isContainingNumeric,
  isContainingSpecialChar,
  isContainingUppercase,
  PasswordCheckerState,
} from '@utils/text.util';
import { PWD_MIN_LENGTH } from 'common/constants/general';

import { useQuery } from 'common/hooks/useQueryParam.hook';
import { AnimatePresence } from 'framer-motion';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';
import { useKratosChangePwdFlow } from 'kratos/flows/changePwdFlow.flow';
import { FormEvent, useEffect, useState } from 'react';

import { UnpackNestedValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PwdChecker } from '../PwdChecker/PwdChecker.component';

export const ChangePwdForm: React.FC = () => {
  const { t } = useTranslation();
  const flowId = useQuery().get('flow');
  const [flow, submitHandler] = useKratosChangePwdFlow(flowId, () => {
    toastSuccess('Password has been changed');
    setValue('password', '');
    setValue('password_repeat', '');
  });
  const [passwordState, setPasswordState] = useState<PasswordCheckerState>(
    defaultPasswordCheckerState,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
    setError,
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = (
    data: UnpackNestedValue<
      SubmitSelfServiceSettingsFlowWithPasswordMethodBody & {
        password_repeat: string;
      }
    >,
  ) => {
    if (!flow) return;

    if (data.password !== data.password_repeat) {
      setError('password_repeat', {
        type: 'manual',
        message: t('error:content_checker.password_dont_match'),
      });
      return;
    }
    submitHandler(data);
  };
  const onFormChange = (e: FormEvent<HTMLFormElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.value === undefined) return;

    if (input.name === 'password' || input.name === 'password_repeat') {
      setPasswordState({
        correctNumeric: isContainingNumeric(input.value),
        correctLength: input.value.length > PWD_MIN_LENGTH,
        correctSpecial: isContainingSpecialChar(input.value),
        correctLowercaseUppercase:
          isContainingUppercase(input.value) &&
          isContainingLowercase(input.value),
        passwordMatch:
          watch('password') === watch('password_repeat') ? true : false,
      });
    }
  };
  const [pwdIsValid, setPwdIsValid] = useState(false);
  useEffect(() => {
    setPwdIsValid(
      Object.values(passwordState).every((state) => {
        return state;
      }),
    );
  }, [passwordState]);
  return (
    <AnimatePresence exitBeforeEnter>
      <div
        className="pt-2 px-4 md:px-8 pb-4 h-auto"
        data-testid="changePwd-form"
      >
        {flow ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            onChange={onFormChange}
            method={flow.ui.method}
          >
            <KratosFormInputs
              flowUiNodes={flow.ui.nodes}
              register={register}
              isSubmitting={isSubmitting}
              errors={errors}
              bgColor={'dark:bg-gray-third'}
              classOverrides={['dark:text-gray-primary']}
            />
            <PwdChecker values={passwordState} />

            <SubmitButton
              classOverrides={['my-4']}
              content={t('action:save_password')}
              isSubmitting={isSubmitting}
              isValid={pwdIsValid}
              errors={errors}
            />
          </form>
        ) : (
          <SpinnerIcon classOverrides={['my-8']} />
        )}
      </div>
    </AnimatePresence>
  );
};
