import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  github,
  solarizedDark,
} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import ReactTooltip from 'react-tooltip';

type Props = ClassOverrides & {
  children: React.ReactNode & React.ReactNode[];
  inline: boolean;
  className: string | undefined;
};

const MarkdownCode: React.FC<Props> = ({ children, inline, className }) => {
  const { t } = useTranslation();
  const match = /language-(\w+)/.exec(className || '');
  const content = String(children).replace(/\n$/, '');
  const { theme } = useContext(ThemeContext);
  return !inline && match ? (
    <div className="relative w-full flex my-4">
      <SyntaxHighlighter
        children={content}
        className="bg-gray-primary dark:bg-gray-secondary overflow-auto w-full flex "
        style={theme === 'light' ? github : solarizedDark}
        language={match ? match[1] : 'yaml'}
        PreTag="div"
        useInlineStyles={true}
      />
      <div className="flex items-start pt-2 justify-start bg-gray-primary dark:bg-dark-blue">
        <Tooltip
          afterShow={() => {
            navigator.clipboard.writeText(content);
            setTimeout(ReactTooltip.hide, 2000);
          }}
          title={t('action:copied')}
          click={true}
          classOverrides={['cursor-pointer z-20 px-3  text-xl']}
          colorSet={ColorSetEnum.success}
          place="top"
        >
          <button
            className={
              ' hover:text-secondary-default transition duration-300 dark:text-gray-primary'
            }
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </Tooltip>
      </div>
    </div>
  ) : String(children).length > 40 ? (
    <div className=" overflow-x-auto w-full" style={{ display: 'inherit' }}>
      <div className=" w-max bg-gray-primary dark:bg-dark-blue dark:text-gray-primary">
        {children}
      </div>
    </div>
  ) : (
    <code className=" w-max inline p-1 bg-gray-primary dark:bg-dark-blue dark:text-gray-primary">
      {children}
    </code>
  );
};

export { MarkdownCode };
