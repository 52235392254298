import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '@components/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { useForm } from 'react-hook-form';
import { isUrlFriendly } from '@utils/text.util';
import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';
import {
  AutoPipelineResponseType,
  useOnProjectPipeline,
} from 'user/hooks/useOnProjectPipeline.hook';
import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import { ExclamationCircleIcon } from '@components/Icons/ExclamationCircleIcon/ExclamationCircleIcon.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { cn } from '@utils/classes.util';
import { StyledCheckboxInput } from '@components/Form/StyledCheckboxInput.component/StyledCheckboxInput.component';
import { InputLabel } from '@components/Form/StyledField/StyledField.component';
import Plausible from 'plausible-tracker';
import mixpanel from '@utils/mixpanel.util';
import { GitHubRepository } from 'common/hooks/useGitHubProjects.hook';
import { determineIfIsGitLabOrGitHub } from '@utils/object.util';
import { ProjectsContext } from 'user/contexts/projects.context';

export type AutoPipelineConfig = {
  projectID: number;
  projectUrl: string;
  platform: string;
  sourceBranch: string;
  destBranch?: string;
  write: boolean;
};

type Props = {
  project: GitLabProjectResponse | GitHubRepository;
  autoPipelineRemaining: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setAutoPipelineResponse: React.Dispatch<
    React.SetStateAction<AutoPipelineResponseType | undefined>
  >;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddProjectPipelineModal: React.FC<Props> = ({
  project,
  autoPipelineRemaining,
  setShowModal,
  setAutoPipelineResponse,
  setShowResponseModal,
  showModal,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = Plausible();

  const { currentLicense } = useContext(ProjectsContext);
  let [mutate, data, loading] = useOnProjectPipeline(project);
  const [warningColor, setWarningColor] = useState('text-green-pearl');

  useEffect(() => {
    if (typeof autoPipelineRemaining === 'number' && autoPipelineRemaining > 1)
      return;
    if (autoPipelineRemaining === 1) {
      setWarningColor('text-orange-warning');
    } else if (autoPipelineRemaining === 0) {
      setWarningColor('text-red-alert');
    }
  }, [autoPipelineRemaining, currentLicense, warningColor]);

  const autoPipelineCanBeUsed = () => {
    let projectIsUsed = false;
    if (currentLicense && currentLicense.projects) {
      projectIsUsed =
        currentLicense?.projects.find(
          (licenseProject) => project?.id === licenseProject.projectIDPlatform,
        ) !== undefined;
    } else if (currentLicense?.active === false) {
      return false;
    } else {
      return true;
    }
    if (autoPipelineRemaining > 0) {
      return true;
    } else if (projectIsUsed) {
      return true;
    } else {
      return false;
    }
  };
  const canUseIt = autoPipelineCanBeUsed();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });
  const projectWebUrl = determineIfIsGitLabOrGitHub(project)
    ? project?.web_url
    : project?.html_url;

  const submitHandler = (data: AutoPipelineConfig): void => {
    if (!projectWebUrl) return;
    const urlHost = new URL(projectWebUrl).host.replace('.com', '');
    let autoPipelineConfig: AutoPipelineConfig = {
      projectID: project.id,
      projectUrl: projectWebUrl,
      platform: urlHost,
      sourceBranch: data.sourceBranch,
      destBranch: data.destBranch,
      write: data.write ?? false,
    };
    trackEvent('Trigger autoPipeline');
    mixpanel.track(`Trigger autoPipeline`);
    mutate(autoPipelineConfig);
  };

  useEffect(() => {
    if (!data) return;
    if (data.pipelines && data.projectIDPlatform === project.id) {
      data.project = project;
      setShowModal(false);
      setShowResponseModal(true);
      setAutoPipelineResponse(data);
    }
  }, [
    data,
    project,
    project.id,
    setAutoPipelineResponse,
    setShowModal,
    setShowResponseModal,
  ]);
  useEffect(() => {
    if (!projectWebUrl) return;
    setValue('repoUrl', projectWebUrl);
    setValue(
      'sourceBranch',
      project?.default_branch ? project.default_branch : '',
    );
    setValue('destBranch', 'r2devops');
    setValue('write', true);
  }, [project.default_branch, setValue, project, projectWebUrl]);
  const [displayWarning, setDisplayWarning] = useState(false);

  useEffect(() => {
    if (!currentLicense || !currentLicense.projects) setDisplayWarning(true);
    if (currentLicense && currentLicense.projects && currentLicense.active) {
      setDisplayWarning(
        currentLicense.projects.find(
          (licenseProject) => project?.id === licenseProject.projectIDPlatform,
        ) === undefined,
      );
    }
  }, [currentLicense, project?.id]);

  return (
    <>
      {project && (
        <Modal
          classOverrides={['w-56 md:w-125 lg:w-135 py-6 px-12', 'z-40 ']}
          setShowModal={setShowModal}
          showModal={showModal}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="text-center mb-4">
              <Title
                size="h2"
                classOverrides={['text-secondary-default font-extrabold']}
              >
                {t('my_projects.modal.title')}
              </Title>
            </div>
            <div className="mb-1">
              <StyledInput
                register={register('repoUrl', {
                  required: true,
                })}
                label={t('my_projects.form_modal.inputs_label.0')}
                classOverrides={[
                  'dark:bg-dark-secondary dark:text-gray-primary text-sm md:text-base',
                ]}
                labelClassOverrides={['text-sm md:text-base']}
                type="text"
                disabled
                bgColor="bg-gray-input"
                key={'repo-input'}
                error={errors.repoUrl?.message}
                data-testid="repo-url-input"
              />
            </div>
            <div className="mb-1">
              <StyledInput
                register={register('sourceBranch', {
                  validate: (value) =>
                    !isUrlFriendly(value)
                      ? `${t('error:pipeline_form.special_chars')}`
                      : undefined,
                  required: {
                    value: true,
                    message: t('error:form.required'),
                  },
                })}
                label={t('my_projects.form_modal.inputs_label.1')}
                type="text"
                key={'path-input'}
                placeholder={t('my_projects.form_modal.inputs_placeholder.0')}
                labelClassOverrides={['text-sm md:text-base']}
                classOverrides={[
                  'dark:text-gray-primary text-sm md:text-base',
                  errors.repoUrl?.message
                    ? 'bg-gray-input dark:bg-dark-third'
                    : 'dark:bg-gray-third',
                ]}
                error={errors.sourceBranch?.message}
                data-testid="job-path-input"
                tooltipClassOverrides={['w-72']}
                tooltipTitle={t('my_projects.form_modal.inputs_tooltip.0')}
              />
            </div>
            <StyledInput
              register={register('destBranch', {
                required: {
                  value: true,
                  message: t('error:form.required'),
                },
                validate: (value) =>
                  !isUrlFriendly(value)
                    ? `${t('error:pipeline_form.special_chars')}`
                    : undefined,
              })}
              label={t('my_projects.form_modal.inputs_label.2')}
              type="text"
              key={'job-input'}
              disabled={!watch('write')}
              labelClassOverrides={['text-sm md:text-base']}
              placeholder={t('my_projects.form_modal.inputs_placeholder.1')}
              classOverrides={[
                'dark:text-gray-primary',
                errors.repoUrl?.message
                  ? 'bg-gray-input dark:bg-dark-third'
                  : 'dark:bg-gray-third',
                !watch('write')
                  ? 'bg-gray-input dark:bg-dark-secondary'
                  : 'bg-transparent',
              ]}
              error={errors.destBranch?.message}
              data-testid="job-name-input"
              tooltipClassOverrides={['w-72']}
              tooltipTitle={t('my_projects.form_modal.inputs_tooltip.1')}
            />
            {/* Checkbox for the autorization to write on the destination branch */}
            <div className="flex items-center mb-4">
              <StyledCheckboxInput
                register={register('write')}
                classOverrides={['flex-col-reverse text-sm md:text-base']}
              />
              <InputLabel
                classOverrides={['mr-2 dark:text-gray-primary']}
                label={t('my_projects.form_modal.inputs_label.3')}
              />
            </div>
            <div className="text-center">
              {displayWarning && (
                <div
                  className={cn(
                    'lg:flex-row flex-col flex mb-4 w-full items-center justify-center',
                    autoPipelineRemaining === 0 && 'lg:items-start',
                    warningColor,
                  )}
                >
                  <ExclamationCircleIcon
                    classOverrides={['']}
                    width="18"
                    height="18"
                  />
                  <Typography
                    classOverrides={[warningColor, 'lg:ml-1']}
                    size="sm"
                  >
                    {t('my_projects.modal.instruction', {
                      autoPipelineRemaining:
                        typeof autoPipelineRemaining === 'number'
                          ? autoPipelineRemaining
                          : 1,
                    })}{' '}
                    {autoPipelineRemaining === 0 && (
                      <TrackedLink
                        name={t('my_projects.modal.upgrade_license')}
                        to={'/dashboard/billing'}
                        className="font-bold underline hover:opacity-70"
                      >
                        {' '}
                        Upgrade license
                      </TrackedLink>
                    )}
                  </Typography>
                </div>
              )}
              {currentLicense && currentLicense.active === false && (
                <div
                  className={cn(
                    'lg:flex-row flex-col flex mb-4 w-full items-center justify-center text-red-alert',
                  )}
                >
                  <ExclamationCircleIcon
                    classOverrides={['']}
                    width="18"
                    height="18"
                  />
                  <Typography
                    classOverrides={['lg:ml-1']}
                    size="sm"
                    color="text-red-alert"
                  >
                    {t('my_projects.modal.inactive_license', {
                      currentLicense: currentLicense.licenseType.name,
                    })}{' '}
                    <TrackedLink
                      name={t('my_projects.modal.upgrade_license')}
                      to={'/dashboard/billing'}
                      className="font-bold underline hover:opacity-70"
                    >
                      {' '}
                      Upgrade license
                    </TrackedLink>
                  </Typography>
                </div>
              )}
              <SubmitButton
                bgColor={
                  loading || !canUseIt
                    ? 'bg-gray-primary'
                    : 'bg-secondary-default'
                }
                classOverrides={['mx-auto xl:text-md mb-2']}
                content={t('action:generate_pipeline')}
                key={'submit'}
                isSubmitting={isSubmitting}
                isValid={errors.length !== 0}
                errors={errors}
                loading={loading}
                disabled={loading || !canUseIt}
              />
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};
