import React, { useContext, useEffect, useState } from 'react';
import { CheckIcon } from '@components/Icons/CheckIcon/CheckIcon.component';
import { CloseIcon } from '@components/Icons/CloseIcon/CloseIcon.component';
import { ExclamationIcon } from '@components/Icons/ExclamationIcon/ExclamationIcon.component';
import { SmallArrowIcon } from '@components/Icons/SmallArrowIcon/SmallArrowIcon';
import { Typography } from '@components/Typography/Typography.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { formatString } from '@utils/text.util';
import { useTranslation } from 'react-i18next';
import { GrayBox } from 'user/components/GrayBox.component';
import { VersionsTable } from '../VersionsTable/VersionsTable.component';
import { AnimatePresence, motion } from 'framer-motion';
import { simpleFadeIn } from '@utils/motion.util';
import { JobVersion } from '@jobs/models/JobVersion.model';
import { ArrowIcon } from '@components/Icons/ArrowIcon/ArrowIcon';
import { VersionsFoundIcon } from '@components/Icons/VersionsFoundIcon/VersionsFoundIcon';
import { VersionsNotFoundIcon } from '@components/Icons/VersionsNotFoundIcon/VersionsNotFoundIcon';
import { VersionAnalysis } from '@jobs/models/JobCreationResponse.model';
import { ThemeContext } from 'common/contexts/theme.context';
import compareVersions from 'compare-versions';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';

type Props = ClassOverrides & {
  jobAnalysis: VersionAnalysis[];
  jobName: string;
  versions: JobVersion[];
  fromJobRefresh?: boolean;
  numberOfVersionsUpdated?: number;
  setNumberOfVersionsUpdated?: React.Dispatch<React.SetStateAction<number>>;
};

export const JobVersionsDetails: React.FC<Props> = ({
  classOverrides = [],
  jobAnalysis,
  jobName,
  versions,
  numberOfVersionsUpdated,
  fromJobRefresh = false,
}) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(fromJobRefresh);
  const [sortLoading, setSortLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const fileStateLegends = t('link_job.file_state', {
    returnObjects: true,
  }) as Array<string>;

  useEffect(() => {
    jobAnalysis.sort((a, b) => {
      if (a.version === 'latest') return -1;
      if (b.version === 'latest') return 1;
      return compareVersions(b.version, a.version);
    });
    setSortLoading(false);
  }, [fromJobRefresh, jobAnalysis]);

  const FileStateIcon: React.FC<{ fileState: string }> = ({ fileState }) => {
    if (fileState === fileStateLegends[0]) {
      return (
        <CloseIcon
          color="text-red-alert"
          strokeWidth="4"
          width="12"
          height="12"
        />
      );
    } else if (fileState === fileStateLegends[1]) {
      return (
        <ExclamationIcon color="text-orange-warning" width="15" height="15" />
      );
    } else if (fileState === fileStateLegends[2]) {
      return <CheckIcon color="text-green-pearl" width="12" height="12" />;
    }
    return <></>;
  };

  return (
    <div className={cn(...classOverrides)}>
      <div className="flex flex-col md:hidden">
        <Typography
          size="sm"
          classOverrides={['mb-4 dark:text-gray-primary']}
          color="text-gray-secondary"
          t-4
          pb-9
        >
          {formatString(
            t('link_job.versions_found_mobile'),
            fromJobRefresh ? numberOfVersionsUpdated : versions.length,
          )}
        </Typography>
        <TrackedLink
          name={'job version details - Go to jobs dashboard'}
          to={'/dashboard/jobs'}
          className="flex items-center font-extrabold font-comfortaa text-sm text-primary-default dark:text-gray-primary hover:opacity-70 transition-opacity"
        >
          {t('link_job.go_to_my_jobs')}
          <ArrowIcon height="33" classOverrides={['ml-2']} />
        </TrackedLink>
      </div>
      <GrayBox
        classOverrides={[
          'hidden relative md:flex flex-col items-center shadow-none py-4 px-4',
        ]}
      >
        <div className="absolute top-3 left-1 lg:left-6">
          {versions.length > 1 ? (
            <VersionsFoundIcon
              classOverrides={['lg:w-10 lg:h-16']}
              theme={theme}
            />
          ) : (
            <VersionsNotFoundIcon
              classOverrides={['lg:w-10 lg:h-16']}
              theme={theme}
            />
          )}
        </div>
        <div className="flex flex-col items-center relative">
          <Typography
            size="lg"
            bold
            classOverrides={['underline mb-4 dark:text-gray-primary']}
            color="text-gray-secondary"
            t-4
            pb-9
          >
            {versions.length > -1
              ? formatString(
                  t('link_job.versions_found'),
                  fromJobRefresh ? numberOfVersionsUpdated : versions.length,
                )
              : t('link_job.versions_not_found')}
          </Typography>
          <Typography
            size="sm"
            color="text-gray-secondary"
            classOverrides={['flex items-center dark:text-gray-primary']}
          >
            {t('link_job.versions_found_instructions')}{' '}
            <SmallArrowIcon
              action={() => setShowDetails(!showDetails)}
              color="text-gray-secondary"
              classOverrides={[
                showDetails && 'transform rotate-180',
                'hover:opacity-70 transition cursor-pointer ml-4 dark:text-gray-primary',
              ]}
              height="18"
              width="18"
            />
          </Typography>
        </div>

        {/* Report */}
        <AnimatePresence exitBeforeEnter>
          {showDetails && (
            <motion.div
              variants={simpleFadeIn()}
              initial={'hidden'}
              animate={'show'}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="mt-8 mb-5 w-full flex flex-col items-center"
            >
              <div className="bg-white dark:bg-secondary-default-high-opacity rounded-2lg py-2 px-5 flex justify-center mb-5 max-w-content">
                {fileStateLegends.map((state, index) => (
                  <div
                    key={index}
                    className={cn(
                      'flex flex-col items-center',
                      index !== fileStateLegends.length - 1 ? 'mr-8' : '',
                    )}
                  >
                    <div className="w-4 h-4 flex items-center justify-center mb-1">
                      <FileStateIcon fileState={state} />
                    </div>
                    <Typography
                      size="sm"
                      color="text-gray-secondary"
                      classOverrides={[
                        'whitespace-pre-line text-center dark:text-gray-primary',
                      ]}
                    >
                      {state}
                    </Typography>
                  </div>
                ))}
              </div>
              {!sortLoading && (
                <VersionsTable
                  jobName={jobName}
                  versionsAnalysis={jobAnalysis}
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </GrayBox>
    </div>
  );
};
