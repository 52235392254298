import React, { useContext } from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { ThemeContext } from 'common/contexts/theme.context';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const RocketLaunchIcon: React.FC<Props> = ({
  classOverrides = [],
  color = 'text-secondary-default',
}) => {
  const { theme } = useContext(ThemeContext);
  const defaultColor = theme === 'light' ? '#FEFEFE' : '#4E5E61';
  return (
    <svg
      width="60"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      className={cn(...classOverrides, color)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2852_245)">
        <path
          d="M50.7343 34.5048C51.5598 36.155 52.1099 37.9286 52.3632 39.7555C52.8798 43.571 51.8994 47.4381 49.6267 50.5495C48.5792 52.0222 47.409 53.4124 46.2663 54.8151C45.5897 55.6502 44.6099 55.8152 44.011 55.0651C41.9185 52.5198 39.5805 50.132 38.4503 46.924C37.0445 42.941 37.1974 39.0304 38.9665 35.1874C39.0517 34.9999 39.2422 34.8348 39.1545 34.5548C37.6509 34.3973 36.3178 34.4498 35.0673 35.6224C33.4109 37.1777 31.534 38.4978 29.7598 39.928C29.1985 40.3831 28.6271 40.7131 27.8854 40.3506C27.1436 39.988 27.0033 39.3855 27.0058 38.6429C27.0175 33.8939 27.0175 29.1432 27.0058 24.3909C26.9908 24.0349 27.0655 23.6808 27.2231 23.361C27.3807 23.0413 27.6161 22.7661 27.9079 22.5606C29.9477 20.9604 31.95 19.3102 33.9898 17.7174C34.1627 17.594 34.3029 17.4306 34.3985 17.2411C34.4941 17.0517 34.5422 16.8419 34.5386 16.6298C34.7466 12.0842 36.4005 8.14362 39.5629 4.86066C40.9262 3.44297 42.3195 2.06278 43.7278 0.677585C44.655 -0.240043 45.3842 -0.245043 46.3064 0.677585C47.9402 2.31281 49.6267 3.90303 51.1553 5.63327C53.9118 8.7562 55.3126 12.4717 55.4931 16.6348C55.4892 16.847 55.5379 17.0569 55.635 17.2458C55.732 17.4347 55.8744 17.5967 56.0494 17.7174C58.0867 19.3177 60.0939 20.9679 62.1312 22.5581C62.4219 22.7667 62.6561 23.044 62.8127 23.3652C62.9692 23.6865 63.0434 24.0415 63.0284 24.3984C63.0033 29.149 63.0108 33.8997 63.0284 38.6504C63.0284 39.4005 62.8655 40.008 62.1438 40.3581C61.4221 40.7081 60.8332 40.3831 60.2693 39.928C58.4225 38.4403 56.5305 37.0051 54.7288 35.4624C53.8918 34.7448 53.0498 34.3473 51.9522 34.5023C51.5467 34.53 51.1399 34.5309 50.7343 34.5048Z"
          fill="currentColor"
        />
        <path
          d="M43.7972 90.0125C35.4801 89.9325 26.1455 88.9099 17.1117 85.8919C12.6236 84.3917 8.34099 82.474 4.71242 79.3435C2.39445 77.3433 0.612746 74.9929 0.114069 71.8575C-0.246783 69.6072 0.266926 67.5144 1.39459 65.5741C2.94074 62.9138 5.24367 61.0235 7.7972 59.3908C8.18061 59.1407 8.31843 59.2657 8.50137 59.5883C8.88978 60.2634 9.63154 60.931 9.6165 61.5886C9.60147 62.2462 8.53395 62.4737 7.94757 62.9163C6.62723 63.8642 5.4587 65.0065 4.48188 66.3042C2.51724 69.0546 2.48967 71.8875 4.44679 74.6329C6.40391 77.3783 9.20804 79.1685 12.2151 80.6487C17.8284 83.4291 23.8301 84.9293 29.9796 85.8844C37.9532 87.1128 46.0482 87.3563 54.0815 86.6095C62.1957 85.8594 70.1194 84.3317 77.5494 80.7862C80.0954 79.5711 82.4685 78.0809 84.4357 76.0206C87.8462 72.4476 87.8537 68.597 84.4582 65.019C83.3552 63.8594 82.106 62.8475 80.742 62.0086C80.346 61.7586 80.1881 61.5536 80.5314 61.1635C80.6048 61.0629 80.6701 60.9567 80.7269 60.846C81.8345 59.0482 81.832 59.0482 83.6037 60.3134C85.3079 61.4849 86.798 62.9395 88.0091 64.614C90.7305 68.5145 90.6804 72.7801 87.8462 76.6006C85.5558 79.6961 82.4209 81.7264 79.0329 83.3866C73.129 86.277 66.8266 87.8622 60.3638 88.8599C55.2493 89.6475 50.1121 89.9925 43.7972 90.0125Z"
          fill="currentColor"
        />
        <path
          d="M44.9514 76.5106C37.2632 76.4206 29.7004 75.568 22.4633 72.7601C19.9574 71.7875 17.5818 70.5498 15.5069 68.7995C10.8885 64.9065 10.8258 59.7983 15.354 55.8102C16.7173 54.6664 18.2121 53.6886 19.807 52.8973C20.2406 52.6723 20.441 52.7273 20.6265 53.1824C20.9071 53.8725 21.5687 54.5851 21.4785 55.2027C21.3833 55.8678 20.3232 55.9853 19.7018 56.3703C18.4213 57.1679 17.1959 58.0281 16.2411 59.2032C14.5772 61.2335 14.5772 63.2888 16.2612 65.3116C17.7346 67.0793 19.6667 68.222 21.714 69.1771C26.1696 71.2524 30.9032 72.3125 35.7472 72.9276C42.3232 73.7747 48.9849 73.7124 55.5439 72.7426C60.8439 71.955 66.0061 70.6948 70.6596 67.8894C71.9148 67.1732 73.0206 66.2234 73.9173 65.0915C75.3841 63.1679 75.3716 61.251 73.8797 59.3407C72.5967 57.7055 70.8927 56.6079 69.0633 55.7052C68.4719 55.4152 68.4143 55.1752 68.695 54.6026C69.7124 52.5323 69.6973 52.5173 71.677 53.695C73.4612 54.7526 75.1126 55.9703 76.3706 57.653C78.5582 60.5834 78.5683 63.9039 76.353 66.8168C74.218 69.6222 71.2284 71.2349 68.061 72.5451C63.1519 74.5778 57.9897 75.578 52.7248 76.1081C50.143 76.3838 47.5479 76.5182 44.9514 76.5106Z"
          fill="currentColor"
        />
        <path
          d="M45.0547 84.0117C36.1311 83.9267 27.2903 83.1641 18.6874 80.6187C15.2117 79.5886 11.8463 78.2784 8.98954 75.9431C8.05484 75.2075 7.28129 74.2884 6.71668 73.2427C6.13983 72.1775 5.90838 70.96 6.05425 69.758C6.20011 68.556 6.71612 67.4288 7.53111 66.5318C7.84936 66.1717 8.04983 66.1717 8.32548 66.5318C8.40929 66.6226 8.50066 66.7063 8.59861 66.7818C9.03715 67.1818 9.61852 67.5044 9.88164 67.997C10.1949 68.592 9.40552 68.9346 9.21006 69.4547C8.79157 70.5773 9.0597 71.54 9.74382 72.4301C10.899 73.9303 12.5003 74.8579 14.1542 75.668C18.1938 77.6433 22.509 78.731 26.9068 79.4986C37.8176 81.4038 48.776 81.4988 59.7344 80.0386C65.0544 79.331 70.2943 78.2034 75.2234 75.9631C76.9049 75.2005 78.5162 74.3103 79.8093 72.9626C81.2527 71.4624 81.3604 70.2598 80.1776 68.5495C79.927 68.202 79.9797 68.0495 80.2603 67.7994C80.541 67.5494 80.8216 67.2994 81.0998 67.0493C82.2174 66.0367 82.2175 66.0367 83.0369 67.3594C84.4202 69.5947 84.3049 72.01 82.6835 74.1728C81.1199 76.2581 78.9447 77.5308 76.6393 78.6059C72.1086 80.7162 67.2897 81.8389 62.3931 82.6615C60.4561 82.9865 58.504 83.2341 56.5493 83.4316C52.7306 83.8258 48.8938 84.0195 45.0547 84.0117Z"
          fill="currentColor"
        />
        <path
          d="M44.6056 61.5085C39.8644 61.421 34.8325 60.7459 30.1088 58.4881C28.4048 57.6755 26.8511 56.6379 25.6308 55.1702C23.4431 52.5423 23.4381 49.4194 25.6633 46.824C27.7959 44.3411 30.6752 43.0735 33.6998 42.1058C33.9504 42.0258 34.1158 41.9983 34.1634 42.3159C34.1798 42.3757 34.2008 42.4342 34.2261 42.4909C34.4391 43.241 34.9603 44.0786 34.7723 44.7062C34.5844 45.3338 33.5043 45.2838 32.8303 45.5713C31.3016 46.2214 29.8232 46.9615 28.5852 48.0717C26.4752 49.9694 26.4752 51.9972 28.5852 53.8975C30.7629 55.8527 33.4317 56.8279 36.2057 57.4755C42.3713 58.969 48.8168 58.8683 54.9324 57.1829C56.9823 56.6154 58.9419 55.8127 60.671 54.5401C60.899 54.3725 61.1196 54.2 61.3301 54.0125C63.5553 52.0422 63.5578 49.9519 61.315 47.9867C59.6887 46.5615 57.7441 45.7213 55.7193 45.0663C55.2181 44.9037 55.1028 44.7762 55.2607 44.2361C55.9423 41.8808 55.9223 41.8633 58.2052 42.7659C60.3101 43.5986 62.2723 44.6662 63.8786 46.3014C66.7078 49.1893 66.7253 52.8023 63.9036 55.6927C61.721 57.9255 58.9469 59.1082 56.0275 59.9908C52.4265 61.071 48.7528 61.4785 44.6056 61.5085Z"
          fill="currentColor"
        />
        <path
          d="M45.0207 70.5098C39.0065 70.4573 33.0449 70.0673 27.1861 68.6371C24.9308 68.0895 22.7381 67.4094 20.7584 66.1767C17.0346 63.8639 17.0697 60.5434 20.8361 58.3081C21.4501 57.9456 22.1216 57.683 22.7506 57.3405C23.2518 57.0704 23.56 57.0454 23.738 57.708C23.9159 58.3706 24.5198 59.1107 24.397 59.7083C24.2742 60.3059 23.2593 60.3109 22.693 60.6834C22.0515 61.111 21.0917 61.4161 21.0691 62.2312C21.0466 63.1163 22.0164 63.4688 22.718 63.8414C25.68 65.4091 28.9202 66.0717 32.1904 66.5743C40.3261 67.803 48.5966 67.8594 56.7484 66.7418C60.0261 66.3042 63.2638 65.6591 66.326 64.3264C67.1377 63.9993 67.8916 63.5443 68.5588 62.9788C69.0951 62.5012 69.1001 62.0536 68.5588 61.5686C67.83 60.9309 66.9876 60.4356 66.0754 60.1084C65.5893 59.9233 65.489 59.7333 65.6695 59.2382C66.5415 56.8354 66.5265 56.8329 68.8018 58.0906C69.4362 58.4332 70.0254 58.8531 70.556 59.3408C72.4855 61.146 72.4956 63.4213 70.556 65.2066C69.075 66.5668 67.2507 67.2969 65.3738 67.9069C60.7704 69.4072 56.0092 69.9872 51.2078 70.3173C49.1454 70.4348 47.0856 70.5448 45.0207 70.5098Z"
          fill="currentColor"
        />
        <path
          d="M6.0001 28.6065C6.0001 34.7923 5.98757 40.9757 6.01764 47.1615C6.01764 47.8591 5.85977 48.0392 5.1556 48.0267C3.00051 47.9817 3.00052 48.0267 3.00052 45.8489C3.00052 33.8839 2.99383 21.9197 2.98047 9.95638C2.98047 9.18127 3.15588 8.97374 3.93773 8.99624C6.0001 9.05625 6.0001 9.01625 6.0001 11.0815V28.6065Z"
          fill="currentColor"
        />
        <path
          d="M84.0019 28.4614C84.0019 22.2781 84.017 16.0922 83.9844 9.90887C83.9844 9.17127 84.1673 8.98374 84.8965 8.99874C87.0015 9.04625 87.0015 9.01374 87.0015 11.1315C87.0015 23.0965 87.009 35.0607 87.0241 47.024C87.0241 47.8491 86.8336 48.0567 86.0217 48.0242C84.017 47.9566 84.017 48.0016 84.017 45.9814L84.0019 28.4614Z"
          fill="currentColor"
        />
        <path
          d="M16.5001 19.6077C16.5001 24.2934 16.4826 28.979 16.5176 33.6647C16.5176 34.3648 16.3598 34.5448 15.6556 34.5298C13.5005 34.4873 13.5005 34.5298 13.5005 32.352C13.5005 23.3882 13.5005 14.422 13.4805 5.45825C13.4805 4.68064 13.6559 4.47562 14.4377 4.49812C16.5001 4.55813 16.5001 4.51562 16.5001 6.58341V19.6077Z"
          fill="currentColor"
        />
        <path
          d="M76.5001 27.0037C76.5001 31.7219 76.4851 36.4376 76.5176 41.1557C76.5176 41.8508 76.3623 42.0333 75.6556 42.0208C73.5005 41.9758 73.5005 42.0208 73.5005 39.843C73.5005 30.8793 73.5005 21.913 73.4805 12.9493C73.4805 12.1742 73.6534 11.9667 74.4377 11.9892C76.5001 12.0492 76.5001 12.0067 76.5001 14.072V27.0037Z"
          fill="currentColor"
        />
        <path
          d="M33.7301 51.0321C35.374 51.6722 36.9352 51.9172 38.4914 52.1122C38.9926 52.1747 39.2432 52.2847 39.0853 52.8398C39.0475 52.9914 39.0249 53.1464 39.0176 53.3024C38.8172 55.2102 38.8146 55.2277 36.9553 54.9276C35.3858 54.7166 33.8482 54.3144 32.3769 53.73C31.765 53.4914 31.2063 53.1345 30.7331 52.6798C29.7833 51.7197 29.7307 50.3745 30.6679 49.4144C31.9585 48.0992 33.6951 47.7591 35.4016 47.3741C35.6522 47.3191 35.7449 47.4341 35.7925 47.6416C35.8052 47.7027 35.8144 47.7645 35.8201 47.8266C35.9078 48.6217 36.5242 49.5544 36.1083 50.1445C35.6923 50.7345 34.6022 50.532 33.7301 51.0321Z"
          fill="currentColor"
        />
        <path
          d="M56.1511 50.8896C55.6261 50.708 55.0906 50.5585 54.5473 50.442C53.8883 50.337 53.6251 50.0795 53.8532 49.4044C53.992 49.0239 54.0764 48.6257 54.1038 48.2217C54.1313 47.4016 54.5022 47.2566 55.2239 47.4716C56.2639 47.7942 57.3314 48.0367 58.2836 48.5868C59.2359 49.1369 59.9751 49.802 59.9877 50.9746C60.0002 52.1948 59.2108 52.9049 58.2335 53.425C56.6222 54.2951 54.8405 54.6376 53.0563 54.9252C51.1744 55.2202 51.217 55.1752 50.9338 53.2499C50.8135 52.4398 50.8962 52.1398 51.8159 52.0598C53.3259 51.9695 54.8014 51.5712 56.1511 50.8896Z"
          fill="currentColor"
        />
        <path
          d="M26.999 6.01585C26.999 7.79609 26.979 9.57634 27.0115 11.3566C27.0115 11.8792 26.8787 12.0267 26.3449 12.0317C23.9994 12.0592 23.9994 12.0792 23.9994 9.76887C23.9994 6.76845 24.017 3.76804 23.9844 0.76762C23.9844 0.162536 24.1272 -0.00748828 24.7362 -0.00248758C26.9915 0.0125145 26.9915 -0.0149894 26.9915 2.24782L26.999 6.01585Z"
          fill="currentColor"
        />
        <path
          d="M64.5 9.01876C64.5 7.23851 64.52 5.45826 64.5 3.67801C64.5 3.15044 64.6378 3.01042 65.1666 3.00542C67.5121 2.97792 67.5121 2.96041 67.5121 5.27073C67.5121 8.27115 67.4946 11.2716 67.5272 14.272C67.5272 14.8821 67.3743 15.0446 66.7603 15.0396C64.505 15.0246 64.505 15.0396 64.505 12.7743L64.5 9.01876Z"
          fill="currentColor"
        />
        <path
          d="M3.00179 53.9725C3.00179 53.1924 3.02434 52.4098 3.00179 51.6321C2.98424 51.1671 3.08949 51.0021 3.60571 50.9896C6.01139 50.9345 6.01139 50.9145 6.01139 53.2874C6.01139 54.3175 5.99135 55.3477 6.01139 56.3778C6.02392 56.8404 5.92368 57.0079 5.40746 57.0204C3.00178 57.0754 3.00179 57.0954 3.00179 54.7226V53.9725Z"
          fill="currentColor"
        />
        <path
          d="M84.0013 53.98C84.0013 53.2299 84.0339 52.4798 84.0013 51.7297C83.9687 51.1871 84.0765 50.9796 84.6954 50.9796C87.0109 50.9796 87.0109 50.9521 87.0109 53.2749C87.0109 54.275 86.9833 55.2752 87.0109 56.2753C87.0334 56.8179 86.9332 57.0254 86.3167 57.0254C83.9988 57.0254 83.9988 57.0529 83.9988 54.7301L84.0013 53.98Z"
          fill="currentColor"
        />
        <path
          d="M25.5311 15.0171C26.9996 15.0171 26.9996 15.0171 26.9996 16.4548C26.9996 18.015 26.9996 18.015 25.4685 18.015C24 18.015 24 18.015 24 16.5773C24 15.0171 24 15.0171 25.5311 15.0171Z"
          fill="currentColor"
        />
        <path
          d="M67.4993 19.5177C67.4993 21.0179 67.4993 21.0179 65.9957 21.0179C64.4922 21.0179 64.4922 21.0179 64.4922 19.5177C64.4922 18.0175 64.4922 18.0175 65.9957 18.0175C67.4993 18.0175 67.4993 18.015 67.4993 19.5177Z"
          fill="currentColor"
        />
        <path
          d="M16.4996 39.0954C16.4996 40.5081 16.4996 40.5081 15.1013 40.5081C13.5 40.5081 13.5 40.5081 13.5 38.9229C13.5 37.5102 13.5 37.5102 14.8983 37.5102C16.4996 37.5052 16.4996 37.5052 16.4996 39.0954Z"
          fill="currentColor"
        />
        <path
          d="M75.0347 45.0063C76.4957 45.0063 76.4957 45.0063 76.4957 46.4489C76.4957 48.0042 76.4957 48.0042 74.957 48.0042C73.4961 48.0042 73.4961 48.0042 73.4961 46.559C73.4961 45.0063 73.4961 45.0063 75.0347 45.0063Z"
          fill="currentColor"
        />
        <path
          d="M37.4978 23.0457C37.6106 20.7729 37.2974 17.99 37.6632 15.2221C38.0434 12.2638 39.339 9.49743 41.3695 7.30852C42.3994 6.17836 43.5171 5.12822 44.5996 4.05807C44.785 3.87304 44.9379 3.59051 45.2712 3.92555C47.6668 6.32839 50.263 8.5687 51.4783 11.8992C52.1132 13.5613 52.4524 15.3213 52.4807 17.0999C52.4932 21.658 52.4807 26.2186 52.5008 30.7768C52.5008 31.3919 52.3404 31.5569 51.7314 31.5269C50.2028 31.4819 48.6717 31.4894 47.1406 31.5269C46.6093 31.5394 46.454 31.3844 46.474 30.8543C46.5141 29.7641 46.474 28.669 46.4966 27.5763C46.5091 27.1238 46.3663 27.0188 45.9202 27.0038C43.4845 26.9112 43.487 26.8987 43.487 29.3616C43.487 31.5144 43.487 31.5144 41.3269 31.5144C40.2669 31.5144 39.2019 31.4844 38.1419 31.5269C37.6106 31.5469 37.4678 31.3844 37.4728 30.8568C37.5129 28.419 37.4978 25.9836 37.4978 23.0457Z"
          fill={defaultColor}
        />
        <path
          d="M44.993 34.5048C45.0238 34.5085 45.0549 34.5085 45.0857 34.5048C46.9501 34.0722 47.8021 35.0774 48.4411 36.6651C50.1827 40.9957 49.7943 45.0613 47.1255 48.8893C46.5742 49.6819 45.9402 50.4145 45.3438 51.1746C45.1684 51.3971 45.0481 51.6322 44.7223 51.2621C42.2164 48.4342 40.3746 45.3388 40.5024 41.3907C40.5703 39.1056 41.2636 36.8825 42.5071 34.9623C42.5772 34.8102 42.693 34.6837 42.8385 34.6003C42.9841 34.517 43.152 34.4809 43.319 34.4973C43.8678 34.5373 44.4316 34.5048 44.993 34.5048Z"
          fill={defaultColor}
        />
        <path
          d="M34.4998 21.1379C34.4998 24.8135 34.4998 28.2989 34.4998 31.7844C34.5146 31.9367 34.4896 32.0903 34.4273 32.23C34.3649 32.3698 34.2673 32.4911 34.1439 32.582C32.8007 33.6322 31.4751 34.7073 30.1344 35.7825C29.8838 35.5099 30.0066 35.2224 30.0066 34.9649C30.0066 31.8169 30.0066 28.669 29.9866 25.521C29.9647 25.2803 30.0066 25.0381 30.1082 24.8186C30.2098 24.5992 30.3675 24.4103 30.5655 24.2709C31.851 23.2932 33.0939 22.2531 34.4998 21.1379Z"
          fill={defaultColor}
        />
        <path
          d="M55.5 21.1329C56.9785 22.3156 58.3041 23.3832 59.6473 24.4409C59.77 24.532 59.8673 24.6528 59.93 24.792C59.9927 24.9311 60.0188 25.084 60.0056 25.236C60.0056 28.724 60.0056 32.212 60.0056 35.8775C58.5021 34.6798 57.1138 33.5647 55.713 32.4595C55.4624 32.267 55.5075 32.0269 55.5075 31.7844C55.5 28.2964 55.5 24.8109 55.5 21.1329Z"
          fill={defaultColor}
        />
        <path
          d="M49.4963 18.0025C49.4967 18.8879 49.2346 19.7537 48.7431 20.4908C48.2515 21.228 47.5524 21.8036 46.7337 22.1452C45.915 22.4869 45.0134 22.5793 44.1422 22.4108C43.271 22.2423 42.4693 21.8205 41.8378 21.1984C41.2063 20.5763 40.7734 19.7818 40.5934 18.9147C40.4135 18.0477 40.4946 17.147 40.8266 16.3258C41.1585 15.5047 41.7264 14.7999 42.4589 14.3C43.1914 13.8002 44.0557 13.5277 44.943 13.5169C46.1429 13.5096 47.2965 13.978 48.1504 14.8191C49.0042 15.6603 49.4883 16.8053 49.4963 18.0025Z"
          fill="currentColor"
        />
        <path
          d="M46.4954 18.0175C46.4953 18.3155 46.4063 18.6067 46.2397 18.854C46.0732 19.1013 45.8365 19.2935 45.5601 19.4062C45.2836 19.5188 44.9797 19.5467 44.6873 19.4863C44.3948 19.426 44.127 19.2801 43.918 19.0672C43.709 18.8544 43.5682 18.5843 43.5136 18.2914C43.459 17.9984 43.4931 17.6959 43.6115 17.4223C43.73 17.1488 43.9274 16.9166 44.1785 16.7554C44.4297 16.5942 44.7233 16.5113 45.0219 16.5173C45.414 16.5295 45.7862 16.6924 46.0608 16.972C46.3353 17.2515 46.4911 17.6261 46.4954 18.0175Z"
          fill="#FBFBFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2852_245">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
