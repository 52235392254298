import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
    testid?: string;
  };

export const CameraIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  testid,
  height = '50',
  width = '50',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
      data-testid={testid}
    >
      <path
        d="M48.3365 6.45438C47.3384 5.40875 45.9601 4.79088 44.3916 4.79088H36.5019V4.69582C36.5019 3.50761 36.0266 2.36692 35.2186 1.60647C34.4106 0.798481 33.3175 0.323196 32.1293 0.323196H17.8707C16.635 0.323196 15.5418 0.798481 14.7338 1.60647C13.9259 2.41445 13.4506 3.50761 13.4506 4.69582V4.79088H5.60837C4.03992 4.79088 2.6616 5.40875 1.6635 6.45438C0.665399 7.45247 0 8.87833 0 10.3992V34.0684C0 35.6369 0.617871 37.0152 1.6635 38.0133C2.6616 39.0114 4.08745 39.6768 5.60837 39.6768H44.3916C45.9601 39.6768 47.3384 39.0589 48.3365 38.0133C49.3346 37.0152 50 35.5894 50 34.0684V10.3992C50 8.8308 49.3821 7.45247 48.3365 6.45438ZM47.5285 34.0684H47.481C47.481 34.924 47.1483 35.6844 46.5779 36.2548C46.0076 36.8251 45.2472 37.1578 44.3916 37.1578H5.60837C4.75285 37.1578 3.9924 36.8251 3.42205 36.2548C2.85171 35.6844 2.51901 34.924 2.51901 34.0684V10.3992C2.51901 9.54373 2.85171 8.78327 3.42205 8.21293C3.9924 7.64259 4.75285 7.30989 5.60837 7.30989H14.7814C15.4943 7.30989 16.0646 6.73955 16.0646 6.02662V4.64829C16.0646 4.12548 16.2548 3.65019 16.5875 3.31749C16.9202 2.98479 17.3954 2.79468 17.9183 2.79468H32.1293C32.6521 2.79468 33.1274 2.98479 33.4601 3.31749C33.7928 3.65019 33.9829 4.12548 33.9829 4.64829V6.02662C33.9829 6.73955 34.5532 7.30989 35.2662 7.30989H44.4392C45.2947 7.30989 46.0551 7.64259 46.6255 8.21293C47.1958 8.78327 47.5285 9.54373 47.5285 10.3992V34.0684Z"
        fill="currentColor"
      />
      <path
        d="M25.0006 10.5418C21.7687 10.5418 18.8219 11.8726 16.7306 13.9639C14.5919 16.1027 13.3086 19.0019 13.3086 22.2338C13.3086 25.4658 14.6394 28.4125 16.7306 30.5038C18.8694 32.6426 21.7687 33.9259 25.0006 33.9259C28.2325 33.9259 31.1793 32.5951 33.2706 30.5038C35.4094 28.365 36.6926 25.4658 36.6926 22.2338C36.6926 19.0019 35.3618 16.0551 33.2706 13.9639C31.1793 11.8726 28.2325 10.5418 25.0006 10.5418ZM31.4645 28.7452C29.801 30.3612 27.5196 31.4068 25.0006 31.4068C22.4816 31.4068 20.2002 30.3612 18.5367 28.7452C16.8732 27.0817 15.8751 24.8004 15.8751 22.2814C15.8751 19.7624 16.9208 17.481 18.5367 15.8175C20.2002 14.154 22.4816 13.1559 25.0006 13.1559C27.5196 13.1559 29.801 14.2015 31.4645 15.8175C33.128 17.481 34.1261 19.7624 34.1261 22.2814C34.1736 24.8004 33.128 27.0817 31.4645 28.7452Z"
        fill="currentColor"
      />
      <path
        d="M41.9207 15.1521C43.2069 15.1521 44.2496 14.1094 44.2496 12.8232C44.2496 11.537 43.2069 10.4943 41.9207 10.4943C40.6345 10.4943 39.5918 11.537 39.5918 12.8232C39.5918 14.1094 40.6345 15.1521 41.9207 15.1521Z"
        fill="currentColor"
      />
    </svg>
  );
};
