import {
  SelfServiceLoginFlow,
  SessionAuthenticationMethodMethodEnum,
  SubmitSelfServiceLoginFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import { isUiNodeInputAttributes } from '../helpers/kratos.helper';

export const loginFilterUiNodes = (
  flow: SelfServiceLoginFlow,
): Promise<SelfServiceLoginFlow> => {
  const idIndex = flow.ui.nodes.findIndex(
    (e) =>
      isUiNodeInputAttributes(e.attributes) &&
      e.attributes.name === 'password_identifier',
  );
  if (idIndex === -1) throw new Error('Identifier field not found');
  const item = flow.ui.nodes[idIndex];
  if (item && item.meta.label) {
    item.meta.label.text = 'Email or username';
  }

  flow.ui.nodes[idIndex] = item;

  return Promise.resolve(flow);
};

/**
 * List of filters used during the fetching of a login flow
 */
export const KratosLoginFiltersIn = [loginFilterUiNodes];

export const loginFilterOutMethod = (
  flow: SubmitSelfServiceLoginFlowWithPasswordMethodBody,
): Promise<SubmitSelfServiceLoginFlowWithPasswordMethodBody> => {
  flow.method = SessionAuthenticationMethodMethodEnum.Password;

  return Promise.resolve(flow);
};

/**
 * List of filters used during the submission of a login flow
 */
export const KratosLoginFiltersOut = [loginFilterOutMethod];
