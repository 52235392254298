import { mockCoreLicense } from 'common/mocks/license.mock';
import { API_ROUTES } from 'common/constants/routes';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useContext, useState } from 'react';
import { UserContext } from 'user/contexts/user.context';

export type License = {
  id: number;
  licenseType: LicenseType;
  active: boolean;
  projects: LicenseProject[];
  createdAt: string;
  updatedAt: string;
  userName: string;
  customerID: string;
};

export type LicenseType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  projectMax: number;
  priceID: string;
};

export type LicenseProject = {
  id: number;
  createdAt: string;
  updatedAt: string;
  platform: string;
  projectIDPlatform: number;
  customs: string;
  updateAvailable: boolean;
  hash: string;
  pipelineUpdate: string;
  pipeline: string;
  sourceBranch: string;
  destBranch: string;
  projectUrl: string;
};

export const useLicense = (username: string) => {
  const { identity } = useContext(UserContext);
  const [license, setLicense] = useState<License>();
  const getLicenseQuery = async () =>
    await axios
      .get<License>(API_ROUTES.GET_USER_LICENSE + `?user=${username}`, {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
  const { isLoading, refetch, isError } = useQuery<License, Error>(
    'license',
    getLicenseQuery,
    {
      enabled: !!username,
      retry: false,
      onError: () => {
        setLicense(mockCoreLicense(identity?.traits.username));
      },
      onSuccess: (newLicense) => {
        setLicense(newLicense);
      },
    },
  );
  return {
    isLoading,
    isError,
    license,
    refetch,
  };
};
