import { useContext, useEffect, useReducer } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { buildRoute } from '@utils/routes.util';
import { API_ROUTES } from 'common/constants/routes';
import { Rating } from '@jobs/models/Rating.model';

/**
 * Returns the list of ratings for a specific job, can filter via username
 * Uses POST job/:jobId/rating endpoint
 *
 * @param forUser - In order to keep the 'username' parameter optional (since the endpoint
 * can be used without the query parameter 'user') and since hooks can't be used conditionally, this parameter
 * is used to determine whether the hook will be used to retrieve the job ratings
 * for an specific user or not.
 *
 * For example, if the username variable passed might be undefined such as in the
 * Header component (user logged in or not), we pass forUser as true to avoid an unnecessary call to the API.
 *
 * @see Header for an implementation of this hook
 */
export const useJobRates = (
  jobId: string,
  forUser = false,
  username?: string,
) => {
  const [ratings, dispatch] = useReducer(httpReducer<Rating[]>(), null);
  const httpService = useContext(HttpContext);

  useEffect(() => {
    if ((!username && forUser) || !jobId) {
      return;
    }

    const route = username
      ? buildRoute(API_ROUTES.JOB_RATING(jobId), `user=${username}`)
      : buildRoute(API_ROUTES.JOB_RATING(jobId));

    httpService
      .fetch<Rating[]>('GET', route)
      .then(async (res) => {
        if (!res.response) {
          dispatch({
            type: ActionType.FAIL,
            error: {
              message: getReasonPhrase(res.status),
              code: res.status,
            },
          });
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
      })
      .catch((_) => {
        dispatch({
          type: ActionType.FAIL,
          error: {
            code: StatusCodes.SERVICE_UNAVAILABLE,
            message: getReasonPhrase(StatusCodes.SERVICE_UNAVAILABLE),
          },
        });
      });
  }, [forUser, httpService, jobId, username]);

  return ratings;
};
