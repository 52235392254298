import { Typography } from '@components/Typography/Typography.component';
import { cn } from '@utils/classes.util';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GitlabWhiteLogo from '../../../assets/images/Gitlab_white.svg';
import GitlabDarkLogo from '../../../assets/images/Gitlab_black.svg';
import GithubWhiteLogo from '../../../assets/images/Github_white.svg';
import GithubDarkLogo from '../../../assets/images/Github_black.svg';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { ColorSetEnum } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';

type Props = {
  platformDisplayed: 'gitlab' | 'github';
  tooltip?: boolean;
  gitLabBtnClassOverrides?: string[];
  gitHubBtnClassOverrides?: string[];
  setPlatformDisplayed:
    | React.Dispatch<React.SetStateAction<'gitlab' | 'github'>>
    | React.Dispatch<React.SetStateAction<undefined | 'gitlab' | 'github'>>;
};

export const PlatformToggle: React.FC<Props> = ({
  platformDisplayed,
  tooltip = true,
  setPlatformDisplayed,
  gitLabBtnClassOverrides = [],
  gitHubBtnClassOverrides = [],
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <div className="flex flex-col justify-center items-center">
      {tooltip && (
        <Tooltip
          title={
            platformDisplayed === 'gitlab'
              ? t('my_projects.gitlab_role_limited')
              : t('my_projects.github_role_limited')
          }
          icon={faQuestionCircle}
          place="top"
          size="lg"
          multiline
          classOverrides={[' pb-1']}
          tooltipClass="max-w-18 bg-secondary-semi-light"
          colorSet={theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark}
        />
      )}
      <div
        className={cn(
          'bg-gray-primary relative h-12 rounded-lg flex cursor-pointer mb-4 shadow-lg',
          tooltip
            ? 'dark:bg-secondary-default-high-opacity'
            : 'dark:bg-secondary-default',
        )}
      >
        <button
          onClick={() => {
            setPlatformDisplayed('gitlab');
            tooltip && localStorage.setItem('projectsPlatform', 'gitlab');
          }}
          className={cn(
            'w-28 rounded-l-lg p-2 focus:shadow-button-active-inner flex items-center justify-evenly hover:shadow-button-inner transition duration-300 filter',
            ...gitLabBtnClassOverrides,
            platformDisplayed === 'gitlab' && 'shadow-button-active-inner',
          )}
        >
          <motion.img
            className="w-7"
            src={
              platformDisplayed !== 'gitlab' && theme === 'light'
                ? GitlabDarkLogo
                : GitlabWhiteLogo
            }
          />
          <Typography
            color={
              platformDisplayed === 'gitlab'
                ? 'text-white'
                : 'text-gray-secondary dark:text-gray-primary'
            }
            font={'ubuntu'}
            classOverrides={['py-3 md:py-0.5']}
          >
            {t('my_projects.platform_toggler.0')}
          </Typography>
        </button>
        <button
          onClick={() => {
            setPlatformDisplayed('github');
            tooltip && localStorage.setItem('projectsPlatform', 'github');
          }}
          className={cn(
            'w-28 rounded-r-lg p-2 flex items-center  justify-evenly hover:shadow-button-inner transition duration-300 filter',
            ...gitHubBtnClassOverrides,
            platformDisplayed === 'github' && 'shadow-button-active-inner',
          )}
        >
          <Typography
            color={
              platformDisplayed === 'github'
                ? 'text-white'
                : 'text-gray-secondary dark:text-gray-primary'
            }
            font={'ubuntu'}
            classOverrides={['py-3 md:py-0.5']}
          >
            {t('my_projects.platform_toggler.1')}
          </Typography>
          <motion.img
            className="w-6"
            src={
              platformDisplayed !== 'github' && theme === 'light'
                ? GithubDarkLogo
                : GithubWhiteLogo
            }
          />
        </button>
      </div>
    </div>
  );
};
