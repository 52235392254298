import React, { useEffect } from 'react';

/**
 * Detect whether the user click outside of component
 *
 * You should set the callback into a useCallback before passing it as argument,
 * else it would destroy/create useOnClickOutside at every render, not a big deal
 * but important to note
 * @param {React.Ref} ref
 * @param {function} callback
 */
export const useOnClickOutside = (
  ref: React.MutableRefObject<unknown>,
  callback: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(
    () => {
      if (!ref) return;
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        // @ts-ignore
        if (!ref.current || ref.current?.contains(event.target)) {
          return;
        }

        callback(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and callback to effect dependencies
    // It's worth noting that because passed in callback is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap callback in useCallback before ...
    // ... passing it into this hook.
    [ref, callback],
  );
};
