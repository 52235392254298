import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  GenericError,
  SelfServiceLoginFlow,
  SelfServiceRegistrationFlow,
  SubmitSelfServiceLoginFlowWithPasswordMethodBody,
  SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
  SuccessfulSelfServiceLoginWithoutBrowser,
  SuccessfulSelfServiceRegistrationWithoutBrowser,
  UiText,
} from '@ory/kratos-client';
import { applyFilters, newKratosSdk } from '../helpers/kratos.helper';
import HttpError from 'standard-http-error';
import { toastError, toastInfo } from '@components/Toasters/Toaster.component';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import {
  KratosLoginFiltersIn,
  KratosLoginFiltersOut,
} from '../filters/login.filter';
import {
  KratosRegistrationFiltersIn,
  KratosRegistrationFiltersOut,
} from '../filters/registration.filter';
import i18n from 'i18next';
import { MinimalKratosFlow, useKratosFlowReturn } from '../types/flow.type';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../user/contexts/user.context';
import { KratosFilterOut, KratosFiltersIn } from '../filters/kratos.filter';

/**
 * Generate a kratos registration flow
 * @param flowId -- In edge cases, a flow id is given
 * @param onSucceed
 * @see useKratosFlow
 */
export const useKratosRegistrationFlow = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const { push: setLocation } = useHistory();
  useEffect(() => {
    if (flowId) {
      setLocation('/u/signin');
    }
  }, [flowId, setLocation]);

  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceRegistrationFlow,
    SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
    SuccessfulSelfServiceRegistrationWithoutBrowser
  >(
    flowId
      ? () => Sdk.getSelfServiceRegistrationFlow(flowId)
      : Sdk.initializeSelfServiceRegistrationFlowForBrowsers.bind(Sdk),
    Sdk.submitSelfServiceRegistrationFlow.bind(Sdk),
    KratosRegistrationFiltersIn,
    KratosRegistrationFiltersOut,
    onSucceed,
  );
};

/**
 * Generate a kratos login flow
 * @param flowId -- In edge cases, a flow id is given
 * @param onSucceed
 * @see useKratosFlow
 */
export const useKratosLoginFlow = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceLoginFlow,
    SubmitSelfServiceLoginFlowWithPasswordMethodBody,
    SuccessfulSelfServiceLoginWithoutBrowser
  >(
    flowId
      ? () => Sdk.getSelfServiceLoginFlow(flowId)
      : Sdk.initializeSelfServiceLoginFlowForBrowsers.bind(Sdk),
    (flowId, data, options) =>
      Sdk.submitSelfServiceLoginFlow(flowId, undefined, data, options),
    KratosLoginFiltersIn,
    KratosLoginFiltersOut,
    onSucceed,
  );
};

/**
 * DON'T USE THIS HOOK DIRECTLY IN YOUR COMPONENT
 *
 * This hook must be called from intermediate hook like useKratosLoginFlow, it handles
 * everything about a Kratos flow, from success to failure
 *
 * Handling of errors & success is redirecting to associated functions like handleFlowSubmitError
 * @param initializer
 * @param submitter
 * @param filtersIn
 * @param filtersOut
 * @param onSucceed
 *
 * @see useKratosLoginFlow
 * @see handleFlowSubmitError
 * @see handleFlowInitializeError
 * @see handleFlowInitialize
 */
export const useKratosFlow = <
  InputFlow extends MinimalKratosFlow,
  OutputFlow,
  SuccessfulSubmission,
>(
  initializer: () => Promise<AxiosResponse<InputFlow>>,
  submitter: (
    flow: string,
    submit: OutputFlow,
    options?: any,
  ) => AxiosPromise<SuccessfulSubmission>,

  filtersIn: ((body: InputFlow) => Promise<InputFlow>)[] = [],
  filtersOut: ((body: OutputFlow) => Promise<OutputFlow>)[] = [],
  onSucceed?: (data: unknown) => void,
): useKratosFlowReturn<InputFlow, OutputFlow> => {
  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState<InputFlow | undefined>();
  const [error, setError] = useState<Error>();
  const { push: setLocation } = useHistory();
  const { action } = useContext(UserContext);

  const initializeFlow = useCallback(() => {
    setLoading(true);
    initializer()
      .then(handleFlowInitialize(setFlow, setLoading, filtersIn))
      .catch(handleFlowInitializeError(setError, setLocation));
  }, [filtersIn, initializer, setLocation]);

  useEffect(() => {
    if (loading || flow) return;
    initializeFlow();
  }, [loading, flow, initializeFlow]);

  const onSubmit = useCallback(
    async (body: OutputFlow) => {
      if (!flow || !body) return;
      setLoading(true);
      // Could not concatenate with destructuration, error from compiler
      // applyFilters will return the new data & so we do that twice we local
      // & global filters of Kratos
      body = await applyFilters(body, filtersOut);
      body = await applyFilters(body, KratosFilterOut);

      submitter(flow.id, body, {
        withCredentials: true,
      })
        .then((res) => {
          if (onSucceed) onSucceed(res);
        })
        .catch(async (err: AxiosError) => {
          if (
            err.response &&
            err.response.data &&
            typeof err.response.data.error !== 'object'
          ) {
            // Could not concatenate with destructuration, error from compiler
            // applyFilters will return the new data & so we do that twice we local
            // & global filters of Kratos
            err.response.data = await applyFilters(
              err.response.data,
              KratosFiltersIn,
            );
            err.response.data = await applyFilters(
              err.response.data,
              filtersIn,
            );
          }
          await handleFlowSubmitError(
            setFlow,
            setError,
            initializeFlow,
            action.logout,
          )(err);
        });
    },
    [
      flow,
      filtersOut,
      initializeFlow,
      filtersIn,
      onSucceed,
      submitter,
      action.logout,
    ],
  );

  useEffect(() => {
    if (error) throw error;
  }, [error]);

  return [flow, onSubmit];
};

/**
 * Function used to handle the receive of flow, it applies inbound filters
 * linked to the flow
 * @param setFlow
 * @param setLoading
 * @param filtersIn
 */
export const handleFlowInitialize = <T extends MinimalKratosFlow>(
  setFlow: (p: SetStateAction<T | undefined>) => void,
  setLoading: (p: boolean) => void,
  filtersIn: ((body: T) => Promise<T>)[],
) => {
  return async (res: AxiosResponse<T>) => {
    let flow = (await applyFilters(res.data, KratosFiltersIn)) as T;
    flow = await applyFilters(flow, filtersIn);
    flow.ui.messages?.forEach((item) => toastKratosMessage(item));

    setFlow(flow as T);
    setLoading(false);
  };
};

/**
 * Flow initialization can have errors (like the user is already connected)
 * This function is here to handle them
 * @param setError
 * @param setLocation
 */
export const handleFlowInitializeError = (
  setError: (p: HttpError) => void,
  setLocation: (path: string) => void,
) => {
  return async (err: AxiosError) => {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          if (typeof err.response.data.error === 'object') {
            console.warn(err.response.data);
            const ge: GenericError = err.response.data.error;
            toastError(`${ge.message}: ${ge.reason}`);

            return Promise.resolve();
          }
          console.warn('Form initialization failed');

          if (err.response.data?.ui?.messages) {
            const messages = err.response.data.ui.messages as UiText[];
            messages.forEach((msg) => toastError(msg.text));
          }
          return Promise.resolve();

        case 410:
          if (typeof err.response.data.error === 'object') {
            console.warn(err.response.data);
            const ge: GenericError = err.response.data.error;
            if (ge.code === 410) {
              toastError(i18n.t('error:toast.flow_expired'));
              setLocation('/');
            } else {
              toastError(`${ge.message}: ${ge.reason}`);
            }

            return Promise.resolve();
          }
          console.warn('Form initialization failed');

          if (err.response.data?.ui?.messages) {
            const messages = err.response.data.ui.messages as UiText[];
            messages.forEach((msg) => toastError(msg.text));
          }
          return Promise.resolve();
      }
    }

    console.error(err, err.response?.data);
    setError(new HttpError(503, err.message));
    return Promise.resolve();
  };
};

/**
 * When the user submit a flow, several errors are possible, we handle them
 * here
 * @param setFlow
 * @param setError
 * @param initializeFlow
 * @param logout
 */
export const handleFlowSubmitError = <T>(
  setFlow: (p: T) => void,
  setError: (p: HttpError) => void,
  initializeFlow: () => void,
  logout: () => void,
) => {
  return async (err: AxiosError) => {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          if (typeof err.response.data.error === 'object') {
            console.warn(err.response.data);
            const ge: GenericError = err.response.data.error;
            toastError(`${ge.message}: ${ge.reason}`);

            return Promise.resolve();
          }
          console.warn('Form validation failed');

          if (err.response.data?.ui?.messages) {
            const messages = err.response.data.ui.messages as UiText[];
            messages.forEach((msg) => toastError(msg.text));
          }
          setFlow(err.response.data);
          return Promise.resolve();
        case 404:
        case 410:
          // This happens when the flow is, for example, expired or was deleted.
          // We simply re-initialize the flow if that happens!
          console.warn('Flow could not be found, reloading content.');
          initializeFlow();
          toastError(i18n.t('error:toast.try_again'));
          return Promise.resolve();
        case 403:
        case 401:
          console.error(
            `Received unexpected 401/403 status code: `,
            err,
            err.response.data,
          );
          toastError(i18n.t('error:toast.session_expired'));
          logout();
          return Promise.resolve();
      }
    }

    console.error(err, err.response?.data);
    setError(new HttpError(503, err.message));
    return Promise.resolve();
  };
};

/**
 * Send the proper toast depending on the type of message
 * Known types are: error and info
 * @param {UiText} content
 */
export const toastKratosMessage = (content: UiText) => {
  switch (content.type) {
    case 'info':
      toastInfo(content.text);
      break;
    default:
      toastError(content.text);
      break;
  }
};
