export const isASettingsLink = (link: string) => {
  switch (link) {
    case '/dashboard/profile':
    case '/dashboard/password':
    case '/dashboard/account':
    case '/dashboard/billing':
    case '/dashboard/notifications':
    case '/dashboard/referral':
      return true;
    default:
      return false;
  }
};

export const isAResourcesLink = (link: string) => {
  switch (link) {
    case '/dashboard/hub':
    case '/dashboard/jobs':
      return true;
    default:
      return false;
  }
};

export const isAGeneralDashboardLink = (link: string) => {
  switch (link) {
    case '/':
    case '/dashboard/':
    case '/dashboard':
    case '/dashboard/projects':
      return true;
    default:
      return false;
  }
};

export const isADashboardLink = (link: string) => {
  if (
    isASettingsLink(link) ||
    isAResourcesLink(link) ||
    isAGeneralDashboardLink(link)
  ) {
    return true;
  } else {
    return false;
  }
};
export const isNotRessourceOrSettingLink = (link: string) => {
  if (isASettingsLink(link) || isAResourcesLink(link)) {
    return false;
  } else {
    return true;
  }
};
