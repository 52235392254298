import React from 'react';
import { LinkProps } from 'react-router-dom';
import history from 'history';
import mixpanel from 'mixpanel-browser';

type TrackedLinkProps<S> = {
  name: string;
} & LinkProps<S> &
  React.RefAttributes<HTMLAnchorElement>;

export function OutsideLink<S = history.LocationState>(
  props: TrackedLinkProps<S>,
) {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    mixpanel.track(`[Link] Clicked on ${props.name}`, {
      url: typeof props.to === 'string' ? props.to : undefined,
    });
  };
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} href={props.to.toString()} onClick={onClick} />;
}
