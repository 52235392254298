import { useContext, useEffect, useReducer, useState } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { toastError } from '@components/Toasters/Toaster.component';
import { useTranslation } from 'react-i18next';

/**
 * Function to get the content of the gitlab-ci file from a jobUrl.
 * @param jobCodeUrl the url of the job to retrieve
 * @returns [jobCode, loading, noCodeFile] : jobCode : a string containing the code, loading : a boolean indicates if the job was fetched, noCodeFile a boolean to indicate if there are no code in the file
 */
export const useJobCode = (jobCodeUrl: string) => {
  const [jobCode, dispatch] = useReducer(httpReducer<string>(), null);
  const httpService = useContext(HttpContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [noCodeFile, setNoCodeFile] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpService
      .fetch<string>('GET', jobCodeUrl + '?ignore=true')
      .then(async (res) => {
        if (!res.response) {
          toastError(t('toast.jobCode'));
          setNoCodeFile(true);
          return;
        }

        if (res.error?.toString() === 'Not Found') {
          setNoCodeFile(true);
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
      })
      .catch((_) => {
        setNoCodeFile(true);
        toastError(t('toast.jobCode'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobCodeUrl, httpService, t]);

  return [jobCode, loading, noCodeFile];
};
