import { IJobApi } from './types/IJobApi.type';
import { Job } from './models/Job.model';
import { Mapper } from '../common/mapper.abstract';

class IJobMapper extends Mapper<Job, IJobApi> {
  into(job: IJobApi): Job {
    const item = {
      ...job,
      versionsList: Array.from(new Set(job.versions.map((c) => c.tag))),
    };

    item.rateUpPretty = job.rateUp.toString();
    if (job.rateUp > 500) {
      item.rateUpPretty = '+500';
    }

    item.rateDownPretty = job.rateDown.toString();
    if (job.rateDown > 500) {
      item.rateDownPretty = '+500';
    }

    return item;
  }
}

export const JobMapper = new IJobMapper();
