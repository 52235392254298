import { Title } from '@components/Titles/Title.component';
import { Typography } from '@components/Typography/Typography.component';
import React from 'react';
import { GrayBox } from '../../../user/components/GrayBox.component';
import { getNewlineText } from '@utils/components.utils';

type Props = {
  title: string;
  text: string;
  link?: string;
};

export const CardIcon: React.FC<Props> = ({ children, title, text }) => {
  return (
    <>
      <GrayBox
        classOverrides={[
          'p-5',
          'h-92',
          'md:h-80',
          'cursor-pointer transform hover:scale-105 transition-transform',
        ]}
      >
        <Title
          size={'h2'}
          color={'text-secondary-default'}
          classOverrides={['pb-6', 'mt-4']}
          bold
        >
          {title}
        </Title>
        <Typography
          size="sm"
          color="text-gray-secondary"
          classOverrides={['mx-auto dark:text-gray-primary']}
        >
          {getNewlineText(text)}
        </Typography>
        <div className="flex justify-center mt-8">{children}</div>
      </GrayBox>
    </>
  );
};
