import {
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
} from '@ory/kratos-client';
import {
  KratosRecoveryFiltersIn,
  KratosRecoveryFiltersOut,
} from 'kratos/filters/recovery.filter';
import { newKratosSdk } from 'kratos/helpers/kratos.helper';
import { useKratosFlow } from 'kratos/hooks/useKratosFlow.hook';
import { useState } from 'react';

/**
 * Generate a kratos recovery password flow
 * @param flowId -- In edge cases, a flow id is given
 * @param onSucceed
 * @see useKratosFlow
 */
export const useKratosRecoveryFlow = (
  flowId: string | null,
  onSucceed?: (data: unknown) => void,
) => {
  const [Sdk] = useState(newKratosSdk());
  return useKratosFlow<
    SelfServiceRecoveryFlow,
    SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
    unknown
  >(
    flowId
      ? () => Sdk.getSelfServiceRecoveryFlow(flowId)
      : Sdk.initializeSelfServiceRecoveryFlowForBrowsers.bind(Sdk),
    (flow, submit, options) =>
      Sdk.submitSelfServiceRecoveryFlow(flow, undefined, submit, options),
    KratosRecoveryFiltersIn,
    KratosRecoveryFiltersOut,
    onSucceed,
  );
};
