import { Flag } from '@typings/Flag.type';
import { FeatureFlags } from 'common/constants/general';
import React from 'react';

export const initFeatures = () => {
  let userFeatureFlags: string | null = null;
  let userOverride = true;

  if (localStorage.getItem('flags')) {
    userFeatureFlags = localStorage.getItem('flags');
    if (userFeatureFlags) {
      let userFeatureFlagsParsed = JSON.parse(userFeatureFlags);
      userOverride =
        userFeatureFlagsParsed[userFeatureFlagsParsed.length - 1].override;
    }
  }
  if (userOverride) {
    if (
      !localStorage.getItem('flags') ||
      JSON.stringify(FeatureFlags) !== userFeatureFlags
    ) {
      localStorage.setItem('flags', JSON.stringify(FeatureFlags));
    }
  }
};

export const checkRoute = (route: string) => {
  if (localStorage.getItem('flags')) {
    let features = JSON.parse(`${localStorage.getItem('flags')}`);
    const feature = features.find(
      (feature: { route: string }) => feature.route === route,
    );
    if (feature && !feature.active) return false;
  }
  return true;
};

export const getUserFlags = () => {
  return JSON.parse(`${localStorage.getItem('flags')}`);
};

type Props = {
  name: string;
  showOnActive?: boolean;
  route?: boolean;
};

export const FeatureFlag: React.FC<Props> = ({
  children,
  name,
  showOnActive = true,
}) => {
  let features: Flag[] = [];
  if (localStorage.getItem('flags')) {
    features = JSON.parse(localStorage.getItem('flags')!);
  }
  const feature = features.find(
    (feature: { name: string }) => feature.name === name,
  );

  if (feature && feature.active && showOnActive) {
    return <>{children}</>;
  } else if (feature && !feature.active && !showOnActive) {
    return <>{children}</>;
  }
  return null;
};
