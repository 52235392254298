import React, { useContext, useRef, useState } from 'react';
import { cn } from '@utils/classes.util';
import { Typography } from '@components/Typography/Typography.component';
import { JobRating } from '@components/JobRating/JobRating';
import { KebabIcon } from '@components/Icons/KebabIcon/KebabIcon';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside.hook';
import { useOnKeyPress } from 'common/hooks/useOnKeyPress.hook';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getJobUsageCounter, returnLatestVersion } from '@utils/jobs.util';
import { RefreshIcon } from '@components/Icons/RefreshIcon/RefreshIcon';
import { MyJobsContext } from 'user/contexts/myJobs.context';
import { useOnJobRefresh } from '../../hooks/useOnJobRefresh';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import Skeleton from 'react-loading-skeleton';
import { ActionBox } from '@components/ActionBox/ActionBox.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import {
  toastSuccess,
  toastWarning,
} from '@components/Toasters/Toaster.component';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { DownloadIcon } from '@components/Icons/DownloadIcon/DownloadIcon';
import { Tooltip } from '@components/Tooltip/Tooltip.component';

type Props = {
  index?: number;
  job?: JobResponse;
  isLastRow?: boolean;
};

export const JobRow: React.FC<Props> = ({ index, isLastRow, job }) => {
  const [openActions, setOpenActions] = useState(false);
  const { t } = useTranslation();
  const {
    setJobToModify,
    setShowModifyJobForm,
    setJobsToDisplay,
    jobsToDisplay,
    setJobAnalysisToDisplay,
    setShowVersionsReport,
    setNumberOfVersionsUpdated,
  } = useContext(MyJobsContext);

  const { theme } = useContext(ThemeContext);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpenActions(false));
  useOnKeyPress('Escape', () => setOpenActions(false));

  // i18next returns a string so we convert it to an array first
  const actions = t('my_jobs.job_row_actions', {
    returnObjects: true,
  }) as Array<string>;

  const modifyJob = (job: JobResponse | undefined) => {
    if (job) {
      setShowModifyJobForm && setShowModifyJobForm(true);
      setJobToModify && setJobToModify(job);
    }
  };
  const [refreshJob, loading, getNumberOfVersionsUpdated] = useOnJobRefresh();

  const getLockIcon = (classOverrides: string) =>
    job?.policy === 'private' && (
      <FeatureFlag name="private-job">
        <LockIcon classOverrides={[classOverrides]} height="20" />
      </FeatureFlag>
    );

  const successToaster = (
    <Trans i18nKey={t('toast.jobRefreshed')}>
      Job has been successfully refreshed!
      <div
        className="text-primary-default underline font-bold"
        onClick={() => {
          setShowVersionsReport && setShowVersionsReport(true);
        }}
      >
        Take a look at the report!
      </div>
    </Trans>
  );

  const noNewVersionToaster = (
    <Trans i18nKey={t('toast.jobRefreshedWithNoVersions')}>
      We haven't found any new versions
      <div
        className="text-primary-default underline font-bold"
        onClick={() => {
          setShowVersionsReport && setShowVersionsReport(true);
        }}
      >
        Take a look at the report!
      </div>
    </Trans>
  );

  const onRefreshJob = () => {
    if (!job) return;
    refreshJob(job.ownerName, job.name).then((response) => {
      setOpenActions(false);
      /**
       * Find job in current jobs list and replace it with refreshed one when id matches
       *
       * Caveat: since we are using the mocked URL and the id from the job returned is always
       * the same no matter the job we try to refresh, the list won't be updated.
       * Hardcode response.job.id to the same value as jobInList.id to see how it works
       */
      if (response && setJobsToDisplay) {
        const jobsInList = jobsToDisplay.map((jobInList) =>
          jobInList.id === response.job.id ? response.job : jobInList,
        );
        setJobAnalysisToDisplay && setJobAnalysisToDisplay(response);
        setNumberOfVersionsUpdated &&
          setNumberOfVersionsUpdated(
            getNumberOfVersionsUpdated(response.analysis, job.name),
          );
        setJobsToDisplay(jobsInList);
        if (getNumberOfVersionsUpdated(response.analysis, job.name) > 0) {
          toastSuccess(successToaster);
        } else {
          toastWarning(noNewVersionToaster);
        }
      }
    });
  };
  const ActionItem: React.FC<{ action: string; index: number }> = ({
    action,
    index,
  }) => {
    let item;
    if (job) {
      switch (index) {
        case 0:
          /**
           * Modify your job
           */
          item = (
            <button
              className={cn(
                'flex justify-between',
                loading && 'cursor-default',
              )}
              disabled={loading}
              onClick={() => modifyJob(job)}
            >
              <span className="w-min ">
                <FontAwesomeIcon icon={faEdit} className="text-lg" />
              </span>
              <span className="hidden md:block sm:ml-2">{action}</span>
            </button>
          );
          break;
        case 1:
          /**
           * See your job
           */
          item = (
            <TrackedLink
              name={`see his job details from job submenu`}
              to={`/_/${job.ownerName}/${job.name}`}
              className={cn(
                'flex justify-between',
                loading && 'pointer-events-none',
              )}
            >
              <span className="w-min">
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  className="text-lg mr-0.5"
                />
              </span>
              <span className="hidden md:block sm:ml-2">{action}</span>
            </TrackedLink>
          );
          break;
        case 2:
          /**
           * Refresh my job
           */
          item = (
            <button
              className={cn(
                'flex justify-between',
                loading && 'cursor-default',
              )}
              disabled={loading}
              onClick={onRefreshJob}
            >
              <span className="w-min ">
                <RefreshIcon />
              </span>
              <span className="hidden md:block sm:ml-2">{action}</span>
            </button>
          );
          break;
        default:
          item = <></>;
          break;
      }
    }
    return <div className="flex items-center">{item}</div>;
  };

  const evenRow = index && index % 2 === 0;
  const jobVersion = job ? returnLatestVersion(job.versions) : undefined;
  const baseColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.backgroundColor
      : undefined;
  const highlightColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.color
      : undefined;
  return (
    <div className="relative" ref={ref} data-testid={'my-job-card-' + index}>
      <div
        className={cn(
          evenRow || index === 0
            ? 'bg-secondary-semi-light dark:bg-secondary-default'
            : 'bg-white dark:bg-transparent',
          index === 0 && 'rounded-t-2lg',
          isLastRow && 'rounded-b-2lg',
          'relative px-3 sm:pl-10 py-2 flex items-center justify-between sm:rounded-2lg',
        )}
      >
        <div className="flex items-center w-full">
          <div
            className={cn(
              'rounded-full h-9 w-9 relative mr-2 sm:mr-6',
              evenRow || index === 0 ? 'bg-white' : 'bg-secondary-semi-light',
            )}
          >
            {job ? (
              <div className="text-xl absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <span data-testid="job-icon">
                  {job?.icon ?? String.fromCodePoint(0x1f3f7)}
                </span>
              </div>
            ) : (
              <Skeleton
                circle
                baseColor={baseColor}
                highlightColor={highlightColor}
                containerTestId="job-icon-skeleton"
                height="100%"
                width="100%"
                containerClassName="avatar-skeleton relative -top-1 sm:mr-4"
              />
            )}
          </div>
          <div className="sm:flex items-center sm:w-full  flex-wrap">
            <span className="text-secondary-default inline-block md:flex md:flex-row items-baseline w-full sm:w-80 lg:w-88 xl:w-8/12">
              <TrackedLink
                name={`user job row`}
                to={`/dashboard/_/${job?.ownerName}/${job?.name}`}
              >
                <div className="max-w-10 md:max-w-18 lg:max-w-16 xl:max-w-20 2xl:max-w-md truncate">
                  <Typography
                    action={() => modifyJob(job)}
                    classOverrides={[
                      'hover:opacity-70 transition-opacity pr-1 dark:text-white',
                    ]}
                    color="text-secondary-default"
                    bold
                    size="lg"
                    title={job?.name}
                  >
                    {job?.name ? (
                      job.name
                    ) : (
                      <Skeleton
                        containerTestId="job-name-skeleton"
                        width={200}
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                      />
                    )}
                  </Typography>
                </div>
              </TrackedLink>
              <span className="hidden text-xs md:text-sm md:block pl-1 font-comfortaa text-gray-secondary dark:text-gray-input">
                {jobVersion ? (
                  jobVersion.tag
                ) : (
                  <Skeleton
                    containerClassName="w-full"
                    containerTestId="job-version-skeleton"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                )}
              </span>
            </span>
            <span
              className={cn(
                'flex items-baseline text-xs-leading-3 md:text-base text-secondary-dark dark:text-gray-primary divide-secondary-dark divide-opacity-60 md:divide-x-0',
                job && 'divide-x-2',
              )}
            >
              <span className="md:hidden min-w-2.4 pr-1 md:pr-2 font-comfortaa text-gray-secondary dark:text-gray-input">
                {jobVersion ? (
                  jobVersion.tag
                ) : (
                  <Skeleton
                    containerClassName="w-full"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                )}
              </span>
              <div className="pl-1 md:pl-2 flex items-center w-full">
                {/* Times used */}
                <span className="lg:min-w-3.2 text-right">
                  {job ? (
                    getJobUsageCounter(job)
                  ) : (
                    <Skeleton
                      containerClassName="w-full"
                      containerTestId="job-usages-skeleton"
                      baseColor={baseColor}
                      highlightColor={highlightColor}
                    />
                  )}
                </span>
                {job && (
                  <Tooltip
                    title={t('my_jobs.job_row_download_description')}
                    colorSet={
                      theme === 'light' ? ColorSetEnum.gray : ColorSetEnum.dark
                    }
                  >
                    {' '}
                    <DownloadIcon
                      classOverrides={[
                        'h-4 w-4 md:h-5 md:w-5 ml-2 md:mx-3 text-secondary-default dark:text-gray-primary',
                      ]}
                    />
                  </Tooltip>
                )}
                <span>
                  {getLockIcon(
                    'hidden sm:block text-primary-default dark:text-white absolute -left-1 top-4',
                  )}
                </span>
                {typeof job?.rateUp !== 'undefined' &&
                typeof job?.rateDown !== 'undefined' ? (
                  <JobRating
                    classOverrides={[
                      'hidden text-secondary-default dark:text-gray-primary lg:block text-base ml-1',
                    ]}
                    rateUp={job.rateUp}
                    rateDown={job.rateDown}
                  />
                ) : (
                  <Skeleton
                    count={2}
                    className="mr-1"
                    inline
                    width={40}
                    containerTestId="job-ratings-skeleton"
                    containerClassName="hidden ml-6 justify-self-end sm:block"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
        <div className="pt-1 sm:static ">
          {job ? (
            <div className="h-5 md:h-full">
              {!loading ? (
                <button
                  data-testid="kebabButton"
                  onClick={() => setOpenActions(!openActions)}
                  title={t('my_jobs.job_row_kebab_title')}
                >
                  <KebabIcon
                    classOverrides={[
                      cn(
                        'h-full top-7 sm:top-4 md:top-4 right-2 h-5 md:h-6 cursor-pointer hover:opacity-70 transition',
                        openActions
                          ? 'text-primary-default'
                          : 'text-secondary-default dark:text-gray-primary',
                      ),
                    ]}
                  />
                </button>
              ) : (
                <SpinnerIcon
                  classOverrides={['h-4']}
                  color="text-secondary-default"
                />
              )}
            </div>
          ) : (
            <div className="relative right-1.5 -top-3 sm:-top-1">
              <Skeleton
                width={10}
                height={30}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
            </div>
          )}
        </div>
        {getLockIcon(
          'absolute top-6 right-5 sm:hidden text-primary-default dark:text-white',
        )}
      </div>
      <ActionBox
        bgColor={'bg-primary-default'}
        classOverrides={[
          'absolute -right-3 sm:-right-0 lg:-right-44 top-10 sm:top-14 lg:top-0',
          ' text-white font-comfortaa text-sm z-10',
        ]}
        isOpen={openActions}
      >
        {actions.map((action, index) => {
          return (
            <div
              key={index}
              className={cn(
                'flex items-center justify-start hover:opacity-70 transition',
                index === actions.length - 1 ? '' : 'mb-3',
                loading && 'opacity-70',
              )}
            >
              <ActionItem index={index} action={action} />
            </div>
          );
        })}
      </ActionBox>
    </div>
  );
};
