import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { useGitLab } from 'gitlab/hooks/useGitLab.hook';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  repoUrl: string;
  statsClassOverrides?: string[];
  repoClassOverrides?: string[];
};

export const GitLabCounter: React.FC<
  PropsWithChildren<Props & ClassOverrides>
> = ({
  classOverrides = [],
  statsClassOverrides = [],
  repoClassOverrides = [],
  children,
  repoUrl,
}) => {
  const { t } = useTranslation();
  const formatedRepoUrl = new URL(repoUrl).pathname.replace('/', '');
  const [repo, loading] = useGitLab(encodeURIComponent(formatedRepoUrl));
  useEffect(() => {
    return () => {};
  });
  return (
    <OutsideLink
      name={'Hub repository'}
      to={repoUrl}
      className={cn(
        'justify-center flex items-center hover:opacity-70',

        ...classOverrides,
      )}
      rel="noreferrer"
    >
      {children}
      <div className="flex-col flex items-center max-w-12">
        <div
          className={cn(
            'underline font-semibold text-base text-center font-comfortaa',
            ...repoClassOverrides,
          )}
        >
          {formatedRepoUrl}
        </div>

        {typeof repo === 'object' && !loading && (
          <div
            className={cn(
              'text-xs flex w-full mt-0.5 justify-center divide-x-4 divide-transparent font-comfortaa',
              ...statsClassOverrides,
            )}
          >
            {typeof repo?.star_count === 'number' && (
              <>
                <div className="flex items-center">{repo?.star_count}</div>
                <div>{t('job.details_tab.stars')}</div>
              </>
            )}
            <div>-</div>
            {typeof repo?.forks_count === 'number' && (
              <>
                <div className="flex items-center">{repo?.forks_count}</div>
                <div>{t('job.details_tab.forks')}</div>
              </>
            )}
          </div>
        )}
      </div>
    </OutsideLink>
  );
};
