/**
 * This class is an abstract mapper used in the HTTPService
 * so every component using a httpCall gets the final entity
 * which is mapped properly.
 *
 * @typeParam S - The target frontend entity
 * @typeParam T - The source entity coming from API
 */
export abstract class Mapper<S, T> {
  /**
   * This method returns a promise as in some case
   * we might want to fetch some additional informations
   * @param <T> entity - Source data
   */
  abstract into(entity: T): Promise<S> | S;
}
