import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import mixpanel from 'mixpanel-browser';
import React from 'react';

type Props = ClassOverrides & {
  content: string;
};

export const Mixpanel: React.FC<Props> = ({
  content = '',
  children,
  classOverrides = [],
}) => {
  const track = () => {
    mixpanel.track(content);
  };
  return (
    <div onClick={track} className={cn(...classOverrides)}>
      {children}
    </div>
  );
};
