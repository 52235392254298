import React, { useContext } from 'react';
import { RouteProps } from 'react-router';
import { RouteConditions } from '@typings/RouteConditions.type';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../../user/contexts/user.context';
import { checkRoute } from '@components/FeatureFlag/FeatureFlag.component';
import HttpError from 'standard-http-error';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';

type Props = Omit<RouteProps, 'component'> & {
  /**
   * Redefine type of component for props & typing
   */
  component: React.FC;
  /**
   * Route path
   */
  path: string;
  /**
   * Route where the user is redirect if there condition isn't fullfilled
   */
  return_to: string;
  conditions: RouteConditions;
};

/**
 * This component is used to protect a route, in this component
 * a route has a condition which leads the user into another route
 * @param {RouteConditions} conditions -- Condition to which the user needs to fill
 * @param {string} return_to -- The path where to the user is redirected
 * @param {JSX.Element} component -- Rendered component
 * @param rest
 * @constructor
 */
export const ProtectedRoute: React.FC<Props> = ({
  conditions,
  return_to,
  component,
  path,
  ...rest
}) => {
  const user = useContext(UserContext);
  let conditionApproved = true;
  if (conditions.isAuthenticated && !user.identity) conditionApproved = false;
  else if (!conditions.isAuthenticated && user.identity) {
    conditionApproved = false;
  }

  const redirectingComponent = (props: any) => {
    if (!checkRoute(path))
      throw new HttpError(404, getReasonPhrase(StatusCodes.NOT_FOUND));
    if (conditionApproved) {
      return React.createElement(component, props);
    } else {
      return <Redirect to={{ pathname: return_to }} />;
    }
  };
  return <Route {...rest} render={redirectingComponent} />;
};
