import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { Typography } from '@components/Typography/Typography.component';
import { Admonitions } from '@components/Admonitions/Admonitions.component';
import {
  removeAdmonitionsSpeChar,
  removeBrTag,
  removeTargetBlank,
} from '@utils/markdown.util';

type Props = ClassOverrides & {
  children: React.ReactNode & React.ReactNode[];
};

const MarkdownParagraph: React.FC<Props> = ({ children }) => {
  if (!children) return <></>;
  const newChildren = children.map((child, key) => {
    if (`${child}`.includes('{:target="_blank"}')) {
      return `${child}`.replace('{:target="_blank"}', '');
    }
    if (`${child}`.startsWith('^^') || `${child}`.endsWith('^^')) {
      child = (
        <div className="underline" key={key}>
          {`${child}`.replace(/(\^\^)/g, '')}
        </div>
      );
    }
    return child;
  });
  if (`${children}`.includes('!!!') || `${children}`.includes('???')) {
    let titleResult: RegExpMatchArray | null = [];
    let title = '';
    let admonition = '';
    let cleanChildren = removeTargetBlank(children);
    if (`${children}`.includes('!!!')) {
      const admonitionType = children[0]?.toString().match(/^!!! [a-zA-Z]+/);
      admonition = `${admonitionType}`.replace('!!! ', '');
    }
    if (`${children}`.includes('???')) {
      const admonitionType = children[0]
        ?.toString()
        .match(/^(?:\?\?\?) [a-zA-Z]+/);
      admonition = `${admonitionType}`.replace('??? ', '');
    }

    if (`${children}`.match(/^!!! [a-zA-Z]+ ("+...+")/)) {
      titleResult = `${children}`.match(/^!!! [a-zA-Z]+ ("+...+")/);
      if (titleResult) {
        title = titleResult[1];
      }
    }

    if (`${children}`.match(/^(?:\?\?\?) [a-zA-Z]+ ("+...+")/)) {
      titleResult = `${children}`.match(/^(?:\?\?\?) [a-zA-Z]+ ("+...+")/);
      if (titleResult) {
        title = titleResult[1];
      }
    }
    cleanChildren = removeBrTag(cleanChildren);

    const newChildren = removeAdmonitionsSpeChar(
      cleanChildren,
      admonition,
      title,
    );
    return (
      <Admonitions
        type={admonition}
        title={title ? title.replace(/"/g, '') : undefined}
        content={`${children}`}
      >
        {newChildren}
      </Admonitions>
    );
  }
  return (
    <>
      <Typography
        color="text-gray-secondary"
        classOverrides={['dark:text-gray-primary']}
      >
        {newChildren}
      </Typography>
      <br />
      <br />
    </>
  );
};

export { MarkdownParagraph };
