import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './common/styles/index.scss';
import { HelmetProvider } from 'react-helmet-async';
import { IndexRouter } from './pages/index.router';
import {
  HttpContext,
  HttpServiceInstance,
} from './common/contexts/http.context';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './common/config/i18n';
import { ToasterHandler } from './common/components/ToasterHandler/ToasterHandler.component';
import { LoadingPage } from './pages/Loading.page';
import { AuthProvider } from './user/components/AuthProvider/AuthProvider.component';
import { AnimatePresence } from 'framer-motion';
import { QueryClient, QueryClientProvider } from 'react-query';

/**
 * Suspense are used as i18n is loaded asynchronously
 */
const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <HttpContext.Provider value={HttpServiceInstance}>
        <QueryClientProvider client={queryClient}>
          <AnimatePresence>
            <Suspense fallback={<LoadingPage key={'loading'} />}>
              <AuthProvider>
                <IndexRouter />
              </AuthProvider>
              <ToasterHandler />
            </Suspense>
          </AnimatePresence>
        </QueryClientProvider>
      </HttpContext.Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
