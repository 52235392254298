import React, { useContext, useState } from 'react';
import { ArrowIcon } from '@components/Icons/ArrowIcon/ArrowIcon';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import Editor from '@monaco-editor/react';
import { cn } from '@utils/classes.util';
import { Tooltip } from '@components/Tooltip/Tooltip.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorSetEnum } from 'common/constants/getColorSet';
import ReactTooltip from 'react-tooltip';
import mixpanel from '@utils/mixpanel.util';
import Plausible from 'plausible-tracker';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import {
  PipelineCustomization,
  useProjectCustomization,
} from 'user/hooks/useProjectCustomization.hook';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { LicenseProject } from 'common/hooks/useLicense.hook';
import { TriggerPipelineModal } from '../TriggerPipelineModal/TriggerPipelineModal.component';

type Props = {
  returnAction?: () => void;
  returnContent?: string;
};

export const ModifyProject: React.FC<{ project: LicenseProject } & Props> = ({
  project,
  returnAction,
  returnContent = 'action:go_back_to_dashboard',
}) => {
  const {
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const { t } = useTranslation();
  const { trackEvent } = Plausible();
  const [showTriggeringModal, setShowTriggeringModal] = useState(false);
  const [addCustomization, loading] = useProjectCustomization();
  const [editorValues, setEditorValues] = useState<string | undefined>(
    project?.customs,
  );
  const copyCustomizationCode = (editorValues: string) => {
    if (!editorValues) return;
    navigator.clipboard.writeText(editorValues);
    mixpanel.track(`[Copy] Content of the pipeline customization`);
    trackEvent('Copy the pipeline customization');
  };

  const CustomizePipeline = (withPush: boolean, destBranch?: string): void => {
    if (!project) return;
    localStorage.setItem('project', project.id.toString());
    const PipelineCustomization: PipelineCustomization = {
      projectID: project.id.toString(),
      customs: editorValues ? editorValues : '',
      push: withPush,
      destBranch: destBranch ? destBranch : undefined,
    };
    addCustomization(PipelineCustomization, project);
  };
  const { theme } = useContext(ThemeContext);

  function handleEditorChange(value: string | undefined) {
    if (value !== project?.customs) {
      setEditorValues(value ? value : '');
    }
  }

  return (
    <div className="text-center">
      <Title bold color="text-secondary-default" font="ubuntu" size="h1">
        {project.projectUrl &&
          new URL(project.projectUrl).pathname.replace('/', '')}
      </Title>
      <>
        <TriggerPipelineModal
          triggerFunction={CustomizePipeline}
          showModal={showTriggeringModal}
          setShowModal={setShowTriggeringModal}
          loading={loading}
        />
        <div className="relative flex items-center justify-center text-gray-secondary font-comfortaa mt-6 lg:mt-0 mb-8">
          <TrackedLink
            name={`go back to project dashboard`}
            to="/dashboard/projects"
            className="flex w-56 xl:w-auto items-center absolute left-0 cursor-pointer hover:opacity-70 transition-opacity dark:text-gray-primary"
          >
            <ArrowIcon
              classOverrides={['transform rotate-180 mr-4 w-8 h-8']}
              strokeWidth="1.5"
              height="45"
              width="55"
            />
            <span className="">{t(returnContent)}</span>
          </TrackedLink>
          <div className="w-24 sm:w-max flex justify-center items-center  flex-col-reverse md:flex-row"></div>
        </div>
        <form
          className={cn(
            'flex flex-col items-center justify-center rounded-lg border-gray-primary dark:border-secondary-default p-2 bg-gray-primary dark:bg-secondary-default border-2 shadow-lg w-full',
            theme === 'light' ? '' : 'border-brown-primary bg-brown-primary',
          )}
        >
          <div
            className={cn(
              'w-full flex 2xl:flex-row items-center justify-center flex-col  relative ',
            )}
          >
            {project && project.pipeline && (
              <div className="flex flex-col w-full 2xl:w-2/4">
                <div className="text-center mb-2">
                  <Title
                    bold
                    color="text-secondary-default"
                    font="ubuntu"
                    size="h2"
                  >
                    {t('my_projects.modify.sub_title.0')}
                  </Title>
                </div>
                <div className="bg-brown-primary p-2 2xl:rounded-l-lg 2xl:rounded-none rounded-lg relative">
                  <Tooltip
                    afterShow={() => {
                      copyCustomizationCode(project.pipeline);
                      setTimeout(ReactTooltip.hide, 2000);
                    }}
                    title={t('action:copied')}
                    click={true}
                    classOverrides={['absolute h-6 w-6 top-3 right-4 ']}
                    colorSet={ColorSetEnum.success}
                  >
                    <div
                      className={
                        'text-xl ' +
                        'text-gray-primary hover:opacity-70' +
                        'transition-all duration-300 cursor-pointer right-0 -mt-1 -mr-1'
                      }
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                  </Tooltip>
                  <Editor
                    defaultLanguage="yaml"
                    height="55vh"
                    width="100%"
                    language="yaml"
                    options={{ readOnly: true }}
                    theme={'vs-dark'}
                    keepCurrentModel
                    value={project.pipeline}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col w-full 2xl:w-2/4">
              <div className="text-center mb-2">
                <Title
                  bold
                  color="text-secondary-default"
                  font="ubuntu"
                  size="h2"
                >
                  {t('my_projects.modify.sub_title.1')}
                </Title>
              </div>
              <div className="bg-brown-primary p-2 2xl:rounded-r-lg 2xl:rounded-none rounded-lg relative">
                <Tooltip
                  afterShow={() => {
                    copyCustomizationCode(editorValues ? editorValues : '');
                    setTimeout(ReactTooltip.hide, 2000);
                  }}
                  title={t('action:copied')}
                  click={true}
                  classOverrides={['absolute h-6 w-6 top-3 right-4 ']}
                  colorSet={ColorSetEnum.success}
                >
                  <div
                    className={
                      'text-xl ' +
                      'text-gray-primary hover:opacity-70' +
                      'transition-all duration-300 cursor-pointer right-0 -mt-1 -mr-1'
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </div>
                </Tooltip>
                <Editor
                  defaultLanguage="yaml"
                  height="55vh"
                  width="100%"
                  className=""
                  theme={'vs-dark'}
                  onChange={handleEditorChange}
                  value={project ? project.customs : ''}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <SecondaryButton
              classOverrides={[
                'text-sm xl:text-base dark:bg-dark-primary w-max px-6 h-12 mr-4',
              ]}
              bgColor="bg-secondary-default"
              color="text-white"
              content={t('action:save')}
              font="comfortaa"
              disabled={isSubmitting || loading}
              bold
              action={() => {
                CustomizePipeline(false);
              }}
            />
            <SecondaryButton
              classOverrides={[
                'text-sm xl:text-base dark:bg-dark-primary w-max px-6 h-12',
              ]}
              content={t('action:save_trigger')}
              bgColor="bg-secondary-default"
              color="text-white"
              bold
              font="comfortaa"
              action={() => {
                setShowTriggeringModal(true);
              }}
            />
          </div>
        </form>
      </>
    </div>
  );
};
