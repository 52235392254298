import React from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { ThemeContext } from 'common/contexts/theme.context';

export const HeaderBottom: React.FC<ClassOverrides> = ({
  classOverrides = [],
}) => {
  const { theme } = React.useContext(ThemeContext);
  const svg = (
    <svg
      className="w-full h-12"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      {theme === 'dark' ? (
        <polygon fill="#02191E" points="0,102 100,0 100,102" />
      ) : (
        <polygon fill="white" points="0,102 100,0 100,102" />
      )}
    </svg>
  );

  if (classOverrides.length > 0)
    return <div className={cn(...classOverrides)}>{svg}</div>;
  else return svg;
};
