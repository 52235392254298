import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Title } from '@components/Titles/Title.component';
import R2_bookCry from '@images/R2/R2_book_cry.svg';
import { JobVersion } from '@jobs/models/JobVersion.model';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { removeTargetBlank } from '@utils/markdown.util';
import { fadeInTopVariant } from '@utils/motion.util';
import {
  CONTRIBUTE_JOB_DOCUMENTATION,
  DOCUMENTATION_LINK,
} from 'common/constants/general';
import { useJobMarkdown } from 'common/hooks/useJobMarkdown.hook';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { MarkdownCode } from './MarkdownCode.component';
import { MarkdownParagraph } from './MarkdownParagraph.component';

type Props = ClassOverrides & {
  version: JobVersion;
};

const DocTab: React.FC<Props> = ({ classOverrides = [], version }) => {
  const [markdown, setMarkdown] = useState('');
  const [markdownRequest, loading, noDocFile] = useJobMarkdown(
    version.readmeUrl,
  );
  const { t } = useTranslation();
  useEffect(() => {
    if (!markdownRequest) return;
    setMarkdown(markdownRequest);
  }, [markdownRequest]);
  const sanitizeSchema = {
    attributes: {
      '*': ['className', 'class', 'placeholder'],
      a: ['className', 'class', 'href'],
    },
  };
  return (
    <div
      data-testid={'details-tab'}
      className={cn('lg:mr-8 w-full ', ...classOverrides)}
    >
      {!noDocFile && !loading ? (
        <ReactMarkdown
          skipHtml={false}
          remarkPlugins={[[remarkGfm, { tablePipeAlign: true }]]}
          rehypePlugins={[[rehypeSanitize, sanitizeSchema]]}
          transformImageUri={(uri) => (uri.startsWith('![') ? uri : '')}
          components={{
            p: ({ node, children }) => {
              return <MarkdownParagraph children={children && children} />;
            },
            li: ({ node, children }) => {
              const newChildren = removeTargetBlank(children);
              return (
                <li className="list-decimal list-inside text-gray-secondary dark:text-gray-primary mb-2">
                  {newChildren}
                </li>
              );
            },
            strong: ({ node, children }) => {
              const newChildren = removeTargetBlank(children);
              return <strong>{newChildren}</strong>;
            },
            a: ({ node, children }) => {
              return (
                <a
                  href={
                    `${node.properties?.href}`.startsWith('/')
                      ? `${DOCUMENTATION_LINK + node.properties?.href}`
                      : `${node.properties?.href}`
                  }
                  className="text-primary-default hover:text-secondary-default dark:text-primary-default dark:hover:text-secondary-default"
                  rel="noreferrer"
                >
                  {children}
                </a>
              );
            },
            h2: ({ children }) => {
              return (
                <div
                  id={children[0]
                    ?.toString()
                    .toLowerCase()
                    .replaceAll(' ', '-')}
                >
                  <Title
                    bold
                    color={'text-primary-default'}
                    size={'h2'}
                    classOverrides={['py-4 dark:text-white']}
                  >
                    {children}
                  </Title>
                </div>
              );
            },
            h3: ({ children }) => {
              return (
                <div
                  id={children[0]
                    ?.toString()
                    .toLowerCase()
                    .replaceAll(' ', '-')}
                >
                  <Title
                    bold
                    color={'text-gray-secondary'}
                    size={'h3'}
                    classOverrides={['py-4 dark:text-white']}
                  >
                    {children}
                  </Title>
                </div>
              );
            },
            h4: ({ children }) => {
              return (
                <div
                  id={children[0]
                    ?.toString()
                    .toLowerCase()
                    .replaceAll(' ', '-')}
                >
                  <Title
                    bold
                    color={'text-gray-secondary'}
                    size={'h3'}
                    classOverrides={['py-4 ml-6 dark:text-white']}
                  >
                    {children}
                  </Title>
                </div>
              );
            },
            table: ({ node, children }) => {
              return (
                <table className="border-gray-secondary dark:border-secondary-default ay- mb-4 table-fixed w-full">
                  {children}
                </table>
              );
            },
            th: ({ children }) => {
              return (
                <th className="table-cell border-2 h-14 text-gray-primary bg-gray-secondary dark:bg-secondary-default-high-opacity dark:border-secondary-default ">
                  {children}
                </th>
              );
            },
            td: ({ node, children }) => {
              const cleanChildren = removeTargetBlank(children);
              if (!children) {
                return (
                  <td className="text-gray-secondary dark:text-gray-primary border-2 dark:border-secondary-default  h-28 break-words text-center table-cell px-2"></td>
                );
              }
              const newChildren = cleanChildren.filter((value) => {
                if (`${value}`.includes('<img')) {
                  return null;
                }
                return value;
              });
              return (
                <td className="text-gray-secondary dark:text-gray-primary  border-2 dark:border-secondary-default  h-28 table-cell text-center  ">
                  <div className="w-full h-full overflow-auto px-2 flex items-center justify-center">
                    <div className="w-full max-h-full">{newChildren}</div>
                  </div>
                </td>
              );
            },
            code({ inline, className, children, ...props }) {
              return (
                <MarkdownCode
                  children={children && children}
                  inline={inline ? inline : false}
                  className={className ? className : ''}
                />
              );
            },
          }}
        >
          {markdown}
        </ReactMarkdown>
      ) : loading ? (
        <SpinnerIcon classOverrides={['mt-6']} />
      ) : (
        <div>
          <Title
            bold
            classOverrides={['text-center md:text-left mb-6']}
            color="text-primary-default"
            size="h2"
          >
            {t('job.doc_tab.no_doc_found.title')}
          </Title>
          <div className="text-center md:text-left font-comfortaa text-lg text-secondary-dark dark:text-gray-primary">
            <p className="mb-2">
              {t('job.doc_tab.no_doc_found.instructions.0')}
            </p>
            <p>
              {t('job.doc_tab.no_doc_found.instructions.1')}
              <OutsideLink
                name="Job documentation"
                className="text-primary-default hover:opacity-70"
                to={CONTRIBUTE_JOB_DOCUMENTATION}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('job.doc_tab.no_doc_found.instructions.2')}
              </OutsideLink>
              {t('job.doc_tab.no_doc_found.instructions.3')}
            </p>
          </div>
          <div className="flex justify-center w-full">
            <motion.img
              variants={fadeInTopVariant()}
              src={R2_bookCry}
              alt={t('job.doc_tab.r2_img_alt')}
              title={t('job.doc_tab.r2_img_alt')}
              className=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { DocTab };
