import React from 'react';
import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';

type Props = ClassOverrides &
  Sizing & {
    action?: () => void;
    color?: TextColor;
    dataTestId?: string;
    strokeWidth?: string;
  };

export const CloseIcon: React.FC<Props> = ({
  action,
  dataTestId,
  color,
  height = '23',
  width = '24',
  classOverrides = [],
  strokeWidth = '3',
}: Props) => {
  const onClickButton = () => {
    if (action) action();
  };

  return (
    <svg
      data-testid={dataTestId}
      onClick={onClickButton}
      width={height}
      height={width}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <path
        d="M0.707107 1.29289L21.9203 22.5061"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M21.9205 1.70711L0.707283 22.9203"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
