import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const DownloadIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '20',
  width = '20',
}) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(color, ...classOverrides)}
    >
      <g clipPath="url(#clip0_671:645)">
        <path
          d="M17.5 20H2.5C1.12167 20 0 18.8783 0 17.5V14.1666C0 13.7066 0.373333 13.3333 0.833333 13.3333C1.29333 13.3333 1.66667 13.7066 1.66667 14.1666V17.5C1.66667 17.9592 2.04083 18.3333 2.5 18.3333H17.5C17.9592 18.3333 18.3333 17.9592 18.3333 17.5V14.1666C18.3333 13.7066 18.7067 13.3333 19.1667 13.3333C19.6267 13.3333 20 13.7066 20 14.1666V17.5C20 18.8783 18.8783 20 17.5 20Z"
          fill="currentColor"
        />
        <path
          d="M13.9213 10.5892L10.588 13.9225C10.2622 14.2483 9.73552 14.2483 9.40969 13.9225L6.07636 10.5892C5.83802 10.3508 5.76636 9.9925 5.89552 9.68083C6.02469 9.37 6.32885 9.16667 6.66552 9.16667H9.16552V0.833333C9.16552 0.373333 9.53885 0 9.99885 0C10.4589 0 10.8322 0.373333 10.8322 0.833333V9.16667H13.3322C13.6688 9.16667 13.973 9.37 14.1022 9.68083C14.2313 9.9925 14.1597 10.3508 13.9213 10.5892Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_671:645">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
