import React from 'react';
import { cn } from '@utils/classes.util';
import { ButtonProps } from '@typings/ButtonProps.type';
import { HTMLStyling } from '@typings/ClassOverrides.type';
import mixpanel from '@utils/mixpanel.util';

type Props = HTMLStyling & ButtonProps;

/**
 * When using either children and content properties
 * the component will output both. Content comes first.
 * @param {string} content
 * @param {function} action
 * @param {Array<string>} classOverrides
 * @param {React.FC} children
 *
 * @see HTMLStyling
 * @see ButtonProps
 */
const PrimaryButton: React.FC<Props> = ({
  content,
  action,
  children,
  classOverrides = [],
  bgColor = 'bg-primary-default',
  color = 'text-white',
  bold = true,
  light = false,
  margin = 'm-8',
  uppercase = false,
  font = 'ubuntu',
  submitButton = false,
  disabled = false,
  hoverBgColor = '',
}) => {
  const onClickButton = () => {
    if (!action) return;
    action();
    mixpanel.track(`[Button] Click on ${content}`, {
      buttonType: 'primaryButton',
    });
  };

  return (
    <button
      onClick={onClickButton}
      className={cn(
        !/.*py-.*/.test(classOverrides[0] as string) && 'py-3',
        !/.*px-.*/.test(classOverrides[0] as string) && 'px-4',
        'rounded-2lg hover:shadow-button-inner transition duration-500 ' +
          'filter shadow-lg drop-shadow-button-outer ',
        disabled ? 'bg-gray-secondary' : bgColor,
        color,
        `font-${font}`,
        bold && 'font-bold',
        light && 'font-light',
        margin,
        uppercase && 'uppercase',
        hoverBgColor,
        ...classOverrides,
      )}
      data-testid={`primarybutton-${content}`}
      type={submitButton ? 'submit' : 'button'}
      disabled={disabled}
      aria-label={content}
    >
      {content}
      {children}
    </button>
  );
};

export { PrimaryButton };
