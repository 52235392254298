import { GitHubRepository } from 'common/hooks/useGitHubProjects.hook';
import { GitLabProjectResponse } from 'common/models/GitLabProject.model';

export const removeEmptyFields = (data: any): any => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export type RepositoriesPlatforms = GitHubRepository | GitLabProjectResponse;

export const determineIfIsGitLabOrGitHub = (
  toBeDetermined: RepositoriesPlatforms,
): toBeDetermined is GitLabProjectResponse => {
  if ((toBeDetermined as GitLabProjectResponse).web_url) {
    return true;
  }
  return false;
};
