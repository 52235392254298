import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag.component';
import { LockIcon } from '@components/Icons/LockIcon/LockIcon.component';
import { JobDescription } from '@components/JobDescription/JobDescription';
import { Typography } from '@components/Typography/Typography.component';
import { JobResponse } from '@jobs/models/JobCreationResponse.model';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import { returnLatestVersion } from '@utils/jobs.util';
import { ColorSetEnum, getColorSet } from 'common/constants/getColorSet';
import { ThemeContext } from 'common/contexts/theme.context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { CardLabelSlider } from '../CardLabelSlider/CardLabelSlider.component';

type Props = ClassOverrides & {
  job?: JobResponse;
  index: number;
};

export const JobsExplorerRow: React.FC<Props> = ({ job, index }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const baseColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.backgroundColor
      : undefined;
  const highlightColor =
    theme === 'dark'
      ? getColorSet(ColorSetEnum.darkSkeleton)?.color
      : undefined;

  const evenRow = index && index % 2 === 0;
  const jobVersion = job ? returnLatestVersion(job.versions) : undefined;
  return (
    <div className="relative" data-testid={'my-job-row-' + index}>
      <div
        className={cn(
          evenRow || index === 0
            ? 'bg-secondary-semi-light dark:bg-secondary-default-high-opacity'
            : 'bg-white dark:bg-transparent',
          index === 0 && 'rounded-t-2lg',
          'relative px-3 sm:px-5 2xl:px-10 py-2  sm:rounded-2lg hover:opacity-70',
        )}
      >
        <div className=" items-center w-full block 2xl:flex">
          <div className="flex-col flex items-center md:flex-row">
            <div
              className={cn(
                'rounded-full h-9 w-9 relative -mb-9 2xl:-mb-0',
                evenRow || index === 0 ? 'bg-white' : 'bg-secondary-semi-light',
              )}
            >
              {job ? (
                <div className="text-xl absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <span data-testid="job-icon">
                    {job?.icon ?? String.fromCodePoint(0x1f3f7)}
                  </span>
                </div>
              ) : (
                <Skeleton
                  circle
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  containerTestId="job-icon-skeleton"
                  height="100%"
                  width="100%"
                  containerClassName="avatar-skeleton relative -top-1 sm:mr-4"
                />
              )}
            </div>
            <span className="text-secondary-default 2xl:flex-col flex flex-row items-center md:items-baseline w-max xl:w-64">
              <div className="flex ">
                <div className="2xl:max-w-12 truncate text-ellipsis">
                  <Typography
                    classOverrides={[
                      ' transition-opacity ml-2 mr-1   dark:text-white',
                    ]}
                    color="text-secondary-default"
                    bold
                    size="md"
                    title={job?.name}
                  >
                    {job?.name ? (
                      job.name
                    ) : (
                      <Skeleton
                        containerTestId="job-name-skeleton"
                        width={200}
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                      />
                    )}
                  </Typography>
                </div>
                <div className="font-bold mr-1">|</div>
                <div className="text-xs mr-2 2xl:mr-0  flex items-center w-max dark:text-gray-primary">
                  {job?.ownerName ? (
                    job.ownerName
                  ) : (
                    <Skeleton
                      baseColor={baseColor}
                      highlightColor={highlightColor}
                      width={30}
                      containerClassName="ml-12 md:ml-0"
                      containerTestId={'job-owner-skeleton'}
                    />
                  )}
                </div>
              </div>
              <span className="text-xs md:block 2xl:ml-2 font-comfortaa text-gray-secondary dark:text-gray-input font-extralight">
                {jobVersion ? (
                  jobVersion.tag
                ) : (
                  <Skeleton
                    containerClassName="w-full"
                    containerTestId="job-version-skeleton"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                  />
                )}
              </span>
            </span>
          </div>

          <div className="flex flex-col w-full pl-7 2xl:ml-0">
            {/* Job Description */}
            <div className="ml-4 w-full items-center flex justify-center h-2 pr-8 lg:pr-0 mb-2 text-secondary-dark dark:text-gray-primary">
              {job ? (
                <JobDescription
                  description={
                    job.description ? job.description : t('job.no_description')
                  }
                  classOverrides={['line-clamp text-sm w-full']}
                />
              ) : (
                <Skeleton
                  containerClassName="w-full"
                  containerTestId="job-usages-skeleton"
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                />
              )}
            </div>
            <div className="flex right-0 h-full overflow-hidden items-center ml-4">
              {job?.labels && job?.tier ? (
                <CardLabelSlider
                  isDeprecated={job.deprecated}
                  labels={job.labels}
                  tier={job.tier}
                  labelClass="text-xs"
                />
              ) : (
                <Skeleton
                  baseColor={baseColor}
                  highlightColor={highlightColor}
                  count={3}
                  containerTestId={'job-labels-skeleton-' + index}
                  className="mr-1"
                  inline
                  containerClassName="flex justify-beetwen w-full"
                />
              )}
            </div>
            {job?.policy === 'private' && (
              <FeatureFlag name="private-job">
                <div
                  className="absolute right-8 top-1 h-12"
                  data-testid={'lockIcon-' + job.name}
                  title={t('my_jobs.modify_your_job.policy.public.tooltip')}
                >
                  <LockIcon
                    width="20"
                    classOverrides={[
                      'text-primary-default dark:text-gray-primary max-h-full',
                    ]}
                  />
                </div>
              </FeatureFlag>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
