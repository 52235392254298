import React, { useEffect, useState } from 'react';
import { Page } from '@components/Page/Page.component';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '@components/Titles/Title.component';
import { NavBarCutter } from '@components/NavigationBar/NavBarCutter/NavBarCutter.component';
import { Typography } from '@components/Typography/Typography.component';
import i18n from 'common/config/i18n';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';

type Definitions = {
  keys: string | undefined;
  content: string | undefined;
};

export const TermsOfUsePage: React.FC = () => {
  const { t } = useTranslation();
  const [definitions, setDefinitions] = useState<Definitions[]>([]);
  useEffect(() => {
    const definitionsToDisplay: Definitions[] = [];
    let i = 0;
    while (i18n.exists('terms_of_use.definitions.keys.' + i)) i++;
    for (let j = 0; j < i; j++) {
      definitionsToDisplay.push({
        keys: i18n.exists('terms_of_use.definitions.keys.' + j)
          ? t('terms_of_use.definitions.keys.' + j)
          : undefined,
        content: i18n.exists('terms_of_use.definitions.contents.' + j)
          ? t('terms_of_use.definitions.contents.' + j)
          : undefined,
      });
    }
    setDefinitions(definitionsToDisplay);
  }, [t]);
  return (
    <Page
      title={t('terms_of_use.page_title')}
      description={t('terms_of_use.description')}
    >
      <div className="bg-secondary-default text-center">
        <Title bold color={'text-white'} size={'h1'}>
          {t('terms_of_use.page_title')}
        </Title>
        <NavBarCutter />
      </div>
      <div className="mx-12 md:mx-24 xl:mx-96">
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.0')}
        </Title>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['my-4 dark:text-gray-primary font-black']}
          >
            {t('terms_of_use.preamble.0')}
          </Typography>
        </div>
        <Typography
          color={'text-gray-secondary'}
          classOverrides={['dark:text-gray-primary']}
        >
          {t('terms_of_use.preamble.1')}
        </Typography>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['font-black dark:text-gray-primary']}
          >
            {t('terms_of_use.preamble.2')}
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.1')}
        </Title>
        <div className="pt-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.relationships.0')}
          </Typography>
        </div>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.relationships.1')}
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.2')}
        </Title>
        <div>
          {definitions.map((row, index) => {
            if (!row.keys || !row.content) return null;
            return (
              <div
                className="py-2 dark:text-gray-primary"
                key={index + '-' + row.keys}
              >
                <ListKeyValue light keyStr={row.keys} value={row.content} />
                <br />
              </div>
            );
          })}
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.3')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.prerequisites')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.4')}
        </Title>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.contract_duration.0')}
          </Typography>
        </div>
        <Typography
          color={'text-gray-secondary'}
          classOverrides={['dark:text-gray-primary']}
        >
          {t('terms_of_use.contract_duration.1')}
        </Typography>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.contract_duration.2')}
          </Typography>
        </div>
        <Typography
          color={'text-gray-secondary'}
          classOverrides={['dark:text-gray-primary']}
        >
          {t('terms_of_use.contract_duration.3')}
        </Typography>
        <div className="py-4">
          <Typography
            color={'text-gray-secondary'}
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.contract_duration.4')}
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.5')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.financial')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.6')}
        </Title>
        <div className="text-left my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary underline']}
          >
            {t('terms_of_use.terms_access.sub_titles.0')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary']}
        >
          <Trans
            i18nKey={t('terms_of_use.terms_access.text.0')}
            tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
          />
        </Typography>
        <div className="text-left my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary underline']}
          >
            {t('terms_of_use.terms_access.sub_titles.1')}
          </Typography>
        </div>
        <div className="mb-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans i18nKey="terms_of_use.terms_access.text.1">
              The Platform is accessible within the Client's internal
              infrastructure as part of an on-premise license or, in the case of
              a SaaS license, on the website
              <TrackedLink
                name={'signUp'}
                to="/"
                title={t('action:title.redirect_home_page')}
                className={'underline text-primary-default hover:opacity-70'}
              >
                https://r2devops.io
              </TrackedLink>
              .
            </Trans>
          </Typography>
        </div>
        <div className="mb-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.terms_access.text.2')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.7')}
        </Title>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.0')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.0')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.service_diversity.text.1')}
          </Typography>
        </div>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.service_diversity.text.2')}
          </Typography>
        </div>
        <ul>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.service_diversity.list_text.0')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.service_diversity.list_text.1')}
            </Typography>
          </li>
        </ul>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.service_diversity.text.3')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.1')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.4')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.2')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.5')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.3')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.6')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.4')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.7')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.service_diversity.sub_titles.5')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.service_diversity.text.8')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
          <ul>
            <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
              <Typography
                color="text-gray-secondary"
                classOverrides={['dark:text-gray-primary']}
              >
                {t('terms_of_use.service_diversity.list_pipeline.0')}
              </Typography>
            </li>
            <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
              <Typography
                color="text-gray-secondary"
                classOverrides={['dark:text-gray-primary']}
              >
                <Trans i18nKey="terms_of_use.service_diversity.list_pipeline.1">
                  Generate is available on
                  <OutsideLink
                    name={'Generate website'}
                    to="https://generate.r2devops.io"
                    rel="nofollow noreferrer"
                    target="_blank"
                    className={
                      'underline text-primary-default hover:opacity-70'
                    }
                  >
                    https://generate.r2devops.io
                  </OutsideLink>
                  . You don't need an account to use this service, but you'll
                  only get a light CI/CD pipeline.
                </Trans>
              </Typography>
            </li>
          </ul>
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.service_diversity.text.9')}
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.8')}
        </Title>
        <div className="mt-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary underline']}
          >
            {t('terms_of_use.provider_obligation.sub_titles.0')}
          </Typography>
        </div>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.provider_obligation.text.0')}
          </Typography>
        </div>

        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.provider_obligation.sub_titles.1')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.text.1')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.provider_obligation.sub_titles.2')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.text.2')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.provider_obligation.sub_titles.3')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.text.3')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <ul>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.0')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.1')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.2')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.3')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.4')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.5')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.6')}
            </Typography>
          </li>
        </ul>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.provider_obligation.text.4')}
          </Typography>
        </div>
        <ul>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.7')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.8')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.9')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.10')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.11')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.12')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.13')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.14')}
            </Typography>
          </li>
          <li className="mt-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.liability.list.15')}
            </Typography>
          </li>
        </ul>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.text.5')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.provider_obligation.sub_titles.4')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.provider_obligation.support.text.0')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary ml-4 lg:ml-12 xl:ml-24 ']}
        >
          {t('terms_of_use.provider_obligation.support.titles.0')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.support.text.1')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary ml-4 lg:ml-12 xl:ml-24 ']}
        >
          {t('terms_of_use.provider_obligation.support.titles.1')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.support.text.2')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary ml-4 lg:ml-12 xl:ml-24 ']}
        >
          {t('terms_of_use.provider_obligation.support.titles.2')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.support.text.3')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary ml-4 lg:ml-12 xl:ml-24 ']}
        >
          {t('terms_of_use.provider_obligation.support.titles.3')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.support.text.4')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <ul>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.0')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.1')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.2')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.3')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.4')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.5')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.6')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.provider_obligation.support.list.7')}
            </Typography>
          </li>
        </ul>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provider_obligation.support.text.5')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.9')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.obligations')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.10')}
        </Title>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary underline']}
          >
            {t('terms_of_use.intellectual.titles.0')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['ml-4 lg:ml-12 xl:ml-24  dark:text-gray-primary']}
        >
          {t('terms_of_use.intellectual.sub_titles.0')}
        </Typography>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.intellectual.text.0')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <ul>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.intellectual.list.0')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.intellectual.list.1')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.intellectual.list.2')}
            </Typography>
          </li>
        </ul>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.intellectual.text.1')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['ml-4 lg:ml-12 xl:ml-24  dark:text-gray-primary']}
        >
          {t('terms_of_use.intellectual.sub_titles.1')}
        </Typography>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.intellectual.text.2')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['ml-4 lg:ml-12 xl:ml-24  dark:text-gray-primary']}
        >
          {t('terms_of_use.intellectual.sub_titles.2')}
        </Typography>
        <div className="my-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.intellectual.text.3')}
          </Typography>
        </div>
        <Typography
          color="text-gray-secondary"
          classOverrides={['dark:text-gray-primary underline']}
        >
          {t('terms_of_use.intellectual.titles.1')}
        </Typography>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.intellectual.text.4')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.11')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.insurance')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.12')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.hosting.0')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <ul>
          <li className="mb-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.hosting.1')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.hosting.2')}
            </Typography>
          </li>
          <li className="my-4 ml-4 lg:ml-12 xl:ml-24  list-disc text-gray-secondary">
            <Typography
              color="text-gray-secondary"
              classOverrides={['dark:text-gray-primary']}
            >
              {t('terms_of_use.hosting.3')}
            </Typography>
          </li>
        </ul>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.13')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.personal_data')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.14')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.backups')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.15')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            {t('terms_of_use.confidentiality')}
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.16')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.assignment')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.17')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.compliance_law')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.18')}
        </Title>
        <div className="py-4">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.applicable_law')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>

        <Title
          bold
          color={'text-secondary-default'}
          classOverrides={['text-center']}
          size={'h2'}
        >
          {t('terms_of_use.titles.19')}
        </Title>
        <div className="pt-4 pb-12">
          <Typography
            color="text-gray-secondary"
            classOverrides={['dark:text-gray-primary']}
          >
            <Trans
              i18nKey={t('terms_of_use.provisions')}
              tOptions={{ transKeepBasicHtmlNodesFor: ['br'] }}
            />
          </Typography>
        </div>
      </div>
    </Page>
  );
};

type ListKeyValueProps = {
  keyStr: string;
  value: string;
  light?: boolean;
};

/**
 * @param {string} keyStr -- cannot be named key as is React reserved property
 * @param {string} value
 */
export const ListKeyValue: React.FC<ListKeyValueProps> = ({
  keyStr,
  value,
}) => (
  <span>
    <span className="font-black text-gray-secondary dark:text-gray-primary">
      {keyStr}:
    </span>{' '}
    <span className="font-light text-gray-secondary dark:text-gray-primary">
      {value}
    </span>
  </span>
);
