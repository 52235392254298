import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const ChainIcon: React.FC<Props> = ({
  classOverrides = [],
  color,
  height = '20',
  width = '20',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(color, ...classOverrides)}
    >
      <path
        d="M13.7794 7.88109C13.2667 7.36836 12.2707 6.66207 10.8294 6.68785L8.80757 8.70973C8.57558 8.94172 8.57554 9.30633 8.80757 9.53836C9.0396 9.77039 9.40421 9.77035 9.6362 9.53836C10.324 8.85058 11.4343 8.85058 12.1221 9.53836C12.8099 10.2261 12.8099 11.3365 12.1221 12.0243L6.99894 17.1433C6.3112 17.831 5.20081 17.8311 4.513 17.1433C3.82522 16.4555 3.82526 15.3451 4.513 14.6573L5.93218 13.2424C5.77472 13.1181 5.63382 12.9938 5.49296 12.8529C5.06206 12.422 4.71405 11.9248 4.46546 11.3945L2.85569 13.0001C1.25644 14.5993 1.25644 17.2013 2.85569 18.8005C4.45495 20.3998 7.0569 20.3998 8.65616 18.8005L13.7794 13.6816C14.7492 12.7118 15.8929 9.99457 13.7794 7.88109Z"
        fill="#00B1D2"
      />
      <path
        d="M14.6092 7.0527C15.0485 7.49191 15.3882 7.98078 15.6367 8.51109L17.1455 7.00016C18.7447 5.4009 18.7447 2.79895 17.1455 1.19969C15.5462 -0.39957 12.9442 -0.39957 11.345 1.19969L6.32284 6.22406C4.77034 7.77656 4.68171 10.3834 6.32284 12.0245C7.14745 12.8491 8.29851 13.2513 9.2728 13.2178L11.2947 11.1959C11.5267 10.9639 11.5267 10.5993 11.2947 10.3673C11.0626 10.1352 10.6981 10.1352 10.466 10.3673C9.77831 11.055 8.66792 11.055 7.98011 10.3673C7.2923 9.67949 7.29237 8.56906 7.98011 7.88133L13.0023 2.85695C13.69 2.16918 14.8004 2.16918 15.4882 2.85695C16.176 3.54473 16.176 4.65508 15.4882 5.34289L14.1701 6.6632C14.3192 6.77922 14.4684 6.91184 14.6092 7.0527Z"
        fill="#00B1D2"
      />
    </svg>
  );
};
