import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '@components/Form/StyledInput/StyledInput.component';
import { FieldValues, useForm } from 'react-hook-form';
import Plausible from 'plausible-tracker';
import mixpanel from '@utils/mixpanel.util';
import { isGitLabOrGitHubUrl } from '@utils/text.util';
import {
  LightPipelineConfig,
  useLightPipelineGenerator,
} from '@home/hooks/useLightPipelineGenerator/useLightPipelineGenerator.hook';
import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';
import { LightPipelineResponseModal } from '../LightPipelineResponseModal/LightPipelineResponseModal.component';
import { GitHubIcon } from '@components/Icons/GitHubIcon/GitHubIcon.component';
import { GitlabAlliancePartnerIcon } from '@components/Icons/GitlabIcon/GitlabAlliancePartnerIcon';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { Title } from '@components/Titles/Title.component';
import { ThemeContext } from 'common/contexts/theme.context';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInRightVariant } from '@utils/motion.util';
import { SecondaryButton } from '@components/Buttons/SecondaryButton/SecondaryButton.component';
import { Typography } from '@components/Typography/Typography.component';

/**
 * Left button in the top navigation bar
 * @constructor
 */
type ProjectTemplate = {
  techno: string;
  url: string;
};
export const LightPipelineGenerator: React.FC = () => {
  const { t } = useTranslation();
  const [triggerAutoPipeline, fetchResult, loading] =
    useLightPipelineGenerator();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });
  const { theme } = useContext(ThemeContext);
  const projectsList: ProjectTemplate[] = [
    { techno: 'Python', url: 'https://gitlab.com/tictac-toe/grid-api' },
    { techno: 'Node', url: 'https://gitlab.com/tictac-toe/grid-frontend' },
    { techno: 'Golang', url: 'https://gitlab.com/purelb/purelb' },
    { techno: 'Java', url: 'https://gitlab.com/spacecowboy/Feeder' },
  ];
  const gitlabGroupsComponents = (className: string) => (
    <div className={'flex items-center ' + className}>
      <GitHubIcon classOverrides={['w-9 mr-2']} white={theme === 'dark'} />
      <OutsideLink
        name="gitlab alliance"
        to="https://about.gitlab.com/partners/technology-partners/#continuous-integration"
        className="ml-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitlabAlliancePartnerIcon white={theme === 'dark'} width="auto" />
      </OutsideLink>
    </div>
  );
  const { trackEvent } = Plausible();
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [platformToDisplay, setPlatformToDisplay] = useState<
    'github' | 'gitlab'
  >();
  const [projectUrl, setProjectUrl] = useState<string>();
  const submitHandler = async (data: FieldValues): Promise<void> => {
    const autoPipelineConfig: LightPipelineConfig = {
      projectUrl: data.projectUrl,
      sourceBranch: '',
      platform: data.projectUrl.toLowerCase().includes('gitlab.com')
        ? 'gitlab'
        : data.projectUrl.toLowerCase().includes('github.com') && 'github',
    };
    setPlatformToDisplay(autoPipelineConfig.platform);
    setProjectUrl(data.projectUrl);
    triggerAutoPipeline(autoPipelineConfig);
    trackEvent('Trigger light pipeline generator');
    mixpanel.track(`Trigger light pipeline generator`);
  };
  useEffect(() => {
    if (fetchResult?.errors || !fetchResult?.pipelines || !fetchResult) return;
    if (fetchResult?.pipelines) {
      setShowResponseModal(true);
    }
  }, [fetchResult, fetchResult?.pipelines]);

  return (
    <AnimatePresence>
      <motion.div
        className="w-full sm:w-104 lg:mt-2 mt-6 rounded-xl py-4 px-4 bg-white dark:bg-dark-primary transition-all transition-duration-500"
        variants={fadeInRightVariant()}
      >
        {fetchResult && platformToDisplay && projectUrl && (
          <LightPipelineResponseModal
            projectUrl={projectUrl}
            fetchResult={fetchResult}
            showResponseModal={showResponseModal}
            setShowResponseModal={setShowResponseModal}
            platformToDisplay={platformToDisplay}
          />
        )}
        <Title
          size="h3"
          classOverrides={[
            'text-secondary-default font-extrabold text-center pb-4',
          ]}
        >
          {t('action:try')} 👇
        </Title>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex justify-center items-center flex-col">
            <div className="w-full">
              <StyledInput
                register={register('projectUrl', {
                  validate: (value) =>
                    value && !isGitLabOrGitHubUrl(value)
                      ? `${t('home.light_pipeline.input_error')}`
                      : undefined,
                })}
                bgColor={'bg-gray-primary'}
                classOverrides={[
                  'dark:bg-gray-third dark:text-gray-primary h-12 text-center text-sm md:text-base',
                ]}
                labelClassOverrides={['text-sm md:text-base']}
                type="text"
                key={'repo-input'}
                error={errors.projectUrl?.message}
                placeholder={t('home.light_pipeline.input_placeholder')}
                data-testid="repo-url-input"
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <Typography
                color="text-secondary-default"
                bold
                classOverrides={['dark:text-gray-primary']}
              >
                {t('home.light_pipeline.or')}
              </Typography>
              <Typography
                color="text-gray-secondary"
                classOverrides={['dark:text-gray-primary mt-1']}
              >
                {t('home.light_pipeline.description')}
              </Typography>
            </div>
            <div className="flex my-3">
              {projectsList.map((project) => {
                return (
                  <SecondaryButton
                    key={project.techno}
                    content={project.techno}
                    action={() => {
                      setValue('projectUrl', project.url);
                    }}
                    bgColor={'bg-secondary-default'}
                    color={'text-white'}
                    classOverrides={[
                      'w-max h-8 flex items-center font-medium text-sm mr-2 focus:shadow-button-active-inner',
                    ]}
                  />
                );
              })}
            </div>
            <SubmitButton
              margin="m-0"
              bgColor={'bg-secondary-default'}
              color={'text-gray-primary'}
              classOverrides={[
                'w-46 mt-2 md:w-64 mb-5 dark:bg-primary-default h-12 text-sm md:text-base ',
                !loading && 'flex justify-center',
              ]}
              content={t('action:generate_my_pipeline')}
              key={'submit'}
              font="comfortaa"
              isSubmitting={isSubmitting}
              isValid={!!errors}
              errors={errors}
              loading={loading}
              disabled={!watch('projectUrl') || errors.projectUrl?.message}
            />
            {gitlabGroupsComponents('flex')}
          </div>
        </form>
      </motion.div>
    </AnimatePresence>
  );
};
