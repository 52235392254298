import {
  ClassOverrides,
  Sizing,
  TextColor,
} from '@typings/ClassOverrides.type';
import { cn } from '@utils/classes.util';
import React from 'react';

type Props = ClassOverrides &
  Sizing & {
    color?: TextColor;
  };

export const PenIcon: React.FC<Props> = ({
  classOverrides = [],
  color = 'text-secondary-dark',
  height = '35',
  width = '34',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 ' + width + ' ' + height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(...classOverrides, color)}
    >
      <g clipPath="url(#clip0_2646_838)">
        <path
          d="M27.8958 0.527802C28.9262 0.776114 29.8448 1.35998 30.5069 2.18752C31.1597 2.88197 31.8403 3.57641 32.5347 4.2153C32.8697 4.5391 33.1361 4.92701 33.318 5.35591C33.5 5.78481 33.5937 6.24594 33.5937 6.71183C33.5937 7.17773 33.5 7.63885 33.318 8.06776C33.1361 8.49666 32.8697 8.88456 32.5347 9.20836C32.1736 9.55558 31.8194 9.90975 31.4722 10.2709C31.3611 10.3889 31.2847 10.4375 31.1389 10.2709L23.75 2.88891C24.2708 2.34725 24.7639 1.79169 25.2986 1.28475C25.6578 0.964956 26.0929 0.742598 26.5625 0.638913C26.6597 0.638913 26.7847 0.638913 26.8472 0.527802H27.8958Z"
          fill="currentColor"
        />
        <path
          d="M30.2773 11.4445L30.187 11.5486C30.1304 11.6145 30.0701 11.6771 30.0065 11.7361C24.0713 17.6806 18.1315 23.625 12.187 29.5695C11.9717 29.7917 11.8676 29.7847 11.6523 29.5695C9.30043 27.1945 6.93469 24.8287 4.55506 22.4722C4.36756 22.2917 4.35367 22.2014 4.55506 22C10.5273 16.0324 16.4972 10.0602 22.4648 4.08335C22.6523 3.88891 22.7356 3.88197 22.9301 4.08335C25.2981 6.47224 27.687 8.85419 30.062 11.2361C30.1315 11.2709 30.2009 11.3681 30.2773 11.4445Z"
          fill="currentColor"
        />
        <path
          d="M10.4521 30.5695L0.111816 34.0417L3.58404 23.7014L10.4521 30.5695Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2646_838">
          <rect
            width="33.4653"
            height="33.4931"
            fill="white"
            transform="translate(0.138672 0.527802)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
