import React, { InputHTMLAttributes } from 'react';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { CameraIcon } from '@components/Icons/CameraIcon/CameraIcon';
import { cn } from '@utils/classes.util';
import { InputProps } from '@components/Form/StyledField/StyledField.component';

type Props = ClassOverrides &
  InputProps<InputHTMLAttributes<HTMLInputElement>> & {
    disabled?: boolean;
    isSubmitting?: boolean;
    placeholderBlur?: boolean;
    placeholder: string;
  };

export const PictureInput: React.FC<Props> = ({
  classOverrides = [],
  disabled = false,
  isSubmitting,
  label,
  name,
  register = {},
  placeholder,
  placeholderBlur = false,
  value,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-start text-gray-secondary dark:text-gray-primary font-comfortaa text-md">
        {label}
      </div>
      <label
        data-testid="picture-input"
        htmlFor={name}
        className={cn(
          'flex-shrink-0 inline-block bg-gray-primary rounded-full ' +
            'filter drop-shadow-button-outer text-secondary-dark transition-all duration-300',
          !disabled ? 'hover:text-secondary-dark-low-opacity' : '',
          value ? '' : 'p-2',
          ...classOverrides,
        )}
        // Override label HTML element cursor:default
        style={{ cursor: !disabled ? 'pointer' : 'default' }}
      >
        <img
          className={cn(
            'filter rounded-full w-full h-full',
            value ? '' : placeholderBlur ? 'blur-sm' : '',
          )}
          src={value?.toString() ?? placeholder}
          alt="Placeholder or user's avatar"
        />
        {!disabled && (
          <CameraIcon
            classOverrides={[
              'absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2',
            ]}
          />
        )}
        {name && (
          /**
           * TODO: Register input once we implement profile picture update
           * Had to remove it for project to compile after component rework
           */
          <input
            accept="image/*"
            className="hidden"
            disabled={isSubmitting || disabled}
            id={name}
            type="file"
          />
        )}
      </label>
    </div>
  );
};
