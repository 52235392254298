import { SpinnerIcon } from '@components/Icons/SpinnerIcon/SpinnerIcon.component';
import {
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
} from '@ory/kratos-client';
import { AnimatePresence } from 'framer-motion';
import { KratosFormInputs } from 'kratos/components/KratosFormInputs/KratosFormInputs';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GrayBox } from '../GrayBox.component';
import React from 'react';
import { SubmitButton } from '@components/Buttons/SubmitButton/SubmitButton.component';

type Props = {
  flow?: SelfServiceRecoveryFlow;
  submitHandler: (
    body: SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
  ) => void;
};

export const RecoveryForm: React.FC<Props> = ({ flow, submitHandler }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmitOverride = (
    body: SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
  ) => {
    reset();
    submitHandler(body);
  };

  return (
    <GrayBox>
      <div
        className="pt-2 px-4 md:px-8 pb-4 h-auto"
        data-testid="recovery-form"
      >
        <AnimatePresence exitBeforeEnter>
          {flow ? (
            <form
              onSubmit={handleSubmit(onSubmitOverride)}
              method={flow.ui.method}
            >
              <KratosFormInputs
                flowUiNodes={flow.ui.nodes}
                register={register}
                isSubmitting={isSubmitting}
                errors={errors}
                classOverrides={['dark:text-gray-primary']}
                bgColor={'dark:bg-secondary-default-high-opacity'}
              />
              <SubmitButton
                content={t('action:send')}
                classOverrides={['dark:bg-dark-secondary']}
                isSubmitting={isSubmitting}
                isValid={isValid}
                errors={errors}
              />
            </form>
          ) : (
            <SpinnerIcon classOverrides={['my-8']} />
          )}
        </AnimatePresence>
      </div>
    </GrayBox>
  );
};
