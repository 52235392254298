import { Typography } from '@components/Typography/Typography.component';
import { TrackedLink } from '@components/Mixpanel/TrackedLink.component';
import { OutsideLink } from '@components/Mixpanel/OutsideLink.component';
import { DashboardLink } from './DashboardSideBar.component';
import { useHistory } from 'react-router-dom';
import { cn } from '@utils/classes.util';
import { ClassOverrides } from '@typings/ClassOverrides.type';
import { isAResourcesLink, isASettingsLink } from '@utils/dashboard.util';

type Props = {
  link: DashboardLink;
  isRoute?: boolean;
  subLink?: boolean;
  rounded?: string;
  padding?: string;
  setResourcesIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSettingsIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & ClassOverrides;

export const SideBarLink: React.FC<Props> = ({
  link,
  isRoute = false,
  children,
  subLink = false,
  padding = 'p-3',
  setSettingsIsOpen,
  setResourcesIsOpen,
  classOverrides = [],
  rounded = 'lg:rounded-xl',
}) => {
  const history = useHistory();
  const actionOnClick = () => {
    if (isASettingsLink(link.to)) {
      setResourcesIsOpen(false);
    } else if (isAResourcesLink(link.to)) {
      setSettingsIsOpen(false);
    } else {
      setResourcesIsOpen(false);
      setSettingsIsOpen(false);
    }
  };
  return !isRoute ? (
    <TrackedLink
      title={link.title ?? ''}
      name={link.name}
      to={link.to}
      onClick={actionOnClick}
    >
      <button
        className={cn(
          'hover:opacity-70 hover:shadow-button-inner filter flex items-center justify-center w-full',
          rounded,
          history.location.pathname === link.to &&
            subLink &&
            'shadow-button-active-inner bg-secondary-default-high-opacity ',
          history.location.pathname === '/dashboard/' &&
            link.to === '/dashboard/projects' &&
            !subLink &&
            'shadow-button-active-inner bg-secondary-default ',
          history.location.pathname === link.to &&
            !subLink &&
            'shadow-button-active-inner bg-secondary-default ',
          padding,
          ...classOverrides,
        )}
      >
        <div className="text-white flex items-center justify-center">
          {children && <div className="w-full lg:w-12">{children}</div>}
          <Typography
            color="text-white"
            size="sm"
            classOverrides={[
              'py-3 md:py-0.5 hidden lg:block',
              history.location.pathname === link.to &&
                subLink &&
                'decoration-white font-bold',
              history.location.pathname === '/dashboard/' &&
                link.to === '/dashboard/projects' &&
                !subLink &&
                'decoration-white',
              history.location.pathname === link.to &&
                !subLink &&
                'decoration-white',
            ]}
          >
            {link.name}
          </Typography>
          {link.button && (
            <button className="hidden lg:block w-12 bg-gray-primary ">
              {link.button}
            </button>
          )}
        </div>
      </button>
    </TrackedLink>
  ) : (
    <OutsideLink
      title={link.title ?? ''}
      name={link.name}
      to={link.to}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        className={cn(
          'hover:opacity-70 flex px-3 py-3 items-center filter justify-center w-full',
          rounded,
          history.location.pathname === link.to && 'bg-secondary-default',
          ...classOverrides,
        )}
      >
        <div className="text-white flex items-center">
          {children && <div className="w-full lg:w-12">{children}</div>}
          <Typography
            size="sm"
            color="text-white"
            classOverrides={['py-3 md:py-0.5 hidden lg:block']}
          >
            {link.name}
          </Typography>
          {link.button && (
            <button className="hidden lg:block ml-2 py-0 px-1 bg-gray-primary text-secondary-default text-xs">
              {link.button}
            </button>
          )}
        </div>
      </button>
    </OutsideLink>
  );
};
