import { useContext, useEffect, useReducer, useState } from 'react';
import { HttpContext } from '../../common/contexts/http.context';
import { ActionType, httpReducer } from 'common/reducers/httpReducer';
import { toastError } from '@components/Toasters/Toaster.component';
import { useTranslation } from 'react-i18next';

type ReturnType = [string | null | undefined, boolean, boolean];

export const useJobMarkdown = (jobMarkdownUrl: string): ReturnType => {
  const [jobMarkdown, dispatch] = useReducer(httpReducer<string>(), null);
  const httpService = useContext(HttpContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [noDocFile, setNoDocFile] = useState(false);
  useEffect(() => {
    if (!jobMarkdownUrl) {
      toastError(t('toast.jobDocumentation'));
      return;
    }

    setLoading(true);
    httpService
      .fetch<string>('GET', jobMarkdownUrl, {
        withCredentials: false,
      })
      .then(async (res) => {
        if (res.error?.toString() === 'Not Found') {
          if (!noDocFile) {
            toastError(t('toast.jobDocumentation'));
            setNoDocFile(true);
          }
        }
        if (!res.response) {
          if (!noDocFile) {
            toastError(t('toast.jobDocumentation'));
            setNoDocFile(true);
          }
          return;
        }
        dispatch({
          type: ActionType.SUCCESS,
          result: res.response,
        });
        return;
      })
      .catch((_) => {
        if (!noDocFile) {
          toastError(t('toast.jobDocumentation'));
          setNoDocFile(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobMarkdownUrl, httpService, t, noDocFile]);

  return [jobMarkdown, loading, noDocFile];
};
